import { Recording } from './components';
import OpenEnded from './components';
import { MultiInputs } from './components';
import { AnswerOptions } from './components';
import { ArrangeWords } from './components';
import { WordMatch } from './components';
// import Result from './result';
import classNames from 'classnames'; 
import React, { useState } from 'react';
import { Loader } from '../loading';
import TimeCount from '../input/timeCount';
import { MdClose} from 'react-icons/md';
import { useEffect } from 'react';
import { FaCheck, FaFastForward, FaFileAlt, FaFileAudio } from 'react-icons/fa';
import { creditIcon } from '../../common/utils';
import CountUp from './countUp';
import { useStore } from '../../store/useStore';

const TestAnswer = ({tests, testData, onSubmit, onSkip, isPreview, openEndData, setOpenEndData, recordData, setRecordData,
    onTimeOutHandler, answer, setAnswer, setAnswerTime, loading, worksheetTime, onWorksheetTimeout, timeCount, 
    setTimeCount, resultData, onNext, openModal2, showSelfMarked, selfMarkedComment, setSelfMarkedComment,
    selfMarkedAction, setSelfMarkedAction, themeId, getTimeCount, setGetTimeCount}) => {
    const [isShowTime, setIsShowTime] = useState(false);
    const {user} = useStore();
    const commentOnChange = (e) => {
        setSelfMarkedComment(e.target.value);
    }

    const onAnswerOptionsChange = (value) => {
        setAnswer(value);
    }

    useEffect(()=>{
        selfMarkedAction && !selfMarkedComment && setOpenEndData({...openEndData, type: 'Self-marked', marked: selfMarkedAction});
        selfMarkedAction && selfMarkedComment && setOpenEndData({...openEndData, type: 'Self-marked', marked: selfMarkedAction, comment: selfMarkedComment});
    }, [selfMarkedAction, selfMarkedComment]);

    useEffect(()=>{
        loading && setTimeCount(0);
    }, [loading]);

    const continueOnClick = () => {
        if(testData.nextQuestionId) {
            onNext && onNext(true);
        }
    }

    return (
        <div className={classNames(tests.testAnswer, {[tests.openEnded]: testData && testData.answerType === "openEnded"})}>
            {
                worksheetTime > 0 &&
                <div className={tests.time}>
                    <TimeCount
                        setIsShowTime={setIsShowTime} 
                        seconds={worksheetTime*60} 
                        messageTimeOut="" 
                        onTimeOut={onWorksheetTimeout}
                        className={tests.timeCount}
                        style={{
                            display: isShowTime ? "block": "none"
                        }}
                        setAnswerTime={setAnswerTime}
                    />
                </div> 
            }
            {loading && 
                <div>
                    <Loader />
                </div>
            }
            {
                !loading && testData && testData.questionTime && testData.showQuestionTime &&
                <div className={tests.time}>
                    <TimeCount
                        setIsShowTime={setIsShowTime} 
                        seconds={testData && testData.skipTime ? testData.questionTime*60 - testData.skipTime : testData.questionTime*60} 
                        messageTimeOut="" 
                        onTimeOut={onTimeOutHandler}
                        className={tests.timeCount}
                        style={{
                            display: isShowTime ? "block": "none"
                        }}
                        setAnswerTime={setAnswerTime}
                    />
                </div> 
            }

            {
                !loading && testData && !resultData && ((!isPreview && user.userRole !== 'Teachers' ) || (isPreview && user.userRole === 'Teachers')) &&
                <div className={tests.answers}>
                    {testData.answerType === "text" && testData.shortAnswerBlanks > 0 && !testData.noOfBlanks &&
                        <MultiInputs
                            number={testData.shortAnswerBlanks}
                            onChange={onAnswerOptionsChange}
                            subject={testData.subjects && testData.subjects.length && testData.subjects[0] && testData.subjects[0].label}
                        />
                    }

                    {testData.answerType === "text" && testData.noOfBlanks > 0 &&
                        <ul className={tests.multiInputs}>
                            <label>Fill in the blanks</label>
                            {
                                <MultiInputs
                                    number={testData.noOfBlanks} 
                                    onChange={onAnswerOptionsChange}
                                    subject={testData.subjects && testData.subjects.length && testData.subjects[0] && testData.subjects[0].label}
                                />
                            }
                        </ul>
                        
                    }

                    {testData.answerType === "choice" && testData.options && testData.options.length &&
                        <AnswerOptions
                            options={testData.options} 
                            onChange={onAnswerOptionsChange}
                            isMulti={testData.multiRightAnswers}
                            type="textChoice"
                            showText={themeId && themeId === 'th8' ? true : false}
                        />
                    }

                    {testData.answerType === "ordering" && testData.options &&
                        <ArrangeWords options={testData.options} onChange={onAnswerOptionsChange}/>
                    }

                    {
                        (testData.answerType === "matching") && testData.options && testData.options2 &&
                        <WordMatch 
                            options1={testData.options} 
                            options2={testData.options2} 
                            onChange={onAnswerOptionsChange}
                            type={testData.answerType}
                        />
                    }

                    {
                        testData.answerType === "openEnded" &&
                        <OpenEnded 
                            markData={openEndData} 
                            setMarkData={setOpenEndData} 
                            subject={testData.subjects && testData.subjects.length && testData.subjects[0] && testData.subjects[0].label}
                        />
                    }

                    {
                        showSelfMarked && testData.answerType === "openEnded" &&
                        <div className={tests.selfMarked}>
                            <h3>Let's check our reference answer and mark your answer</h3>
                            <div className={tests.explanation} dangerouslySetInnerHTML={{__html: testData.explanation}}></div>
                            <div className={tests.selfMarkedButtons}>
                                <button className={selfMarkedAction === 'correct' ? tests.active : ''} onClick={() => setSelfMarkedAction('correct')}>My answer is correct</button>
                                <button className={selfMarkedAction === 'incorrect' ? tests.active : ''} onClick={() => setSelfMarkedAction('incorrect')}>My answer is not correct</button>
                                <button className={selfMarkedAction === 'disagree' ? tests.active : ''} onClick={() => setSelfMarkedAction('disagree')}>I disagree with the reference answer</button>
                            </div>
                            {
                                selfMarkedAction === 'disagree' &&
                                <div className={tests.smComment}>
                                    <label>Add your comment here</label>
                                    <textarea onChange={commentOnChange} value={selfMarkedComment}></textarea>
                                </div>
                            }
                        </div>
                    }

                    {
                        testData.answerType === "recording" &&
                        <Recording markData={recordData} setMarkData={setRecordData} />
                    }
                    {/* {   !isPreview && !showSelfMarked &&
                        <CountUp 
                            tests={tests} 
                            timeCount={timeCount} 
                            setTimeCount={!showSelfMarked ? setTimeCount : null} 
                            getTimeCount={getTimeCount} 
                            setGetTimeCount={setGetTimeCount}
                        />
                    } */}
                    {
                        !isPreview &&
                        <div className={tests.buttons}>
                            {
                                !showSelfMarked &&
                                <button className={tests.skipBtn} onClick={onSkip}>Skip</button>
                            }
                            <button className={tests.submitBtn} onClick={onSubmit}>Submit</button>
                        </div>
                    }
                </div>
            }
            {
                !loading && resultData &&
                <div className={tests.results}>
                    { resultData.answerStatus &&
                        <h3 className={tests[resultData.answerStatus]}>
                            { resultData.answerStatus === 'correct' &&
                                <FaCheck />
                            }
                            {
                                ['skip', 'skipped', 'inprogress'].includes(resultData.answerStatus) &&
                                <FaFastForward />
                            }
                            { ['incorrect', 'disagree', 'timeout'].includes(resultData.answerStatus) &&
                                <MdClose />
                            }
                            {
                                resultData.questionType === 'openEnded' && testData.openEndedType === 'Self-marked' &&
                                <span>Self-Marked</span>
                            }
                            {
                                resultData.answerStatus !== 'inprogress' ? resultData.answerStatus : 'Skipped'
                            }
                        </h3>
                    }
                    {
                        resultData.credit > 0 && resultData.answerStatus === "correct" &&
                        <div className={tests.credit}>+ {creditIcon(resultData.credit)}</div>
                    }
                    {
                        resultData.studentAnswer && resultData.studentAnswer.length > 0 && 
                        <div className={tests.studentAnswer}>
                            <label>Student's Answer</label>
                            <ul>
                                {
                                    resultData.studentAnswer && resultData.studentAnswer.length > 0 &&
                                    resultData.studentAnswer.map((item,index) => {
                                        if (item.startsWith('http') && testData.answerType === 'recording') { 
                                            return <FaFileAudio size={50} color='#434343' key={`audo${index}`} />
                                        }
                                        if (item.startsWith('http') && testData.answerType !== 'recording') { 
                                            return <FaFileAlt size={50} color='#434343' key={`audo${index}`} />
                                        }
                                        return <li key={`your answer ${index}`} dangerouslySetInnerHTML={{__html: testData.answerType === 'choice' ?  parseInt(item)+1  : item}}></li>
                                    })
                                }
                            </ul>
                        </div>
                    }
                    {
                        resultData.correctAnswer && resultData.correctAnswer.length > 0 && resultData.questionType !== 'openEnded' &&
                        <div className={tests.correct}>
                            <label>Correct Answer</label>
                            <ul>
                                {
                                    resultData.correctAnswer && resultData.correctAnswer.length > 0 && resultData.correctAnswer.map((item, index) => item !=='' && <li key={`correct anwser ${index}`} dangerouslySetInnerHTML={{__html: testData && testData.answerType === 'choice' ? parseInt(item)+1 : item}}></li>)
                                }
                            </ul>
                        </div>
                    }
                    {/* {
                        resultData.explanation && !showSelfMarked &&
                        <div className={tests.explanation}>
                            <h3>Explanation</h3>
                            <div dangerouslySetInnerHTML={{__html: resultData.explanation}}></div>
                        </div>
                    } */}
                    {
                        resultData.answerStatus === "manual" &&
                        <div className={tests.manualInfo}>
                            <p>Teacher will mark this question and the result will be sent to your dashboard!</p>
                        </div>
                    }
                    {
                        resultData.answerStatus === "disagree" && resultData.studentFeedback &&
                        <div className={tests.manualInfo}>
                            <p>Thanks for your feedback!</p>
                        </div>
                    }
                    <div className='buttons'>
                        {
                            testData.nextQuestionId &&
                            <button className='btn redBtn' onClick={continueOnClick}>Continue</button>
                        }
                        {
                            !isPreview &&
                            <button onClick={() => openModal2 && openModal2('preComplete')} className={ testData.nextQuestionId ? 'btn skipBtn' : 'btn redBtn'}>Complete</button>
                        }
                    </div>
                    
                </div>
            }
        </div>
    )
}

export default TestAnswer;