import QuestionDetails from '../../../components/questionDetails';

const QuestionReview = ({lib, formData}) => {
    return (
        <div className={lib.autoScroll}>
            <QuestionDetails item={formData} />
        </div>
    )
}

export default QuestionReview;