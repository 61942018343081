import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import API_URL from '../../../common/urls';
import { getError} from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import {toast} from 'react-toastify';
import { useStore } from '../../../store/useStore';
import assign from './styles/assign.module.scss';
import meeting from './styles/meeting.module.scss';
import { Loader } from '../../../components/loading';
import {uniqueId} from 'lodash';
import classNames from 'classnames';
import {MdFastForward, MdDone, MdClose} from 'react-icons/md';
import QuestionDetail from './components/questionDetails';

export const QuestionsList = ({list, studentId, isInResult, className, assignId, getAssigment}) => {
    return (
        <ul className={className}>
            {
                list && list.length > 0 && list.map((item,index) => 
                    <li key={uniqueId()}>
                        <div className={meeting.status}>
                            <strong>Question {index + 1}</strong>
                            {studentId && item.result === "correct" &&
                                <MdDone className={meeting.correct} title="Correct"/>
                            }
                            {studentId && item.result === "incorrect" &&
                                <MdClose className={meeting.incorrect} title="Incorrect" />
                            }
                            {studentId && item.result === "skip" &&
                                <MdFastForward className={meeting.skip} title="Skip" />
                            }
                            {studentId && item.result === "timeout" &&
                                <span className={meeting.timeout} title="Timeout">Timeout</span>
                            }
                            {studentId && item.result.includes("%") &&
                                <span className={meeting.score} title="Timeout">{item.result}</span>
                            }
                            {studentId && item.result === "pending" &&
                                <span className={meeting.pending} title="Timeout">pending...</span>
                            }
                        </div>
                        
                        <div className={meeting.question}>
                            <QuestionDetail
                                item={item} 
                                index={index} 
                                isInResult={isInResult}
                                studentId={studentId}
                                assignId={assignId}
                                getAssigment={getAssigment}
                            />
                        </div>
                    </li>
                )
            }
        </ul>
        )
}

const AssignmentReview = ({assignId, studentId}) => {
    const {setUser, user} = useStore();
    const history = useHistory();
    const [assignment, setAssignment] = useState();
    const [loading, setLoading] = useState();
    const [contentLoading, setContentLoading] = useState();
    const [questionId, setQuestionId] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [questionContent, setQuestionContent] = useState();

    useEffect(()=>{    
        assignId && getAssigment();

    },[assignId]);

    useEffect(()=>{    
        questionId && getQuestionContent(questionId);
    },[questionId]);


    const getQuestionContent = (id) => {
        setContentLoading(true);
        return axios.get(API_URL.GET_USER_ASSIGNMENT_QUESTION_URL,{
            headers: authHeader(),
            params: {
                studentId: studentId,
                assignId: assignId,
                questionId: id,
                isReview: true
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setQuestionContent(response.data);
                 setContentLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setContentLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const getAssigment = () => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_ASSIGNMENT_URL,{
            headers: authHeader(),
            params: {
                studentId: studentId,
                assignId: assignId,
                isReview: true
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setAssignment(response.data);
                 response.data.questions && response.data.questions.length > 0 && !questionId && setQuestionId(response.data.questions[0].questionId)
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={assign.assignReview}>
            {loading && <Loader />}
            {
                !loading && assignment &&
                <>
                    <h3 className={meeting.title}>{assignment.name} {assignment.isHomeWork ? <span>Homework</span>: ""}</h3>
                    <div className={meeting.flex}>
                        <div className={meeting.leftContent}>
                            {
                                studentId &&
                                <ul className={meeting.topInfo}>
                                    {
                                        assignment.totalQuestions &&
                                        <li>
                                            <label>Total questions</label><strong>{assignment.totalQuestions}</strong>
                                        </li>
                                    }
                                    {
                                        <li>
                                            <label>Auto-mark Passed</label>
                                            <strong>
                                                {assignment.rightAnswers} 
                                                {/* ({Math.floor(parseInt(assignment.rightAnswers)/parseInt(assignment.totalAutoQuestions ? assignment.totalAutoQuestions : assignment.totalQuestions)*100)}%) */}
                                            </strong>
                                        </li>
                                    }
                                    {
                                        <li>
                                            <label>Manual-mark Marked</label>
                                            <strong>
                                                {assignment.manualResults && assignment.manualResults.marked ? assignment.manualResults.marked : 0}
                                            </strong>
                                        </li>
                                    }
                                    {
                                        <li>
                                            <label>Manual-mark Pending</label>
                                            <strong>
                                                {assignment.manualResults && assignment.manualResults.pending ? assignment.manualResults.pending : 0}
                                            </strong>
                                        </li>
                                    }
                                </ul>
                            }
                            {
                                assignment.questions && assignment.questions.length > 0 && 
                                <div className={meeting.questionsLinks}>
                                    {assignment.questions.map((item,index) =>
                                        <a href="#" className={questionId === item.questionId ? meeting.active : ''} onClick={() => setQuestionId(item.questionId)} key={uniqueId()}>
                                            <label>Question {index+1}</label>
                                            {studentId && item.result === "correct" &&
                                                <MdDone className={meeting.correct} title="Correct"/>
                                            }
                                            {studentId && item.result === "incorrect" &&
                                                <MdClose className={meeting.incorrect} title="Incorrect" />
                                            }
                                            {studentId && item.result === "skip" &&
                                                <MdFastForward className={meeting.skip} title="Skip" />
                                            }
                                            {studentId && item.result === "timeout" &&
                                                <span className={meeting.timeout} title="Timeout">Timeout</span>
                                            }
                                            {studentId && item.result && item.result.includes("%") &&
                                                <span className={meeting.score} title="Timeout">{item.result}</span>
                                            }
                                            {studentId && item.result === "pending" &&
                                                <span className={meeting.pending} title="Timeout">pending</span>
                                            }
                                        </a>
                                    )}
                                </div>
                            }
                        </div>
                        <div className={meeting.mainContent}>
                            {
                                contentLoading &&
                                <Loader />
                            }
                            {
                                !contentLoading &&
                                <>
                                <div className={meeting.status}>
                                    <strong>Question {currentIndex + 1}</strong>
                                    {studentId && questionContent.result === "correct" &&
                                        <MdDone className={meeting.correct} title="Correct"/>
                                    }
                                    {studentId && questionContent.result === "incorrect" &&
                                        <MdClose className={meeting.incorrect} title="Incorrect" />
                                    }
                                    {studentId && questionContent.result === "skip" &&
                                        <MdFastForward className={meeting.skip} title="Skip" />
                                    }
                                    {studentId && questionContent.result === "timeout" &&
                                        <span className={meeting.timeout} title="Timeout">Timeout</span>
                                    }
                                    {studentId && questionContent.result.includes("%") &&
                                        <span className={meeting.score} title="Timeout">{questionContent.result}</span>
                                    }
                                    {studentId && questionContent.result === "pending" &&
                                        <span className={meeting.pending} title="Timeout">pending...</span>
                                    }
                                    
                                </div>
                                <QuestionDetail
                                    item={questionContent} 
                                    index={0} 
                                    isInResult={false}
                                    studentId={studentId}
                                    assignId={assignId}
                                    getAssigment={getAssigment}
                                    setCurrentQuestionId={setQuestionId}
                                    getQuestionContent={getQuestionContent}
                                />
                                </>
                            }
                            
                        </div>
                    </div>
                </>
            }
            
        </div>
    )
}

export default AssignmentReview;