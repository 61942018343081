import styles from './styles.module.scss';
import {uniqueId} from 'lodash';

const Steps = ({list, currentStep, setCurrentStep, isInline, condition, hideStep}) => {

    const stepOnClick = (id) => {
        if(condition && condition(id - 1)) {
            setCurrentStep && setCurrentStep(id);
        }
    }

    return (
        <div className={isInline ? styles.steps : styles.steps2}>
            <ul>
                {list && list.length > 0 && 
                    list.map((step, index) => 
                        <li
                            className={currentStep === step.id ? styles.active : ''}
                            onClick={() =>  stepOnClick(step.id)}
                            key={uniqueId()}
                            style={{display: hideStep && (index+1) === hideStep ? 'none' : ''}}
                        >
                            <label>{step.name}</label>
                            <span>{step.id}</span>
                        </li>    
                    )
                }
            </ul>
        </div>
    )
}

export default Steps;