import axios from 'axios';
import classNames from 'classnames';
import { useRef, useState, useEffect } from 'react';
import { MdBookmark, MdBookmarkBorder, MdClose, MdList, MdNote, MdThumbUpAlt, MdThumbDownAlt, MdOutlineThumbUpAlt, MdOutlineThumbDownAlt } from 'react-icons/md';
import styles from './styles.module.scss';
import { useStore } from '../../../store/useStore';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { toast } from 'react-toastify';
import API_URL from '../../../common/urls';
import { Loader } from '../../../components/loading';

const VideoView = ({id, url, subject, showModal, feedback}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [showList, setShowList]  = useState('');
    const [videosList, setVideosList] = useState(null);
    const [notesList, setNotesList] = useState(null);
    const [curUrl, setCurUrl] = useState(url);
    const [curId, setCurId] = useState(id);
    const [loading, setLoading] = useState();
    const [showNoteBox, setShowNoteBox] = useState(false);
    const [newNoteContent, setNewNoteContent] = useState('');
    const [fb, setFb] = useState(feedback);
    const videoRef = useRef(null);

    useEffect(()=>{
        showList === "video" && getList("video");
        showList === "bookmark" && getList("video");
        showList === "note" && getList("note");
    },[showList]);

    useEffect(()=>{
        setCurUrl(url);
        setCurId(id);
        setFb(feedback);
    },[id, url, feedback]);

    useEffect(()=>{
        if(!showModal) {
            videoRef.current.pause();
            setShowList(null);
            setShowNoteBox(false);
            setNewNoteContent('');
        } else {
            videoRef.current.load();
        }
    },[showModal]);

    const getList = (type) => {
        setLoading(true);
        const url = type === "video" ? API_URL.GET_TOPIC_VIDEOS_LIST_URL : API_URL.GET_TOPIC_NOTES_LIST_URL;
        return axios.get(url,{
            headers: authHeader(),
            params:{
                subject,
                videoId: type === "note" ? curId : undefined,
                isBookmark: type === "video" ? showList === "bookmark" : undefined
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.list) {
                type === "video" && setVideosList(response.data.list);
                type === "note" && setNotesList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const openVideo = (item) => {
        setCurUrl(item.url);
        setCurId(item.id);
        setFb(item.feedback);
        videoRef.current.load();
    } 

    const update = (type, videoId, item, topicItem) => {
        if (type === "addNote") {
            const time = videoRef.current.currentTime;
            const newNote = {
                time,
                content: newNoteContent
            };
            notesList.push(newNote);
            setNotesList([...notesList]);
            setShowNoteBox(false);
        }

        if (type === "removeNote") {
            const notesListContent = notesList.map(item => item.content);
            const index = notesListContent.indexOf(item.content);
            notesList.splice(index,1);
            setNotesList([...notesList]);
        }

        const data = (type === "addNote" || type === "removeNote") ? { noteContent: notesList } : {};
    
        return axios.post(API_URL.POST_DAILY_MISSION_ITEM_URL, data,
            {
                headers: authHeader(),
                params: {
                    type: type === "removeNote" ? "addNote" : type,
                    id: videoId
                }
            }).then(response => {
                if (type === "bookmark" || type === "unbookmark") {
                    const index = topicItem.videos.indexOf(item);

                    const newItem = {...item, isBookmarked: !item.isBookmarked};
                    topicItem.videos.splice(index, 1, newItem);
                    setVideosList([...videosList]);
                }

                if (type.includes('feedback')) {
                    type === 'feedback-none' ? setFb(null) : setFb(type);
                }
            })
            .catch(error => {
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }      
            });
    }

    const playVideoAt = (time) => {
        videoRef.current.currentTime = time;
    }

    const changeControlView = (type) => {
        if(showList === type) {
            setShowList(null);
        } else {
            setShowList(type);
            setLoading(true);
        }        
    }

    return (
        <div className={styles.videoViewer}>
            <div className={styles.controls}>
                <MdList size={30} title='List of Videos' onClick={() => changeControlView('video')} className={showList === "video" ? styles.active : undefined}/>
                <MdBookmark size={24} title='List of Bookmarked Videos' onClick={() => changeControlView('bookmark')} className={showList === "bookmark" ? styles.active : undefined} />
                <MdNote size={24} title='List of Notes' onClick={() => changeControlView('note')} className={showList === "note" ? styles.active : undefined} />
            </div>

            <div className={styles.bottomControls}>
                {
                    fb === 'feedback-like' &&
                    <MdThumbUpAlt
                        size={27} 
                        title='Remove feedback this question'
                        onClick={()=> update('feedback-none', curId)}
                    />
                }
                {
                    fb !== 'feedback-like' &&
                    <MdOutlineThumbUpAlt
                        size={27} 
                        title='Like this question'
                        onClick={()=> update('feedback-like', curId)}
                    />
                }
                {
                    fb === 'feedback-dislike' &&
                    <MdThumbDownAlt
                        size={27} 
                        title='Remove feedback this question'
                        onClick={()=> update('feedback-none', curId)}
                    />
                }
                {
                    fb !== 'feedback-dislike' &&
                    <MdOutlineThumbDownAlt
                        size={27} 
                        title='Dislike this question'
                        onClick={()=> update('feedback-dislike', curId)}
                    />
                }
            </div>
            
            <div className={classNames(styles.videosList, {[styles.show]: showList})}>
                {
                    loading && <Loader />
                }
                {
                    !loading && ((videosList && videosList.length === 0 && ["video","bookmark"].includes(showList)) || (notesList && notesList.length === 0)) &&
                    <div>
                        <p>No item found.</p>
                        {
                            showList === "note" &&
                            <ul>
                                <li className={styles.new} onClick={() => setShowNoteBox(true)}>Add Note</li>
                            </ul>
                        }
                    </div>
                }
                {
                    !loading && videosList && videosList.length > 0 && ["video","bookmark"].includes(showList) &&
                    <ul>
                        {videosList.map((item,index) => 
                            <li key={`topic ${index}`}>
                                <div>{item.name}</div>
                                {
                                    item.videos && item.videos.length === 0 &&
                                    <p>No videos found.</p>
                                }
                                {
                                    item.videos && item.videos.length > 0 &&
                                    <ul>
                                        {
                                            item.videos.map((itm, index) => 
                                                <li className={itm.id === curId ? styles.active : undefined} key={`video ${index}`}>
                                                    <span onClick={() => openVideo(itm)}>{itm.name ? itm.name : 'Video ' + (index + 1)}</span>
                                                    {
                                                        itm.isBookmarked &&
                                                        <MdBookmark title='UnBookmark this video' onClick={() => update('unbookmark', itm.id, itm, item)} />
                                                    }
                                                    {
                                                        !itm.isBookmarked &&
                                                        <MdBookmarkBorder title='Bookmark this video' onClick={() => update('bookmark', itm.id, itm, item)}/>
                                                    }
                                                </li>
                                            )
                                        }
                                    </ul>
                                }
                            </li>)
                        }
                    </ul>
                }
                {
                    !loading && notesList && notesList.length > 0 && showList === "note" &&
                    <ul>
                        {notesList.map((item,index) => 
                            <li className={styles.noteItem} key={`note ${index}`}>
                                <div className={styles.time}>Time: <span onClick={() => playVideoAt(item.time)}>{item.time.toFixed(2)}</span> (s) <MdClose onClick={() => update('removeNote', curId, item)}/></div>
                                <div className={styles.noteContent}>{item.content}</div>
                            </li>)
                        }
                        <li className={styles.new} onClick={() => setShowNoteBox(true)}>Add Note</li>
                    </ul>
                }
            </div>
            
            <video height="95%" controls autoPlay controlsList="nodownload" ref={videoRef}>
                <source src={curUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {
                showNoteBox &&
                <div className={styles.addNoteBox}>
                    <div className={styles.title}>
                        New Note
                        <MdClose onClick={()=> setShowNoteBox(false)}/>
                    </div>
                    <div>
                        <textarea onChange={e => setNewNoteContent(e.target.value)}/>
                        <div className='buttons'>
                            <button className='btn' onClick={() => update('addNote', curId)} disabled={!newNoteContent}>Save</button>
                        </div>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default VideoView;