import { forwardRef, useState } from "react";
import { OPTIONS_TYPE } from "../../../common/utils";
import DateRangePicker from "../../../components/day-range";
import Input from '../../../components/input';

const QuestionFilter = forwardRef(({lib, filtersData, filters, setFilters, setShowFilter}, ref) => {

    const [filter, setFilter]  = useState(filters);

    const onDateRangeChange = (from, to) => setFilter({...filter, fromDate:from, toDate: to});
    const levelOnChange = (type) => setFilter({...filter, levels: type});
    const subjectOnChange = (type) => setFilter({...filter, subjects: type});
    const typeOnChange = (type) => setFilter({...filter, questionType: type});
    const authorOnChange = (type) => setFilter({...filter, authors: type});
    const topicsOnChange = (type) => setFilter({...filter, topics: type});
    const subTopicsOnChange = (type) => setFilter({...filter, subTopics: type});
    const tagsOnChange = (type) => setFilter({...filter, tags: type});
    const searchOnChange = (e) => setFilter({...filter, search: e.target.value});

    const onApplyFilter = () => {
        setFilters && setFilters(filter);
        setShowFilter && setShowFilter(false);
    }

    return (
    <div className={lib.filtersOption} ref={ref}>
        <Input
            type="text" 
            placeholder="Search" 
            className={lib.searchBox}
            onChange={searchOnChange}
            value={filter.search}
            label="Search for name, title, content"
        />
        <div className={lib.flex}>
            <div>
                <div>
                    <label>Date</label>
                    <DateRangePicker
                        fromDate={filter.fromDate}
                        toDate={filter.toDate}
                        onChange={onDateRangeChange}
                        className={lib.datePicker}
                    />
                </div>
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.authors}
                    placeholder=''
                    onChange={authorOnChange}
                    value={filter.authors}
                    label="Author"
                    isMulti
                />
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.levels}
                    placeholder=''
                    onChange={levelOnChange}
                    value={filter.levels}
                    label="Levels"
                    isMulti={true}
                />
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.subjects}
                    placeholder=''
                    onChange={subjectOnChange}
                    value={filter.subjects}
                    label="Subjects"
                    isMulti={true}
                />
            </div>
            <div>
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.topics}
                    placeholder=''
                    onChange={topicsOnChange}
                    value={filter.topics}
                    label="Topics"
                    isMulti={true}
                />
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.subTopics}
                    placeholder=''
                    onChange={subTopicsOnChange}
                    value={filter.subTopics}
                    label="Sub Topics"
                    isMulti={true}
                />
                <Input
                    className={lib.select}
                    type="select"
                    options={filtersData && filtersData.tags}
                    placeholder=''
                    onChange={tagsOnChange}
                    value={filter.tags}
                    label="Tags"
                    isMulti={true}
                />
                <Input
                    className={lib.select}
                    type="select"
                    options={OPTIONS_TYPE.QUESTIONS_TYPE}
                    placeholder=''
                    onChange={typeOnChange}
                    value={filter.questionType}
                    label="Question Types"
                    isMulti
                />
            </div>
        </div>
        <div className="rightAlign">
            <button className="smallBtn2" onClick={() => onApplyFilter()}>Apply Filters</button>
        </div>
    </div>
        
    )
});

export default QuestionFilter;