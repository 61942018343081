import { useEffect, useState } from "react";
import API_URL from "../../../common/urls";
import { authHeader } from '../../../services/auth-header';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import lib from './lib.module.scss';
import {uniqueId} from 'lodash';

const DeleteItems = ({selectItems, setShowModal, setReloadList}) => {
    const {setUser} = useStore();
    const history = useHistory();

    const onDelete =  () => {
        axios.post(API_URL.POST_LIBRARY_ITEMS_ACTION,{items: selectItems, action: 'delete'},{
            headers: authHeader(),
        })
        .then(response => {
            setReloadList && setReloadList(Math.random().toString(36));
            setShowModal && setShowModal(false);
        })
        .catch(error => {
            const resMessage = getError(error);   
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={lib.delete}>
            <h2>
                Delete Items
            </h2>
            <div>
                Are you sure you want to delete 
                {
                    selectItems.length > 1 ? ' ' + selectItems.length + ' items ' : ''
                }
                {
                    selectItems && selectItems.length > 0 &&
                    selectItems.map((item, index) => <span key={uniqueId()}>{item.name}</span>)
                } ?
            </div>
            <div className="buttons rightAlign">
                <button className='btn' onClick={onDelete}>Confirm</button>
            </div>
        </div>
    )
}

export default DeleteItems;