import {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import classItem from './classItem.module.scss';
import {Link} from 'react-router-dom';
import {MdPerson, MdDateRange, MdAccessTime, MdBookmark, MdShoppingCart, MdAddShoppingCart} from 'react-icons/md';
import CartOptions from './cartOptions';
import useClickOutside from '../../hooks/useClickOutside';
import classNames from 'classnames';
import { useStore } from '../../store/useStore';
import moment from 'moment';
import TimeCount from '../input/timeCount';

const ClassItem = ({data, trial, isCompleted, isMyClass, setShowModal, setModalType, setClassId, 
    setLessionId, setTeacherId, setClassContent, setMeetingInfo, joinClassByApp, isCalendar}) => {
    const history = useHistory();
    const {user, cartItems} = useStore();
    const [showOptions, setShowOptions] = useState(false);
    const [isLeftDropdown, setIsLeftDropdown] = useState(false);
    const [classStatus, setClassStatus] = useState(false);
    const [isClassUpdate, setIsClassUpdate] = useState(false);
    const timeDiff = moment.utc().diff(moment.utc(data.startTime));
    const seconds = Math.floor(Math.abs(timeDiff)/1000);
    const timeDiffEnd = moment.utc().diff(moment.utc(data.endTime));
    const [itemActive, setItemActive] = useState(false);

    const buttonRef = useRef();
    useClickOutside(buttonRef, () => setShowOptions(false));
    useClickOutside(buttonRef, () => setItemActive(false));
    const [isShowTime, setIsShowTime] = useState(false);

    const onSetItemActive = () => {
        setItemActive(!itemActive);
    }

    useEffect(()=>{
        if(seconds > 0) {
            setIsShowTime(true);
        } else {
            setIsShowTime(false);
        }
    },[seconds]);

    useEffect(()=>{
        if (timeDiff < -3600000 || (timeDiffEnd <= 0 && !data.hasStudent)) {
            setClassStatus('upcoming');
        };

        if (timeDiff > -3600000 && timeDiff < -2000 && data.lessionId && data.hasStudent) { // time diff is in 1 hr
            setClassStatus('count');
        }

        if (timeDiff >= -2000 && timeDiffEnd < 2700000 && data.lessionId && data.hasStudent) {
            setClassStatus('open');
        };
        if ((timeDiffEnd > 2700000 && data.lessionId) || (timeDiffEnd > 0 && !data.hasStudent)) {

            // allow class continue 45 mins after end time
            setClassStatus('end');
            setIsClassUpdate(true);
        }

    },[timeDiff, timeDiffEnd]);

    useEffect(()=>{
        if(isClassUpdate === true) {
            // getList(true);
        }
    },[isClassUpdate]);

    const typeClass = (type) => {
        if (type === "Class Full") {
            return classItem.full;
        }

        if (type === "Bootcamp") {
            return classItem.type1;
        }

        if (type === "Standard" || type === "Course" || type === "Interactive Live Class") {
            return classItem.type2;
        }

        if(type === "Trial") {
            return classItem.type3;
        }
    }

    const inCart = (classId) => {
        return cartItems && cartItems.length && cartItems.map(itm => itm.classId).includes(classId);
    }

    const onSetShowOption = () => {
        setShowOptions(!showOptions);
        var boundingLeft = buttonRef.current.getBoundingClientRect().left;
        const deviceWidth = window.innerWidth;
        if(deviceWidth - boundingLeft < 280) {
            setIsLeftDropdown(true);
        }
    }

    const onJoinTrialClick = () => {
        setClassId && setClassId(data.classId);
        setShowModal(true);
    }

    const onTrialTitleClick = () => {
        setClassId && setClassId(null);
        setClassContent && setClassContent({
            title: data.title,
            imageUrl: data.imageUrl,
            classIntro: data.classIntro
        });
        setShowModal(true);
    }

    const onJoinClass = () => {
        if (data.classType.includes("Interactive")) {
            joinClassByApp(data.classId, data.lessionId)
        }
        else
        {
            //setModalType('addMeeting');
            //setShowModal(true);
            setClassId && setClassId(data.classId);
            setLessionId && setLessionId(data.lessionId);
            // set dummy data as now read from db on backend
            setMeetingInfo({meetingNo: '1', pass:'2', type: data.classType})
        }
    }

    const onOpenMore = (e, type) => {
        e.preventDefault();
        setModalType(type);
        setShowModal(true);
        setItemActive(false);
        setClassId(data.classId);
        setTeacherId(data.teacherId);
        setLessionId(data.lessionId);
    }

    const onBuyMore = () => {
        history.push(`/products/classes/list/${data.classId}`);
    }

    const onJoinClassByApp = () => {
        joinClassByApp && joinClassByApp(data.classId, data.lessionId)
    }
    
    return (
        <div className={classNames(classItem.item, {[classItem.myClass]: isMyClass},
            {[classItem.upcoming]: classStatus === "count"}, {[classItem.ongoing]: classStatus === "open"})}>
            <div className={classItem.image}>
                {data.imageUrl && <img src={data.imageUrl} alt="teacher" />}
                {!data.imageUrl && <MdPerson size="150" color="#CCCCCC" />} 
            </div>
            <div className={classItem.info}>
                <h4 ref={buttonRef}>
                    {!trial && 
                        <span>
                            {data.title}
                        </span>
                    }
                    {trial && !isCalendar && 
                        <Link to="#" onClick={onTrialTitleClick}>
                            {data.title}
                        </Link>
                    }

                    {
                        isMyClass && !isCalendar && 
                        <>
                            <button
                                className={classNames(classItem.more,{[classItem.active]: itemActive})} 
                                onClick={onSetItemActive}
                            >
                                &bull;&bull;&bull;
                            </button>
                
                            <div className={classItem.dropdown}>
                                {user && user.userRole === "Teachers" &&
                                    <Link to="#" onClick={(e) => onOpenMore(e, "studentsList")}>Students list</Link>
                                }
                                
                                {user && user.userRole === "Teachers" && user.permitEditClass && classStatus !== "end" && classStatus !== "open" &&
                                    <Link to="#" onClick={(e) => onOpenMore(e, "edit")}>Edit Class</Link>
                                }    

                                {user && user.userRole !== "Teachers" && data.teacherId &&
                                    <Link to="#" onClick={(e) => onOpenMore(e, "teacherInfo")}>Teacher Information</Link>
                                }
                                <Link to="#" onClick={(e) => onOpenMore(e, "classDocuments")}>Materials &amp; Assigments</Link>
                                {user && (user.userRole === "Teachers" || data.lessonsBalance !== 0) && (classStatus === "count" || classStatus === "open") && ((user.userRole === "Teachers" && timeDiff > -600000) || (user.userRole === "Students" && timeDiff > -300000)) &&
                                    <Link to="#" onClick={onJoinClassByApp}>Join Class by App</Link>
                                }

                                {
                                    user && user.userRole === "Teachers" && classStatus === "open" && 
                                    <Link to="#" onClick={(e) => onOpenMore(e, "classReport")}>Lesson Report</Link>
                                }

                                {
                                    user && user.userRole !== "Teachers" && classStatus === "open" && 
                                    <Link to="#" onClick={(e) => onOpenMore(e, "classFeedback")}>Lesson Feedback</Link>
                                }
                            </div>
                        </>
                    }
                </h4>
                {data.grade && <p><MdBookmark size="18" /> {data.grade}</p>}
                {data.teacher && <p><MdPerson size="18" /> Teacher {data.teacher}</p>}
                {data.startTime && <p><MdDateRange size="18" /> {moment.utc(data.startTime).local().format('MMMM DD, YYYY (dddd)')}</p>}
                {(data.time || data.startTime) && <p><MdAccessTime size="18" />&nbsp;
                    {data.startTime && data.endTime &&
                        <>
                        {moment.utc(data.startTime).local().format('hh:mm a')} - {moment.utc(data.endTime).local().format('hh:mm a')}
                        </>
                    }
                    {!data.startTime && !data.endTime &&
                        <>{data.time}</>
                    }
                </p>}
            </div>
            <div className={classNames(classItem.btInfo, 
                    {[classItem.expired]: user && user.userRole !== "Teachers" && data.lessonsBalance === 0, [classItem.buyMore]: user && user.userRole !== "Teachers" && data.lessonsBalance === 1})}
                title={user && user.userRole !== "Teachers" && data.lessonsBalance === 0 ? "Please buy more lessons to join" : user && user.userRole !== "Teachers" && data.lessonsBalance === 1 ?
                 "Classes are running out, please buy more lessons to join fully course" : data.lessonsBalance <= 3 ? "Buy more lessons": ""}
                >
                {!isCompleted &&
                <div className={classItem.type}>
                    <span className={typeClass(data.type)}>{data.type}</span>
                </div>
                }

                {
                    !isMyClass && 
                    <strong>
                        {data.price}
                        {!trial && data.quantity &&
                            <span>{data.quantity}</span>
                        }
                    </strong>
                }
                
                {
                    trial && !isMyClass && 
                    <span className={classItem.trialOldPrice}>{data.oldPrice}</span>
                }

                {!trial && !isMyClass && ((user && user.userRole !== "Teachers") || !user) &&
                    <div 
                        className={classNames(classItem.addToCart, {[classItem.isInCart]: inCart(data.classId)})} 
                        ref={buttonRef} 
                        style={{display: data.type === "Class Full" ? "none" : ""}}
                    >
                        <button className={classItem.cartBtn} onClick={onSetShowOption}>
                            <MdShoppingCart size={20} />
                        </button>
                        <CartOptions 
                            productData={data}
                            showOptions={showOptions}
                            setShowOptions={setShowOptions}
                            classId={data.classId}
                            isInCart={inCart(data.classId)}
                            isLeftDropdown={isLeftDropdown}
                        />
                    </div>
                }

                {trial && !isMyClass && !(user && user.userRole === "Teachers") && 
                    <button className={classItem.trialButton} onClick={onJoinTrialClick}>
                        Join Trial
                    </button>
                }

                {
                    isMyClass && classStatus === "upcoming" &&
                    <div className={classItem.myClass}>
                        <p>Class {timeDiff < 0 ? "will be" : "has been"} started</p>
                        <strong>{moment.utc(data.startTime).local().startOf('seconds').fromNow()}</strong>
                    </div>
                }
                
                {
                    isMyClass && classStatus === "count" &&  
                    <div className={classItem.myClass}>
                        <p>Class will be started in</p>
                        <TimeCount 
                            seconds={seconds} 
                            setIsShowTime={setIsShowTime} 
                            messageTimeOut='' 
                            style={{display: isShowTime ? "block": "none"}}
                            setClassStatus={setClassStatus}
                            isFromClassItem={true}
                        />
                        {
                            user.userRole !== "Teachers" && data.lessonsBalance === 0 && <span>Please buy more to join</span>
                        }
                        {user && (user.userRole === "Teachers" || data.lessonsBalance !== 0) && ((user.userRole === "Teachers" && timeDiff > -600000) || (user.userRole === "Students" && timeDiff > -300000)) &&
                            <button className={classNames("btn",{[classItem.interactive]:data.classType.includes("Interactive")})} onClick={onJoinClass}>Start Class Now</button>
                        }
                    </div>
                }

                {
                    isMyClass && classStatus === "open"  && 
                    <div className={classItem.myClass}>
                        <p className={classItem.started}>Class is ongoing</p>
                        {
                            user.userRole !== "Teachers" && data.lessonsBalance === 0 && <span>Please buy more to join</span>
                        }
                        {
                            (user.userRole === "Teachers" || data.lessonsBalance !== 0) && 
                        <button onClick={onJoinClass} className={classNames(classItem.join,{[classItem.interactive]:data.classType.includes("Interactive")})}>Join Class Now</button>
                        }
                    </div>
                }

                {
                    isMyClass && classStatus === "end"  && 
                    <div className={classItem.myClass}>
                        <p className={classItem.started}>Class has ended {moment.utc(data.endTime).local().startOf('seconds').fromNow()}</p>
                    </div>
                }

                {
                    user && user.userRole !== "Teachers" && data.lessonsBalance <= 3 &&
                    <button className={classItem.cartBtn} onClick={onBuyMore}>
                        <MdAddShoppingCart size={20} />
                    </button>
                }
            </div>
        </div>
    )
}

export default ClassItem;