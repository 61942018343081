import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import meeting from "./styles/meeting.module.scss";

const MeetingEnd = () => {
    const history = useHistory();
    useEffect(()=>{
        setTimeout(()=> {
            history.push('/user/classes');
        },30000);
    },[]);

    return (
        <div className={meeting.meeting}>
            <div className={meeting.notAvailable}>
                Your meeting is ended.
            </div>
        </div>
    )
}

export default MeetingEnd;