import {useState} from 'react';
import {toast} from 'react-toastify';
import {isEqual} from 'lodash';
import axios from 'axios';
import API_URL from '../../../common/urls';
import profile from './profile.module.scss';
import Input from '../../../components/input';
import {getError} from '../../../common/utils';
import {useStore} from '../../../store/useStore';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import {useHistory} from 'react-router-dom';

const ChangePassword =  () => {
    const history = useHistory();
    const {setUser} = useStore();
    const [oldPass, setOldPass] = useState();
    const [password, setPassword] = useState();
    const [pasConfirm, setPassConfirm] = useState();

    const oldPassOnChange = (e) => setOldPass(e.target.value);
    const passwordOnChange = (e) => setPassword(e.target.value);
    const passwordConfirmOnChange = (e) => setPassConfirm(e.target.value);

    const onUpdate = () => {
        if(!oldPass) {
            toast.error(`Old Password is empty!`);
            return;
        }

        if(!password) {
            toast.error(`New Password is empty!`);
            return;
        }

        if(password.length < 6) {
            toast.error(`Password at least 6 characters!`);
            return;
        }

        if(isEqual(password, oldPass)) {
            toast.error(`New Password has to be different from old password!`);
            return;
        }

        if(!isEqual(password, pasConfirm)) {
            toast.error(`Password confirmation is not equal password!`);
            return;
        }

        axios.post(API_URL.CHANGE_PASS_URL,{oldPass, password},{
            headers: authHeader()
        })
        .then(response => {
            toast.success('Your password has been changed.');
            setUser(response.data);
            localStorage.setItem("user", JSON.stringify(response.data));
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);  
            }
        })
    }

    return (
        <div>
            <Input 
                type="pass-with-view" 
                placeholder="" 
                onChange={oldPassOnChange}
                className={profile.password}
                label="Current password"
            />

            <Input 
                type="pass-with-view" 
                placeholder="" 
                onChange={passwordOnChange}
                className={profile.password}
                label="New password (at least 6 characters)"
            />
            <Input 
                type="pass-with-view" 
                placeholder="" 
                onChange={passwordConfirmOnChange}
                className={profile.password}
                label="Confirm new password"
            />
            <div>
                <button className="btn" onClick={onUpdate}>Update</button>
            </div>
        </div>
    )
}

export default ChangePassword;