import styles from './styles.module.scss';
import education from '../../images/education.avif';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

const Price = ({data, buyOnClick}) => {
    const history = useHistory();
    return (
        <div className={styles.pricing}>
            <p id='pricing' style={{height: 30}}></p>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            <div>
                <img src={data && data.imageUrl ? data.imageUrl : education} alt='education' />
                <div className={styles.data}>
                    <h3 className={styles.title}>{data && data.title}</h3>
                    {
                        data && data.newPrice &&
                        <div className={styles.price}>
                            {
                                data && data.offerMessage &&
                                <div className={styles.offers} dangerouslySetInnerHTML={{__html: data.offerMessage}} />
                            }
                            <div className={styles.priceBox}>
                                <span>{data && data.currency}</span>{data.newPrice} <span className={styles.unit}>{data && data.unit}</span>
                                <div className={styles.oldPrice}>{data && data.currency} {data && data.price}</div>
                            </div>
                        </div>
                    }
                    {
                        data && !data.newPrice &&
                        <div className={classNames(styles.price, styles.noOffer)}>
                            <div className={styles.priceBox}>
                            <span>{data && data.currency}</span>{data && data.price} <span className={styles.unit}>{data && data.unit}</span>
                            </div>
                        </div>
                    }
                    <div className={styles.content} dangerouslySetInnerHTML={{__html: data && data.content}}/>
                    {
                        data && data.listItems && data.listItems.length > 0 &&
                        <ul>
                            {
                                data.listItems.map((item, index) =>
                                    <li key={`price${index}`}><FaCheckCircle color='green' /> {item}</li>
                                )
                            }
                        </ul>
                    }
                    <div className='homeBtns buttons'>
                        <a className='homeBtn btn' href='#howStart' onClick={buyOnClick}>Buy Now</a>
                        <button onClick={() => history.push('/register')}>Start Free Trial</button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Price;