import { useState, useRef, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import Modal from "../../components/modal";

const CustomTheme = ({showModal, setShowModal, styles, customValue, setCustomValue, updateTheme}) => {
    const contentRef = useRef();
    const uploadRef = useRef();    

    const colorOnChange = (e) => {
        setCustomValue && setCustomValue({...customValue, color: e.target.value});
    }

    const onChangePicture = (e) => {
        if (e.target.files.length) {
            if (e.target.files.length + customValue.images.length <=5) {
                for(let i=0; i< e.target.files.length; i++) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        const obj = {
                            url: reader.result,
                            repeat: 'no-repeat',
                            position: 'left top',
                            size: 'auto'
                        };
                        customValue.images.push(obj);
                        setCustomValue && setCustomValue({...customValue});
                    });
                    reader.readAsDataURL(e.target.files[i]);
                };
            } else {
                toast.error('Maximum 5 images! Please try again.');
            }
        }
    }

    const bgSizeOnChange = (value, index) => {
        customValue.images[index].size = value;
        setCustomValue && setCustomValue({...customValue});
    }

    const bgSizeCustomOnChange = (e, index) => {
        customValue.images[index].size = e.target.value +'px';
        setCustomValue && setCustomValue({...customValue});
    }

    const bgRepeatOnchange = (value, index) => {
        customValue.images[index].repeat = value;
        setCustomValue && setCustomValue({...customValue});
    }

    const bgPositionOnChange = (value, index) => {
        customValue.images[index].position = value;
        setCustomValue && setCustomValue({...customValue});
    }

    const boxColorOnChange = (e) => {
        if (customValue.box) {
            customValue.box.boxColor = e.target.value;
            setCustomValue && setCustomValue({...customValue});
        }
    }

    const textColorOnChange = (e) => {
        if (customValue.box) {
            customValue.box.textColor = e.target.value;
            setCustomValue && setCustomValue({...customValue});
        }
    }

    const shadowColorOnChange = (e) => {
        if (customValue.box) {
            customValue.box.shadowColor = e.target.value;
            setCustomValue && setCustomValue({...customValue});
        }
    }

    const boxOpacityOnChange = (e) => {
        if (customValue.box) {
            customValue.box.boxColorOpacity = e.target.value;
            setCustomValue && setCustomValue({...customValue});
        }
    }

    const removeImage = (index) => {
        customValue.images.splice(index,1);
        setCustomValue && setCustomValue({...customValue});
    }

    const boxEffectOnChange = (value) => {
        if (customValue.box) {
            customValue.box.effect = value;
            setCustomValue && setCustomValue({...customValue});
        }
    }

    return (
        <Modal
            setShowModal={setShowModal}
            showModal={showModal}
            contentRef={contentRef}
            width="230px"
            height="100vh"
            type="rightModal"
            content={
                <div ref={contentRef}>
                    <div className={styles.customTheme}>
                        <label>Background</label>
                        <div className={styles.properties}>
                            <div>
                                <span>Color</span>
                                <input type="color" onChange={colorOnChange} value={customValue && customValue.color ? customValue.color : undefined}/>
                            </div>
                            <div className={styles.picture}>
                                <span>Images</span>
                                <input type="file" ref={uploadRef} accept=".png,.jpg, .jpeg, .gif, .svg" onChange={onChangePicture} multiple/>
                                {
                                    customValue && ((customValue.images && customValue.images.length  === 0) || !customValue.images) &&
                                    <button onClick={() => uploadRef.current.click()}>Upload Images</button>
                                }
                                {
                                    customValue && customValue.images && customValue.images.length > 0 &&
                                    <div>
                                        {customValue.images.map((image,index) => 
                                        <div key={`image ${index}`}>
                                            <MdClose onClick={() => removeImage(index)}/>
                                            <img onClick={() => uploadRef.current.click()} src={image.url} />
                                            <div>
                                                <span>Repeat</span>
                                                <ul className="radioList">
                                                    <li className={image.repeat === 'repeat' ? 'active' : undefined} onClick={() => bgRepeatOnchange('repeat', index)}>Both side</li>
                                                    <li className={image.repeat === 'repeat-x' ? 'active' : undefined} onClick={() => bgRepeatOnchange('repeat-x', index)}>Horizontal</li>
                                                    <li className={image.repeat === 'repeat-y' ? 'active' : undefined} onClick={() => bgRepeatOnchange('repeat-y', index)}>Vertical</li>
                                                    <li className={image.repeat === 'no-repeat' ? 'active' : undefined} onClick={() => bgRepeatOnchange('no-repeat', index)}>No Repeat</li>
                                                </ul>
                                                <span>Size</span>
                                                <ul className="radioList">
                                                    <li className={image.size === 'auto' ? 'active' : undefined} onClick={() => bgSizeOnChange('auto', index)}>Real size</li>
                                                    <li className={image.size === 'contain' ? 'active' : undefined} onClick={() => bgSizeOnChange('contain', index)}>Contain</li>
                                                    <li className={image.size === 'cover' ? 'active' : undefined} onClick={() => bgSizeOnChange('cover', index)}>Cover</li>
                                                </ul>
                                                <span>Custom Size</span>
                                                <input type="number" onChange={(e) => bgSizeCustomOnChange(e, index)} value={Number.isInteger(image.size) ? image.size : undefined}/>
                                                <span>Position</span>
                                                <div className={styles.imagePosition}>
                                                    <span className={image.position === "left top" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('left top', index)}></span>
                                                    <span className={image.position === "center top" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('center top', index)}></span>
                                                    <span className={image.position === "right top" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('right top', index)}></span>
                                                    <span className={image.position === "left center" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('left center', index)}></span>
                                                    <span 
                                                        className={image.position === "center center" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('center center', index)}
                                                    ></span>
                                                    <span className={image.position === "right center" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('right center', index)}></span>
                                                    <span className={image.position === "left bottom" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('left bottom', index)}></span>
                                                    <span className={image.position === "center bottom" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('center bottom', index)}></span>
                                                    <span className={image.position === "right bottom" ? styles.active : undefined}
                                                        onClick={() => bgPositionOnChange('right bottom', index)}></span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                }
                                {
                                    customValue && customValue.images && customValue.images.length !== 0 && customValue.images.length < 5 &&
                                    <button onClick={() => uploadRef.current.click()}>Add More Images</button>
                                }
                            </div>
                            
                        </div>
                        <label>Boxes</label>
                        <div className={styles.properties}>
                            <div>
                                <span>Text Color</span>
                                <input type="color" onChange={textColorOnChange} value={customValue && customValue.box && customValue.box.textColor ? customValue.box.textColor : undefined}/>
                            </div>
                            <div>
                                <span>Shadow</span>
                                <input type="color" onChange={shadowColorOnChange} value={customValue && customValue.box && customValue.box.shadowColor ? customValue.box.shadowColor : undefined}/>
                            </div>
                            <div>
                                <span>Bg Color</span>
                                <input type="color" onChange={boxColorOnChange} value={customValue && customValue.box && customValue.box.boxColor ? customValue.box.boxColor : undefined}/>
                            </div>
                            <div>
                                <span>Bg Opacity</span>
                                <input type="range" onChange={boxOpacityOnChange} step={0.1} min={0} max={1} value={customValue && customValue.box && customValue.box.boxColorOpacity ? customValue.box.boxColorOpacity : undefined}/>
                            </div>
                            <div>
                                <span>Effect</span>
                                <ul className="radioList">
                                    <li className={customValue && customValue.box && customValue.box.effect === 0 ? 'active' : undefined} onClick={() => boxEffectOnChange(0)}>No effect</li>
                                    <li className={customValue && customValue.box && customValue.box.effect === 1 ? 'active' : undefined} onClick={() => boxEffectOnChange(1)}>Grey</li>
                                    <li className={customValue && customValue.box && customValue.box.effect === 2 ? 'active' : undefined} onClick={() => boxEffectOnChange(2)}>Blue</li>
                                    <li className={customValue && customValue.box && customValue.box.effect === 3 ? 'active' : undefined} onClick={() => boxEffectOnChange(3)}>Yellow</li>
                                    <li className={customValue && customValue.box && customValue.box.effect === 4 ? 'active' : undefined} onClick={() => boxEffectOnChange(4)}>Red</li>
                                </ul>
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="btn" onClick={updateTheme}>Save Theme</button>
                        </div>
                    </div>  
                </div>
            } />
    )
}

export default CustomTheme;