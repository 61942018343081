import { useState, useEffect } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import { useHistory } from "react-router-dom";
import exam from '../connections/connection2.module.scss';

const ExamHistory = () => {
    const {user, setUser} = useStore();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);

    useEffect(()=>{
        getList();
    },[]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_EXAM_HISTORY_URL,{
            headers: authHeader(),
            params: {
                // type: curTab
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.list && setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const statusColor = (status) => {
        let color = "black";
        switch(status) {
            case "Completed": 
                color = "green";
                break;
            case "Missed": 
                color = "red";
                break;
        };
        return color;
    }

    return (
        <div className={`${exam.giftsTracking} ${exam.challengeHistory}`}>
            {
                loading && <Loader />
            }
            {
                !loading && list && list.length === 0 &&
                <div className='noResult'>
                    No item found.
                </div>
            }
            {
                !loading && list && list.length > 0 &&
                <div>
                    <ul>
                        <li className={exam.title}>
                            <span>Date</span>
                            <span style={{flexGrow: 1}}>Name</span>
                            <span>Status</span>
                            <span>Result</span>
                            <span>Earned Credit</span>
                        </li>
                    {
                        list.map((item, index) => 
                            <li key={`gift-${index}`}>
                                <span>{item.date}</span>
                                <span style={{flexGrow: 1}}>{item.name}</span>
                                
                                <span style={{color: statusColor(item.status)}}>{item.status}</span>
                                <span>{item.result}</span>
                                <span>{item.earnedCredit}</span>
                            </li>
                        )
                    }
                    </ul>
                </div>
            }
        </div>
    )
}

export default ExamHistory;