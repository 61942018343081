import {useState} from 'react';
import modal from './modal.module.scss';
import classNames from 'classnames';
import {MdClose} from 'react-icons/md';
import Draggable from 'react-draggable';
import { useStore } from '../../store/useStore';

const NoteBox = ({note, color, left, top, onClose, isInMeeting, onRemove, index}) => {
    const {notesListPositionValue, setNotesListPositionValue} = useStore();
    const [defaultPosition, setDefaultPosition] = useState(notesListPositionValue[index]);

    const closeBoxHandle = () => {
        onClose && onClose();
    }

    const onRemoveItem = () => {
        onRemove && onRemove();
    }

    return (
        <>
        {
            !isInMeeting &&
            <div className={classNames(modal.noteBox, modal[color])} style={{left: left, top: top}}>
            {
                !onRemove &&
                <div className={classNames(modal.handle, modal[color])}>
                </div>
            }
            <div className={modal.content}>
                <div dangerouslySetInnerHTML={{__html: note.replace(/(\n)/g,'<br />')}} />
            </div>
                {
                    !onRemove &&
                    <button className={modal.close} onClick={closeBoxHandle}>&times;</button>
                }
                {
                    onRemove &&
                    <MdClose className={modal.remove} title="Remove item" onClick={onRemoveItem}/>
                }
            </div>
        }
        
        {isInMeeting &&
            <Draggable
                handle=".handleBox"
                position={null}
                scale={1}
                defaultPosition={defaultPosition}
                onStop={(e, data) => {
                    notesListPositionValue.splice(index, 1, { x: data.x, y: data.y });
                    setNotesListPositionValue([...notesListPositionValue]);
                    }}
            >
                <div className={classNames(modal.noteBox, modal[color], {[modal.isInMeeting]: isInMeeting})} style={{left: left, top: top}}>
                {
                    !onRemove &&
                    <div className={classNames(modal.handle, modal[color],'handleBox')}>
                    </div>
                }
                <div className={modal.content}>
                    <div dangerouslySetInnerHTML={{__html: note.replace(/(\n)/g,'<br />')}} />
                </div>
                    {
                        !onRemove &&
                        <button className={modal.close} onClick={closeBoxHandle}>&times;</button>
                    }
                    {
                        onRemove &&
                        <MdClose className={modal.remove} title="Remove item" onClick={onRemoveItem}/>
                    }
                </div>
            </Draggable>
        }
        </>
    )
}

export default NoteBox;