import {Link, useHistory} from 'react-router-dom';
import styles from '../styles.module.scss';
import LiveClass from './liveClassTab';
import { useStore } from '../../../store/useStore';
import ProficiencyReport from './proficiencyReportTab';
import AssignmentTracking from './assignmentTrackingTab';
import QuickView from './quickViewTab';
import { StudentDetails } from './proficiencyReportTab';
import overview from '../../../images/overview.svg';
import leaderboard from '../../../images/leaderboard.svg';
import tracking from '../../../images/tracking.svg';
import process from '../../../images/process.svg';
import LeaderBoard from './leaderBoard';
import { parseRgb } from '../../../common/utils';
import classNames from 'classnames';

const Dashboard = () => {
    const history = useHistory();
    const {setUser, user, customValue, showBox, setShowBox, activeTab, setActiveTab} = useStore();
    const student = {userName: user.userName};
    
    const boxChange = (tab) => {
        setActiveTab(tab);
        setShowBox(false);
    }

    const tabChange = () => {
        setShowBox(true);
        setActiveTab('');
    }

    return (
        <div>
            {
                activeTab &&
                <div className={styles.tabLinks}>
                    <Link
                        to="#" 
                        onClick={tabChange} 
                        style={{width: activeTab === "Quick View" ? 250 : activeTab  ===  "Leader Board" ? 320 : 400}}
                    >
                        <svg viewBox={activeTab === "Assignment Tracking" ? "0 0 580 60" : activeTab === "Quick View" ?  "0 0 300 60" : activeTab  ===  "Leader Board" ? "0 0 390 55" : "0 0 550 60"}>
                            <text className={styles.svgStroke} x="50%" y="50%">{activeTab}</text>
                            <text className={styles.svgText} x="50%" y="50%">{activeTab}</text>
                        </svg>
                    </Link>
                </div>
            }     
            
            {
                showBox &&
                <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
                    {
                        user.userRole === "Teachers" && 
                        <>
                        <div style={{width: 400}} onClick={() => boxChange('Live Class Reports')} >
                            <div className={styles.color1} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    
                                    className={activeTab === "Live Class Reports" ? styles.active : ""}
                                    style={{height: 400, background:`url(${overview}) no-repeat center 90%/contain`}}
                                >
                                    <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Live Class Reports</strong>
                                </Link>
                            </div>
                        </div>
                        <div style={{width: 300}}>
                            <div className={styles.color2} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Proficiency Report')} 
                                    className={activeTab === "Proficiency Report" ? styles.active : ""}
                                    style={{height: 190, marginBottom: 10, background:`url(${process}) no-repeat center 90%/220px`}}
                                >
                                    <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Proficiency Report</strong>
                                </Link>
                            </div>
                            <div className={styles.color3} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Assignment Tracking')} 
                                    className={activeTab === "Assignment Tracking" ? styles.active : ""}
                                    style={{height: 200, background:`url(${tracking}) no-repeat center 90%/contain`}}
                                >
                                    <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Assignment Tracking</strong>
                                </Link>
                            </div>
                        </div>
                        
                        </>
                    }

                    {
                        (user.userRole === "Students" || user.userRole === "Parents") && 
                        <>
                            <div style={{width: 400}}>
                            <div className={styles.color1} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                    <Link 
                                        to="#" 
                                        onClick={() => boxChange('Quick View')} 
                                        className={activeTab === "Quick View" ? styles.active : ""}
                                        style={{height: 400, background:`url(${overview}) no-repeat center 90%/contain`}}
                                    >
                                        <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Quick View</strong>
                                    </Link>
                                </div>
                            </div>
                            <div style={{width: 300}}>
                                <div className={styles.color2} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                    <Link 
                                        to="#" 
                                        onClick={() => boxChange('Proficiency Report')} 
                                        className={activeTab === "Proficiency Report" ? styles.active : ""}
                                        style={{height: 150, marginBottom: 10, background:`url(${process}) no-repeat center 90%/220px`}}
                                    >
                                        <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Proficiency Report</strong>
                                    </Link>
                                </div>
                                <div className={styles.color3} style={customValue && customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue && customValue.box.boxColorOpacity})`}}>
                                    <Link 
                                        to="#" 
                                        onClick={() => boxChange('Leader Board')} 
                                        className={activeTab === "Leader Board" ? styles.active : ""}
                                        style={{height: 240, background:`url(${leaderboard}) no-repeat center 90%/contain`}}
                                    >
                                        <strong style={customValue && customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Leader Board</strong>
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            {
                !showBox &&
                <div className={styles.whiteBox2}>
                    {activeTab === "Live Class Reports" && <LiveClass />}
                    {activeTab === "Proficiency Report" && user.userRole === "Teachers" && <ProficiencyReport />}
                    {activeTab === "Proficiency Report" && user.userRole !== "Teachers" && 
                        <StudentDetails 
                            student={student} 
                            setUser={setUser} 
                            history={history}
                        />}
                    {activeTab === "Assignment Tracking" && <AssignmentTracking />}
                    {activeTab === "Quick View" && <QuickView />}
                    {activeTab === "Leader Board" && <LeaderBoard />}
                </div>
            }
        </div>
    )
}

export default Dashboard;