import React, {useState, useEffect, useRef} from 'react';
import modal from './modal.module.scss';
import classNames from 'classnames';
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from 'react-icons/md';

const FullModal = ({showModal, content="", setShowModal, width, height, isExpandable}) => {
    const contentRef = useRef();
    const [inExpand, setInExpand] = useState(false);
    const modalClick = (e) => {
        if (!contentRef.current.contains(e.target)) setShowModal(false);
    }

    const closeButtonOnClick = () => {
        setShowModal(false);
    }

    useEffect(()=>{
        !isExpandable && setInExpand(false);
    },[isExpandable]);

    return (
        <div 
            className={classNames(modal.modal, modal.fullModal)}
            style={{display: showModal ? '' : 'none'}} 
            onClick={modalClick}
        >
            <div 
                className={modal.modalContent}
                ref={contentRef}
                style={{width: inExpand ? '100%' : width ,height: inExpand ? '100%' : height}}
            >
                <button className={modal.close} onClick={closeButtonOnClick}>&times;</button>
                {
                    isExpandable && !inExpand &&
                    <MdOutlineFullscreen onClick={() => setInExpand(true)} className={modal.expand}/>
                }
                {
                    isExpandable && inExpand &&
                    <MdOutlineFullscreenExit onClick={() => setInExpand(false)} className={modal.expand}/>
                }
                {content}
                
            </div>
            
        </div>
    )
}

export default FullModal;