import { useEffect, useState } from "react";
import API_URL from "../../../common/urls";
import { authHeader } from '../../../services/auth-header';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import lib from './lib.module.scss';
import FoldersTree from "../../../components/foldersTree";
import { Loader } from "../../../components/loading";
import {uniqueId} from 'lodash';

const CopyItems = ({selectItems, showModal, setShowModal, setReloadList, type, modalType}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState();
    const [selectFolder, setSelectFolder] = useState('');
    const selectIds = selectItems && selectItems.length ? selectItems.map(item => item.id) : [];

    useEffect(()=>{
        setLoading(true);
        axios.get(API_URL.GET_LIBRARY_FOLDERS_URL,{
            headers: authHeader(),
            params: {
                type: type,
            }
        })
        .then(response => {
            if(response.data && response.data.list) {
                setFolders(response.data.list);
            }
            setLoading(false);
        })
        .catch(error => {
            const resMessage = getError(error);   
            setLoading(false);         
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[showModal]);

    const onCopy =  () => {
        const data = {items: selectItems, action: modalType, destination: selectFolder ? selectFolder :  ''};
        axios.post(API_URL.POST_LIBRARY_ITEMS_ACTION,data,{
            headers: authHeader(),
        })
        .then(response => {
            setReloadList && setReloadList(Math.random().toString(36));
            setShowModal && setShowModal(false);
        })
        .catch(error => {
            const resMessage = getError(error);   
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={lib.delete}>
            <h2>
                {modalType === "copy" ? "Copy" : "Move"} Items
            </h2>
            <div>
                <label>Selected Items</label>
                {
                    selectItems && selectItems.length > 0 &&
                    selectItems.map(item => <span key={uniqueId()}>{item.name}</span>)
                }
            </div>
            <div>
                <label>Destination</label>
                <div>
                    {loading && <Loader />}
                    {
                        !loading && 
                        <FoldersTree 
                            folders={folders} 
                            selectFolder={selectFolder} 
                            setSelectFolder={setSelectFolder}
                            disableFolders={selectIds}
                        />
                    }
                </div>
            </div>
            <div className="buttons rightAlign">
                <button className='btn' onClick={onCopy}>Confirm</button>
            </div>
        </div>
    )
}

export default CopyItems;