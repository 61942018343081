import React, {useEffect, useRef, useState} from 'react';
import tests from './tests.module.scss';
import classNames from 'classnames';
import TestContent from './testContent';
import { randomIntFromInterval } from '../../common/utils';
import PrePage from './prePage';
import useQuery from '../../hooks/useQuery';

const TestPlay = ({showModal, setShowModal, themeId, isPreview, worksheetId, questionId, apiUrl}) => {
    // const contentRef = useRef();
    const queryParams = useQuery();
    const learnId = decodeURIComponent(queryParams.get('learnId'));
    const learnType = decodeURIComponent(queryParams.get('learnType'));

    const [preview, setPreview] = useState(isPreview);
    const [inExpand, setInExpand] = useState(false);
    const [refreshTheme, setRefreshTheme] = useState(0);
    const [playTest, setPlayTest] = useState( apiUrl ? true : false);
    // const savedTheme = sessionStorage.getItem('themeId');
    const [theme, setTheme] = useState(themeId);
    const [worksheetTime, setWorksheetTime] = useState(0);
    const closeButtonOnClick = () => {
        setShowModal(false);
    }
    useEffect(()=>{
        refreshTheme && modalStyles(theme);
    },[refreshTheme]);

    useEffect(()=>{
        setTheme(themeId);
    },[themeId]);

    useEffect(()=>{
        !showModal && setPlayTest(false);
    },[showModal]);

    const modalStyles = (theme) => {
        let bg = {modal: '', content: '', corner: '', animation: ''};
        switch(theme) {
            case 'th1':
                const set = ['#F65247','#F0AD28','#F4DC39','#A5CB43','#52AFE6','#565BAF'];
                bg.modal = set[Math.floor(Math.random() * set.length)];
                break;
            case 'th2':
                const set1 = ['#C6D57E', '#B983FF', '#316B83', '#CC7351', '#FF87CA','#316B83'];
                const set2 = ['#94B3FD', '#70AF85', '#A0C1B8', '#E08F62', '#FFC4E1', '#6D8299'];
                const set3 = ['#99A799', '#FFABE1', '#CBAF87', '#DED7B1', '#EAEAEA', '#8CA1A5'];
                const set4 = ['#F6D7A7', '#FAFCC2', '#94DAFF', '#9DAB86', '#EED7CE', '#FF7878'];
                bg.modal = `linear-gradient(to bottom right, 
                    ${set1[Math.floor(Math.random() * set1.length)]}, 
                    ${set2[Math.floor(Math.random() * set1.length)]}, 
                    ${set3[Math.floor(Math.random() * set1.length)]}, 
                    ${set4[Math.floor(Math.random() * set1.length)]})`;
                break;
            case 'th4':    
                bg.content = `url("/themes/pattern1/${randomIntFromInterval(1, 15)}.png") repeat center top / 350px`;
                break;
            case 'th5':    
                const random = randomIntFromInterval(1,4);
                bg.animation = 'mymove 2s';
                bg.content = `url("/themes/animal1/${random}.svg") no-repeat center ${random === 3 || random === 4 ? '20px' : 'top'}`;
                break;
        }
        return bg;
    }

    return(
        <div 
            className={classNames(tests.modal, tests[theme])}
            style={{display: showModal ? '' : 'none', 
            background: modalStyles(theme).modal}} 
        >
            <div 
                className={tests.modalContent}
                style={{ 
                background: modalStyles(theme).content, animation: modalStyles(theme).animation}}
            >   
                {   setShowModal &&
                    <button className={classNames(tests.close, tests.whiteClose)} onClick={closeButtonOnClick}>&times;</button>
                }
                    
                {
                    !playTest && worksheetId && !apiUrl &&
                    <PrePage 
                        tests={tests} 
                        setPlayTest={setPlayTest} 
                        worksheetId={worksheetId} 
                        setTheme={setTheme} 
                        themeId={theme}
                        setWorksheetTime={setWorksheetTime}
                        isPreview={preview}
                        learnId={learnId}
                        learnType={learnType}
                        setPreview={setPreview}
                    /> 
                }
                {
                    playTest &&
                    <TestContent
                        showModal={showModal}
                        setShowModal={setShowModal}
                        isPreview={preview}
                        themeId={theme}
                        setRefreshTheme={setRefreshTheme}
                        refreshTheme={refreshTheme}
                        modalStyles={modalStyles}
                        worksheetId={worksheetId}
                        worksheetTime={worksheetTime}
                        inExpand={inExpand}
                        setInExpand={setInExpand}
                        questionId={questionId}
                        learnId={learnId}
                        learnType={learnType}
                        apiUrl={apiUrl}
                    />
                }
                
            </div>
            
        </div>
    )
}

export default TestPlay;