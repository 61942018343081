// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".percentage_progress__3qLlj {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  background: RGBA(0, 0, 0, 0.8);\n  z-index: 4001;\n  top: 0;\n  left: 0;\n}\n.percentage_progress__3qLlj .percentage_progressContent__2VrpM {\n  width: 700px;\n  margin: 0 auto;\n  color: white;\n  margin-top: 20%;\n}\n.percentage_progress__3qLlj .percentage_progressContent__2VrpM .percentage_progressBar__36wax {\n  height: 12px;\n  background: #ccc;\n  border-radius: 6px;\n}\n.percentage_progress__3qLlj .percentage_progressContent__2VrpM .percentage_progressBar__36wax div {\n  height: 12px;\n  background: #009CC8;\n  border-radius: 6px;\n}", "",{"version":3,"sources":["webpack://src/components/percentage/percentage.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,eAAA;EACA,8BAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;AACJ;AACI;EACI,YAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;AACR;AACQ;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;AACZ;AACY;EACI,YAAA;EACA,mBAAA;EACA,kBAAA;AAChB","sourcesContent":[".progress {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    background: RGBA(0,0,0,0.8);\n    z-index: 4001;\n    top: 0;\n    left: 0;\n\n    .progressContent {\n        width: 700px;\n        margin: 0 auto;\n        color: white;\n        margin-top: 20%;\n\n        .progressBar {\n            height: 12px;\n            background: #ccc;\n            border-radius: 6px;\n\n            div{\n                height: 12px;\n                background: #009CC8;\n                border-radius: 6px;\n              }\n          }\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "percentage_progress__3qLlj",
	"progressContent": "percentage_progressContent__2VrpM",
	"progressBar": "percentage_progressBar__36wax"
};
export default ___CSS_LOADER_EXPORT___;
