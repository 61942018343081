import { MdInfoOutline } from "react-icons/md";
import { useState, useEffect, useRef } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";

const ChallengeResponse = ({type, curItem, connection, setShowModal, list, setList}) => {
    const [reason, setReason] = useState('');
    const {setUser} = useStore();
    const history = useHistory();

    const onReject = () => {
        callApi('reject', reason);
    }

    const onDoLater = () => {
        callApi('accept-later');
    }

    const onDoChallenge = () => {
        callApi('accept-now');
    }

    const callApi = (type, reason) => {
        axios.post(API_URL.USER_CHALLENGES_ACTION_URL,{action: type, id: curItem.id, reason},{
            headers: authHeader(),
        })
        .then(response => {
            setShowModal(false);
            if (type === "accept-now" && response.data.worksheetId) {
                history.push(`testPlay/${response.data.worksheetId}/${response.data.themeId}?learnId=${response.data.learnId}&learnType=${response.data.learnType}`)
            } 

            if (type === "accept-later" || type === "reject") {
                const listId = list.map(item => item.id);
                const index = listId.indexOf(curItem.id);
                const newCurItem = {...curItem, readyToStart: true};
                type === "reject" ? list.splice(index, 1) : list[index] = newCurItem;
                setList([...list]);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setShowModal(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }
    return (
        <div className={connection.response}>
            {
                type === "accept" &&
                <div>
                    <p className="systemInfo"><MdInfoOutline size="20"/>If you don't start the challenge before expire time, you will be consider as loser and lose the betpoints</p>
                    <div className="buttons">
                        <button className="btn" onClick={onDoChallenge}>Start Challenge Now</button>
                        <button onClick={onDoLater}>Start Later</button>
                    </div>
                </div>
            }
           {
               type === "reject" &&
               <div>
                   <div>
                       <label>Reject reason <i>Optional</i></label>
                       <textarea onChange={e => setReason(e.target.value)}/>
                   </div>
                   <div className="buttons">
                        <button className="btn" onClick={onReject}>Submit</button>
                    </div>
               </div>
           }
        </div>
    )
}

export default ChallengeResponse;