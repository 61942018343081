import {useState} from 'react';
import {Link} from 'react-router-dom';
import styles from '../styles.module.scss';
// import MainInfo from './mainInfo';
// import ChangePassword from './changePassword';
// import ProfilePicture from './profilePicture';
import ConnectUsers from './connectUsers';
import useQuery from '../../../hooks/useQuery';
import classNames from 'classnames';
import connections from '../../../images/connections.png';
import gifts from '../../../images/gift.png';
import battle from '../../../images/battle.png';
// import profile from '../../../images/profile.svg';
import changePass from '../../../images/changePass.svg';
import sharing from '../../../images/sharing.svg';
import { MdAccountCircle } from 'react-icons/md';
import { getCurrentUserImage } from '../../../services/auth.service';
import { parseRgb } from '../../../common/utils';
import { useEffect } from 'react';
import Sharing from './sharing';
import { useStore } from '../../../store/useStore';
import Gifts from './gifts';
import Challenges from './challenge';

const Connection = () => {
    const {user, customValue, showBox, setShowBox, activeTab, setActiveTab} = useStore();
    const profileImage = getCurrentUserImage();
    const boxChange = (tab) => {
        setActiveTab(tab);
        setShowBox(false);
    }
    
    const tabChange = () => {
        setShowBox(true);
        setActiveTab('');
    }


    return (
        <div>
            {
                activeTab &&
                <div className={styles.tabLinks}>
                    <Link
                        to="#" 
                        onClick={tabChange} 
                        style={{width: 
                            activeTab === "Connections" ? 300 : 
                            activeTab === "Sharing" ? 220 :
                            activeTab === "Challenges" ? 280 :
                            activeTab === "Gifts" ? 180 : 
                            400}}
                    >
                        <svg viewBox={
                            activeTab === "Connections" ? "0 0 360 60" : 
                            activeTab === "Sharing" ? "0 0 240 60" : 
                            activeTab === "Challenges" ? "0 0 340 60":
                            activeTab === "Gifts" ? "0 0 180 60": 
                            "0 0 550 60"}>
                            <text className={styles.svgStroke} x="50%" y="50%">{activeTab}</text>
                            <text className={styles.svgText} x="50%" y="50%">{activeTab}</text>
                        </svg>
                    </Link>
                </div>
            }
            {
                showBox &&
                <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
                    <div style={{width: 350}}>
                        <div className={classNames(styles.color1, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                            <Link 
                                to="#" 
                                onClick={() => boxChange('Connections')} 
                                className={classNames({[styles.active] : activeTab === "Connections"})}
                                style={{height: user.userRole === "Students" ? 240 : 370, marginBottom: 10, background:`url(${connections}) no-repeat center 90%/320px`}}
                            >
                                <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>My Connection</strong>
                            </Link>
                        </div>
                        {
                            user.userRole === "Students" &&
                            <div className={classNames(styles.color2, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Sharing')} 
                                    className={classNames({[styles.active] : activeTab === "Sharing"})}
                                    style={{height: 120, background:`url(${sharing}) no-repeat center 90%/160px`}}
                                >
                                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Sharing</strong>
                                </Link>
                            </div>
                        } 
                    </div>
                    <div style={{width: 350}}>
                        {
                            user.userRole === "Teachers" &&
                            <div className={classNames(styles.color2, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Sharing')} 
                                    className={classNames({[styles.active] : activeTab === "Sharing"})}
                                    style={{height: 240, marginBottom: 10, background:`url(${sharing}) no-repeat center 90%/220px`}}
                                >
                                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Sharing</strong>
                                </Link>
                            </div>
                        }
                            <div className={classNames(styles.color3, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Gifts')} 
                                    className={classNames({[styles.active] : activeTab === "Gifts"})}
                                    style={{height: 120, marginBottom: 10, background:`url(${gifts}) no-repeat center 90%/250px`}}
                                >
                                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Gifts</strong>
                                </Link>
                            </div>
                        {
                            user.userRole === "Students" &&
                            <div className={classNames(styles.color4, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                                
                                    <Link 
                                        to="#" 
                                        onClick={() => boxChange('Challenges')} 
                                        className={classNames({[styles.active] : activeTab === "Challenges"})}   
                                        style={{height: 240,  background:`url(${battle}) no-repeat center 90%/280px`}}
                                    >
                                        <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Challenge Friends</strong>
                                    </Link>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                !showBox &&
                <div className={styles.whiteBox2}>
                    {activeTab === "Sharing" && <Sharing />}
                    {activeTab === "Connections" && <ConnectUsers/>}
                    {activeTab === "Gifts" && <Gifts />}
                    {activeTab === "Challenges" && <Challenges />}
                </div>
            }           
        </div>
    )
}

export default Connection;