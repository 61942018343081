import {useLocation} from 'react-router-dom';
import styles from './styles.module.scss';

const Footer = ({contact, socialConnect}) => {
    const location = useLocation();

    return (
        <footer className={styles.footer}>
            {
                location.pathname !== '/login' && location.pathname !== '/register' && 
                location.pathname !== '/forgot-pass' &&
           <div className={styles.bottomText}>
            © Copyright 2023 AidaPal Pte Ltd. All rights reserved.
           </div>           
        }
        </footer>
    )
}

export default Footer;