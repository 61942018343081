// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editor_editor__17rug {\n  border: rgba(170, 170, 170, 0.6666666667) solid 1px;\n}\n.editor_editor__17rug .editor_editorToolbar__3S6kP button {\n  font-size: 14px;\n  padding: 3px 10px !important;\n}\n.editor_editor__17rug .editor_editorBox__3B-F9 {\n  min-height: 100px;\n  padding: 0 10px;\n  background: white;\n}\n\n.editor_wordCount__2DovT {\n  text-align: right;\n  font-size: 13px;\n  padding-top: 10px;\n}\n.editor_wordCount__2DovT i {\n  margin-left: 20px;\n  font-style: normal;\n}", "",{"version":3,"sources":["webpack://src/components/editor/editor.module.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;AACJ;AACQ;EACI,eAAA;EACA,4BAAA;AACZ;AAEI;EACI,iBAAA;EACA,eAAA;EACA,iBAAA;AAAR;;AAIA;EACI,iBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAGI;EACI,iBAAA;EACA,kBAAA;AADR","sourcesContent":[".editor {\n    border: #aaaa solid 1px;\n    .editorToolbar {\n        button {\n            font-size: 14px;\n            padding: 3px 10px!important;\n        }\n    }\n    .editorBox {\n        min-height: 100px;\n        padding: 0 10px;\n        background: white;\n    }\n}\n\n.wordCount {\n    text-align: right;\n    font-size: 13px;\n    padding-top: 10px;\n\n    i {\n        margin-left: 20px;\n        font-style: normal\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "editor_editor__17rug",
	"editorToolbar": "editor_editorToolbar__3S6kP",
	"editorBox": "editor_editorBox__3B-F9",
	"wordCount": "editor_wordCount__2DovT"
};
export default ___CSS_LOADER_EXPORT___;
