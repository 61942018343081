import {useState} from 'react';
import Input from '../../../components/input';
import {useHistory} from 'react-router-dom';
import classes from './styles/classes.module.scss';
import {toast} from 'react-toastify';
import { useStore } from '../../../store/useStore';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import API_URL from '../../../common/urls';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';

const SetBreakTimeForm = ({classId, lessionId, setShowIdle, setShowModal, minutes, setMinutes}) => {
    const history = useHistory();
    const minutesOnChange = (e) => setMinutes(e.target.value);
    const  {clickCount, setClickCount} = useStore();
    const {setUser} = useStore();
    const [loading, setLoading] = useState();

    const onSubmit = () => {
        if(!minutes) {
            toast.error(`Minute is empty!`);
            return;
        }

        let number = clickCount ? clickCount : 0;
                setClickCount(number + 1);
        setShowIdle && setShowIdle(true);
        setShowModal && setShowModal(false);

        // call api to set to all students
        const data = {lessionId : lessionId, minutes: minutes};
        axios.post(API_URL.SET_CLASS_BREAK_TIME_URL,data, {
            headers: authHeader()
        })
        .then(response => {
            // toast.success('Break time set has been sent successfully!');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={classes.form}>
            <h2>Break time</h2>
            <div className={classes.formControl}>
                <Input 
                    type="number" 
                    placeholder="" 
                    onChange={minutesOnChange}
                    value={minutes}
                    label="Input minutes"
                    min={1}
                />
                <div>
                    <button className="btn" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SetBreakTimeForm;