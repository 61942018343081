import { useEffect } from 'react';
import { useState } from 'react';
import API_URL from '../../../common/urls';
import { Loader } from '../../../components/loading';
import styles from './styles.module.scss';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError } from '../../../common/utils';
import { toast } from 'react-toastify';
import QuestionDetails from '../../../components/questionDetails';

const Revision = ({subject}) => {
    const {setUser, user} = useStore();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [topics, setTopics] = useState([]);
    const [bookmarks, setBookmarks] = useState();
    const [isBookmark, setIsBookmark] = useState(false);
    const [loading, setLoading] = useState();
    const [loading2, setLoading2] = useState();
    const [questionContent, setQuestionContent] = useState();
    const [currentTime, setCurrentTime] = useState(0);
    const [currentTopic, setCurrentTopic] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentQuestionId, setCurrentQuestionId] = useState('');

    useEffect(()=>{
        subject && setData([]);
        subject && setTopics([]);
        subject && getList();
    },[subject]);

    useEffect(()=>{
        setCurrentTime(0);
        setCurrentTopic(0);
        setCurrentQuestion(0);
        setCurrentQuestionId(bookmarks && bookmarks[currentTopic] && bookmarks[currentTopic].questionIds[currentQuestion]);
    },[isBookmark]);

    useEffect(()=>{
        data.length && data[currentTime] && setTopics(data[currentTime].topics);
        currentTime && setCurrentTopic(0);
    },[currentTime]);

    useEffect(()=>{
        setCurrentQuestionId(data[currentTime] && data[currentTime].topics && data[currentTime].topics[currentTopic] && data[currentTime].topics[currentTopic].questionIds[currentQuestion]);
    },[currentQuestion]);

    useEffect(()=>{
        data.length && setCurrentQuestionId(data[currentTime] && data[currentTime].topics && data[currentTime].topics[currentTopic] && data[currentTime].topics[currentTopic].questionIds[0]);
    },[data, currentTime, currentTopic]);

    useEffect(()=>{
        currentQuestionId && setLoading2(true);
        currentQuestionId && axios.get(API_URL.GET_REVISION_QUESTION_CONTENT_URL,{
            headers: authHeader(),
            params: {
                id: currentQuestionId
            }
        })
        .then(response => {
            setLoading2(false);
            response.data && setQuestionContent(response.data);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading2(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[currentQuestionId]);

    const getList = () => {
        setLoading(true);
        const url = API_URL.GET_REVISION_TIMELINES_URL;
        return axios.get(url,{
            headers: authHeader(),
            params: {
                subject
            }
        })
        .then(response => {
            setLoading(false);
            response.data.list && setData(response.data.list);
            response.data.list && response.data.list[0] && response.data.list[0].topics && setTopics(response.data.list[0].topics);
            response.data.bookmarks && setBookmarks(response.data.bookmarks);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={styles.revision}>
            <div className={styles.timeLine}>
                <div className={styles.tabs}>
                    <span onClick={() => setIsBookmark(false)} className={!isBookmark ? styles.active : undefined}>Timeline</span>
                    <span onClick={() => setIsBookmark(true)} className={isBookmark ? styles.active : undefined}>Bookmarks</span>
                </div>
                {
                    loading && <Loader />
                }
                {
                    !loading && data.length === 0 && 
                    <div>No timelines found.</div>
                }
                {
                    !loading && data.length > 0 && !isBookmark &&
                    <ul>
                        {data.map(
                            (item, index) => 
                            <li className={index === currentTime ? styles.active : undefined}
                                onClick={() => { setCurrentTime(index); setIsBookmark(false)}}
                                key={`timeline ${index}`}
                            >
                                {item.time}
                            </li>
                            )
                        }
                    </ul>
                }   
            </div>
            <ul className={styles.topics}>
                {
                    !loading && data.length > 0 && topics.length > 0 && !isBookmark &&
                    topics.map((item, index) => 
                    <li className={index === currentTopic ? styles.active : undefined}
                        onClick={() => setCurrentTopic(index)}
                        key={`tp ${index}`}
                    >
                        {item.name}
                        <span>{item.noOfQuestions}</span>
                    </li>)
                }
                {
                    !loading && isBookmark && bookmarks.length > 0 &&
                    bookmarks.map((item, index) => 
                    <li  className={index === currentTopic ? styles.active : undefined}
                        onClick={() => setCurrentTopic(index)}
                        key={`bm ${index}`}
                    >
                        {item.name}
                        <span>{item.noOfQuestions}</span>
                    </li>)
                }
            </ul>
            <div className={styles.questionContent}>
                {
                    loading2 && <Loader />
                }
                {
                    !loading2 && !loading && !isBookmark &&  data.length === 0 && topics.length === 0 &&
                    <div className='noResult'>No question found.</div>
                }
                {
                    !loading2 && !loading && !isBookmark && questionContent && data && data.length > 0 && topics && topics.length > 0 &&
                    <QuestionDetails item={questionContent} studentId={user.userName} isRevision={true} />
                }
                {
                    !loading2 && !loading && isBookmark && bookmarks.length === 0 &&
                    <div className='noResult'>No question found.</div>
                }
            </div>
            {
                data.length && 
                <div className={styles.nav}>
                    {
                        data[currentTime].topics[currentTopic] &&
                        data[currentTime].topics[currentTopic].questionIds &&
                        data[currentTime].topics[currentTopic].questionIds.length &&
                        data[currentTime].topics[currentTopic].questionIds.map((item,index) => 
                            <button key={`btn${index}`} onClick={() => setCurrentQuestion(index)} className={currentQuestion === index ? styles.active : undefined}>{index + 1}</button>
                        )
                    }
                </div>
            }
        </div>
    )
}

export default Revision;