import {Link} from 'react-router-dom';
import styles from '../styles.module.scss';
import Classes from './classes';
import payment from '../../../images/payment.svg';
import click from '../../../images/click.svg';
import done from '../../../images/done.svg';
import { parseRgb } from '../../../common/utils';
import classNames from 'classnames';
import { useStore } from '../../../store/useStore';

const MyClasses = () => {
    const {customValue, showBox, setShowBox, activeTab, setActiveTab} = useStore();
    const boxChange = (tab) => {
        setActiveTab(tab);
        setShowBox(false);
    }

    const tabChange = () => {
        setShowBox(true);
        setActiveTab('');
    }

    return (
        <div>
            {
                activeTab &&
                <div className={styles.tabLinks}>
                    <Link
                        to="#" 
                        onClick={tabChange} 
                        style={{width: activeTab === "Completed Classes" ? 400 : 280}}
                    >
                        <svg viewBox={activeTab === "Completed Classes" ? "0 0 540 60" : "0 0 380 60"}>
                            <text className={styles.svgStroke} x="50%" y="50%">{activeTab}</text>
                            <text className={styles.svgText} x="50%" y="50%">{activeTab}</text>
                        </svg>
                    </Link>
                </div>
            }
            {
                showBox &&
                <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
                    <div style={{width: 400}}>
                        <div className={styles.color1} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => boxChange('Paid Classes')} 
                            className={activeTab === "Paid Classes" ? styles.active : ""}
                            style={{height: 400, background:`url(${payment}) no-repeat center 90%/300px`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Paid Classes</strong>
                        </Link>
                        </div>
                    </div>
                    <div style={{width: 300}}>
                        <div className={styles.color2} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => boxChange('Trial Classes')} 
                            className={activeTab === "Trial Classes" ? styles.active : ""}
                            style={{height: 150, marginBottom: 10, background:`url(${click}) no-repeat center 90%/contain`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Trial Classes</strong>
                            
                        </Link>
                        </div>
                        <div className={styles.color4} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => boxChange('Completed Classes')} 
                            className={activeTab === "Completed Classes" ? styles.active : ""}
                            style={{height: 240, background:`url(${done}) no-repeat center 90%/contain`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Completed Classes</strong>
                        </Link>
                        </div>
                    </div> 
                </div>
            }
            {
                !showBox &&
                <div className={styles.whiteBox2}>
                    {activeTab === "Paid Classes" && <Classes isMyClass={true}/>}
                    {activeTab === "Trial Classes" && <Classes trial={true} isMyClass={true}/>}
                    {activeTab === "Completed Classes" && <Classes isCompleted={true} isMyClass={true}/>}
                </div>
            }
        </div>
    )
}

export default MyClasses;