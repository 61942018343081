import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const Chart = ({title, subTitle, data, height, chartType, categories, clickFunction}) => {
  const chartOptions = {
    chart: {
        type: chartType === "pie2" ? "pie" : chartType,
        backgroundColor: 'rgba(0,0,0,0)',
    },  
    title: {
        text: title
    },
    subtitle: {
        text: subTitle
    },
    credits: {
        enabled: false
    },
    xAxis: {
        type: 'category',
        categories: categories
    },
    yAxis: {
      title: {
        text: "Number of Topics"
      }
    },
    legend: {
      enabled: chartType === "pie2" ? false : true,
      layout:'vertical',
      align: 'right',
      verticalAlgin: 'middle',
      textOverflow: 'none',
      itemWidth: 120,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
      },
        series: {
          showInLegend: chartType === "pie" || chartType === "pie2" ? true : false,
          borderWidth: chartType === "pie2" ? 1 :0,
          dataLabels: {
              enabled: chartType === "pie2" ? true : false,
              distance: '-40%',
              style: {
                textOverflow: 'none',
                fontSize: 15,
              }
          },
          point:{
            events:{
                click: function (event) {
                  clickFunction && clickFunction(this.name);
                },
                unselect: function (event) {
                  clickFunction && clickFunction('');
                }
            }
        } 
        }
    },
    series: [
      {
        data: data,
        size: chartType === "pie2" ? '120%' : '110%',
          innerSize: chartType === "pie2" ? 0 : '70%',
      }
    ]
  };

  return (
    <div>
      <HighchartsReact 
        highcharts={Highcharts} 
        options={chartOptions} 
        containerProps={{ style: { height: height } }}
    />
    </div>
  );
};

export default Chart;