import {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import tests from './tests.module.scss';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import {toast} from 'react-toastify';
import classNames from 'classnames'; 
// import AssignmentReview from './assignmentReview';
// import { TestResults } from './components';
import Result from './result';
import TestQuestion from './testQuestion';
import TestAnswer from './testAnswer';
import Modal from '../modal';
import VideoView from '../../views/user/myAidaPal/modal-videoView';
import SideBar from './sideBar';
import ReportProblem from './report';
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from 'react-icons/md';
import Share from './share';

const TestContent = ({showModal, showTestForm, setShowTestForm, setNoScroll, worksheetId, isPreview, 
    themeId, setRefreshTheme, refreshTheme, worksheetTime, setShowModal, inExpand, setInExpand, questionId,
    learnId, learnType, apiUrl}) => {
    const history = useHistory();
    const {user, setUser, moreInfo, setMoreInfo} = useStore();
    const [testData, setTestData] = useState(null); 
    const [loading, setLoading] = useState();
    const [answer, setAnswer] = useState([""]);
    const [no, setNo] = useState(1);
    const [resultData, setResultData] = useState(null);
    const [answerTime, setAnswerTime] = useState(0);
    const [showList, setShowList] = useState();
    const [showModal2, setShowModal2] = useState();
    const contentRef = useRef();
    const [modalType, setModalType] = useState('');
    const [timeCount, setTimeCount] = useState(0);
    const [getTimeCount, setGetTimeCount] = useState(false);
    const [allResults, setAllResults] = useState([]);
    const [hideResult, setHideResult] = useState(false);
    const [openEndData, setOpenEndData] = useState({text: '', fileUrl: '', type: '', marked: '', comment: ''});
    const [recordData, setRecordData] = useState({recordedFileUrl: '', fileUrl: ''});
    const [showSelfMarked, setShowSelfMarked] = useState(false);
    const [selfMarkedComment, setSelfMarkedComment] = useState('');
    const [selfMarkedAction, setSelfMarkedAction] = useState('');
    const [updateList, setUpdateList] = useState(0);

    useEffect(()=>{
        if(showModal || showTestForm) {
            resetValues();
        }

        if(!showModal && !showTestForm) {
            setResultData(null);
            setTestData(null);
        }
    },[showModal,showTestForm]);

    useEffect(()=>{
            questionId && getTest(questionId);
    },[questionId]); // in case view from sharing

    useEffect(()=>{
        testData && testData.review && testData.review.allResults && setAllResults(testData.review.allResults);
    },[testData && testData.review && testData.review.allResults]);

    useEffect(()=>{
        testData && testData.review && testData.review.result && setResultData(testData.review.result);
    },[testData && testData.review && testData.review.result]);

    useEffect(()=>{
        if(worksheetId) {
            setTestData(null);
            getTest(null);
            resetValues();
        }
    },[worksheetId]);

    useEffect(()=>{
        if(apiUrl && questionId) {
            setTestData(null);
            getTest(questionId);
            resetValues();
        }
    },[apiUrl && questionId]);

    const resetValues = () => {
        setResultData(null);
        setAnswer([""]);
        setShowSelfMarked(false);
        setSelfMarkedAction('');
        setSelfMarkedComment('');
        setOpenEndData({text: '', fileUrl: '', type: '', marked: '', comment: ''});
        setRecordData({recordedFileUrl: '', fileUrl: ''});
    }
    const getTest = (questionId) => {
        setLoading(true);
        return axios.get(apiUrl ? apiUrl : API_URL.GET_USER_LIVE_TEST_URL2,{
            headers: authHeader(),
            params: {
                worksheetId,
                questionId: questionId ? questionId : null,
                isPreview: isPreview,
                learnId,
                learnType
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setTestData(response.data);
                 response.data && response.data.skipTime && setTimeCount(response.data.skipTime);
                 if (response.data && response.data.result) {
                    setResultData(response.data.result);
                    setMoreInfo({...user, credit: moreInfo.credit + response.data.result});
                 }
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onSubmit = () => {
        if(!isPreview && testData.answerType !== "openEnded" && testData.answerType !== "recording" &&
            (!answer || (answer && answer.length === answer.filter(item => item === '').length))) {
            toast.error('Please input the answer!');
            return;
        }

        if(!isPreview && testData.answerType === "openEnded" && !openEndData.text && !openEndData.fileUrl) {
            toast.error('Please input the answer or upload a file or insert a file url!');
            return;
        }

        if(!isPreview && testData.answerType === "recording" && !recordData.fileUrl && !recordData.recordedFileUrl) {
            toast.error('Please upload a media file or recording a file');
            return;
        }

        if(!isPreview && testData.answerType === "openEnded" && testData.openEndedType === 'Self-marked' && !selfMarkedAction) {
            setShowSelfMarked(true);
        } else {
            !apiUrl && postSubmit('submit');
        }
    }

    const postSubmit = (type) => {
        
        if (testData.answerType === "openEnded" && selfMarkedAction === 'disagree' && !selfMarkedComment 
            && type === 'submit' && showSelfMarked) {
            toast.error('Please add your comment!');
            return;
        }

        setGetTimeCount(true);
        const _answerData = {
            questionId: testData.questionId,
            isLastQuestion: testData.nextQuestionId ? false : true,
            credit: testData.difficulty && testData.difficulty.value ? testData.difficulty.value : 0,
            answer: type === 'submit' ? testData.answerType === "openEnded" ? Object.values(openEndData) : testData.answerType === "recording" ? Object.values(recordData) : answer : [], 
            answerTime: timeCount, 
            action: type,
            markType: testData.answerType === "recording" ? "manual-recording" : testData.answerType === "openEnded" && testData.openEndedType !== 'Self-marked' ? "manual-open-ended"  :"auto"
        };
        
       callPost(_answerData);
    }

    const callPost = (data, type) => {
        return axios.post(API_URL.POST_USER_LIVE_TEST_RESULT_URL,{data: data},{
            headers: authHeader(),
            params: {
                worksheetId,
                questionId: testData.questionId,
                learnId,
                learnType,
                action: type,
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.result && setResultData(response.data.result);
                setNoScroll && setNoScroll(false);
                setOpenEndData({text: '', fileUrl: '', type: '', marked: '', comment: ''});
                setRecordData({recordedFileUrl: '', fileUrl: ''});

                type === 'complete' && response.data.allResults && setAllResults(response.data.allResults);
                setHideResult(false);
                setUpdateList(updateList+1);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }

            type === 'complete' && setAllResults(null);
        })
    }
    const onBack = () => {
        setNo(no - 1);
        postSubmit('move');
        getTest(testData.backQuestionId);
        setRefreshTheme(refreshTheme + 1);
        resetValues();
    }

    const onNext = (iscontinue) => {
        setNo(no + 1);
        !iscontinue && postSubmit('move');
        getTest(testData.nextQuestionId);
        setRefreshTheme(refreshTheme + 1);
        resetValues();
    }

    const onSkip = () => {
        !apiUrl && postSubmit('skip');  
        getTest(testData.nextQuestionId);      
        setRefreshTheme(refreshTheme + 1);
        resetValues();
    }

    const onQuestionSelect = (questionId, index) => {
        setNo(index + 1);
        !isPreview && postSubmit('move');
        getTest(questionId);
        setRefreshTheme(refreshTheme + 1);
        resetValues();
    }

    const onTimeOutHandler = () => {
        if(!isPreview) {
            postSubmit('timeout');
        } else {
            setShowTestForm && setShowTestForm(false);
        }
    }

    const onWorksheetTimeout = ()  => {
        if(!isPreview) {
            postSubmit('timeout-worksheet');
        } else {
            setShowTestForm && setShowTestForm(false);
        }
    }

    const openModal2 = (type) => {
        setModalType(type);
        if (type === "complete") {
            callPost({action: 'complete'}, 'complete');
        } else {
            setShowModal2(true);
        }
    }

    const update = (type, doSth, item, noteList) => {
        doSth && doSth(type, item);
        return axios.post(API_URL.POST_USER_LIVE_TEST_ACTION_URL,{
            action: type,
            id: testData.questionId,
            noteList: JSON.stringify(noteList),
        },
            {
                headers: authHeader()
            }).then(response => {
            })
            .catch(error => {
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }      
            });
    }

    return(
        <div className={classNames(tests.testForm, {[tests.expand]: inExpand})}>
            {
                    !inExpand &&
                    <MdOutlineFullscreen title='Full view' onClick={() => setInExpand(true)} className={tests.expandBtn}/>
                }
                {
                    inExpand &&
                    <MdOutlineFullscreenExit title='Small view' onClick={() => setInExpand(false)} className={tests.expandBtn}/>
                }
            <div className={tests.testContent}>
                <TestQuestion 
                    tests={tests} 
                    testData={testData} 
                    onNext={onNext} 
                    onBack={onBack} 
                    setShowTestForm={setShowTestForm}
                    isPreview={isPreview}
                    loading={loading}
                    openModal2={openModal2}
                    update={update}
                    setTestData={setTestData}
                    resultData={resultData}
                    showSelfMarked={showSelfMarked}
                />  
                <TestAnswer
                    tests={tests} 
                    testData={testData} 
                    onSubmit={onSubmit}
                    onSkip={onSkip}
                    isPreview={isPreview}
                    openEndData={openEndData}
                    setOpenEndData={setOpenEndData}
                    recordData={recordData}
                    setRecordData={setRecordData}
                    onTimeOutHandler={onTimeOutHandler}
                    answer={answer}
                    setAnswer={setAnswer}
                    setAnswerTime={setAnswerTime}
                    loading={loading}
                    worksheetTime={worksheetTime}
                    onWorksheetTimeout={onWorksheetTimeout}
                    timeCount={timeCount}
                    setTimeCount={setTimeCount}
                    resultData={resultData}
                    allResults={allResults}
                    onNext={onNext}
                    openModal2={openModal2}
                    showSelfMarked={showSelfMarked}
                    selfMarkedAction={selfMarkedAction}
                    selfMarkedComment={selfMarkedComment}
                    setSelfMarkedAction={setSelfMarkedAction}
                    setSelfMarkedComment={setSelfMarkedComment}
                    themeId={themeId}
                    getTimeCount={getTimeCount}
                    setGetTimeCount={setGetTimeCount}
                />
            </div>
            {
                !apiUrl &&
                <SideBar 
                    showModal={showModal} 
                    tests={tests} 
                    showList={showList} 
                    setShowList={setShowList} 
                    testData={testData} 
                    onQuestionSelect={onQuestionSelect} 
                    setTestData={setTestData}
                    setModalType={openModal2}
                    update={update}
                    isPreview={isPreview}
                    worksheetId={worksheetId}
                    learnId={learnId}
                    learnType={learnType}
                    currentNumber={no}
                    updateList={updateList}
                />
            }

             <Modal
                setShowModal={setShowModal2} 
                showModal={showModal2} 
                contentRef={contentRef}
                width={!["report-problem", "share", "preComplete"].includes(modalType) ? "85%" : "600px"}
                height={!["report-problem", "share", "preComplete"].includes(modalType) ? "90%" : "auto"}
                isExpandable={!["report-problem", "share", "preComplete"].includes(modalType) && true}
                firstExpand={["video","file"].includes(modalType)}
                bgSet={modalType === "video" ? {background: "black", color: "white"} : undefined}
                content={
                    <div ref={contentRef}>
                        {
                            modalType === "file" && 
                            <div className={tests.viewfile}>
                                {   (testData && testData.materials.fileType === "pdf") && 
                                    <iframe width="100%"
                                        height="98%"
                                        src={`${API_URL.COURSEWARE_PDF_VIEW_URL}?docId=${testData && testData.materials.fileId}`}>
                                    </iframe>
                                }

                                {   (testData && testData.materials.fileType !== "pdf") &&
                                    <iframe width="100%"
                                        height="100%"
                                        src={`${testData && testData.materials.fileUrl}&embedded=true`}>
                                    </iframe>
                                }
                            </div>
                        }
                        {
                            modalType === "video" && 
                            <VideoView 
                                url={testData && testData.materials.videoUrl} 
                                id={testData && testData.materials.videoId} 
                                showModal={showModal2}
                                feedback={testData && testData.materials.videoFeedback}
                            />
                        }
                        {
                            modalType === "report-problem" &&
                            <ReportProblem tests={tests} showModal2={showModal2} setShowModal2={setShowModal2} questionId={testData && testData.questionId}/>
                        }
                        {
                            modalType === "share" && testData &&
                            <Share
                                tests={tests}
                                setShowModal2={setShowModal2}
                                questionId={testData && testData.questionId}
                                themeId={themeId}
                            />
                        }
                        {
                            modalType === 'preComplete' &&
                            <div>
                                <h3>If you complete this worksheet, you don't have any chance to do it again?</h3>
                                <div className='buttons'>
                                    <button className='btn' onClick={() => { openModal2('complete'); setShowModal2(false) }}>Ok</button>
                                    <button onClick={() => setShowModal2(false)}>Cancel</button>
                                </div>
                            </div>
                        }
                    </div>
                }
            />
            {
                modalType === "complete" && allResults &&
                <Result 
                    className={tests}
                    allResults={allResults}
                    setShowModal={setShowModal}
                    hideResult={hideResult}
                    setHideResult={setHideResult}
                />
            }
        </div>
    )
}

export default TestContent;