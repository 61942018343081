import {useRef} from 'react';
import {uniqueId} from 'lodash';
import terms from './styles/terms.module.scss';
import {toast} from 'react-toastify';
import {MdClose} from 'react-icons/md';
import classNames from 'classnames';

const ImagePreview = ({image, index, removeFile, formChild}) => {
    const onRemoveItem = () => {
        removeFile && removeFile(index);
    }

    return (
        <div className={terms.imagePreview}>
            <img src={image} alt="preview" />
            {
                formChild === "answerImages" &&
                <span>{index+1}</span>
            }
            <MdClose className={terms.close} title="Remove file" onClick={onRemoveItem}/>
        </div>
    )
}

const ImageMatch = ({setFormData, formData, formChild, length, label}) => {
    const fileRef = useRef();

    const removeFileHandler = (index) => {
        formData[formChild].imagesList.splice(index, 1);
        setFormData({...formData});
    }

    const filesOnChange = (e) => {
        const _images = Array.from(e.target.files);
        if (_images && _images.length > 0) {
            _images.forEach(image => {
                const reader = new FileReader();
                const maxSize = 512*1024; // 500 Kb
                if(image.size > maxSize) {
                    toast.error(`Size of ${image.name} is larger than 500 Kb`);
                } else {
                    reader.addEventListener("load", () => {
                        if(formData[formChild].imagesList && formData[formChild].imagesList.length < length) {
                            formData[formChild].imagesList.push(reader.result);
                            setFormData({...formData})
                        } else {
                            length && toast.error(`Maximum ${length} images!`)
                        }
                    });
                    reader.readAsDataURL(image);
                }
            })
            
          }
    }

    const checkLength = () => {
        if (length === 0) {
            toast.error('Please input words list first!');
            return;
        } else {
            if(formData[formChild].imagesList.length > length) {
                length && toast.error(`Maximum ${length} images!`);
                return;
            } else {
                fileRef.current.click();
            }
        }
    }

    return (
        <div className={classNames(terms.imagesChoice, terms.imagesMatch)}>
            <label>{label}</label>
            {
                formData[formChild].imagesList && formData[formChild].imagesList.length > 0 && 
                formData[formChild].imagesList.map((image, index) => 
                    <ImagePreview 
                        image={image} 
                        key={uniqueId()}
                        index={index}
                        removeFile={removeFileHandler}
                        formChild={formChild}
                    />
                )
            }
            {
                formData[formChild].imagesList && 
                <div className={terms.uploadImages} onClick={checkLength}>
                    <span>+</span>
                        <input 
                            type="file" 
                            multiple={true}
                            onChange={filesOnChange}
                            ref={fileRef}
                        />
                </div>
            }
               
        </div>
    )
}

export default ImageMatch;