import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import input from './input.module.scss';
import classNames from 'classnames';
import { useStore } from '../../store/useStore';

const TimeCount = ({ seconds, setIsShowTime, messageTimeOut, style, onTimeOut, setAnswerTime, isFromClassItem, className}) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const [secondsLeft, setSecondsLeft] = useState('');
    const [minutes, setMinutes] = useState('');
    const [alert, setAlert] = useState(false);
    const  {clickCount} = useStore();
  
    useEffect(()=>{
        seconds && setTimeLeft(seconds);
        setIsShowTime && setIsShowTime(true);
    },[seconds, clickCount]);

    useEffect(() => {
      setAnswerTime && setAnswerTime(seconds-timeLeft);
    },[timeLeft]);

    useEffect(() => {
      if (!timeLeft) return;
  
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      if(isFromClassItem && timeLeft === 3600) {
        toast.info('Your class will be started in 1 hour!');
      }

      if(isFromClassItem && timeLeft === 900) {
          toast.info('Your class will be started in 15 mins!');
      }
      
      if(timeLeft === 1) {
        messageTimeOut && toast.error(messageTimeOut); 
        setIsShowTime(false);
        onTimeOut && onTimeOut();
      } else {
        setMinutes(Math.floor(timeLeft / 60));
        setSecondsLeft(Math.floor((timeLeft - (minutes*60))));

        if(timeLeft < 60) {
            setAlert(true)
        }
      }

      return () => clearInterval(intervalId);
    }, [timeLeft, minutes]);

    return (
      <div style={style} className={classNames(input.time, {[input.timeOut]: alert}, className)}>
        <span>{minutes < 10 ? '0' + minutes : minutes}</span> : <span>{secondsLeft < 10 ? '0'+secondsLeft : secondsLeft}</span>
      </div>
    );
  };

export default TimeCount;