import {useState} from 'react';
import styles from './styles.module.scss';
import API_URL from '../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import Input from '../../components/input';
import {MdEmail, MdPhone} from 'react-icons/md';
import {FaWhatsapp} from 'react-icons/fa';
import {getError} from '../../common/utils';
import ComplexField from '../../components/input/complexField';

const ContactUs = ({fromHome}) => {
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [name, setName] = useState('');

    const onEmailChange = (e) => {
        const val = e.target.value;
        setEmail(val)
    }

    const onContentChange = (e) => {
        const val = e.target.value;
        setContent(val)
    }

    const onNameChange = (e) => {
        const val = e.target.value;
        setName(val)
    }

    const handleSubmit = () => {
        if(!phoneNo && !email) {
            toast.error(`Please enter Phone number or email!`);
            return;
        }

        const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email && !emailReg.test(email)) {
            toast.error(`You have entered an invalid email address!`);
            return;
        }

        const data = {
            phoneNo, email, content, name
        }

        axios.post(API_URL.CONTACT_US_POST_URL,data)
        .then(response => 
            {
                toast.success('Your information is sent successfully.');
                setEmail('');
                setContent('');
                setName('');
                setPhoneNo('');
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);       
        })

    }

    const onSetPhoneNo = (value) => {
        setPhoneNo(value);
    }
    
    return (
        <div className={styles.contactUs}>
            <div className={styles.box}>
                <div className={styles.form}>
                    {
                        !fromHome &&
                        <h1>Contact Us</h1>
                    }
                        <Input 
                            type="text" 
                            placeholder="Name" 
                            onChange={onNameChange}
                            value={name}
                        />
                        <ComplexField
                            styles={styles}
                            placeholder="Phone No."
                            setPhoneNo={onSetPhoneNo}
                            phoneNo={phoneNo}
                            value={phoneNo}
                        />
                        <Input 
                            type="email" 
                            placeholder="Email" 
                            onChange={onEmailChange}
                            value={email}
                        />
                        <Input 
                            type="textarea" 
                            placeholder="How can we help you?" 
                            onChange={onContentChange}
                            value={content}
                        />

                    <div className={styles.buttons}>
                        <button className="btn" onClick={handleSubmit}>Submit</button>    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;