// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio_wrapper__2EwXm {\n  position: relative;\n  display: block;\n  cursor: pointer;\n  margin-top: 10px;\n}\n\n.radio_hidden__1V9mG {\n  display: none;\n}\n\n.radio_icon__1nnl1 {\n  display: inline-block;\n  vertical-align: middle;\n  color: grey;\n}\n\n.radio_label___iol_ {\n  display: inline-block;\n  vertical-align: middle;\n  margin-left: 10px;\n  font-size: 14px;\n}\n\n.radio_checked__3hibz {\n  color: #009CC8;\n}", "",{"version":3,"sources":["webpack://src/components/radio/radio.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,qBAAA;EACA,sBAAA;EACA,WAAA;AAGF;;AADA;EACE,qBAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;AAIF;;AAFA;EACE,cAAA;AAKF","sourcesContent":[".wrapper {\n  position: relative;\n  display: block;\n  cursor: pointer;\n  margin-top: 10px;\n}\n.hidden {\n  display: none;\n}\n.icon {\n  display: inline-block;\n  vertical-align: middle;\n  color: grey;\n}\n.label {\n  display: inline-block;\n  vertical-align: middle;\n  margin-left: 10px;\n  font-size: 14px;\n}\n.checked {\n  color: #009CC8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "radio_wrapper__2EwXm",
	"hidden": "radio_hidden__1V9mG",
	"icon": "radio_icon__1nnl1",
	"label": "radio_label___iol_",
	"checked": "radio_checked__3hibz"
};
export default ___CSS_LOADER_EXPORT___;
