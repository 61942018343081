import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import { getError } from '../../common/utils';
import styles from './styles.module.scss';
import { endsWithAny } from '../../common/format';
import {uniqueId, cloneDeep, clone} from 'lodash';
import {MdFastForward, MdDone, MdClose} from 'react-icons/md';
import RichEditor2 from '../../components/editor/editor2';
import ManualMarkForm from './manualMarkForm';

const QuestionDetails = ({item, getQuestionContent, isInResult, studentId, isRevision, isQuestionReport, assignId, getAssigment, setCurrentQuestionId}) => {
    const history = useHistory();
    const {setUser, user} = useStore();

    const [markup, setMarkup] = useState('');
    const [directlyMarkup, setDirectlyMarkup] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [showOrigin, setShowOrigin] = useState(false);

    const markupOnChange = value => setMarkup(value); 

    const saveDirectlyMarkup = (content) => {
        if(!markup) {
            toast.error('Please input markup');
            return;
        }

        if(markup === content) {
            toast.error('Please change something!');
            return;
        }

        const url = API_URL.POST_USER_DIRECTLY_MARKUP_URL;
        axios.post(url,{markup: markup},{
            headers: authHeader(),
            params: {
                assignId: assignId,
                questionId: item.questionId,
                studentId: studentId
            }
        })
        .then(response => {
            setCurrentQuestionId && setCurrentQuestionId(item.questionId);
            getAssigment && getAssigment();
            getQuestionContent && getQuestionContent(item.questionId);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <>
            {
                !isRevision &&
                <div className={styles.questionItem}>
                    <h3>General Information</h3>
                    <div className="flex flexWrap">
                        {item.levels && item.levels.length > 0 &&
                            <div className={styles.reportInfo}>Levels <strong>{item.levels.map(item => item.label).join(', ')}</strong></div>
                        }
                        {item.subjects && item.subjects.length > 0 &&
                            <div className={styles.reportInfo}>Subjects <strong>{item.subjects.map(item => item.label).join(', ')}</strong></div>
                        }
                        {item.topics && item.topics.length > 0 &&
                            <div className={styles.reportInfo}>Topics <strong>{item.topics.map(item => item.label).join(', ')}</strong></div>
                        }
                        {item.subTopics && item.subTopics.length > 0 &&
                            <div className={styles.reportInfo}>Sub Topics <strong>{item.subTopics.map(item => item.label).join(', ')}</strong></div>
                        }
                        {item.tags && item.tags.length > 0 && 
                            <div className={styles.reportInfo}>Tags <strong>{item.tags.map(item => item.label).join(', ')}</strong></div>
                        }
                        {
                            item.questionTime &&
                            <div className={styles.reportInfo}>Question Time<strong>{item.questionTime} mins</strong></div>
                        }
                        {
                            item.answerTime &&
                            <div className={styles.reportInfo}>Answer Time<strong>{item.answerTime ? `${Math.floor(item.answerTime/60) > 0 ? 
                                Math.floor(item.answerTime/60) + ' mins' : ""} ${item.answerTime%60 ? item.answerTime%60 + ' secs': ''}` : 0}</strong></div>
                        }
                    
                        {item.difficulty && 
                            <div className={styles.reportInfo}>Difficulty Level<strong>{item.difficulty.value}</strong></div>
                        }
                    </div>
                </div>
            }
            <div className={styles.answerItem}>
                {item.content &&
                    <>
                    <h3>Question content</h3>
                    {item.title &&
                    <h4>{item.title}</h4>
                    }
                    <div dangerouslySetInnerHTML={{__html: item.content.replace('(autoplay)|(autoPlay)|(AUTOPLAY)','')}} className={styles.questionContent}/>
                    </>
                }

                {
                    item.mediaUrl && !endsWithAny(['.mp3', '.ogg', '.wav', 'mp4'],item.mediaUrl) &&
                    <iframe
                        frameBorder="0"
                        width={'100%'}
                        height={['mp3', 'm4a'].includes(item.mediaType) ? 150 : '500'}
                        src={item.mediaUrl.replace(/\/view\?usp=sharing/g, '/preview').replace(/\/view/g, '/preview')}>
                    </iframe>
                }

                {
                    item.mediaUrl && endsWithAny(['.mp3', '.ogg', '.wav'],item.mediaUrl) &&
                    <audio controls>
                        <source src={item.mediaUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                }

                {
                    item.mediaUrl && item.mediaUrl.endsWith('mp4') &&
                    <video width="320" height="240" controls>
                        <source src={item.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

                {
                    !isInResult && 
                    <div className={styles.options}>
                        {
                            ["choice", "matching", "ordering"].includes(item.answerType) &&
                            <h4>Options</h4>
                        }

                        {
                            item.answerType === "matching" &&
                            <p><u>Note:</u> The position of second options in pair are changed randomly!</p>
                        }
                        
                        <div className={item.answerType === "matching" ? styles.matching : undefined}>
                        {(item.answerType === "choice") && item.options &&
                             <div>
                              {
                                item.options.map(option => (
                                    <div className={styles.option} key={uniqueId()} dangerouslySetInnerHTML={{__html: option}} />
                                ))
                              } 
                            </div>
                        }

                        {
                            item.answerType === "ordering" && item.options &&
                            <div>
                              {
                                item.options.map((item) => clone(item)).map(option => (
                                    <div className={styles.option} key={uniqueId()} dangerouslySetInnerHTML={{__html: option}} />
                                ))
                              } 
                            </div>
                        }

                        { item.answerType === "matching" && item.options && item.options2 && item.options.length === item.options2.length &&
                            <>
                                {
                                    item.options2.map((item) => clone(item)).map((option, index) => (
                                        <div key={uniqueId()}>
                                            <div className={styles.option} dangerouslySetInnerHTML={{__html: item.options[index]}} />
                                            <div className={styles.option} dangerouslySetInnerHTML={{__html: option}} />
                                        </div>
                                    ))
                                } 
                            </>
                        }
                        </div>   
                    </div>
                }
                {
                    !(item.result && item.result.includes('%')) && item.result !== "pending" &&
                    ((item.correctOptions && item.correctOptions.length > 0) || (item.correctText && item.correctText.length > 0)) &&
                    <div className={styles.rightAnswers}>
                        <h3>Correct Answer</h3>
                        <div className={styles.options}>
                            <div className={item.answerType === "matching" ? styles.matching : undefined}>
                            { 
                                item.answerType === "matching" && item.options && item.options.length > 0 && item.correctText && item.correctText.length === item.options.length && 
                                <>
                                { item.options.map((option, index) => (
                                    <div key={uniqueId()}>
                                        <div className={styles.option} dangerouslySetInnerHTML={{__html: option}} />
                                        <div className={styles.option} dangerouslySetInnerHTML={{__html: item.correctText[index]}} />
                                    </div>
                                )) }
                                </> 
                            }
                            {
                                item.answerType === "choice" && item.correctOptions && item.options && item.options.length > 0 && item.correctOptions.length > 0 && 
                                <div>
                                {item.correctOptions.map(option => (
                                    <div className={styles.option} key={uniqueId()} dangerouslySetInnerHTML={{__html: item.options[parseInt(option)]}} />
                                ))}
                                </div>
                            }
                            {
                                !["choice", "matching"].includes(item.answerType) && item.correctText && item.correctText.length > 0 && 
                                <div>
                                {
                                item.correctText.map(option => (
                                    <div className={styles.option} key={uniqueId()} dangerouslySetInnerHTML={{__html: option.replace(/(\|){2}/g, '<strong class="orOption">OR</strong>')}} />
                                ))}
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                }
                
                {
                    studentId && !isQuestionReport &&
                    <div className={styles.studentAnswers}>
                        <h3>
                            Student's Answer 
                            {
                                !directlyMarkup && user.userRole === "Teachers" && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <button className="btn" onClick={() => setDirectlyMarkup(true)}>Directly Markup</button>
                            }
                            {
                                directlyMarkup && user.userRole === "Teachers" && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <>
                                <button className="btn" disabled={!markup} onClick={() => saveDirectlyMarkup(item.studentAnswer[0])}>Save Markup</button>
                                <button onClick={() => setDirectlyMarkup(false)}>Cancel</button>
                                </>
                            }
                        </h3>

                            {
                                directlyMarkup && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <RichEditor2 
                                    editorData={item.answerTeacherMarkup ? item.answerTeacherMarkup : item.studentAnswer[0]}
                                    onChange={markupOnChange}
                                    isFull={true}
                                />
                            }

                        <div className={item.markType ==="manual-open-ended" ? styles.textInput : item.answerType === "matching" ? styles.matching : undefined}>
                            {
                                item.answerType === "matching" && item.options && item.options.length > 0 && item.studentAnswer && item.options.length === item.studentAnswer.length &&
                                <>
                                    { item.options.map((option, index) => (
                                        <div key={uniqueId()}>
                                            <div className={styles.option} dangerouslySetInnerHTML={{__html: option}} />
                                            <div className={styles.option} dangerouslySetInnerHTML={{__html: item.studentAnswer[index]}} />
                                        </div>
                                    )) }
                                </>
                            }
                            { item.answerType !== "matching" && item.studentAnswer && Array.isArray(item.studentAnswer) && item.markType === "auto" &&
                            item.studentAnswer.map(option => (
                                <span key={uniqueId()} dangerouslySetInnerHTML={{__html: item.answerType === 'choice'? item.options[parseInt(option)] : option}} />
                            ))
                            }

                            { item.studentAnswer && !Array.isArray(item.studentAnswer) && !item.result.includes('%') && item.result !== "pending" && item.markType === "auto" &&
                                <span key={uniqueId()} dangerouslySetInnerHTML={{__html: item.studentAnswer}} />
                            }

                            {
                                !directlyMarkup && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <div className={styles.box} dangerouslySetInnerHTML={{__html: item.answerTeacherMarkup ? item.answerTeacherMarkup : item.studentAnswer[0]}} />
                            }

                            {
                                item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.answerTeacherMarkup &&
                                <div className={styles.box}>
                                    <a className={styles.viewOrigin} href="#" onClick={() => setShowOrigin(!showOrigin)}>{showOrigin ? 'Hide' : 'View'} Original answer</a>
                                    <div style={{display: showOrigin ? 'block' : 'none'}} dangerouslySetInnerHTML={{__html: item.studentAnswer[0]}} />
                                </div>
                            }

                            {
                                ((item.markType ==="manual-open-ended" || item.markType ==="manual-recording") && item.studentAnswer && item.studentAnswer.length > 1 && item.studentAnswer[1]) &&
                                <div className={styles.box}>
                                    <a href={item.studentAnswer[1]} target="_blank">View Attached file</a> 
                                </div>
                            }

                            {
                                item.markType ==="manual-recording" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <div className={styles.box}>
                                    <video src={item.studentAnswer[0]} controls />
                                </div>
                            }

                            {
                                item.markType ==="manual-recording" && item.studentAnswer && item.studentAnswer.length > 2 && item.studentAnswer[2] &&
                                <div className={styles.box}>
                                    Note: <div dangerouslySetInnerHTML={{__html: item.studentAnswer[2]}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    isQuestionReport &&
                    <div className={styles.studentAnswers}>
                        <h3>Student's Answer</h3>
                            <div>
                                <h4 className={styles.correct}><MdDone title="Correct"/> Correct</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.correct &&
                                    <div>
                                        <strong>{item.studentAnswer.correct} students: </strong>
                                        {
                                            item.studentAnswer.correctStudents && item.studentAnswer.correctStudents.length > 0 &&
                                            item.studentAnswer.correctStudents.join(', ')
                                        }
                                    </div>
                                }

                                <h4 className={styles.incorrect}><MdClose title="Incorrect" /> Incorrect</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.incorrect &&
                                    <ul>
                                    <strong>{item.studentAnswer.incorrect} students: </strong>
                                    {
                                        item.studentAnswer.incorrectStudents && item.studentAnswer.incorrectStudents.length > 0 &&
                                        item.studentAnswer.incorrectStudents.map(student => 
                                            <li key={uniqueId()}>
                                                {student.name} : <p>
                                                    {student.answer && student.answer.length >0 &&
                                                        student.answer.map(answer => <span key={uniqueId()}>{answer}</span>)
                                                    }
                                                </p>
                                            </li>
                                        ) 
                                    }
                                    </ul>
                                }

                                <h4 className={styles.skip}><MdFastForward title="Skip" /> Skip</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.skip &&
                                    <div>
                                    <strong>{item.studentAnswer.skip} students: </strong>
                                    {
                                        item.studentAnswer.skipStudents && item.studentAnswer.skipStudents.length > 0 &&
                                        item.studentAnswer.skipStudents.join(', ')
                                    }
                                    </div>
                                }

                                <h4 className={styles.timeOut}><span>Timeout</span> Timeout</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.timeout &&
                                    <div>
                                    <strong>{item.studentAnswer.timeout} students: </strong>
                                    {
                                        item.studentAnswer.timeoutStudents && item.studentAnswer.timeoutStudents.length > 0 &&
                                        item.studentAnswer.timeoutStudents.join(', ')
                                    }
                                    </div>
                                }
                            </div>
                    </div>
                }
                {
                    studentId && item.result && item.result.includes("%") &&
                    <div className={styles.manualMark}>
                        <h3>Teacher marked</h3>
                        <div className={styles.box}>
                            <div><label>Score:</label> <strong>{item.result}</strong></div>
                            {
                                item.teacherNote && 
                                <div>
                                    <label>Teacher Note:</label>
                                    <div dangerouslySetInnerHTML={{__html: item.teacherNote}} />
                                </div>
                            }
                            {
                                item.teacherNoteUrl && 
                                <div><a href={item.teacherNoteUrl} target="_blank">View Attached file</a></div>
                            }
                        </div>
                    </div>
                }
                {
                    item.explanation && 
                    <div className={styles.explanation}>
                        <h3>Explanation</h3>
                        <div className={styles.full} dangerouslySetInnerHTML={{__html: item.explanation}} />
                    </div>
                }
            </div>
            {
                user.userRole === "Teachers" && studentId && (item.result === "pending" || item.result.includes('%')) &&
                <ManualMarkForm item={item} styles={styles} />
            }    
        </>
    )
}

export default QuestionDetails;