import {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import {MdPerson} from 'react-icons/md';
import { useStore } from '../../../store/useStore';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import classes from './styles/classes.module.scss';
import {Logout} from '../../../services/auth.service';
import { ItemPerPage } from '../../../components/table/pagination';
import Pagination from '../../../components/table/pagination';

const StudentsList = ({classId, lessionId, showModal}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [list, setList] = useState();
    const [pagination, setPagination] = useState();
    const [loading, setLoading] = useState();
    const [itemPerPage, setItemPerPage] = useState(20);
    const [page, setPage] = useState(0);

    useEffect(() => {
        let isCancelled = false;
        setLoading(true);
        const url = API_URL.GET_CLASS_STUDENTS_LIST_URL;
        showModal && axios.get(url,{
            headers: authHeader(),
            params: {
                classId,
                lessionId,
                pNo: page,
                noOfItems: itemPerPage
            }
        })
        .then(response => {
            if(response.data.list && !isCancelled) {
                 response.data.list && setList(response.data.list);
                 response.data.pagination && setPagination(response.data.pagination);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })

        return () => {
            isCancelled = true;
          };
    },[showModal, itemPerPage, page]);

    const onItemPerPageChange = type => setItemPerPage(type.value);
    const onHandlePageChanged = page => setPage(page);

    return (
        <div className={classes.studentsList}>
            <h2>
                Students List 
                {list && list.length > 0 && <span> ({list.length})</span>}
            </h2>
            {pagination && pagination.totalItems > 10 &&
                <ItemPerPage itemPerPage={itemPerPage} onItemPerPageChange={onItemPerPageChange}/>
            }
            {
                loading && 
                <Loader />
            }

            {
                !loading && 
                <div className={classes.list}>
                    {
                        !list || list.length === 0 &&
                        <div className='noResult'>
                            No students found.
                        </div>
                    }
                    {
                        list && list.length > 0 && 
                        <ul>
                            {list.map(item => <li key={uniqueId()}>
                                <div className={classes.topInfo}>
                                    <div className={classes.image}>
                                        {item.imageUrl && 
                                            <img src={item.imageUrl} alt="student" /> 
                                        }
                                        {!item.imageUrl && 
                                            <MdPerson size="70" />
                                        }
                                    </div>
                                    <div>
                                        <strong>{item.fullName}</strong>
                                        <span>{item.userName}</span>
                                        <div className={classes.mainInfo}>
                                            <label>Attendance</label>
                                            <strong>{item.details.attendance}/{item.details.totalLessions}</strong>
                                        </div>
                                        <div className={classes.mainInfo}>
                                            <label>Average Score</label>
                                            <strong>{item.details.averageScore}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.info}>
                                    <div>
                                        <label>Late</label>
                                        <span>{item.details.late}/{item.details.totalLessions}</span>
                                    </div>
                                    <div>
                                        <label>Finished Assignments</label>
                                        <span 
                                            className={
                                                item.details.finishedAssignments === item.details.totalAssignments ? classes.green
                                                : item.details.finishedAssignments < item.details.totalAssignments/2 ? classes.red : ""
                                            }
                                        >{item.details.finishedAssignments}/{item.details.totalAssignments}</span>

                                    </div>
                                    <div>
                                        <label>Highest Score</label>
                                        <span>{item.details.highestScore}</span>
                                    </div>  
                                    <div>
                                        <label>Lowest Score</label>
                                        <span>{item.details.lowestScore}</span>
                                    </div> 
                                </div>
                            </li>)}
                        </ul>
                    }
                    {pagination && (
                            <Pagination
                                total={pagination.totalItems}
                                currentPage={page}
                                itemPerPage={itemPerPage}
                                onChange={onHandlePageChanged}
                            />
                        )}
                </div>
            }
            
        </div>
    )
}

export default StudentsList;