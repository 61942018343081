import {useState, useEffect} from 'react';
import Input from '../../../components/input';

import {Link, useHistory} from 'react-router-dom';
import classes from './styles/classes.module.scss';
import {toast} from 'react-toastify';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { Loader } from '../../../components/loading';
import AssigmentForm from './assigmentForm';

const QuestionForm = ({classId, setShowModal, lessionId}) => {
    const {setUser} = useStore();
    const history = useHistory();
    
    const [isNew, setIsNew] = useState(false);
    const [loading, setLoading] = useState();
    const [selectedLession, setSelectedLession] = useState();
    const [selectedLessionId, setSelectedLessionId] = useState();
    const [resources, setResources] = useState();

    const [selectedClassList, setSelectedClassList] = useState('');

    useEffect(()=>{
        lessionId && getAssignmentList(lessionId);
    },[lessionId]);

    const getAssignmentList = (lessionId) => {
        return axios.get(API_URL.GET_USER_LESSION_RESOURCE_LIST_URL,{
            headers: authHeader(),
            params: {
                lessionId: lessionId
            }
        })
        .then(response => {
            if(response.data) {
                 response.data.list && setResources(response.data.list);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const lessionOnChange = (type) => {
        setSelectedLession(type);
        if(type.value) {
            setSelectedLessionId(type.value);
            setLoading(true);
            getAssignmentList(type.value)
        }
    }

    const sendQuestionOnClick = () => {
        const confirm = window.confirm("Are you sure you want to send this assigment?");
        if(confirm) {
            const data = {lessionId: lessionId, selectedLessionId: selectedLessionId, assignId: selectedClassList};
            axios.post(API_URL.USER_SEND_QUESTION_LIST_URL,data, {
                headers: authHeader()
            })
            .then(response => {
                toast.success('Assignment has been sent successfully!');
            })
            .catch(error => {
                const resMessage = getError(error);
                
                setLoading(false);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }
            })
            setShowModal(false);
        }
    }

    const selectedClassListOnChange = type => setSelectedClassList(type);

    return (
        <div className={classes.form}>
            <h2>Question</h2>
            <div className={classes.blueTabs}>
                <Link className={isNew ? "" : classes.active} to="#" onClick={() => setIsNew(false)}>Resource</Link>
                <Link className={isNew ? classes.active : ""} to="#" onClick={() => setIsNew(true)}>New Assignment</Link>
            </div>
            {
                !isNew && 
                <div className={classes.resources}>
                    <Input
                        className={classes.select}
                        type="async-select"
                        url={`${API_URL.GET_USER_CLASS_LESSIONS_LIST_URL}?classId=${classId}`}
                        placeholder=''
                        onChange={lessionOnChange}
                        label="Select Lession"
                        value={selectedLession}
                        defaultValue={selectedLession || undefined}
                        compareAction={setSelectedLession}
                        compareValue={lessionId}
                    />
                    <div>
                        {loading && <Loader />}
                        {loading === false && !selectedLessionId && !resources && 
                            <div>
                                No assigment available
                            </div>
                        }
                        {!loading && resources && resources.length > 0 && 
                            <ul>
                                <Input
                                    label='Select Assignment'
                                    type="radio"
                                    items={resources}
                                    value={selectedClassList}
                                    onChange={selectedClassListOnChange}
                                /> 
                            </ul>
                        }
                        <div className={classes.buttons}>
                            <button className="btn" onClick={sendQuestionOnClick} disabled={resources && resources.length > 0 ? false : true}>Send</button>
                        </div>
                    </div>
                </div>
            }
            {
                isNew && 
                <AssigmentForm 
                    lessionId={lessionId}
                    setShowModal={setShowModal}
                    isFromMeeting={true}
                />
            }
            </div>
            
    )
}

export default QuestionForm;