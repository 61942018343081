import axios from 'axios';
import { useState, useRef,useEffect } from 'react';
import API_URL from '../../../common/urls';
import Modal from '../../../components/modal';
import TestPlay from '../../../components/testPlay';
import EditName from './modal-editName';
import Revision from './revision';
import SmartSearch from './smartSearch';
import styles from './styles.module.scss';
import { useStore } from '../../../store/useStore';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { toast } from 'react-toastify';
import VideoView from './modal-videoView';
import GenerateWorksheet from './modal-generate';
import DmContent from './dmContent';
import SmartJourney from './smartJourney';
import classNames from 'classnames';

const PageContent = ({subject, activeTab}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [showModal, setShowModal] = useState();
    const contentRef = useRef();
    const [modalType, setModalType] = useState('new');
    const [selectItem, setSelectItem] = useState({});
    const [savedName, setSavedName] = useState('');
    const [spentTime, setSpentTime] = useState({
        startTime: 0,
        endTime: 0
    });

    const openModal = (type, item) => {
        setShowModal(true);
        setModalType(type);
        setSelectItem(item);
        setSpentTime({...spentTime, startTime: new Date()});
    }

    useEffect(()=>{
        if (!showModal) {
            setSpentTime({...spentTime, endTime: new Date()});
        }
    },[showModal]);

    console.log(selectItem);
    return(
        <div className={classNames(styles.content, styles[subject])}>
            <div className={styles.insideContent}>
                <div className={styles.tabContent}>
                    {
                        activeTab === "Daily Mission" &&
                        <DmContent
                            styles={styles} 
                            subject={subject}
                            openModal={openModal}
                            showModal={showModal}
                            spentTime={spentTime}
                            selectItem={selectItem}
                            modalType={modalType}
                        />
                    }
                    {
                        activeTab === "Smart Journey" &&
                        <SmartJourney
                            styles={styles}
                            subject={subject} 
                            openModal={openModal}
                            savedName={savedName}
                            selectItem={selectItem}
                            setSelectItem={setSelectItem}
                        />
                    }
                    {
                        activeTab === "Revision" &&
                        <Revision subject={subject} openModal={openModal}/>
                    }
                    {
                        activeTab === "Smart Search" &&
                        <SmartSearch subject={subject} openModal={openModal}/>
                    }
                </div>
            </div>

            <Modal
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width={["file","video"].includes(modalType) ? "85%" : "400px"}
                height={modalType === "file" ? "90%" : "auto"}
                disabledClickOutside={modalType === "generating"}
                isExpandable={["file","video"].includes(modalType)}
                firstExpand={["video","file"].includes(modalType)}
                bgSet={modalType === "video" ? {background: "black", color: "white"} : undefined}
                content={
                    <div ref={contentRef}>
                        {
                            modalType === "file" && selectItem &&
                            <div className={styles.viewfile}>
                                    {   (selectItem.readingType === "pdf") && 
                                        <iframe width="100%"
                                            height="98%"
                                            src={`${API_URL.COURSEWARE_PDF_VIEW_URL}?docId=${selectItem.readingId}`}>
                                        </iframe>
                                    }

                                    {   (selectItem.readingType !== "pdf") &&
                                        <iframe width="100%"
                                            height="100%"
                                            src={`${selectItem.readingUrl}&embedded=true`}>
                                        </iframe>
                                    }
                            </div>
                        }
                        {
                            modalType === "worksheetNameEdit" && selectItem &&
                            <EditName 
                                savedName={savedName}
                                setSavedName={setSavedName}
                                openModal={openModal}
                                item={selectItem}
                            />   
                        }
                        {
                            modalType === "video" && selectItem && 
                            <VideoView 
                                url={selectItem.videoUrl} 
                                subject={subject} 
                                id={selectItem.videoId} 
                                showModal={showModal}
                                feedback={selectItem.videoFeedback}
                            />
                        }
                        {
                            modalType === "generating" && selectItem &&
                            <GenerateWorksheet 
                                openModal={openModal} 
                                savedName={savedName} 
                                learnId={selectItem.learnId}
                                level={selectItem.level}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                subject={subject}
                            />
                        }
                    </div>
                }
            />
            { modalType === "worksheet" && selectItem && 
                    <TestPlay
                        setShowModal={setShowModal} 
                        showModal={showModal} 
                        contentRef={contentRef}
                        themeId={selectItem.themeId}
                        isPreview={false}
                        worksheetId={selectItem.worksheetId}
                    />
                }
        </div> 
    )
}

export default PageContent;