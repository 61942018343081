import {useEffect, useState} from 'react';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import InputSearch from '../../../components/input/inputSearch';
import Input from '../../../components/input';
import {OPTIONS_TYPE} from '../../../common/utils';
import {getError} from '../../../common/utils';
import connection from './connection.module.scss';
import styles from '../styles.module.scss';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import {useHistory} from 'react-router-dom';

const ConnectionForm = ({setShowModal, getList, type, setType, list, setList, editItem, setEditItem}) => {
    const history = useHistory();
    const {setUser, user} = useStore();
    const [search, setSearch] = useState('');
    const [groupName, setGroupName] = useState('');
    const [relationship, setRelationship] = useState({label: '', value: ''});
    const [toUsers, setToUsers] = useState({users: []});

    const searchOnChange = (e) => setSearch(e.target.value);
    const relationshipOnChange = (value) => setRelationship(value);
    const groupNameOnChange = (e) => setGroupName(e.target.value);

    const onAdd = () => {
        if(type === 'connection' && !search) {
            toast.error(`User name, phone or email is empty!`);
            return;
        }

        if(type === 'connection' && !relationship.value) {
            toast.error(`Please choose a relationship!`);
            return;
        }

        if (type === 'group' && !groupName) {
            toast.error(`Group name is empty!`);
            return;
        }

        if (type === 'group' &&  !toUsers.users.length) {
            toast.error(`Please select users to group!`);
            return;
        }

        const data = type === 'connection' ? {search, relationship, type: "request"} : editItem ? {groupName, users: toUsers.users, type: "group-edit"} : {groupName, users: toUsers.users, type: "group-new"};

        axios.post(API_URL.USER_CONNECTIONS_REQUEST_URL, data,{
            headers: authHeader()
        })
        .then(response => {
            if (type === 'connection') {
                toast.success('Your request is sent. Please wait until they confirm!');
            } else if (editItem){
                const index = list.customGroups.indexOf(editItem);
                
                if(list.customGroups[index]) {
                    const newGroup = {...list.customGroups[index], name: groupName,list: toUsers.users};
                    list.customGroups.splice(index, 1, newGroup);
                    setList({...list});
                }
            } else {
                const newGroup = {
                    id: response.data.groupId ? response.data.groupId : 'tempId', 
                    name: groupName,
                    list: toUsers.users
                }
                list && list.customGroups && list.customGroups.push(newGroup);
                setList({...list});
            }
            setShowModal(false);
            setSearch('');
            setRelationship({label: '', value: ''});
            setGroupName('');
            toUsers.users = [];
            setToUsers({...toUsers});
            setEditItem && setEditItem(null);
            // getList && getList(true);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage); 
            }
        })
    }

    const getResult = (value) => {
        setSearch(value);
    }

    useEffect(()=>{
        if (type) {
            setSearch('');
            setRelationship({label: '', value: ''});
            setGroupName('');
            toUsers.users = [];
            setToUsers({...toUsers});
        }
    },[type]);

    useEffect(()=>{
        editItem && editItem.name && setGroupName(editItem.name);
        editItem && editItem.list && setToUsers({users: editItem.list});
    },[editItem]);

    return (
        <div className={connection.connectionForm}>
            {
                type === "connection" &&
                <>
                    <h2>Add Connection
                    {
                        user.userRole === "Teachers" &&
                        <span onClick={() => setType('group')}>New Group</span>
                    }
                    </h2>
                    <InputSearch 
                        placeholder='Search...'
                        onChange={searchOnChange}
                        label="User name, phone or email"
                        value={search}
                        apiUrl={API_URL.CONNECTION_SEARCH_URL}
                        getResult={getResult}
                    />
                    <Input
                        className={styles.select}
                        type="select"
                        options={OPTIONS_TYPE.RELATIONSHIP}
                        placeholder=''
                        onChange={relationshipOnChange}
                        label="Relationship"
                    />
                    <div className={connection.buttons}>
                        <button className="btn" onClick={onAdd}>Request</button>
                    </div>
                </>
            }
            {
                type === 'group' &&
                <div className={connection.group}>
                    <h2>{editItem ? 'Edit' : 'Add'} Group
                    {
                        user.userRole === "Teachers" && !editItem && 
                        <span onClick={() => setType('connection')}>New Connection</span>
                    }
                    </h2>
                    <Input
                        type="text"
                        placeholder=''
                        onChange={groupNameOnChange}
                        label="Group name"
                        value={groupName}
                    />
                    <InputSearch
                        placeholder='Search...'
                        onChange={searchOnChange}
                        label="Select users to new group"
                        value={search}
                        setValue={setSearch}
                        apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                        formData={toUsers}
                        setFormData={setToUsers}
                        collection='users'
                        useFullResult
                        isMulti
                    />
                    <div className={connection.buttons}>
                        <button className="btn" onClick={onAdd}>Submit</button>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default ConnectionForm