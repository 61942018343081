import { useState, useEffect } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError, toastCredit } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import { useHistory } from "react-router-dom";
import moment from "moment";


const CreditExchangeTracking = ({connection}) => {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const {setUser, moreInfo, setMoreInfo, setToastContent} = useStore();

    useEffect(()=>{
        getList();
        return () => {
            setList([]);
          };
    },[]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_CREDIT_EXCHANGE_TRACKING_URL,{
            headers: authHeader(),
            params: {
                // type: curTab
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.list && setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const setRoute = (index, trackingId, productId, type) => {
        axios.post(API_URL.USER_CREDIT_EXCHANGE_ACTION_URL,{
            trackingId: trackingId,
            productId: productId,
            action: type,
        },
        {
            headers: authHeader()
        })
        .then(response => {
            list[index].route = type;
            list[index].lastUpdated = new Date();
            setList([...list]);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
        
    }

    const onCancel = (index, trackingId, productId, type, credit) => {
        setToastContent && setToastContent(toastCredit('+', credit));
        moreInfo.credit = moreInfo.credit + credit;
        setMoreInfo({...moreInfo});
        setRoute(index, trackingId, productId, type);
    }

    return (
        <div className={connection.giftsTracking}>
            {
                loading && <Loader />
            }
            {
                !loading && list && list.length === 0 &&
                <div className='noResult'>
                    No item found.
                </div>
            }
            {
                !loading && list && list.length > 0 &&
                <div>
                    <ul>
                        <li className={connection.title}>
                            <span>Image</span>
                            <span>Name</span>
                            <span>Credit</span>
                            <span>Route</span>
                            <span>Last Updated</span>
                        </li>
                    {
                        list.map((item, index) => 
                            <li key={`exchange-${index}`}>
                                {item.imageUrl && <span><img src={item.imageUrl} height="30" /></span>}
                                <div>
                                    <span>{item.name}</span>
                                </div>
                                <span>{item.credit}</span>
                                <div className={`${connection.route} ${connection.take} ${connection[item.route]} ${connection.allowCancel}`}>
                                    <div>
                                        <span>Pending</span>
                                        <span>Preparing</span>
                                        <span>Delivering</span>
                                        <span onClick={() => setRoute(index, item.trackingId, item.productId, 'received')}>Received</span>
                                        <span onClick={() => onCancel(index, item.trackingId, item.productId, 'cancelled', item.credit)}>
                                            {item.route === 'cancelled' ? 'Cancelled' : 'Cancel'}
                                        </span>
                                    </div>
                                </div>
                                <span>{moment(item.lastUpdated).format('DD/MM/YYYY HH:mm:ss')}</span>
                            </li>
                        )
                    }
                    </ul>
                </div>
            }
        </div>
    )
}

export default CreditExchangeTracking;