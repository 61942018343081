import { useEffect, useState } from 'react';
import image from '../../images/home-explain.png';
import styles from './styles.module.scss';

const Products = ({data}) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (data && data.list && data.list.length) {
            setList(data.list);
        }
    }, [data]);

    return (
        <div className={styles.products}>
            <p id='products'></p>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            
            {
                list && list.length &&
                <div>
                    <img src={image} alt="product" />
                    {
                        list[0] && list[1] &&
                        <div className={styles.topExplanation}>
                            <div className={styles.item}>
                                <h3>{list[0].title}</h3>
                                <div dangerouslySetInnerHTML={{__html: list[0].content}} />
                            </div>
                            <div className={styles.item}>
                                <h3>{list[1].title}</h3>
                                <div dangerouslySetInnerHTML={{__html: list[1].content}} />
                            </div>
                        </div>
                    }  
                    {
                        list[2] && list[3] &&
                        <div className={styles.rightExplanation}>
                            <div className={styles.item}>
                                <h3>{list[2].title}</h3>
                                <div dangerouslySetInnerHTML={{__html: list[2].content}} />
                            </div>
                            <div className={styles.item}>
                                <h3>{list[3].title}</h3>
                                <div dangerouslySetInnerHTML={{__html: list[3].content}} />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Products;