import {useState} from 'react';
import classNames from 'classnames';
import Select from '../../components/select';
import ASyncSelect from '../../components/select/async';
import styles from './input.module.scss';
import {Fragment} from 'react';
import {RadioGroup} from '../../components/radio';
import {MdVisibilityOff, MdVisibility, MdAttachFile} from 'react-icons/md';
import {flagOptions} from '../../common/utils';
import {uniqueId} from 'lodash';

const Input = ({
  className,
  label,
  type,
  name,
  value,
  onSelectChange,
  placeholder,
  error,
  onChange,
  onBlur,
  icon,
  subIcon,
  defaultValue,
  options,
  disabled,
  isClearable,
  isMulti,
  url,
  min,
  max,
  onInputValueChange,
  isAsync,
  isMandatory,
  rows = 5,
  loading,
  autoSelect,
  onKeyPress,
  buttonOnClick,
  buttonText,
  items,
  checkboxLabel,
  style,
  selectDefault,
  selectValue,
  length,
  files,
  accept,
  fileNames,
  autoFocus,
  compareAction,
  compareValue,
  labelLink,
  labelLinkName,
  isOptional,
  endText
}) => {
  const [passType, setPassType] = useState('password');
  const inputClass = classNames(styles.input, className, {
    [styles.errorWrapper]: error && error !== '',
    [styles.selectWrapper]:
      type === "select" ||
      type === "async-select" ,
    [styles.hasIcon]: icon !== undefined,
  });

  const onInputChange = (e) =>{
    let val = e.target.value;
    onChange &&
    onChange({
      ...e,
      target: {value: val},
    });
  };

  const onRadioChange = (value) => onChange && onChange(value);

  const onChangePassType = () => {
    if(passType === "password"){
      setPassType("text") 
    }
    else {
      setPassType("password") 
    }
  }
  
  return (
    <div 
      className={classNames(styles.field, className, 
        {[styles.passWithView]: type === "pass-with-view",
         [styles.checkbox]: type === "checkbox" 
         })}
      style={style}
      >
      {label && (
        <label className={styles.label}>
          {label}
          {isOptional && <i>Optional</i>}
          {isMandatory && <sup>*</sup>}
          {labelLink && <a href={labelLink}>{labelLinkName}</a>}
        </label>
      )}

      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {subIcon && <div className={styles.subIconWrapper}>{subIcon}</div>}

      {type === "radio" && (
        <RadioGroup items={items} value={value} onChange={onRadioChange} />
      )}

      {type === "checkbox" && (
        <div>
          <input 
            type="checkbox" 
            onChange={onChange}
            checked={value}
          />
          <label>{checkboxLabel}</label>
        </div>
      )}

      {type === "text" && (
        <input
          className={inputClass}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onInputChange}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
          maxLength={length}
          onBlur={onBlur}
          autoFocus={autoFocus}
        />
      )}

      {type === "text-button" && (
        <Fragment>

          {onSelectChange && 
            <Select
              className={classNames(inputClass, styles.select, {[styles.selectDisabled]: disabled})}
              name={name}
              defaultValue={selectDefault}
              value={selectValue}
              onChange={onSelectChange}
              options={flagOptions}
              isClearable={isClearable}
              onKeyPress={onKeyPress}
              valueRenderer={(option) => option.subString(0,10)}
            />
          }
            
            <input
                className={inputClass}
                type="text"
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onInputChange}
                disabled={disabled}
                onKeyPress={onKeyPress}
            />
            { buttonText && 
              <input 
                type="button"
                onClick={buttonOnClick}
                value={buttonText}
                disabled={disabled}
              />    
            }
        </Fragment>   
      )}
      {type === "textarea" && (
        <textarea
          className={inputClass}
          rows={rows}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onInputChange}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          onBlur={onBlur}
        />
      )}
      
      {type === "number" && (
        <input
          className={inputClass}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onInputChange}
          min={min}
          max={max}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
        />
      )}
      {type === "email" && (
        <input
          className={inputClass}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
        />
      )}
      {type === "password" && (
        <input
          className={inputClass}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
        />
      )}
      {type === "pass-with-view" && (
        <div className={styles.passWithView}>
          <input
            // className={inputClass}
            type={passType}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            onKeyPress={onKeyPress}
          />
          {passType==="password" && <MdVisibilityOff onClick={onChangePassType} />}
          {passType==="text" && <MdVisibility onClick={onChangePassType}/>}
        </div>
      )}
      {type === "select" && (
        <Select
            className={classNames(inputClass, styles.select, {[styles.selectDisabled]: disabled})}
            isMulti={isMulti}
            name={name}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            isClearable={isClearable}
            onKeyPress={onKeyPress}
            isDisabled={disabled}
        />
      )}
      {type === "async-select" && (
        <ASyncSelect
            className={classNames(inputClass, styles.select, {[styles.selectDisabled]: disabled})}
            isMulti={isMulti}
            name={name}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            isClearable={isClearable}
            url={url}
            onInputChange={onInputValueChange}
            isAsync={isAsync}
            autoSelect={autoSelect}
            onKeyPress={onKeyPress}
            compareValue={compareValue}
            compareAction={compareAction}
            isDisabled={disabled}
        />
      )}

      {type === "file" && (
        <div>
          <input
              type={type}
              className={inputClass}
              multiple={isMulti}
              name={name}
              accept={accept}
              onChange={onChange}
              value={value}
              files={files}
          />
          {!fileNames.length &&
            <ul>
                <div>Drag or Click to select file</div>
            </ul>
          }
          {fileNames && fileNames.length > 0 && 
                <ul>
                    {fileNames.map(item => <li key={uniqueId()}><MdAttachFile />{item}</li>)}
                </ul>
          }
          {/* {
            !Array.isArray(fileNames) && !isMulti &&
            <ul>
              <li key={uniqueId()}><MdAttachFile />{fileNames}</li>
            </ul>
          } */}
        </div>
      )}
      {endText && <span>{endText}</span>}
      {error && <span className={styles.error}>{error}</span>}
      {loading && (
        <div className={styles.spinner}>
          <div className={styles.bounce1} />
          <div className={styles.bounce2} />
          <div className={styles.bounce3} />
        </div>
      )}
    </div>
  );
};

export default Input;
