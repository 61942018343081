import {useEffect, useState, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import dashboard from './dashboard.module.scss';
import Chart from './chart';
import {MdQuestionAnswer, MdAccessTime, MdGroupWork, MdAssignmentTurnedIn} from 'react-icons/md';
import {uniqueId} from 'lodash';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';

const QuickView = () => {
    const ref = useRef();
    const history = useHistory();
    const {setUser} = useStore();
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [loadingSj, setLoadingSj] = useState();  
    const [subjects, setSubjects] = useState([]);  
    const [curSubject, setCurSubject] = useState('');                                            

    useEffect(()=>{
        getData(API_URL.GET_USER_DASHBOARD_QUICK_VIEW_SUBJECTS_URL, setLoadingSj, setSubjects);
    },[]);

    useEffect(()=>{
        getData(API_URL.GET_USER_DASHBOARD_QUICK_VIEW_URL, setLoading, setData);
    },[curSubject]);

    const getData = (url, setLoading, setData) => {
        setLoading && setLoading(true);
        return axios.get(url,{
            headers: authHeader(),
            params: curSubject ? {
                subject: curSubject,
            } : {}
        })
        .then(response => {
            if(response.data) {
                 response.data && setData && setData(response.data);
                 setLoading && setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading && setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={dashboard.dashboard}>                 
            <div className={dashboard.topInfo}>
                <div className={dashboard.chartSj}>
                    {
                        loadingSj && <Loader />
                    } 
                    {
                        !loadingSj && subjects && <Chart data={subjects} height={200} chartType="pie2" clickFunction={setCurSubject} />
                    }
                </div>
                {
                    loading && <Loader />
                }
                {
                    !loading && data &&
                    <div className={dashboard.boxes}>
                        <div>
                            <label><MdGroupWork size="20"/>Live class attendance</label>
                            <div>
                            <strong>{data.liveClassAttended}</strong>
                            </div>    
                        </div>
                        <div>
                            <label><MdQuestionAnswer size="20"/>Total questions attempts</label>
                            <div>
                            <strong>{data.questionsAttempts}</strong>
                            </div>    
                        </div>
                        <div>
                            <label><MdAccessTime size="20"/>Total time spent</label>
                            <div>
                            <strong>{data.questionsTime} <span>mins</span></strong>
                            </div>    
                        </div>
                        <div>
                            <label><MdAssignmentTurnedIn size="20"/>Total skill gained</label>
                            <div>
                            <strong>{data.totalSkillGained}</strong>
                            </div>    
                        </div>
                    </div>
                }
            </div>
            {
                !loading && data &&
                <div className={dashboard.charts}>
                    <div className={dashboard.pieChart}>
                        <label>Learning progress</label>
                        <div>
                            {/* <Chart data={data.learningProgress} height={180} chartType="column" /> */}
                            <Chart data={data.learningProgress} height={180} chartType="pie" />
                        </div>
                    </div>
                    <div className={dashboard.pieChart}>
                        <label>Answer result</label>
                        <div>
                            <Chart data={data.studentsResult} height={180} chartType="column" />
                        </div>
                    </div>
                    <div className={dashboard.feedback}>
                        <label ref={ref}>Relevant Live classes &amp; Bootcamp</label>
                        <div className={dashboard.list}>
                            {
                                data.suggestClasses && data.suggestClasses.length > 0 &&
                                data.suggestClasses.map(item => (
                                    <div key={uniqueId()}>
                                        <Link to={`/products/classes/list/${item.classId}`} target="_blank">{item.className}</Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default QuickView;