import connection from './connection2.module.scss';
import { useState} from "react";
import ChallengesList from './challengesList';
import ChallengesHistory from './challengesHistory';

const Challenges = () => {
    const [curTab, setCurTab] = useState('challengesList');

    return (
        <div className={connection.gifts}>
            <div className="leaderBoard">
                <div className="topTabs">
                    <button onClick={() => setCurTab('challengesList')} className={curTab === "challengesList" ? "active" : undefined}>All Challenges</button>
                    <button onClick={() => setCurTab('history')} className={curTab === "history" ? "active" : undefined}>Challenges History</button>
                </div>
            </div>
                {
                    curTab === "challengesList" &&
                    <ChallengesList connection={connection} />
                }
                {
                    curTab === "history" &&
                    <ChallengesHistory connection={connection} />
                }
        </div>
    )
}

export default Challenges;