import { useState, useEffect, useRef } from "react";
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import {toast} from 'react-toastify';
import { useHistory } from "react-router-dom";
import { MdAttachFile } from "react-icons/md";

const ReportProblem = ({tests, showModal2, setShowModal2, questionId}) => {
    const [problem, setProblem] = useState('');
    const [explain, setExplain] = useState('');
    const {setUser} = useStore();
    const history = useHistory();
    const inputRef = useRef();
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    useEffect(()=>{
        !showModal2 && setProblem('');
    },[showModal2]);

    const onSubmit = () => {
        const data = new FormData();
        data.append('action', 'report-problem');
        data.append('id', questionId);
        problem && data.append('problemType', problem);
        data.append('info', explain);
        file && data.append('screenshot', file[0]);
        
        return axios.post(API_URL.POST_USER_LIVE_TEST_ACTION_URL,data,  
            {
                headers: authHeader()
            }).then(response => {
                toast.success('Your report has been sent!');
                setShowModal2(false);
            })
            .catch(error => {
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }      
            });
    }

    const fileOnChange = (e) => {
        setFile(e.target.files);
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        }
    }

    return(
        <div className={tests.reportProblem}>
            <h2>Report Problem</h2>
            <div>
                {
                    !problem &&
                    <ul>
                        <li onClick={() => setProblem('Solution problem')}>Solution problem</li>
                        <li onClick={() => setProblem('Image problem')}>Image problem</li>
                        <li onClick={() => setProblem('Question content problem')}>Question content problem</li>
                        <li onClick={() => setProblem('Other reason')}>Other reason</li>
                    </ul>
                }
                {
                    problem &&
                    <div className={tests.problem}>
                        <div onClick={() => setProblem( '')}>{problem}</div>
                        <textarea placeholder="Explain more details about the issue" onChange={(e) => setExplain(e.target.value)} />
                        <span onClick={() => inputRef.current.click()}><MdAttachFile /> {fileName ? fileName : 'Attach the screenshot'}</span>
                        <input type="file" ref={inputRef} onChange={fileOnChange}/>
                        <div className="rightAlign">
                            <button onClick={onSubmit}>Submit</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ReportProblem;