import { useRef, useState, useEffect } from 'react';
import { Loader } from '../../../components/loading';
import Input from '../../../components/input';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError } from '../../../common/utils';
import { toast } from 'react-toastify';
import API_URL from '../../../common/urls';
import { FaCheck } from 'react-icons/fa';
import bookIcon from '../../../images/reading.png';
import worksheetIcon from '../../../images/ws.svg';
import muscleIcon from '../../../images/muscle.svg';
import classNames from 'classnames';
import { FaYoutube } from 'react-icons/fa';

const SmartJourney = ({styles, subject, openModal, selectItem, setSelectItem}) => {
    const [data, setData] = useState([]);
    const {setUser, user} = useStore();
    const [loading, setLoading] = useState(false);
    const [level, setLevel] = useState(user && user.grade ? {label: user.grade, value: user.grade} : {});
    const [levelsList, setLevelsList] = useState([]);
    const [generatedType, setGeneratedType] = useState('auto');
    const history = useHistory();
    const [topics, setTopics] = useState([]);

    useEffect(()=>{
        level && getList();
    },[level, subject]);

    useEffect(()=>{
        if(data && data.length) {
            const tp = data.map(item => item.topic);
            setTopics([...new Set(tp)]);
        }
    },[data]);

    useEffect(()=>{
        generatedType === "auto" && setSelectItem({...selectItem, worksheetName: null, learnId: 'AUTO'});
    },[generatedType]);

    useEffect(()=>{
        axios.get(API_URL.GET_SMART_JOURNEY_LEVELS_LIST_URL,{
            headers: authHeader()
        }).then(response => {
            const availableLevels = user.subscriptionLevels;
            const arr = [];
            if (response.data.grades && response.data.grades.length) {
                const levels = response.data.grades.map(item => item.label);
                response.data.grades.forEach(item => {
                    if (availableLevels.includes(item.label)) {
                        arr.push(item);
                    }
                });
                setLevelsList(arr);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    },[]);

    const getList = () => {
        setLoading(true);
        const url = API_URL.GET_SMART_JOURNEY_LIST_URL;
        return axios.get(url,{
            headers: authHeader(),
            params:{
                subject,
                level: level && level.value
            }
        })
        .then(response => {
            setLoading(false);
            response.data.list && setData(response.data.list);
            response.data.levels && setLevelsList(response.data.levels);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const levelOnChange = (type) => {
        setLevel(type);
        setSelectItem({worksheetName: null, learnId: 'AUTO', level: type && type.value});
    }

    const generateWorksheet = e => {
        e.preventDefault();
        generatedType === 'custom' && selectItem.learnId && openModal('worksheetNameEdit', selectItem);
        generatedType === 'auto' && openModal('generating', selectItem);
    }

    const setItem = (item) => {
        if(selectItem && selectItem.learnId !== item.learnId) 
         {
            setSelectItem({...selectItem,
                worksheetName: item.topic + ' - ' + item.subTopic,
                learnId: item.learnId,
            });
        } else {
            setSelectItem({...selectItem,worksheetName: null, learnId: 'AUTO'});
        }
    }

    const getTopicColor = (topic) => {
        let color = styles.color1;
        switch(true) {
            case topics.filter((item, index) => index%5 === 1).includes(topic):
                color = styles.color2;
                break;
            case topics.filter((item, index) => index%5 === 2).includes(topic):
                color = styles.color3;
                break;
            case topics.filter((item, index) => index%5 === 3).includes(topic):
                color = styles.color4;
                break;
            case topics.filter((item, index) => index%5 === 4).includes(topic):
                color = styles.color5;
                break;        
        }
        return color; 
    }

    console.log(levelsList);
    return (
        <div className={classNames(styles.history, styles.journey)}>
            <div className='flex'>
                <div className={styles.generated}>
                    <ul className='radioList'>
                        <li className={generatedType === "auto" ? "active" : undefined} onClick={() =>  setGeneratedType('auto')}>Auto</li>
                        <li className={generatedType === "custom" ? "active" : undefined} onClick={() =>  setGeneratedType('custom')}>Custom</li>
                    </ul>
                    <a href="" onClick={generateWorksheet} className={generatedType === "custom" && selectItem && !selectItem.worksheetName ? 'disabledButton' : ''}>Generate worksheet</a>
                </div>
                <div className={styles.level}>
                    <Input
                        className={styles.select}
                        type="select"
                        options={levelsList}
                        placeholder='Level'
                        onChange={levelOnChange}
                        value={level}
                        label=""
                        disabled={levelsList.length?false:true}
                    />
                </div>
            </div>
            
            <div className={styles.heading}>
                <span>Topic</span>
                <span>Sub Topic</span>
                <span>Mastery Level</span>
                <span>Progress</span>
                <span></span>
            </div>
            {
                loading && <Loader />
            }
            {
                !loading &&
                <>
                    {
                        data.length === 0 &&
                        <div className='noResult'>No item found.</div>
                    }
                    {
                        data.length > 0 &&
                        <ul>
                            {
                                data.map((item,index) => 
                                <li key={`r ${index}`} className={getTopicColor(item.topic)}>
                                    <span>
                                        <div className={`checkBox ${selectItem && selectItem.learnId === item.learnId ? "active" : ''} ${styles.checkBox} 
                                        ${generatedType !== "custom" ? "disabled" : undefined}`}
                                        onClick={() => setItem(item)}>
                                            <FaCheck />
                                        </div> {item.topic}
                                    </span>
                                    <span>{item.subTopic}</span>
                                    <span>
                                        {
                                            item.masteryLevel > 0 &&
                                            Array.from(Array(item.masteryLevel)).map((itm, index) => 
                                                <img key={`img${index}`} src={muscleIcon} alt='muscle' height={18} title={item.masteryLevel} style={{marginLeft: 5}} />    
                                            )
                                        }
                                    </span>
                                    <span className={styles[`span${item.progress}`]} title={`${((item.progress*100)/3).toFixed(0)}%`}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                    <span className={styles.lastColumn}>
                                        {
                                            item.readingId &&
                                            <span>
                                            <img src={bookIcon} height="28" title="Reading" onClick={() => openModal('file', item)}/>
                                            {
                                                item.readingDone && <FaCheck className={styles.check} />
                                            }
                                            </span>
                                        }
                                        {
                                            item.videoId &&
                                            <span>
                                            <FaYoutube color="#bb0000" size="30" title="View Video" onClick={() => openModal('video', item)}/>
                                            {
                                                item.videoDone && <FaCheck className={styles.check} />
                                            }
                                            </span>
                                        }
                                        {
                                            item.worksheetId &&
                                            <a href={`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`}>
                                                <img src={worksheetIcon} height="25" title="Play worksheet" />
                                                {
                                                    item.worksheetDone && <FaCheck className={styles.check} />
                                                }
                                            </a>
                                        }
                                    </span>
                                </li>
                                )
                            }
                        </ul>
                    }
                </>
            }
        </div>
    )
}

export default SmartJourney;