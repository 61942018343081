import {Fragment, useState, useRef} from 'react';
import {NavLink, Link, useHistory} from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import styles from './styles.module.scss';
import {MdArrowDropDown, MdArrowForward, MdAccountCircle, MdNotifications} from 'react-icons/md';
import classNames from 'classnames';
import {topMenus} from '../../common/utils';
import {useStore} from '../../store/useStore';
import {Logout} from '../../services/auth.service';
import useClickOutside from '../../hooks/useClickOutside';
import Alerts from '../alerts';

const MainMenu = ({subMenus, type}) => {

    return (
        <ul>
            {topMenus.length > 0 && 
                topMenus.map(item => {
                    return (
                        <Fragment key={uniqueId()}>
                            {(item.type === type || !item.type) && 
                            <li>
                                <a href={item.url} className={styles.link}>{item.label}</a>
                            </li> 
                            } 
                        </Fragment>
                        
                    )
                })
            }
        </ul>
    )
}

export const MobileMenu = ({isMenuOpen, setMenuOpen, subMenus}) => {
    const history = useHistory();
    const {user, setUser, cartNumber, alertsCount, setAlertsCount} = useStore();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);
    const buttonRef = useRef();
    useClickOutside(buttonRef, () => setShowAlerts(false));

    const onCloseClick = () => {
        setMenuOpen && setMenuOpen(false);
    }

    const openSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    }

    const alertsOnClick = () => {
        setShowAlerts(true);
    }

    const logout = () => {
        Logout();
        setUser(null);
        history.push('/login');
    }

    return (
        <ul className={classNames(styles.mMenu,{[styles.open]: isMenuOpen})}>
            <li className={styles.topItems}>
                <div className={styles.userState}>
                {!user &&
                <Fragment>
                    <NavLink to="/login" onClick={onCloseClick}>Log In</NavLink>
                    <NavLink to="/register" onClick={onCloseClick}>Sign Up</NavLink>
                </Fragment>
                }
                {user && 
                    <>
                    <div ref={buttonRef} className={styles.notification} onClick={alertsOnClick}>
                        <MdNotifications size="25"/>
                        {alertsCount > 0 && <span>{alertsCount}</span>}
                        <Alerts showAlerts={showAlerts} setAlertsCount={setAlertsCount} onClick={onCloseClick}/>
                    </div>
                    
                    <div className={styles.menuUser}>
                        <MdAccountCircle className={styles.userLink} size="25"/>
                        <div className={styles.dropdown}>
                            <div className={styles.account}>
                                {
                                    !user.userImageUrl &&
                                    <MdAccountCircle className={styles.userLink} size="50"/>
                                }

                                {
                                    user.userImageUrl &&
                                    <div>
                                        <img src={user.userImageUrl} alt="avatar" />
                                    </div>
                                }
                                {user.full_name}
                             </div>
                             <div className={styles.links}>
                                <Link to="/user/profile" onClick={onCloseClick}>Profile</Link>
                                <Link to="/user/classes" onClick={onCloseClick}>My classes</Link>
                                <Link to="/login" onClick={logout}>Logout</Link>
                             </div>
                            
                        </div>
                    </div>
                    </>
                }
                </div>
                <button className={styles.closeBtn} onClick={onCloseClick}>&times;</button>
            </li>
            
            {topMenus.length > 0 && 
                topMenus.map(item => 
                (
                    <li key={uniqueId()} >
                        <a href={item.url} className={styles.link} onClick={onCloseClick}>{item.label}</a>
                        {item.label === "Live Classes" && subMenus && subMenus.length > 0 && <MdArrowDropDown onClick={openSubMenu} size="25"/>}
                        {item.label === "Live Classes" && subMenus && subMenus.length > 0 && 
                                <div className={classNames(styles.subMenus,{[styles.open]: isSubMenuOpen})}>
                                    <div className={styles.lists}>
                                        {
                                            subMenus.map(subItm => 
                                            (
                                                <div key={uniqueId()} >
                                                    <h3>{subItm.listName}</h3>
                                                    {subItm.items.length > 0 && 
                                                        <ul>
                                                            {subItm.items.map(menu => (
                                                                <li key={uniqueId()} >
                                                                    <MdArrowForward />
                                                                    <NavLink to={menu.url} onClick={onCloseClick}>{menu.label}</NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </div>
                                            )    
                                            )
                                        }
                                        <ul>
                                        </ul>
                                    </div>
                                    <NavLink className={styles.viewAll} to={item.url} onClick={onCloseClick}>View All</NavLink>    
                                </div>
                            }
                    </li>
                )  
                )
            }
        </ul>
    )
}

export default MainMenu;