import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

const HowToStart = ({data, curIndex, setCurIndex}) => {
    const history = useHistory();
    return (
        <div className={styles.howStart}>
            <p id='howStart'></p>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            {
                data && data.list && data.list.length > 0 &&
                <div>
                    <div className={styles.steps}>
                        {
                            data.list.map((item, index) => 
                            <div key={`howStart${index}`} className={curIndex === index ? styles.active : ''}
                                data-number={index+1} onClick={() => setCurIndex(index)}
                            >
                                <span>{item.title}</span>
                            </div>
                            )
                        }
                    </div>
                    <ul>
                        {
                            data.list.map((item, index) => 
                            <li key={`howStart${index}`} className={curIndex === index ? styles.active : ''}>
                                <h3 className={styles.title}>
                                    {item.title}
                                </h3>
                                <div className={styles.content}>
                                    <div>
                                        <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                                        {
                                            index === 0 &&
                                            <div className='homeBtns'>
                                                <button className='homeBtn btn' onClick={() => history.push('/register')}>Sign Up Now</button>
                                            </div>
                                        }
                                        {
                                            index === 1 &&
                                            <div className='homeBtns'>
                                                <button className='homeBtn btn' onClick={() => history.push('/register')}>Sign Up Now</button>
                                                <button className='btn' onClick={() => history.push('/login')}>Log In Now</button>
                                            </div>
                                        }
                                        {
                                            index === 4 &&
                                            <div className='homeBtns'>
                                                <button className='homeBtn btn' onClick={() => history.push('/login')}>Log In Now</button>
                                            </div>
                                        }
                                    </div>
                                    {
                                        item.imageUrl && index !== 2 &&
                                        <img src={item.imageUrl} alt='image' />
                                    }
                                    {
                                        index === 2 && item.content2 &&
                                        <div className={styles.note} dangerouslySetInnerHTML={{__html: item.content2}} />
                                    }
                                </div>
                            </li>
                            )
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

export default HowToStart;