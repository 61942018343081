import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import meeting from './styles/meeting.module.scss';
import { Loader } from '../../../components/loading';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { QuestionsList } from './assignmentReview';
import classNames from 'classnames';

const StudentReports = ({studentId, classId, lessionId, studentName, assignId}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [studentData, setStudentData] = useState(null);
    const [loading, setLoading] = useState();

    const getList = (condition) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_CLASS_REPORTS_STUDENT_LIST_URL,{
            headers: authHeader(),
            params: {classId, lessionId, assignId, studentId: studentId}
        })
        .then(response => {
            if(response.data && condition) {
                 response.data && setStudentData(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    useEffect(()=>{
        let isCancelled = false;
        getList(!isCancelled);
        return () => {
            isCancelled = true;
          };
    },[]);

    return (
        <div className={meeting.studentReports}>
            {
                loading && <Loader />
            }
            {
                !loading && studentData && 
                <div>
                    <div className={meeting.flex}>
                        <p className={classNames(meeting.reportInfo, meeting.reportTopInfo)}>Total questions <strong>{studentData.totalQuestions}</strong></p>
                        <p className={classNames(meeting.reportInfo, meeting.reportTopInfo)}>Total time <strong>{studentData.totalAnswerTime ? `${Math.floor(studentData.totalAnswerTime/60) > 0 ? 
                            Math.floor(studentData.totalAnswerTime/60) + ' mins' : ""} ${studentData.totalAnswerTime%60 ? studentData.totalAnswerTime%60 + ' secs': ''}` : 0}</strong></p>
                        <p className={classNames(meeting.reportInfo, meeting.reportTopInfo)}>Passed 
                            <strong>
                                {studentData.rightAnswers}
                                {studentData.totalQuestions > 0 && <span> ({Math.floor(parseInt(studentData.rightAnswers)/parseInt(studentData.totalQuestions)*100)}%)</span>}
                            </strong>
                        </p>
                    </div>
                    
                    {
                        studentData.list && studentData.list.length > 0 &&
                        <>
                            <h4>Questions List</h4>
                            <QuestionsList list={studentData.list} studentId={studentId} studentName={studentName}/>
                        </>
                    }
                    
                </div>
                
            }

        </div>
    )
}

export default StudentReports;