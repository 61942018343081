import { useState, useEffect } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError, newWorksheetSteps, worksheetThemes } from '../../../common/utils';
import { useStore } from "../../../store/useStore";
import { useHistory } from "react-router";
import { Logout } from "../../../services/auth.service";
import lib from './new.module.scss';
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Steps from "../../../components/steps";
import NewQuestion from "./newQuestionForm";
import WorksheetReview from "./stepReview";

const NewItems = ({type, modalType, setModalType, folderId, showModal, setShowModal, setReloadList, showQuestionForm, setShowQuestionForm, 
    selectItem, questionLength, setPercentCompleted, saving, setSaving, isClone, setIsClone, setImportId, parentType, setReload,
    importList, setImportList}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [files, setFiles] = useState({});
    const [wsFile, setWsFile] = useState({});
    const [selectQItem, setSelectQItem] = useState(null);
    const [editQData, setEditQData] = useState(null);
    const worksheetEdit = (modalType === "edit" && type === 'worksheet');
    const [uploadLoading, setUploadLoading] = useState(null);
    const [parentInfo, setParentInfo] = useState({});

    const [formData, setFormData] = useState({
        name: '',
        levels: [],
        subjects: [],
        topics:[],
        subTopics: [],
        privacy: 'onlyMe',
        viewTime: 30,
        permissionForUsers: [],
        worksheetMethod: worksheetEdit ? 'new' : 'select',
        worksheetType: 'practice',
        theme: worksheetThemes[0].themeId,
        showList: true,
        showTime: false,
        showCorrectAnswer: true,
        showTimeQuestion: false,
        downloadable: false,
        displayRandom: false,
        dueDate: '',
        totalTime: 0,
        questions: []
    });

    const [step, setStep] = useState(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [filtersData, setFiltersData] = useState({});

    useEffect(()=>{
        setCurrentIndex(formData.worksheetMethod === 'new' ? formData.questions.length : questionLength);
    },[formData]);

    useEffect(()=>{
        modalType === "edit" && selectItem && selectItem.id && axios.get(API_URL.GET_LIBRARY_ITEM_CONTENT_URL,{
            headers: authHeader(),
            params: {
                type,
                id: selectItem.id,
            }
        })
        .then(response => {
            if (response.data && worksheetEdit && type === 'worksheet') {
                const data = response.data;
                setFormData({...data, worksheetMethod: 'new'});
            } else {
                response.data && setFormData(response.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[selectItem, modalType]);

    useEffect(()=>{
        showModal && setStep(1);
    },[showModal]);

    useEffect(()=>{
        if(formData.worksheetMethod === "new" || formData.worksheetMethod === "upload") {
            if(selectQItem) {
                const arr = formData.questions.filter(item => (item.id === selectQItem.id || item.tempQuestionId  === selectQItem.id));
                if(arr.length) {
                    setEditQData(arr[0]);
                }
            } else {
                setEditQData(null);
            }
        } else {
            setEditQData(formData); //formData now is getting data for current question GET_LIBRARY_ITEM_CONTENT_URL
        }

    },[selectQItem, formData]);

    useEffect(()=>{
        if(!showModal) {
            setFormData({
                name: '',
                levels: parentInfo && parentInfo.levels ? parentInfo.levels : [],
                subjects: parentInfo && parentInfo.subjects ? parentInfo.subjects : [],
                topics:[],
                subTopics: [],
                privacy: 'onlyMe',
                viewTime: 30,
                permissionForUsers: [],
                worksheetMethod: 'select',
                worksheetType: 'practice',
                theme: worksheetThemes[0].themeId,
                showList: true,
                showCorrectAnswer: true,
                showTime: false,
                showTimeQuestion: false,
                downloadable: false,
                displayRandom: false,
                dueDate: '',
                totalTime: 0,
                questions: []
            });
            setStep(1);
            setShowQuestionForm(false);
            setIsClone && setIsClone(false);
        }
    },[showModal]);

    useEffect(()=>{
        axios.get(API_URL.GET_LIBRARY_FILTERS_LIST_URL,{
            headers: authHeader(),
            params: {
            }
        })
        .then(response => {
            if(response.data) {
                setFiltersData(response.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    },[]);

    const onSave = () => {
        let data = new FormData();
        if(type === "file"){
            if(!formData.levels.length) {
                toast.error("Please select levels");
                return;
            }

            if(!formData.subjects.length) {
                toast.error("Please select subjects");
                return;
            }

            if(!formData.topics.length) {
                toast.error("Please select or add new topics");
                return;
            }

            if(!formData.subTopics.length) {
                toast.error("Please select or add new sub topics");
                return;
            }
            data.append('viewTime', formData.viewTime);
        }

        if (formData.privacy === 'authorised' && !formData.permissionForUsers.length) {
            toast.error("Please add users to grant permissions");
            return;
        }
        if((type === "file" || type === "questions list") && modalType !== "edit") {
            if(!files.length) {
                toast.error("Please select files");
                return;
            } else {
                for (let i = 0; i < files.length; i++) {
                    data.append('files', files[i]);
                }
            }
        }

        if (type === "folder" || type === "worksheet") {
            if(!formData.name) {
                toast.error("Please input folder name.");
                return;
            }
            data.append('name', formData.name);
        }

        if (type === "worksheet") {
            data.append('worksheetType', formData.worksheetType);
            data.append('wsThemeId', formData.theme);
            formData.dueDate && data.append('wsDueDate', formData.dueDate);
            formData.worksheetType === 'exam' && data.append('wsShowCorrectAnswer', formData.showCorrectAnswer);
            data.append('wsShowListQuestion', formData.showList);
            data.append('wsDisplayRandom', formData.displayRandom);
            formData.showTime && data.append('wsShowTime', formData.showTime);
            formData.showTime && data.append('wsCountdownTime', formData.totalTime);
            formData.showTime && formData.showTimeQuestion && data.append('wsQuestionCountdown', formData.showTimeQuestion);
            formData.wsImportId && data.append('wsImportId', formData.wsImportId);
            if (formData.questions && formData.questions.length) {
                data.append('questionsLength', formData.questions.length);
                for (var i = 0; i < formData.questions.length; i++) {
                    data.append('questionsList - ' + (i+1), JSON.stringify(formData.questions[i]));
                  }
            }
        }

        formData.levels && data.append('level', formData.levels.map(item => item.value).join(','));
        formData.subjects && data.append('subject', formData.subjects.map(item => item.value).join(','));
        formData.topics && formData.topics.length && data.append('topics', formData.topics.map(item => item.value).join(','));
        formData.subTopics && formData.subTopics.length && data.append('subTopics', formData.subTopics.map(item => item.value).join(','));
        formData.privacy && data.append('privacy', formData.privacy);
        if (formData.permissionForUsers.length > 0) {
            const users = formData.permissionForUsers.map(user => user.userName);
            const permissions = formData.permissionForUsers.map(user => user.permission);
            data.append('usersList', users);
            data.append('permissionsList', permissions);
        } 

        setSaving && setSaving(true);
        // setShowModal && setShowModal(false);
        const config = {
            headers: authHeader(),
            params: {
                folderId: folderId,
                type: type,
                parentType: parentType ? parentType : null,
                isEdit: modalType === "edit",
                editId: selectItem && selectItem.id
            },
            onUploadProgress: function(progressEvent) {
                setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
              }
        };

        axios.post(API_URL.POST_LIBRARY_CREATE_NEW_ITEM, data, config)
        .then(response => {
            setSaving(false);
            if (type === "questions list" && response.data && response.data.importId) {
                setModalType('viewImportQuestions');
                setImportId(response.data.importId);
                toast.success(`Import successfully${response.data.total ? ', total ' + response.data.total + ' questions' :''}. Please review and update the content.`)
            } else {
                setShowModal && setShowModal(false);
                setReloadList && setReloadList(Math.random().toString(36));
            }
        })
        .catch(error => {
            setSaving(false);
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter' || event.key === 'Spacebar'){
            onNext();
        }
    }

    const handleKeyPressSave = (event) => {
        if(event.key === 'Enter' || event.key === 'Spacebar'){
            onSave();
        }
    }

    const onNext = () =>  {
        if (formData.worksheetMethod === "upload" && wsFile.length) {
            setUploadLoading(true);
            const data = new FormData();
            for (let i = 0; i < wsFile.length; i++) {
                data.append('files', wsFile[i]);
            }
            data.append('files', wsFile);
            data.append('privacy', formData.privacy);
            const config = {
                headers: authHeader(),
                params: {
                    folderId,
                    type: 'questions list',
                    parentType: 'worksheets',
                    isEdit: false
                }
            }
            axios.post(API_URL.POST_LIBRARY_CREATE_NEW_ITEM, data, config)
            .then(response => {
                if (response.data && response.data.importId) {
                    const _importId = response.data.importId;
                    axios.get(API_URL.GET_LIBRARY_ITEM_CONTENT_URL,{
                        headers: authHeader(),
                        params: {
                            type: 'import',
                            importId: response.data.importId,
                        }
                    })
                    .then(response => {
                        response.data && response.data.questions && setFormData({...formData, 
                            questions: response.data.questions, wsImportId: _importId});
                        setUploadLoading(false);
                        setStep(step + 1);
                    })
                    .catch(error => {
                        const resMessage = getError(error);
                        setUploadLoading(false);
                        if(error.response && error.response.status === 401) {
                            Logout();
                            setUser(null);
                            history.push('/login');
                        } else {
                            toast.error(resMessage);
                        }
                    })
                }
            })
            .catch(error => {
                setSaving(false);
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }
            })
        } else {
            checkCondition(step) && setStep(step + 1);
        }
    }

    const onPrev = () =>  {
        setStep(step - 1);
    }

    const checkCondition = (step) => {
        if(step > 0) {
            if(!formData.name) {
                toast.error("Please add worksheet name");
                return false;
            }

            if(formData.levels && !formData.levels.length) {
                toast.error("Please select Levels");
                return false;
            }

            if(formData.subjects && !formData.subjects.length) {
                toast.error("Please select Subjects");
                return false;
            }

            if(!formData.topics.length) {
                toast.error("Please select or add new topics");
                return;
            }

            if(!formData.subTopics.length) {
                toast.error("Please select or add new sub topics");
                return;
            }

            if(formData.showTime && !formData.showTimeQuestion && formData.totalTime === 0) {
                toast.error("Please add time for whole worksheet");
                return false;
            }

            if (formData.privacy === "authorised" && !formData.permissionForUsers.length) {
                toast.error("Please select users to share permission");
                return false;
            }
        }

        if(step > 1) {
            if(!formData.worksheetMethod) {
                toast.error("Please select worksheet type");
                return false;
            }
        }

        if(step > 2) {
            if(formData.worksheetMethod !== "upload" && !formData.questions.length) {
                toast.error("Please select or add question");
                return false;
            }

            if(formData.worksheetMethod === "upload" && !wsFile.length) {
                toast.error("Please select worksheet file");
                return false;
            } 
        }

        return true;
    }

    return(
        <div className={lib.newFolder}>
            {   !showQuestionForm && !saving &&
                <div className={type === "worksheet" || type === "file" ? "flex": ""}>
                    {
                        (type === "worksheet" || type === "file") && 
                        <div className="modalLeft">
                            <h2>{modalType === "edit" ? "Edit" : "New"} {type}</h2>
                            {
                                type === "worksheet" &&
                                <Steps list={newWorksheetSteps} currentStep={step} setCurrentStep={setStep}
                                    condition={checkCondition} />
                            }
                        </div>
                    }
                    
                    <div className={type === "worksheet" || type === "file" ? "modalContent": ""}>
                        {
                            type !== "worksheet" && type !== "file" && 
                            <h2>{modalType} {modalType === "edit" && type === "file" ? "file properties" : type}</h2>
                        }
                        {
                            step === 1 &&
                            <Step1 
                                lib={lib} 
                                type={type} 
                                formData={formData} 
                                setFormData={setFormData} 
                                filtersData={filtersData} 
                                selectItem={selectItem}
                                files={files}
                                setFiles={setFiles}
                                parentInfo={parentInfo}
                                setParentInfo={setParentInfo}
                            />
                        }
                        
                        {
                            step === 2 &&
                            <Step2 lib={lib} formData={formData} setFormData={setFormData} worksheetThemes={worksheetThemes}
                            worksheetEdit={worksheetEdit} />
                        }
                        
                        {
                            step === 3 &&
                            <Step3 lib={lib} type={type} formData={formData} setFormData={setFormData} 
                            filtersData={filtersData} showQuestionForm={showQuestionForm} setShowQuestionForm={setShowQuestionForm}
                            setSelectQItem={setSelectQItem} wsFile={wsFile} setWsFile={setWsFile}
                            setIsClone={setIsClone} worksheetEdit={worksheetEdit} setStep={setStep} uploadLoading={uploadLoading}
                            />
                        }
                        
                        {
                            step === 4 &&
                            <WorksheetReview formData={formData} setFormData={setFormData} lib={lib} 
                            setSelectQItem={setSelectQItem} setShowQuestionForm={setShowQuestionForm} showQuestionForm={showQuestionForm}
                            setIsClone={setIsClone} />
                        }
                        
                        <div className="buttons rightAlign">
                            {
                                (type !== "worksheet" || step === 4) &&
                                <button className='btn' onClick={onSave} onKeyPress={handleKeyPressSave}>Save</button>
                            }
                            {
                                type === "worksheet" && step < 4 && 
                                <button className='btn' onClick={onNext} onKeyPress={handleKeyPress}>Next</button>
                            }
                            {
                                step > 1 &&
                                <button className='btn' onClick={onPrev}>Previous</button>
                            }
                        </div>
                    </div>
                </div> 
            }
               
            {
                showQuestionForm && !saving &&
                <>
                    <NewQuestion
                        currentIndex={currentIndex}
                        assignment={importList ? importList : formData}
                        setAssignment={setImportList ? setImportList : setFormData}
                        showQuestionForm={showQuestionForm}
                        setShowQuestionForm={setShowQuestionForm}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        questionId={formData.questions && formData.questions.length > 0 && formData.questions[currentIndex] && formData.questions[currentIndex].questionId}
                        filtersData={filtersData}
                        fromWorkSheet={formData.worksheetMethod === "new" || formData.worksheetMethod === "upload"}
                        selectItem={selectQItem ? selectQItem : selectItem}
                        editQData={editQData}
                        setReloadList={setReloadList}
                        type={type}
                        isClone={isClone}
                        folderId={folderId}
                        setReload={setReload}
                    />
                    {
                        type !== "question" &&
                        <button onClick={() => setShowQuestionForm(false)} className="close">&times;</button>
                    }
                </>
            }
        </div>
    )
}

export default NewItems;