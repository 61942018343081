import { useState } from 'react';
import Input from '../../../components/input';
import styles from './styles.module.scss';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { checkTouchDevice, getError } from '../../../common/utils';
import { toast } from 'react-toastify';
import API_URL from '../../../common/urls';
import { Loader } from '../../../components/loading';
import bookIcon from '../../../images/reading.png';
// import videoIcon from '../../../images/video2.svg';
import worksheetIcon from '../../../images/ws.svg';
import { FaYoutube } from 'react-icons/fa';

const SmartSearch = ({subject, openModal}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState();
    const [loading, setLoading] = useState();

    const searchOnChange = (e) => {
        setSearch(e.target.value);
        if (e.target.value.length > 2 && checkTouchDevice()) { 
            startSearch();
        };
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            search && search.length > 0 && startSearch();
        }
    }

    const startSearch = () => {
        setLoading(true);
        const url = API_URL.GET_SMART_SEARCH_URL;
        return axios.get(url,{
            headers: authHeader(),
            params: {
                search,
                subject
            }
        })
        .then(response => {
            setLoading(false);
            response.data.results && setSearchResults(response.data.results);
        })
        .catch(error => {
            setLoading(false);
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }
    return (
        <div className={styles.smartSearch}>
            <Input
                type="text" 
                placeholder={`${checkTouchDevice() ? 'Type at least 3 characters to search' : 'Type and press Enter/Return to search'}`} 
                className={styles.searchBox}
                onChange={searchOnChange}
                value={search}
                label="Search for files, videos, worksheets"
                onKeyPress={handleKeyPress}
            />
            <div className={styles.searchResults}>
                {
                    loading && <Loader />
                }
                {
                    !loading && searchResults && searchResults.length === 0 &&
                    <div className='noResult'>No result found.</div>
                }

                {
                    !loading && searchResults && searchResults.length > 0 &&
                    <ul>
                        {
                            searchResults.map((item,index) => 
                            <li key={`result ${index}`} title={item.name}>
                                {
                                    item.type === "worksheet" &&
                                    <a href={`testPlay/${item.id}/th1?learnId=${item.learnId}&learnType=${item.learnType}`}>
                                        <img src={worksheetIcon} height={60}/>
                                    </a>
                                }
                                {
                                    item.type === "file" &&
                                    <img src={bookIcon} height={60} onClick={() => openModal && openModal('file', item)}/>
                                }
                                {
                                    item.type === "video" &&
                                    <FaYoutube color="#bb0000" size="60" title="View Video" onClick={() => openModal && openModal('video', item)}/>
                                }
                                <label title={item.name}>{item.name.length > 21 ? item.name.substring(0,21)+"..." : item.name}</label>
                            </li>
                            )
                        }
                    </ul>
                    
                }
            </div>
        </div>
    )
}

export default SmartSearch;