import React from 'react';
import classNames from 'classnames';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';

import styles from './radio.module.scss';

const Radio = ({className, value, label, checked, onChange}) => {
  const onRadioBoxChange = () => {
    onChange && onChange(value);
  };
  return (
    <div className={classNames(styles.wrapper, className)} onClick={onRadioBoxChange}>
      {checked && <MdRadioButtonChecked className={classNames(styles.icon, styles.checked)} size={20} />}
      {!checked && <MdRadioButtonUnchecked className={styles.icon} size={20} />}
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export const RadioGroup = ({className, items, value, onChange}) => {
  const onRadioBoxChange = (curValue) => {
    onChange && onChange(curValue);
  };

  return (
    <div className={className}>
      {items &&
        items.map((item) => (
          <Radio
            key={item.value}
            label={item.label}
            value={item.value}
            checked={value === item.value}
            onChange={onRadioBoxChange}
          />
        ))}
    </div>
  );
};

export default Radio;
