import { useState, useRef} from "react";
import { useHistory } from "react-router";
import lib from './lib.module.scss';
import folder from "../../../images/folder.svg";
import folder2 from "../../../images/folder-fav.svg";

import assignmentIcon1 from '../../../images/assign1.svg';
import assignmentIcon2 from '../../../images/assign2.svg';
import { MdFavorite, MdLock } from "react-icons/md";
import Controls from "./itemControls";
import { FaQuestion } from "react-icons/fa";
import { questionColor } from "../../../common/utils";
import {uniqueId} from 'lodash';

const ListItems = ({privacy, type, list, selectItems, setSelectItems, openControl, 
    setOpenControl, setCModalType, isGrid, loading, addFavorite, setNewType, tab}) => {
    const history = useHistory();
    const controlRef = useRef();

    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    
    const controlOnClick = (e, type, newType) => {
        e.stopPropagation();
        setCModalType(type);
        newType && setNewType && setNewType(newType);
    }

    const onParentClick = (e) => {
        setSelectItems([]);
        setOpenControl && setOpenControl(false);
    }

    const FolderOnClick = (id) => {
        history.push(`/user/library?folderId=${id}&tab=${tab}&subTab=${type}`);
    }

    const openControlHandler = (e) => {
        e.preventDefault();
        setOpenControl && setOpenControl(true);
    }

    const selectItem = (e, type, item, parentIndex, isRightClick, shiftCase) => {   

        if(selectItems && setSelectItems) {
            const selectedIds = selectItems.map(item => item.id);
            const id = item.folderId || item.fileId || item.worksheetId || item.questionId;
            if(selectedIds.includes(id) && !shiftCase) {

                if(!isRightClick) {
                    //remove current id
                    const index = selectedIds.indexOf(id);
                    selectItems.splice(index, 1);

                    setSelectItems([...selectItems]);
                }
                
            } else {
                selectItems.push({
                    id,
                    parentId: item.parentId ? item.parentId : null,
                    type,
                    name: item.name ? item.name : type === 'question' ? 'Question ' + (parentIndex + 1) : null,
                    url: item.url ? item.url : null,
                    fileType: item.fileType ? item.fileType : null,
                    permission: item.permission ? item.permission : null,
                    questionIndex: parentIndex,
                    themeId: item.themeId ? item.themeId : null,
                    favorite: item.favorite ? item.favorite : null,
                    parentIndex: parentIndex
                });
                setSelectItems([...selectItems]);
            }
            
            setOffsetX(e.nativeEvent.clientX + 20);
            setOffsetY(e.nativeEvent.clientY);
        }
    }

    const itemClick = (e, item, type, index, parentList) => {
        e.stopPropagation();
        if(e.detail === 2) {
            type === "folder" && FolderOnClick(item.folderId);
            type === "file" && setCModalType('viewfile');
            type === "worksheet" && setCModalType('viewworksheet');
            type === "question" && setCModalType('viewquestion');
        }
        
        if(e.detail === 1) {
            selectItem(e, type, item, index, false);
            if(e.shiftKey && selectItems && selectItems.length > 0 && selectItems[selectItems.length-2] && selectItems[selectItems.length-2].type === type) {
                const min = Math.min(index, selectItems[selectItems.length-2].parentIndex);
                const max = Math.max(index, selectItems[selectItems.length-2].parentIndex);
                for(let i=min+1; i <= max-1; i++) {
                    selectItem(e, type, parentList[i], i, false, true);
                }
            }
        }
    }

    return(            
            <div onClick={onParentClick}>
                <div className={`${lib.itemsList} ${isGrid ? lib.grid : ''}`}>
                    {
                        !loading && list.folders.length > 0 &&
                        list.folders.map((item, index) => 
                            <div 
                                onClick={e => itemClick(e, item, 'folder', index, list.folders)}
                                className={`${lib.folder} ${(selectItems && selectItems.map(item => item.id).includes(item.folderId)) ? lib.selected : ''}`}
                                onContextMenu={(e)=> 
                                    {
                                        e.stopPropagation();
                                        selectItem(e, 'folder', item, index, true);
                                        openControlHandler(e);
                                    }
                                }
                                key={'folder'+index}
                                title={item.name}
                            >
                                <img src={item.folderId === 'favourite' ? folder2 : folder} width={isGrid ? 75 : 21} />
                                
                                {
                                    item.permission === "forbidden" &&
                                    <MdLock size="20" color="#444" className={lib.lock}/>
                                }
                                <span className={lib.name}>{item.name.length > 21 ? item.name.substring(0,21)+"..." : item.name}</span>
                                {
                                    !isGrid &&
                                    <>
                                        <span className={lib.date}>{item.lastModified}</span>
                                        <span>{item.authorName}</span>
                                        <span>{item.size ? item.size : "_"}</span>
                                    </>
                                }
                                
                                {
                                    item.favorite &&
                                    <MdFavorite size="20" color="red" className={lib.favorite} />
                                }
                            </div>)
                    }
                </div>    
                <div className={`${lib.itemsList} ${isGrid ? lib.grid : ''}`}>
                {
                    !loading && type === "files" && list.files.length > 0 &&
                    list.files.map((item, index) => 
                        <div 
                            onClick={e => itemClick(e, item, 'file', index, list.files)}
                            className={`${lib.file}
                            ${(selectItems && selectItems.map(item => item.id).includes(item.fileId)) ? lib.selected : ''}`}
                            key={uniqueId()}
                            onContextMenu={(e)=> 
                                {
                                    selectItem(e, 'file', item, index, true);
                                    openControlHandler(e);
                                }
                            }
                            title={item.name}
                        >
                            
                            <span className={`${lib.fileIcon} ${lib[item.fileType]}`}>
                                {item.fileType}
                                <span>{item.fileSize}</span>
                            </span>
                            <span className={lib.name}>{item.name.length > 21 && isGrid ? item.name.substring(0,21)+"..." : item.name}</span>
                            {
                                privacy === "authorised" && item.permission === "forbidden" &&
                                <MdLock size="20" color="#444" className={lib.lock} />
                            }
                            {
                                item.favorite &&
                                <MdFavorite size="20" color="red" className={lib.favorite} />
                            }
                            {
                                !isGrid &&
                                <>
                                    <span className={lib.date}>{item.lastModified}</span>
                                    <span>{item.authorName}</span>
                                    <span>{item.fileSize ? item.fileSize : "_"}</span>
                                </>
                            }
                        </div>
                    )
                }
                </div>

                <div className={`${lib.itemsList} ${isGrid ? lib.grid : ''}`}>
                {
                    !loading && type === "worksheets" && list.worksheets.length > 0 &&
                    list.worksheets.map((item, index) => 
                        <div 
                            onClick={e => itemClick(e, item, 'worksheet', index, list.worksheets)}
                            className={`${lib.file}
                            ${(selectItems && selectItems.map(item => item.id).includes(item.worksheetId)) ? lib.selected : ''}`}
                            key={uniqueId()}
                            onContextMenu={(e)=> 
                                {
                                    selectItem(e, 'worksheet', item, index, true);
                                    openControlHandler(e);
                                }
                            }
                            title={item.name}
                        >
                            
                            <img src={item.worksheetType === "practice" ? assignmentIcon1 : assignmentIcon2} width={isGrid ? 66 : 22}/>
                            <span className={lib.name}>{item.name.length > 21 && isGrid ? item.name.substring(0,21)+"..." : item.name}</span>
                            {
                                item.permission === "forbidden" &&
                                <MdLock size="20" color="#444" className={lib.lock} />
                            }
                            {
                                item.favorite &&
                                <MdFavorite size="20" color="red" className={lib.favorite} />
                            }
                            {
                                !isGrid &&
                                <>
                                    <span className={lib.date}>{item.lastModified}</span>
                                    <span>{item.authorName}</span>
                                    <span>{item.worksheetType ? item.worksheetType : "_"}</span>
                                </>
                            }
                        </div>
                    )
                }
                </div>
                <div className={`${lib.itemsList} ${isGrid ? lib.grid : ''}`}>
                {
                    !loading && type === "questions" && list.questions && list.questions.length > 0 &&
                    list.questions.map((item, index) => 
                        <div 
                            onClick={e => itemClick(e, item, 'question', index, list.questions)}
                            className={`${lib.question}
                            ${(selectItems && selectItems.map(item => item.id).includes(item.questionId)) ? lib.selected : ''}`}
                            key={uniqueId()}
                            onContextMenu={(e)=> 
                                {
                                    selectItem(e, 'question', item, index, true);
                                    openControlHandler(e);
                                }
                            }
                            title={item.name}
                        >
                            <FaQuestion size={isGrid ? 60 : 22} color={questionColor(item.answerType)}/>
                            <span className={lib.name}>{item.name.length > 21 && isGrid ? item.name.substring(0,21)+"..." : item.name}</span>
                            {
                                item.permission === "forbidden" &&
                                <MdLock size="20" color="#444" className={lib.lock} />
                            }
                            {
                                item.favorite &&
                                <MdFavorite size="20" color="red" className={lib.favorite} />
                            }
                            {
                                !isGrid &&
                                <>
                                    <span className={lib.date}>{item.lastModified}</span>
                                    <span>{item.authorName}</span>
                                    <span>{item.questionType ? item.questionType : "_"}</span>
                                </>
                            }
                            <div className={lib.questionContent} dangerouslySetInnerHTML={{__html: item.content}} />
                            <div className={lib.questionId}>{item.questionId}</div>
                        </div>
                    )
                }
                </div>
                {
                    selectItems.length > 0 && openControl &&
                    <Controls 
                        lib={lib} 
                        selectItems={selectItems} 
                        privacy={privacy} 
                        offsetX={offsetX} 
                        offsetY={offsetY}
                        onClick={controlOnClick}
                        ref={controlRef}
                        addFavorite={addFavorite}
                        viewFolder={FolderOnClick}
                    />
                }
            </div>
    )
};

export default ListItems;