import { useEffect } from "react";
import { useState } from "react";
import Input from "../../../components/input";

const EditName = ({openModal, item, setSavedName, savedName}) => {
    const nameOnChange = (e) => setSavedName && setSavedName(e.target.value);

    useEffect(()=>{
        item && item.worksheetName && setSavedName(item.worksheetName);
    },[item && item.worksheetName])

    const saveName = () => {
        openModal && openModal('generating', {...item, worksheetName: savedName, learnId: item.learnId});
    }

    return (
        <div>
            <Input 
                type="text"
                label="Edit worksheet name"
                onChange={nameOnChange}
                value={savedName}
            />
            <button className="btn" onClick={saveName}>Save</button>
        </div>
        
    )
}

export default EditName;