import { useEffect, useState } from "react";
import API_URL from "../../../common/urls";
import { authHeader } from '../../../services/auth-header';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import lib from './lib.module.scss';
import { FaQuestionCircle } from "react-icons/fa";
import { MdInsertDriveFile } from "react-icons/md";
import assignmentIcon1 from '../../../images/assign1.svg';
import assignmentIcon2 from '../../../images/assign2.svg';
import folder from "../../../images/folder.svg";

const FileInfo = ({id, type, showModal, setShowModal}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState();

    useEffect(()=>{
        setLoading(true);
        showModal && id && axios.get(API_URL.GET_LIBRARY_ITEM_INFO_URL,{
            headers: authHeader(),
            params: {
                id,
                type,
            }
        })
        .then(response => {
            if(response.data && response.data.info) {
                setInfo(response.data.info);
            }
            setLoading(false);
        })
        .catch(error => {
            const resMessage = getError(error);   
            setLoading(false);         
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[id, showModal]);

    const onRequestPermission = (permission) => {
        axios.post(API_URL.POST_LIBRARY_ITEMS_ACTION,{item: id, action: 'request-permission', permission, itemType: type},{
            headers: authHeader(),
        })
        .then(response => {
            toast.success("Your request has been sent");
            setShowModal && setShowModal(false);
        })
        .catch(error => {
            const resMessage = getError(error);   
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={lib.info}>
            {
                loading && <Loader />
            }
            {
                !loading &&
                <>
                    <h2>
                        {info.type === "folder" ? <img src={folder} width="40"/> : info.type === "worksheet" ? 
                        <img width={40} src={info.worksheetType === "practice" ? assignmentIcon2 : assignmentIcon1} /> : 
                        info.type === "question" ? <FaQuestionCircle size="40" color="#CCC"/> : 
                        <MdInsertDriveFile size="40" color="#0ea7d2" />}
                        {info.name}
                    </h2>
                    <ul>
                        <li>
                            <label>Creator </label>
                            <span>{info.creator ? info.creator : '-'}</span>
                        </li>
                        <li>
                            <label>Created </label>
                            <span>{info.created ? info.created : '-'}</span>
                        </li>
                        <li>
                            <label>Last Modified </label>
                            <span>{info.lastModified ? info.lastModified : '-'}</span>
                        </li>
                        {
                            type === 'file' &&
                            <>
                                <li>
                                    <label>Size </label>
                                    <span>{info.size ? info.size : '-'}</span>
                                </li>
                                <li>
                                    <label>Required viewing time </label>
                                    <span>{info.viewTime ? info.viewTime : '-'}</span>
                                </li>
                            </>
                        }
                        <li>
                            <label>Levels</label>
                            <span>{info.levels ? info.levels : '-'}</span>
                        </li>
                        <li>
                            <label>Subjects</label>
                            <span>{info.subjects ? info.subjects : '-'}</span>
                        </li>
                        <li>
                            <label>Topics</label>
                            <span>{info.topics ? info.topics : '-'}</span>
                        </li>
                        <li>
                            <label>SubTopics</label>
                            <span>{info.subTopics ? info.subTopics : '-'}</span>
                        </li>
                        <li>
                            <label>Privacy</label>
                            <span>{info.privacy ? info.privacy : '-'}</span>
                        </li>
                        <li>
                            <label>Favorite</label>
                            <span>{info.favorite ? info.favorite : '-'}</span>
                        </li>
                        {
                            type === 'worksheet' &&
                            <>
                                <li>
                                    <label>Due Date </label>
                                    <span>{info.dueDate ? info.dueDate : '-'}</span>
                                </li>
                                <li>
                                    <label>Countdown Time</label>
                                    <span>{info.countdownTime ? info.countdownTime : '-'}</span>
                                </li>
                                <li>
                                    <label>Theme</label>
                                    <span>{info.theme ? info.theme : '-'}</span>
                                </li>
                            </>
                        }
                        <li>
                            <label>Permission</label>
                            <span className={info.permission === "forbidden" ? "red" : info.permission === "view" ? "orange" : "green"}>{info.permission ? info.permission : '-'}</span>
                        </li>
                        {info.permission === "forbidden" && 
                            <div className="buttons centerAlign">
                                <button className="btn" onClick={() => onRequestPermission('view')}>Request View Permission</button>
                                <button className="btn" onClick={() => onRequestPermission('edit')}>Request Edit Permission</button>
                            </div>
                        }
                    </ul>
                </>
            }
        </div>
    )
}

export default FileInfo;