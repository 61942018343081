import { FaCheck, FaYoutube } from 'react-icons/fa';
import { creditIcon} from '../../../common/utils';
import bookIcon from '../../../images/reading.png';
import worksheetIcon from '../../../images/ws.svg';
import classNames from 'classnames';
import { useEffect } from 'react';

const DmCombo = ({item, styles, openModal, getList}) => {
    useEffect(()=>{
        item.readingDone && item.videoDone && item.worksheetDone && getList && getList();
    }, [item.readingDone, item.videoDone, item.worksheetDone]);
  return (
    <li>
      <div>
          <p className={styles.date}>Due {item.dueDate}</p>
          <h4>{item.topic}</h4>
          <h3>{item.subTopic}</h3>
          <div className={styles.credit}>{creditIcon(item.credit)}</div>
          <div className={classNames(styles.status,{[styles.pending]: item.status === "pending"})}><span>{item.status}</span></div>
      </div>
      <div className={styles.icons}>
        {
            item.readingUrl &&
            <span>
              <img src={bookIcon} height="62" style={{paddingBottom: 10}} title="Reading" onClick={() => openModal('file', item)}/>
              {
                  item.readingDone && <FaCheck className={styles.check} />
              }
            </span>
        }
        {
            item.videoId &&
            <span>
              <FaYoutube color="#bb0000" size="53" title="View Video" onClick={() => openModal('video', item)}/>
              {
                  item.videoDone && <FaCheck className={styles.check} />
              }
            </span>
        }
        {
            item.worksheetId &&
            <a href={`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`}>
              <img src={worksheetIcon} height="48" title="Play worksheet" />
              {
                  item.worksheetDone && <FaCheck className={styles.check} />
              }
            </a>
        }
      </div>
  </li>
  )
}

export default DmCombo;