import {useState, useEffect, useRef} from 'react';
import {useHistory, Link} from 'react-router-dom';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import {toast} from 'react-toastify';
import classNames from 'classnames'; 
import RichEditor2 from '../../components/editor/editor2';
import {MdAttachFile} from 'react-icons/md';
import { useStore } from '../../store/useStore';
import {MdClose, MdFiberManualRecord, MdDone, MdFastForward, MdFileUpload} from 'react-icons/md';
import {uniqueId} from 'lodash';
import styles from './styles.module.scss';
import {FaAward, FaVolumeUp, FaVideo} from 'react-icons/fa';
import { useReactMediaRecorder, ReactMediaRecorder } from "react-media-recorder";
import QuestionDetails from '.';

export const QuestionsList = ({list, studentId, isInResult, className, assignId, getAssigment}) => {
    return (
        <ul className={className}>
            {
                list && list.length > 0 && list.map((item,index) => 
                    <li key={uniqueId()}>
                        <div className={styles.status}>
                            <strong>Question {index + 1}</strong>
                            {studentId && item.result === "correct" &&
                                <MdDone className={styles.correct} title="Correct"/>
                            }
                            {studentId && item.result === "incorrect" &&
                                <MdClose className={styles.incorrect} title="Incorrect" />
                            }
                            {studentId && item.result === "skip" &&
                                <MdFastForward className={styles.skip} title="Skip" />
                            }
                            {studentId && item.result === "timeout" &&
                                <span className={styles.timeout} title="Timeout">Timeout</span>
                            }
                            {studentId && item.result.includes("%") &&
                                <span className={styles.score} title="Timeout">{item.result}</span>
                            }
                            {studentId && item.result === "pending" &&
                                <span className={styles.pending} title="Timeout">pending...</span>
                            }
                        </div>
                        
                        <div className={styles.question}>
                            <QuestionDetails
                                item={item} 
                                index={index} 
                                isInResult={isInResult}
                                studentId={studentId}
                                assignId={assignId}
                                getAssigment={getAssigment}
                            />
                        </div>
                    </li>
                )
            }
        </ul>
        )
}

export const MultiInputs = ({number, onChange}) => {
    const [listWords, setListWords] = useState(Array(number).fill(''));

    useEffect(()=>{
        onChange && onChange(listWords);
    },[listWords])

    const inputOnChange = (e, index) => {
    const spans = document.getElementsByClassName('blankSpan');
        
        setListWords && setListWords(
            listWords.map((value,i)=>{
                if(i===index) {
                    value = e.target.value;
                    if(spans && spans.length > 0) spans[index].innerText = value;
                }
                    return value;
                }))
    }

    return (
        listWords.map((value,index) => (
            <li key={index}>
                <input 
                    type="text" 
                    onChange={e => inputOnChange(e, index)} 
                    value={value} 
                />
                <span></span> 
            </li>
        ))
    )
}

export const AnswerOptions = ({options, onChange, isMulti, type}) => {
    const [currentChoice, setCurrentChoice] = useState([]);

    const onOptionClick = (item, index) => {  
        if(!isMulti) {
            if(currentChoice.length < 1) {
                currentChoice.push(type === "imageChoice" ? index : item);
            } else {
                currentChoice.splice(0,1,type === "imageChoice" ? index : item);
            }
        } else {
            if(currentChoice.includes(index) || currentChoice.includes(item) ) {
                currentChoice.splice(type === "imageChoice" ? currentChoice.indexOf(index) : currentChoice.indexOf(item), 1);
            } else {
                currentChoice.push(type === "imageChoice" ? index : item);
            }
        }
        setCurrentChoice([...currentChoice]);
        currentChoice.length > 0 && onChange && onChange(currentChoice);
    }
    return (
        <ul className={styles.answerOptions}>
            <label>{isMulti ? "Select multi-options" : "Select 1 option"}</label>
            {options.map((item,index) => 
            <li 
                key={uniqueId()} 
                className={classNames({[styles.active] : currentChoice.includes(index+1) || currentChoice.includes(item),
                    [styles.image]: type === "imageChoice"})}
                onClick={() => onOptionClick(item, index+1)}
            >
                {type === "textChoice" && item}
                {type === "imageChoice" && <img src={item} alt="choice" />}
            </li>)}
        </ul>
    )
}

export const ArrangeWords = ({options, onChange}) => {
    const [showOptions, setShowOptions] = useState(options);
    const [sentence, setSentence] = useState([]);

    const getWord = (item,index) => {
        showOptions.splice(index, 1);
        setShowOptions([...showOptions]);
        sentence.push(item);
        setSentence([...sentence]);

        sentence.length > 0 && onChange && onChange(sentence);
    }

    const onRemoveItem = (item, index) => {
        showOptions.push(item);
        setShowOptions([...showOptions]);
        sentence.splice(index, 1);
        setSentence([...sentence]);

        sentence.length > 0 && onChange && onChange(sentence);
    }

    return (
        <div>
        <ul className={classNames(styles.answerOptions,styles.arrangeOptions)}>
            {
                showOptions.map((item,index) =>
                    <li key={uniqueId()} onClick={() => getWord(item,index)}>
                        {item}
                    </li>
                )
            }
        </ul>
        <ul className={styles.arrangedWords}>
            {sentence && sentence.length > 0 &&
                sentence.map((item,index) => 
                    <li key={uniqueId()}>
                        {item}
                        <MdClose className={styles.close} title="Remove file" onClick={() => onRemoveItem(item,index)}/>
                    </li>
                )
            }
        </ul>
        </div>
    )
}

export const WordMatch = ({options1, options2, onChange, type}) => {
    const [list2, setList2] = useState(options2);
    const [activeIndex, setActiveIndex] = useState(null);
    const [choseIndex, setChoseIndex] = useState([]);

    const chooseWord = (index) => {
        if(activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    }

    const chooseSecondWord = (item,index) => {
            const temp1 = list2[index];
            const temp2 = list2[activeIndex];
            list2.splice(index,1,temp2);
            list2.splice(activeIndex,1, temp1);
            setList2([...list2]);
            choseIndex.push(activeIndex);
            setChoseIndex([...choseIndex]);
            setActiveIndex(null);

            choseIndex.length > 0 && onChange && onChange(list2);
    }

    return (
        <div className={classNames(styles.wordMatch, {[styles.wordImageMatch]: type === "wordImageMatch"})}>
            <label>Click to choose the corresponding word</label>
            <div>
                {
                    options1.map((item,index) =>
                    <div 
                        key={uniqueId()}
                        className={styles.item}
                    >
                        <div 
                            className={classNames(activeIndex === index ? styles.active : activeIndex !== null ? styles.disabled : "", {[styles.chose]: choseIndex.includes(index)})}
                            onClick={() => chooseWord(index)}
                        >
                            {type === "wordMatch" && item}
                            {type === "wordImageMatch" && <img src={item} alt="choice" />}
                        </div>
                        <div
                            className={classNames(activeIndex === index ? styles.active : activeIndex === null ? styles.disabled : "", {[styles.chose]: choseIndex.includes(index)})}
                            onClick={() => chooseSecondWord(item,index)}
                        >
                            {list2[index]}
                        </div>
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export const TestResults = ({resultData, onViewFullDetails}) => {
    const {user} = useStore();
    return (
        <div className={styles.result}>
            <FaAward size="70" color="#FFC200" />
            {resultData.rightAnswers === resultData.totalQuestions &&
                <h1>CONGRATULATION!</h1> 
            }
            {resultData.rightAnswers < resultData.totalQuestions &&
                <h1>TEST RESULT</h1> 
            }
            <p className={styles.resultItem}><label>Total questions:</label> {resultData.totalQuestions}</p>
            <p className={styles.resultItem}><label>Passed:</label> {resultData.rightAnswers}{resultData.autoQuestions ? '/' + resultData.autoQuestions : ''}
                {resultData.autoQuestions && <span>({Math.floor(parseInt(resultData.rightAnswers)/parseInt(resultData.autoQuestions)*100)}%)</span>}
            </p>
            <p className={styles.resultItem}><label>Manual mark:</label> {resultData.manualQuestions ? resultData.manualQuestions : 0} 
            </p>
            <h3>Summary <button className="btn" onClick={onViewFullDetails}>View Full Details</button></h3>
            {
                resultData.details && resultData.details.length > 0 &&
                <div className={styles.studentReports}>
                    <QuestionsList 
                        studentId={user.userName} 
                        list={resultData.details}
                        isInResult={true}
                    />
                </div>
            }
        </div> 
    )
}

const VideoPreview = ({ stream }) => {
    const videoRef = useRef();
  
    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);
    if (!stream) {
      return null;
    }
    return <video ref={videoRef} autoPlay controls />;
  };

export const Recording = ({markData, setMarkData}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [isVideo, setIsVideo] = useState(false);
    const [blob, setBlob] = useState('');
    const [percentCompleted, setPercentCompleted] = useState(0);

    const onUpload = () => {
        const data = new FormData();
        blob && data.append('file', blob, `recording-${Math.random().toString(36).slice(2)}`);

        var config = {
            headers: authHeader(),
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total));
            }
          };

        axios.post(API_URL.USER_UPLOAD_FILE_URL,data,config)
        .then(response => {
            setMarkData({...markData, recordedFileUrl : response.data.data.link})
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onUrlChange = e => setMarkData({...markData, fileUrl: e.target.value});
    const onNoteChange = value => setMarkData({...markData, note: value});

    const resetUpload = () => {
        setMarkData({...markData, recordedFileUrl : ''});
        setPercentCompleted(0);
    }

    return (
        <div className={styles.recording}>
            <div className={styles.recordingContent}>
                    <label>Record a media file</label>
                    {
                        markData && markData.recordedFileUrl && 
                        <div className={styles.uploadSuccess}>
                            Your record is uploaded successfully!
                            <div>
                                <button onClick={resetUpload}>Upload another file</button>
                            </div>
                        </div>
                    }

                    {
                        percentCompleted > 0 && percentCompleted < 100 &&
                        <div className={styles.uploading}>
                            <span style={{width: percentCompleted + "%"}}>{percentCompleted}</span>
                        </div>
                    }

                    {
                        markData && !markData.recordedFileUrl && percentCompleted === 0 && 
                        <>
                            <div className={styles.recordOptions}>
                                <FaVolumeUp title="Audio" className={!isVideo ? styles.active : ""} onClick={() => setIsVideo(false)}/>
                                <FaVideo title="Video" className={isVideo ? styles.active : ""} onClick={() => setIsVideo(true)}/>
                            </div>
                            <ReactMediaRecorder
                                video={isVideo}
                                onStop={(blobUrl, blob) => setBlob(blob)}
                                render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
                                    <div>
                                        {/* <p>{status}</p> */}
                                        {
                                            status !== "recording" &&
                                            <button onClick={startRecording}><MdFiberManualRecord size="35" color="red" /> REC</button>
                                        }
                                        {
                                            status === "stopped" &&
                                            <button title="Confirm and Upload" onClick={onUpload}><MdFileUpload /></button>
                                        }
                                        {
                                            status === "recording" &&
                                            <button className={styles.stopRecord} onClick={stopRecording}><MdFiberManualRecord size="35" /> REC</button>
                                        }
                                        
                                        {
                                            status === "recording" && isVideo &&
                                            <VideoPreview stream={previewStream} />
                                        }
                                        {
                                            status === "stopped" &&
                                            <center>
                                                <video className={!isVideo ? styles.audio : ""} src={mediaBlobUrl} controls autoPlay />
                                            </center>                                
                                        }
                                    </div>
                                )}
                            />
                        </>
                    }
                    
                </div>
        
            <UploadFile markData={markData} setMarkData={setMarkData} type=".mp3, .a4m, .wav, .mov, .mp4"/> 
        
            <div>
                <label>Shared File Url</label>
                <input type="text" onChange={onUrlChange} placeholder="" />
            </div>
        
            <div className={styles.note}>
                <label>Note</label>
                <RichEditor2 onChange={onNoteChange} placeholder="" />
            </div>
        </div>
    )
}

export const UploadFile = ({markData, setMarkData, type}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const fileOnChange = (e) => {
        setFile(e.target.files);
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        }
    }

    const resetUpload = () => {
        setFile(null);
        setFileName('');
        setMarkData({...markData, fileUrl : ''});
        setPercentCompleted(0);
    }

    const saveFile = () => {
        const data = new FormData();

        if (file && file.length > 0) {
            data.append('file', file[0]);
        }

        var config = {
            headers: authHeader(),
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total));
            }
          };

        axios.post(API_URL.USER_UPLOAD_FILE_URL,data,config)
        .then(response => {
            setMarkData({...markData, fileUrl : response.data.data.link})
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
            <div className={styles.addFile}>
                <label>Attached file <MdAttachFile size="16" /></label>
                <div>
                    {!fileName &&
                        <>
                            <h5>Drag or Click to upload file</h5>
                            {type && 
                                <p>File type: {type}</p>
                            }
                            
                            <input 
                                type="file" 
                                onChange={fileOnChange}
                                accept={type ? type : "*"}
                            />
                        </>
                    }

                    {fileName && markData &&!markData.fileUrl && 
                        <div className={percentCompleted > 0 ? styles.disabled : ""}>
                        <h6>
                            {fileName}
                        </h6>
                        <button onClick={saveFile}>Upload</button>
                        </div>
                    }

                    {
                        markData && markData.fileUrl && percentCompleted === 100 &&
                        <div className={styles.uploadSuccess}>
                            <label>Your file has been uploaded successfully!</label>
                            <div>
                                <button onClick={resetUpload}>Upload another file</button>
                            </div>
                        </div>
                    }

                    { percentCompleted > 0 && percentCompleted < 100 && 
                        <div className={styles.inProgress} style={{width: percentCompleted + '%'}}>
                            {percentCompleted}%
                        </div>
                    }
                </div>                                           
            </div>
    )
}

const OpenEnded = ({markData, setMarkData}) => {
    const onTextChange = text => setMarkData({...markData, text: text});
    const onUrlChange = e => setMarkData({...markData, fileUrl: e.target.value});

    return (
        <div className={classNames("openEnded", styles.answerBox)}>
                <RichEditor2
                    label="Type your answer here"
                    onChange={onTextChange}
                    editorData={markData.text}
                />
            
                <UploadFile markData={markData} setMarkData={setMarkData} />
            
                <div>
                    <label>Shared File Url</label>
                    <input type="text" onChange={onUrlChange} placeholder="" />
                </div>
        </div>
    )
}

export default OpenEnded;