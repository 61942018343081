import { useState, useEffect, useRef } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError, reactionIcon } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import connection from './connection.module.scss';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { MdFaceRetouchingNatural, MdMoreHoriz, MdPerson, MdSend, MdTagFaces } from "react-icons/md";
import SharingLikeAction from "./sharingLikeAction";
import Picker, { SKIN_TONE_DARK } from 'emoji-picker-react';
import useClickOutside from "../../../hooks/useClickOutside";
import { FaRegComment } from "react-icons/fa";
import SharingOptions from "./sharingMoreOptions";

const Comment = ({item, index, list, setList, setCurId, setRType, setShowModal, setModalType, commentReply, setCurItem, setComment, sharingId}) => {
    const {user, setUser} = useStore();
    const history= useHistory();
    const [showLikeList, setShowLikeList] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [editComment, setEditComment] = useState('');
    const editCommentRef = useRef();

    const reactionOnClick = (type) => {
        setShowModal(true);
        setModalType('reactionList');
        setCurId && setCurId(item.id);
        setRType(type);
    }

    const commentOnBlur = (e) => {
        setEditComment(e.target.innerHTML);
    }

    const onCancel = () => {
        setEditComment('');
    }

    const onEditComment = () => {
        return axios.post(API_URL.USER_SHARING_COMMENTS_NEW_COMMENT_URL,{editComment},{
            headers: authHeader(),
            params: {
                sharingId: sharingId,
                commentId: item.id
            }
        })
        .then(response => {
            const newComment = {...item, content: editComment};
            list.splice(index, 1, newComment);
            setList([...list]);
            setEditComment('');
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    return (
        <li>
            <div className={connection.userIcon}>
                {
                    item.userImageUrl &&
                    <img 
                        src={item.userImageUrl} 
                        alt="user image" 
                        width="50"
                    />
                }
                {
                    !item.userImageUrl &&
                    <MdPerson size="50" color="#ddd"/>
                }
            </div>
            <div>
                <div className={connection.name}>
                    {item.fullName}
                </div>
                <div className={connection.time}>
                    {moment.utc(item.time).startOf('seconds').fromNow()}
                </div>
                {
                    !editComment && 
                    <div dangerouslySetInnerHTML={{__html: item.content}} onBlur={commentOnBlur} />
                }
                
                {
                    editComment &&
                    <>
                        <div className={connection.editComment} dangerouslySetInnerHTML={{__html: item.content}} onBlur={commentOnBlur} contentEditable ref={editCommentRef}/>
                        <div className={connection.editCommentButtons}>
                            <button onClick={onEditComment} className="smallBtn2">Save</button>
                            <button onClick={onCancel}>Cancel</button>
                        </div>
                    </>
                }
                <div className={connection.reactionBox}>
                    <div onMouseOver={() => setShowLikeList(true)} onMouseLeave={() => setShowLikeList(false)}>
                        <span title="Add Reaction" onClick={() => reactionOnClick('all')}>
                            <MdFaceRetouchingNatural size={25} />
                            {item.totalReaction}
                        </span>
                        {
                            showLikeList &&
                            <SharingLikeAction 
                                connection={connection} 
                                setShowLikeList={setShowLikeList} 
                                id={item.id}
                                list={list}
                                setList={setList}
                                yourReaction={item.yourReaction}
                                setComment={setComment}
                                setShowOptions={setShowOptions}
                                item={item}
                            />
                        }
                    </div>
                    {
                        item.reactionDetails.like > 0 &&
                        reactionIcon('like', true, item.reactionDetails.like, reactionOnClick)
                    }
                    {
                        item.reactionDetails.love > 0 &&
                        reactionIcon('love', true, item.reactionDetails.love, reactionOnClick)
                    }
                    {
                        item.reactionDetails.haha > 0 &&
                        reactionIcon('haha', true, item.reactionDetails.haha, reactionOnClick)
                    }
                    {
                        item.reactionDetails.surprise > 0 &&
                        reactionIcon('surprise', true, item.reactionDetails.surprise, reactionOnClick)
                    }
                    {
                        item.reactionDetails.cry > 0 &&
                        reactionIcon('cry', true, item.reactionDetails.cry, reactionOnClick)
                    }
                    {
                        item.reactionDetails.angry > 0 &&
                        reactionIcon('angry', true, item.reactionDetails.angry, reactionOnClick)
                    }
                    {
                        user.userName !== item.userName &&
                        <span onClick={() => commentReply(item.fullName)} title="Reply this comment">
                            <FaRegComment size={22}/>   
                        </span>
                    }
                    
                </div>
                <div className={connection.moreAction} title='more actions' onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                    <MdMoreHoriz />
                    { showOptions &&
                        <SharingOptions 
                            item={item} 
                            list={list}
                            setList={setList}
                            setShowModal={setShowModal} 
                            setModalType={setModalType}
                            setCurId={setCurId}
                            optionType='comment-item'
                            setCurItem={setCurItem}
                            setShowOptions={setShowOptions}
                            setEditComment={setEditComment}
                            commentRef={editCommentRef}
                        />
                    }
                </div>
            </div>
        </li>
    )
}

const SharingItemComments = ({id, setModalType, setShowModal, rType, setRType, setCurId, setCurItem}) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState();
    const {user, setUser} = useStore();
    const history = useHistory();
    const [comment, setComment] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [isClick, setIsClick] =  useState(false);
    const commentRef = useRef();

    const pickerRef = useRef();
    useClickOutside(pickerRef, () => setShowPicker(false));

    useEffect(()=>{
        id && getList();
    },[id]);

    useEffect(()=>{
        comment && isClick && addComment();
    },[isClick]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_SHARING_COMMENTS_URL,{
            headers: authHeader(),
            params: {
                sharingId: id
            }
        })
        .then(response => {
            if(response.data && response.data.list) {
                setLoading(false);
                setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const onCommentChange = e => {
        const content = e.currentTarget.innerHTML;
        setComment(content);
    }

    const onEmojiClick = (event, emojiObject) => {
        let _comment = comment + '<span class="emoji">' + emojiObject.emoji + '</span>';
        setComment(_comment);
      };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            if (!e.shiftKey && e.currentTarget.innerHTML) {
                setIsClick(true);
                commentRef.current.blur();
            } else {
                setIsClick(false);
            }
        }
    }

    const addComment = () => {
        return axios.post(API_URL.USER_SHARING_COMMENTS_NEW_COMMENT_URL,{comment},{
            headers: authHeader(),
            params: {
                sharingId: id
            }
        })
        .then(response => {
            const newComment = {
                id: 'tempId',
                content: comment,
                fullName: user.full_name,
                reactionDetails: {like: 0, love: 0, haha: 0, surprise: 0, cry: 0, angry: 0},
                time: new Date(),
                totalReaction: 0,
                userImageUrl: user.userImageUrl,
                userName: user.userName
            };

            list.push(newComment);
            setList([...list]);
            setComment('');
            setIsClick(false);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            setIsClick(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const commentReply = (fullName) => {
        setComment(`<span class="mentionName">${fullName}</span>&nbsp;`);
        // commentRef.current.selectionStart = comment.length;
        // commentRef.current.selectionEnd = comment.length;
        commentRef.current.focus();
    }

    return(
        <div className={connection.comments}>
            <h3>Comments</h3>
            {
                loading && <Loader />
            }
            {
                !loading && list.length > 0 &&
                <ul>
                    {
                        list.map((item,index)=>
                            <Comment 
                                key={`comment${index}`} 
                                item={item}
                                index={index}
                                list={list}
                                setList={setList}
                                setModalType={setModalType}
                                setShowModal={setShowModal}
                                rType={rType}
                                setRType={setRType}
                                setCurId={setCurId}
                                commentReply={commentReply}
                                setCurItem={setCurItem}
                                setComment={setComment}
                                commentRef={commentRef}
                                sharingId={id}
                            />
                        )
                    }
                </ul>
            }
            <div className={connection.comment}>
                <div 
                    contentEditable 
                    onBlur={onCommentChange}
                    // onInput={onCommentChange}
                    dangerouslySetInnerHTML={{__html: comment}} 
                    onKeyPress={handleKeyPress}
                    ref={commentRef}
                />
                <MdTagFaces size={25} onClick={() => setShowPicker(true)}/>
                {showPicker &&
                    <div ref={pickerRef} className={connection.emojiBox}>
                        <Picker 
                            disableAutoFocus={true}
                            skinTone={SKIN_TONE_DARK}
                            groupNames={{ smileys_people: 'PEOPLE' }}
                            native
                            onEmojiClick={onEmojiClick} 
                        />
                    </div>
                }
                <MdSend size={25} onClick={addComment} />
            </div>
            
        </div>
    )
}

export default SharingItemComments;