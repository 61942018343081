import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import classes from './styles/classes.module.scss';
import Input from '../../../components/input';
import {toast} from 'react-toastify';
import { OPTIONS_TYPE } from '../../../common/utils';
import classNames from 'classnames';
import ImageMatch from './questionImageMatch';
import RichEditor2 from '../../../components/editor/editor2';
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import Percentage from '../../../components/percentage';
import { Loader } from '../../../components/loading';
// import profile from '../profile/profile.module.scss';

const NewQuestion = ({setShowQuestionForm, assignment, questionId, saveAssignment, 
    lessionId, topics, subTopics}) => {
    const [formData, setFormData] = useState({
        tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}-${lessionId}`,
        tags:"",
        selectedTopics: [],
        selectedSubTopics: [],
        editorState: "",
        answerTime: 1,
        difficulty: {value: 1, label: 1},
        mediaUrl: "",
        mediaType: "",
        explanation: "",
        answerType: "text",
        answerInputs: {words: "", rightAnswer: ""},
        answerImages: {imagesList:[], rightAnswer: ""},
        answerRightText: "",
        arrangeWords: {words: "", rightAnswer: ""},
        wordMatch: { wordsList1: "", wordsList2: "", rightAnswer: ""},
        wordImageMatch: { wordsList: "", imagesList: [], rightAnswer: ""}
    });

    const {setUser} = useStore();
    const history = useHistory();
    const [fileNames, setFileNames] = useState([]);
    const [files, setFiles] = useState([]);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [addFileType, setAddFileType] = useState('fileUrl');
    const [loading, setLoading] = useState();

    useEffect(()=>{
        assignment.assignId && questionId && getQuestionContent(questionId);
    },[questionId]);

    const getQuestionContent = (id) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_ASSIGNMENT_QUESTION_URL,{
            headers: authHeader(),
            params: {
                assignId: assignment.assignId,
                questionId: id,
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setFormData(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const addFileTypeOnChange = type =>  setAddFileType(type);
    const tagsOnChange = e => setFormData({...formData, tags: e && e.target && e.target.value});
    const topicsOnChange = type => setFormData({...formData, selectedTopics: type});
    const subTopicsOnChange = type => setFormData({...formData, selectedSubTopics: type});
    const onContentChange = (data) => setFormData({...formData, editorState : data});
    const mediaUrlOnChange = e => setFormData({...formData, mediaUrl: e && e.target && e.target.value});
    const mediaTypeOnChange = e => setFormData({...formData, mediaType: e && e.target && e.target.value});
    const onExplanationChange = (explanation) => setFormData({...formData, explanation : explanation});
    const answerTimeOnChange = e => setFormData({...formData, answerTime: e.target.value});
    const difficultyOnChange = type => setFormData({...formData, difficulty: type});
    const onAnswerTypeChange = type => 
        setFormData({...formData, answerType : type, answerInputs: {words: "", rightAnswer: ""},answerImages: {imagesList:[], rightAnswer: ""},
            answerRightText: "",arrangeWords: {words: "", rightAnswer: ""},wordMatch: { wordsList1: "", wordsList2: "", rightAnswer: ""},
            wordImageMatch: { wordsList: "", imagesList: [], rightAnswer: ""}
        });
    const answerTextOnChange = e => {
        setFormData({...formData, answerRightText: e.target.value});
    }

    const textListOnChange = e => {
        const answerInputs = formData.answerInputs;
        setFormData({...formData, answerInputs: {...answerInputs, words: e.target.value}});
    }

    const rightTextListOnChange = e => {
        const answerInputs = formData.answerInputs;
        setFormData({...formData, answerInputs: {...answerInputs, rightAnswer: e.target.value}});
    }

    const rightImageListOnChange = e => {
        const answerImages = formData.answerImages;
        setFormData({...formData, answerImages: {...answerImages, rightAnswer: e.target.value}});
    }

    const arrangeWordsOnChange = e => {
        const arrangeWords = formData.arrangeWords;
        setFormData({...formData, arrangeWords: {...arrangeWords, words: e.target.value}});
    }

    const arrangeWordsOrderOnChange = e => {
        const arrangeWords = formData.arrangeWords;
        setFormData({...formData, arrangeWords: {...arrangeWords, rightAnswer: e.target.value}});
    }

    const wordMatchList1OnChange = e => {
        const wordMatch = formData.wordMatch;
        setFormData({...formData, wordMatch: {...wordMatch, wordsList1: e.target.value}});
    }

    const wordMatchList2OnChange = e => {
        const wordMatch = formData.wordMatch;
        setFormData({...formData, wordMatch: {...wordMatch, wordsList2: e.target.value}});
    }

    const wordMatchOrderOnChange = e => {
        const wordMatch = formData.wordMatch;
        setFormData({...formData, wordMatch: {...wordMatch, rightAnswer: e.target.value}});
    }

    const wordImageMatchListOnChange = e => {
        const wordImageMatch = formData.wordImageMatch;
        setFormData({...formData, wordImageMatch: {...wordImageMatch, wordsList: e.target.value}});
    }

    const wordImageMatchOrderOnChange = e => {
        const wordImageMatch = formData.wordImageMatch;
        setFormData({...formData, wordImageMatch: {...wordImageMatch, rightAnswer: e.target.value}});
    }

    const onQuestionSave = () => {

        if(!formData.editorState) {
            toast.error("Content is empty");
            return; 
        }

        if(!formData.answerTime) {
            toast.error("Time should be larger than 0");
            return; 
        }

        if(Number.isNaN(formData.answerTime)) {
            toast.error("Time is a number");
            return; 
        }

        if(formData.answerType === "text" && !formData.answerRightText) {
            toast.error("Right answers is empty!");
                return;
        }

        if(formData.answerType === "text" && formData.answerRightText && formData.answerRightText.split('---').length > 1) {
            const blanks = formData.editorState.match(/(_){3,}/g);
            const blanksLength = blanks ? blanks.length : 0;
            if(blanksLength !== formData.answerRightText.split('---').length) {
                toast.error("Multiple right text with --- should be equal to the number of blanks (at least 3 underscores) in content!");
                return;
            }
        }

        if(formData.answerType === "textChoice" && (!formData.answerInputs.words || !formData.answerInputs.rightAnswer)) {
            toast.error("Options or Right answers text is empty!");
                return;
        }

        if(formData.answerType === "imageChoice" && (!formData.answerImages.imagesList.length || !formData.answerImages.rightAnswer)) {
            toast.error("Missing image or Right answers text is empty!");
                return;
        }

        if(formData.answerType === "arrangeWords" && (!formData.arrangeWords.words || !formData.arrangeWords.rightAnswer)) {
            toast.error("Words list or Anwer Right text is empty!");
                return;
        }

        if(formData.answerType === "wordMatch" && (!formData.wordMatch.wordsList1 || !formData.wordMatch.wordsList1 || !formData.wordMatch.rightAnswer)) {
            toast.error("Words list or Anwer Right text is empty!");
                return;
        }

        if(formData.answerType === "wordImageMatch" && (!formData.wordImageMatch.wordsList || !formData.wordImageMatch.imagesList.length || !formData.wordImageMatch.rightAnswer)) {
            toast.error("Words list or Images or Anwer Right text is empty!");
                return;
        }

        if(formData.answerType === "wordMatch" && (formData.wordMatch.wordsList1.split('---').length !== formData.wordMatch.wordsList1.split('---').length)) {
            toast.error("Number of 2 words list should be equal!");
                return;
        }

        if(formData.answerType === "wordImageMatch" && (formData.wordImageMatch.wordsList.split('---').length !== formData.wordImageMatch.imagesList.length)) {
            toast.error("Number of words list and images should be equal!");
                return;
        }

        saveAssignment && saveAssignment(formData);
        setShowQuestionForm && setShowQuestionForm(false);
    }

    const filesOnChange = (e) => {
        const files = e.target.files;
        if(files.length > 1) {
            toast.error('Allow 1 file only. Please try again!');
            setFiles([]);
        } else {
            setFiles(e.target.files);
            if (e.target.files.length > 0) {
                fileNames.splice(0, 1, e.target.files[0].name);
                setFileNames([...fileNames]);
            }
        }
    }

    const addFilesHandler = (files) => {
        const data = new FormData();

        if (files && files.length > 0) {
            data.append('file', files[0]);
        }

        var config = {
            headers: authHeader(),
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
          };

        axios.post(API_URL.USER_UPLOAD_FILE_URL,data,config)
        .then(response => {
            toast.success("File has been added to question!");
            setFormData({...formData, mediaUrl : response.data.data.link, mediaType: response.data.data.extension})
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div>
        {
            loading &&
            <Loader />
        }
        {percentCompleted < 100 && percentCompleted > 0 && !loading &&
            <Percentage percentCompleted={percentCompleted} />
        }
        {
            (percentCompleted === 0 || percentCompleted === 100) && !loading &&
            <div className={classNames(classes.formControl, classes.autoScroll)}>
                <div className={classes.flex}>
                    <div>
                        <label>Topics</label>
                        <CreatableSelect
                            isMulti
                            onChange={topicsOnChange}
                            options={topics}
                            className={classes.select}
                            value={formData.selectedTopics}
                        />
                    </div>
                    <div>
                        <label>Sub Topics</label>
                        <CreatableSelect
                            isMulti
                            onChange={subTopicsOnChange}
                            options={subTopics}
                            className={classes.select}
                            value={formData.selectedSubTopics}
                        />
                    </div>
                    <Input 
                        type="text" 
                        placeholder="" 
                        onChange={tagsOnChange}
                        value={formData.tags}
                        label="Tags (hint: separated by commas)"
                    />
                </div>

                <RichEditor2 
                    label="Question Content"
                    onChange={onContentChange}
                    editorData={formData.editorState}
                    isFull={true}
                />

                <div className={classes.mediaArea}>
                    <Input
                        className={classes.inline}
                        label='Add Media File'
                        type="radio"
                        items={OPTIONS_TYPE.ADD_FILE_TYPE}
                        value={addFileType}
                        onChange={addFileTypeOnChange}
                    /> 
                    {
                        addFileType === "fileUrl" && 
                        <>
                            <div className={classes.mediaFileUrl}>
                                <input type="text" onChange={mediaUrlOnChange} value={formData.mediaUrl} placeholder="File Url"/>
                                <input type="text" onChange={mediaTypeOnChange} value={formData.mediaType} placeholder="File Type"/>
                            </div>
                        </>
                    }

                    {
                        addFileType === "upload" && 
                        <div className={classes.mediaFileUpload}>
                            <Input 
                                type="file" 
                                placeholder="Click to choose file"
                                onChange={filesOnChange}
                                files={files}
                                label=""
                                isMulti={false}
                                fileNames={fileNames}
                                className={classes.inputFile}
                            />
                            <input type="button" className="btn" onClick={() => addFilesHandler(files)} value="Upload" />
                        </div>
                    }
                    
                </div>
                
                <Input
                    className={classes.select}
                    type="select"
                    options={OPTIONS_TYPE.QUESTION_DIFFICULTY_LEVEL}
                    placeholder=''
                    onChange={difficultyOnChange}
                    value={formData.difficulty}
                    label="Difficulty Level"
                />
                <Input 
                    type="number" 
                    placeholder="" 
                    min={0}
                    onChange={answerTimeOnChange}
                    value={formData.answerTime}
                    label="Answer time (minutes)"
                />
                <Input
                    className={classes.inline}
                    label='Answer Type'
                    type="radio"
                    items={OPTIONS_TYPE.ANSWER_TYPE}
                    value={formData.answerType}
                    onChange={onAnswerTypeChange}
                />    
                {
                    formData.answerType === "text" && 
                    <div>
                        <Input 
                            type="textarea"
                            label="Right answers (separated by ---)"
                            onChange={answerTextOnChange}
                            value={formData.answerRightText}
                        />
                    </div>
                }

                {
                    formData.answerType === "textChoice" && 
                    <div className={classes.answerOptions}>
                        <Input 
                            type="textarea"
                            label="Options (separated by ---)"
                            onChange={textListOnChange}
                            value={formData.answerInputs.words}
                        />
                        <Input 
                            type="textarea"
                            label="Right answer (separated by ---)"
                            onChange={rightTextListOnChange}
                            value={formData.answerInputs.rightAnswer}
                        />
                    </div>
                }

                {
                    formData.answerType === "imageChoice" && 
                    <>
                    <ImageMatch
                        setFormData={setFormData} 
                        formData={formData}
                        length={10}
                        formChild="answerImages"
                        label="Images list (maximum 10 images, maximum 500 Kb for each)"
                    />
                    <Input 
                        type="textarea"
                        label="Right answer number (separated by ---)"
                        onChange={rightImageListOnChange}
                        value={formData.answerImages.rightAnswer}
                    />
                    </>
                }

                {
                    formData.answerType === "arrangeWords" &&
                    <div className={classes.answerOptions}>
                        <Input 
                            type="textarea"
                            label="Words list (separated by ---)"
                            onChange={arrangeWordsOnChange}
                            value={formData.arrangeWords.words}
                        />
                        <Input 
                            type="textarea"
                            label="Right answer (separated by ---)"
                            onChange={arrangeWordsOrderOnChange}
                            value={formData.arrangeWords.rightAnswer}
                        />
                    </div>
                }

                {
                    formData.answerType === "wordMatch" &&
                    <div className={classes.answerOptions}>
                        <Input 
                            type="textarea"
                            label="Words list 1 (separated by ---)"
                            onChange={wordMatchList1OnChange}
                            value={formData.wordMatch.wordsList1}
                        />
                        <Input 
                            type="textarea"
                            label="Words list 2 (separated by ---)"
                            onChange={wordMatchList2OnChange}
                            value={formData.wordMatch.wordsList2}
                        />
                        <Input 
                            type="textarea"
                            label="Right answer for list 2 (separated by ---)"
                            onChange={wordMatchOrderOnChange}
                            value={formData.wordMatch.rightAnswer}
                        />
                    </div>
                }

                {
                    formData.answerType === "wordImageMatch" &&
                    <div className={classes.answerOptions}>
                        <Input 
                            type="textarea"
                            label="Words list (separated by ---)"
                            onChange={wordImageMatchListOnChange}
                            value={formData.wordImageMatch.wordsList}
                        />
                        <ImageMatch
                            setFormData={setFormData} 
                            formData={formData}
                            length={formData.wordImageMatch.wordsList.split('---').length}
                            formChild="wordImageMatch"
                            label="Images list (maximum 500 Kb for each)"
                        />
                        <Input 
                            type="textarea"
                            label="Right answer for words list (separated by ---)"
                            onChange={wordImageMatchOrderOnChange}
                            value={formData.wordImageMatch.rightAnswer}
                        />
                    </div>
                }

                <RichEditor2 
                    label="Explanation"
                    onChange={onExplanationChange}
                    editorData={formData.explanation}
                    isFull={true}
                />

                <div className={classes.buttons}>
                    <button className="btn" onClick={onQuestionSave}>Save Question</button>
                </div>
            </div>
        }
        </div>
    )
}

export default NewQuestion;