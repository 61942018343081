import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../../services/auth-header';
import {Logout} from '../../../../services/auth.service';
import { useStore } from '../../../../store/useStore';
import { getError } from '../../../../common/utils';
import meeting from '../styles/meeting.module.scss';
import profile from '../../profile/profile.module.scss';
import { endsWithAny } from '../../../../common/format';
import {uniqueId} from 'lodash';
import {MdFastForward, MdDone, MdClose} from 'react-icons/md';
import Input from '../../../../components/input';
import classNames from 'classnames';
import RichEditor2 from '../../../../components/editor/editor2';
import { UploadFile } from './liveTest';

const QuestionDetail = ({item, getQuestionContent, isInResult, studentId, isQuestionReport, assignId, getAssigment, setCurrentQuestionId}) => {
    const history = useHistory();
    const {setUser, user} = useStore();
    const [formData, setFormData] = useState({
        score: 0,
        comment: '',
        fileUrl: ''
    });

    const [markup, setMarkup] = useState('');
    const [directlyMarkup, setDirectlyMarkup] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [showOrigin, setShowOrigin] = useState(false);

    const markupOnChange = value => setMarkup(value); 
    const scoreOnChange = e => setFormData({...formData, score: e.target.value});
    const commentOnChange = value => setFormData({...formData, comment: value});

    const onSubmit = () => {
        if(!formData.score) {
            toast.error('Please input score');
            return;
        }

        const url = API_URL.POST_USER_MANUAL_MARK_URL;
        axios.post(url,formData,{
            headers: authHeader(),
            params: {
                assignId: assignId,
                questionId: item.questionId,
                studentId: studentId
            }
        })
        .then(response => {
            setCurrentQuestionId && setCurrentQuestionId(item.questionId);
            getAssigment && getAssigment();
            getQuestionContent && getQuestionContent(item.questionId);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const saveDirectlyMarkup = (content) => {
        if(!markup) {
            toast.error('Please input markup');
            return;
        }

        if(markup === content) {
            toast.error('Please change something!');
            return;
        }

        const url = API_URL.POST_USER_DIRECTLY_MARKUP_URL;
        axios.post(url,{markup: markup},{
            headers: authHeader(),
            params: {
                assignId: assignId,
                questionId: item.questionId,
                studentId: studentId
            }
        })
        .then(response => {
            setCurrentQuestionId && setCurrentQuestionId(item.questionId);
            getAssigment && getAssigment();
            getQuestionContent && getQuestionContent(item.questionId);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <>
            <div className={meeting.questionItem}>
                <div className={meeting.flex}>
                    {item.topics && item.topics.length > 0 &&
                        <div className={meeting.reportInfo}>Topics <strong>{item.topics.join(', ')}</strong></div>
                    }
                    {item.subTopics && item.subTopics.length > 0 &&
                        <div className={meeting.reportInfo}>Sub Topics <strong>{item.subTopics.join(', ')}</strong></div>
                    }
                    {item.tags &&
                        <div className={meeting.reportInfo}>Tags <strong>{item.tags}</strong></div>
                    }
                    <div className={meeting.reportInfo}>Time<strong>{item.answerTime ? `${Math.floor(item.answerTime/60) > 0 ? 
                        Math.floor(item.answerTime/60) + ' mins' : ""} ${item.answerTime%60 ? item.answerTime%60 + ' secs': ''}` : 0}</strong></div>
                    {item.difficulty && 
                        <div className={meeting.reportInfo}>Difficulty Level<strong>{item.difficulty.value}</strong></div>
                    }
                </div>
            </div>
            <div className={meeting.answerItem}>
                {item.editorState &&
                    <>
                    <h3>Question content</h3>
                    <div dangerouslySetInnerHTML={{__html: item.editorState.replace('(autoplay)|(autoPlay)|(AUTOPLAY)','')}} className={meeting.questionContent}/>
                    </>
                }

                {
                    item.mediaUrl && !endsWithAny(['.mp3', '.ogg', '.wav', 'mp4'],item.mediaUrl) &&
                    <iframe
                        frameBorder="0"
                        width={'100%'}
                        height={['mp3', 'm4a'].includes(item.mediaType) ? 150 : '500'}
                        src={item.mediaUrl.replace(/\/view\?usp=sharing/g, '/preview').replace(/\/view/g, '/preview')}>
                    </iframe>
                }

                {
                    item.mediaUrl && endsWithAny(['.mp3', '.ogg', '.wav'],item.mediaUrl) &&
                    <audio controls>
                        <source src={item.mediaUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                }

                {
                    item.mediaUrl && item.mediaUrl.endsWith('mp4') &&
                    <video width="320" height="240" controls>
                        <source src={item.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

                {
                    !isInResult && 
                    <div className={meeting.options}>
                        {item.answerType === "textChoice" && item.answerInputs && item.answerInputs.words &&
                            item.answerInputs.words.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }

                        { item.answerType === "arrangeWords" && item.arrangeWords && item.arrangeWords.words && 
                            <div>
                                {
                                    item.arrangeWords.words.map(option => (
                                        <span key={uniqueId()}>{option}</span>
                                    ))
                                } 
                            </div>
                        }  

                        { item.answerType === "imageChoice" && item.answerImages && item.answerImages.imagesList &&
                            item.answerImages.imagesList.map((option,index) => (
                                <span key={uniqueId()}>
                                    <img src={option} alt="choice image" />
                                    <span>{index+1}</span>
                                </span>
                            ))
                        }

                        {  item.answerType === "wordMatch" && item.wordMatch && item.wordMatch.optionslist1 &&
                            <div>
                            {
                                    item.wordMatch.optionslist1.map(option => (
                                        <span key={uniqueId()}><img src={option} alt="choice image" /></span>
                                    ))
                            } 
                            </div>
                            
                        }  

                        { item.answerType === "wordImageMatch" && item.wordImageMatch && item.wordImageMatch.wordsList &&
                            <div>
                                {
                                    item.wordImageMatch.wordsList.map(option => (
                                        <span key={uniqueId()}>{option}</span>
                                    ))
                                } 
                            </div>
                        }   

                        {item.answerType === "wordMatch" && item.wordMatch && item.wordMatch.optionslist2 &&
                            item.wordMatch.optionslist2.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }

                        { item.answerType === "wordImageMatch" && item.wordImageMatch && item.wordImageMatch.imagesList &&
                            <div>
                                {
                                    item.wordImageMatch.imagesList.map(option => (
                                        <span key={uniqueId()}><img src={option} alt="choice image" /></span>
                                    ))
                                } 
                            </div>
                        }   
                    </div>
                }
                {
                    !(item.result && item.result.includes('%')) && item.result !== "pending" &&
                    <div className={meeting.rightAnswers}>
                        <h3>Correct Answer</h3>
                        <div>
                        {
                            item.rightAnswer && Array.isArray(item.rightAnswer) && 
                            item.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }    
                        { item.answerRightText && Array.isArray(item.answerRightText) && 
                            item.answerRightText.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        { item.answerInputs && Array.isArray(item.answerInputs.rightAnswer) && 
                            item.answerInputs.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        { item.answerImages && Array.isArray(item.answerImages.rightAnswer) && 
                            item.answerImages.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        { item.arrangeWords && Array.isArray(item.arrangeWords.rightAnswer) && 
                            item.arrangeWords.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        { item.wordMatch && Array.isArray(item.wordMatch.rightAnswer) && 
                            item.wordMatch.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        { item.wordImageMatch && Array.isArray(item.wordImageMatch.rightAnswer) && 
                            item.wordImageMatch.rightAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                        }
                        
                        </div>
                    </div>
                }
                
                {
                    studentId && !isQuestionReport &&
                    <div className={meeting.studentAnswers}>
                        <h3>
                            Student's Answer 
                            {
                                !directlyMarkup && user.userRole === "Teachers" && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <button className="btn" onClick={() => setDirectlyMarkup(true)}>Directly Markup</button>
                            }
                            {
                                directlyMarkup && user.userRole === "Teachers" && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <>
                                <button className="btn" disabled={!markup} onClick={() => saveDirectlyMarkup(item.studentAnswer[0])}>Save Markup</button>
                                <button onClick={() => setDirectlyMarkup(false)}>Cancel</button>
                                </>
                            }
                        </h3>

                            {
                                directlyMarkup && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <RichEditor2 
                                    editorData={item.answerTeacherMarkup ? item.answerTeacherMarkup : item.studentAnswer[0]}
                                    onChange={markupOnChange}
                                    isFull={true}
                                />
                            }

                        <div className={item.markType ==="manual-open-ended" ? meeting.textInput : ""}>
                            { item.studentAnswer && Array.isArray(item.studentAnswer) && item.markType === "auto" &&
                            item.studentAnswer.map(option => (
                                <span key={uniqueId()}>{option}</span>
                            ))
                            }

                            { item.studentAnswer && !Array.isArray(item.studentAnswer) && !item.result.includes('%') && item.result !== "pending" && item.markType === "auto" &&
                                <span key={uniqueId()}>{item.studentAnswer}</span>
                            }

                            {
                                !directlyMarkup && item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <div className={meeting.box} dangerouslySetInnerHTML={{__html: item.answerTeacherMarkup ? item.answerTeacherMarkup : item.studentAnswer[0]}} />
                            }

                            {
                                item.markType ==="manual-open-ended" && item.studentAnswer && item.studentAnswer.length > 0 && item.answerTeacherMarkup &&
                                <div className={meeting.box}>
                                    <a className={meeting.viewOrigin} href="#" onClick={() => setShowOrigin(!showOrigin)}>{showOrigin ? 'Hide' : 'View'} Original answer</a>
                                    <div style={{display: showOrigin ? 'block' : 'none'}} dangerouslySetInnerHTML={{__html: item.studentAnswer[0]}} />
                                </div>
                            }

                            {
                                ((item.markType ==="manual-open-ended" || item.markType ==="manual-recording") && item.studentAnswer && item.studentAnswer.length > 1 && item.studentAnswer[1]) &&
                                <div className={meeting.box}>
                                    <a href={item.studentAnswer[1]} target="_blank">View Attached file</a> 
                                </div>
                            }

                            {
                                item.markType ==="manual-recording" && item.studentAnswer && item.studentAnswer.length > 0 && item.studentAnswer[0] &&
                                <div className={meeting.box}>
                                    <video src={item.studentAnswer[0]} controls />
                                </div>
                            }

                            {
                                item.markType ==="manual-recording" && item.studentAnswer && item.studentAnswer.length > 2 && item.studentAnswer[2] &&
                                <div className={meeting.box}>
                                    Note: <div dangerouslySetInnerHTML={{__html: item.studentAnswer[2]}} />
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    isQuestionReport &&
                    <div className={meeting.studentAnswers}>
                        <h3>Student's Answer</h3>
                            <div>
                                <h4 className={meeting.correct}><MdDone title="Correct"/> Correct</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.correct &&
                                    <div>
                                        <strong>{item.studentAnswer.correct} students: </strong>
                                        {
                                            item.studentAnswer.correctStudents && item.studentAnswer.correctStudents.length > 0 &&
                                            item.studentAnswer.correctStudents.join(', ')
                                        }
                                    </div>
                                }

                                <h4 className={meeting.incorrect}><MdClose title="Incorrect" /> Incorrect</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.incorrect &&
                                    <ul>
                                    <strong>{item.studentAnswer.incorrect} students: </strong>
                                    {
                                        item.studentAnswer.incorrectStudents && item.studentAnswer.incorrectStudents.length > 0 &&
                                        item.studentAnswer.incorrectStudents.map(student => 
                                            <li key={uniqueId()}>
                                                {student.name} : <p>
                                                    {student.answer && student.answer.length >0 &&
                                                        student.answer.map(answer => <span key={uniqueId()}>{answer}</span>)
                                                    }
                                                </p>
                                            </li>
                                        ) 
                                    }
                                    </ul>
                                }

                                <h4 className={meeting.skip}><MdFastForward title="Skip" /> Skip</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.skip &&
                                    <div>
                                    <strong>{item.studentAnswer.skip} students: </strong>
                                    {
                                        item.studentAnswer.skipStudents && item.studentAnswer.skipStudents.length > 0 &&
                                        item.studentAnswer.skipStudents.join(', ')
                                    }
                                    </div>
                                }

                                <h4 className={meeting.timeOut}><span>Timeout</span> Timeout</h4>
                                {
                                    item.studentAnswer && item.studentAnswer.timeout &&
                                    <div>
                                    <strong>{item.studentAnswer.timeout} students: </strong>
                                    {
                                        item.studentAnswer.timeoutStudents && item.studentAnswer.timeoutStudents.length > 0 &&
                                        item.studentAnswer.timeoutStudents.join(', ')
                                    }
                                    </div>
                                }
                            </div>
                    </div>
                }
                {
                    studentId && item.result.includes("%") &&
                    <div className={meeting.manualMark}>
                        <h3>Teacher marked</h3>
                        <div className={meeting.box}>
                            <div><label>Score:</label> <strong>{item.result}</strong></div>
                            {
                                item.teacherNote && 
                                <div>
                                    <label>Teacher Note:</label>
                                    <div dangerouslySetInnerHTML={{__html: item.teacherNote}} />
                                </div>
                            }
                            {
                                item.teacherNoteUrl && 
                                <div><a href={item.teacherNoteUrl} target="_blank">View Attached file</a></div>
                            }
                        </div>
                    </div>
                }
                {
                    item.explanation && 
                    <div className={meeting.explanation}>
                        <h3>Explanation</h3>
                        <div className={meeting.full} dangerouslySetInnerHTML={{__html: item.explanation}} />
                    </div>
                }
            </div>
            {
                user.userRole === "Teachers" && studentId && (item.result === "pending" || item.result.includes('%')) &&
                <div className={meeting.manualMarkForm}>  
                    <h3>{item.result.includes('%') ? 'Re-mark Form' : 'Mark Form'}</h3>                  
                    <div className={classNames(meeting.form, meeting.showForm)}>
                        
                            <div className={meeting.flex}>
                                <div>
                                    <label>Score</label>
                                    <Input
                                        className={meeting.score}
                                        type="number"
                                        min={0}
                                        max={100}
                                        placeholder=''
                                        onChange={scoreOnChange}
                                    />
                                    <span className={meeting.maxScore}>/ 100</span>
                                </div>
                                <UploadFile markData={formData} setMarkData={setFormData} />
                            </div>
                            <div className={meeting.control}>
                                <RichEditor2
                                    label="Comment"
                                    onChange={commentOnChange}
                                    editorData={formData.comment}
                                />
                            </div>
                            <div className={meeting.buttons}>
                                <button className="btn" onClick={onSubmit}>Submit</button>
                            </div>
                        </div>
                </div>
            }    
        </>
    )
}

export default QuestionDetail;