import {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import dashboard from './dashboard.module.scss';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import Filter from './filter';
import {MdKeyboardArrowDown, MdKeyboardArrowUp, MdPerson} from 'react-icons/md';
import Modal from '../../../components/modal';
import icon5 from '../../../images/icon5.svg';
import icon4 from '../../../images/icon4.svg';
import icon3 from '../../../images/icon3.svg';
import icon2 from '../../../images/icon2.svg';
import icon1 from '../../../images/icon1.svg';
import classNames from 'classnames';
import {MdArrowUpward, MdArrowDownward} from 'react-icons/md';

const subjects = ['Math', 'English', 'Science'];
export const StudentDetails = ({student, setUser, history}) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [loadingData, setLoadingData] = useState();
    const [curSubject, setCurSubject] = useState(subjects[0]);
    const [type, setType] = useState('weekly');

    useEffect(()=>{
        curSubject && getList();
    },[student.userName, curSubject, type]);

    const getList = () => {
        setLoadingData(true);
        return axios.get(API_URL.GET_USER_DASHBOARD_PROFICIENCY_DETAILS_URL,{
            headers: authHeader(),
            params: {
                studentId: student.userName,
                subject: curSubject,
                type: type,
            }
        })
        .then(response => {
            if(response.data) {
                 response.data.list && setData(response.data.list);
                 setLoadingData(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoadingData(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }
    return (
        <div>
            <div className="topTabs">
                <button onClick={() => setType('weekly')} className={type==="weekly" ? "active" : undefined}>Weekly</button>
                <button onClick={() => setType('monthly')} className={type==="monthly" ? "active" : undefined}>Monthly</button>
                <button onClick={() => setType('quarterly')} className={type==="quarterly" ? "active" : undefined}>Quarterly</button>
            </div>
            {loading && <Loader />}
            {!loading && subjects.length > 0 &&
                <ul className={dashboard.subjectScroll}>
                    {
                        subjects.map((sj, index) =>
                        <li key={`sj${index}`} onClick={() => setCurSubject(sj)} className={sj === curSubject ? dashboard.active : undefined}>
                            <div className={dashboard.title}>
                                <span>{sj}</span>
                                { sj === curSubject ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                            </div>
                            {
                                loadingData && sj === curSubject && <Loader />
                            }
                            {
                                !loadingData && sj === curSubject && data && data.length > 0 &&
                                <ul className={classNames(dashboard.dashboardTable, dashboard.proficiencylist, dashboard.details)}>
                                    <li className={dashboard.headings}>
                                            <div className={dashboard.image}>
                                                {
                                                    student.imageUrl &&
                                                    <img src={student.imageUrl} alt="profile"/>
                                                }

                                                {
                                                    !student.imageUrl && <MdPerson size="40" color="#CCCCCC"/>
                                                }
                                        </div>
                                        
                                        <h3>
                                            {student.fullName} <span>{student && student.grade}</span>
                                            {!student.fullName && 'Skill'}
                                        </h3>
                                        <div>
                                            <strong>Mastery</strong>
                                            <img src={icon5} alt="Mastery" width="50"/>
                                        </div>
                                        <div>
                                            <strong>Competent</strong>
                                            <img src={icon4} alt="Competent" width="50"/>
                                        </div>
                                        <div>
                                            <strong>Developing</strong>
                                            <img src={icon3} alt="Developing" width="50"/>
                                        </div>
                                        <div>
                                            <strong>Beginning</strong>
                                            <img src={icon2} alt="Beginning" width="50"/>
                                        </div>
                                        <div>
                                            <strong>Incomplete</strong>
                                            <img src={icon1} alt="Incomplete" width="50"/>
                                        </div>
                                    </li>
                                    {
                                        data.length > 0 && data.map(item => 
                                        <li key={uniqueId()}>
                                            <div></div>
                                            <div>{item.skill}</div>
                                            <div>
                                                {item.mastery.value}
                                                {item.mastery.up && <span className={dashboard.up}><MdArrowUpward />{item.mastery.up}</span>}
                                                {item.mastery.down && <span className={dashboard.down}><MdArrowDownward />{item.mastery.down}</span>}
                                            </div>
                                            <div>
                                                {item.competent.value}
                                                {item.competent.up && <span className={dashboard.up}><MdArrowUpward />{item.competent.up}</span>}
                                                {item.competent.down && <span className={dashboard.down}><MdArrowDownward />{item.competent.down}</span>}
                                            </div>
                                            <div>
                                                {item.developing.value}
                                                {item.developing.up && <span className={dashboard.up}><MdArrowUpward />{item.developing.up}</span>}
                                                {item.developing.down && <span className={dashboard.down}><MdArrowDownward />{item.developing.down}</span>}
                                            </div>
                                            <div>
                                                {item.beginning.value}
                                                {item.beginning.up && <span className={dashboard.up}><MdArrowUpward />{item.beginning.up}</span>}
                                                {item.beginning.down && <span className={dashboard.down}><MdArrowDownward />{item.beginning.down}</span>}    
                                            </div>
                                            <div>
                                                {item.incomplete.value}
                                                {item.incomplete.up && <span className={dashboard.up}><MdArrowUpward />{item.incomplete.up}</span>}
                                                {item.incomplete.down && <span className={dashboard.down}><MdArrowDownward />{item.incomplete.down}</span>}
                                            </div>
                                        </li>)
                                    }
                                </ul>
                            }
                        </li>)
                    }
                </ul>
            }
        </div>
    )
}

const ProficiencyReport = () => {
    const contentRef = useRef();
    const history = useHistory();
    const {setUser} = useStore();
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [grade, setGrade] = useState();
    const [filterGrade, setFilterGrade] = useState();
    const [filterSubject, setFilterSubject] = useState();
    const [filterClassName, setFilterClassName] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [currentStudent, setCurrentStudent] = useState();

    useEffect(()=>{
        let isCancelled = false;
        axios.get(API_URL.GET_USER_DASHBOARD_GRADES_FILTER_URL,{
            headers: authHeader()})
        .then(response => {
            if(response.data && !isCancelled) {
                response.data.grade && setGrade(response.data.grade); 
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);
        });
        return () => {
            isCancelled = true;
          };
    },[])

    useEffect(()=>{
        getData(true);
    },[filterGrade, filterSubject, dateFrom, dateTo, filterClassName]);

    const getData = (condition) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_DASHBOARD_PROFICIENCY_URL,{
            headers: authHeader(),
            params: {
                grade: filterGrade && filterGrade.value,
                subject: filterSubject && filterSubject.value,
                fromDate: dateFrom,
                toDate: dateTo,
                classId: filterClassName && filterClassName.value
            }
        })
        .then(response => {
            if(response.data && condition) {
                 response.data.list && setData(response.data.list);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onGradeChange = type => setFilterGrade(type);
    const onSubjectChange = type => setFilterSubject(type);
    const onClassNameChange = type => setFilterClassName(type);

    const studentOnClick = (userName,fullName, imageUrl) => {
        setCurrentStudent({
            userName,
            fullName,
            imageUrl
        })
        setShowModal(true);
    }

    return (
        <div className={dashboard.dashboard}>
            {grade && grade.length && 
                <Filter 
                    onGradeChange={onGradeChange}
                    onSubjectChange={onSubjectChange}
                    grades={grade}
                    subject={filterSubject}
                    filterGrade={filterGrade}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    className={filterClassName}
                    onClassNameChange={onClassNameChange}
                />
            }
            {loading && <Loader />}
            {!loading && data &&
                <>        
                <ul className={classNames(dashboard.dashboardTable, dashboard.proficiencylist)}>
                    <li className={dashboard.headings}>
                        <div></div>
                        <div></div>
                        <div>
                            <strong>Mastery</strong>
                            <img src={icon5} alt="Mastery" width="50"/>
                        </div>
                        <div>
                            <strong>Competent</strong>
                            <img src={icon4} alt="Competent" width="50"/>
                        </div>
                        <div>
                            <strong>Developing</strong>
                            <img src={icon3} alt="Developing" width="50"/>
                        </div>
                        <div>
                            <strong>Beginning</strong>
                            <img src={icon2} alt="Beginning" width="50"/>
                        </div>
                        <div>
                            <strong>Incomplete</strong>
                            <img src={icon1} alt="Incomplete" width="50"/>
                        </div>
                    </li>
                    {
                        data.length > 0 && data.map(student => 
                        <li key={uniqueId()} onClick={() => studentOnClick(student.userName, student.fullName, student.imageUrl)}>
                            <div className={dashboard.image}>
                                {
                                    student.imageUrl &&
                                    <img src={student.imageUrl} alt="profile"/>
                                }

                                {
                                    !student.imageUrl && <MdPerson size="40" color="#CCCCCC"/>
                                }
                            </div>
                            <h3>{student.fullName} <span>{student.grade}</span></h3>
                            <div>{student.mastery} <label>skills</label></div>
                            <div>{student.competent} <label>skills</label></div>
                            <div>{student.developing} <label>skills</label></div>
                            <div>{student.beginning} <label>skills</label></div>
                            <div>{student.incomplete} <label>skills</label></div>
                        </li>)
                    }
                </ul>
                </>
            }

            <Modal
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width="80%"
                height="auto"
                content={
                    <div ref={contentRef} className={dashboard.modalContent}>
                        {
                            currentStudent && currentStudent.userName && 
                            <StudentDetails 
                                student={currentStudent}
                                setUser={setUser}
                                history={history}
                            />
                        }
                    </div>
                } />
        </div>
    )
}

export default ProficiencyReport;