import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import { newQuestionSteps, shuffle } from '../../../common/utils';
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import Percentage from '../../../components/percentage';
import { Loader } from '../../../components/loading';
import QuestionStep1 from './qStep1';
import Steps from '../../../components/steps';
import lib from './new.module.scss';
import QuestionStep2 from './qStep2';
import QuestionStep3 from './qStep3';
import QuestionReview from './qReview';
import QuestionStep4 from './qStep4';
import {isEqual, omit} from 'lodash';

const NewQuestion = ({type, setShowQuestionForm, assignment, questionId, setAssignment, setReloadList,
    filtersData, fromWorkSheet, setReload, showModal, setShowModal, selectItem, editQData, isClone, folderId}) => {
    const [qFormData, setQFormData] = useState({
        tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}`,
        name: 'question name',
        topics: [],
        subTopics: [],
        tags: [],
        levels: fromWorkSheet ? assignment.levels : [],
        subjects: fromWorkSheet ? assignment.subjects : [],
        privacy: fromWorkSheet ? assignment.privacy : "onlyMe",
        permissionForUsers: fromWorkSheet ? assignment.permissionForUsers : [],
        title: "",
        content: "",
        questionTime: 1,
        answerTime: null,
        difficulty: {value: 1, label: 1},
        mediaUrl: "",
        mediaType: "",
        explanation: "",
        allowClone: true,
        
        questionType: {},
        answerType: "",
        noOfBlanks: 0,
        shortAnswerBlanks: 1,
        setMaxChar: false,
        maxInputChar: 0,
        options: ["",""],
        options2: [],
        correctOptions: [],
        correctText: [],
        openEndedType: 'Self-marked',
    });

    const {setUser} = useStore();
    const history = useHistory();

    const [step, setStep] = useState(1);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [loading, setLoading] = useState();

    useEffect(()=>{
        questionId && getQuestionContent(questionId);
    },[questionId]);

    useEffect(() => {
        if (step === 5 && qFormData.answerType === 'matching' && isEqual(qFormData.correctText, qFormData.options2)) {
            const _array = [...qFormData.options2];
            const shuffleArray = shuffle(_array);
            let arr = [];
            _array.forEach(item => {
                shuffleArray.includes(item) && arr.push(shuffleArray.indexOf(item));
            });
            setQFormData({...qFormData, options2: shuffleArray, correctText: qFormData.options2, correctOptions: arr});
        }

        if (step === 5 && qFormData.answerType === 'ordering' && isEqual(qFormData.correctText, qFormData.options)) {
            const _array = [...qFormData.options];
            const shuffleArray = shuffle(_array);
            let arr = [];
            _array.forEach(item => {
                shuffleArray.includes(item) && arr.push(shuffleArray.indexOf(item));
            });
            setQFormData({...qFormData, options: shuffleArray, correctText: qFormData.options, correctOptions: arr});
        }
         
    }, [step]);

    useEffect(()=>{
        if(!showModal && type === "question") {
            setQFormData({
                tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}`,
                name: 'question name',
                topics: fromWorkSheet ? assignment.topics : [],
                subTopics: fromWorkSheet ? assignment.subTopics : [],
                tags: [],
                levels: fromWorkSheet ? assignment.levels : [],
                subjects: fromWorkSheet ? assignment.subjects : [],
                privacy: fromWorkSheet ? assignment.privacy : "onlyMe",
                permissionForUsers: fromWorkSheet ? assignment.permissionForUsers : [],
                title: "",
                content: "",
                questionTime: 1,
                answerTime: null,
                difficulty: {value: 1, label: 1},
                mediaUrl: "",
                mediaType: "",
                explanation: "",
                allowClone: true,
                
                hasNotGiven: false,
                questionType: {},
                answerType: "",
                noOfBlanks: 0,
                shortAnswerBlanks: 1,
                setMaxChar: false,
                maxInputChar: 0,
                options: ["",""],
                options2: [],
                correctOptions: [],
                correctText: [],
                openEndedType: 'Self-marked',
            });
            setStep(1);
            setShowQuestionForm(false);
        }
    },[showModal]);

    useEffect(()=>{
        if(selectItem && editQData) { 
            setQFormData(editQData);
            if (isClone) {
                setQFormData({...editQData, id: null, name: "question name", tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}`});
            }
        } else {
            setQFormData({
                tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}`,
                name: 'question name',
                topics: fromWorkSheet ? assignment.topics : [],
                subTopics: fromWorkSheet ? assignment.subTopics : [],
                tags: [],
                levels: fromWorkSheet ? assignment.levels : [],
                subjects: fromWorkSheet ? assignment.subjects : [],
                privacy: fromWorkSheet ? assignment.privacy : "onlyMe",
                permissionForUsers: fromWorkSheet ? assignment.permissionForUsers : [],
                title: "",
                content: "",
                questionTime: 1,
                answerTime: null,
                difficulty: {value: 1, label: 1},
                mediaUrl: "",
                mediaType: "",
                explanation: "",
                allowClone: true,
                
                hasNotGiven: false,
                questionType: {},
                answerType: "",
                noOfBlanks: 0,
                shortAnswerBlanks: 1,
                setMaxChar: false,
                maxInputChar: 0,
                options: ["",""],
                options2: [],
                correctOptions: [],
                correctText: [],
                openEndedType: 'Self-marked',
            });
        }
    },[selectItem, editQData]);

    const getQuestionContent = (id) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_ASSIGNMENT_QUESTION_URL,{
            headers: authHeader(),
            params: {
                questionId: id,
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setQFormData(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter' || event.key === 'Spacebar'){
            onNext();
        }
    }

    const handleKeyPressSave = (event) => {
        if(event.key === 'Enter' || event.key === 'Spacebar'){
            onSave();
        }
    }

    const onNext = () =>  {
        checkCondition(step) && setStep(step + 1);
    }

    const onPrev = () =>  {
        setStep(step - 1);
    }

    const onSave = () => {

        if(assignment && setAssignment && assignment.questions) {
            const arr = assignment.questions;
            const arrIds = arr && arr.length && arr.map(item => (item.id || item.tempQuestionId));
            if(arrIds && arrIds.length && arrIds.includes(qFormData.id || qFormData.tempQuestionId)) {
                const index = arrIds.indexOf(qFormData.id) || arrIds.indexOf(qFormData.tempQuestionId);
                arr.splice(index, 1, qFormData);
            } else {
                arr.push(qFormData); 
            }

            setAssignment && setAssignment({...assignment, questions: arr});
            setShowQuestionForm && setShowQuestionForm(false);
        }

        if (!fromWorkSheet) {
            axios.post(API_URL.POST_USER_CREATE_QUESTION_URL, {question: qFormData} , {
                headers: authHeader(),
                params: {
                    questionId: qFormData.questionId,
                    folderId,
                }
            })
            .then(response => {
                setShowModal && setShowModal(false);
                setReloadList && setReloadList(Math.random());
                setReload && setReload(Math.random());
            })
            .catch(error => {
                const resMessage = getError(error);
                setLoading(false);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }
            })
        }
        
    }

    const checkCondition = (step) => {
        if(step > 0) {
            if(qFormData.levels && !qFormData.levels.length) {
                toast.error("Please select Levels");
                return false;
            }
            if(qFormData.subjects && !qFormData.subjects.length) {
                toast.error("Please select Subjects");
                return false;
            }
        }

        if(step > 1) {
            if(!qFormData.content) {
                toast.error("Please add question content");
                return false;
            }
        }

        if(step > 2) {
            if(!qFormData.answerType) {
                toast.error("Please select question type");
                return false;
            }

            if (["choice", "matching"].includes(qFormData.answerType) && qFormData.options.filter(item => item === "").length) {
                toast.error("Please add content to options");
                return false;
            }

            if (qFormData.answerType === "choice" && qFormData.correctOptions.length === 0) {
                toast.error("Please select correct options by ticking to the square icon");
                return false;
            }

            if (qFormData.answerType === "matching" && qFormData.options2.length === 0) {
                toast.error("Please add content to second editor");
                return false;
            }

            if (qFormData.answerType === "matching" && qFormData.options.length !== qFormData.options2.length) {
                toast.error("Please add content to all editors");
                return false;
            }

            if (qFormData.answerType === "ordering" && qFormData.options.filter(item => item === '').length > 0) {
                toast.error("Please add content to options");
                return false;
            }

            if (qFormData.answerType === "text" && qFormData.questionType.label === "Fill-in-the-blanks" && qFormData.correctText.filter(item => item === "").length) {
                toast.error("Please add content to all inputs");
                return false;
            }

            if (qFormData.answerType === "text" && qFormData.questionType.label === "Short answer" && qFormData.correctText.length != qFormData.shortAnswerBlanks) {
                toast.error("Please add content to all inputs");
                return false;
            }

            if (qFormData.setMaxChar && !qFormData.maxInputChar) {
                toast.error("Please add the number of character limit for each answer");
                return false;
            }
        }

        if (step === 4 && qFormData.questionType === 'openEnded' && qFormData.openEndedType === 'Self-marked' &&
            !qFormData.explanation) {
                toast.error("Please add explanation for student to mark the question by themself");
                return false;
            }
        return true;
    }

    return (
        <>
        {
            loading &&
            <Loader />
        }
        {percentCompleted < 100 && percentCompleted > 0 && !loading &&
            <Percentage percentCompleted={percentCompleted} />
        }
        {
            (percentCompleted === 0 || percentCompleted === 100) && !loading &&
            <div className="flex">
                <div className="modalLeft">
                    <h2>{isClone ? "New" : "Edit"} Question</h2>
                    <Steps list={newQuestionSteps} currentStep={step} setCurrentStep={setStep} condition={checkCondition}/>
                </div>
                <div className="modalContent">
                    {
                        step === 1 &&
                        <QuestionStep1 
                            formData={qFormData} 
                            setFormData={setQFormData} 
                            lib={lib} 
                            filtersData={filtersData} 
                            fromWorkSheet={fromWorkSheet}
                        />
                    }
                    {
                        step === 2 &&
                        <QuestionStep2 formData={qFormData} setFormData={setQFormData} lib={lib} percentCompleted={percentCompleted} setPercentCompleted={setPercentCompleted}/>
                    }
                    {
                        step === 3 &&
                        <QuestionStep3 formData={qFormData} setFormData={setQFormData} lib={lib} filtersData={filtersData}/>
                    }
                    {
                        step === 4 &&
                        <QuestionStep4 formData={qFormData} setFormData={setQFormData} lib={lib} />
                    }
                    {
                        step === 5 &&
                        <QuestionReview formData={qFormData} lib={lib} />
                    }
                    <div className="buttons rightAlign">
                        {
                            step === 5 &&
                            // <button className='btn' onClick={onSave} onKeyPress={handleKeyPressSave} 
                            // disabled={editQData ? isEqual(omit(qFormData, ['id', 'tempQuestionId', 'questionId', 'name']), 
                            //     omit(editQData, ['id', 'tempQuestionId', 'questionId', 'name'])) : false}>Save</button>
                            <button className='btn' onClick={onSave} onKeyDown={handleKeyPressSave}>Save</button>    
                        }
                        {
                            step < 5 && 
                            <button className='btn' onClick={onNext} onKeyPress={handleKeyPress}>Next</button>
                        }
                        {
                            step > 1 &&
                            <button className='btn' onClick={onPrev}>Previous</button>
                        }
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default NewQuestion;