import styles from './styles.module.scss';

const WhatBring = ({data}) => {
    return (
        <div className={styles.whatBring}>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            <ul>
                {
                    data && data.list && data.list.length > 0 &&
                    data.list.map((item, index) => 
                        <li key={`whatBring${index}`}>
                            <div className={styles.title}>
                                <span>{index+1}</span>
                                <strong>{item.title}</strong>
                                {
                                    item.imageUrl &&
                                    <div className={styles.image}>
                                        <img src={item.imageUrl} alt='icon' />
                                    </div>
                                }
                            </div>
                            <div className={styles.content}>{item.content}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}

export default WhatBring;