import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import styles from './styles.module.scss';
import {getError} from '../../common/utils';
import {Loader} from '../../components/loading';
import {uniqueId} from 'lodash';
import {MdAccountCircle, MdErrorOutline} from 'react-icons/md';
import moment from 'moment';
import classNames from 'classnames';
import {useStore} from '../../store/useStore';
import {Logout} from '../../services/auth.service';

const AlertItem = ({history, item, handleSuccess, setUser}) => {

    const handleRequest = (type, action) => {
        const data = type === 'connection' ? {userName : item.userName, action} : {action, alertId: item.alertId};
        const url = type === 'connection' ? API_URL.USER_CONNECTIONS_REQUEST_ACTION_URL : API_URL.POST_LIBRARY_PERMISSION_REQUEST_URL;

        axios.post(url,data,{
            headers: authHeader()
        })
        .then(response => {
            if (action === "approve request") {
                toast.success(`Request has been accepted!`);
            }
            if (action === "cancel request") {
                toast.success(`Request has been canceled!`);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }      
        });

        handleSuccess(item.alertId);
    }

    const handlePayment = (action) => {
        action === "view" && history.push(`/cart?showModal=1`);
        handleSuccess(item.alertId);
    }

    const handleView = (action) => {
        if (action === "view") {
            if(item.activity.action !== "paidCart") {
                history.push(`/products/classes/list/${item.activity.id}`);
            }
    
            if(item.activity.action === "paidCart") {
                history.push(`/user/classes`);
            }
        }

        handleSuccess(item.alertId);
    }

    const handleClassView = (action) => {
        if(action === "view") {
            history.push(`/user/classes`);
        }

        handleSuccess(item.alertId);
    }

    return (
        <div className={styles.item}>
            <div className={styles.image}>
                {
                    !item.imageUrl && item.alertType !== "notification" && item.alertType !== "class-notification" &&
                    <MdAccountCircle className={styles.userLink} size="70"/>
                }

                {
                    (item.alertType === "notification" || item.alertType === "class-notification" ) &&
                    <MdErrorOutline className={classNames(styles.noti,{[styles.important]: item.alertLevel === "important"})} size="70"/>
                }

                {
                    item.imageUrl &&
                    <img src={item.imageUrl} alt="avatar" />
                }
            </div>
            
            <div>
            {item.alertType === "connection-request" &&
                <>
                    <div className={styles.message}>
                        <strong>{item.fullName}</strong>
                        has requested a connection with you as their <strong>{item.relationship}</strong>
                    </div>
                    <div className={styles.buttons}>
                        <button className="btn" onClick={() => handleRequest('connection', 'approve request')}>Accept</button>
                        <button onClick={() => handleRequest('connection', 'cancel request')}>Deny</button>
                    </div>
                </>
            }
            {item.alertType === "payment-request" &&
                <>
                    <div className={styles.message}>
                        <strong>{item.fullName}</strong>
                        has requested payment for their cart items
                    </div>
                    <div className={styles.buttons}>
                        <button className="btn" onClick={() => handlePayment('view')}>View</button>
                        <button onClick={() => handlePayment('close')}>Ignore</button>
                    </div>
                </>
            }
            {item.alertType === "activity" &&
                <>
                    <div className={styles.message}>
                        <strong>{item.fullName}</strong>
                        {item.activity.action === "paidCart" ? "has paid your cart" : 
                        item.activity.action === "createdClass" ? "has created a class" :
                        "has joined a class"
                        }
                    </div>
                    <div className={styles.buttons}>
                        <button className="btn" onClick={() => handleView('view')}>View</button>
                        <button onClick={() => handleView('close')}>Ignore</button>
                    </div>
                </>
            }

            {
                item.alertType.includes("notification") &&
                <>
                    <div className={styles.message} dangerouslySetInnerHTML={{__html: item.message}}/>
                    <div className={styles.buttons}>
                        {
                            item.alertType === "class-notification" &&
                            <button className="btn" onClick={() => handleClassView('view')}>View</button>
                        }
                        {
                            item.alertType !== "permission-notification" &&
                            <button className={item.alertType === "notification" ? "btn" : ""} onClick={() => handleClassView('close')}>Remove</button>
                        }
                        {
                            item.alertType === "permission-notification" &&
                            <>
                                <button className="btn" onClick={() => handleRequest('permission', 'approve request')}>Accept</button>
                                <button onClick={() => handleRequest('permission', 'cancel request')}>Deny</button>
                            </>
                        }
                    </div>
                </>
            }
            <div className={styles.time}>
                {moment.utc(item.time).startOf('seconds').fromNow()}
            </div>
            </div>
        </div>
    )
}

const AlertsList = ({refresh, isShowAll, setAlertsCount}) => {
    const [alerts, setAlerts] = useState();
    const [loading, setLoading] = useState();
    const {setUser} = useStore();
    const history = useHistory();

    const getList = (condition, alertId) => {
        if (alertId) {
            return axios.post(API_URL.ALERTS_LIST_URL,{alertId: alertId}, {
                headers: authHeader(),
                params: {
                    isShowAll:isShowAll
                }
            })
            .then(response => {
                //update alerts list, remove 1 item
                const index = alerts.findIndex(item => item.alertId = alertId);
                alerts.splice(index, 1);
                setAlerts([...alerts]);
            })
            .catch(error => {
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }
            })
        }

        return axios.get(API_URL.ALERTS_LIST_URL,{
            params: {
                alertId: alertId ? alertId : null,
                isShowAll: isShowAll ? isShowAll : false
            },
            headers: authHeader()
        })
        .then(response => {
            if(response.data.alerts && condition) {
                response.data.alerts && setAlerts(response.data.alerts);
                setLoading(false);
                setAlertsCount && setAlertsCount(0);
           }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
            setLoading(false);
        })
    };

    useEffect(()=>{
        let isCancelled = false;
        if(refresh) {
            getList(!isCancelled);
        }
        setLoading(true);
        return () => {
            isCancelled = true;
          };
    },[refresh]);

    const handleRequestSuccess = (alertId) => {
        getList(true, alertId);
    }

    return (
        <>
        {
            loading && 
            <Loader />
        }
        
        {
            alerts && alerts.length > 0 && !loading &&
            <div className={styles.alerts}>
                {alerts.map(item => (
                    <AlertItem 
                        item={item} 
                        key={uniqueId()} 
                        handleSuccess={handleRequestSuccess}
                        history={history}
                        setUser={setUser}
                    />
                ))}
            </div>  
        }

        {
            (!alerts ||  alerts.length === 0) && !loading &&
            <div className='noResult'>
                No {!isShowAll ? 'new notification' : 'notification'} found.
            </div>
        }
        </> 
    )
}

export default AlertsList;
