import {useEffect, useState} from 'react';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import meeting from '../myClasses/styles/meeting.module.scss';
import {MdFastForward, MdDone, MdClose} from 'react-icons/md';
import QuestionDetail from '../myClasses/components/questionDetails';

const AssignmentDetailsQuestion = ({currentQuestion, setLoading, loading, setUser, history, assignId}) => {
    const [questionData, setQuestionData] = useState();

    useEffect(()=>{
        setLoading(true);
        axios.get(API_URL.GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_QUESTION_URL,{
            headers: authHeader(),
            params: {
                questionId: currentQuestion.questionId,
                assignId
            }
        })
        .then(response => {
            if(response.data) {
                 setQuestionData(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[currentQuestion]);

    return (
        <div>
            {loading && <Loader />}
            {!loading && questionData && 
                <>
                <QuestionDetail
                    item={questionData} 
                    index={currentQuestion.index}
                    isQuestionReport={true}
                />
               </> 
            }
        </div>
    )
}

export default AssignmentDetailsQuestion;