import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import profile from './profile.module.scss';
import styles from '../styles.module.scss';
import Input from '../../../components/input';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';
import {formatDate, parseDate} from 'react-day-picker/moment';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import RichEditor2 from '../../../components/editor/editor2';
 
const MainInfoEdit = ({formData, setFormData}) => {
    const history = useHistory();
    const {user, setUser} = useStore();
    const DateFormat = 'DD-MM-YYYY';

    const [gendersList, setGendersList] = useState();
    const [subjectsList, setSubjectsList] = useState();
    const [educationList, setEducationList] = useState();
    const [gradesList, setGradesList] = useState();

    const schoolOnChange = (e) => setFormData({...formData, school: e.target.value});
    const gradeOnChange = (value) => setFormData({...formData, grade: value});
    const firstNameOnChange = (e) => setFormData({...formData, firstName: e.target.value});
    const lastNameOnChange = (e) => setFormData({...formData, lastName: e.target.value});
    const userNameOnChange = (e) => setFormData({...formData, userName: e.target.value});
    const phoneNumberOnChange = (e) => setFormData({...formData, phoneNumber: e.target.value});
    const emailOnChange = (e) => setFormData({...formData, email: e.target.value});
    const genderOnChange = (value) => setFormData({...formData, gender: value});
    const subjectOnChange = (value) => setFormData({...formData, subject: value});
    const goalOnChange = (e) => setFormData({...formData, goal: e.target.value});
    const selfIntroOnChange = (data) => setFormData({...formData, selfIntro : data});
    const hobbiesOnChange = (e) => setFormData({...formData, hobbies: e.target.value});
    const addressOnChange = (e) => setFormData({...formData, address: e.target.value});
    const educationOnChange = (value) => setFormData({...formData, education: value});
    const experiencesOnChange = (e) => setFormData({...formData, experiences: e.target.value});
    const accomplishmentsOnChange = (e) => setFormData({...formData, accomplishments: e.target.value});
    const lisencesOnChange = (e) => setFormData({...formData, lisencesAndCertificates: e.target.value});
    const dateOfBirthOnChange = (selectedDay) => setFormData({...formData, dateOfBirth: selectedDay});

    useEffect(() => {

        axios.get(API_URL.USER_PROFILE_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data.data) {
                setFormData(response.data.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });

        axios.get(API_URL.GET_USER_PROFILE_LIST_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data) {
                 response.data.genders && setGendersList(response.data.genders);
                 response.data.grades && setGradesList(response.data.grades);
                 response.data.subjects && setSubjectsList(response.data.subjects);
                 response.data.education && setEducationList(response.data.education);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    },[]);

    const onUpdate = () => {
        if(!formData.firstName) {
            toast.error('First Name is empty');
            return;
        }

        if(!formData.lastName) {
            toast.error('Last Name is empty');
            return;
        }

        const url = API_URL.USER_PROFILE_UPDATE_URL;
        axios.post(url,formData,{
            headers: authHeader()
        })
        .then(response => {
            toast.success('Your information is updated!');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <>
        <div className={profile.form}>
            <div className={profile.formCol}>
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={firstNameOnChange}
                    value={formData.firstName}
                    label="First Name"
                />
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={lastNameOnChange}
                    value={formData.lastName}
                    label="Last Name"
                />
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={userNameOnChange}
                    value={formData.userName}
                    disabled={true}
                    label="User Name"
                />
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={phoneNumberOnChange}
                    value={formData.phoneNumber}
                    disabled={true}
                    label="Phone Number"
                />
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={emailOnChange}
                    value={formData.email}
                    disabled={true}
                    label="Email"
                /> 
                <div className={profile.dateOfBirth}>
                    <label>Date Of Birth</label>
                    <DayPickerInput 
                        placeholder="" 
                        format={DateFormat} 
                        value={formData.dateOfBirth}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        onDayChange={dateOfBirthOnChange}
                    />
                </div>
                
                <Input
                    className={styles.select}
                    type="select"
                    options={gendersList}
                    placeholder=''
                    onChange={genderOnChange}
                    value={formData.gender}
                    label="Gender"
                />
                
            </div>
            <div className={profile.formCol}>  
                
                { user.userRole !== "Parents" && 
                    <>
                        <Input
                            className={styles.select}
                            type="select"
                            options={gradesList}
                            placeholder=''
                            onChange={gradeOnChange}
                            value={formData.grade}
                            label="Level/Grade"
                            // isMulti={true}
                        />
                        <Input 
                            type="text" 
                            placeholder="" 
                            onChange={schoolOnChange}
                            value={formData.school}
                            label="School"
                        />
                    </>
                }
                    <Input 
                        type="text" 
                        placeholder="" 
                        onChange={addressOnChange}
                        value={formData.address}
                        label="Address"
                    />  
                    <Input 
                        type="text" 
                        placeholder="" 
                        onChange={hobbiesOnChange}
                        value={formData.hobbies}
                        label="Hobbies"
                    />        
                
                { user.userRole !== "Teachers" &&
                    <Input 
                        type="textarea" 
                        placeholder="" 
                        onChange={goalOnChange}
                        value={formData.goal}
                        label="Your Goal"
                    />
                }
                
            </div>
        </div>    
            { user.userRole === "Teachers" &&
                <div className={profile.moreInfo}>
                    <RichEditor2
                        label="Self Intro"
                        editorData={formData.selfIntro}
                        onChange={selfIntroOnChange}
                    />
                    
                    <div className={profile.flex}>
                        <div>
                            <Input 
                                className={styles.select}
                                type="select"
                                options={subjectsList} 
                                placeholder="" 
                                onChange={subjectOnChange}
                                value={formData.subject}
                                label="Subject"
                                isMulti={true}
                            />
                            <Input 
                                type="select" 
                                className={styles.select}
                                options={educationList}
                                placeholder="" 
                                onChange={educationOnChange}
                                value={formData.education}
                                label="Education"
                            />
                            <Input 
                                type="textarea" 
                                placeholder="" 
                                onChange={lisencesOnChange}
                                value={formData.lisencesAndCertificates}
                                label="Lisences And Certificates"
                            />
                        </div>
                        <div>
                            <Input 
                                type="textarea" 
                                placeholder="" 
                                onChange={accomplishmentsOnChange}
                                value={formData.accomplishments}
                                label="Accomplishments"
                            />
                            <Input 
                                type="textarea" 
                                placeholder="" 
                                onChange={experiencesOnChange}
                                value={formData.experiences}
                                label="Experiences"
                            />
                        </div>
                        
                    </div>
                </div>
            }
        <div className={profile.buttons}>
            <button className="btn" onClick={onUpdate}>Update</button>
        </div>
        </>
    )
}

export default MainInfoEdit;