import { useEffect, useState } from "react";
import Input from '../../../components/input';
import { toast } from 'react-toastify';

const UploadFiles = ({lib, type, files, setFiles}) => {
    const [fileNames, setFileNames] = useState([]);

    const filesOnChange = (e) => {
        const files = e.target.files;
        let totalSize = 0;
        if (files.length > 0) {
            const arr = [];
            for (let i = 0; i < files.length; i++) {
                arr.push(files[i].name);
                totalSize = totalSize + files[i].size;
            }
            if(totalSize/1000000 > 100) {
                toast.error('Allow maximum 100 Mb in total, please try again!');
                setFiles && setFiles({});
                setFileNames([]);
            } else {
                setFileNames(arr);
                setFiles && setFiles(files);
            }
        }
    }

    return (
        <Input 
            type="file" 
            placeholder=""
            onChange={filesOnChange}
            files={files}
            label={type === "file" ? "Upload files (up to 100 Mb in total)" : "Upload WORD or PDF (up to 100 Mb)"}
            isMulti={type === "file" ? true : false}
            fileNames={fileNames}
            className={lib.inputFile}
            labelLink="https://www.google.com"
            labelLinkName={(type === "worksheet" || type === "questions list") && "(Download Template)"}
            accept={type !== "file" ? ".pdf,.docx,.doc" : "*"}
        />
    )
}

export default UploadFiles;