// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".calendar_calendar__11xtg {\n  width: calc(100% - 50px);\n  font-size: 14px;\n  max-width: 1400px;\n  margin: 30px auto;\n}\n\n@media (max-width: 768px) {\n  .calendar_calendar__11xtg {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://src/views/user/calendar/calendar.module.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEA;EACI;IACI,WAAA;EACN;AACF","sourcesContent":[".calendar {\n    width: calc(100% - 50px);\n    font-size: 14px;\n    max-width: 1400px;\n    margin: 30px auto;\n}\n\n@media (max-width: 768px) {\n    .calendar {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "calendar_calendar__11xtg"
};
export default ___CSS_LOADER_EXPORT___;
