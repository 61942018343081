import React, {useState, useMemo} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from 'react-day-picker/moment';
import {MdClear} from 'react-icons/md/index';
import './picker.css';
import styles from './picker.module.scss';
import YearMonthForm from './YearMonthFrom';

const InputDayPicker = React.forwardRef(({value, placeholder, onChange, dayPickerProps, disabled, disableFuture = true}, ref) => {
  const [month, setMonth] = useState();
  const DateFormat = 'DD-MM-YYYY';

  const defaultOpts = useMemo(
    () => ({
      month: month,
      showOutsideDays: true,
      numberOfMonths: 1,
      captionElement: ({date, localeUtils}) => (
        <YearMonthForm date={date} localeUtils={localeUtils} onChange={setMonth} disableFuture={disableFuture} />
      ),
    }),
    [month],
  );

  const onClear = () => onChange && onChange(undefined);

  return (
    <div className={styles.wrapper}>
      <DayPickerInput
        ref={ref}
        value={value}
        format={DateFormat}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{...defaultOpts ,...dayPickerProps}}
        onDayChange={onChange}
        inputProps={
          {
            disabled: disabled,
            readOnly: true,
          }
        }
      />
      <MdClear className={styles.btnClear} onClick={onClear} />
    </div>
  );
});

export default InputDayPicker;
