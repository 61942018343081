import RichEditor2 from "../../../components/editor/editor2";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const NewSharing = ({connection, modalType, setShowModal, showModal, curItem, list, setList, newSharing, setNewSharing}) => {
    const {user, setUser} = useStore();
    const history = useHistory();

    const contentOnChange = (value) => {setNewSharing({...newSharing, content: value})};

    const onSave = () => {
        if (!newSharing.content) {
            toast.error('Content is empty!');
            return;
        }

        return axios.post(API_URL.USER_SHARING_ACTION_URL, newSharing,{
            headers: authHeader(),
            params: {
                id: curItem && curItem.id// just have value for edit case
            }
        })
        .then(response => 
            {               
                const idList = list && list.map(item => item.id);
                const index = idList.indexOf(curItem && curItem.id);

                if (curItem && curItem.id) {
                    const newItem = {...curItem, 
                        privacy: newSharing.privacy,
                        content: newSharing.content,
                        bgCode: newSharing.bgCode}
                    list.splice(index, 1, newItem);
                    setList([...list]);
                } else {
                    const newItem = {
                        id: "tempId",
                        fullName: user.full_name,
                        userName: user.userName,
                        userImageUrl: user.userImageUrl,
                        time: new Date(),
                        bgCode: newSharing.bgCode,
                        content: newSharing.content,
                        privacy: newSharing.privacy,
                        totalReaction: 0,
                        reactionDetails: {
                            like: 0,
                            love: 0,
                            haha: 0,
                            surprise: 0,
                            cry: 0,
                            angry: 0
                            },
                        yourReaction: null,
                        totalComment: 0
                    }
                    list.unshift(newItem);
                    setList([...list]);
                }

                setShowModal(false);
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={connection.newSharing}>
            <h2>New Sharing</h2>
            <div className="privacy">
                <label>Who can view</label>
                <ul>
                    <li className={newSharing && newSharing.privacy === "public" ? "active" : ""}
                        onClick={() => setNewSharing({...newSharing, privacy: "public"})}>Public</li>
                    <li className={newSharing && newSharing.privacy === "connections" ? "active" : ""}
                        onClick={() => setNewSharing({...newSharing, privacy: "connections"})}>My Connections</li>
                    <li className={newSharing && newSharing.privacy === "onlyMe" ? "active" : ""}
                        onClick={() => setNewSharing({...newSharing, privacy: "onlyMe"})}>Only Me</li>    
                </ul>
            </div>
            <RichEditor2 
                label="Content"
                editorData={newSharing && newSharing.content}
                onChange={contentOnChange}
            />
            <div className={connection.userBg}>
                <label>User Background</label>
                <ul>
                    <li className={`${connection.color1} ${newSharing && newSharing.bgCode === '#b9b9b9' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#b9b9b9"})}></li>
                    <li className={`${connection.color2} ${newSharing && newSharing.bgCode === '#ffca71' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#ffca71"})}></li>
                    <li className={`${connection.color3} ${newSharing && newSharing.bgCode === '#ff9c9c' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#ff9c9c"})}></li>
                    <li className={`${connection.color4} ${newSharing && newSharing.bgCode === '#abe389' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#abe389"})}></li>
                    <li className={`${connection.color5} ${newSharing && newSharing.bgCode === '#83dceb' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#83dceb"})}></li>
                    <li className={`${connection.color6} ${newSharing && newSharing.bgCode === '#c999eb' ? connection.active : ''}`} onClick={() => setNewSharing({...newSharing, bgCode: "#c999eb"})}></li>
                </ul>
            </div>
            <div className="buttons rightAlign">
                <button onClick={onSave} className="btn">Save</button>
            </div>
        </div>
    )
}

export default NewSharing;