import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import { MdClose, MdEdit, MdReportProblem } from "react-icons/md";
import { useStore } from "../../../store/useStore";

const SharingOptions = ({optionType, item, setShowModal, setModalType, setCurId, list, setList, setShowOptions, setCurItem, setEditComment, commentRef}) => {
    const {user, setUser} = useStore();
    const history = useHistory();

    const reportOnClick = () => {
        setShowModal(true);
        setModalType('report');
        setCurId(item.id);
    }

    const sharingAction = (action) => {
        return axios.post(API_URL.USER_SHARING_ACTION_URL,{
            action,
            id: item.id,
            type: optionType
        },{
            headers: authHeader()
        })
        .then(response => 
            {
                setShowOptions(false);
                const idList = list && list.map(item => item.id);
                const index = idList && idList.indexOf(item.id);

                if (action === "delete" && index !== undefined) {
                    list.splice(index, 1);
                    setList([...list]);
                }

                if (action === "edit" && index !== undefined && optionType === "sharing-item") {
                    setShowModal(true);
                    setModalType('edit');
                    setCurItem(item);
                }

                if (action === "edit" && index !== undefined && optionType === "comment-item" && setEditComment) {
                    setCurItem(item);
                    setEditComment(item.content + "&nbsp;");
                    commentRef && commentRef.current.focus();
                }
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            setShowOptions(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className="options">
            {
                item.userName !== user.userName &&
                <MdReportProblem title="Report" onClick={reportOnClick}/>
            }
            {
                item.userName === user.userName &&
                <>
                    <MdEdit title="Edit" onClick={() => sharingAction('edit')}/>
                    <MdClose title="Delete" onClick={() => sharingAction('delete')}/>
                </>
            }
        </div>
    )
}

export default SharingOptions;