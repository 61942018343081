import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import likeIcon from '../../../images/reaction-like.svg';
import loveIcon from '../../../images/reaction-love.svg';
import hahaIcon from '../../../images/reaction-haha.svg';
import surpriseIcon from '../../../images/reaction-surprise.svg';
import cryIcon from '../../../images/reaction-cry.svg';
import angryIcon from '../../../images/reaction-angry.svg';

const SharingLikeAction = ({connection, setShowLikeList, id, list, setList, yourReaction}) => {
    const {setUser} = useStore();
    const history = useHistory();

    const onReact = (action) => {
        return axios.post(API_URL.USER_SHARING_ACTION_URL,{action},{
            headers: authHeader()
        })
        .then(response => 
            {
                setShowLikeList(false);
                const listId = list.map(item => item.id);
                const index = listId.indexOf(id);

                //add reaction
                if(index > -1 && !action.includes('remove')) {
                    const arr = Array.isArray(list[index].yourReaction) ? list[index].yourReaction : [];
                    arr.push(action);
                    list[index].totalReaction += 1; 
                    list[index].reactionDetails[action] +=1; 
                    list[index].yourReaction = arr;
                    setList([...list]);
                }

                //remove reaction
                if(index > -1 && action.includes('remove')) {
                    const reaction =  action.substring(7);
                    const i =  yourReaction.indexOf(reaction);
                    list[index].totalReaction -= 1;
                    list[index].yourReaction.splice(i, 1);
                    list[index].reactionDetails[reaction] -=1; 
                    setList([...list]);
                }
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            setShowLikeList(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={connection.sharingLike}>
            <img 
                onClick={() => yourReaction && yourReaction && yourReaction.includes('like') ? onReact('remove-like') : onReact('like')} 
                src={likeIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('like') ? 'remove like' : 'like'}
                width={40} 
                className={yourReaction && yourReaction.includes('like') ? connection.active : undefined}
                alt="like" 
            />
            <img 
                onClick={() => yourReaction && yourReaction.includes('love') ? onReact('remove-love') : onReact('love')} 
                src={loveIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('love') ? 'remove love' : 'love'} 
                width={40} 
                className={yourReaction && yourReaction.includes('love') ? connection.active : undefined}
                alt="love" />
            <img 
                onClick={() => yourReaction && yourReaction.includes('haha') ? onReact('remove-haha') : onReact('haha')} 
                src={hahaIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('haha') ? 'remove haha' : 'haha'} 
                width={45} 
                className={yourReaction && yourReaction.includes('haha') ? connection.active : undefined}
                alt="haha" />
            <img 
                onClick={() => yourReaction && yourReaction.includes('surprise') ? onReact('remove-surprise') : onReact('surprise')} 
                src={surpriseIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('surprise') ? 'remove surprise' : 'surprise'}
                width={45} 
                className={yourReaction && yourReaction.includes('surprise') ? connection.active : undefined}
                alt="surprise" />
            <img 
                onClick={() => yourReaction && yourReaction.includes('cry') ? onReact('remove-cry') : onReact('cry')} 
                src={cryIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('cry') ? 'remove cry' : 'cry'}
                width={43} 
                className={yourReaction && yourReaction.includes('cry') ? connection.active : undefined}
                alt="cry" />
            <img 
                onClick={() => yourReaction && yourReaction.includes('angry') ? onReact('remove-angry') : onReact('angry')} 
                src={angryIcon} 
                title={yourReaction && yourReaction && yourReaction.includes('angry') ? 'remove angry' : 'angry'}
                width={40} 
                className={yourReaction && yourReaction.includes('angry') ? connection.active : undefined}
                alt="angry" />
        </div>
    )
}

export default SharingLikeAction;
