import {Fragment, useState} from 'react';
import dashboard from './dashboard.module.scss';
import Input from '../../../components/input';
import DateRangePicker from '../../../components/day-range';
import API_URL from '../../../common/urls';
// import DayRange from '../../../components/day-range';

const Filter = ({ grades, dateFrom, dateTo, onGradeChange, subject, onSubjectChange, setDateFrom, setDateTo, 
    filterGrade, className, onClassNameChange}) => {
    const [grade, setGrade] = useState(grades[0]);

    const onDateRangeChange = (from, to) => {
        setDateFrom && setDateFrom(from);
        setDateTo && setDateTo(to);
      };

    const gradeOnChange = type => {
        setGrade(type);
        onGradeChange && onGradeChange(type);
    }

    return (
        <div className={dashboard.filter}>
            <div className={dashboard.flex}>
                <div>
                    <label>Grade</label>
                    <Input
                        className={dashboard.select}
                        type="select"
                        options={grades}
                        placeholder='All'
                        onChange={gradeOnChange}
                        value={filterGrade || undefined}
                    />
                </div>
                <div>
                    {
                        grade.subject && grade.subject.length > 0 && 
                        <Fragment>
                            <label>Subject</label>
                            <Input
                                className={dashboard.select}
                                type="select"
                                options={grade.subject}
                                placeholder='All'
                                onChange={onSubjectChange}
                            />
                        </Fragment>
                    }
                </div>
                <div>
                    <DateRangePicker
                        fromDate={dateFrom}
                        toDate={dateTo}
                        onChange={onDateRangeChange}
                    />
                </div>
            </div>
            <div className={dashboard.classNameFilter}>
                <Input
                    className={dashboard.select}
                    type="async-select"
                    url={`${API_URL.GET_USER_DASHBOARD_CLASS_NAME_FILTER_URL}?grade=${grade && grade.value}&subject=${subject && subject.value}`}
                    placeholder=''
                    onChange={onClassNameChange}
                    label="Class"
                    value={className}
                />
            </div>
        </div>
    )
}

export default Filter;