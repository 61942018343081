import Flag from 'react-world-flags';
import image2 from './themes/image2.png';
import image3 from './themes/image3.png';
import image4 from './themes/image4.png';
import image5 from './themes/image5.png';
import image6 from './themes/image6.png';
import image7 from './themes/image7.png';
import image8 from './themes/image8.png';
import image9 from './themes/image9.png';
import creditIcon1 from '../images/medal.svg';
import icon1 from '../images/icon-difficulty1.svg';
import icon2 from '../images/icon-difficulty2.svg';
import icon3 from '../images/icon-difficulty3.svg';
import icon4 from '../images/icon-difficulty4.svg';
import icon5 from '../images/icon-difficulty5.svg';
import likeIcon from '../images/reaction-like.svg';
import loveIcon from '../images/reaction-love.svg';
import hahaIcon from '../images/reaction-haha.svg';
import surpriseIcon from '../images/reaction-surprise.svg';
import cryIcon from '../images/reaction-cry.svg';
import angryIcon from '../images/reaction-angry.svg';
import axios from 'axios';
import creditImage from '../images/medal.svg';

export const getError = (error) =>{
        let resMessage = error.message || error.toString();
            if(error.response && error.response.data ) {
                if(error.response.data.message) resMessage = error.response.data.message;
                if(error.response.data.detail) resMessage = error.response.data.detail;
            }
        return resMessage;    
}

export const OPTIONS_TYPE = {
    RELATIONSHIP: [
        {value: 'Parent', label: 'Parent'},
        {value: 'Chilren', label: 'Children'},
        {value: 'Sibling', label: 'Sibling'},
        {value: 'Teacher', label: 'Teacher'},
        {value: 'Student', label: 'Student'},
        {value: 'Friend', label: 'Friend'}
    ],
    WEEKDAYS:[
        {value: 'Monday', label: 'Monday'},
        {value: 'Tuesday', label: 'Tuesday'},
        {value: 'Wednesday', label: 'Wednesday'},
        {value: 'Thursday', label: 'Thursday'},
        {value: 'Friday', label: 'Friday'},
        {value: 'Saturday', label: 'Saturday'},
        {value: 'Sunday', label: 'Sunday'},
    ],
    REPEAT_OPTIONS: [
        {value: 'No Repeat', label: 'No Repeat'},
        {value: 'Weekly', label: 'Weekly'},
        {value: '2-weeks Repeat', label: '2-weeks Repeat'},
        {value: 'Monthly', label: 'Monthly'},
    ],
    QUESTION_DIFFICULTY_LEVEL: [
        {value: 1, label: 1},
        {value: 2, label: 2},
        {value: 3, label: 3},
        {value: 4, label: 4},
        {value: 5, label: 5},
    ],
    SORT_OPTIONS: [
        {value: 'latest', label: 'Latest'},
        {value: 'oldest', label: 'Oldest'},
        {value: 'az', label: 'A-Z'},
        {value: 'za', label: 'Z-A'},
    ],
    ADD_FILE_TYPE: [
        {
            label: "Add Url",
            value: "fileUrl"
        },
        {
            label: "Upload New File",
            value: "upload"
        }
    ],
    ANSWER_TYPE: [
        {
            label: "Text Box",
            value: "text"
        },
        {
            label: "Text choices",
            value: "textChoice"
        },
        {
            label: "Images choices",
            value: "imageChoice"
        },
        {
            label: "Arrange words",
            value: "arrangeWords"
        },
        {
            label: "Word-word match",
            value: "wordMatch"
        },
        {
            label: "Word-image match",
            value: "wordImageMatch"
        },
        {
            label: "Open-Ended",
            value: "openEnded"
        },
        {
            label: "Recording",
            value: "recording"
        }
    ],
    ITEM_PER_PAGE: [
        {value: '10', label: '10'},
        {value: '20', label: '20'},
        {value: '40', label: '40'},
        {value: '60', label: '60'},
        {value: '80', label: '80'},
        {value: '100', label: '100'},
    ],
    QUESTIONS_TYPE: [
        {value: 'choice', label: 'True-False', explanation: 'Include 2 options (True & False) & students select 1 correct option.'},
        {value: 'choice', label: 'Yes-No', explanation: 'Include 2 options (Yes & No) & students select 1 correct option.'},
        {value: 'choice', label: 'Choices', explanation: 'Include multiple options under text or image format from which students can select 1 or more correct options.'},
        {value: 'text', label: 'Fill-in-the-blanks', explanation: 'Students insert appropriate words or numbers in the provided blanks.'},
        {value: 'text', label: 'Short answer', explanation: 'Students give short answers for a question.'},
        {value: 'matching', label: 'Matching', explanation: 'Students choose to match correct options.'},
        {value: 'ordering', label: 'Ordering', explanation: 'Students arrange all the options for a right order.'},
        {value: 'openEnded', label: 'Open-ended', explanation: 'Students give free-form answers.'},
        {value: 'recording', label: 'Speaking', explanation: 'Students submit their speech as an answer'},
    ]
  };

export const topMenus = [
    {
        label: "Home",
        url: "/#top",
    },
    {
        label: "Products",
        url: "#products"
    },
    {
        label: "Pricing",
        url: "#pricing"
    },
    {
        label: "Blog",
        url: "#blog"
    },
    {
        label: "Contact Us",
        url: "#contactUs"
    }
    // {
    //     label: "Live Classes",
    //     url: "/products/classes",
    //     type: "classes"
    // },
    // {
    //     label: "Videos",
    //     url: "/products/videos",
    //     type: "videos"
    // },
    // {
    //     label: "Worksheets",
    //     url: "/products/worksheets",
    //     type: "worksheets"
    // },
    // {
    //     label: "Searches",
    //     url: "/products/searches",
    //     type: "searches"
    // },
    // {
    //     label: "Blog",
    //     url: "/blogs"
    // },
    // {
    //     label: "About Us",
    //     url: "/about-us"
    // },
    // {
    //     label: "Contact Us",
    //     url: "/contact-us"
    // }
]
  
export const getPhoneCode = (code) => {
    let phoneCode = '';  
    switch(code) {
        case "sg": 
            phoneCode = '+65';
            break;

        case "vn": 
            phoneCode = '+84';
            break;
            
        case "cn": 
            phoneCode = '+86';
            break;
            
        case "au": 
            phoneCode = '+61';
            break;

        case "id": 
            phoneCode = '+62';
            break;

        case "in": 
            phoneCode = '+91';
            break;

        case "hk": 
            phoneCode = '+852';
            break;

        case "mo": 
            phoneCode = '+853';
            break;

        case "tw": 
            phoneCode = '+886';
            break;

        case "my": 
            phoneCode = '+60';
            break;

         case "th": 
            phoneCode = '+66';
            break;

        case "kh": 
            phoneCode = '+63';
            break;

        case "ph": 
            phoneCode = '+855';
            break;

        case "mm": 
            phoneCode = '+95';
            break;
    };

    return phoneCode;
}

export const CountryWithFlag = ({code, phoneCode, country}) => {
    return (
      <>
      <Flag code={code} width="20"/> {phoneCode}
      </>
    )
  }

export const questionColor = (type) => {
    let color = "#ff7f00";
    switch(type) {
        case "text":
            color = "#fdb84d";
            break;
        case "ordering":
            color = "#1393b9";
            break;
        case "matching":
            color = "#ca3d2f";
            break;
        case "choice":
            color = "#5d4ed9";
            break;
        case "openEnded":
            color = "#41c100";
            break;
        case "recording":
            color = "#fd4dd7";
            break;
    }
    return color;
}

export const flagOptions = [
    {value: '+65', label: <CountryWithFlag code="sg" phoneCode="+65" country="Singapore" />},
    {value: '+84', label: <CountryWithFlag code="vn" phoneCode="+84" />},
    {value: '+86', label: <CountryWithFlag code="cn" phoneCode="+86" />},
    {value: '+61', label: <CountryWithFlag code="au" phoneCode="+61" />},
    {value: '+1', label: <CountryWithFlag code="us" phoneCode="+1" />},
    {value: '+852', label: <CountryWithFlag code="hk" phoneCode="+852" />},
    {value: '+62', label: <CountryWithFlag code="id" phoneCode="+62" />},
    {value: '+91', label: <CountryWithFlag code="in" phoneCode="+91" />},
    {value: '+853', label: <CountryWithFlag code="mo" phoneCode="+853" />},
    {value: '+60', label: <CountryWithFlag code="my" phoneCode="+60" />},
    {value: '+886', label: <CountryWithFlag code="tw" phoneCode="+886" />},
    {value: '+66', label: <CountryWithFlag code="th" phoneCode="+66" />},
    {value: '+63', label: <CountryWithFlag code="ph" phoneCode="+63" />},
    {value: '+855', label: <CountryWithFlag code="kh" phoneCode="+855" />},
    {value: '+95', label: <CountryWithFlag code="mm" phoneCode="+95" />}
];

export const handleKeyPress = (event, keyName, callback) => {
    if(event.key === keyName){
        callback();
    }
}

export const newWorksheetSteps = [
    {
        id: 1,
        name: "General"
    },
    {
        id: 2,
        name: "Method"
    },
    {
        id: 3,
        name: "Questions"
    },
    {
        id: 4,
        name: "Preview"
    }
];

export const newQuestionSteps = [
    {
        id: 1,
        name: "General"
    },
    {
        id: 2,
        name: "Content"
    },
    {
        id: 3,
        name: "Types"
    },
    {
        id: 4,
        name: "Explanation"
    },
    {
        id: 5,
        name: "Preview"
    }
];

export const worksheetThemes = [
    {
        themeId: "th1",
        name: "Random Colors",
        thumnailImage: '',
        isAvailable: true
    },
    {
        themeId: "th2",
        name: "Random Gradients",
        thumnailImage: image2,
        isAvailable: true
    },
    {
        themeId: "th3",
        name: "Animated Gradients",
        thumnailImage: image3,
        isAvailable: true
    },
    {
        themeId: "th4",
        name: "Random Pattern 1",
        thumnailImage: image4,
        isAvailable: true
    },
    {
        themeId: "th5",
        name: "Random Animal 1",
        thumnailImage: image5,
        isAvailable: true
    },
    {
        themeId: "th6",
        name: "Random Animal 2",
        thumnailImage: image6,
        colorsSet:[],
        isAvailable: true
    },
    {
        themeId: "th7",
        name: "Students 1",
        thumnailImage: image7,
        isAvailable: true
    },
    {
        themeId: "th8",
        name: "Farm 1",
        thumnailImage: image8,
        isAvailable: true
    },
    {
        themeId: "th9",
        name: "Frame 1",
        thumnailImage: image9,
        isAvailable: true
    }
]

export const shuffle = (array) => {
    const newArr = [...array];
    for (var i = newArr.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * i);
        var temp = newArr[i];
        newArr[i] = newArr[j];
        newArr[j] = temp;
    }
    return newArr;
}

export const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

export const creditIcon = (credit, height) => {
    return <span>{credit && credit.toLocaleString()} <img src={creditIcon1} height={height ? height : 40} /></span>;
}

export const parseRgb = (color) => {
    const r = parseInt(color.substr(1,2), 16);
    const g = parseInt(color.substr(3,2), 16);
    const b = parseInt(color.substr(5,2), 16);
    return {r,g,b};
}

export const hardLevelIcon = (type) => {
    let icon = icon1;
    switch(type) {
        case 1:
            icon = icon1;
            break;
        case 2:
            icon = icon2;
            break;
        case 3:
            icon = icon3;
            break;
        case 4:
            icon = icon4;
            break;
        case 5:
            icon = icon5;
            break;
    }
    return icon;
}

export const reactionIcon = (action, showValue, value, handleClick) => {
    let icon = likeIcon;
    switch(action) {
        case 'like':
            icon = likeIcon;
            break;
        case 'love':
            icon = loveIcon;
            break;
        case 'haha':
            icon = hahaIcon;
            break;
        case 'surprise':
            icon = surpriseIcon;
            break;
        case 'cry':
            icon = cryIcon;
            break;
        case 'angry':
            icon = angryIcon;
            break;
    }

    const iconOnClick = () => {
        handleClick && handleClick(action);
    }
    return  <span onClick={iconOnClick}>
                <img src={icon} height={25} alt={action} /> {showValue && value}
            </span>
}

export const checkTouchDevice = () => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      return true;
    } else {
      return false;
    }
}

export const checkImage = (path) => {
    axios
      .get(path)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

export const renderArray = (string) => {
    const arr = string.split(',');
    let arr2 = [];
    arr.forEach(item => {
        const obj = {
            label: item,
            value: item
        };
        arr2.push(obj);
    });
    return arr2;
}

export const toastCredit = (type, credit) => {
    return `${type}${credit} <img src=${creditImage} height="50" />`
}