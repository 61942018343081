import styles from './styles.module.scss';
import RegisterPage from '../login/register';
import { FaPlay } from 'react-icons/fa';
import { useRef, useState } from 'react';
import Modal from '../../components/modal';

const Banner = ({data}) => {
    const [showVideo, setShowVideo] = useState(false);
    const contentRef = useRef();

    return (
        <div className={styles.banner}>
            <div className={styles.container}>
                <div className={styles.video}>
                    <div className={styles.topText} dangerouslySetInnerHTML={{__html: data.topText}} />
                    {data.title && 
                        <h1>{data.title}</h1>
                    }
                    {
                        data.subTitle &&
                        <div className={styles.info}>{data.subTitle}</div>
                    }
                    {
                        data && data.imageUrl && data.videoUrl &&
                        <div className={styles.image}>
                            <img src={data.imageUrl} alt='banner' />
                            <div onClick={() => setShowVideo(true)}><FaPlay /></div>
                        </div>
                    }
                </div>
                <div className={styles.bannerInfo}>
                    <RegisterPage fromHome={data.registerInfo} />
                </div>
                {data.content &&
                    <div className={styles.bottomText}>{data.content}</div>
                }          
            </div>
            {
                showVideo && data && data.videoUrl &&
                <Modal
                    setShowModal={setShowVideo} 
                    showModal={showVideo}
                    width={800}
                    height={'auto'}
                    contentRef={contentRef}
                    content={
                        <div ref={contentRef} className={styles.videoPlaying}>
                            <video height="auto" width="100%" controls autoPlay controlsList="nodownload">
                                <source src={data.videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    }
                />
            }
        </div>
    )
}

export default Banner;