import { creditIcon, toastCredit } from '../../../common/utils';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { useHistory } from 'react-router-dom';
import { getError } from '../../../common/utils';

const CreditExchangeItem = ({item, connection, setCurTab}) => {
    const history = useHistory();
    const {setUser, user, moreInfo, setMoreInfo, setToastContent} = useStore();

    const buyOnClick = () => {
        callApi('buy');
    }

    const callApi = (action) => {
        axios.post(API_URL.USER_CREDIT_EXCHANGE_ACTION_URL,{action: action, productId: item.id},{
            headers: authHeader(),
        })
        .then(response => {
              setToastContent && setToastContent(toastCredit('-', item.credit));
              moreInfo.credit = moreInfo.credit - item.credit;
              setMoreInfo({...moreInfo});
              setCurTab && setCurTab('tracking');
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    return (
        <li className={item.userName === user.userName ? connection.give : ''}>
            <div className={connection.info}>
                <div className={connection.image}>
                    {
                        item.imageUrl &&
                        <img src={item.imageUrl} />
                    }
                </div>
                <h4>{item.name}</h4>
            </div>
            <div className={connection.action}>
                <div>
                    <h3>{creditIcon(item.credit, 30)}</h3>
                </div>
                <button onClick={buyOnClick} className='btn' disabled={moreInfo.credit < item.credit} title={moreInfo.credit < item.credit ? 'Not enough credit' : 'Buy by your credit'}>Buy</button>
            </div>
        </li>
    )
}

export default CreditExchangeItem;