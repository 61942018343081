import { useState, useEffect, useRef } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import connection from './connection.module.scss';
import { useHistory } from "react-router-dom";
import Modal from "../../../components/modal";
import NewSharing from "./sharingNew";
import SharingItem from "./sharingItem";
import SharingReactionList from "./sharingReactionList";
import Report from "./report";

const Sharing = () => {
    const {setUser} = useStore();
    const history = useHistory();
    const contentRef = useRef();
    const [curTab, setCurTab] = useState('allSharing');
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('new');
    const [curId, setCurId] = useState('');
    const [curItem, setCurItem] = useState(null);
    const [rType, setRType] = useState('all');
    const [newSharing, setNewSharing] = useState({});

    useEffect(()=>{
        curTab && getList();
    },[curTab]);

    useEffect(()=>{
        if (modalType === "edit" && curItem) {
            newSharing.privacy = curItem.privacy;
            newSharing.content = curItem.content;
            newSharing.bgCode = curItem.bgCode;
            setNewSharing({...newSharing});
        } else {
            newSharing.privacy = 'public';
            newSharing.content = '';
            newSharing.bgCode = '#b9b9b9';
            setNewSharing({...newSharing});
        }
    },[modalType, curItem]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_SHARING_URL,{
            headers: authHeader(),
            params: {
                type: curTab
            }
        })
        .then(response => {
            if(response.data && response.data.list) {
                setLoading(false);
                setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const newSharingOnClick = () => {
        setShowModal(true);
        setModalType('new');
        setCurItem(null);
    }

    const onReport = (content) => {
        if (!content) {
            toast.error('Report content is empty!');
            return;
        }

        axios.post(API_URL.USER_SHARING_ACTION_URL,{action: "report", id: curId, content},{
            headers: authHeader(),
        })
        .then(response => {
            setShowModal(false);
            toast.success('Your report messsage has been sent, we will check it and feedback later. Thanks');
        })
        .catch(error => {
            const resMessage = getError(error);
            setShowModal(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    return (
        <div>
            <div className="leaderBoard">
                <div className="topTabs">
                    <button onClick={() => setCurTab('allSharing')} className={curTab === "allSharing" ? "active" : undefined}>All Sharing</button>
                    <button onClick={() => setCurTab('mySharing')} className={curTab === "mySharing" ? "active" : undefined}>My Sharing</button>
                </div>
            </div>
            <div className={connection.sharing}>
                <div className={connection.button}>
                    <button onClick={newSharingOnClick}><span>+</span> Sharing</button>
                </div>
                {
                    loading && <Loader />
                }
                {
                    !loading && list.length === 0 && 
                    <div className='noResult'>No sharing found.</div>
                }
                {
                    !loading && list.length > 0 && 
                    <ul className={connection.shareList}>
                        {
                            list.map((item,index)=>
                                <SharingItem 
                                    item={item} 
                                    key={`sharingItem${index}`} 
                                    connection={connection} 
                                    list={list} 
                                    setList={setList}
                                    index={index}
                                    setShowModal={setShowModal}
                                    setModalType={setModalType}
                                    setCurId={setCurId}
                                    setRType={setRType}
                                    rType={rType}
                                    setCurItem={setCurItem}
                                />
                            )
                        }
                    </ul>
                }
            </div>
            <Modal 
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width={(modalType === "reactionList" || modalType === "report") ? "500px" : "800px"}
                height={"auto"}
                disabledClickOutside={modalType === "generating"}
                noScroll={modalType === "reactionList"}
                content={
                    <div ref={contentRef}>
                        {
                            (modalType === "new" || modalType === "edit") &&
                            <NewSharing 
                                connection={connection} 
                                setShowModal={setShowModal} 
                                showModal={showModal}
                                curItem={curItem}
                                modalType={modalType}
                                list={list}
                                setList={setList}
                                newSharing={newSharing}
                                setNewSharing={setNewSharing}
                            />
                        }
                        {
                            modalType === "reactionList" && rType &&
                            <SharingReactionList
                                connection={connection}
                                id={curId}
                                rType={rType}
                                setRType={setRType}
                            />
                        }
                        {
                            modalType === "report" && curId && 
                            <Report 
                                connection={connection} 
                                onReport={onReport}
                            />
                        }
                    </div>
                }
            />
        </div>
    )
}

export default Sharing;