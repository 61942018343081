import axios from "axios";
import API_URL from '../common/urls';

export const Login = (username, password) => {
    return axios
      .post(API_URL.LOGIN_URL, {
        username,
        password
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

export const Logout = () => {
    localStorage.removeItem("user");
  } 

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  }

export const getCurrentFullName = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) return user.full_name;
  }  

export const getCurrentUserRole = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) return user.userRole;
}

export const getCurrentUserImage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) return user.userImageUrl;
}