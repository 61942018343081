import { useState } from "react";

const Report = ({connection, onReport}) => {
    const [content, setContent] = useState('');

    return (
        <div className={connection.reportBox}>
            <h2>Report</h2>
            <div>
                <label>Reason</label>
                <textarea onChange={e => setContent(e.target.value)} className="fullInput"/>
            </div>
            <div className="buttons">
                <button className="btn" onClick={() => onReport && onReport(content)}>Send</button>
            </div>
        </div>
    )
}

export default Report;