import {useState} from 'react';
import modal from './modal.module.scss';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import Draggable from 'react-draggable';
import { useStore } from '../../store/useStore';

const NoteBoxForm = ({setShowNoteBox, onSave}) => {
    const [color, setColor] = useState();
    const [showColorBox, setShowColorBox] = useState(false);
    const [note, setNote] = useState('');
    const {noteFormPositionValue, setNoteFormPositionValue} = useStore();
    const [defaultPosition, setDefaultPosition] = useState(noteFormPositionValue);

    const showColorBoxHandler = () => {
        setShowColorBox(!showColorBox);
    }

    const setColorHandler = (color) => {
        setColor(color);
        setShowColorBox(false);
    }

    const saveOnClick = () => {
        if(!note) {
            toast.error('Note is empty!');
            return;
        }

        onSave && onSave(note, color);
        setShowNoteBox(false);
    }

    return (
        <Draggable
            handle=".handle"
            position={null}
            scale={1}
            defaultPosition={defaultPosition}
            onStop={(e, data) => {
                setNoteFormPositionValue({x: data.x, y: data.y});
                }}
        >
            <div className={classNames(modal.noteBox, modal.noteBoxForm, modal[color])}>
                <div className={classNames(modal.handle, modal[color],'handle')}>
                    <span onClick={showColorBoxHandler} className={modal[color]}></span>
                    {
                        showColorBox && 
                        <ul className={modal.colorBox}>
                            <li className={modal.yellow} onClick={() => setColorHandler('yellow')}></li>
                            <li className={modal.blue} onClick={() => setColorHandler('blue')}></li>
                            <li className={modal.pink} onClick={() => setColorHandler('pink')}></li>
                            <li className={modal.green} onClick={() => setColorHandler('green')}></li>
                        </ul>
                    }
                    
                </div>
                <div className={modal.content}>
                    <textarea onChange={e => setNote(e.target.value)}/>
                    <button className="btn" onClick={saveOnClick}>Save</button>
                </div>
                <button className={modal.close} onClick={() => setShowNoteBox(false)}>&times;</button>
            </div>
        </Draggable>
    )
}

export default NoteBoxForm;