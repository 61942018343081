import styles from './styles.module.scss';

const WhyUs = ({data}) => {
    return (
        <div className={styles.whyUs}>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            <ul>
                {
                    data && data.list && data.list.length > 0 &&
                    data.list.map((item, index) =>
                        <li key={`whyUs${index}`}>
                            {
                                item.imageUrl && 
                                <div className={styles.image}><img src={item.imageUrl} width={'100%'} /></div>
                            }
                            <h3 className={styles.title} key={`reason${index}`}>{item.title}</h3>
                            <div className={styles.content}>{item.content}</div>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}

export default WhyUs;