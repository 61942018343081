import {Fragment, useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {toast} from 'react-toastify';
import API_URL from '../../common/urls';
import {MdShoppingCart, MdMenu, MdAccountCircle, MdNotifications} from 'react-icons/md';
import {Link} from 'react-router-dom';
import styles from './styles.module.scss';
import {useStore} from '../../store/useStore';
import {Logout} from '../../services/auth.service';
import useInterval from '../../hooks/useInterval';
import Alerts from '../alerts';
import useClickOutside from '../../hooks/useClickOutside';
import { authHeader } from '../../services/auth-header';
import { creditIcon, getError } from '../../common/utils';

const RightMenus = ({setIsMenuOpen, credit}) => {
    const history = useHistory();
    const {user, setUser, alertsCount, setAlertsCount, cartNumber} = useStore();

    const [showAlerts, setShowAlerts] = useState(false);
    const buttonRef = useRef();
    useClickOutside(buttonRef, () => setShowAlerts(false));

    const setMenuOpen = () => {
        setIsMenuOpen && setIsMenuOpen(true);
    }

    const logout = () => {
        Logout();
        setUser(null);
        history.push('/login');
    }

    useEffect(() => {
        user && counting();
    },[user]);

    useInterval(
        ()=> {
            counting();
        }, user ? 30000 : null);
      
    const counting = () => {
        return axios.get(API_URL.ALERTS_COUNTING_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data) {
                response.data.alertsCount && setAlertsCount(response.data.alertsCount);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                if (resMessage != 'Network Error'){
                    toast.error(resMessage);
                }
            }
        })
    } 

    const alertsOnClick = () => {
        setShowAlerts(true);
    }

    return (
            <div className={styles.rightMenu}>
                {!user &&
                <Fragment>
                    <Link to="/login">Log In</Link>
                    <Link to="/register">Sign Up</Link>
                </Fragment>
                }
                
                {user && 
                    <>
                    <div ref={buttonRef} className={styles.notification} onClick={alertsOnClick}>
                        <MdNotifications size="25"/>
                        {alertsCount > 0 && <span>{alertsCount}</span>}
                        <Alerts showAlerts={showAlerts} setAlertsCount={setAlertsCount}/>
                    </div>
                    
                    <div className={styles.menuUser}>
                        {user.userImageUrl &&
                            <img 
                                src={user.userImageUrl} 
                                alt="profile" 
                            />
                        }
                        {
                            !user.userImageUrl &&
                            <MdAccountCircle size="25" color="#ccc"/>
                        }
                        {
                            user.userRole === "Students" && 
                            creditIcon(credit, 20)
                            // <strong>{credit}</strong>
                        }
                        <div className={styles.dropdown}>
                            <div className={styles.account}>
                                {
                                    !user.userImageUrl &&
                                    <MdAccountCircle className={styles.userLink} size="50"/>
                                }

                                {
                                    user.userImageUrl &&
                                    <div>
                                        <img src={user.userImageUrl} alt="avatar" />
                                    </div>
                                }
                                <div>
                                    {user.full_name}
                                    {user.userRole === "Students" && credit >= 0 && creditIcon(credit, 30)}
                                </div>
                                
                             </div>
                             <div className={styles.links}>
                                {
                                    user.userRole === "Students" &&
                                    <Link to="/user/myAidaPal">My Learning</Link>
                                }
                                {
                                    user.userRole === "Teachers" &&
                                    <Link to="/user/library">Library</Link>
                                }
                                <Link to="/user/profile">Profile</Link>
                                {/* <Link to="/user/classes">My classes</Link> */}
                                <Link to="/login" onClick={logout}>Logout</Link>
                             </div>
                            
                        </div>
                    </div>
                    </>
                }
                <MdMenu size="30" className={styles.mobileMenu} onClick={setMenuOpen} />
            </div>
    )
}

export default RightMenus;