import connection from '../connections/connection2.module.scss';
import CreditExchangeTracking from "./creditExchangeTracking";
import CreditExchangeList from "./creditExchangeList";
import { useState} from "react";

const CreditExchange = () => {
    const [curTab, setCurTab] = useState('list');

    return (
        <div className={connection.gifts}>
            <div className="leaderBoard">
                <div className="topTabs">
                    <button onClick={() => setCurTab('list')} className={curTab === "list" ? "active" : undefined}>All Products</button>
                    <button onClick={() => setCurTab('tracking')} className={curTab === "tracking" ? "active" : undefined}>My Products Tracking</button>
                </div>
            </div>
                {
                    curTab === "list" &&
                    <CreditExchangeList connection={connection} setCurTab={setCurTab}/>
                }
                {
                    curTab === "tracking" &&
                    <CreditExchangeTracking connection={connection} />
                }
        </div>
    )
}

export default CreditExchange;