import {useEffect, useState, useRef, Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import dashboard from './dashboard.module.scss';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {uniqueId, map} from 'lodash';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import Filter from './filter';
import Modal from '../../../components/modal';
import classNames from 'classnames';
import Table, {SortHeader, Cell} from '../../../components/table';
import Pagination from '../../../components/table/pagination';
import {MdPerson,MdFastForward, MdDone, MdClose, MdDoNotDisturbAlt} from 'react-icons/md';
import StudentReports from '../myClasses/studentReports';
import AssignmentDetailsQuestion from './assignmentDetailsQuestion';

const ITEMPERPAGE = 20;

const AssignmentDetails = ({assignId, setCurrentStudent, setCurrentQuestion}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [name, setName] = useState('');
    const [students, setStudents] = useState();
    const [questions, setQuestions] = useState();
    const [questionAnalysis, setQuestionAnalysis] = useState();
    const [loading, setLoading] = useState();

    useEffect(()=>{
        setLoading(true);
        assignId && axios.get(API_URL.GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_URL,{
            headers: authHeader(),
            params: {
                assignId: assignId
            }
        })
        .then(response => {
            if(response.data) {
                 response.data.students && setStudents(response.data.students);
                 response.data.questions && setQuestions(response.data.questions);
                 response.data.assignName && setName(response.data.assignName);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[assignId]);

    const studentOnClick = (studentId, fullName) => {
        setCurrentStudent && setCurrentStudent({studentId: studentId, fullName: fullName});
    }

    const questionOnClick = (questionId, index) => {
        setCurrentQuestion && setCurrentQuestion({questionId: questionId, index: index});
    }

    return (
        <div>
            {loading && <Loader />}
            {!loading && name && 
                <h2>{name}</h2>
            }
            {!loading && students && students.length > 0 &&
                <ul className={classNames(dashboard.dashboardTable, dashboard.details)}>
                    <li className={dashboard.headings}>
                        <div style={{width: 60}}>
                        </div>
                        <div>
                            Name
                        </div>
                        <div>
                            Score                       
                        </div>
                        {
                            questions && questions.length > 0 && 
                            questions.map((q,index) => 
                            <div className={dashboard.question} key={uniqueId()} onClick={() => questionOnClick(q.questionId, index)}>
                                Q{index+1}
                                {
                                    (q.correct || q.incorrect || q.skip) &&
                                    <div className={dashboard.questionPercent}>
                                        {q.correct && <span style={{width: q.correct*100/students.length+'%'}} className={dashboard.correct}></span>}
                                        {q.incorrect && <span style={{width: q.incorrect*100/students.length+'%'}} className={dashboard.incorrect}></span>}
                                        {q.skip && <span style={{width: q.skip*100/students.length+'%'}} className={dashboard.skip}></span>}
                                    </div>
                                }
                            </div>)
                        }
                    </li>
                    {
                        students.length > 0 && students.map(student => 
                        <li key={uniqueId()} className={dashboard.clickable} onClick={() => studentOnClick(student.userName, student.fullName)}>
                            <div className={dashboard.image}>
                                {
                                    student.imageUrl &&
                                    <img src={student.imageUrl} alt="profile"/>
                                }

                                {
                                    !student.imageUrl && <MdPerson size="40" color="#CCCCCC"/>
                                }
                            </div>
                            <div>{student.fullName}</div>
                            <div> 
                                {
                                    student.status === "completed" &&
                                    <>
                                    <div>{student.score}/{student.totalQuestion}</div>
                                    <div className={dashboard.percentBar}>
                                        <span style={{width: student.score*100/student.totalQuestion+"%"}}></span>
                                    </div>
                                    </>
                                }
                                {
                                    student.status === "pending" &&
                                    <div><i>pending...</i></div>
                                }
                                
                            </div>
                            {
                                student.details && student.details.length > 0 &&
                                student.details.map(q => 
                                    <div key={uniqueId()} className={dashboard.center}>
                                        {q === "correct" ? <MdDone color="green" size="20"/> : q === "incorrect" ? 
                                        <MdClose color="red" size="20"/> : q === "skip" ? <MdFastForward color="#aaa" size="20"/> : <MdDoNotDisturbAlt color="#ccc" size="18" />}
                                    </div>
                                ) 
                            }
                        </li>)
                    }
                </ul>
            }
        </div>
    )
}

const AssignmentTracking = () => {
    const contentRef = useRef();
    const history = useHistory();
    const {setUser} = useStore();
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [grade, setGrade] = useState();
    const [filterGrade, setFilterGrade] = useState();
    const [filterSubject, setFilterSubject] = useState();
    const [filterClassName, setFilterClassName] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [assignId, setAssignId] = useState();
    const [page, setPage] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(ITEMPERPAGE);
    const [headItems, setHeadItems] = useState([]);
    const [paginationData, setPaginationData] = useState([]);
    const [sortBy, setSortBy] = useState('ModelName');
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentStudent, setCurrentStudent] = useState();
    const [currentQuestion, setCurrentQuestion] = useState();

    useEffect(()=>{
        let isCancelled = false;
        axios.get(API_URL.GET_USER_DASHBOARD_GRADES_FILTER_URL,{
            headers: authHeader()})
        .then(response => {
            if(response.data && !isCancelled) {
                response.data.grade && setGrade(response.data.grade); 
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);
        });
        return () => {
            isCancelled = true;
          };
    },[])

    useEffect(()=>{
        getData(true);
    },[filterGrade, filterSubject, dateFrom, dateTo, filterClassName, page, itemPerPage, sortBy, sortDirection]);

    const getData = (condition) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_DASHBOARD_ASSIGNMENT_TRACKING_URL,{
            headers: authHeader(),
            params: {
                grade: filterGrade && filterGrade.value,
                subject: filterSubject && filterSubject.value,
                fromDate: dateFrom,
                toDate: dateTo,
                classId: filterClassName && filterClassName.value,
                pNo: page,
                noOfItems: itemPerPage,
                sortBy,
                sortDirection,
            }
        })
        .then(response => {
            if(response.data && condition) {
                response.data.body && setData(response.data.body);
                response.data.heads && setHeadItems(response.data.heads);
                response.data.pagination && setPaginationData(response.data.pagination);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onGradeChange = type => setFilterGrade(type);
    const onSubjectChange = type => setFilterSubject(type);
    const onClassNameChange = type => setFilterClassName(type);

    const assignOnClick = (assignId) => {
        setCurrentStudent(null);
        setCurrentQuestion(null);
        setAssignId(assignId)
        setShowModal(true);
    }

    const onHandlePageChanged = (page) => setPage(page);

    const onHandleSortChange = (index) => {
        const newHead = map(headItems, (item, i) => {
        if (index === i) {
            item.sortType = item.sortType ?
            item.sortType === 'asc' ?
                'desc' :
                'asc' :
            'asc';
            setSortBy(item.id);
            setSortDirection(item.sortType);
        } else {
            item.sortType = null;
        }
        return item;
        });
        setHeadItems([...newHead]);
    };

    const Row = ({item, headItems}) => {
        return (
        <tr onClick={() => assignOnClick(item.assignId)}>
            {headItems.map(head => <Fragment key={uniqueId()}>
                <Cell key={uniqueId()}>
                    {item[head.id]}
                </Cell>
            </Fragment>)}
        </tr>
        );
    };

    const renderRow = (item) => (
        <Row
            key={uniqueId()}
            item={item}
            headItems={headItems}
            setCurrentStudent={setCurrentStudent}
            setAssignId={setAssignId}
            setShowModal={setShowModal}
        />
    );

    const renderHead = (head, index) =>
            <SortHeader
            key={head.id}
            index={index}
            id={head.id}
            align={head.align}
            text={head.text}
            width={head.width}
            sortType={head.sortType}
            onSortChange={onHandleSortChange}
            />;

    const closeView = () => {
        setCurrentQuestion(null);
        setCurrentStudent(null);
        setAssignId(null);
    }

    return (
        <div className={dashboard.dashboard}>
            {grade && grade.length && 
                <Filter 
                    onGradeChange={onGradeChange}
                    onSubjectChange={onSubjectChange}
                    grades={grade}
                    subject={filterSubject}
                    filterGrade={filterGrade}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    className={filterClassName}
                    onClassNameChange={onClassNameChange}
                />
            }
            {loading && <Loader />}
            {!loading && data &&
                <div>        
                <Table
                    head={
                        <tr>
                        {headItems && headItems.map(renderHead)}
                        </tr>
                    }
                    className={dashboard.assignTable}
                    >
                        <Fragment>
                        {data && data.map(renderRow)}
                        {!data ||
                            (!data.length && (
                            <tr>
                                <Cell align='center' colSpan={8}>
                                No results found
                                </Cell>
                            </tr>
                            ))}
                        </Fragment>
                    </Table>
                    {paginationData.length > 0 && (
                    <Pagination
                        total={paginationData.totalItems}
                        currentPage={paginationData.currentPage}
                        itemPerPage={paginationData.itemPerPage}
                        onChange={onHandlePageChanged}
                    />
                    )}
                </div>
            }

            <Modal
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width="80%"
                height="auto"
                content={
                    <div ref={contentRef} className={dashboard.modalContent}>
                        {
                            assignId && !currentStudent && !currentQuestion &&
                            <AssignmentDetails 
                                assignId={assignId}
                                setCurrentStudent={setCurrentStudent}
                                setCurrentQuestion={setCurrentQuestion}
                            />
                        }
                        {
                            currentStudent && currentStudent.studentId && assignId && 
                            <>
                            <h2>{currentStudent.fullName}</h2>
                            <StudentReports 
                                assignId={assignId}
                                studentId={currentStudent.studentId}
                                studentName={currentStudent.fullName}
                            />
                            <button onClick={closeView} className={dashboard.close}>&times;</button>
                            </>
                        }
                        {
                            currentQuestion && assignId &&
                            <>
                            <AssignmentDetailsQuestion
                                currentQuestion={currentQuestion}
                                assignId={assignId}
                                setLoading={setLoading}
                                loading={loading}
                                setUser={setUser}
                                history={history}
                            />
                            <button onClick={closeView} className={dashboard.close}>&times;</button>
                            </>
                        }
                    </div>
                } />
        </div>
    )
}

export default AssignmentTracking;