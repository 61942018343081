import { useEffect } from "react";
import Themes from "./themes";

const Step2 = ({lib, formData, setFormData, worksheetThemes, worksheetEdit}) => {

    const themeOnChange = (id) => {
        setFormData && setFormData({...formData, theme: id});
    }

    const updateWorksheetType = (type) => {
        setFormData && setFormData({...formData, worksheetMethod: type});
    }

    return (
        <div className={lib.autoScroll}>
                <div className={lib.methods}>
                    <label>Add Questions Methods</label>
                    <ul>
                        <li className={formData.worksheetMethod === "select" ? lib.active : ""} onClick={() => updateWorksheetType("select")}>
                            Select From Available Resource
                        </li>
                        {
                            !worksheetEdit &&
                            <li className={formData.worksheetMethod === "upload" ? lib.active : ""} onClick={() => updateWorksheetType("upload")}>
                                Upload Questions File
                            </li>
                        }
                        <li className={formData.worksheetMethod === "new" ? lib.active : ""} onClick={() => updateWorksheetType("new")}>
                            Create New or Edit Questions
                        </li>
                    </ul>
                </div>
            
            <Themes lib={lib} themes={worksheetThemes} currentTheme={formData.theme} onChange={themeOnChange} />
        </div>
    )
}

export default Step2;