import { useEffect} from 'react';
import { Link } from 'react-router-dom';
import PageContent from './pageContent';
import styles from './styles.module.scss';
import styles2 from '../styles.module.scss';
import TabContent from './tabContent';
import { useStore } from '../../../store/useStore';
import classNames from 'classnames';

const MyAidaPal = () => {
    const {customValue, activeSubTab, setActiveSubTab, activeTab, setActiveTab, user} = useStore();
    const changeSubject = (subject) => {
        setActiveTab(subject);
        if (subject === activeTab) setActiveSubTab('');
    }

    useEffect(()=>{
        if (!activeTab) {
            const subjects = user.subscriptionSubjects;
            const array = subjects.split(',');
            if (array.length && array[0]) {
              setActiveTab(array[0].trim());
            }
          }
    }, []);
    return(
        <div className={styles.container}>
            <div className={styles[activeTab]}>
                <div className={styles2.tabLinks}>
                    <Link
                        to="#" 
                        onClick={() => changeSubject('math')} 
                        className={classNames(activeTab === "math" ? styles2.active : "", {[styles2.disabled]: !user.subscriptionSubjects.includes('math')})}
                        style={{width: 130}}
                    >
                        {
                            activeTab === "math" && activeSubTab && <span>{activeSubTab}</span>
                        }
                        <svg viewBox="0 0 160 60">
                            <text className={styles2.svgStroke} x="50%" y="50%">Math</text>
                            <text className={styles2.svgText} x="50%" y="50%">Math</text>
                        </svg>
                    </Link>
                    <Link 
                        to="#" 
                        onClick={() => changeSubject('english')} 
                        className={classNames(activeTab === "english" ? styles2.active : "", {[styles2.disabled]: !user.subscriptionSubjects.includes('english')})}
                        style={{width: 180}}
                    >
                        {
                            activeTab === "english" && activeSubTab && <span>{activeSubTab}</span>
                        }
                        <svg viewBox="0 0 222 60">
                            <text className={styles2.svgStroke} x="50%" y="50%">English</text>
                            <text className={styles2.svgText} x="50%" y="50%">English</text>
                        </svg>
                    </Link>
                    <Link 
                        to="#" 
                        onClick={() => changeSubject('science')} 
                        className={classNames(activeTab === "science" ? styles2.active : "", {[styles2.disabled]: !user.subscriptionSubjects.includes('science')})}
                        style={{width: 180}}
                    >
                        {
                            activeTab === "science" && activeSubTab && <span>{activeSubTab}</span>
                        }
                        <svg viewBox="0 0 222 60">
                            <text className={styles2.svgStroke} x="50%" y="50%">Science</text>
                            <text className={styles2.svgText} x="50%" y="50%">Science</text>
                        </svg>
                    </Link>
                    <Link 
                        to="#" 
                        onClick={() => changeSubject('bilingual math')} 
                        className={classNames(activeTab === "bilingual math" ? styles2.active : "", {[styles2.disabled]: !user.subscriptionSubjects.includes('bilingual math')})}
                        style={{width: 130}}
                    >
                        {
                            activeTab === "bilingual math" && activeSubTab && <span>{activeSubTab}</span>
                        }
                        <svg viewBox="0 0 160 60">
                            <text className={styles2.svgStroke} x="50%" y="50%">Toán</text>
                            <text className={styles2.svgText} x="50%" y="50%">Toán</text>
                        </svg>
                    </Link>
                </div>
                <div>
                    {
                        !activeSubTab && activeTab &&
                        <TabContent activeTab={activeSubTab} setActiveTab={setActiveSubTab}
                            activeParent={activeTab} setActiveParent={setActiveTab} customValue={customValue}/>
                    }
                    {
                        activeSubTab &&
                        <PageContent subject={activeTab} activeTab={activeSubTab} setActiveTab={setActiveSubTab}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyAidaPal;