import connection from './connection2.module.scss';
import GiftsTracking from "./giftsTracking";
import GiftsList from "./giftsList";
import { useState} from "react";

const Gifts = () => {
    const [curTab, setCurTab] = useState('giftsList');

    return (
        <div className={connection.gifts}>
            <div className="leaderBoard">
                <div className="topTabs">
                    <button onClick={() => setCurTab('giftsList')} className={curTab === "giftsList" ? "active" : undefined}>All Gifts</button>
                    <button onClick={() => setCurTab('tracking')} className={curTab === "tracking" ? "active" : undefined}>Gifts Tracking</button>
                </div>
            </div>
                {
                    curTab === "giftsList" &&
                    <GiftsList connection={connection} curTab={curTab}/>
                }
                {
                    curTab === "tracking" &&
                    <GiftsTracking connection={connection} />
                }
        </div>
    )
}

export default Gifts;