import classNames from 'classnames';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {MdTrendingFlat} from 'react-icons/md';
import InputDayPicker from './InputDayPicker';
import styles from './picker.module.scss';


const DateRangePicker = ({fromDate, toDate, className, size, onChange, disablePast = false}) => {
  const DateFormat = 'DD-MM-YYYY';
  const [from, setFrom] = useState(
    fromDate ?
      new Date() :
      undefined,
  );

  const [to, setTo] = useState(
    toDate ?
      new Date() :
      undefined,
  );


  const toEl = useRef(null);

  const modifiers = {start: from, end: to};

  useEffect(() => {
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      toEl.current.getDayPicker() &&
        toEl.current.getDayPicker().showMonth(from);
    }
    if (onChange && from && !to) {
      onChange(moment(from).format(DateFormat), null);
    }
    if (onChange && from && to && toEl.current.getDayPicker()) {
      onChange(moment(from).format(DateFormat), moment(to).format(DateFormat));
    }
  }, [from, to]);

  const wrapperClass = classNames(styles.DayPickerInput, className, {
    [styles.small]: size === 'small',
  });

  const onFromChange = (date) => {
    if (!to) {
      setFrom(date);
    } else if (moment(to).diff(moment(date)) >= 0) {
      setFrom(date);
    } else {
      setFrom(to);
    }
  };

  const onToChange = (date) => {
    if (moment(date).diff(moment(from)) >= 0) {
      setTo(date);
    } else {
      setTo(from);
    }
  };

  const disabledFromDays = disablePast ?
    {
      before: new Date(),
      after: to,
    } : {after: to};

  return (
    <ul className={wrapperClass}>
      <li className={styles.from}>
        <InputDayPicker
          value={from}
          placeholder="From"
          dayPickerProps={{
            selectedDays: [from, {from, to}],
            disabledDays: disabledFromDays,
            toMonth: to,
            modifiers,
            onDayClick: () => toEl.current.getInput().focus(),
          }}
          onChange={onFromChange}
        />
      </li>
      <li className={styles.arrow}>
        <MdTrendingFlat size={20} />
      </li>
      <li className={styles.to}>
        <InputDayPicker
          ref={toEl}
          value={to}
          placeholder="To"
          dayPickerProps={{
            selectedDays: [from, {from, to}],
            disabledDays: {before: from},
            modifiers,
            fromMonth: from,
          }}
          onChange={onToChange}
        />
      </li>
    </ul>
  );
};

export default DateRangePicker;
