import { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from '../common/urls';
import {toast} from 'react-toastify';
import { getError } from '../common/utils';

const useCountryCheck = () => {
    const [countryCode, setCountryCode] = useState('');
    const [country, setCountry] = useState('');
    useEffect(() => {
        let isCancelled = false;
        axios.get(API_URL.COUNTRY_LOOKUP_URL)
        .then(response => {
            response.data && isCancelled && response.data.country && setCountry(response.data.country);
            response.data && isCancelled && response.data.countryCode && setCountryCode(response.data.countryCode.toLowerCase());
       })
       .catch(error => {
        const resMessage = getError(error);
        toast.error(resMessage);
       });

       return isCancelled = true;
     },[])

    return [countryCode, country]
}

export default useCountryCheck;
 