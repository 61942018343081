import {useEffect, useState, Fragment} from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import styles from './App.module.scss';
import Header from './components/header';
import { Route, BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginPage from './views/login';
import Home from './views/homepage';
import RegisterPage from './views/login/register';
import ForgotPassPage from './views/login/forgotPass';
import ContactUs from './views/contactUs';
import API_URL from './common/urls';
import User from './views/user';
import Footer from './components/footer';
import Loading from './components/loading';
import { useStore } from './store/useStore';
import { getError } from './common/utils';
import MeetingEnd from './views/user/myClasses/meetingEnd';
import Toc from './components/toc';
import CustomToast from './components/customToast';
import ShareQuestion from './views/shareQuestion';

function App() {
  const [contact, setContact] = useState({});
  const [socialConnect, setSocialConnect] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [products, setProducts] = useState();
  const {user, toastContent, setToastContent} = useStore();
  const currentUser = user && user.access ? user.access : null;

  useEffect(() => {
    let isCancelled = false;
    // document.title = "AIDA Learning program";
    
        const url = API_URL.GENERAL_URL;
        axios.get(url)
        .then(response => {
            if(response.data.data && !isCancelled) {
              response.data.data.products && setProducts(response.data.data.products);
              response.data.data.contact && setContact(response.data.data.contact);
              response.data.data.socialConnect && setSocialConnect(response.data.data.socialConnect);
              setShowLoading(false);
            }
        })
        .catch(error => {
          const resMessage = getError(error);
          toast.error(resMessage);
          setShowLoading(false);
        })

        return () => {
            isCancelled = true;
          };
 }, []);

 useEffect(()=>{
   toastContent && setTimeout(()=> setToastContent(''), 5000);
 },[toastContent]);

  return (
      <Fragment>
      <Loading showLoading={showLoading}/>
      <BrowserRouter>
        <div className={styles.App} id='top'>
          <Header        
            socialConnect={socialConnect}
            products={products}
          />
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot-pass" component={ForgotPassPage} />
          <Route exact path="/contact-us" component={() => <ContactUs contact={contact} socialConnect={socialConnect}/>} />
          <Route path='/shareTestPlay/:themeId/:qId' component={ShareQuestion} />
          {currentUser && 
            <Fragment>
            <Route path="/user" component={User}>
              <Route path="/user/:menu" component={User} />
            </Route>
            <Route exact path="/meetingEnd" component={MeetingEnd} />
            {
              !user.agreed_toc_time &&
              <Toc />
            }
            </Fragment>
          }

          {!currentUser && 
            <Fragment>
            <Route exact path="/user" component={LoginPage} />
            <Route exact path="/assessment-test" component={LoginPage} />
            <Route exact path="/meetingEnd" component={LoginPage} />
            </Fragment>
          }
        </div>
        <Footer contact={contact} socialConnect={socialConnect}/>
        <ToastContainer />
        {
            toastContent &&
            <CustomToast html={toastContent} />
        }
      </BrowserRouter>
      </Fragment>
  );
}

export default App;
