import React, {Fragment, useState, useEffect, useRef, forwardRef} from 'react';
import {useHistory, Link} from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import axios from 'axios';
import map from 'lodash/map';
import Table, {SortHeader, Cell} from '../../../components/table';
import {authHeader} from '../../../services/auth-header';
import {toast} from 'react-toastify';
import styles from '../styles.module.scss';
import {MdMoreHoriz} from 'react-icons/md';

import Pagination from '../../../components/table/pagination';
import API_URL from '../../../common/urls';
import {Loader} from '../../../components/loading';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import Modal from '../../../components/modal';

const ITEMPERPAGE = 20;

const Row = ({item, index, headItems, setShowModal, setCurrentTransaction}) => {
  const moreOnClick = () => {
    setCurrentTransaction && setCurrentTransaction(item);
    setShowModal(true);
  }
  return (
    <Fragment>
      <tr>
          {headItems.map(head => 
          <Fragment key={uniqueId()}>
              <Cell key={uniqueId()}>
                  {item[head.id]}
              </Cell>
          </Fragment>)}
          <Cell className={styles.cellMore}>
            <MdMoreHoriz size="25" onClick={moreOnClick} className={styles.more}/>
          </Cell>
      </tr>
      
    </Fragment>
  );
};

const TransactionHistory = () => {
  const {setUser} = useStore();
  const history = useHistory();
  const contentRef = useRef();
  const [page, setPage] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(ITEMPERPAGE);
  const [headItems, setHeadItems] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [sortBy, setSortBy] = useState('purchaseDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState();

  useEffect(()=>{
    setLoading(true);
    const url = API_URL.GET_TRANSATION_HISTORY_URL;
      axios.get(url,{
        params: {
          pNo: page,
          noOfItems: itemPerPage,
          sortBy,
          sortDirection,
        },
        headers: authHeader()
      }).then((res)=>{
          setLoading(false);
          res.data.body && setResponseData(res.data.body);
          res.data.heads && setHeadItems(res.data.heads);
          res.data.pagination && setPaginationData(res.data.pagination);
      }).catch(error => {
        setLoading(false);
        const resMessage = getError(error);
        
        if(error.response && error.response.status === 401) {
          Logout();
          setUser(null);
          history.push('/login');
        } else {
          toast.error(resMessage);
        }

    });
  },[page,itemPerPage,sortBy,sortDirection]);

  const onHandlePageChanged = (page) => setPage(page);

  const onHandleSortChange = (index) => {
    const newHead = map(headItems, (item, i) => {
      if (index === i) {
        item.sortType = item.sortType ?
          item.sortType === 'asc' ?
            'desc' :
            'asc' :
          'asc';
        setSortBy(item.id);
        setSortDirection(item.sortType);
      } else {
        item.sortType = null;
      }
      return item;
    });
    setHeadItems([...newHead]);
  };

  const renderRow = (item, index) => (
      <Row
        key={uniqueId()}
        item={item}
        headItems={headItems}
        index={index}
        setShowModal={setShowModal}
        setCurrentTransaction={setCurrentTransaction}
      />
  );

  const renderHead = (head, index) =>
        <SortHeader
          key={head.id}
          index={index}
          id={head.id}
          align={head.align}
          text={head.text}
          width={head.width}
          sortType={head.sortType}
          onSortChange={onHandleSortChange}
        />;

  return (
    <Fragment>
      {loading && <Loader />}
      {!loading &&
      <div className={styles.whiteBox}>
        <Table
          head={
            <tr>
              {headItems && headItems.map(renderHead)}
              <Cell></Cell>
            </tr>
          }
        >
            <Fragment>
              {responseData && responseData.map(renderRow)}
              {!responseData ||
                (!responseData.length && (
                  <tr>
                    <Cell align='center' colSpan={6}>
                      No results found
                    </Cell>
                  </tr>
                ))}
            </Fragment>
        </Table>
        {paginationData && (
          <Pagination
            total={paginationData.totalItems}
            currentPage={paginationData.currentPage}
            itemPerPage={paginationData.itemPerPage}
            onChange={onHandlePageChanged}
          />
        )}
      </div>
      }
        <Modal 
          setShowModal={setShowModal} 
          showModal={showModal} 
          contentRef={contentRef}
          width="500px"
          height="auto"
          noScroll={false}
          content={
              <div ref={contentRef}>
                {
                  currentTransaction && 
                  <div className={styles.transactionDetails}>
                      <h2>Transaction Details</h2>
                      <p><label>Ref number:</label>{currentTransaction.refNumber}</p>
                      <p><label>Name:</label>{currentTransaction.packageName}</p>
                      <p><label>Purchase date:</label>{currentTransaction.purchaseDate}</p>
                      <p><label>Status:</label>{currentTransaction.status}</p>
                      {currentTransaction.value > 0 &&
                        <p><label>Total:</label>${currentTransaction.value}</p>
                      }
                      {currentTransaction.discount > 0 &&
                        <p><label>Total discount:</label>${currentTransaction.discount}</p>
                      }
                      <h4>Details List</h4>
                      <div className={styles.list}>
                        {currentTransaction.details && currentTransaction.details.length > 0 
                          && currentTransaction.details.map(
                          detail => (
                            <div key={uniqueId()}>
                              <Link to={`/products/classes/list/${detail.productId}`}>{detail.name}</Link>
                              {detail.price > 0 && 
                                <span>${detail.price}</span>
                              }
                              {
                                detail.oldPrice > 0 && 
                                <span className={styles.oldPrice}>{detail.oldPrice}</span>
                              }
                              
                            </div>
                          )
                        )}
                      </div> 
                  </div>
                }
                  
              </div>
          } 
      />
    </Fragment>
  );
};

export default TransactionHistory;
