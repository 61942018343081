import moment from "moment";
import { useEffect, useState } from "react";
import { MdAccessTime, MdDateRange, MdPerson } from "react-icons/md";
import TimeCount from "../../../components/input/timeCount";
import creditIcon1 from '../../../images/medal.svg';
import worksheetIcon from '../../../images/ws.svg';

const Item = ({item, exam, history}) => {
    const [examStatus, setExamStatus] = useState(false);
    const timeDiff = moment.utc().diff(moment.utc(item.startTime));
    const seconds = Math.floor(Math.abs(timeDiff)/1000);
    const timeDiffEnd = moment.utc().diff(moment.utc(item.endTime));
    const [isShowTime, setIsShowTime] = useState(false);

    useEffect(()=>{
        if (timeDiff < -3600000 || timeDiffEnd <= 0) {
            setExamStatus('upcoming');
        };

        if (timeDiff > -3600000 && timeDiff < -2000 ) { // time diff is in 1 hr
            setExamStatus('count');
        }

        if (timeDiff >= -2000 && timeDiffEnd < 2700000) {
            setExamStatus('open');
        };
        if ((timeDiffEnd > 2700000 && item.lessionId) || (timeDiffEnd > 0)) {

            // allow class continue 45 mins after end time
            setExamStatus('end');
        }

    },[timeDiff, timeDiffEnd]);

    const onJoinExam = () => {
        history.push(`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`);
    }

    return (
        <li>
           <div className={exam.image}>
                <img title='Exam' src={worksheetIcon} width={80} />
            </div>
            <div className={exam.info}>
                <strong>{item.name}</strong>
                <div className={exam.time}>
                    {item.startTime && <p><MdDateRange size="18" /> {moment.utc(item.startTime).local().format('MMMM DD, YYYY (dddd)')}</p>}
                    {(item.time || item.startTime) && <p><MdAccessTime size="18" />&nbsp;
                        {item.startTime && item.endTime &&
                            <>
                            {moment.utc(item.startTime).local().format('hh:mm a')} - {moment.utc(item.endTime).local().format('hh:mm a')}
                            </>
                        }
                        {!item.startTime && !item.endTime &&
                            <>{item.time}</>
                        }
                    </p>}
                </div>
            </div>
            <div className={exam.status}>
                {
                    examStatus === "upcoming" &&
                    <div className={exam.myExam}>
                        <p>Exam {timeDiff < 0 ? "will be" : "has been"} started</p>
                        <strong>{moment.utc(item.startTime).local().startOf('seconds').fromNow()}</strong>
                    </div>
                }
                
                {
                    examStatus === "count" &&  
                    <div className={exam.myExam}>
                        <p>Exam will be started in</p>
                        <TimeCount 
                            seconds={seconds} 
                            setIsShowTime={setIsShowTime} 
                            messageTimeOut='' 
                            style={{display: isShowTime ? "block": "none"}}
                            setExamStatus={setExamStatus}
                            isFromClassItem={true}
                        />
                        
                        { timeDiff > -300000 &&
                            <button className="btn" onClick={onJoinExam}>Start Exam Now</button>
                        }
                    </div>
                }

                {
                    examStatus === "open"  && 
                    <div className={exam.myExam}>
                        <p className={exam.started}>Exam is ongoing</p> 
                        <button onClick={onJoinExam} className={exam.join}>Join Exam Now</button>
                    </div>
                }

                {
                    examStatus === "end"  && 
                    <div className={exam.myExam}>
                        <p className={exam.started}>Exam has ended {moment.utc(item.endTime).local().startOf('seconds').fromNow()}</p>
                    </div>
                }
            </div>
            {
                item.credit > 0 &&
                <div className={exam.credit}>+ {item.credit} <img src={creditIcon1} height="20" /></div>
            }
            
        </li>
    )
}

export default Item;