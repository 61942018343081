import {useState, useEffect, useRef} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import calendar from './calendar.module.scss';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {uniqueId} from 'lodash';
import { getError } from '../../../common/utils';
import Modal from '../../../components/modal';
import ClassItem from '../../../components/class-item';
import Meeting from '../myClasses/meeting';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
    const {setUser} = useStore();
    const history = useHistory();
    const contentRef = useRef();
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentEvent, setCurrentEvent] = useState();
    const [meetingInfo, setMeetingInfo] = useState({meetingNo: '', pass: ''});
    const [classId, setClassId] = useState('');
    const [lessionId, setLessionId] = useState('');
    const date = new Date();
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    useEffect(()=>{
        startDate && axios.get(API_URL.GET_CALENDAR_EVENTS_URL,{
            headers: authHeader(),
            params: {
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD')
            }
        })
        .then(response => {
            if(response.data.list) {
              const _events = response.data.list.map(item => 
                Object.create({...item,
                    id: item.lessionId,
                    title: item.title,
                    start: new Date(moment.utc(item.startTime).local()),
                    end: new Date(moment.utc(item.endTime).local())}) 
              );
              setEvents(_events);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[startDate]);

    const onClickEvent = (event) => {
      if(event) {
        setShowModal(true);
        setCurrentEvent(event);
        setClassId(event.classId);
        setLessionId(event.lessionId);
      }
    }

    const onRangeChange = (range) => {
      if(range.start) {
        setStartDate(range.start);
        setEndDate(range.end);
      } else {
        if(range[6]) {
          setStartDate(range[0]);
          setEndDate(range[6]);
        } else {
          setStartDate(range[0]);
          setEndDate(range[0]);
        }
      }
    }

    return (
      <>
      {!meetingInfo.meetingNo && 
        <div className={calendar.calendar}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100vh - 130px)' }}
                onSelectEvent={event => onClickEvent(event)}
                // onNavigate={onNavigate}
                onRangeChange={onRangeChange}
            />
            <Modal
              setShowModal={setShowModal} 
              showModal={showModal} 
              contentRef={contentRef}
              width="450px"
              height="auto"
              noScroll={false}
              content={
                  <div ref={contentRef}>
                    {
                      currentEvent && 
                      <div>
                          <h2>{currentEvent.title}</h2>
                          <ClassItem
                            data={currentEvent} 
                            key={uniqueId()} 
                            trial={false}
                            isMyClass={true}
                            isCalendar={true}
                            isCompleted={false}
                            setShowModal={setShowModal}
                            setMeetingInfo={setMeetingInfo}
                          />
                      </div>
                    }
                      
                  </div>
              } 
          />
        </div>
        }

        { meetingInfo.meetingNo && 
            <Meeting meetingInfo={meetingInfo} classId={classId} lessionId={lessionId} />
        } 
      </>  
    )
}

export default MyCalendar;