import { OPTIONS_TYPE } from '../../../common/utils';
import Input from '../../../components/input';
import { useState, useRef, useEffect } from 'react';
import useClickOutside from "../../../hooks/useClickOutside";
import { FaCheck } from 'react-icons/fa';
import NewOption from './newOption';
import {uniqueId, isEmpty} from 'lodash';
import { MdCancel } from 'react-icons/md';
import { toast } from 'react-toastify';

const QuestionStep3 = ({lib, formData, setFormData, }) => {
    const [openList, setOpenList] = useState(false);
    const [selectedType, setSelectedType] = useState(formData.questionType ? formData.questionType : {value: "text", label: "Short answer"});
    const listRef = useRef();
    useClickOutside(listRef, () => { 
        setOpenList(false);
    });

    useEffect(()=>{
        formData.noOfBlanks && setSelectedType({value: "text", label: "Fill-in-the-blanks"});
    },[]);

    const selectType = (type) => {
        setOpenList(false);
        setSelectedType(type);
        let _options = ["",""];
        if (type.label === "True-False") {
            if(formData.hasNotGiven) {
                _options = ["True","False","Not Given"];
            } else {
                _options = ["True","False"];
            }
        }

        if (type.label === "Yes-No") {
            if(formData.hasNotGiven) {
                _options = ["Yes","No","Not Given"];
            } else {
                _options = ["Yes","No"];
            }
        }

        //set questionType, answerType, reset options
        setFormData({...formData, questionType: type, answerType: type.value, options: _options, options2: [], correctOptions: [], correctText: []});
    }

    const changeCorrectOption = (value) => {
        const arr = formData.correctOptions;
        if(arr.length > 0) {
            arr.splice(0,1,value);
        } else  {
            arr.push(value);
        }
        
        setFormData({...formData, correctOptions: arr});
    }

    const newOptionOnChange = () => {
        const arr1 = formData.options;
        const arr2 = formData.options2;
        const arrCorrect = formData.correctText;
        arr1.push("");

        if (formData.answerType === "matching") {
            setFormData({...formData, options: arr1, options2: arr2, correctText: arrCorrect});
        } else {
            setFormData({...formData, options: arr1, options2: [], correctText: arrCorrect});
        }
    }

    const rightAnswerListRemove = (index) => {
        const arr = formData.correctText;
        if (formData.shortAnswerBlanks > 1) {
            arr.splice(index, 1);
            setFormData({...formData, correctText: arr, shortAnswerBlanks: formData.shortAnswerBlanks - 1});
        } else {
            toast.error('Each question should have at least 1 answer!');
        }
    }

    const rightAnswerListOnChange = (e, index) => {
        const arr = formData.correctText;
        if (arr.length > 0) {
            arr.splice(index, 1, e.target.value);
        } else {
            arr.push(e.target.value);
        }
        
        setFormData({...formData, correctText: arr, questionType: selectedType, answerType: selectedType.value});
    }

    const maxInputCharOnChange = (e) => {
        setFormData({...formData, maxInputChar: e.target.value});
    }

    const updateOpenEndedType = (type) => {
        setFormData({...formData, openEndedType: type});
    }

    return (
        <div className={lib.autoScroll}>
            <div className={lib.questionTypes}>
                <label>Question Type</label>
                <div className={lib.selectedType} onClick={() => setOpenList(!openList)}>
                    {selectedType && selectedType.label}
                </div>
                <ul className={lib.list} style={{display: openList? 'block' : 'none'}} ref={listRef}>
                    {OPTIONS_TYPE.QUESTIONS_TYPE.map( item => 
                        <li key={uniqueId()} id={item.value}
                            onClick={() => selectType(item)}
                            className={item.label ===  selectedType.label ? lib.active : ''}
                        >
                            <div>
                                {item.label}
                            </div>
                            <p>{item.explanation}</p>
                        </li>
                    )}
                </ul>
            </div>
            {
                (selectedType.label === "True-False" || selectedType.label === "Yes-No") && 
                <div className={lib.additionInfo}>
                    <label>Select correct option 
                        <button className="smallBtn2" onClick={()=> {
                            const _options = formData.options;
                            if(!formData.hasNotGiven) {
                                _options.push('Not Given');
                            }
                            setFormData({...formData, options: _options, hasNotGiven: !formData.hasNotGiven})
                        }
                            }>
                            {formData.hasNotGiven ? "Remove " : "Add "}option "Not Given"
                        </button>
                    </label>
                    <div className={`checkBox ${(formData && formData.correctOptions && formData.correctOptions.includes(0)) ? "active" : ''}`} 
                    onClick={() => changeCorrectOption(0)}>
                        <FaCheck /> <span>{selectedType.label === "True-False" ? "True" : "Yes"}</span>
                    </div>
                    <div className={`checkBox ${(formData && formData.correctOptions && formData.correctOptions.includes(1)) ? "active" : ''}`} 
                    onClick={() => changeCorrectOption(1)}>
                        <FaCheck /> <span>{selectedType.label === "True-False" ? "False" : "No"}</span>
                    </div>
                    {
                        formData.hasNotGiven &&
                        <div className={`checkBox ${(formData && formData.correctOptions && formData.correctOptions.includes(2)) ? "active" : ''}`} 
                        onClick={() => changeCorrectOption(2)}>
                            <FaCheck /> <span>Not Given</span>
                        </div>
                    }
                </div>
            }
            {
                selectedType.label === "Choices" && 
                <div className={lib.additionInfo}>
                    <label>Options <span>{formData.options.length}</span></label>
                    <p className="sub-label">Click to checkboxes to select correct answers</p>
                    <a href="#" className={lib.newBox} onClick={newOptionOnChange}><span>+</span> Add new Option</a>
                </div>
            }
            {
                selectedType.label === "Short answer" && 
                <div className={lib.additionInfo}>
                    <label>Right Answer 
                        <button className="smallBtn2" onClick={() => setFormData({...formData, shortAnswerBlanks: formData.shortAnswerBlanks + 1})}>
                            Add new input
                        </button>
                    </label>
                    <div className={lib.inputOption}>
                        {/* <Input
                            type="text" 
                            placeholder="" 
                            onChange={rightAnswerOnChange}
                            value={formData.correctText.length > 0 ?  formData.correctText[0] : ""}
                            label=""
                            className={lib.inputName}
                        />   */}
                        {
                        formData.shortAnswerBlanks > 0 && Array.from(Array(formData.shortAnswerBlanks)).map(
                                (item, index) =>
                                <div className={lib.inputOption} key={'blank '+index}>
                                    <MdCancel size="20" onClick={() => rightAnswerListRemove(index)}/>
                                    <Input
                                        type="text" 
                                        placeholder="" 
                                        onChange={(e) => rightAnswerListOnChange(e,index)}
                                        value={formData.correctText[index]}
                                        label=""
                                        className={lib.inputName}
                                    />
                                </div> 
                            )
                        } 
                    </div>
                    <div className={`checkBox ${(formData && formData.setMaxChar) ? "active" : ''}`} 
                    onClick={() => setFormData({...formData, setMaxChar: !formData.setMaxChar})}>
                        <FaCheck /> <span>Set character limit for each answer (not including spaces)</span>
                    </div>
                    {
                        formData.setMaxChar &&
                        <Input
                            type="number"
                            value={formData.maxInputChar}
                            onChange={maxInputCharOnChange}
                            endText="char(s)"
                            className={lib.maxChar}
                            min={0}
                        />
                    }
                </div>
            }
            {
                selectedType.label === "Fill-in-the-blanks" && 
                <div className={lib.additionInfo}>
                    <label>Right Answers for <span>{formData.noOfBlanks ? formData.noOfBlanks : 0}</span> blank(s)</label>
                    <p><u>Note:</u> Change <strong>number of Blanks</strong> by coming back to <b>Content</b> Step.</p>
                    {
                        formData.noOfBlanks > 0 && Array.from(Array(formData.noOfBlanks)).map(
                            (item, index) =>
                            <div className={lib.inputOption} key={'blank '+index}>
                                <Input
                                    type="text" 
                                    placeholder="" 
                                    onChange={(e) => rightAnswerListOnChange(e,index)}
                                    value={formData.correctText[index]}
                                    label=""
                                    className={lib.inputName}
                                />
                            </div> 
                        )
                    }
                      
                </div>
            }
            {
                (selectedType.value === "matching") && 
                <div className={lib.additionInfo}>
                    <label>Pair of options <span>{formData.options.length}</span></label>
                    <p><u>Note:</u> Input the <strong>RIGHT PAIR</strong>, system will change position in the test.</p>
                    <a href="#" className={lib.newBox} onClick={newOptionOnChange}><span>+</span> Add new Option</a>
                </div>
            }
            {
                selectedType.value === "ordering" && 
                <div className={lib.additionInfo}>
                    <label>Options <span>{formData.options.length}</span></label>
                    <p><u>Note:</u> Add the options in the right order, system will change position in the test.</p>
                    <a href="#" className={lib.newBox} onClick={newOptionOnChange}><span>+</span> Add new Option</a>
                </div>
            }
            {
                formData.options.length > 0 && ["Choices", "Matching", "Ordering"].includes(selectedType.label) &&
                formData.options.map((item, index) => 
                <NewOption
                    lib={lib} 
                    selectedType={selectedType}
                    index={index}
                    item={item}
                    formData={formData}
                    setFormData={setFormData}
                    key={'option' + index}
                />
                ) 
            }
            {
                selectedType.label === "Open-ended" &&
                <div className={lib.methods}>
                    <label>Select Type</label>
                    <ul>
                        <li className={formData.openEndedType === "Self-marked" ? lib.active : ""} onClick={() => updateOpenEndedType("self-marked")}>
                            Self-marked
                        </li>
                        <li className='disabledOption'>
                            Ai-marked
                        </li>
                        <li className='disabledOption'>
                            Teacher-marked
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

export default QuestionStep3;