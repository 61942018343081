import {useEffect, useState, useRef, Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import {uniqueId} from 'lodash';
import terms from './styles/terms.module.scss';
import {MdAdd, MdAttachFile, MdVisibility, MdClose, MdFileDownload} from 'react-icons/md';

const LessionFile = ({file, setShowView, setFileUrl, setFileId, setFileType, removeFile, user, index, i, fi, isClassFile}) => {

    const openFile = (e, id, url, type) => {

        setShowView && setShowView(true);
        setFileUrl && setFileUrl(url);
        setFileType && setFileType(type);
        setFileId && setFileId(id);
    }

    const onRemoveItem = () => {
        const confirm = window.confirm("Are you sure you want to remove this file?");
        if(confirm) {
                removeFile && removeFile(file.fileId, "file", index, i, fi, isClassFile);
        }
    }

    return (
            <div className={classNames(terms.file, terms[file.fileType])}>
                <div onClick={(e) => openFile(e, file.fileId, file.url, file.fileType)}>
                    <h5 title={file.name}>{file.name}</h5>
                    <span>{file.fileSize}</span>
                </div>
                <a href={file.url} target="_blank" title="Download"><MdFileDownload size="25"/></a>
                {
                    user.userRole === "Teachers" &&
                    <MdClose className={terms.close} title="Remove file" onClick={onRemoveItem}/>
                }
            </div>
    )
}

const LessionFiles = ({list, user, addFiles, lessionId, setShowView, setFileUrl, setFileId, setFileType, removeFile, index, i, isClassFile}) => {
    const [isAddingFile, setIsAddingFile] = useState(false);
    const [files, setFiles] = useState();
    const [fileNames, setFileNames] = useState([]);

    const filesOnChange = (e) => {
        const files = e.target.files;
        if(files.length > 5) {
            toast.error('Allow maximum 5 files. Please try again!');
            setFiles([]);
        } else {
            setFiles(e.target.files);
            if (e.target.files.length > 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    fileNames.push(e.target.files[i].name);
                }
                setFileNames([...fileNames]);
                setIsAddingFile(true);
            }
        }
    }

    const onCancel = () => {
        setFileNames([]);
        setFiles([]);
        setIsAddingFile(false);
    }

    const onSaveFiles = () => {
        addFiles && addFiles(lessionId, files);
    }

    return (
        <div>
            {
               (user.userRole === "Teachers" || (user.userRole !== "Teachers" && list && list.length > 0)) &&
                <label>Materials</label>
            }
            
            {list && list.length > 0 && list.map((lessionFile, fi) => 
                <LessionFile 
                    file={lessionFile} 
                    key={uniqueId()} 
                    setShowView={setShowView}
                    setFileUrl={setFileUrl}
                    setFileId={setFileId}
                    setFileType={setFileType}
                    removeFile={removeFile}
                    user={user}
                    index={index}
                    i={i}
                    fi={fi}
                    isClassFile={isClassFile}
                />
            )
            }
            {user.userRole === "Teachers" && 
                <div className={terms.newFile} title="Add materials">
                    {!isAddingFile && 
                        <MdAdd />
                    }
                    <input 
                        type="file" 
                        multiple={true}
                        onChange={filesOnChange}
                        files={files}
                    />
                    {isAddingFile && fileNames && fileNames.length > 0 && 
                        <>
                        <ul>
                            {fileNames.map(item => <li key={uniqueId()}><MdAttachFile />{item}</li>)}
                        </ul>
                        <div className={terms.buttons}>
                            <button className="btn" onClick={onSaveFiles}>Save</button>
                            <button onClick={onCancel}>Cancel</button>
                        </div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default LessionFiles;