import React, {useState, useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom';
import axios from 'axios';
import {toast} from 'react-toastify';
import {Logout} from '../../services/auth.service';
import styles from '../login/styles.module.scss';
import Input from '../../components/input';
import API_URL from '../../common/urls';
import {getError} from '../../common/utils';
import classNames from 'classnames';
import ComplexField from '../../components/input/complexField';
import {isEqual} from 'lodash';

const ForgotPass = () => {
    const history = useHistory();
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [isEmail, setIsEmail] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [pasConfirm, setPassConfirm] = useState('');

    useEffect(() => {
        Logout();
    },[]);

    const emailOnchange = (e) => {
        const val = e.target.value;
        setEmail(val)
    }

    const onSetPhoneNo = (value) => {
        setPhoneNo(value);
    }

    const handleForgotPass = () => {
        if(!email) {
            toast.error(`User name is empty!`);
            return;
        }

        const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!emailReg.test(email)) {
            toast.error(`You have entered an invalid email address!`);
            return;
        }

        axios.post(API_URL.FORGOT_VERIFICATION_URL,{email})
        .then(response => 
            {
                toast.success('Your password is already sent to your email.');
                setIsConfirm(true);
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);       
        })

    }

    const emailOnChoose = (type) => {
        type === "email" && setIsEmail(true);
        type !== "email" && setIsEmail(false);
    }

    const verificationCodeOnChange = (e) => setVerificationCode(e.target.value);
    const passwordOnChange = (e) => setPassword(e.target.value);
    const passwordConfirmOnChange = (e) => setPassConfirm(e.target.value);

    const onSubmit = () => {
        if(!verificationCode) {
            toast.error(`Verification Code is empty!`);
            return;
        }

        if(!password) {
            toast.error(`Password is empty!`);
            return;
        }

        if(password.length < 6) {
            toast.error(`Password at least 6 characters!`);
            return;
        }

        if(!isEqual(password, pasConfirm)) {
            toast.error(`Password confirmation is not equal password!`);
            return;
        }

        axios.post(API_URL.UPDATE_PASSWORD_URL,{verificationCode, password})
        .then(response => 
            {
                toast.success('Your new password is updated.');
                history.push('/login');
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);       
        })
    }

    return (
        <div className={styles.formContainer}>
            <div className={classNames(styles.login, styles.forgotPass)}>
                <h1>Forgot Password</h1>
                <p>Add your phone number or email to get verification code</p>
                <div className={styles.blueTabs}>
                    <Link className={isEmail ? "" : styles.active} to="#" onClick={() => emailOnChoose('phone')}>Phone Number</Link>
                    <Link className={isEmail ? styles.active : ""} to="#" onClick={() => emailOnChoose('email')}>Email</Link>
                </div>
                {!isEmail && 
                    <div>
                        <ComplexField
                            styles={styles}
                            placeholder="Phone No."
                            buttonText="Get Code"
                            setPhoneNo={onSetPhoneNo}
                            phoneNo={phoneNo}
                            apiUrl={API_URL.FORGOT_VERIFICATION_URL}
                            value={phoneNo}
                            handleSuccess={setIsConfirm}
                        />
                    </div>
                }
                {
                   isEmail && 
                   <div>
                       <Input 
                            type="text-button" 
                            placeholder="Email"
                            onChange={emailOnchange}
                            className={styles.verification}
                            buttonOnClick={handleForgotPass}
                            buttonText="Get Code"
                        />
                   </div> 
                }
                {
                    isConfirm && 
                    <>
                        <Input 
                            type="text" 
                            placeholder="Verification Code"  
                            onChange={verificationCodeOnChange} 
                        />
                        <Input 
                            type="pass-with-view" 
                            placeholder="Password (at least 6 characters)" 
                            onChange={passwordOnChange}
                            className={styles.registerInput}
                        />

                        <Input 
                            type="pass-with-view" 
                            placeholder="Password confirmation" 
                            onChange={passwordConfirmOnChange}
                            className={styles.registerInput}
                        />
                        <div className="buttons">
                            <button className="btn" onClick={onSubmit}>Confirm</button>
                        </div>
                    </>
                }       
            </div>
        </div>
        
    )
}

export default ForgotPass;