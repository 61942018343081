import useQuery from "../../../hooks/useQuery";
import styles from '../styles.module.scss';
import {Link} from 'react-router-dom';
import LibraryContent from "./libraryContent";
import Tabs from "./tabs";
import { useEffect } from "react";
import { useStore } from "../../../store/useStore";

const Library = () => {
    const {user, customValue, activeSubTab, setActiveSubTab, activeTab, setActiveTab} = useStore();
    const queryParams = useQuery();
    const folderId = decodeURIComponent(queryParams.get('folderId'));

    const changeTab = (tab) => {
        setActiveTab(tab);
        if (tab === activeTab) setActiveSubTab('');
    }

    useEffect(()=>{
        !activeTab && setActiveTab('myLib');
    },[]);

    return(
        <div>
            {user.userRole === 'Teachers' &&
                <>
                    <div className={styles.tabLinks}>
                        <Link
                            to="#" 
                            onClick={() => changeTab('public')} 
                            className={activeTab === "public" ? styles.active : ""}
                            style={{width: 151}}
                        >
                            {
                                activeTab === "public" && activeSubTab && <span>{activeSubTab}</span>
                            }
                            <svg viewBox="0 0 190 60">
                                <text className={styles.svgStroke} x="50%" y="50%">Public</text>
                                <text className={styles.svgText} x="50%" y="50%">Public</text>
                            </svg>
                        </Link>
                        <Link 
                            to="#" 
                            onClick={() => changeTab('authorised')} 
                            className={activeTab === "authorised" ? styles.active : ""}
                            style={{width: 270}}
                        >
                            {
                                activeTab === "authorised" && activeSubTab && <span>{activeSubTab}</span>
                            }
                            <svg viewBox="0 0 340 60">
                                <text className={styles.svgStroke} x="50%" y="50%">Authorised</text>
                                <text className={styles.svgText} x="50%" y="50%">Authorised</text>
                            </svg>
                        </Link>
                        <Link 
                            to="#" 
                            onClick={() => changeTab('myLib')} 
                            className={activeTab === "myLib" ? styles.active : ""}
                            style={{width: 246}}
                        >
                            {
                                activeTab === "myLib" && activeSubTab && <span>{activeSubTab}</span>
                            }
                            <svg viewBox="0 0 310 60">
                                <text className={styles.svgStroke} x="50%" y="50%">My Library</text>
                                <text className={styles.svgText} x="50%" y="50%">My Library</text>
                            </svg>
                        </Link>
                    </div>
                    <div className={styles.tabContent}>
                        {
                            !activeSubTab && (folderId == 'null') &&
                            <Tabs activeTab={activeSubTab} setActiveTab={setActiveSubTab}
                                activeParent={activeTab} setActiveParent={setActiveTab} customValue={customValue}/>
                        }
                        {
                            activeSubTab &&
                            <LibraryContent
                                privacy={activeTab}
                                folderId={folderId}
                                activeTab={activeSubTab}
                                setActiveTab={setActiveSubTab}
                                parentTab={activeTab}
                            />
                        }
                    </div>
                </>
            }
            {
                user.userRole !== 'Teachers' &&
                <div className={styles.whiteBox}>
                    <div className="noResult">Your role is not allowed to view this</div>
                </div>
            }
        </div>
    )
}

export default Library;