import styles from './styles.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../common/override-css.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1224, min: 850 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    }
  };

const QuickView = ({data}) => {
    return (
        <div className={styles.quickView}>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            {
                data && data.list && data.list.length > 0 &&
                <Carousel responsive={responsive} itemClass={styles.listItem} containerClass={styles.container}>
                    {data.list.map((item, index) =>
                        <div key={`carousel2${index}`} className={styles.image}>
                            <img src={item && item.imageUrl} />
                            <div>{item.title}</div>
                        </div>
                    )
                    }
                </Carousel> 
            }
        </div>
    )
}

export default QuickView;