import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import lib from './lib.module.scss';
import { authHeader } from '../../../services/auth-header';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import Modal from '../../../components/modal';
import NewItems from "./newItem";
import FileInfo from "./info";
import DeleteItems from "./deleteItem";
import CopyItems from "./copyItem";
import useClickOutside from "../../../hooks/useClickOutside";
import ListItems from "./listItems";
import FullModal from "../../../components/modal/fullModal";
import {uniqueId} from 'lodash';
import WorksheetReview from "./stepReview";
import InfiniteScroll from "react-infinite-scroll-component";
import TestPlay from "../../../components/testPlay";

const Folders = ({privacy, type, sort, showModal, setShowModal, modalType, setModalType, newType, parentTab,
    search, filters, reloadList, setReloadList, folderId, isGrid, setNewType, isClone, setIsClone}) => {
    const {setUser} = useStore();
    const listRef = useRef();
    const history = useHistory();
    const contentRef = useRef();
    const [selectItems, setSelectItems] = useState([]);
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [openControl, setOpenControl] = useState(false);
    const [cModalType, setCModalType] = useState();
    const [saving, setSaving] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [importId, setImportId] = useState('');
    const [selectWsId, setSelectWsId] = useState('');

    useClickOutside(listRef, () => { 
        setSelectItems([]);
        setOpenControl && setOpenControl(false);
    });

    const [list, setList] = useState({
        folders: [],
        files: [],
        worksheets: [],
        questions: []
    });
    const [foldersTree, setFoldersTree] = useState([]);
    const [loading, setLoading] = useState();

    useEffect(()=>{
        cModalType && setModalType(cModalType);
        cModalType && setShowModal(true);
    },[cModalType]);

    useEffect(()=>{
        !showModal && setSelectItems && setSelectItems([]);
        !showModal  && setCModalType && setCModalType('');
        !showModal && importId && getList();
        !showModal && setImportId('');
        showModal && setOpenControl && setOpenControl(false);
    },[showModal]);

    useEffect(()=>{
        selectItems && selectItems[0] && selectItems[0].id && selectItems[0].type === 'worksheet' && setSelectWsId(selectItems[0].id);
        if (selectItems && selectItems.length > 1 && selectItems.filter(item => item.id === 'favourite').length){
            let _index = null;
            selectItems.forEach((item, index) => {
                if(item.id === 'favourite') _index=index;
            });
            _index !== null && selectItems.splice(_index, 1);
            setSelectItems([...selectItems]);
        } ;
    },[selectItems]);

    useEffect(()=>{
        setSelectItems && setSelectItems([]);
        setOpenControl && setOpenControl(false);
        reloadList && setLoading(true);
        reloadList && setSelectItems([]);
        setLoading(true);
        (privacy || search || type || sort || filters || reloadList || folderId) && getList();
    },[privacy, search, type, sort, filters, reloadList, folderId]);

    const getList = (isFetchData) => {
        const url = API_URL.GET_LIBRARY_LIST_URL;
        return axios.get(url,{
            headers: authHeader(),
            params: {
                // pageNo: pageNo,
                // pageSize: 50,
                privacy,
                folderId: folderId ? folderId : null,
                type,
                search,
                sort,
                fromDate: filters.fromDate ? filters.fromDate : null,
                toDate: filters.toDate ? filters.toDate : null,
                levels: filters.levels.length ? filters.levels.map(item => item.value).join(',') : null,
                subjects: filters.subjects ? filters.subjects.map(item => item.value).join(',') : null,
                isFavorite: filters.isFavorite,
                authors: filters.authors ? filters.authors.map(item => item.value).join(',') : null,
                fileTypes: type === "files" && filters.fileTypes ? filters.fileTypes.map(item => item.value).join(',') : null,
                worksheetType: type === "worksheets" && filters.worksheetType ? filters.worksheetType.map(item => item.value).join(',') : null,
                topics: filters.topics ? filters.topics.map(item => item.value).join(',') : null,
                subTopics: filters.subTopics ? filters.subTopics.map(item => item.value).join(',') : null,
                tags: type === "questions" && filters.tags ? filters.tags.map(item => item.value).join(',') : null,
                questionType:type === "questions" && filters.questionType ? filters.questionType.map(item => item.label).join(',') : null,
                sizeLarger: type === "files" && filters.sizeLarger ? filters.sizeLarger : null,
                sizeSmaller: type === "files" && filters.sizeSmaller ? filters.sizeSmaller : null,
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.list) {
                setList(response.data.list);
                // !isFetchData && setList(response.data.list);
                // isFetchData && setList(list.concat(response.data.list));
                // isFetchData && setHasMore(true);
            }
            if(response.data.foldersTree) {
                setFoldersTree(response.data.foldersTree);
            }
            if (response.data.list.length === 0) {
                setHasMore(false);
              }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const fetchMoreData = () => {
        setPageNo(pageNo + 1);
        getList(true);
    }

    const addFavorite = (type) => {
        axios.post(API_URL.POST_LIBRARY_ITEMS_ACTION, {items: selectItems, action: type},{
            headers: authHeader(),
        })
        .then(response => {
            setReloadList(reloadList + 1);
        })
        .catch(error => {
            const resMessage = getError(error);            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const moveTo = (id) => {
        // e.preventDefault();
        history.push(`/user/library?folderId=${id}&tab=${parentTab}&subTab=${type}`);
    };

    return(
        <div className={lib.list}>
            {
                loading && <Loader />
            }
            {
                !loading &&
                <div className={lib.breadcrumbs}>
                    <a onClick={(e) => moveTo('null')}>Root</a>
                    {
                        foldersTree.length > 0 &&
                        foldersTree.map(
                            (item, index) => 
                            <a key={`bc${index}`} onClick={(e) => moveTo(item.id)}>{item.name}</a>)
                    }
                </div>
            }
            {
                !loading && !isGrid &&
                <div className={lib.listHeader}>
                    <span>Name</span>
                    <span className={lib.date}>Last Modified</span>
                    <span>Author</span>
                    {
                        type === "files"  &&
                        <span>Size</span>
                    }
                    {
                        type !== "files"  &&
                        <span>Type</span>
                    }
                </div>
            }
            {
                !loading && type === "files"  && list.folders.length === 0 && list.files.length === 0 &&
                <div className={lib.noItem}>
                    No item found. 
                </div>
            }
            {
                !loading && type === "worksheets"  && list.folders.length === 0 && list.worksheets.length === 0 &&
                <div className={lib.noItem}>
                    No item found. 
                </div>
            }

            {
                saving && percentCompleted > 0 &&
                <div className={lib.saving}>
                    <div>Please wait, saving ...</div>
                    <div className={lib.uploading}>
                        <span style={{width: percentCompleted + "%"}}>{percentCompleted}</span>
                    </div>
                </div>
            }

            <div ref={listRef}>
                {/* <InfiniteScroll
                    dataLength={50}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Loader />}
                > */}
                    <ListItems
                        list={list}
                        privacy={privacy}
                        type={type}
                        selectItems={selectItems}
                        setSelectItems={setSelectItems}
                        isGrid={isGrid}
                        loading={loading}
                        addFavorite={addFavorite}
                        openControl={openControl}
                        setOpenControl={setOpenControl}
                        setCModalType={setCModalType}
                        setNewType={setNewType}
                        tab={parentTab}
                    />
                {/* </InfiniteScroll> */}
                
                {
                    (!["question","worksheet"].includes(newType)  || (["question","worksheet"].includes(newType) && !["new","edit"].includes(modalType))) && modalType !== "viewworksheet" &&
                    <Modal
                        setShowModal={setShowModal} 
                        showModal={showModal} 
                        contentRef={contentRef}
                        width={(["viewfile", "viewworksheet"].includes(modalType)) ? "90%" : modalType === "viewquestion" ? "1000px" : "600px"}
                        height={modalType === "viewfile" ? "calc(100% - 40px)" : "auto"}
                        disabledClickOutside={modalType === "new" ? true : false}
                        isExpandable={modalType === "viewfile"}
                        isTestForm={modalType === "play"}
                        content={
                            <div ref={contentRef}>
                                {
                                    (modalType === "new" || modalType === "edit")  &&
                                    <NewItems type={newType} folderId={folderId ? folderId : null} modalType={modalType} setModalType={setModalType}
                                    setShowModal={setShowModal} setReloadList={setReloadList} showModal={showModal}
                                    showQuestionForm={newType === "question" ? true : showQuestionForm} setShowQuestionForm={setShowQuestionForm} 
                                    selectItem={selectItems && selectItems[0]} saving={saving} setSaving={setSaving}
                                    percentCompleted={percentCompleted} setPercentCompleted={setPercentCompleted}
                                    isClone={isClone} setIsClone={setIsClone} setImportId={setImportId} parentType={type}
                                />
                                }
                                {
                                    modalType === "info"  &&
                                    <FileInfo id={selectItems.length ? selectItems[0].id : null} 
                                        type={selectItems.length ? selectItems[0].type : null} showModal={showModal} 
                                        setShowModal={setShowModal}/>
                                }
                                {
                                    modalType === "delete" &&
                                    <DeleteItems selectItems={selectItems} setShowModal={setShowModal}
                                        setReloadList={setReloadList} />
                                } 
                                {
                                    (modalType === "copy" || modalType === "move") &&
                                    <CopyItems selectItems={selectItems} setShowModal={setShowModal}
                                    setReloadList={setReloadList} type={type} modalType={modalType} showModal={showModal}/>
                                }
                                {
                                    modalType === "viewfile" && selectItems && selectItems[0] &&
                                    <div className={lib.viewfile}>
                                        {   (selectItems[0].fileType === "pdf") && 
                                            <iframe width="100%"
                                                height="98%"
                                                src={`${API_URL.COURSEWARE_PDF_VIEW_URL}?docId=${selectItems[0].id}`}>
                                            </iframe>
                                        }

                                        {   (selectItems[0].fileType !== "pdf" && selectItems[0].fileType !== "mp4") &&
                                            <iframe width="100%"
                                                height="100%"
                                                src={`${selectItems[0].url}&embedded=true`}>
                                            </iframe>
                                        }
                                        {
                                            selectItems[0].fileType === "mp4" &&
                                            <video height="100%" controls controlsList="nodownload">
                                                <source src={selectItems[0].url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        }
                                    </div>
                                }
                                {
                                    modalType === "viewquestion" && selectItems && selectItems[0] &&
                                    <WorksheetReview lib={lib} id={selectItems[0].id} type="question" listQuestions={list.questions}/>     
                                }
                            </div>
                        }
                    /> 

                }
                {
                    ((["question","worksheet", "file"].includes(newType) && ["new","edit"].includes(modalType)) || modalType === "viewworksheet" || modalType === "viewImportQuestions") &&
                    <FullModal
                        setShowModal={setShowModal} 
                        showModal={showModal} 
                        contentRef={contentRef}
                        width={"80%"}
                        height={"calc(100% - 40px)"}
                        isExpandable
                        content={
                            <>
                            {
                                modalType === "viewworksheet" && selectItems && selectItems[0] &&
                                    <WorksheetReview lib={lib} id={selectItems[0].id} type="worksheet"/> 
                            }
                            {
                                modalType !== "viewworksheet" && modalType !== "viewImportQuestions" &&
                                <NewItems type={newType} folderId={folderId ? folderId : null} modalType={modalType} selectItem={selectItems && selectItems[0]}
                                    setShowModal={setShowModal} setReloadList={setReloadList} showModal={showModal} setModalType={setModalType}
                                    showQuestionForm={newType === "question" ? true : showQuestionForm} setShowQuestionForm={setShowQuestionForm}
                                    questionLength={list.questions.length} saving={saving} setSaving={setSaving}
                                    percentCompleted={percentCompleted} setPercentCompleted={setPercentCompleted}
                                    isClone={isClone} setIsClone={setIsClone} parentType={type} setImportId={setImportId}
                                />
                            }
                            {
                                modalType === "viewImportQuestions" && importId &&
                                <WorksheetReview lib={lib} importId={importId} type="import" setShowModal={setShowModal} />
                            }
                            </>
                        }
                    />
                }
                { modalType === "play" &&
                    <TestPlay
                        setShowModal={setShowModal} 
                        showModal={showModal} 
                        contentRef={contentRef}
                        themeId={selectItems && selectItems[0] && selectItems[0].themeId}
                        isPreview={true}
                        worksheetId={selectWsId}
                    />
                }
            </div>     
        </div>
    )
};

export default Folders;