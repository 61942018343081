import classNames from 'classnames';
import styles from './input.module.scss';
import fracIcon from '../../images/icon-frac.svg';
import supIcon from '../../images/icon-sup.svg';
import rootIcon from '../../images/icon-root.svg';
import { useState } from 'react';
import { MdAttachFile } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';
import { useRef } from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import { useEffect } from 'react';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import {toast} from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store/useStore';

const MathInput = ({label, className, content, setContent, index, type, subject, data, setData}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [percentCompleted, setPercentCompleted] = useState(0);
    const inputRef = useRef();
    const urlRef = useRef();
    const _content = content ? content : data  && data.text ? data.text : '';
    useClickOutside(urlRef, () => { 
        setOpenUrlInput(false);
        setOpenFileUpload(false);
    }); 
    const [focus, setFocus] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [openUrlInput, setOpenUrlInput] = useState(false);
    const [openFileUpload, setOpenFileUpload] = useState(false);
    // const [url, setUrl] = useState('');

    const fileOnChange = (e) => {
        setFile(e.target.files);
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
        }
    }

    const onForcusOut = (e) => {
        setContent && setContent(e.currentTarget.innerHTML, index);
        setData && setData({...data, text: e.currentTarget.innerHTML})
        setFocus(false);
    }

    useEffect(() => {
        fileName && setOpenFileUpload(true);
    },[fileName]);

    const iconOnClick = (type, index) => {
        if (type === "frac") {
            const fracMl = '<math><mfrac><mi>x</mi><mi>y</mi></mfrac></math>&nbsp;';
            setContent && setContent(_content !== undefined && _content.concat(fracMl), index);
            setData && setData({...data, text: data.text.concat(fracMl)});
        };

        if (type === "superscript") {
            const ssMl =  '<math><msup><mi>x</mi><mn>n</mn></msup></math>&nbsp;';
            setContent && setContent(_content !== undefined && _content.concat(ssMl), index);
            setData && setData({...data, text: data.text.concat(ssMl)});
        }

        if (type === "root") {
            const rootMl = '<math><mroot><mi>x</mi><mn>n</mn></mroot></math>&nbsp;';
            setContent && setContent(_content !== undefined && _content.concat(rootMl), index);
            setData && setData({...data, text: data.text.concat(rootMl)});
        }

        if (type === "celsius") {
            const celMl = '&#8451;';
            setContent && setContent(_content !== undefined && _content.concat(celMl), index);
            setData && setData({...data, text: data.text.concat(celMl)});
        }
    }

    const saveFile = () => {
        const formData = new FormData();

        if (file && file.length > 0) {
            formData.append('file', file[0]);
        }

        var config = {
            headers: authHeader(),
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total));
            }
          };

        axios.post(API_URL.USER_UPLOAD_FILE_URL,formData,config)
        .then(response => {
            setData({...data, fileUrl : response.data.data.link});
            setOpenFileUpload(false);
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={classNames(styles.field, className)}>
            {
                label && 
                <label className={styles.label}>
                    {label}
                </label>
            }
            <div className={styles.controls}>
                {
                    ["Math", "Bilingual Math"].includes(subject) &&
                    <img src={fracIcon} alt='Fraction' height={35} 
                    onClick={() => iconOnClick('frac', index)}/>
                }
                
                {
                    ["Math","Science","Bilingual Math"].includes(subject) &&
                    <img src={supIcon} alt='Superscript' style={{padding:10}} 
                    onClick={() => iconOnClick('superscript', index)}/>
                }

                {
                    ["Science"].includes(subject) &&
                    <span onClick={() => iconOnClick('celsius', index)}>&#8451;</span>
                }
                
                {
                    ["Math","Bilingual Math"].includes(subject) &&
                    <img src={rootIcon} alt='Root' height={20} 
                    onClick={() => iconOnClick('root', index)}/>
                }
                
                {
                    type === "fullOptions" &&
                    <>
                    <MdAttachFile size={25} style={{padding: 7}} onClick={() => inputRef.current.click()}/>
                    <FaLink size={20} style={{padding: 10}} onClick={() => setOpenUrlInput(true)}/>
                    </>
                }
                {
                    openFileUpload &&
                    <div className={styles.urlInput} ref={urlRef}>
                        <div>
                            {
                                percentCompleted > 0 &&
                                <span style={{width: percentCompleted + '%'}}>{percentCompleted}</span>
                            }
                            <input type='text' value={fileName} />
                            <input type='button' value='Upload' onClick={saveFile} disabled={!file} />
                        </div>
                    </div>
                }
                {
                    openUrlInput &&
                    <div className={styles.urlInput} ref={urlRef}>
                        <label>Add File Url</label>
                        <div>
                            <input type='text' onChange={(e) => setData({...data, fileUrl: e.target.value})} />
                        </div>
                    </div>
                }
            </div>
            <div className={classNames(styles.inputPseudo,{[styles.full]: type === "fullOptions"})}>
                    <div className={focus ? styles.focus : undefined} 
                        dangerouslySetInnerHTML={{__html: content ? content : data && data.text}} 
                        contentEditable={true} 
                        onFocus={()=> setFocus(true)}
                        onBlur={onForcusOut}
                        suppressContentEditableWarning={true}
                    />
                    <input type='file' ref={inputRef} onChange={fileOnChange} />
            </div>
            {/* {
                fileName && percentCompleted === 100 &&
                <div className={styles.file}><MdAttachFile size={20} /> {fileName}</div>
            } */}
            {
                data && data.fileUrl &&
                <div className={styles.file}><FaLink /> {data.fileUrl}</div>
            }
        </div>
    )
}

export default MathInput;