import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError, reactionIcon } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import likeIcon from '../../../images/reaction-like.svg';
import loveIcon from '../../../images/reaction-love.svg';
import hahaIcon from '../../../images/reaction-haha.svg';
import surpriseIcon from '../../../images/reaction-surprise.svg';
import cryIcon from '../../../images/reaction-cry.svg';
import angryIcon from '../../../images/reaction-angry.svg';
import { useEffect, useState } from 'react';
import { Loader } from '../../../components/loading';
import { MdPerson } from 'react-icons/md';

const List = ({list, connection}) => {
    const {setUser} = useStore();
    const history = useHistory();

    const requestConnect = (userName) => {
        axios.post(API_URL.USER_CONNECTIONS_REQUEST_URL,{search: userName, type: "request"},{
            headers: authHeader()
        })
        .then(response => {
            toast.success('Your request is sent. Please wait until they confirm!');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage); 
            }
        })
    }
    return (
        <ul>
            {list.map((item, index)=>
                <li key={`reactUser${index}`}>
                    <div className={connection.userIcon}>
                        {
                            item.userImageUrl &&
                            <img 
                                src={item.userImageUrl} 
                                alt="user image" 
                            />
                        }
                        {
                            !item.userImageUrl &&
                            <MdPerson size="50" color="#ddd"/>
                        }
                        {
                            item.reaction &&
                            reactionIcon(item.reaction, false)
                        }
                    </div>
                    <div className={connection.userName}>
                        {
                            item.fullName
                        }
                        {
                            item.mutualFriends > 0 &&
                            <span>{item.mutualFriends} mutual connections</span>
                        }
                    </div>
                    {
                        !item.isConnection &&
                        <button className='btn' onClick={() =>requestConnect(item.userName)}>Connect</button>
                    }
                </li>
            )}
        </ul>
    )
}

const SharingReactionList = ({profile, rType, setRType, id, connection}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [list, setList] = useState({
        all:  [],
        like: [],
        haha: [],
        love: [],
        surprise: [],
        cry: [],
        angry: []
    });
    const [loading, setLoading] = useState();

    useEffect(()=>{
        id && getList();
    },[id]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_SHARING_REACTION_LIST_URL,{
            headers: authHeader(),
            params: {
                id// can be comment id or sharing id
            }
        })
        .then(response => 
            {
                if (response.data && response.data.list) {
                    setLoading(false);
                    setList({
                        ...list, 
                        all: response.data.list, 
                        like: response.data.list.filter(item => item.reaction === "like"),
                        love: response.data.list.filter(item => item.reaction === "love"),
                        haha: response.data.list.filter(item => item.reaction === "haha"),
                        surprise: response.data.list.filter(item => item.reaction === "surprise"),
                        cry: response.data.list.filter(item => item.reaction === "cry"),
                        angry: response.data.list.filter(item => item.reaction === "angry")
                    });
                }
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return(
        <div className={connection.sharingReactionList}>
            <div className={connection.reactionTabs}>
                <span className={rType === "all" ? connection.active : undefined} onClick={() => setRType('all')}>All</span>
                {
                    list.like.length > 0 &&
                    <img className={rType === "like" ? connection.active : undefined} onClick={() => setRType('like')} src={likeIcon} title="like list" width={40} alt="like" />
                }
                {
                    list.love.length > 0 &&
                    <img className={rType === "love" ? connection.active : undefined} onClick={() => setRType('love')} src={loveIcon} title="love list" width={40} alt="love" />
                }
                {
                    list.haha.length > 0 &&
                    <img className={rType === "haha" ? connection.active : undefined} onClick={() => setRType('haha')} src={hahaIcon} title="haha list" width={45} alt="haha" />
                }
                {
                    list.surprise.length > 0 &&
                    <img className={rType === "surprise" ? connection.active : undefined} onClick={() => setRType('surprise')} src={surpriseIcon} title="surprise list" width={45} alt="surprise" />
                }
                {
                    list.cry.length > 0 &&
                    <img className={rType === "cry" ? connection.active : undefined} onClick={() => setRType('cry')} src={cryIcon} title="cry list" width={43} alt="cry" />
                }
                {
                    list.angry.length > 0 &&
                    <img className={rType === "angry" ? connection.active : undefined} onClick={() => setRType('angry')} src={angryIcon} title="angry list" width={40} alt="angry" />
                }
    
            </div>
            <div className={connection.reactionList}>
                {
                    loading && <Loader />
                }
                {
                    !loading && list.all.length === 0 &&
                    <div>No data found</div>
                }
                {
                    !loading && rType === "all" && list.all.length > 0 &&
                    <List list={list.all} connection={connection} />
                }

                {
                    !loading && rType === "like" && list.like.length > 0 &&
                    <List list={list.like} connection={connection} />
                }

                {
                    !loading && rType === "love" && list.love.length > 0 &&
                    <List list={list.love} connection={connection} />
                }

                {
                    !loading && rType === "haha" && list.haha.length > 0 &&
                    <List list={list.haha} connection={connection} />
                }

                {
                    !loading && rType === "cry" && list.cry.length > 0 &&
                    <List list={list.cry} connection={connection} />
                }

                {
                    !loading && rType === "surprise" && list.surprise.length > 0 &&
                    <List list={list.surprise} connection={connection} />
                }

                {
                    !loading && rType === "angry" && list.angry.length > 0 &&
                    <List list={list.angry} connection={connection} />
                }
            </div>
        </div>
    )
}

export default SharingReactionList;
