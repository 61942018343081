import { forwardRef } from "react";
import { MdContentCopy, MdDelete, MdDriveFileMoveOutline, MdInfoOutline, MdEdit, MdPlayArrow, MdFavorite, MdVisibility, MdDownload } from "react-icons/md";
import lib from './lib.module.scss';

const Controls = forwardRef(({selectItems, offsetX, offsetY, onClick, addFavorite, viewFolder, isNewList}, ref) => {

    return (
        <div className={lib.control} ref={ref} style={{left: offsetX ? offsetX : 0, top: offsetY ? offsetY : 0}}>
            {
                selectItems.length === 1 &&
                <>
                {
                    !isNewList && selectItems[0].id !== 'favourite' &&
                    <MdInfoOutline size="35" title="Info" onClick={(e)=> onClick && onClick(e, 'info')} />
                }
                
                {
                    (selectItems[0].permission === "edit" || isNewList) &&
                    <MdEdit size="32" title="Edit" onClick={(e)=> onClick && onClick(e, 'edit', selectItems[0].type)} />
                }
                {
                    selectItems[0].permission !== "forbidden" && !isNewList &&
                    <MdVisibility size="32" title="View" onClick={(e)=> selectItems[0].type !== "folder" ? onClick && onClick(e, `view${selectItems[0].type}`) : viewFolder && viewFolder(selectItems[0].id)} />
                }
                {
                    selectItems[0].type === "worksheet" && selectItems[0].permission !== "forbidden" &&
                        <MdPlayArrow size="38" title="Preview" onClick={(e)=> onClick && onClick(e, 'play')} />
                }
                {
                     selectItems[0].type === "file" && ["edit","download"].includes(selectItems[0].permission) &&
                    <a href={selectItems[0].url} target="_blank" download={selectItems[0].name}>
                        <MdDownload size="32" title="Download" />
                    </a>
                }
                {
                    isNewList && selectItems[0].allowClone &&
                    <MdContentCopy size="30" title="Copy" onClick={(e)=> onClick && onClick(e, 'copy')} />
                }
                {
                    !isNewList && selectItems[0].id !== 'favourite' &&
                    <MdFavorite size="30" className={selectItems[0].favorite ? lib.active : ''} title={selectItems[0].favorite ? 'unlike' : 'like'} onClick={()=> addFavorite(selectItems[0].favorite ? 'unlike' : 'favorite')}/>
                }
                </>
            }
            {
                !isNewList && selectItems.every(item => item.permission === "edit") &&
                <>
                <MdContentCopy size="30" title="Copy" onClick={(e)=> onClick && onClick(e, 'copy')} />
                <MdDriveFileMoveOutline size="35" title="Move" onClick={(e)=> onClick && onClick(e, 'move')} />
                <MdDelete size="32" title="Delete" onClick={(e)=> onClick && onClick(e, 'delete')}/>
                </>
            }

            {
                isNewList &&
                <MdDelete size="32" title="Delete" onClick={(e)=> onClick && onClick(e, 'delete')}/>
            }
        </div>
    )
});

export default Controls;