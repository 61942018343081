import { hardLevelIcon } from '../../common/utils';
import { FaArrowLeft, FaArrowRight, FaYoutube } from 'react-icons/fa';
import {endsWithAny} from '../../common/format';
import { Loader } from '../loading';
import classNames from 'classnames';
import creditIcon2 from '../../images/medal.svg';
import { MdOutlineThumbDownOffAlt, MdOutlineThumbUpAlt, MdThumbDownAlt, MdThumbUpAlt } from 'react-icons/md';
// import bookIcon from '../../images/book.svg';
// import videoIcon from '../../images/video.svg';
import bookIcon from '../../images/reading.png';

const TestQuestion = ({tests, testData, setTestData, onNext, onBack, update, loading, openModal2, isPreview, resultData, showSelfMarked}) => {
    const hardLevel = (level) => {
        let text = 'Easy';
        switch(level) {
            case 1:
                text = 'Easy';
                break;
            case 2:
                text = 'Normal';
                break;
            case 3:
                text = 'Difficult';
                break;
            case 4:
                text = 'Hard';
                break;
            case 5:
                text = 'Intense';
                break;
        }
        return text;
    }

    const updateHandler = (type) => {
        type === 'feedback-none' ? setTestData({...testData, feedback: null}) : setTestData({...testData, feedback: type});
    }

    return (
        <div className={classNames(tests.testQuestion, 
        {[tests.w35]: testData && ["matching","ordering","openEnded"].includes(testData.answerType),
        [tests.w60]: testData && testData.answerType === "recording"})}>
            {loading && 
                <div style={{background: "white", height: '100%'}}>
                    <Loader />
                </div>
            }
            {
                !loading && testData &&
                <>
                    <div className={tests.topInfo}>
                        <div>
                            <div>
                                {
                                    testData.topics && Array.isArray(testData.topics) && 
                                    testData.topics.map(item => item.label).join(' ,') 
                                }
                                {
                                    testData.topics && testData.topics.length > 0 &&
                                    <span>|</span>
                                }
                                {
                                    testData.subTopics && testData.subTopics.length > 0 && 
                                    testData.subTopics.map(item => item.label).join(', ')
                                }
                                {
                                    testData.subTopics && testData.subTopics.length > 0 &&
                                    <span>|</span>
                                }
                                {testData.questionType && 
                                <>{testData.questionType.label}<span>|</span></>}
                                ID {testData.questionId}
                            </div>
                            
                            <h1>
                                <span>Question {testData.questionNumber}</span>
                                {
                                    !isPreview &&
                                    <div>
                                        {
                                            testData.feedback === 'feedback-like' &&
                                            <MdThumbUpAlt
                                                size={27} 
                                                color="#009CC8" 
                                                title='Remove feedback this question'
                                                onClick={()=> update('feedback-none', updateHandler('feedback-none'))}
                                            />
                                        }
                                        {
                                            testData.feedback !== 'feedback-like' &&
                                            <MdOutlineThumbUpAlt
                                                size={27} 
                                                color="#009CC8" 
                                                title='Like this question'
                                                onClick={()=> update('feedback-like', updateHandler('feedback-like'))}
                                            />
                                        }
                                        {
                                            testData.feedback === 'feedback-dislike' &&
                                            <MdThumbDownAlt
                                                size={27} 
                                                color="#009CC8" 
                                                title='Remove feedback this question'
                                                onClick={()=> update('feedback-none', updateHandler('feedback-none'))}
                                            />
                                        }
                                        {
                                            testData.feedback !== 'feedback-dislike' &&
                                            <MdOutlineThumbDownOffAlt
                                                size={27} 
                                                color="#009CC8" 
                                                title='Dislike this question'
                                                onClick={()=> update('feedback-dislike', updateHandler('feedback-dislike'))}
                                            />
                                        }
                                    </div>
                                }
                                
                            </h1>
                        </div>
                        <div>
                            <div className={tests.credit}>
                                {testData.credit || (testData.difficulty && testData.difficulty.label)} <img title={`${testData.credit} medals`} src={creditIcon2} height={30} />
                            </div>
                            <div>
                                <img title={`Hard Level: ${testData.difficulty && testData.difficulty.label}`} src={hardLevelIcon(testData.difficulty && testData.difficulty.label)} height={25} />
                                <p>{hardLevel(testData.difficulty && testData.difficulty.label)}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            testData.content && 
                            <div className={tests.content}>
                                <div className={tests.paragraph} id="inputsContent"
                                    dangerouslySetInnerHTML={{__html: testData.answerType === "text" && testData.noOfBlanks && testData.noOfBlanks > 0 
                                    ? testData.content.replace(/(_){3,}/g,'<span class="blankSpan"></span>') : testData.content}}/>
                                {
                                    testData.mediaUrl && !endsWithAny(['.mp3', '.ogg', '.wav', 'mp4'],testData.mediaUrl) &&
                                    <iframe
                                        frameBorder="0"
                                        width={'100%'}
                                        height={['mp3', 'm4a'].includes(testData.mediaType) ? 150 : '500'}
                                        src={testData.mediaUrl.replace(/\/view\?usp=sharing/g, '/preview').replace(/\/view/g, '/preview')}>
                                    </iframe>
                                }

                                {
                                    testData.mediaUrl && endsWithAny(['.mp3', '.ogg', '.wav'],testData.mediaUrl) &&
                                    <audio controls>
                                        <source src={testData.mediaUrl} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                }

                                {
                                    testData.mediaUrl && testData.mediaUrl.endsWith('mp4') &&
                                    <video width="320" height="240" controls>
                                        <source src={testData.mediaUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                }

                                {
                                    testData.options && testData.options.length > 0 && testData.answerType === "choice" &&
                                    <ul className={tests.contentOptions}>
                                        {
                                            testData.options.map(
                                                (item, index) => 
                                                    <li key={`option ${index}`}>
                                                        <span>{index + 1}</span>
                                                        <div dangerouslySetInnerHTML={{__html: item}} />
                                                    </li>
                                            )
                                        }
                                    </ul>
                                    
                                }
                                {
                                    resultData && resultData.correctAnswer && resultData.correctAnswer.length > 0 && resultData.questionType !== 'openEnded' &&
                                    <div className={tests.correctAnswer}>
                                        <h3>Correct Answer</h3>
                                        <ul>
                                            {
                                                resultData.correctAnswer && resultData.correctAnswer.length > 0 && resultData.correctAnswer.map((item, index) => item !=='' && <li key={`correct anwser ${index}`} dangerouslySetInnerHTML={{__html: testData && testData.answerType === 'choice' ? parseInt(item)+1 : item.replace(/(\|){2}/g, '<strong class="orOption">OR</strong>')}}></li>)
                                            }
                                        </ul>
                                    </div>
                                }
                                {
                                    resultData && resultData.explanation && !showSelfMarked &&
                                    <div className={tests.explanation}>
                                        <h3>Explanation</h3>
                                        <div dangerouslySetInnerHTML={{__html: resultData.explanation}}></div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={tests.buttons}>
                        {
                            testData.backQuestionId &&
                            <button className="btn" onClick={onBack}><FaArrowLeft /> Back</button>
                        }
                        {
                            !testData.backQuestionId &&
                            <div></div>
                        }
                        {
                            testData && testData.worksheetType !== "exam" && testData.materials &&
                            <div className={tests.materials}>
                                {
                                    testData.materials.fileId &&
                                    <img src={bookIcon} height="35" title="View Pdf" onClick={() => openModal2('file')}/>
                                }
                                {
                                    testData.materials.videoUrl &&
                                    <FaYoutube color="#bb0000" size="35" title="View Video" onClick={() => openModal2('video')}/>
                                }
                            </div>
                        }
                        {
                            testData.nextQuestionId && 
                            <>
                                <button className="btn" onClick={() => onNext && onNext(false)}><FaArrowRight /> Next</button>
                            </>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default TestQuestion;