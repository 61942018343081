import { MdBookmark, MdList, MdNote, MdShare, MdClose, MdReportProblem } from 'react-icons/md';
import classNames from 'classnames';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import { Loader } from '../../components/loading';
import {toast} from 'react-toastify';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaCheck, FaFastForward } from 'react-icons/fa';
import {ImExit} from 'react-icons/im';

const SideBar = ({tests, showModal, showList, setShowList, testData, onQuestionSelect, setTestData, setModalType,
    update, isPreview, worksheetId, learnId, learnType, updateList}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [loading, setLoading] = useState();
    const [showNoteBox, setShowNoteBox] = useState(false);
    const [newNoteContent, setNewNoteContent] = useState('');
    const [notesList, setNotesList] = useState([]);
    const [questionsList, setQuestionsList] = useState([]);

    useEffect(()=>{
        if(!showModal) {
            setQuestionsList(null);
            setShowNoteBox(false);
            setNewNoteContent('');
        }
    },[showModal]);

    useEffect(()=>{
        showList === "question" && getList("question");
        showList === "note" && getList("note");
    },[showList]);

    useEffect(()=>{
        updateList && getList('question', 'update');
    }, [updateList]);

    const getList = (type, isUpdate) => {
        !isUpdate && setLoading(true);
        const url = type === "question" ? API_URL.GET_USER_LIVE_TEST_LIST_URL : API_URL.GET_USER_LIVE_TEST_NOTE_LIST_URL;
        return axios.get(url,{
            headers: authHeader(),
            params:{
                // subject,
                worksheetId,
                questionId: type === "note" ? testData && testData.questionId : undefined,
                isBookmark: type === "question" ? showList === "bookmark" : undefined,
                learnId: learnId ? learnId : undefined,
                learnType: learnType ? learnType : undefined
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.list) {
                type === "question" && setQuestionsList(response.data.list);
                type === "note" && typeof response.data.list === 'string' && setNotesList(JSON.parse(response.data.list));
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const updateSuccess = (type, item) => {
        if (type === "addNote") {
            const newNote = {
                content: newNoteContent
            };
            notesList.push(newNote);
            setNotesList([...notesList]);
            setShowNoteBox(false);
        }

        if (type === "removeNote") {
            const index = notesList.indexOf(item);
            notesList.splice(index,1);
            setNotesList([...notesList]);
        }

        if (type === "bookmark") {
            setTestData({...testData, isBookmarked: !testData.isBookmarked})
        }
    }

    const changeControlView = (type) => {
        if(showList === type) {
            setShowList(null);
        } else {
            setShowList(type);
            setLoading(true);
        }        
    }

    const questionSelect = (id, index) => {
        onQuestionSelect && onQuestionSelect(id, index);
    }

    return (
        <>
            <div className={classNames(tests.barItems,{[tests.showList]: showList, [tests.note]: showList === "note"})}>
                <ImExit title='Back to previous page' onClick={() => window.history.back()} size={22} />
                {
                    testData && testData.viewQuestionsList &&
                    <MdList title='List Of Questions' className={showList === 'question' ? tests.active : undefined} onClick={() => changeControlView('question')}/>
                }
                {
                    !isPreview &&
                    <>
                        <MdBookmark title={`${testData && testData.isBookmarked ? 'Unbookmark': 'Bookmark'} this question`} 
                            onClick={() => update('bookmark',updateSuccess)} className={testData && testData.isBookmarked ? tests.active : undefined} />
                        <MdShare title='Share this question to a friend' onClick={() => setModalType && setModalType('share')}/>
                        <MdNote title='List of Notes' onClick={() => changeControlView('note')} className={showList === "note" ? tests.active : undefined}/>
                        <MdReportProblem title='Report Problem' onClick={() => setModalType && setModalType('report-problem')}/>
                    </>
                }
            </div>
            {
                showNoteBox &&
                <div className={tests.addNoteBox}>
                    <div className={tests.title}>
                        New Note
                        <MdClose onClick={()=> setShowNoteBox(false)}/>
                    </div>
                    <div>
                        <textarea onChange={e => setNewNoteContent(e.target.value)}/>
                        <div className='buttons'>
                            <button className='btn' onClick={() => update('addNote', updateSuccess, null, notesList)} disabled={!newNoteContent}>Save</button>
                        </div>
                    </div>
                </div>
            }
            <div className={classNames(tests.questionsList,{[tests.open]: showList, [tests.note]: showList === "note"})}>
                {
                    loading && <Loader />
                }
                {
                    !loading && showList === "note" && notesList && 
                    <ul>
                        {notesList.length > 0 && notesList.map((item, index) => 
                            <li className={tests.noteItem} key={`note${index}`}>
                                <div className={tests.time}><MdClose onClick={() => update('removeNote',updateSuccess, item, notesList)}/></div>
                                <div className={tests.noteContent}>{item.content}</div>
                            </li>)
                        }
                        <li className={tests.new} onClick={() => setShowNoteBox(true)}>Add Note</li>
                    </ul>
                }
                {
                    !loading && questionsList.length > 0 && showList === "question" &&
                    <ul>
                        {
                            questionsList.map((item, index) => 
                            <li key={`questionList${index}`}>
                                {
                                    item.name && questionsList.length > 1 && 
                                    <div>{item.name} <span>{item.noOfQuestions}</span></div>
                                }
                                
                                {
                                    item.name && questionsList.length === 1 && 
                                    <div className={tests.total}>Total <span>{item.noOfQuestions}</span></div>
                                }

                                {
                                    item.questions && item.questions.length > 0 &&
                                    <ul>
                                        {
                                            item.questions.map((itm,i) => 
                                                <li key={`questionItem${i}`} className={classNames({[tests.active] : testData && itm.questionId === testData.questionId}, tests[itm.status])}
                                                    onClick={() => questionSelect(itm && itm.questionId, i)}
                                                >
                                                    {(itm.status === 'inprogress' || itm.status === "finished") && (i + 1)}
                                                    {itm.status === 'skip' && <FaFastForward size={14}/>}
                                                    {itm.status === "correct" && <FaCheck size={16}/>}
                                                    {itm.status === "incorrect" && <MdClose size={20}/>}
                                                </li>
                                            )
                                        }
                                    </ul>
                                    
                                }
                            </li>
                            )
                        }
                    </ul>
                }
            </div>
        </>
    )
}

export default SideBar;