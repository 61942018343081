import { useRef, useState, useEffect } from 'react';
import { Loader } from '../../../components/loading';
import Input from '../../../components/input';
import { MdFilterList } from 'react-icons/md';
import HistoryFilter from './dmHistoryFilter';
import useClickOutside from '../../../hooks/useClickOutside';
import bookIcon from '../../../images/reading.png';
import worksheetIcon from '../../../images/ws.svg';
import { FaCheck, FaYoutube } from 'react-icons/fa';
import { checkTouchDevice } from '../../../common/utils';

const DmHistory = ({styles, data, loading, setFilters, filters, openModal}) => {
    const [filter, setFilter] = useState(filters);
    const searchOnChange = (e) => setFilter({...filter, search: e.target.value});
    const [showFilter, setShowFilter] = useState(false);
    const ref = useRef();
    useClickOutside(ref, () => setShowFilter(false));

    useEffect(() => {
        filter && filter.search && filter.search.length > 2 && checkTouchDevice() && setFilters(filter);
    }, [filter && filter.search]);

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            filter && filter.search && filter.search.length > 0 && setFilters(filter);
        }
    }
    
    return (
        <div className={styles.history}>
            <div className='flex'>
                <div className={styles.searchBox}>
                    <Input
                        type="text" 
                        placeholder={`${checkTouchDevice() ? 'Type at least 3 characters to search' : 'Type and press Enter/Return to search'}`} 
                        className={styles.searchBox}
                        onChange={searchOnChange}
                        value={filter.search}
                        label="Search text"
                        onKeyPress={handleKeyPress}
                    />
                </div>
                <div className={styles.historyFilter}>
                    <a href="#" onClick={() => setShowFilter(true)}><MdFilterList /> Filter</a>
                    {
                        showFilter &&
                        <HistoryFilter
                            styles={styles} 
                            filters={filters}
                            setFilters={setFilters}
                            ref={ref}
                            setShowFilter={setShowFilter}
                        />
                    }
                </div>
            </div>
            
            <div className={styles.heading}>
                <span>Title</span>
                <span>Create date</span>
                <span>Due date</span>
                <span>Submitted date</span>
                <span>Score</span>
                <span>Credits received</span>
                <span>Status</span>
                <span>&nbsp;</span>
            </div>
            {
                loading && <Loader />
            }
            {
                !loading &&
                <>
                    {
                        data.length === 0 &&
                        <div className='noResult'>No item found.</div>
                    }
                    {
                        data.length > 0 &&
                        <ul>
                            {
                                data.map((item,index) => 
                                <li key={`row ${index}`}>
                                    <span><i className={item.type === "combo" ? styles.combo : ''}>{item.type === "combo" ?  'C' : 'WS'}</i>{item.title}</span>
                                    <span>{item.createdDate}</span>
                                    <span>{item.dueDate}</span>
                                    <span>{item.submittedDate}</span>
                                    <span>{item.score}</span>
                                    <span>{item.credit}</span>
                                    <span>{item.status}</span>
                                    <span>
                                        {
                                            item.readingId &&
                                            <span>
                                            <img src={bookIcon} height="28" title="Reading" onClick={() => openModal('file', item)}/>
                                            {
                                                item.readingDone && <FaCheck className={styles.check} />
                                            }
                                            </span>
                                        }
                                        {
                                            item.videoId &&
                                            <span>
                                            <FaYoutube color="#bb0000" size="30" title="View Video" onClick={() => openModal('video', item)}/>
                                            {
                                                item.videoDone && <FaCheck className={styles.check} />
                                            }
                                            </span>
                                        }
                                        {
                                            item.worksheetId &&
                                            <a href={`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`}>
                                                <img src={worksheetIcon} height="25" title="Play worksheet" />
                                                {
                                                    item.worksheetDone && <FaCheck className={styles.check} />
                                                }
                                            </a>
                                        }
                                    </span>
                                </li>
                                )
                            }
                        </ul>
                    }
                </>
            }
        </div>
    )
}

export default DmHistory;