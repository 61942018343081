import { useState } from 'react';

export const useGlobalStates = () => {
    const cartNo = parseInt(localStorage.getItem('EduTech-CartNumber'));
    const savedItems = localStorage.getItem('EduTech-CartItems');
    const savedUser = JSON.parse(localStorage.getItem('user'));
    const readInnit = {
        connectionInfo : localStorage.getItem('connection-info-read') ? JSON.parse(localStorage.getItem('connection-info-read')) : false,
        connectionRequest: localStorage.getItem('connection-request-read') ? JSON.parse(localStorage.getItem('connection-request-read')) : false,
        alerts : localStorage.getItem('alerts-read') ? JSON.parse(localStorage.getItem('alerts-read')) : false
    }

    let cartItm = [];
    if (savedItems) {
        cartItm = JSON.parse(savedItems);
    }
    
    const [cartNumber, setCartNumber] = useState(cartNo ? cartNo : 0);
    const [cartItems, setCartItems] = useState(cartItm && cartItm.length > 0 ? cartItm : []);
    const [user, setUser] = useState(savedUser ? savedUser : null);
    const [clickCount, setClickCount] = useState(0);
    const [isRead, setIsRead] = useState(readInnit);
    const [alertsCount, setAlertsCount] = useState(0);
    const [notesListPositionValue,setNotesListPositionValue] = useState([]);
    const [noteFormPositionValue,setNoteFormPositionValue] = useState({x: 0, y: 0});
    const [currentMenu, setCurrentMenu] = useState([]);
    const [showBox, setShowBox] = useState(true);
    const [activeSubTab, setActiveSubTab] = useState('');
    const url = new URL(window.location.href);
    let tab = decodeURIComponent(url.searchParams.get('tab'));
    const [activeTab, setActiveTab] = useState(tab !== "null" ? tab : null);
    const [toastContent, setToastContent] = useState('');
    const [moreInfo, setMoreInfo] = useState({credit: 0, showDailyClaimButton: false});

    const [customValue, setCustomValue] = useState({
        color: null,
        images: [],
          box: {
            textColor: null,
            boxColor: null,
            boxColorOpacity: 1,
            effect: 0,
            shadowColor: null
          }
      });

    return {
        cartNumber, setCartNumber, cartItems, setCartItems, user, setUser, 
        clickCount, setClickCount, isRead, setIsRead, activeTab, setActiveTab,
        alertsCount, setAlertsCount, noteFormPositionValue, setNoteFormPositionValue,
        notesListPositionValue, setNotesListPositionValue,
        currentMenu, setCurrentMenu, showBox, setShowBox, activeSubTab, setActiveSubTab,
        toastContent, setToastContent, moreInfo, setMoreInfo, customValue, setCustomValue,
    }
}