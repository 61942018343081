import { useState, useEffect, useRef, useMemo } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import { useHistory } from "react-router-dom";
import GiftItem from "./giftItem";
import Modal from '../../../components/modal';
import NewGift from './giftsNew';
import Report from './report';

const GiftsList = ({connection, curTab}) => {
    const [loading, setLoading] = useState(null);
    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState();
    const [modalType, setModalType] = useState('');
    const contentRef = useRef();
    const [curId, setCurId] = useState('');
    const {setUser, moreInfo} = useStore();
    const history = useHistory();
    const [newGift, setNewGift] = useState({
        privacy: 'public',
        toUsers: '',
        type: moreInfo.credit > 0 ? 'credit' : 'others',
        credit: 0, 
        quantity: 0, 
        imageUrl: "",
        name: "", 
        message: ""
    });
    const [curItem, setCurItem] = useState(null);

    useEffect(()=>{
        if (modalType === "edit" && curItem) {
            newGift.privacy = curItem.privacy;
            newGift.toUsers = curItem.toUsers;
            newGift.type = curItem.type;
            newGift.credit = curItem.credit;
            newGift.quantity = curItem.quantity;
            newGift.imageUrl = curItem.imageUrl;
            newGift.name = curItem.name;
            newGift.message = curItem.message;
            setNewGift({...newGift});
        } else {
            newGift.privacy = 'public';
            newGift.toUsers = '';
            newGift.type = moreInfo.credit > 0 ? 'credit' : 'others';
            newGift.credit = 0;
            newGift.quantity = 0;
            newGift.imageUrl = '';
            newGift.name = '';
            newGift.message = '';
            setNewGift({...newGift});
        }
    },[modalType, curItem]);

    useEffect(()=>{
        !showModal && setNewGift({
            privacy: 'public',
            toUsers: '',
            type: moreInfo.credit > 0 ? 'credit' : 'others',
            credit: 0, 
            quantity: 0, 
            imageUrl: "",
            name: "", 
            message: ""
        })
    },[showModal]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.GET_GIFT_LIST_URL,{
            headers: authHeader(),
            params: {
                // type: curTab
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.list && setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    useMemo(()=>{
        curTab && getList();
    },[curTab]);

    const onReport = (content) => {
        if (!content) {
            toast.error('Report content is empty!');
            return;
        }

        axios.post(API_URL.POST_GIFT_ACTION_URL,{action: "report", productId: curId, content},{
            headers: authHeader(),
        })
        .then(response => {
            setShowModal(false);
            toast.success('Your report messsage has been sent, we will check it and feedback later. Thanks');
        })
        .catch(error => {
            const resMessage = getError(error);
            setShowModal(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    };

    const newGiftOnClick = () => {
        setShowModal(true);
        setModalType('new');
    }

    return (
        <div className={connection.giftsList}>
            <div className={connection.button}>
                <button onClick={newGiftOnClick}><span>+</span> Gift</button>
            </div>
            {
                loading && <Loader />
            }
            
            {
                !loading && list && list.length > 0 &&
                <div>
                    <ul>
                    {
                        list.map((item, index) => 
                            <GiftItem 
                                item={item} 
                                connection={connection} 
                                key={`gift-${index}`}
                                setShowModal={setShowModal}
                                setModalType={setModalType}
                                setCurId={setCurId}
                                list={list}
                                setList={setList}
                                setCurItem={setCurItem}
                            />
                        )
                    }
                    </ul>
                </div>
            }
            <Modal 
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width={modalType === "report" ? "500px" : "800px"}
                height={"auto"}
                disabledClickOutside={modalType === "generating"}
                noScroll={modalType === "reactionList"}
                content={
                    <div ref={contentRef}>
                        {
                            (modalType === "new" || modalType === "edit") &&
                            <NewGift 
                                connection={connection}
                                list={list}
                                setList={setList}
                                setShowModal={setShowModal}
                                newGift={newGift}
                                setNewGift={setNewGift}
                                curItem={curItem}
                            />
                        }
                        {
                            modalType === "report" && curId && 
                            <Report
                                connection={connection} 
                                onReport={onReport}
                            />
                        }
                    </div>
                }
            />
        </div>
    )
}

export default GiftsList;