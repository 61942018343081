import {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import dashboard from './dashboard.module.scss';
import Chart from './chart';
import {MdQuestionAnswer, MdAccessTime, MdGroupWork} from 'react-icons/md';
import {uniqueId} from 'lodash';
import {FaCommentDots} from 'react-icons/fa';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import Filter from './filter';
import Students from './studentsTable';
import useClickOutside from '../../../hooks/useClickOutside';
import { useClickAnyWhere } from '../../../hooks/useClickOutside';

const LiveClass = () => {
    const history = useHistory();
    const {setUser} = useStore();
    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [grade, setGrade] = useState();
    const [filterGrade, setFilterGrade] = useState();
    const [filterSubject, setFilterSubject] = useState();
    const [filterClassName, setFilterClassName] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [currentFeedback, setCurrentFeedback] = useState('');

    useClickAnyWhere(() => setCurrentFeedback(''));
    useEffect(()=>{
        let isCancelled = false;
        axios.get(API_URL.GET_USER_DASHBOARD_GRADES_FILTER_URL,{
            headers: authHeader()})
        .then(response => {
            if(response.data && !isCancelled) {
                response.data.grade && setGrade(response.data.grade); 
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
        return () => {
            isCancelled = true;
          };
    },[]);

    useEffect(()=>{
        getData(true);
    },[filterGrade, filterSubject, dateFrom, dateTo, filterClassName]);

    const getData = (condition) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_DASHBOARD_LIVE_CLASS_URL,{
            headers: authHeader(),
            params: {
                grade: filterGrade && filterGrade.value,
                subject: filterSubject && filterSubject.value,
                fromDate: dateFrom,
                toDate: dateTo,
                classId: filterClassName && filterClassName.value
            }
        })
        .then(response => {
            if(response.data && condition) {
                 response.data && setData(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onGradeChange = type => setFilterGrade(type);
    const onSubjectChange = type => setFilterSubject(type);
    const onClassNameChange = type => setFilterClassName(type)

    const CommentItem = ({type}) => {
        return (
            <>
                <FaCommentDots onClick={()=>setCurrentFeedback(type)} className={currentFeedback === type ? dashboard.active : ''}/>
                <div className={dashboard.comments}>
                    {data.feedbackForClass[type] && data.feedbackForClass[type].comments && data.feedbackForClass[type].comments.map(comment => <div key={uniqueId()}>{comment}</div>)}
                </div>
            </>
        )
    }

    return (
        <div className={dashboard.dashboard}>   
            {grade && grade.length && 
                <Filter 
                    onGradeChange={onGradeChange}
                    onSubjectChange={onSubjectChange}
                    grades={grade}
                    subject={filterSubject}
                    filterGrade={filterGrade}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    className={filterClassName}
                    onClassNameChange={onClassNameChange}
                />
            }  
             {loading && <Loader />}
             {!loading && data &&
                <>                
                <div className={dashboard.topInfo}>
                    <div className={dashboard.boxes}>
                        <div>
                            <label><MdGroupWork size="20"/>Students attended class</label>
                            <div>
                            <strong>{data.studentsAttendedClass}</strong>
                            </div>    
                        </div>
                        <div>
                            <label><MdQuestionAnswer size="20"/>Questions in class</label>
                            <div>
                            <strong>{data.questionsInClass}</strong>
                            </div>    
                        </div>
                        <div>
                            <label><MdAccessTime size="20"/>Time students spent on questions</label>
                            <div>
                            <strong>{data.questionsTime}<span>mins</span></strong>
                            </div>    
                        </div>
                    </div>
                </div>
                <div className={dashboard.charts}>
                    <div className={dashboard.pieChart}>
                        <label>questions by skills/ topics</label>
                        <div>
                            <Chart data={data.questionsBySkills} height={180} chartType="pie" />
                        </div>
                    </div>
                    <div className={dashboard.pieChart}>
                        <label>Students' answer result</label>
                        <div>
                            <Chart data={data.studentsResult} height={180} chartType="column" />
                        </div>
                    </div>
                    <div className={dashboard.feedback}>
                        <label>Feedback for the class</label>
                        <div className={dashboard.list}>
                            {
                                data.feedbackForClass && data.feedbackForClass.excellent &&
                                <div className={dashboard.excellent}>
                                    <span>{data.feedbackForClass.excellent.value}</span>
                                    <label>Excellent</label>
                                    {
                                        data.feedbackForClass.excellent.comments && data.feedbackForClass.excellent.comments.length > 0 &&
                                        <CommentItem type="excellent" />
                                    }
                                </div>
                            }
                            {
                                data.feedbackForClass && data.feedbackForClass.good &&
                                <div className={dashboard.good}>
                                    <span>{data.feedbackForClass.good.value}</span>
                                    <label>Good</label>
                                    {
                                        data.feedbackForClass.good.comments && data.feedbackForClass.good.comments.length > 0 &&
                                        <CommentItem type="good" />
                                    }
                                </div>
                            }
                            {
                                data.feedbackForClass && data.feedbackForClass.normal &&
                                <div className={dashboard.normal}>
                                    <span>{data.feedbackForClass.normal.value}</span>
                                    <label>Normal</label>
                                    {
                                        data.feedbackForClass.normal.comments && data.feedbackForClass.normal.comments.length > 0 &&
                                        <CommentItem type="normal" />
                                    }
                                </div>
                            }
                            {
                                data.feedbackForClass && data.feedbackForClass.needImprovement &&
                                <div className={dashboard.needImprovement}>
                                    <span>{data.feedbackForClass.needImprovement.value}</span>
                                    <label>Need Improvement</label>
                                    {
                                        data.feedbackForClass.needImprovement.comments && data.feedbackForClass.needImprovement.comments.length > 0 &&
                                        <CommentItem type="needImprovement" />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Students list={data.studentsList}/>
                </>
             }
        </div>
    )
}

export default LiveClass;