import { useState, useEffect, useRef } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import { useHistory } from "react-router-dom";
import CreditExchangeItem from "./creditExchangeItem";

const CreditExchangeList = ({connection, setCurTab}) => {
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const {setUser} = useStore();
    const history = useHistory();
     
    useEffect(()=>{
        getList();
        return () => {
            setList([]);
          };
    },[]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_CREDIT_EXCHANGE_LIST_URL,{
            headers: authHeader(),
            params: {
                // type: curTab
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.list && setList(response.data.list);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    return (
        <div className={connection.giftsList}>
            {
                loading && <Loader />
            }
            {
                !loading && list && list.length === 0 &&
                <div className='noResult'>No item found.</div>
            }
            {
                !loading && list && list.length > 0 &&
                <div>
                    <ul>
                    {
                        list.map((item, index) => 
                            <CreditExchangeItem 
                                item={item} 
                                connection={connection} 
                                key={`credit-exchange-${index}`}
                                setCurTab={setCurTab}
                            />
                        )
                    }
                    </ul>
                </div>
            }
        </div>
    )
}

export default CreditExchangeList;