import { useState } from "react";
import {uniqueId} from "lodash";

const Themes = ({lib, themes, currentTheme, onChange}) => {
    const [isExpand, setIsExpand] = useState(false);

    return (
        <div className={lib.themes}>
            <label>Worksheet Theme</label>
            <ul className={!isExpand ? lib.collapse : ''}>
                {
                    themes && themes.length > 0 && 
                    themes.map(theme => 
                        <li className={`${!theme.isAvailable ? lib.disabled : ''}
                        ${currentTheme === theme.themeId ? lib.active : ''}`}
                        onClick={() => onChange && onChange(theme.themeId)}
                        key={uniqueId()}
                        >
                            <div className={lib.theme}
                            style={{backgroundImage: `url(${(theme.thumnailImage ?  theme.thumnailImage : '')})`,
                                    backgroundColor: '#E3C136'}}>
                            </div>
                            <div>{theme.name}</div>
                        </li>    
                    )
                }
                {/* <li className={`${lib.newTheme} ${lib.disabled}`}>+</li> */}
            </ul>
            {/* {
                !isExpand &&
                <a href="#" onClick={()=>setIsExpand(true)}>View more</a>
            }
            {
                isExpand &&
                <a href="#" onClick={()=>setIsExpand(false)}>View less</a>
            } */}
        </div>
    )
}

export default Themes;