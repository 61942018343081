import { Link } from 'react-router-dom';
import styles from '../styles.module.scss';
import worksheetbg from '../../../images/worksheet.svg';
import files from '../../../images/files.svg';
import questions from '../../../images/questions.svg';
import { parseRgb } from '../../../common/utils';
import classNames from 'classnames';

const Tabs = ({activeTab, setActiveTab, customValue, setActiveParent, activeParent}) => {
    const tabOnClick = (tab) => {
        setActiveTab(tab);
        !activeParent && setActiveParent && setActiveParent('myLib');
    }
    return(
        <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
            <div style={{width: 400}}>
                <div className={styles.color1}
                 style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}
                >
                    <Link 
                        to="#" 
                        onClick={() => tabOnClick('worksheets')} 
                        className={activeTab === "worksheets" ? styles.active : ""}
                        style={{height: 400, background:`url(${worksheetbg}) no-repeat center 95%/320px`}}
                    >
                        <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Worksheets</strong>
                    </Link>
                </div>
            </div>
            <div style={{width: 300}}>
                <div className={styles.color2} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                <Link
                    to="#" 
                    onClick={() => tabOnClick('files')} 
                    className={activeTab === "files" ? styles.active : ""}
                    style={{height: 190, marginBottom: 10, background:`url(${files}) no-repeat center 95%/contain`}}
                >
                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Courseware</strong>
                </Link>   
                </div>
                <div className={styles.color4} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                <Link 
                    to="#" 
                    onClick={() => tabOnClick('questions')} 
                    className={activeTab === "questions" ? styles.active : ""}
                    style={{height: 200, background:`url(${questions}) no-repeat center 95%/200px`}}
                >
                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Questions</strong>
                </Link>
                </div>
            </div>
        </div>       
    )
}

export default Tabs;