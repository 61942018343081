import RichEditor2 from '../../../components/editor/editor2';

const QuestionStep4 = ({lib, formData, setFormData}) => {
    const onExplanationChange = (data) => setFormData({...formData, explanation: data});

    return (
        <div className={lib.autoScroll}>
            <RichEditor2 
                label="Expanation"
                onChange={onExplanationChange}
                editorData={formData.explanation}
                className={lib.editorContent}
                isFull={true}
                isOptional={formData.questionType !== 'openEnded' && formData.openEndedType !== 'Self-marked'}
            />
        </div>
    )
}

export default QuestionStep4;