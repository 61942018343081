import React, {useRef, useState, useEffect} from 'react';
import modal from './modal.module.scss';
import classNames from 'classnames';
import { MdOutlineFullscreen, MdOutlineFullscreenExit } from 'react-icons/md';

const Modal = ({showModal, content="", setShowModal, width, maxWidth, height, noScroll, 
disabledClickOutside, isTestForm, hideCloseBtn, isExpandable, firstExpand, bgSet, type}) => {
    const contentRef = useRef();
    const [inExpand, setInExpand] = useState(false);
    const modalClick = (e) => {
        if (!contentRef.current.contains(e.target)) setShowModal(false);
    }

    const closeButtonOnClick = () => {
        setShowModal(false);
    }

    useEffect(()=>{
        !isExpandable && setInExpand(false);
    },[isExpandable]);

    useEffect(()=>{
        if(firstExpand) {
            setInExpand(true);
        } else {
            setInExpand(false);
        }
    },[firstExpand]);

    return (
        <div 
            className={classNames(modal.modal, {[modal.rightModal]:type === "rightModal"})}
            style={{display: showModal ? '' : 'none'}} 
            onClick={!disabledClickOutside ? modalClick : null}
        >
            <div 
                className={classNames(modal.modalContent, {[modal.noScroll]: noScroll, [modal.testFormModal]: isTestForm})}
                ref={contentRef}
                style={{width: isTestForm || inExpand ? '100%' : width ? width : 'auto', 
                maxWidth: isTestForm || inExpand ? '100%' : maxWidth ? maxWidth : 'auto', 
                height: isTestForm || inExpand ? '100vh' : height ? height : 'auto', 
                maxHeight: isTestForm || inExpand ? '100vh' : '', background: bgSet && bgSet.background}}
            >
                {
                    !hideCloseBtn &&
                    <button style={{color: bgSet && bgSet.color}} className={classNames(modal.close,{[modal.whiteClose]: isTestForm})} onClick={closeButtonOnClick}>&times;</button>
                }
                {
                    isExpandable && !inExpand &&
                    <MdOutlineFullscreen style={{color: bgSet && bgSet.color}} onClick={() => setInExpand(true)} className={modal.expand}/>
                }
                {
                    isExpandable && inExpand &&
                    <MdOutlineFullscreenExit style={{color: bgSet && bgSet.color}} onClick={() => setInExpand(false)} className={modal.expand}/>
                }
                {content}
                
            </div>
            
        </div>
    )
}

export default Modal;