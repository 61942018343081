import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import { getError } from '../../common/utils';
import Input from '../../components/input';
import { UploadFile } from './questionTypes';
import classNames from 'classnames';
import RichEditor2 from '../../components/editor/editor2';

const ManualMarkForm = ({meeting, item, assignId, studentId,getAssigment, setCurrentQuestionId, getQuestionContent}) => {
    const history = useHistory();
    const {setUser, user} = useStore();
    const [formData, setFormData] = useState({
        score: 0,
        comment: '',
        fileUrl: ''
    });

    const onSubmit = () => {
        if(!formData.score) {
            toast.error('Please input score');
            return;
        }

        const url = API_URL.POST_USER_MANUAL_MARK_URL;
        axios.post(url,formData,{
            headers: authHeader(),
            params: {
                assignId: assignId,
                questionId: item.questionId,
                studentId: studentId
            }
        })
        .then(response => {
            setCurrentQuestionId && setCurrentQuestionId(item.questionId);
            getAssigment && getAssigment();
            getQuestionContent && getQuestionContent(item.questionId);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const scoreOnChange = e => setFormData({...formData, score: e.target.value});
    const commentOnChange = value => setFormData({...formData, comment: value});

    return(
        <div className={meeting.manualMarkForm}>  
            <h3>{item.result.includes('%') ? 'Re-mark Form' : 'Mark Form'}</h3>                  
            <div className={classNames(meeting.form, meeting.showForm)}>
                
                    <div className={meeting.flex}>
                        <div>
                            <label>Score</label>
                            <Input
                                className={meeting.score}
                                type="number"
                                min={0}
                                max={100}
                                placeholder=''
                                onChange={scoreOnChange}
                            />
                            <span className={meeting.maxScore}>/ 100</span>
                        </div>
                        <UploadFile markData={formData} setMarkData={setFormData} />
                    </div>
                    <div className={meeting.control}>
                        <RichEditor2
                            label="Comment"
                            onChange={commentOnChange}
                            editorData={formData.comment}
                        />
                    </div>
                    <div className={meeting.buttons}>
                        <button className="btn" onClick={onSubmit}>Submit</button>
                    </div>
                </div>
        </div>
    )
}

export default ManualMarkForm;