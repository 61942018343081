import { useRef, useState } from 'react';
import ContactUs from '../contactUs';
import styles from './styles.module.scss';
import Modal from '../../components/modal';

const ContactUsBox = ({data}) => {
    const [openPdf, setOpenPdf] = useState(null);
    const contentRef = useRef();

    return (
        <div className={styles.contactUs}>
            <p id='contactUs'></p>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            <div className={styles.content}>
                {
                    data && data.content1 &&
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data.content1}}/>
                        
                        <div className='homeBtns buttons'>
                        {
                            data.userGuidePdf &&
                                <button className='homeBtn btn' onClick={() => setOpenPdf(data.userGuidePdf)}>See User Guide</button>
                        }
                        {
                            data.faqPdf &&
                            <div className='homeBtns'>
                            <button className='homeBtn btn' onClick={() => setOpenPdf(data.faqPdf)}>Frequently Asked Questions</button>
                            </div>
                        }
                        </div>
                    </div>
                }
                {
                    data && data.content2 &&
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data.content2}}/>
                        <ContactUs fromHome />
                    </div>
                }
                {
                    data && data.content3 &&
                    <div dangerouslySetInnerHTML={{__html: data.content3}}></div>
                }
            </div>
            {
                openPdf &&
                <Modal
                    setShowModal={setOpenPdf} 
                    showModal={openPdf}
                    width={'100%'}
                    height={'100%'}
                    contentRef={contentRef}
                    content={
                        <div ref={contentRef} className={styles.viewPdf}>
                           <iframe width="100%"
                                height="98%"
                                src={openPdf}>
                            </iframe>
                        </div>
                    }
                />
            }
        </div>
    )
}

export default ContactUsBox;