import { useEffect, useState, useRef } from 'react';
import QuestionDetails from '../../../components/questionDetails';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError, handleKeyPress } from '../../../common/utils';
import { useStore } from "../../../store/useStore";
import { useHistory } from "react-router";
import { Logout } from "../../../services/auth.service";
import API_URL from '../../../common/urls';
import { Loader } from '../../../components/loading';
import { MdDelete, MdEdit } from 'react-icons/md';
import FullModal from '../../../components/modal/fullModal';
import NewItems from './newItem'; 

const WorksheetReview = ({lib, formData, setFormData, id, type, setShowModal, importId, setSelectQItem, setShowQuestionForm, setIsClone, listQuestions}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [curQuestionList, setCurQuestionList] = useState([]);
    const [wsSeudo, setWsSeudo] = useState({});
    const [curQuestionId, setCurQuestionId] = useState(id && type === "question" ? id :  
        formData && formData.questions && formData.questions.length ? (formData.questions[0].id || formData.questions[0].tempQuestionId) : null);
    const [curQuestion, setCurQuestion] = useState({});
    const [showModalEdit, setShowModalEdit] = useState(false);
    const contentRefEdit = useRef();
    const [saving, setSaving] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [selectItem, setSelectItem] = useState(null);
    const inputRef = useRef();
    
    useEffect(()=>{
        curQuestionId && curQuestionId.length !== 36 && setCurQuestion(formData && formData.questions.filter(item => (item.id === curQuestionId || item.tempQuestionId === curQuestionId))[0]); // review in edit worksheet
        !formData && !curQuestionId && getContent(type, id); // item view
        curQuestionId && curQuestionId.length === 36 && getContent('question', curQuestionId);
    },[curQuestionId]);

    useEffect(()=>{
        curQuestionList && curQuestionList.length > 0 && setCurQuestion(curQuestionList[0]);
        curQuestionList && curQuestionList.length === 0 && !curQuestionId && setCurQuestion(null);
    },[curQuestionList]);

    useEffect(() => {
        reload && getContent(type, id);
    }, [reload]);

    const getContent = (type, questionId) => {
        setLoading(true);
        return axios.get(API_URL.GET_LIBRARY_ITEM_CONTENT_URL,{
            headers: authHeader(),
            params: {
                type: type,
                id: questionId ? questionId : id ? id : null,
                importId,
            }
        })
        .then(response => {
            setLoading(false);

            type === "question" && response.data && setCurQuestion(response.data);
            type !== "question" && response.data && response.data.questions && setCurQuestionList(response.data.questions);
            type !== 'question' && response.data && setWsSeudo(response.data);
            type !== "question" && response.data && response.data.questions && response.data.questions.length && 
            !curQuestionId && setCurQuestionId(response.data.questions[0].id)
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onImportAction = (action, id, importId, formDataQuestions) => {
        return axios.post(API_URL.POST_LIBRARY_IMPORT_ACTION,
            {action, id, importId},{
            headers: authHeader()
        })
        .then(response => {
            if (action === "delete all") {
                toast.success("All questions in import have been deleted!");
                setShowModal && setShowModal(false);
            }

            if (action === "delete" && id) {
                const qlIds = curQuestionList && curQuestionList.length && curQuestionList.map(item => item.id);
                const qlIdsFormData = formDataQuestions && formDataQuestions.length && formDataQuestions.map(item => item.id);
                if (qlIds && qlIds.includes(id)) {
                    const index = qlIds.indexOf(id);
                    curQuestionList.splice(index, 1);
                    setCurQuestionList([...curQuestionList]);
                }

                if (formData && qlIdsFormData.includes(id)) {
                    const index = qlIdsFormData.indexOf(id);
                    formDataQuestions.splice(index, 1);
                    setFormData && setFormData({...formData, questions: formDataQuestions});
                }
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onEdit = (question) => {
        if (formData) {
            setSelectQItem && setSelectQItem(question);
            // setShowModalEdit(true);
            setShowQuestionForm && setShowQuestionForm(true);
        } else {
            setShowModalEdit(true);
            setSelectItem(question);
        }
        
        setIsClone && setIsClone(false);
    }

    const onHandleKeyPress = (e) => {
        let list = [];
        if (type === "question" && listQuestions) {
            list = listQuestions;
        } 
        
        if (type !== "question" &&curQuestionList && curQuestionList.length){
            list = curQuestionList;
        }

        const questionListId = list && list.length && list.map(item => item.id || item.questionId);
        if (questionListId && questionListId.length && questionListId.includes(curQuestionId)) {
            const idx = questionListId.indexOf(curQuestionId);
            if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
                idx < questionListId.length - 1 && setCurQuestionId(questionListId[idx+1]);
            }
    
            if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
                idx > 0 && setCurQuestionId(questionListId[idx-1]);
            }
        }
    }
    return (
        <div className={lib.wsReview || (curQuestionList && curQuestionList.length > 0) ? "flex" : ''} onClick={() => inputRef.current.focus()}>
             <input value='' ref={inputRef} autoFocus onKeyDown={onHandleKeyPress} style={{background: 'transparent', height: 1, padding: 0, width: 20, border: 'none'}}/> {/* This input just using for onkeyDown purpose */}
            {
                formData && 
                <div className={lib.questionsListReview}>
                    {
                        formData.questions && formData.questions.length > 0 &&
                        <ul>
                            {
                                formData.questions.map((question, index) => 
                                    <li key={`question${index}`} className={curQuestionId === question.id || curQuestionId === question.tempQuestionId ? lib.active : undefined}
                                    onClick={() => setCurQuestionId(question.id || question.tempQuestionId)}>
                                        Question {index + 1}
                                        <span>{question.name}</span>
                                        {
                                            formData && formData.wsImportId &&
                                            <div className={lib.icons}>
                                                <MdEdit title='Edit' onClick={() => onEdit(question)} />
                                                <MdDelete title='Delete' onClick={() => onImportAction('delete', question.id, formData.wsImportId, formData.questions)} />
                                            </div>
                                        }
                                        
                                    </li>
                                )
                            }
                        </ul> 
                    }
                </div>
            }

            {
                curQuestionList && curQuestionList.length > 0 &&
                <div className="modalLeft">
                    {
                        type === "import" &&
                            <>
                                <button className='btn' onClick={() => onImportAction('delete all', null, importId)}>Delete All</button>
                            </>
                    }
                <ul className="list">
                    {
                        curQuestionList.map((question, index) => 
                        <li key={`q${index}`} className={curQuestionId === question.id ? "active" : undefined}>
                            <span onClick={() => setCurQuestionId(question.id)}>Question {index + 1}</span> 
                            {type === "import" && 
                                <>
                                    <MdEdit title='Edit' onClick={() => onEdit(question)} />
                                    <MdDelete title='Delete' onClick={() => onImportAction('delete', question.id, importId)} />
                                </>
                            }
                        </li>
                        )
                    }
                </ul>
                </div>
            }
            
            <div className={`${lib.questionContent} ${curQuestionList && curQuestionList.length > 0 ? "modalContent" : ''}`}>
                {
                    loading && <Loader />
                }
                {
                    !loading && curQuestion && (curQuestion.id || curQuestion.tempQuestionId) &&
                    <QuestionDetails item={curQuestion} />
                }
                {
                    !loading && !curQuestion &&
                    <div className='info'>No content available</div>
                }
            </div>
            {
                !formData &&
                <FullModal
                    setShowModal={setShowModalEdit} 
                    showModal={showModalEdit} 
                    contentRef={contentRefEdit}
                    width={"80%"}
                    height={"calc(100% - 40px)"}
                    content={
                        <>
                            <NewItems type={'question'} folderId={null} modalType={'edit'} selectItem={selectItem}
                                setShowModal={setShowModalEdit} setReloadList={null} showModal={showModalEdit}
                                showQuestionForm={true} setShowQuestionForm={setShowModalEdit}
                                questionLength={null} saving={saving} setSaving={setSaving}
                                percentCompleted={percentCompleted} setPercentCompleted={setPercentCompleted}
                                isClone={null} setIsClone={null} setReload={setReload}
                                importList={wsSeudo} setImportList={setWsSeudo}
                            />
                        </>
                    }
                />
            }
            
        </div>
    )
}

export default WorksheetReview;