import {Link} from 'react-router-dom';
import styles from './styles.module.scss';
import AlertsList from './alertsList';

const Alerts = ({showAlerts, setAlertsCount, onClick}) => {
    return (
        <div className={styles.alertsDropdown} style={{display: showAlerts ? "block" : "none"}}>
            <AlertsList refresh={showAlerts} setAlertsCount={setAlertsCount}/>
            <div className={styles.viewAll}>
                <Link to="/user/notifications" onClick={onClick}>View All</Link>
            </div>
        </div> 
    )
}

export default Alerts;
