import React, {useState, useRef} from 'react';
import styles from './styles.module.scss';
import Modal from '../modal';
import Privacy from './privacy';

const TermsAndServices = () => {
    const contentRef = useRef();
    const [showPrivacy, setShowPrivacy] = useState(false);

    return (
        <div>
            <h1>AiDAPAL&copy; TERMS AND CONDITIONS</h1>
            <p>Thank you for your interest in AidaPal!</p>
            <h3>1. General</h3>
            <p>“Terms”, “Terms &amp; Conditions" - Terms &amp; Conditions <br/>“us”, “we”, “our”, “company”, “AidaPal” - AidaPal Pte Ltd and its affiliates, subsidiaries. “site", “website” - www.aidadojo.com, AidaPal’s website, AidaPal platform, which is owned and operated by AidaPal Pte Ltd “user”, “you”, “your” - users of www.aidadojo.com Any services in connection with the Website - Services
            </p>

            <p>By browsing, accessing, registering, or using our Services (with or without payment/ with or without subscription) through any means shall signify your acceptance of the Terms and your agreement to be legally bound by the same. If you do not agree to these Terms, do not browse, access, register, or otherwise use any of our Services.
            </p>

            <p>You may use our Services only if you can form a binding contract with AidaPal, and only in compliance with these Terms and all applicable laws. If you are a parent or guardian and you provide consent for your child to register with the website, you agree to be bound by these Terms in respect of such child's use of the website. If you are school personnel and you register a school user, you agree, on behalf of your institution, that the school user is bound by the terms, unless your institution has a separate written service agreement with AidaPal that accepts these terms on behalf of school users.</p>

            <p>Your privacy is extremely important to us. Please read the AidaPal <a href="#" onClick={()=> setShowPrivacy(true)}>Privacy Policy</a> carefully for information relating to AidaPal's collection, use, and disclosure of your personal information. Among other things, our <a href="#" onClick={()=> setShowPrivacy(true)}>Privacy Policy</a> explains how we treat your personal information and protect your privacy when you use our Services. By using our Website and Services, you agree that AidaPal can use such data in accordance with our Privacy Policy.</p>           
            
            <p>If you do not agree to the terms outlined in the Terms &amp; Conditions or the Privacy Policy, please do not use the Website or avail the Services.</p>
            <h3>2. Updating our Terms</h3>
            <p>We work constantly to improve our services and develop new features to make our Products better for you. As a result, we may need to update, change, modify, add or remove portions of these Terms from time to time to accurately reflect our services and practices and we have rights to do so at our sole discretion without prior notice to you. We will only make any changes if the provisions are no longer appropriate or if they are incomplete, if required by law and only if the changes are reasonable and take due account of your interests.
            </p>
            <p>When these changes are made, we will make the updated copy accessible to you on our website. We will also update the “Last Updated” date at the top of these Terms, which reflect the effective date of such Terms. Please check the Terms periodically for changes that are made after you open your account. As permitted by applicable law, your continued use of AidaPal's website and services after revised Terms have become effective indicates that you have read, understood and agreed to the current version of the Terms.</p>
            <h3>3. Your Account</h3>
            <h4>3.1. Eligibility</h4>
            <p>If you are opening an account on behalf of yourself, you represent that you are the age of majority in your jurisdiction and fully able and competent to enter into these Terms. If you are under the age of 18 years and over the age of 13 years, you may use our Site only with the involvement of a parent or guardian who agrees to be bound by these Terms. Please have your parent or legal guardian read these terms with you. If you’re a parent or legal guardian, and you allow your child to use the Services, then these terms apply to you and you’re responsible for your child’s activity on the services.
            </p>
            <h4>3.2. Account</h4>
            <p>To use certain features of the Services provided by AidaPal, it is compulsory to create an account. When creating an account, you will be required to furnish your mobile number, your e-mail address and your sign-in information (your user name and password) as well as some other information that you voluntarily provide in your Profile &amp; Settings such as your name, age, gender, hobbies, residential address, grade/ level, school name, etc... You must provide true, accurate, current, and complete information. It is important that you maintain and promptly update your details and any other information you provide to us, to keep such information current and complete. You agree that we may access, preserve and disclose your account information and Your Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to (a) comply with legal process; (b) enforce the Terms; (c) respond to a claim that Your Content violates the rights of third parties; (d) to respond if you contact us; or (e) protect the rights, property or personal safety of the Company, Users and the public.</p>

            <p>Upon registration through any means whatsoever, you consent to receive communications from us through phone calls, e-mails, texts, mobile push notices, or notices and messages on this site or through the other AidaPal Services.</p>

            <h4>3.3. Password</h4>
            <p>When creating an account, you will be asked to provide a password in connection with your account. You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access the Services as you will be responsible for all activities that occur under your password. If you know or suspect that a third-party knows your password or has accessed your account, you should immediately notify us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>. You agree that you may be liable for the losses incurred by us or other users due to any unauthorized use of your account.</p>

            <h4>3.4. Account created by our employees</h4>
            <p>You consent that our employee may create an account on our Site on your behalf as well as access the account for the purposes of, but not limited to, teaching you how to use the Site and/or troubleshooting any technical problems that might be encountered in the Site.</p>

            <h3>4. License and Access </h3>
            <p>When instructors publish courses or other content on AidaPal website, they grant AidaPal a license to sublicense these courses or content to enrolled students.</p>

            <p>When students enroll in a course or other content on AidaPal’s website, whether it’s free or paid content, students are getting a limited, personal, non-exclusive, non-assignable, non-transferable and revocable license from AidaPal to view the content via the AidaPal platform and Services. </p>

            <p>You agree not to:<br/>
            (a) copy, edit, modify, alter, reformat or frame, reproduce, redistribute, republish, transmit, assign, sell, broadcast, rent, share, lend, create derivative works, disassemble, exploit, sublicense, transfer or adapt all information provided by the Site that is proprietary to AidaPal unless we give you explicit permission to do so in a written agreement signed by an AidaPal authorized representative;
            </p>

            <p>(b) permit or provide others access to the Site using your user name and password or otherwise, or the name and password of another authorized user;</p>

            <p>(c) interfere with, damage, impair, or disable the Service’s operation, by any means (whether through automated means or otherwise), including uploading or otherwise disseminating viruses, worms, spyware, adware or other malicious code;</p>

            <p>(d) make unsolicited offers, advertisements, proposals, or send junk mail or spam to other Users, or use the Service for commercial purposes;</p>

            <p>(e) use any robot, spider, scraper, or other automated means to access the Service for any purpose without our express consent;</p>

            <p>(f) remove, circumvent, disable, damage or otherwise interfere with the Service’s security-related features, features that prevent or restrict the use or copying of any part of the Service, or features that enforce Service limitations; </p>

            <p>(g) attempt to gain unauthorized access to the Service, other User accounts, computer systems or networks connected to the Service through hacking, password mining, or any other means;</p>

            <p>(h) make available any misleading, harassing, defamatory, filthy, violent, obscene, pornographic, tortious, libelous, vulgar, abusive, harmful, threatening, hateful, or racially, ethnically or otherwise objectionable, illegal material and content on the Site;</p>

            <p>(i) post material and content that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;</p>

            <p>(j) impersonate another person or represent yourself as affiliated with us, our staff or other industry professionals.</p>
            
            <p>AidaPal will take all reasonable efforts to provide uninterrupted access to the Site and Services to its users. However, from time to time, users may be unable to access the content due to conditions beyond our control such as force majeure, acts of God, power outages, the acts of computer hacker, others acting outside the law; and some other reasons like software issues, server downtime, increased Internet traffic, programming errors, regular maintenance of the system, and other related reasons. In that case, AidaPal will take all reasonable steps - which are taken in good faith without an unduly or burdensome use or expenditure of time, resources, personnel or money - to ensure access is restored within a reasonable period of time.</p>

            <p>You accept that we reserve the right to revoke any license to access and use any content at any point in time in the event where we decide or are obligated to disable access to the content due to legal or policy reasons. Instructors may not grant licenses to their content to students directly, and any such direct license shall be null and void and a violation of these Terms.</p>

            <h3>5. Third Party Websites and Content.</h3>

            <p>When using services from AidaPal, you may have the ability to access and/or use information and content provided by instructors, other users, and/or other third parties and links, references to websites, services and products that are owned, controlled, developed, sponsored and/or maintained by third parties. Access to and use of any third party Sites is at your own risk and we are not responsible for (a) the accuracy, reliability, legitimacy, trueness, integrity, quality, usefulness, or safety of, or intellectual property rights or infringement relating to information and content on Third Party Sites, (b) review, monitor, remove, operate and/or control inappropriate Third Party Content, (c) the acts or omissions of the operators of Third Party Sites (or their partners or affiliates), (d) any loss or damage incurred in connection with the use of any Third Party Site such as malware or other contaminants that may harm your computer, mobile device, or any files therein, or (e) any transaction you consummate in connection with your use or access of any Third Party Site.</p>

            <p>AidaPal disclaims any responsibility or liability related to your access or use of, or inability to access or use, such third party content. You alone are responsible for your involvement with other users and providers of Third Party Content, including providing information, materials and/or other content to the Third-Party Websites. If you have a dispute with (a) another user, (b) the provider of any Third Party Site or Third Party Content and Information, (c) any third party that contacts you as a result of your use of the Service, or (d) any other third party arising in connection with your use of the Service, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>

            <h3>6. Our Rights to your Content</h3>

            <p>The content you post on AidaPal’s website as a student or instructor remains yours. However, by submitting or posting content - of any format such as text, images, photos, video, audio - including but not limited to courses, questions, reviews, homework, quizzes, exams, projects, assignments, posts, creative works, graphics, recordings of your voice or video of you, ideas and suggestions, comments, testimonials and other feedback for updates, new features or improvements, you grant AidaPal a non-exclusive, worldwide, fully-transferable, royalty-free, sublicensable, perpetual license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, distribute this content (including your name and image) on any platform and in any and all media or distribution methods (now known or later developed). This includes making your content available to other companies, organizations, or individuals who partner with AidaPal for the syndication, broadcast, distribution, or publication of content on other media, as well as using your content for marketing purposes. You also waive any rights of privacy, publicity, or other rights of a similar nature applicable to all these uses, to the extent permissible under applicable law. </p>
            
            <p>When video recording a lesson, AidaPal is authorised to make recordings of users’ voices and to obtain an account of their spoken or written thoughts. AidaPal reserves the rights to use these recordings, spoken and written thoughts, as well as likeness, name, voice, identity, in complete or partial form, in connection with any works that AidaPal may create in any media format now known or later developed, including, without limitation, books, publications, video, the Internet, and the World Wide Web.</p>

            <p>You represent and warrant that you have all the rights, power, and authority necessary to authorize us to use any content that you submit and the sharing of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person. You also agree to all such uses of your content with no compensation paid to you. If you delete your user account, your content and name may remain available through the Services.</p>

            <h3>7. Using our Services at Your Own Risk</h3>

            <p>You acknowledge and agree that your use of the AidaPal website is at your own risk. We do not review or edit the content for legal issues, and we are not in a position to determine the legality of content. We do not exercise any editorial control over the content that is available on the platform and, as such, do not guarantee in any manner the reliability, validity, accuracy, or truthfulness of the content. If you access content, you rely on any information provided by an instructor at your own risk.</p>
            
            <p>By using the Services, you may be exposed to content that you consider offensive, indecent, or objectionable. AidaPal has no responsibility to keep such content from you and no liability for your access or enrollment in any course or other content, to the extent permissible under applicable law. You acknowledge the inherent risks and dangers in the strenuous nature of these types of content, and by accessing such content you choose to assume those risks voluntarily, including risk of illness, bodily injury, disability, or death. You assume full responsibility for the choices you make before, during, and after your access to the content.</p>

            <p>When you interact directly with a student or an instructor, you must be careful about the types of personal information that you share. While we restrict the types of information instructors may request from students, we do not control what students and instructors do with the information they obtain from other users on the platform. You should not share your email or other personal information about you for your safety.</p>
            
            <p>We do not hire or employ instructors nor are we responsible or liable for any interactions involved between instructors and students. We are not liable for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to the conduct of instructors or students.</p>

            <p>When you use our Services, you will find links to other websites that we don’t own or control. We are not responsible for the content or any other aspect of these third-party sites, including their collection of information about you. You should also read their terms and conditions and privacy policies.</p>

            <h3>8. Prices, Payments, Subscriptions, and Refunds</h3>

            <h4>8.1. Pricing</h4>

            <p>The prices of the classes, courses, packages or other products will be as quoted on our site from time to time. We occasionally run promotions and sales for our content, during which certain content is available at discounted prices for a set period of time. The price applicable to the content will be the price at the time you complete your purchase of the content (at checkout). We take all reasonable care to ensure that the quoted prices are correct at the time when the relevant information was entered onto the system. However if we discover an error in a price, we reserve the right not to accept your order at the incorrect price.</p>
            
            <h4>8.2. Payments</h4>

            <p>You authorize us to charge your debit or credit card or process other means of payment (such as direct debit, internet banking or mobile wallet...) for all the fees you pay to use some of our content. AidaPal works with payment service providers to offer you the most convenient payment methods in your country and to keep your payment information secure. Any information entered by the User when transacting on the Website is encrypted to protect the User against unintentional disclosure to third parties. The User's payment information (such as credit card details) is not received, stored by or retained by the Company in any manner. This information is supplied by the User directly to the relevant payment gateway which is authorized to handle the information provided, and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with.</p>

            <p>When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the content you are enrolling in, you agree to pay us the corresponding fees within thirty (30) days of notification from us. We reserve the right to disable access to any content for which we have not received adequate payment.</p>

            <h4>8.3. Subscription</h4>

            <p>This section covers additional terms that apply to your use of our subscription-based collections as a student (“Subscription Plans”). During your subscription to a Subscription Plan, you get a limited, non-exclusive, non-transferable license from us to access and view the content included in that Subscription Plan via the Services. 
            The subscription that you purchase or renew determines the scope, features, and price of your access to a Subscription Plan. You may not transfer, assign, or share your subscription with anyone else.
            We reserve the right to revoke any license to use the content in our Subscription Plans for legal or policy reasons at any time and at our sole discretion, such as if we no longer have the right to offer the content through a Subscription Plan.
            </p>

            <p>We make no guarantees as to the availability of any specific content in any Subscription Plan or as to any minimum amount of content in any Subscription Plan. At any point in the future, we reserve the right to offer or cease to offer additional features to any Subscription Plan, or to otherwise modify or terminate a Subscription Plan at our sole discretion.
            </p>

            <h4>8.4. Refunds &amp; Change</h4>

            <p>By contacting us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>, you could request for a change of classes &amp; courses if you find that the content you purchased is not what you were expecting, but you could only request for a refund in case of technical errors and/or payment errors in our platform. This refund and change options do not apply to payments for workshops and/or Subscription Plan purchases.</p>

            <strong>Condition for change</strong>
            <p>- Request for a change within 10 days from the date of payment.<br/>
            - You have not studied more than 50% of the course duration.<br/>
            - You pay the difference if the new course has a higher price than the current course but you receive no refund from us for the difference if the new course has lower price than the current course.
            </p>

            <strong>Condition for refund</strong>
            <p>- Request for a refund within 10 days from the date of payment.<br/>
            - Reasons: technical errors arising from AidaPal platform and website, or payment errors such as overpayment, duplicate payment for orders. <br/>
            - All refund reasons are subject to review and proof by photo. We reserve the right to apply your refund as a refund credit or a refund to your original payment method, at our discretion, depending on capabilities of our payment service providers, the platform from which you purchased your content.
            </p>

            <h3>9. Intellectual Property</h3>

            <p>We respect your privacy rights and hope you also respect our intellectual property rights. As a condition of your access to and use of the AidaPal website, you agree not to use the website to infringe any intellectual property rights.</p>

            <p>You understand that all information, content, material, services marks, copyrights, logos, patents, trade names, trademarks and trade secrets including but not limited to the software, codes, text, images, photographs, graphics, illustrations, audio, video, music, script contained in the Website and Services are proprietary property of AidaPal (“Proprietary Information”). You acknowledge and agree that any feedback, comments or suggestions you may provide regarding the Services will be the sole and exclusive property of AidaPal and you hereby irrevocably assign to us all of your right, title and interest in and to the foregoing.</p>

            <p>Use of the AidaPal Proprietary Information for any purpose not expressly permitted by these Terms is strictly prohibited. Such Proprietary Information may not be downloaded, copied, reproduced, modified, republished, uploaded, posted, distributed, transmitted, broadcast, displayed, sold, licensed or otherwise exploited for any purpose whatsoever without our or, where applicable, our licensors’ prior written consent. Subject to these Terms, we grant you a non-exclusive, limited, non-transferable, non-sublicensable, revocable license to access and use the website, and to access the AidaPal Content through your use of the website, solely for your personal, noncommercial use, in accordance with these Terms, and applicable law, including any applicable intellectual property law.</p>

            <h3>10. Software updates</h3>

            <p>The Software which you use may automatically download and install updates from time to time from AidaPal. These updates are designed to improve, enhance and further develop the Services and may take the form of bug fixes, enhanced functions, new software modules and completely new versions. You agree to receive such updates (and permit AidaPal to deliver these to you) as part of your use of the Services.</p>

            <h3>11. Disclaimer of Warranty</h3>

            <p>The Services are provided “as is”, and “as available" without warranty of any kind, express or implied, including but not limited to the warranties of merchantability, fitness for any particular purpose, title, non-infringement, accuracy, completeness, availability and compatibility.</p>

            <p>No warranty is made that the Services will be error-free, that errors will be corrected, that the Website or Services will be uninterrupted or secure, that the Website or Service will meet your expectations, or that the Website or the servers that make such content available shall be free of viruses or other harmful components. Any material obtained through the use of the Website or Services is done so at your own discretion and risk. To the extent permitted by applicable law, AidaPal shall not be responsible for any damage or loss of data that results from obtaining such material. No warranty is made regarding the use or results of use of the Website, Services, or any part thereof.</p>

            <h3>12. Limitation of Liability</h3>

            <p>Under no circumstances will the AidaPal, including our affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, or shareholders, be liable for any direct, indirect, economic, exemplary, special, punitive, incidental or consequential losses or damages, including but not limited to loss or damage directly or indirectly related to: (a) the service and its operation; (b) the AidaPal content; (c) user or third party content; (d) your inability to use the service; (e) any action taken in connection with an investigation by the AidaPal or law enforcement authorities regarding your or any other party's use of the service; (f) any damage to any user's computer, mobile device, or other equipment or technology; or (g) lost profits, loss of goodwill, loss of data, work stoppage, accuracy of results, death, personal injury, or computer failure or malfunction. We shall not be liable for the foregoing losses or damages even if foreseeable or even if we have been advised of or should have known of the possibility of such damages, and whether in an action of contract, negligence, strict liability or tort (including, without limitation, whether caused in whole or in part by negligence, acts of god, telecommunications failure, or theft or destruction of the service).
            </p>

            <h3>13. Indemnification</h3>

            <p>You agree to defend (at our request), indemnify and hold harmless AidaPal and its employees, managers, officers and agents (collectively, the “AidaPal Parties”) from all claims, demands, damages, losses, liabilities, costs and expenses, including without limitation, reasonable attorneys’ fees and expenses, arising out of or in any way connected with any of the following (including as a result of your direct activities on the Site or Service or those conducted on your behalf): (i) your Content or your access to or use of the Service; (ii) your breach or alleged breach of these Terms; (iii) your violation of any third-party right, including without limitation, any intellectual property right, publicity, confidentiality, property or privacy right; (iv) your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental and quasi-governmental authorities, including, without limitation, all regulatory, administrative and legislative authorities; or (v) any misrepresentation made by you. You will cooperate as fully required by the Company in the defense of any claim. We reserve the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any event settle any claim without our prior written consent.</p>

            <h3>14. Governing Law and Jurisdiction</h3>

            <p>These Terms &amp; Conditions are governed and construed in accordance with the laws of the Republic of Singapore, except as otherwise provided in the Arbitration Provision. Any dispute arising from these Terms shall be resolved in the courts of the Republic of Singapore.</p>

            <p>Any version of this Privacy Policy in a language other than English is provided for convenience. If there is any conflict with a non-English version, you agree that the English language version will control.</p>

            <h3>15. Termination</h3>

            <p>The Terms will continue to apply until terminated by either you or AidaPal as set out below.</p>

            <h4>15.1. Termination by AidaPal</h4>

            <p>AidaPal may prevent a user from using our Services, suspend or terminate any account (or any part thereof) you may have with AidaPal or your use of the Services and remove and discard all or any part of your account, User profile, and User Content, at any time, with or without prior notice, if:<br/>
            (A) you have breached any provision of the Terms; or<br/>
            (B) we are required to do so to comply with a legal requirement or a court order; or<br/>
            (C) we reasonably believe that your conduct causes harm or liability to a user, third party, or AidaPal.<br/>
            (D) the partner with whom AidaPal offered the Services to you has terminated its relationship with AidaPal or ceased to offer the Services to you; or<br/>
            (E) AidaPal is transitioning to no longer providing the Services to users in the country in which you are resident or from which you use the Services; or<br/>
            (F) the provision of the Services to you by AidaPal is, in AidaPal’ opinion, no longer commercially viable.
            </p>

            <h4>15.2. Termination by You</h4>

            <p>If you have any dissatisfaction with (i) the Services, (ii) any provision of these Terms of Service of AidaPal in operating the Services, or (iii) any content or information transmitted through the Services, you may terminate the Terms and your account at any time by notifying us through email (<a href="mailto:care@aidapal.com">care@aidapal.com</a>) or deleting your login account with the Services and discontinuing use of any and all parts of the Services on the Site.</p>

            <p>You agree that AidaPal shall not be liable to you or any third party in the event of such termination of access to the Site and Services. Termination of the Terms as to any User account will not limit AidaPal's rights and remedies regarding any breach of these Terms occurring prior to such termination.</p>

            <h3>16. Contact Us</h3>

            <p>If you have any questions, complaints, concerns, comments and feedback about our Website and Services, you may email us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>.</p>

            <p>Thanks for choosing us!</p>

            <Modal
            setShowModal={setShowPrivacy}
            showModal={showPrivacy}
            contentRef={contentRef}
            width="80%"
            content={
                <div ref={contentRef}>
                    <div className={styles.termsAndServices}>
                        <Privacy />
                    </div>  
                </div>
            } />
        </div>
    )
}

export default TermsAndServices;