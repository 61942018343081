import { useState, useEffect } from "react";

const CountUp = ({tests, timeCount, setTimeCount, getTimeCount}) => {

  useEffect(()=>{
    const timer = setInterval(()=> setTimeCount && setTimeCount(timeCount + 1), 1000);
    return () => clearInterval(timer);
}, [timeCount]);

  return (
    <div className={tests.countUp}>
      {`${Math.floor(timeCount/60) < 10 ? '0' : ''}${Math.floor(timeCount/60)} : ${timeCount%60 < 10 ?'0':'' }${timeCount%60}`}
    </div>
  )
};

export default CountUp;