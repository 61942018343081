import { forwardRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { OPTIONS_TYPE } from "../../../common/utils";
import DateRangePicker from "../../../components/day-range";
import Input from '../../../components/input';

const Filter = forwardRef(({lib, filtersData, filters, setFilters, type, setShowFilter}, ref) => {
    const [filter, setFilter]  = useState(filters);

    const onDateRangeChange = (from, to) => setFilter({...filter, fromDate: from, toDate: to});
    const levelOnChange = (type) => setFilter({...filter, levels: type});
    const subjectOnChange = (type) => setFilter({...filter, subjects: type});
    const fileTypeOnChange = (type) => setFilter({...filter, fileTypes: type});
    const worksheetTypeOnChange = (type) => setFilter({...filter, worksheetType: type});
    const questionTypeOnChange = (type) => setFilter({...filter, questionType: type});
    const authorOnChange = (type) => setFilter({...filter, authors: type});
    const topicOnChange = (type) => setFilter({...filter, topics: type});
    const subTopicOnChange = (type) => setFilter({...filter, subTopics: type});
    const tagOnChange = (type) => setFilter({...filter, tags: type});
    const sizeLargerHandler = (e) => setFilter({...filter, sizeLarger: e.target.value});
    const sizeSmallerHandler = (e) => setFilter({...filter, sizeSmaller: e.target.value});    

    const onApplyFilter = () => {
        setFilters && setFilters(filter);
        setShowFilter && setShowFilter(false);
        localStorage.setItem('library-filter', JSON.stringify(filter));
    }

    const onResetFilter = () => {
        setFilter({
            fromDate: '',
            toDate: '',
            levels: [],
            subjects: [],
            isFavorite: false,
            authors: [],
            fileTypes: [],
            worksheetType: '',
            topics: [],
            subTopics: [],
            tags: [],
            questionType:[],
            sizeLarger: '',
            sizeSmaller: '',
        });
        localStorage.removeItem('library-filter');
    }

    return (
        <div className={lib.moreFilters} ref={ref}>
            <div className={lib.flex}>
                <div>
                    <label>Date</label>
                    <DateRangePicker
                        fromDate={filter.fromDate}
                        toDate={filter.toDate}
                        onChange={onDateRangeChange}
                        className={lib.datePicker}
                    />
                
                    <Input
                        className={lib.select}
                        type="select"
                        options={filtersData && filtersData.authors}
                        placeholder=''
                        onChange={authorOnChange}
                        value={filter.authors}
                        label="Author"
                        isMulti
                    />
                    {
                        type === "files" &&
                        <div className={lib.fileSize}>
                            <label>File Size</label>
                            <div>
                                <div>
                                    <MdOutlineArrowForwardIos />
                                    <input type="number" onChange={sizeLargerHandler} value={filter.sizeLarger}/> <span>Mb</span>
                                </div>
                                <div>
                                    <MdOutlineArrowBackIosNew />
                                    <input type="number" onChange={sizeSmallerHandler} value={filter.sizeSmaller}/> <span>Mb</span>
                                </div>
                            </div>
                            
                        </div>
                        
                    }
                    <div className={`checkBox ${filter.isFavorite ? "active" : ''}`}
                        onClick={() => setFilter({...filter, isFavorite: !filter.isFavorite})}>
                        <FaCheck /> <span>My Favorite Items</span>
                    </div>
                </div>
                <div>
                    <Input
                        className={lib.select}
                        type="select"
                        options={filtersData && filtersData.levels}
                        placeholder=''
                        onChange={levelOnChange}
                        value={filter.levels}
                        label="Levels"
                        isMulti
                    />
                    <Input
                        className={lib.select}
                        type="select"
                        options={filtersData && filtersData.subjects}
                        placeholder=''
                        onChange={subjectOnChange}
                        value={filter.subjects}
                        label="Subjects"
                        isMulti
                    />
                    {
                        type === "files" &&
                        <Input
                            className={lib.select}
                            type="select"
                            options={filtersData && filtersData.fileTypes}
                            placeholder=''
                            onChange={fileTypeOnChange}
                            value={filter.fileTypes}
                            label="File Types"
                            isMulti
                        />
                    }
                    {
                        type === "worksheets" &&
                        <Input
                            className={lib.select}
                            type="select"
                            options={filtersData && filtersData.worksheetType}
                            placeholder=''
                            onChange={worksheetTypeOnChange}
                            value={filter.worksheetType}
                            label="Worksheet Type"
                            isMulti
                        />
                    }
                    {
                        type === "questions" &&
                        <Input
                            className={lib.select}
                            type="select"
                            options={OPTIONS_TYPE.QUESTIONS_TYPE}
                            placeholder=''
                            onChange={questionTypeOnChange}
                            value={filter.questionType}
                            label="Question Types"
                            isMulti
                        />
                    }
                </div>
                
                    <div>
                        <Input
                            className={lib.select}
                            type="select"
                            options={filtersData && filtersData.topics}
                            placeholder=''
                            onChange={topicOnChange}
                            value={filter.topics}
                            label="Topics"
                            isMulti
                        />
                        <Input
                            className={lib.select}
                            type="select"
                            options={filtersData && filtersData.subTopics}
                            placeholder=''
                            onChange={subTopicOnChange}
                            value={filter.subTopics}
                            label="Sub Topics"
                            isMulti
                        />
                        {
                        type === "questions" &&
                        <Input
                            className={lib.select}
                            type="select"
                            options={filtersData && filtersData.tags}
                            placeholder=''
                            onChange={tagOnChange}
                            value={filter.tags}
                            label="Tags"
                            isMulti
                        />
                        }
                    </div>                
            </div>
            <div className="buttons">
                <button className="btn" onClick={onApplyFilter}>Apply Filters</button>
                <button className="btn2" onClick={onResetFilter}>Reset</button>
            </div>
        </div>
    )
});

export default Filter;