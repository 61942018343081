import { useState } from "react";
import { useEffect } from "react";
import API_URL from "../../common/urls";
import { useStore } from '../../store/useStore';
import {Logout} from '../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { useHistory } from "react-router";
import { creditIcon, getError, worksheetThemes } from '../../common/utils';
import { toast } from 'react-toastify';
import { Loader } from "../loading";
import { MdPerson } from "react-icons/md";

const PrePage = ({tests, setPlayTest, worksheetId, setTheme, themeId, setWorksheetTime, isPreview, learnId, learnType, setPreview}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState();

    useEffect(()=>{
        setLoading(true);
        axios.get(API_URL.GET_USER_LIVE_TEST_INFO_URL,{
            headers: authHeader(),
            params:{
                worksheetId,
                isPreview: isPreview,
                learnId,
                learnType
            }
        })
        .then(response => {
            setLoading(false);
            response.data && setInfo(response.data);
            response.data.showWorksheetTime && response.data.totalTime && setWorksheetTime(response.data.totalTime);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[]);

    useEffect(()=>{
        info && info.status && info.status === 'completed' && setPreview && setPreview(true);
    }, [info && info.status]);

    const onChangeTheme = (themeId) => {
        setTheme && setTheme(themeId);
    }

    return (
        <div className={tests.prepage}>
            {
                loading && <Loader />
            }
            {
                !loading && info.name && 
                <div>
                    <h2>{info.name} {info.status && <span className={tests[info.status]}>{info.status}</span>}</h2>
                    {
                        info.status === "challenge" && info.challengeMembers && info.challengeMembers.length > 0 &&
                        <div className={`${tests.members} ${tests.prePage}`}>
                            <ul>
                                {
                                    info.challengeMembers.map((item, index) => 
                                        <li>
                                            <div className={tests.user}>
                                            {
                                                item.userImageUrl &&
                                                <img 
                                                    src={item.userImageUrl} 
                                                    alt="user image" 
                                                    width="100"
                                                />
                                            }
                                            {
                                                !item.userImageUrl &&
                                                <MdPerson size="100" color="#ddd"/>
                                            }
                                            </div>
                                            <h4>{item.userFullName}</h4>
                                            <div className={tests.result}>{item.result}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    }
                    {
                        info.totalQuestions > 0 &&
                        <div><label>Total Question:</label><span>{info.totalQuestions}</span></div>
                    }
                    {
                        info.totalTime > 0 &&
                        <div><label>Total Time:</label><div><span>{info.totalTime}</span> minutes</div></div>
                    }
                    {
                        info.timeForEachQuestion &&
                        <div><label>Each Question Time:</label><div><span>{info.timeForEachQuestion}</span> minutes</div></div>
                    }
                    {
                        info.credit > 0 &&
                        <div className={tests.credit}>
                            <label>Max Credits:</label>
                            <div>{creditIcon(info.credit)}</div>
                        </div>
                    }
                    {
                        info.bonus > 0 &&
                        <div><label>Max Bonus:</label>{creditIcon(info.bonus)}</div>
                    }
                    {
                        info.earnedCredit > 0 && info.status !== 'new' &&
                        <div><label>Earned:</label>{creditIcon(info.earnedCredit)}</div>
                    }
                    <div>
                        <label>Choose Theme:</label>
                        <ul>
                            {
                                worksheetThemes.map((theme, index) => 
                                    <li className={`
                                    ${themeId === theme.themeId ? tests.active : ''}`}
                                    key={'theme'+index}
                                    onClick={()=>onChangeTheme(theme.themeId)}
                                    style={{background: `${(theme.thumnailImage ?  'white url(' + theme.thumnailImage + ') no-repeat center center / cover' : '#e3c136')}`}}
                                    >
                                    </li>    
                                )
                            }
                        </ul>
                    </div>
                    

                    <div className="buttons" style={{justifyContent: 'center'}}>
                        <button onClick={() => {setPlayTest && setPlayTest(true)}}>
                            {info.status === 'new' ? 'START' : 'CONTINUE'}
                        </button>
                    </div>
                    
                </div>
            }
            
        </div>
    )
}

export default PrePage;