import React from 'react';
import {default as SL} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const style = {
  control: base => ({
    ...base,
    border: 0,
    boxShadow: "none"
  })
};

const Select = ({className, isCreatable, isAsync, ...rest}) => {
  return (
    !isCreatable ?
      (!isAsync ?
        (<SL className={className} {...rest} styles={style}/>) :
        <AsyncSelect className={className} {...rest} />):
      (<CreatableSelect className={className} {...rest} />)
  );
};

export default Select;
