import {useState, useRef, Fragment} from 'react';
import {MdPerson} from 'react-icons/md';
import {uniqueId} from 'lodash';
import terms from './styles/terms.module.scss';
import useClickOutside from '../../../hooks/useClickOutside';
import {MdAdd, MdCheckCircle, MdPlayCircleFilled, MdPlayArrow, MdAssignment, MdFormatListBulleted, MdClose, MdEdit} from 'react-icons/md';

const Assignment = ({assign, user, index, i, ai, removeAssignment, setShowTestForm, setNoScroll, setShowAssignmentForm, 
    setSelectedAssignmentId, setShowView, setStudentUserName, setLessionId, lessionId}) => {
    const ref = useRef();
    useClickOutside(ref, () => setShowStudents(false));
    const [showStudents, setShowStudents] = useState(false);

    const onRemoveItem = () => {
        const confirm = window.confirm("Are you sure you want to remove this assigment?");
        if(confirm) {
            removeAssignment && removeAssignment(assign.assignId, "assigment", index, i, ai);
        }
    }

    const onPlayTesting = () => {
        setSelectedAssignmentId && setSelectedAssignmentId(assign.assignId);
        setShowTestForm && setShowTestForm(true);
        setLessionId && setLessionId(lessionId);
    }

    const onPlayTesting2 = () => {
        setSelectedAssignmentId && setSelectedAssignmentId(assign.assignId);
        setShowTestForm && setShowTestForm("Teacher Preview");
        setLessionId && setLessionId(lessionId);
    }

    const onEdit = () => {
        setSelectedAssignmentId && setSelectedAssignmentId(assign.assignId);
        setShowAssignmentForm && setShowAssignmentForm(true);
        setLessionId && setLessionId(lessionId);
    }

    const onReview = (type) => {
        setSelectedAssignmentId && setSelectedAssignmentId(assign.assignId);
        setShowView && setShowView(true);
        if(type && user.userRole==="Students") 
        { 
            setStudentUserName && setStudentUserName(user.userName); 
        } else {
            setStudentUserName && setStudentUserName(null);
        }
    }

    const onTeacherReview = (studentId) => {
        setSelectedAssignmentId && setSelectedAssignmentId(assign.assignId);
        setShowView && setShowView(true);
        setStudentUserName && setStudentUserName(studentId);
    }

    return (
        <div key={uniqueId()} ref={ref} className={assign.markType === "manual" ? terms.manual : ""}>
            <MdAssignment /> 
            {
                user.userRole !== "Teachers" &&
                <span title={assign.name}>{assign.name}</span>
            }
            
            {user.userRole !== "Teachers" && (assign.status === "pending" || (assign.status === "completed" && assign.homework)) &&
                <MdPlayCircleFilled className={terms.play} title="Practice" onClick={onPlayTesting}/>
            }
            
            {user.userRole !== "Teachers" && assign.status === "completed" &&
                <MdCheckCircle className={terms.done} title="Review the assignment" onClick={() => onReview('student')}/>
            }

            {
                user.userRole === "Teachers" &&
                <>
                <span onClick={onReview} className={terms.clickable} title={assign.name}>{assign.name}</span>
                <MdClose className={terms.close} title="Remove item" onClick={onRemoveItem}/>
                <MdEdit className={terms.edit} onClick={onEdit}/>
                <MdPlayArrow className={terms.play} title="Preview" onClick={onPlayTesting2}/>
                </>
            }

            {user.userRole === "Teachers" && assign.students && assign.students.length > 0 &&
                <>
                    <MdFormatListBulleted className={terms.button} onClick={() => setShowStudents(true)}/>
                    <div className={terms.dropdown} style={{display: showStudents ? "block" : "none"}}>
                        {assign.students.map(student => 
                            <div key={uniqueId()}>
                                <div className={terms.image}>
                                    {student.imageUrl && 
                                        <img src={student.imageUrl} alt="student" /> 
                                    }
                                    {!student.imageUrl && 
                                        <MdPerson size="50" />
                                    }
                                </div>
                                <div>
                                    <strong>{student.fullName}</strong>
                                    <span>{student.userName}</span>
                                </div>
                                {student.status === "completed" &&
                                    <div className={terms.status}>
                                        <MdCheckCircle className={terms.done} onClick={() => onTeacherReview(student.userName)}/>
                                        <span>{student.result}</span>    
                                    </div>
                                }
                            </div>    
                        )}
                    </div>
                </>
            }
        </div>
    )
}

const Assignments = ({list, user, setShowAssignmentForm, setShowTestForm, removeAssignment, setLessionId, 
    lessionId, index, i, setNoScroll, setSelectedAssignmentId, setShowView, setStudentUserName}) => {
    const addAssignmentHandler = () => {
        setSelectedAssignmentId && setSelectedAssignmentId(null);
        setShowAssignmentForm && setShowAssignmentForm(true);
        setLessionId && setLessionId(lessionId);
    }

    return (
        <div className={terms.assigments}>
            <label>Assignments</label>
            <div className={terms.assignment}>
            {
                list && list.length > 0 && list.map((assign, ai) =>     
                <Assignment 
                    assign={assign} 
                    user={user} 
                    key={uniqueId()}
                    index={index}
                    i={i}
                    ai={ai}
                    removeAssignment={removeAssignment}
                    setShowTestForm={setShowTestForm}
                    setNoScroll={setNoScroll}
                    setShowAssignmentForm={setShowAssignmentForm}
                    setSelectedAssignmentId={setSelectedAssignmentId}
                    setShowView={setShowView}
                    setStudentUserName={setStudentUserName}
                    setLessionId={setLessionId}
                    lessionId={lessionId}
                />
            )
            }
            </div>
            {user.userRole === "Teachers" && 
                <div className={terms.newFile} title="Add assignments" onClick={addAssignmentHandler}>
                    <MdAdd />
                </div>
            }
        </div>
    )
}

export default Assignments;