import {useState} from 'react';
import Input from '../../../components/input';
import {useHistory} from 'react-router-dom';
import classes from './styles/classes.module.scss';
import {toast} from 'react-toastify';
import { useStore } from '../../../store/useStore';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import API_URL from '../../../common/urls';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import InputSearch from '../../../components/input/inputSearch';

const SetCreditForm = ({classId, lessionId, setShowModal}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [loading, setLoading] = useState();
    const [rewardedUsers, setRewardedUsers] = useState({users: []});
    const [search, setSearch] = useState('');
    const [credit, setCredit] = useState(0);


    const creditOnChange = (e) => setCredit(e.target.value);
    const searchOnChange = (e) => setSearch(e.target.value);
    const onSubmit = () => {
        if(!credit) {
            toast.error(`Credit should be greater than 0!`);
            return;
        }

        setShowModal && setShowModal(false);

        // call api to set to all students
        const data = {lessionId : lessionId, send_credit: credit, users: rewardedUsers.users.length && rewardedUsers.users.join(',')};
        axios.post(API_URL.SEND_CREDIT_URL,data, {
            headers: authHeader()
        })
        .then(response => {
            toast.success('Medals has been sent to selected students!');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={classes.form}>
            <h2>Reward students with medals</h2>
            <div className={classes.formControl}>
                <InputSearch
                    placeholder='Search...'
                    onChange={searchOnChange}
                    label="Students"
                    value={search}
                    setValue={setSearch}
                    apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=students&classId=${classId}`}
                    formData={rewardedUsers}
                    setFormData={setRewardedUsers}
                    collection='users'
                    isMulti
                />
                <Input 
                    type="number" 
                    placeholder="" 
                    onChange={creditOnChange}
                    value={credit}
                    label="Number of Medals"
                    min={1}
                />
                <div>
                    <button className="btn" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SetCreditForm;