import {useState, useEffect, useRef} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import styles from './styles.module.scss';
import {getCurrentUserRole, getCurrentFullName, getCurrentUserImage} from '../../services/auth.service';
import {MdAssessment, MdAccountCircle, MdFeedback, MdPayment,MdDvr, MdQuestionAnswer, MdArrowDropDown, 
    MdNotifications, MdLibraryBooks, MdMenu, MdPeople} from 'react-icons/md';
import {FaRegCalendarAlt, FaBookReader, FaEdit} from 'react-icons/fa';    
import { useStore } from '../../store/useStore';
import useClickOutside from '../../hooks/useClickOutside';

const LeftMenu = ({setShowBox, setActiveTab, setActiveSubTab}) => {
    const pathName = window.location.pathname;
    const history = useHistory();
    const currentRole = getCurrentUserRole();
    const fullName = getCurrentFullName();
    const profileImage = getCurrentUserImage();
    const {currentMenu, setCurrentMenu} = useStore();
    const [showUl, setShowUl] = useState(false);
    const [showLeftMenu, setShowLeftMenu] = useState(false);
    const menuRef = useRef();
    useClickOutside(menuRef, () => setShowLeftMenu(false));

    useEffect(()=>{
        switch(true){
            case pathName.includes('dashboard'):
                setCurrentMenu(['Dashboard', '/user/dashboard']); 
                break;
            case pathName.includes('classes'): 
                setCurrentMenu(['My Classes','/user/classes']);
                break;
            case pathName.includes('library'):
                setCurrentMenu(['Library','/user/library']);
                break;
            case pathName.includes('myAidaPal'): 
                setCurrentMenu(['My AidaPal','/user/myAidaPal']);
                break;
            case pathName.includes('exam'): 
                setCurrentMenu(['My Exam','/user/exam']);
                break;
            case pathName.includes('calendar'): 
                setCurrentMenu(['Calendar','/user/calendar']);
                break;
            case pathName.includes('notifications'): 
                setCurrentMenu(['Notifications','/user/notifications']);
                break;
            case pathName.includes('profile'): 
                setCurrentMenu(['Profile & Settings','/user/profile']);
                break;
            case pathName.includes('connection'): 
                setCurrentMenu(['Connection','/user/connection']);
                break;
            case pathName.includes('feedback'): 
                setCurrentMenu(['Feedback','/user/feedback']);
                break;
            case pathName.includes('transaction'): 
                setCurrentMenu(['Transaction History','/user/transaction']); 
                break;
            case pathName.includes('faq'): 
                setCurrentMenu(['FAQ','/user/faq']);
                break;
            // default: setCurrentMenu(null);
        }
    },[pathName]);

    const profileImageOnClick = () => {
        history.push('/user/profile?tab=profile-picture')
    }

    const menuOnClick = () => {
        setShowUl(false);
        setShowBox(true);
        setActiveTab(null);
        setActiveSubTab(null);
    }

    return (
        <div className={styles.leftMenu} onClick={() => setShowLeftMenu(!showLeftMenu)}>
            <MdMenu color='white' size={30}/>
            <div ref={menuRef} className={showLeftMenu ? styles.showLeftMenu : undefined}>
                <div className={styles.image}>
                    {profileImage &&
                        <div onClick={profileImageOnClick}>
                            <img 
                                src={profileImage} 
                                alt="profile" 
                                title="view profile picture"
                            />
                        </div>
                    }
                    {
                        !profileImage &&
                        <MdAccountCircle size="80" color="#ccc"/>
                    }
                    <h4>{fullName}</h4>
                    <span>{currentRole}</span>
                </div>  
                    <ul className={showUl ? styles.showUl : ''} onClick={menuOnClick}>       
                        <li>
                            <NavLink to="/user/dashboard" activeClassName={styles.active}>
                                <MdAssessment size="20"/> Dashboard
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/user/classes" activeClassName={styles.active}>
                                <MdDvr size="20"/> My Classes
                            </NavLink>
                        </li>

                        {
                            currentRole === "Teachers" &&
                            <li>
                                <NavLink to="/user/library" activeClassName={styles.active}>
                                    <MdLibraryBooks size="20"/> Library
                                </NavLink>
                            </li>
                        }

                        {
                        currentRole !== "Teachers" &&
                            <li>
                                <NavLink to="/user/myAidaPal" activeClassName={styles.active}>
                                    <FaBookReader size="20"/> My AidaPal
                                </NavLink>
                            </li>
                        }

                        {/* {
                        currentRole === "Students" &&
                            <li>
                                <NavLink to="/user/exam" activeClassName={styles.active}>
                                    <FaEdit size="20"/> Exam
                                </NavLink>
                            </li>
                        } */}

                        <li>
                            <NavLink to="/user/calendar" activeClassName={styles.active}>
                                <FaRegCalendarAlt size="20"/> Calendar
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/user/notifications" activeClassName={styles.active}>
                                <MdNotifications size="22"/> Notifications
                            </NavLink>
                        </li>
                        
                        <li>
                            <NavLink to="/user/profile" activeClassName={styles.active}>
                                <MdAccountCircle size="22"/> Profile
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/user/connection" activeClassName={styles.active}>
                                <MdPeople size="22"/> Connection
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/user/feedback" activeClassName={styles.active}>
                                <MdFeedback size="20"/> Feedback
                            </NavLink>
                        </li>

                        {
                            currentRole !== "Teachers" &&
                            <li>
                                <NavLink to="/user/transaction" activeClassName={styles.active}>
                                    <MdPayment size="20"/> Transaction history
                                </NavLink>
                            </li>
                        }
                        
                        <li>
                            <NavLink to="/user/faq" activeClassName={styles.active}>
                                <MdQuestionAnswer size="20"/>FAQ
                            </NavLink>
                        </li>
                    </ul>
            </div>
        </div>
    )
}

export default LeftMenu;