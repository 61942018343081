import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import meeting from './styles/meeting.module.scss';
import iconExcellent from '../../../images/icon-excellent.svg';
import iconLike from '../../../images/icon-like.svg';
import iconNeutral from '../../../images/icon-neutral.svg';
import iconSleepy from '../../../images/icon-sleepy.svg';
import iconWorst from '../../../images/icon-worst.svg';
import Input from '../../../components/input';
import {toast} from 'react-toastify';
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';

const LiveFeedbackForm = ({classId, lessionId, setShowModal}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [feedback, setFeedback] = useState({
        rate: '',
        comment: ''
    });

    const onCommentChange = e => setFeedback({...feedback, comment: e.target.value});

    const onSubmitClick = () => {
        if(!feedback.rate) {
            toast.error('Please choose an emoticon!');
            return;
        }

        axios.post(API_URL.POST_CLASS_LIVE_FEEDBACK_URL, {feedback: feedback},{
            headers: authHeader(),
            params: {
                classId: classId,
                lessionId: lessionId
            },
        })
        .then(response => {
            toast.success("Thank you! Your feedback has been sent successfully!");
            setShowModal && setShowModal(false);
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })

    }

return (
    <div className={meeting.liveFeedback}>
        <h2>Feedback</h2>
        <div className={meeting.emoticon}>
            <label>Choose your feeling about the class</label>
            <div className={meeting.list}>
                <div onClick={()=>setFeedback({...feedback, rate: 'Worst'})} className={feedback.rate === "Worst" ? meeting.active : ''}>
                    <img src={iconWorst} alt="Worst" />
                    <span>Worst</span>
                </div>
                <div onClick={()=>setFeedback({...feedback, rate: 'Bored'})} className={feedback.rate === "Bored" ? meeting.active : ''}>
                    <img src={iconSleepy} alt="Bored" />
                    <span>Bored</span>
                </div>
                <div onClick={()=>setFeedback({...feedback, rate: 'Normal'})} className={feedback.rate === "Normal" ? meeting.active : ''}>
                    <img src={iconNeutral} alt="Normal" />
                    <span>Normal</span>
                </div>
                <div onClick={()=>setFeedback({...feedback, rate: 'Good'})} className={feedback.rate === "Good" ? meeting.active : ''}>
                    <img src={iconLike} alt="Good" />
                    <span>Good</span>
                </div>
                <div onClick={()=>setFeedback({...feedback, rate: 'Excellent'})} className={feedback.rate === "Excellent" ? meeting.active : ''}>
                    <img src={iconExcellent} alt="Excellent" />
                    <span>Excellent</span>
                </div>
            </div>
        </div>
        <div>
            <Input 
                type="textarea"
                value={feedback.comment}
                onChange={onCommentChange}
                label="Comment"
                autoFocus={true}
            />
        </div>
        <div>
            <button className="btn" onClick={onSubmitClick}>Submit</button>
        </div>
    </div>
)}

export default LiveFeedbackForm;