// import soundSanta from './sounds/santa.mp3';
import { creditIcon, toastCredit } from '../../common/utils';
import { FaCheck, FaFastForward, FaFileAlt, FaFileAudio, FaRegClock, FaTimes } from 'react-icons/fa';
import { useState } from 'react';
import { MdArrowDownward, MdArrowUpward, MdPerson } from 'react-icons/md';
import {GrInProgress} from 'react-icons/gr';
import { BsPencilSquare } from 'react-icons/bs';
import { useStore } from '../../store/useStore';

const Result = ({className, allResults, setShowModal, hideResult, setHideResult}) => {
    const [currentQuestion, setCurrentQuestion] = useState('');
    const { setToastContent, moreInfo, setMoreInfo} = useStore();

    const onClose = () => {
        if (allResults.credit) {
            setToastContent && setToastContent(toastCredit('+', allResults.credit));
            moreInfo.credit = moreInfo.credit + allResults.credit;
            setMoreInfo({...moreInfo});
        }
        setShowModal && setShowModal(false);
        setHideResult(true);
        window.history.back();
    }

    return (
        <div className={className.resultModal} style={{display: hideResult ? 'none' : ''}}>
            {
                allResults.questionsList && allResults.questionsList.length > 0 &&
                <div className={className.allResults}>
                    <h2>{allResults.challengeStatus ? allResults.challengeStatus : 'COMPLETED'}</h2>
                    <div className={className.content}>
                    {
                        allResults.challengeMembers && allResults.challengeMembers.length > 0 &&
                        <div className={className.members}>
                            <ul>
                                {
                                    allResults.challengeMembers.map((item, index) => 
                                        <li key={`members${index}`}>
                                            <div className={className.user}>
                                            {
                                                item.userImageUrl &&
                                                <img 
                                                    src={item.userImageUrl} 
                                                    alt="user image" 
                                                    width="100"
                                                />
                                            }
                                            {
                                                !item.userImageUrl &&
                                                <MdPerson size="100" color="#ddd"/>
                                            }
                                            </div>
                                            <h4>{item.userFullName}</h4>
                                            <div className={className.result}>{item.result}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    }
                    <div className={className.credit}>
                        <div>
                            Earned <strong>{creditIcon(allResults.credit >= 0 && allResults.bonus >= 0 ? allResults.credit + allResults.bonus : allResults.credit ? allResults.credit : 0)}</strong>
                            { allResults.bonus && 
                            <div>
                                <p>
                                    <label>Worksheet</label>{creditIcon(allResults.credit, 25)}<br />
                                    <label>Bonus</label>{creditIcon(allResults.bonus, 25)}
                                </p>
                                <p>
                                    <label>Total</label><strong>{creditIcon(allResults.totalCredit, 25)}</strong> <br />
                                    <label>Rank</label>{allResults.rankChange === "up" ? <MdArrowUpward /> : <MdArrowDownward />} {allResults.rank}
                                </p>
                            </div>
                            } 
                        </div>
                        <ul>
                            <li><FaCheck size={20} color='green' /> {allResults.totalCorrect}</li>
                            <li><FaTimes size={20} color='red' /> {allResults.totalInCorrect}</li>
                            <li><FaFastForward size={20} color='grey' /> {allResults.totalSkip}</li>
                        </ul>
                    </div>
                    <div style={{textAlign: 'center', margin: 10}}><FaRegClock size={20} color='#888'/> {allResults.totalTime >  60 ? Math.floor(allResults.totalTime/60) + " minutes " +  allResults.totalTime % 60 + " seconds" : allResults.totalTime + " seconds"}</div>
                    <div className={className.details}>
                        <h3>Details</h3>
                        <ul>
                            {
                                allResults.questionsList.map((item, index) => 
                                    <li className={currentQuestion === item.questionId ? className.active : undefined} key={`q ${index}`}>
                                        <p onClick={() => setCurrentQuestion(currentQuestion === item.questionId ? '' : item.questionId)}>
                                            {item.answerStatus === "correct" && <FaCheck color='green'/>}
                                            {item.answerStatus === "incorrect" && <FaTimes color='red'/>}
                                            {item.answerStatus === "skip" && <FaFastForward color='grey'/>}
                                            {item.answerStatus === "manual" && <BsPencilSquare color='black' />}
                                            {item.answerStatus === 'inprogress' && <GrInProgress color='orange' />}
                                            <label>Question {index + 1}</label>
                                            {
                                                item.credit > 0 &&
                                                <strong>+ {creditIcon(item.credit)}</strong>
                                            }
                                            
                                        </p>
                                        <div>
                                            <div>
                                                <span>Student's answer: </span>
                                                {
                                                    item.studentAnswer && item.studentAnswer.length > 0 &&
                                                    item.studentAnswer.map(itm => {
                                                        if (itm.startsWith('http') && itm.questionType === 'recording') { 
                                                            return <FaFileAudio size={30} color='#434343' key={`audo${index}`} />
                                                        }
                                                        if (itm.startsWith('http') && itm.questionType !== 'recording') { 
                                                            return <FaFileAlt size={30} color='#434343' key={`audo${index}`} />
                                                        }
                                                    return <div dangerouslySetInnerHTML={{__html: item.questionType === 'choice' ?  parseInt(itm)+1 : itm}} />
                                                })
                                                }
                                                {
                                                    (!item.studentAnswer || item.studentAnswer.length === 0) &&
                                                    <>--</>
                                                }
                                            </div>
                                            {
                                                item.answerStatus !== 'manual' &&
                                                <div className={className.correct}>
                                                    <span>Correct answer:</span>
                                                    {
                                                        item.correctAnswer && item.correctAnswer.length > 0 &&
                                                        item.correctAnswer.map((itm, index) => <div key={`correct${index}`} dangerouslySetInnerHTML={{__html: item.questionType === 'choice' ?  parseInt(itm)+1 : itm.replace(/(\|){2}/g, '<strong class="orOption">OR</strong>')}} />)
                                                    }
                                                    {
                                                        (!item.correctAnswer || item.correctAnswer.length === 0) &&
                                                        <>--</>
                                                    }
                                                </div>
                                            }
                                            {
                                                item.explanation &&
                                                <div className={className.explan}>
                                                    <span>Explanation: </span><div dangerouslySetInnerHTML={{__html: item.explanation}} />
                                                </div>
                                            }
                                            
                                        </div>
                                        
                                    </li>    
                                )
                            }
                        </ul>
                    </div>
                    </div>
                    <div className='buttons'>
                        <button onClick={onClose}>Close</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Result;