import classNames from 'classnames';

const Themes = ({styles, setShowModal, customValue, setCustomValue, savedCustomtheme, theme, setTheme, saveTheme}) => {
  const customThemeOnchange = () => {
    setShowModal(true);
    setCustomValue(savedCustomtheme ? JSON.parse(savedCustomtheme) : {
      color: '#FFFFFF',
      images: [],
        box: {
          textColor: '#000000',
          boxColor: '#FFFFFF',
          boxColorOpacity: 1,
          effect: 0,
          shadowColor: '#FFFFFF'
        }
    });
  }

  const changeThemeBox = (boxName) => {
    setTheme({...theme, box: boxName});
    localStorage.setItem('user-theme-box', boxName);
    setCustomValue({...customValue, box: null});
    localStorage.removeItem('user-custom-theme');
    saveTheme();
  }

  const changeThemeBg = (themeId) => {
    let themeBox = 'theme-box1';
    if (['theme-bg5','theme-bg15'].includes(themeId)) {
      themeBox = 'theme-box3';
    }
    if (['theme-bg7','theme-bg10'].includes(themeId)) {
      themeBox = 'theme-box5';
    }
    if (['theme-bg8', 'theme-bg9','theme-bg11', 'theme-bg12'].includes(themeId)) {
      themeBox = 'theme-box2';
    }
    if (themeId === 'theme-bg17') {
      themeBox = 'theme-box7';
    }
    if (themeId === 'theme-bg18') {
      themeBox = 'theme-box6';
    }
    if (themeId === 'theme-bg19') {
      themeBox = 'theme-box8';
    }
    if (['theme-bg3','theme-bg14','theme-bg20'].includes(themeId)) {
      themeBox = 'theme-box9';
    }
    if (['theme-bg16'].includes(themeId)) {
      themeBox = 'theme-box10';
    }
    
    setTheme({...theme, bg: themeId, box: themeBox});
    localStorage.setItem('user-theme-bg', themeId);
    localStorage.setItem('user-theme-box', themeBox);
    setCustomValue({...customValue, images: [], color: null});
    localStorage.removeItem('user-custom-theme');
    saveTheme();
  }

  return (
    <div className={styles.chooseBg}>
        <a href="#" className={styles[theme.bg]}></a>
        <div className={styles.changeTheme}>
            <label>Background</label>
            <div className={styles.bg}>
            {
                Array.from(Array(20)).map(
                    (item, index) => 
                    <span className={classNames(styles['theme-bg'+(index+1)],{[styles.active]: theme.bg === 'theme-bg'+(index+1)})} 
                    onClick={() => changeThemeBg('theme-bg'+(index+1))} key={`theme-bg-${index}`}></span>
                )
            }
            </div>
            <label>Boxes</label>
            <ul>
              <li className={classNames(styles.setColors,{[styles.active]: theme.box === 'theme-box1'})} onClick={() => changeThemeBox('theme-box1')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors2,{[styles.active]: theme.box === 'theme-box2'})} onClick={() => changeThemeBox('theme-box2')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors3,{[styles.active]: theme.box === 'theme-box3'})} onClick={() => changeThemeBox('theme-box3')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors4,{[styles.active]: theme.box === 'theme-box4'})} onClick={() => changeThemeBox('theme-box4')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors5,{[styles.active]: theme.box === 'theme-box5'})} onClick={() => changeThemeBox('theme-box5')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors6,{[styles.active]: theme.box === 'theme-box6'})} onClick={() => changeThemeBox('theme-box6')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors7,{[styles.active]: theme.box === 'theme-box7'})} onClick={() => changeThemeBox('theme-box7')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.setColors, styles.setColors8,{[styles.active]: theme.box === 'theme-box8'})} onClick={() => changeThemeBox('theme-box8')}>
                <div><span></span></div>
              </li>
              <li className={classNames(styles.white,{[styles.active]: theme.box === 'theme-box9'})} onClick={() => changeThemeBox('theme-box9')}>
                <div></div>
              </li>
              <li className={classNames(styles.black,{[styles.active]: theme.box === 'theme-box10'})} onClick={() => changeThemeBox('theme-box10')}>
                <div></div>
              </li>
            </ul>
            <div className='rightAlign'>
              <button onClick={customThemeOnchange}>Custom theme</button>
            </div>
          </div>
    </div>
  )
}

export default Themes;