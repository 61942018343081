import uniqueId from 'lodash/uniqueId';
import classItem from './classItem.module.scss';
import {useStore} from '../../store/useStore';
import {toast} from 'react-toastify';

const CartOptions = ({productData, showOptions, setShowOptions, classId, isInCart, setCurrentOption, isLeftDropdown}) => {
    let {cartNumber, setCartNumber, cartItems, setCartItems} = useStore();

    const addToCart = (index, optionId) => {
        if(!isInCart) {
            //add new option
            setCartNumber(cartNumber+1);
            localStorage.setItem('EduTech-CartNumber', cartNumber+1);
            let item = productData && productData.cartOptions && productData.cartOptions[index];
            item = {
                ...item,
                classId,
                title: productData.title,
                grade: productData.grade
            };
            cartItems.splice(index,0,item);
            setCurrentOption && setCurrentOption(`${item.noOfLessions} lessons - $${item.price}`);
            toast.info(`${item.title} has been added to cart!`);
        } else {

            if(optionInCart(optionId)) {
                //remove chosen option
                setCartNumber(cartNumber - 1);
                localStorage.setItem('EduTech-CartNumber', cartNumber-1);
                const itemIndex = cartItems.findIndex(item => item.classId === classId);
                cartItems.splice(itemIndex, 1);
                setCurrentOption && setCurrentOption("");
                toast.info(`Item has been removed!`);
            } else {
                //replace current option
                const itemIndex = cartItems.findIndex(item => item.classId === classId);
                let item = productData && productData.cartOptions && productData.cartOptions[index];
                item = {
                    ...item,
                    classId,
                    title: productData.title,
                    grade: productData.grade
                };
                cartItems.splice(itemIndex, 1, item);
                setCurrentOption && setCurrentOption(`${item.noOfLessions} lessons - $${item.price}`);
                toast.info(`"${item.noOfLessions} lessons" is updated in cart`);
            }
        }
        setCartItems(cartItems);
        localStorage.setItem('EduTech-CartItems', JSON.stringify(cartItems));
        setShowOptions(false);
    }

    const optionInCart = (optionId) => {
        const isSameOptionId = cartItems && cartItems.length && 
            cartItems.filter(x => x.classId === classId).map(itm => itm.optionId).includes(optionId);
        return isSameOptionId;
    }

    return (
        <ul 
            className={classItem.cartOptions} 
            style={{
                display: showOptions ? "": "none",
                left: isLeftDropdown ? -220 : 0
            }}
        >
            {productData && productData.cartOptions && productData.cartOptions.length > 0 &&
                productData.cartOptions.map((item, index) => (
                    <li 
                        key={uniqueId()}
                        onClick={() => addToCart(index, item.optionId)} 
                        className={optionInCart(item.optionId) ? classItem.isInCart : ""}
                    >
                        <div className={classItem.price}>
                            <strong>{item.price}</strong>
                            {item.oldPrice && item.oldPrice !== item.price && 
                                <span>{item.oldPrice}</span>
                             }
                        </div>
                        <div className={classItem.time}>
                            <strong>{item.noOfLessions} lessons</strong>
                            <span>{item.time}</span> 
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default CartOptions