import API_URL from "../../common/urls";
import TestPlay from "../../components/testPlay";

const ShareQuestion = ({match}) => {
    const id = match.params.qId;
    const themeId = match.params.themeId;

    return (
        <div>
            <TestPlay
                showModal={true} 
                contentRef={null}
                themeId={themeId}
                isTeacherPreview={false}
                worksheetId={null}
                questionId={id}
                apiUrl={API_URL.GET_PUBLIC_TEST_URL}
            />
        </div>
        
    )
}

export default ShareQuestion;