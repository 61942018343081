import {useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import useClickOutside from '../../../hooks/useClickOutside';
import classNames from 'classnames';
import connection from './connection.module.scss';
import {MdPerson} from 'react-icons/md';
import {Link} from 'react-router-dom';
import Modal from '../../../components/modal';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {getError} from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';

const ConnectionItem = ({item, isPending, getList}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [itemActive, setItemActive] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [request, setRequest] = useState({action: '', userName: null, fullName: '', reportContent: ''});
    const contentRef = useRef();
    const buttonRef = useRef();
    useClickOutside(buttonRef, () => setItemActive(false));

    const onApproveRequest = (e) => {
        e.preventDefault();
        setRequest({action: 'approve request', userName: item.userName, fullName: item.fullName})
        setShowModal(true);
        setItemActive(false);
    }

    const onCancelRequest = (e) => {
        e.preventDefault();
        setRequest({action: 'cancel request', userName: item.userName, fullName: item.fullName})
        setShowModal(true);
        setItemActive(false);
    }

    const onDisconnect = (e) => {
        e.preventDefault();
        setRequest({action: 'disconnect', userName: item.userName, fullName: item.fullName})
        setShowModal(true);
        setItemActive(false);
    }

    const onBlock = (e) => {
        e.preventDefault();
        setRequest({action: 'block', userName: item.userName, fullName: item.fullName})
        setShowModal(true);
        setItemActive(false);
    }

    const onReport = (e) => {
        e.preventDefault();
        setRequest({...request, action: 'report', userName: item.userName, fullName: item.fullName})
        setShowModal(true);
        setItemActive(false);
    }

    const onSetItemActive = () => {
        setItemActive(!itemActive);
    }

    const onConfirm = () => {
        const data = {userName: request.userName, action: request.action, reportContent: request.reportContent};
        if (request.action === "report" && request.reportContent === '') {
            toast.error('Please add reason!');
            return;
        }
        axios.post(API_URL.USER_CONNECTIONS_REQUEST_ACTION_URL,data,{
            headers: authHeader()
        })
        .then(response => {
            toast.success(
                request.action === "disconnect" ? `${item.fullName} has been disconnected!` : 
                `Request Connection to ${item.fullName} has been cancelled!`
                );
            getList && getList(true);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);  
            }
        });

        setShowModal(false);
    }

    const onCancel = () => {
        setShowModal(false);
    }

    return (
        <li ref={buttonRef}>
            {(item.imageUrl || item.userImageUrl) && 
                <img src={item.imageUrl || item.userImageUrl} alt="profile" />
            }

            {!item.imageUrl && !item.userImageUrl && 
                <MdPerson size="70" color="#ccc"/>
            }

            <strong>
                {item.fullName}
            </strong>

            <span>
                {item.relationship}
            </span>

            <button
                className={classNames(connection.more,{[connection.active]: itemActive})} 
                onClick={onSetItemActive}
            >
                &bull;&bull;&bull;
            </button>

            <div className={connection.dropdown}>
                {isPending && !item.askConnect && 
                    <Link to="#" onClick={(e) => onApproveRequest(e)}>Approve Request</Link>
                }
                {isPending && 
                    <Link to="#" onClick={(e) => onCancelRequest(e)}>Cancel Request</Link>
                }
                {!isPending && 
                    <Link to="#" onClick={(e) => onDisconnect(e)}>Disconnect</Link>
                }
                <Link to="#" onClick={(e) => onBlock(e)}>Block</Link>
                <Link to="#" onClick={(e) => onReport(e)}>Report</Link>
            </div>
            <Modal 
                    setShowModal={setShowModal} 
                    showModal={showModal} 
                    contentRef={contentRef}
                    width="400px"
                    height="auto"
                    content={
                        <div ref={contentRef} className={connection.confirmBox}>
                            Are you sure you want to <strong className='red'>{request.action}</strong> <strong>{request.fullName}</strong>?
                            {
                                request.action === "block" &&
                                <p>You will be no longer in touch or search or view sharing, gift from this user</p>
                            }
                            {
                                request.action === "report" &&
                                <div>
                                    <label>Reason</label>
                                    <textarea onChange={e => setRequest({...request, reportContent: e.target.value})} />
                                </div>
                            }
                            <div className={connection.buttons}>
                                <button className="btn" onClick={onConfirm}>
                                    Confirm
                                </button>
                                <button onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    } 
                />
        </li>
    )
}

export default ConnectionItem;