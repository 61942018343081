import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import styles from '../styles.module.scss';
import lib from './lib.module.scss';
import Folders from "./folders";
import { OPTIONS_TYPE } from "../../../common/utils";
import { authHeader } from '../../../services/auth-header';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../../../common/utils';
import { MdFileUpload, MdKeyboardArrowDown } from "react-icons/md";
import useClickOutside from "../../../hooks/useClickOutside";
import Filter from "./filter";
import Input from '../../../components/input';
import { FaQuestionCircle } from "react-icons/fa";
import { MdInsertDriveFile } from "react-icons/md";
import assignmentIcon1 from '../../../images/assign1.svg';
import folder from "../../../images/folder.svg";
import { MdOutlineViewList, MdOutlineViewModule } from "react-icons/md";

const NewItem = ({onClick, type, setIsClone}) => {
    const [showNewTypes, setShownewTypes] = useState(false);
    const contentRef = useRef();
    useClickOutside(contentRef, () => setShownewTypes(false));

    const buttonOnClick = (type) => {
        onClick && onClick(type);
        setShownewTypes(false);
        if(type === 'question') {
            setIsClone && setIsClone(true); //use isClone as creating new item
        }
    }

    return (
        <div ref={contentRef} className={lib.new}>
            <span className={`${lib.fileIcon} ${lib.newFile}`} onClick={() => setShownewTypes(!showNewTypes)}>+</span>
            <div style={{display: showNewTypes? "block": "none"}}>
                <button onClick={() => buttonOnClick('folder')}><img src={folder} width="20"/> Folder</button>
                {
                    type === "files" &&
                    <button onClick={() => buttonOnClick('file')}><MdInsertDriveFile size="20" color="#0ea7d2" /> File</button>
                }
                {
                    type === "worksheets" &&
                    <button onClick={() => buttonOnClick('worksheet')}><img width={20} src={assignmentIcon1} /> Worksheet</button>
                }
                {
                    type === "questions" &&
                    <>
                    <button onClick={() => buttonOnClick('question')}><FaQuestionCircle size="20" color="#aaa"/> Question</button>
                    <button onClick={() => buttonOnClick('questions list')}><MdFileUpload size="20" color="#0ea7d2" /> Questions List</button>
                    </>
                }
            </div>
        </div>
    )
}

const LibraryContent = ({privacy, folderId, activeTab, parentTab}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [sortValue, setSortValue] =  useState();
    const [search, setSearch] = useState('');
    const [filtersData, setFiltersData] = useState({});
    const [reloadList, setReloadList] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('new');
    const [newType, setNewType] = useState('folder');
    const [isClone, setIsClone] = useState(false);
    const [isGrid, setIsGrid] = useState(true);

    const [filters, setFilters] = useState({
        fromDate: '',
        toDate: '',
        levels: [],
        subjects: [],
        isFavorite: false,
        authors: [],
        fileTypes: [],
        worksheetType: '',
        topics: [],
        subTopics: [],
        tags: [],
        questionType:[],
        sizeLarger: null,
        sizeSmaller: null,
    });

    const contentRef = useRef();
    useClickOutside(contentRef, () => setShowFilter(false));

    const sortOnChange = (type) => setSortValue(type);
    const searchOnChange = (e) => setSearch(e.target.value);

    useEffect(()=>{
        const savedFilters = localStorage.getItem('library-filter');
        if(savedFilters) {
            setFilters(JSON.parse(savedFilters));
        }
        axios.get(API_URL.GET_LIBRARY_FILTERS_LIST_URL,{
            headers: authHeader(),
            params: {
            }
        })
        .then(response => {
            if(response.data) {
                 setFiltersData(response.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[]);

    const newHandle = (type) => {
        setShowModal(true);
        setNewType(type);
        setModalType('new');
    }

    const isGridOnChange = () => setIsGrid(!isGrid);

    return(
        <div className={styles.whiteBox2}>
            <div className={styles.topNav}>
                <Input
                    type="text" 
                    placeholder="Search" 
                    className={lib.searchBox}
                    onChange={searchOnChange}
                    value={search}
                    label=""
                />
                {
                    !isGrid &&
                    <MdOutlineViewModule className={styles.relativeBtn} onClick={isGridOnChange} size="25" title="Grid View" />
                }
                
                {
                    isGrid &&
                    <MdOutlineViewList className={styles.relativeBtn} onClick={isGridOnChange} size="25" title="List View"/>
                }
            </div>
            <div>
                <div className={lib.filterBar} style={{paddingLeft: privacy === "myLib" ? 70 : 10}}>
                    {
                        privacy === "myLib" &&
                        <NewItem onClick={newHandle} type={activeTab} setIsClone={setIsClone}/>
                    }
                    
                    <div className={lib.filter}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setShowFilter(true)
                        }}>Filter <MdKeyboardArrowDown size="16" color="#888" /></a>
                    </div>
                    <div className={lib.sort}>
                        <Input
                            className={lib.select}
                            type="select"
                            options={OPTIONS_TYPE.SORT_OPTIONS}
                            placeholder='Sort By'
                            onChange={sortOnChange}
                            value={sortValue}
                            label=""
                        />
                    </div>
                    {
                        showFilter &&
                        <Filter 
                            lib={lib} 
                            filtersData={filtersData} 
                            filters={filters} 
                            setFilters={setFilters} 
                            ref={contentRef}
                            type={activeTab}
                            setShowFilter={setShowFilter}
                        />
                    }
                    
                </div>
                <div>
                    <Folders
                        privacy={privacy}
                        type={activeTab}
                        parentTab={parentTab}
                        sort={sortValue && sortValue.value}
                        search={search}
                        filters={filters}
                        reloadList={reloadList}
                        setReloadList={setReloadList}
                        folderId={folderId}
                        isGrid={isGrid}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        modalType={modalType}
                        setModalType={setModalType}
                        newType={newType}
                        setNewType={setNewType}
                        isClone={isClone} 
                        setIsClone={setIsClone}
                    />
                </div>
            </div>
        </div>
    )
}

export default LibraryContent;