import { useState } from "react";
import { FaFolder } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdLanguage } from "react-icons/md";
import styles from './styles.module.scss';
import {uniqueId} from 'lodash';
import folder from "../../images/folder.svg";

const FoldersTree = ({folders, setSelectFolder, selectFolder, disableFolders}) => {

    const Item = ({item, setSelectFolder, selectFolder, disableFolders}) => {
        const itemOnclick = (id) => {
            setSelectFolder && setSelectFolder(id);
        }

        return (
            <li>
                <div className={`${styles.open}
                ${disableFolders && disableFolders.includes(item.folderId) ? styles.disabled : ''}`}>
                    <img src={folder} width="20" />
                    <span
                        onClick={() => itemOnclick(item.folderId)}
                        className={`${selectFolder === item.folderId ? styles.active : ''}`}
                    >
                        {item.name}
                    </span>
                </div>
                {
                    item.childFolders && item.childFolders.length > 0 &&
                    <Children
                        list={item.childFolders}
                        selectFolder={selectFolder}
                        setSelectFolder={setSelectFolder}
                        disableFolders={disableFolders}
                    /> 
                }
            </li>
        )
    }

    const Children = ({list, setSelectFolder, selectFolder, disableFolders}) => {
        return (
            <ul>
                {
                    list.map(item =>
                        <Item
                            item={item}
                            setSelectFolder={setSelectFolder}
                            selectFolder={selectFolder}
                            disableFolders={disableFolders}
                            key={uniqueId()}
                        />   
                    )
                }
            </ul>
            
        )
    }
    return(
        <ul className={styles.folderTree}>
            <li>
                <div className={styles.open}>
                    <MdLanguage color="#007dc8" size="23" />
                    <span className={!selectFolder ? styles.active : ''} onClick={() => setSelectFolder('')}>Root</span></div>
                    {folders.length > 0 &&
                        <Children
                            list={folders}
                            selectFolder={selectFolder}
                            setSelectFolder={setSelectFolder}
                            disableFolders={disableFolders}
                        />
                    }
            </li>

        </ul>
    )
};

export default FoldersTree;
