import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import {getError} from '../../common/utils';
import input from './input.module.scss';
import {MdErrorOutline} from 'react-icons/md';
import TimeCount from './timeCount';

const InputVerify = ({
        type, verifyType, inputClass, value, setIsDisabled, placeholder, onChange,
        defaultValue, onKeyPress, apiUrl, seconds, messageTimeOut, valueType, disabled,
        isShowTime, setIsShowTime, className
    }) => {
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerifiedSuccess, setIsVerifiedSuccess] = useState(false);
    const [isVerifiedFail, setIsVerifiedFail] = useState(false);

    useEffect(()=>{
        if(seconds > 0) {
            setIsShowTime && setIsShowTime(true);
        } else {
            setIsShowTime && setIsShowTime(false);
        }
    },[seconds]);

    const onBlur = () => {
        setIsVerifying(true);

        if(!value) {
            toast.error(`${placeholder} is empty!`);
            setIsVerifying(false);
            setIsVerifiedFail(true);
            setIsDisabled && setIsDisabled(true);
            return;
        }

        if(valueType === "userName") {
            
            const userReg = /^[a-zA-Z0-9@\.+-_]+$/;
            if(!userReg.test(value)) {
                toast.error(`Invalid userName, userName contains only letters, numbers, and @/./+/-/_ characters`);
                setIsVerifying(false);
                setIsVerifiedFail(true);
                setIsDisabled && setIsDisabled(true);
                return;
            }
        }

        if(type === "email") {
            const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if(!emailReg.test(value)) {
                toast.error(`You have entered an invalid email address!`);
                setIsVerifying(false);
                setIsVerifiedFail(true);
                setIsDisabled && setIsDisabled(true);
                return;
            }
        }

        if(apiUrl) {
            axios.post(apiUrl,{[valueType]: value})
            .then(response => 
                {
                    setIsVerifying(false);
                    setIsVerifiedSuccess(true);
                    setIsDisabled && setIsDisabled(false);
                }
            )
            .catch(error => {
                setIsVerifying(false);
                setIsVerifiedFail(true);
                const resMessage = getError(error);
                toast.error(resMessage);
                setIsDisabled && setIsDisabled(true);
            })
        }
    }

    const onFocus = () => {
        setIsVerifying(false);
        setIsVerifiedSuccess(false);
        setIsVerifiedFail(false);
    }

    return(
      <div className={input.inputVerify}>  
        <input
          className={inputClass}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled ? true : verifyType === "timeCount" ? !isShowTime : false}
          defaultValue={defaultValue}
          onKeyPress={onKeyPress}
        />
        {
            verifyType === "checkExisted" &&
                <>
                <span className={input.inputLoading} style={{display: isVerifying ? "block": "none"}}>
                    <i className={input.loader}></i>
                </span>
                <span className={input.inputSuccess} style={{display: isVerifiedSuccess ? "block": "none"}}></span>
                <MdErrorOutline size="25" className={input.inputFail} style={{display: isVerifiedFail ? "block": "none"}}/>
                </>
        }
        {
            verifyType === "timeCount" &&
            <TimeCount 
                setIsShowTime={setIsShowTime} 
                seconds={seconds} 
                messageTimeOut={messageTimeOut} 
                style={{display: isShowTime ? "block": "none"}}
                className={className}
            />
        }
        
     </div>   
    )
}

export default InputVerify;