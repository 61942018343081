import { useState, useEffect, useRef } from "react";
import API_URL from "../../../common/urls";
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError, questionColor } from '../../../common/utils';
import { useStore } from "../../../store/useStore";
import { useHistory } from "react-router";
import { Logout } from "../../../services/auth.service";
import { Loader } from "../../../components/loading";
import { FaPlus, FaMinus } from "react-icons/fa";
import UploadFiles from "./uploadFile";
import {uniqueId, uniq} from 'lodash';
import { FaQuestion } from "react-icons/fa";
import { MdFilterList } from "react-icons/md";
import useClickOutside from "../../../hooks/useClickOutside";
import QuestionFilter from "./qFilter";
import Controls from "./itemControls";

const Step3 = ({lib, formData, setFormData, type, filtersData, showQuestionForm, setShowQuestionForm, setSelectQItem,
    setWsFile, wsFile, setIsClone, worksheetEdit, setStep, uploadLoading}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const [loading, setLoading] = useState(formData.worksheetMethod === "select" ? true : null);
    const [selectItems, setSelectItems] = useState([]);
    const [openControl, setOpenControl] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [oldList, setOldList] = useState([]);

    const controlRef = useRef();
    const listRef = useRef();
    useClickOutside(listRef, () => { 
        setSelectItems([]);
        setOpenControl(false);
    });
    
    const [filters, setFilters] = useState({
        fromDate: '',
        toDate: '',
        search: '',
        levels: formData.levels ? formData.levels : [],
        subjects: formData.subjects ? formData.subjects : [],
        authors: [],
        topics: formData.topics ? formData.topics : [],
        subTopics: formData.subTopics ? formData.subTopics : [],
        tags: [],
        questionType:[]
    });
    const [questionsList, setQuestionsList] = useState([]);
    const [questionContent, setQuestionContent] = useState({title: '', content: '', tags: '', difficulty: ''});
    const [activeQ, setActiveQ] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const selectQuestionsListId = formData.questions.map(item => (item.id || item.tempQuestionId));
    const filterRef = useRef();
    useClickOutside(filterRef, ()=> setShowFilter(false));

    const selectQuestionHandler = (item, index) => {
        if (selectQuestionsListId.includes(item.id)) {
            const _index = formData && formData.questions && item ? formData.questions.indexOf(item) : null;
            _index >=0 && formData.questions.splice(_index, 1);
        } else {
            formData.questions.push(item);
            oldList.push(index);
        }
        setFormData && setFormData({...formData, questions: formData.questions});
        setOldList([...oldList]);
    }

    const viewQuestionHandler = (item, isTemp) => {
        !isTemp && axios.get(API_URL.GET_LIBRARY_ITEM_CONTENT_URL,{
            headers: authHeader(),
            params: {
                type: 'question',
                id: item.id,
            }
        })
        .then(response => {
            setLoading(false);
            response.data && setQuestionContent({title: response.data.title, content: response.data.content, 
                tags: response.data.tags && response.data.tags.length > 0 && response.data.tags.map(item => item.label).join(' ,'), difficulty: response.data.difficulty && response.data.difficulty.label});
            setActiveQ(response.data.id);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
        isTemp && setQuestionContent({title: item.title, content: item.content, 
            tags: item.tags && item.tags.length > 0 && item.tags.map(item => item.label).join(' ,'), difficulty: item.difficulty && item.difficulty.label});
        isTemp && setActiveQ(item.tempQuestionId);
    }

    useEffect(()=>{
        setLoading(true);
        formData.worksheetMethod === "select" && getList();
    },[filters]);

    const getList = () => {
        return axios.get(API_URL.GET_LIBRARY_QUESTIONS_LIST_URL,{
            headers: authHeader(),
            params: {
                fromDate: filters.fromDate ? filters.fromDate : null,
                toDate: filters.toDate ? filters.toDate : null,
                search: filters.search ? filters.search : null,
                levels: filters.levels.length ? filters.levels.map(item => item.value).join(',') : null,
                subjects: filters.subjects ? filters.subjects.map(item => item.value).join(',') : null,
                authors: filters.authors ? filters.authors.map(item => item.value).join(',') : null,
                topics: filters.topics ? filters.topics.map(item => item.value).join(',') : null,
                subTopics: filters.subTopics ? filters.subTopics.map(item => item.value).join(',') : null,
                tags: filters.tags ? filters.tags.map(item => item.value).join(',') : null,
                questionType:filters.questionType ? filters.questionType.map(item => item.label).join(',') : null,
                worksheetId: worksheetEdit && formData.id ? formData.id : null,
            }
        })
        .then(response => {
            if(response.data && response.data.list) {
                setQuestionsList(response.data.list);
            }
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            const resMessage = getError(error);            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const onAddQuestion = () =>  {
        setSelectQItem && setSelectQItem(null);
        setShowQuestionForm && setShowQuestionForm(true);
        setIsClone && setIsClone(true); //use isClone as creating new item
    }

    const selectItem = (e, id, name, isRightClick, questionIndex, allowClone) => {
        if(selectItems && setSelectItems) {
            const selectedIds = selectItems.map(item => item.id);
            if(selectedIds.includes(id)) {

                if(!isRightClick) {
                    //remove current id
                    const index = selectedIds && id ? selectedIds.indexOf(id) : null;
                    index && selectItems.splice(index, 1);

                    setSelectItems([...selectItems]);
                }
                
            } else {
                selectItems.push({
                    id: id,
                    name: name,
                    allowClone: allowClone,
                    questionIndex: questionIndex
                });
                setSelectItems([...selectItems]);
            }
            
            setOffsetX(e.nativeEvent.clientX + 20);
            setOffsetY(e.nativeEvent.clientY);
        }
    }

    const openControlHandler = (e) => {
        e.preventDefault();
        setOpenControl && setOpenControl(true);
    }

    const controlOnClick = (type) => {
        setOpenControl(false);
        setSelectItems([]);
        if (type === "delete") {
            
            const selectIds = selectItems.map(item => item.id);
            const formQuestionsIds = formData.questions.map(item => item.id ? item.id : item.tempQuestionId);
            
            for(var i = 0 ; i < selectIds.length; i++) {
                const index = formQuestionsIds && formQuestionsIds.length && formQuestionsIds.indexOf(selectIds[i]);
                formData.questions.splice(index, 1);
            }

            setFormData({...formData});
            setSelectItems([]);
        }

        if (type === "edit") {
            setSelectQItem && setSelectQItem(selectItems[0]);
            setShowQuestionForm && setShowQuestionForm(true);
            setIsClone && setIsClone(false);
        }

        if (type === "copy") {
            setSelectQItem && setSelectQItem(selectItems[0]);
            setShowQuestionForm && setShowQuestionForm(true);
            setIsClone && setIsClone(true);
        }
    }

    return (
        <div className={lib.autoScroll}>
            {
                formData.worksheetMethod === "select" &&
                <div className={lib.selectQuestions}>
                    {
                        !loading && questionsList &&
                        <div className={lib.questionsFilter}>
                            <a href="#" onClick={() => setShowFilter(true)}><MdFilterList /> Filter</a>
                            {
                                showFilter &&
                                <QuestionFilter 
                                    lib={lib} 
                                    filtersData={filtersData}
                                    filters={filters}
                                    setFilters={setFilters}
                                    ref={filterRef}
                                    setShowFilter={setShowFilter}
                                />
                            }
                        </div>
                    }
                    {
                        loading && <Loader />
                    }
                    {
                        !loading && questionsList && questionsList.length === 0 &&
                        <div className='emptyResult'>No resource available.</div>
                    }
                    {
                        !loading && questionsList && questionsList.length > 0 &&
                    <div className={lib.questions}>
                        <label>Select Questions</label>
                        <div>
                            <div className={lib.questionsList}>
                                {
                                    questionsList.map((item, index) => 
                                        <li onClick={() => viewQuestionHandler(item)} key={uniqueId()} className={`${selectQuestionsListId.includes(item.id) ? lib.select : ''} ${activeQ === item.id ? lib.view : ''}`}>
                                            <span>
                                                Question {index+1}
                                            </span>
                                            <span>{item.name}</span>
                                            <FaPlus onClick={() => selectQuestionHandler(item, index)}/>
                                        </li>
                                    )
                                }
                            </div>
                            <div className={`${lib.questionsList} ${formData.questions.length > 0 ? lib.open : ""}`}>
                                {formData.questions.length > 0  &&
                                    formData.questions.map((item, index) => 
                                        <li onClick={() => viewQuestionHandler(item, item.tempQuestionId ? true : false)} key={uniqueId()} className={`${lib.active} ${activeQ === (item.id || item.tempQuestionId) ? lib.view : ''}`}>
                                            <span>
                                                Question {index+1}
                                            </span>
                                            <span>{item.name}</span>
                                            
                                            <FaMinus onClick={() => selectQuestionHandler(item)}/>
                                        </li>
                                    )
                                }
                            </div>
                            <div className={lib.questionContent}>
                                <h3>
                                    <span>Question</span>
                                    <div>
                                        <span>Difficulty Level:  <strong>{questionContent.difficulty}</strong></span>
                                        {
                                            questionContent.tags && <span>Tags: <strong>{questionContent.tags}</strong></span>
                                        }
                                        
                                    </div>
                                </h3>
                                <h4>{questionContent.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: questionContent.content}}/>
                            </div>
                            
                        </div>
                    </div>
                }
                </div>
            }

            {
                formData.worksheetMethod === "upload" && !worksheetEdit && !uploadLoading &&
                <UploadFiles lib={lib} type={type} files={wsFile} setFiles={setWsFile}/>
            }

            {
                formData.worksheetMethod === "upload" && uploadLoading && <Loader />
            }
            
            {
                formData.worksheetMethod === "new" && !showQuestionForm &&
                <ul className={lib.newQuestionsList} ref={listRef}>
                    <label>Questions List</label>
                        {
                            formData.questions && formData.questions.length > 0 &&
                            formData.questions.map((question, index) =>
                                <li 
                                    key={uniqueId()} 
                                    className={`${lib.question}
                                    ${(selectItems && selectItems.map(item => item.id).includes(question.id ? question.id : question.tempQuestionId)) ? lib.selected : ''}`}
                                    onClick={(event) => selectItem(event, question.id ? question.id : question.tempQuestionId, 'Question ' + (index + 1), false, index, question.allowClone)}
                                    onContextMenu={(e)=> 
                                        {
                                            selectItem(e, question.id ? question.id : question.tempQuestionId, 'Question ' + (index + 1), true, index, question.allowClone);
                                            openControlHandler(e);
                                        }
                                    }
                                >
                                    <FaQuestion color={questionColor(question.answerType)}/>
                                    <span>{question.name.length > 21 ? question.name.substring(0,21)+"..." : question.name !== 'question name' ? question.name : `Question ${index+1}`}</span>
                                    <div className={lib.shortContent} dangerouslySetInnerHTML={{__html: question.content}} />
                                </li>    
                            )
                        }
                        <li className={"newBox "+ lib.newBox} title="New question" onClick={onAddQuestion}>+</li>
                        {
                            selectItems.length > 0 && openControl &&
                            <Controls
                                selectItems={selectItems} 
                                privacy="myLib" 
                                offsetX={offsetX} 
                                offsetY={offsetY}
                                onClick={controlOnClick}
                                ref={controlRef}
                                isNewList={true}
                            />
                        }
                </ul>
            }
        </div>
    )
}

export default Step3;