import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import tests from './styles/tests.module.scss';
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { Loader } from '../../../components/loading';
import {toast} from 'react-toastify';
import TimeCount from '../../../components/input/timeCount';
import Input from '../../../components/input';
import classNames from 'classnames'; 
import AssignmentReview from './assignmentReview';
import {endsWithAny} from '../../../common/format';
import OpenEnded from './components/liveTest';
import { MultiInputs } from './components/liveTest';
import { AnswerOptions } from './components/liveTest';
import { ArrangeWords } from './components/liveTest';
import { WordMatch } from './components/liveTest';
import { TestResults } from './components/liveTest';
import { Recording } from './components/liveTest';

const LiveTestForm = ({showModal, showTestForm, setShowTestForm, setNoScroll, lessionId, assignId, setReloadList}) => {
    const history = useHistory();
    const {user, setUser} = useStore();
    const [testData, setTestData] = useState(null); 
    const [loading, setLoading] = useState();
    const [isShowTime, setIsShowTime] = useState(false);
    const [answer, setAnswer] = useState([""]);
    const [no, setNo] = useState(1);
    const [assignNo, setAssignNo] = useState(1);
    const [resultData, setResultData] = useState(null);
    const [answerTime, setAnswerTime] = useState(0);
    const [viewStudentReport, setViewStudentReport] = useState(false);
    const [isTeacherPreview, setIsTeacherPreview] = useState(false);
    const [openEndData, setOpenEndData] = useState({text: '', fileUrl: ''});
    const [recordData, setRecordData] = useState({recordedFileUrl: '', fileUrl: ''});

    useEffect(()=>{
        if(showModal || showTestForm) {
            getTest(null);
            setAnswer([""]);
            setOpenEndData({text: '', fileUrl: ''});
            setRecordData({recordedFileUrl: '', fileUrl: ''});
            setViewStudentReport(false);
        }

        if(!showModal && !showTestForm) {
            setResultData(null);
            setTestData(null);
        }
    },[showModal,showTestForm]);

    useEffect(()=>{
        if(showTestForm === "Teacher Preview") {
            setIsTeacherPreview(true);
        } else {
            setIsTeacherPreview(false);
        }

    },[showTestForm]);

    useEffect(()=>{
        if(assignId) {
            setTestData(null);
            setOpenEndData({text: '', fileUrl: ''});
            setRecordData({recordedFileUrl: '', fileUrl: ''});
            setResultData(null);
            getTest(null);
            setAnswer([""]);
            setViewStudentReport(false);
        }
    },[assignId]);

    const getTest = (questionId) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_LIVE_TEST_URL,{
            headers: authHeader(),
            params: {
                lessionId,
                assignId,
                questionId: questionId ? questionId : null
            }
        })
        .then(response => {
            if(response.data) {
                 response.data && setTestData(response.data);
                //  response.data && response.data.questionId && setQuestionId(response.data.questionId);
                 setLoading(false);
                //  setNoScroll && setNoScroll(true);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onSubmit = () => {
        if(!isTeacherPreview && testData.answerType !== "openEnded" && testData.answerType !== "recording" &&
            (!answer || (answer && answer.length === answer.filter(item => !item).length))) {
            toast.error('Please input the answer!');
            return;
        }

        if(!isTeacherPreview && testData.answerType === "openEnded" && !openEndData.text && !openEndData.fileUrl) {
            toast.error('Please input the answer or upload a file or insert a file url!');
            return;
        }

        if(!isTeacherPreview && testData.answerType === "recording" && !recordData.fileUrl && !recordData.recordedFileUrl) {
            toast.error('Please upload a media file or recording a file');
            return;
        }

        postSubmit();
    }

    const postSubmit = (isTimeOut) => {
        
        const _answerData = {
            questionId: testData.questionId, 
            answer: testData.answerType === "openEnded" ? Object.values(openEndData) : testData.answerType === "recording" ? Object.values(recordData) : answer, 
            answerTime: testData.time, 
            action: 'submit',
            markType: testData.answerType === "openEnded" ? "manual-open-ended"  : testData.answerType === "recording" ? "manual-recording" : "auto"
        }

        setLoading(true);
        let answerArr = [];
        if(no > 1) {
            for(var i=1; i < no; i++) {
                const data = sessionStorage.getItem(`live-test-question-${assignNo}-${i}`);
                if(data) {
                    const _data = JSON.parse(data);
                    answerArr.push(_data);
                }
            }
        }

        !isTimeOut && answerArr.push(_answerData);

        isTimeOut && answerArr.push({..._answerData, action: 'timeOut'});
        
        axios.post(API_URL.POST_USER_LIVE_TEST_RESULT_URL,{data: answerArr},{
            headers: authHeader(),
            params: {
                lessionId,
                assignId
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.result && setResultData(response.data.result);
                setNoScroll && setNoScroll(false);
                setReloadList && setReloadList(true);
                setNo(1);
                setAssignNo(assignNo+1);
                setTestData(null);
                setOpenEndData({text: '', fileUrl: ''});
                setRecordData({recordedFileUrl: '', fileUrl: ''});
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onSubmitNext = () => {
        if(!isTeacherPreview && testData.answerType !== "openEnded" && testData.answerType !== "recording" &&
            (!answer || (answer && answer.length === answer.filter(item => !item).length))) {
            toast.error('Please input the answer!');
            return;
        }

        if(!isTeacherPreview && testData.answerType === "openEnded" && !openEndData.text && !openEndData.fileUrl) {
            toast.error('Please input the answer or upload a file or insert a file url!');
            return;
        }

        if(!isTeacherPreview && testData.answerType === "recording" && !recordData.fileUrl && !recordData.recordedFileUrl) {
            toast.error('Please upload a media file or recording a file');
            return;
        }

        const data = {
            questionId: testData.questionId, 
            answer: testData.answerType === "openEnded" ? Object.values(openEndData) : testData.answerType === "recording" ? Object.values(recordData) : answer, 
            answerTime: answerTime, 
            action: 'next',
            markType: testData.answerType === "openEnded" ? "manual-open-ended"  : testData.answerType === "recording" ? "manual-recording" : "auto"
        };

        sessionStorage.setItem(`live-test-question-${assignNo}-${no}`, JSON.stringify(data));
        setNo(no + 1);
        getTest(testData.nextQuestionId);
        setAnswer([""]);
        setOpenEndData({text: '', fileUrl: ''});
        setRecordData({recordedFileUrl: '', fileUrl: ''});
    }

    const onBack = () => {
        setNo(no - 1);
        getTest(testData.backQuestionId);
    }

    const onSkip = () => {
        const data = {
            questionId: testData.questionId, 
            answer: [], 
            answerTime: answerTime, 
            action: 'skip',
            markType: testData.answerType === "openEnded" ? "manual-open-ended"  : testData.answerType === "recording" ? "manual-recording" : "auto"
        };
        sessionStorage.setItem(`live-test-question-${assignNo}-${no}`, JSON.stringify(data));
        setNo(no + 1);
        getTest(testData.nextQuestionId);
        setAnswer([""]);
        setOpenEndData({text: '', fileUrl: ''});
        setRecordData({recordedFileUrl: '', fileUrl: ''});
    }

    const onTimeOutHandler = () => {
        const data = {
            questionId: testData.questionId, 
            answer: [], 
            answerTime: testData.time, 
            action: 'timeOut',
            markType: testData.answerType === "openEnded" ? "manual-open-ended"  : testData.answerType === "recording" ? "manual-recording" : "auto"
        };
        sessionStorage.setItem(`live-test-question-${assignNo}-${no}`, JSON.stringify(data));
        setNo(no + 1);
        if(testData && testData.nextQuestionId) {
            getTest(testData.nextQuestionId);
            setAnswer([""]);
            setOpenEndData({text: '', fileUrl: ''});
            setRecordData({recordedFileUrl: '', fileUrl: ''});
        } else {
            if(!isTeacherPreview) {
                postSubmit(true);
            } else {
                setShowTestForm && setShowTestForm(false);
            }
        }
    }

    const onAnswerInputChange = e => {
        answer.splice(0,1,e.target.value);
        setAnswer([...answer]);
    }
    const onAnswerOptionsChange = (value) => {
        setAnswer(value);
    }

    const onViewFullDetails = () => {
        setResultData(null);
        setNoScroll && setNoScroll(false);
        setViewStudentReport(true);
    }

    return(
        <div className={classNames(tests.testForm, {[tests.testFormResultFull]: viewStudentReport})}>
            {loading && 
                <div style={{background: "white"}}>
                    <Loader />
                </div>
            }
            {!loading && !resultData && !viewStudentReport && testData &&
                <>
                <div className={tests.testContent}> 
                    <div className={tests.testQuestion}>
                        <h1>
                            <span>Question</span>
                            {
                                testData.topics && Array.isArray(testData.topics) && 
                                testData.topics.map(item => <strong>{item}</strong>)
                            }
                        </h1>
                        <div className={tests.subInfo}>
                            <div>
                                {
                                    testData.tags &&
                                    <>
                                    <label>Tags:</label> {testData.tags.split(',').map(item =>
                                        <span>{item}</span>
                                    )}
                                    </>
                                }
                            </div>
                            {
                                testData.subTopics && testData.subTopics.length > 0 && 
                                testData.subTopics.map(item => <span>{item}</span>)
                            }
                        </div>
                        <div>
                            {
                                testData.content && 
                                <div className={tests.content}>
                                    <div className={tests.paragraph} id="inputsContent"
                                        dangerouslySetInnerHTML={{__html: testData.answerType === "text" && testData.multiInputs && testData.multiInputs > 1 
                                        ? testData.content.replace(/(_){3,}/g,'<span class="blankSpan"></span>') : testData.content}}/>
                                    {
                                        testData.mediaUrl && !endsWithAny(['.mp3', '.ogg', '.wav', 'mp4'],testData.mediaUrl) &&
                                        <iframe
                                            frameBorder="0"
                                            width={'100%'}
                                            height={['mp3', 'm4a'].includes(testData.mediaType) ? 150 : '500'}
                                            src={testData.mediaUrl.replace(/\/view\?usp=sharing/g, '/preview').replace(/\/view/g, '/preview')}>
                                        </iframe>
                                    }

                                    {
                                        testData.mediaUrl && endsWithAny(['.mp3', '.ogg', '.wav'],testData.mediaUrl) &&
                                        <audio controls>
                                            <source src={testData.mediaUrl} type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    }

                                    {
                                        testData.mediaUrl && testData.mediaUrl.endsWith('mp4') &&
                                        <video width="320" height="240" controls>
                                            <source src={testData.mediaUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                </div>
                            }
                        </div>
                    </div>    
                    <div className={classNames(tests.testAnswer, {[tests.openEnded]: testData.answerType === "openEnded"})}>
                        <div className={tests.time}>
                            <TimeCount
                                setIsShowTime={setIsShowTime} 
                                seconds={testData.time} 
                                messageTimeOut="" 
                                onTimeOut={onTimeOutHandler}
                                style={{
                                    display: isShowTime ? "block": "none", 
                                    fontSize: 25, 
                                    color: "white",
                                    textAlign: "center"
                                }}
                                setAnswerTime={setAnswerTime}
                            />
                        </div>    
                        <div className={tests.answers}>
                                {testData.answerType === "text" && testData.multiInputs  && testData.multiInputs === 1 &&
                                    <Input 
                                        type="textarea"
                                        value={answer}
                                        onChange={onAnswerInputChange}
                                        label="Add answer"
                                    />
                                }

                                {testData.answerType === "text" && testData.multiInputs  && testData.multiInputs > 1 &&
                                    <ul className={tests.multiInputs}>
                                        <label>Fill in the blanks</label>
                                        {
                                            <MultiInputs
                                                number={testData.multiInputs} 
                                                onChange={onAnswerOptionsChange}
                                            />
                                        }
                                    </ul>
                                    
                                }

                                {testData.answerType === "textChoice" && testData.options && testData.options.length &&
                                    <AnswerOptions
                                        options={testData.options} 
                                        onChange={onAnswerOptionsChange}
                                        isMulti={testData.multiRightAnswers}
                                        type="textChoice"
                                    />
                                }

                                {testData.answerType === "imageChoice" && testData.options && testData.options.length &&
                                    <AnswerOptions
                                        options={testData.options} 
                                        onChange={onAnswerOptionsChange}
                                        isMulti={testData.multiRightAnswers}
                                        type="imageChoice"
                                    />
                                } 

                                {testData.answerType === "arrangeWords" && testData.options &&
                                    <ArrangeWords options={testData.options} onChange={onAnswerOptionsChange}/>
                                }

                                {
                                    (testData.answerType === "wordMatch" || testData.answerType === "wordImageMatch") && testData.optionslist1 && testData.optionslist2 &&
                                    <WordMatch 
                                        options1={testData.optionslist1} 
                                        options2={testData.optionslist2} 
                                        onChange={onAnswerOptionsChange}
                                        type={testData.answerType}
                                    />
                                }

                                {
                                    testData.answerType === "openEnded" &&
                                    <OpenEnded markData={openEndData} setMarkData={setOpenEndData} />
                                }

                                {
                                    testData.answerType === "recording" &&
                                    <Recording markData={recordData} setMarkData={setRecordData} />
                                }

                            </div>
                    </div>
                </div>
                <div className={tests.buttons}>
                    {
                        testData.nextQuestionId && !isTeacherPreview &&
                        <>
                            <button className="btn" onClick={onSubmitNext}>{isTeacherPreview ? 'Next' : 'Submit and Next'}</button> <button onClick={onSkip}>Skip</button>
                        </>
                    }
                    {
                        testData.backQuestionId && isTeacherPreview &&
                        <>
                            <button className="btn" onClick={onBack}>Back</button>
                        </>
                    }
                    {
                        testData.nextQuestionId && isTeacherPreview &&
                        <>
                            <button className="btn" onClick={onSubmitNext}>Next</button>
                        </>
                    }
                    {
                        !testData.nextQuestionId && !isTeacherPreview &&
                        <>
                            <button className="btn" onClick={onSubmit}>Submit</button>
                        </>
                    }
                    {
                        !testData.nextQuestionId && isTeacherPreview &&
                        <button className="btn" onClick={() => setShowTestForm && setShowTestForm(false)}>Close</button>
                    }
                </div>
                </>
            }
             {!loading && !viewStudentReport && resultData && !testData &&
                <TestResults resultData={resultData} onViewFullDetails={onViewFullDetails} />
             }
             {
                 !loading && viewStudentReport && !resultData && !testData &&
                 <AssignmentReview studentId={user.userName} assignId={assignId}/>
             }
        </div>
    )
}

export default LiveTestForm;