import {useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import profile from './profile.module.scss';
import {MdPerson} from 'react-icons/md';
import { useStore } from '../../../store/useStore';
import AvatarEditor from 'react-avatar-editor'
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import SystemAvatars from './systemAvatars';

const ProfilePicture =  () => {

    const history = useHistory();
    const {user, setUser} = useStore();
    const inputRef = useRef();

    const [image, setImage] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);

    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageUrl(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
          }
    }

    const zoomOnChange = e => setScale(Number(e.target.value));
    const rotateOnChange = e => setRotate(Number(e.target.value));

    const setEditorRef = (editor) => setImage(editor)

    const savePicture = () => {
        const formData = new FormData();
        if (image) {
            const canvasScaled = image.getImageScaledToCanvas();
            canvasScaled.toBlob(function (blob) {
                formData.append('data', blob, 'avatar.png');
                postImage(formData);
            });
        } else {
            formData.append('avatarUrl', imageUrl);
            postImage(formData);
        }    
    }

    const postImage = (formData) => {
        const url = API_URL.USER_IMAGE_UPDATE_URL;
        axios.post(url, formData , {
            headers: authHeader()
        })
        .then(response => {
            toast.success('your profile picture is updated!');
            if(response && response.data) {
                localStorage.setItem("user", JSON.stringify(response.data));
                setUser({...user, userImageUrl: response.data.userImageUrl});
                setImageUrl(response.data.userImageUrl);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const selectLocalHandle = () => {
        inputRef.current.click();
    }

    const selectAvatar = (avatar, folder) => {
        setImage(null);
        setScale(1);
        setRotate(0);
        setImageUrl(`/avatars/${folder ? folder : ''}${avatar}`);
    }
    return (
        <div className={profile.profilePicture}>
            <div className={profile.upload}>
                <div className={profile.image}>
                    {
                        user.userImageUrl && !imageUrl &&
                        <img 
                            src={user.userImageUrl} 
                            alt="profile"
                        />
                    }

                    {imageUrl && image &&
                        <>
                        <AvatarEditor
                            image={imageUrl} 
                            width={300}
                            height={300}
                            border={50}
                            color={[255, 255, 255, 0.85]} // RGBA
                            scale={scale}
                            rotate={rotate}
                            ref={setEditorRef}
                        />
                        
                        </>   
                    }
                    {
                        imageUrl && !image &&
                        <img src={imageUrl} alt='Selected Avatar' />
                    }
                    {
                        !user.userImageUrl && !imageUrl &&
                        <MdPerson size="150" color="#ddd"/>
                    }
                    <input 
                        type="file" 
                        id="avatar" 
                        name="avatar" 
                        accept="image/png, image/jpeg" 
                        onChange={onChangePicture}
                        ref={inputRef}
                    />
                </div>
                {imageUrl && image &&
                    <div className={profile.control}>
                        <label>Zoom</label>
                        <input 
                            type="range" 
                            min="1" 
                            max="10"
                            step="0.1"
                            className={profile.slider}
                            value={scale} 
                            onChange={zoomOnChange}
                        />
                        <label>Rotate</label>
                        <input 
                            type="range" 
                            className={profile.slider}
                            min="0" 
                            max="360"
                            step="15" 
                            value={rotate} 
                            onChange={rotateOnChange}
                        />
                    </div>
                }
            </div>
            <div className={profile.selectImages}>
                <label>Available Avatars</label>
                <SystemAvatars selectAvatar={selectAvatar} />
            </div>
            <div>
                <button className="btn" onClick={selectLocalHandle} >
                    Upload picture
                </button>
                <button 
                    className={profile.saveBtn} 
                    style={{display: imageUrl ? "inline-block" : "none"}}
                    onClick={savePicture}
                >
                    Save picture
                </button>
            </div>    
        </div>
    )
}

export default ProfilePicture;