import moment from "moment";
import { MdPerson } from "react-icons/md";
import creditIcon1 from '../../../images/medal.svg';

const ChallengeItem = ({item, connection, setCurItem, setModalType, setShowModal, user, history}) => {
    const reponseOnClick = (type) => {
        setShowModal(true);
        setModalType(type);
        setCurItem(item);
    }
    return (
        <li>
           <div className={connection.avatar}>
                {
                    item.userImageUrl &&
                    <img 
                        src={item.userImageUrl} 
                        alt="user image" 
                        width="100"
                    />
                }
                {
                    !item.userImageUrl &&
                    <MdPerson size="100" color="#ddd"/>
                }
            </div>
            <div className={connection.info}>
                <strong>{item.fullName}</strong>
                <div className={connection.time}>
                    <span>{moment.utc(item.time).startOf('seconds').fromNow()}</span>
                    <div>
                        Expire on <strong>{moment(item.expire).format('DD/MM/YYYY HH:mm:ss')}</strong>
                    </div>
                </div>
                <div>
                    {
                        !item.readyToStart && item.userName !== user.userName &&
                        <>
                            <button className="btn" onClick={() => reponseOnClick('accept')}>Accept</button>
                            <button onClick={() => reponseOnClick('reject')}>Reject</button>
                        </>
                    }
                    {
                        item.readyToStart &&
                        <button className="btn" onClick={() => history.push(`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`)}>START NOW</button>
                    }
                    {
                        !item.readyToStart && item.userName === user.userName && !item.worksheetId &&
                        <span className={connection.infoText}>System is generating the questions, please wait...</span>
                    }

                    {
                        !item.readyToStart && item.userName === user.userName && item.worksheetId &&
                        <span className={connection.infoText}>Please wait until your request is accepted</span>
                    }
                </div>
            </div>
            <div className={connection.subInfo}>
                {
                    item.bonus &&
                    <>
                        Winner will get system bonus <strong>{item.bonus}</strong> <img src={creditIcon1} height="20" />
                    </>
                }
                {item.bet > 0 && 
                    <> + bet <strong>{item.bet}</strong> <img src={creditIcon1} height="20" /><br />
                        Loser will lose <strong>{item.bet}</strong> <img src={creditIcon1} height="20" />
                    </>
                }
                {
                    item.participants && item.participants.length > 0 &&
                    <div>Participants:&nbsp;
                        {
                            item.participants.map((user, index) => 
                                <strong key={`participant-${index}`} className={user.isAccepted ? '' : connection.pending} title={user.isAccepted? 'accepted' : 'not accepted yet'}>{user.fullName}
                                </strong>)
                        }
                    </div>
                }
            </div>
            {
                item.bet > 0 &&
                <div className={connection.bet}>+ {item.bet + item.bonus} <img src={creditIcon1} height="20" /></div>
            }
            
        </li>
    )
}

export default ChallengeItem;