import {useState, useEffect} from 'react';
import modal from './modal.module.scss';
import TimeCount from '../input/timeCount';
import { useStore } from '../../store/useStore';

const BreakTime = ({minutes, setMinutes, showIdle, setShowIdle, onCancel}) => {
    const {user} = useStore();
    const [isShowTime, setIsShowTime] = useState();
    
    useEffect(()=>{
        if(isShowTime === false) {
            setShowIdle(false);
            setMinutes && setMinutes(0);
        }
    },[isShowTime]);

    const closeButtonOnClick = () => {
        onCancel && onCancel();
    }

    return (
        <div className={modal.modal} style={{display: showIdle ? "block" : "none", background: "#000000E6"}}>
            <TimeCount 
                setIsShowTime={setIsShowTime} 
                seconds={minutes*60} 
                messageTimeOut="" 
                style={{
                    display: isShowTime ? "block": "none", 
                    fontSize: 100, 
                    color: "#009CC8",
                    textAlign: "center",
                    position: "fixed",
                    bottom: 10,
                    left: 10
                }}
            />
            {
                user.userRole === "Teachers" &&
                <button 
                    className={modal.closeBreak}
                    onClick={closeButtonOnClick}>&times;</button>
            }
            
        </div>
    )
}

export default BreakTime;