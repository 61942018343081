const SystemAvatars = ({selectAvatar}) => {
  return (
    <ul>
        <li onClick={() => selectAvatar('j1.png','j/')}><img src='/avatars/j/j1.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j2.png','j/')}><img src='/avatars/j/j2.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j3.png','j/')}><img src='/avatars/j/j3.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j4.png','j/')}><img src='/avatars/j/j4.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j5.png','j/')}><img src='/avatars/j/j5.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j6.png','j/')}><img src='/avatars/j/j6.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j7.png','j/')}><img src='/avatars/j/j7.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j8.png','j/')}><img src='/avatars/j/j8.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j9.png','j/')}><img src='/avatars/j/j9.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j10.png','j/')}><img src='/avatars/j/j10.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j11.png','j/')}><img src='/avatars/j/j11.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j12.png','j/')}><img src='/avatars/j/j12.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j13.png','j/')}><img src='/avatars/j/j13.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j14.png','j/')}><img src='/avatars/j/j14.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j15.png','j/')}><img src='/avatars/j/j15.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j16.png','j/')}><img src='/avatars/j/j16.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j17.png','j/')}><img src='/avatars/j/j17.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j18.png','j/')}><img src='/avatars/j/j18.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j19.png','j/')}><img src='/avatars/j/j19.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j20.png','j/')}><img src='/avatars/j/j20.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j21.png','j/')}><img src='/avatars/j/j21.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j22.png','j/')}><img src='/avatars/j/j22.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j23.png','j/')}><img src='/avatars/j/j23.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j24.png','j/')}><img src='/avatars/j/j24.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j25.png','j/')}><img src='/avatars/j/j25.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j26.png','j/')}><img src='/avatars/j/j26.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j27.png','j/')}><img src='/avatars/j/j27.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j28.png','j/')}><img src='/avatars/j/j28.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j29.png','j/')}><img src='/avatars/j/j29.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j30.png','j/')}><img src='/avatars/j/j30.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j31.png','j/')}><img src='/avatars/j/j31.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j32.png','j/')}><img src='/avatars/j/j32.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j33.png','j/')}><img src='/avatars/j/j33.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j34.png','j/')}><img src='/avatars/j/j34.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j35.png','j/')}><img src='/avatars/j/j35.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j36.png','j/')}><img src='/avatars/j/j36.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j37.png','j/')}><img src='/avatars/j/j37.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j38.png','j/')}><img src='/avatars/j/j38.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j39.png','j/')}><img src='/avatars/j/j39.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j40.png','j/')}><img src='/avatars/j/j40.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j41.png','j/')}><img src='/avatars/j/j41.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j42.png','j/')}><img src='/avatars/j/j42.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j43.png','j/')}><img src='/avatars/j/j43.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j44.png','j/')}><img src='/avatars/j/j44.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j45.png','j/')}><img src='/avatars/j/j45.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j46.png','j/')}><img src='/avatars/j/j46.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j47.png','j/')}><img src='/avatars/j/j47.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('j48.png','j/')}><img src='/avatars/j/j48.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr1.png','fr/')}><img src='/avatars/fr/fr1.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr2.png','fr/')}><img src='/avatars/fr/fr2.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr3.png','fr/')}><img src='/avatars/fr/fr3.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr4.png','fr/')}><img src='/avatars/fr/fr4.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr5.png','fr/')}><img src='/avatars/fr/fr5.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr6.png','fr/')}><img src='/avatars/fr/fr6.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr7.png','fr/')}><img src='/avatars/fr/fr7.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr8.png','fr/')}><img src='/avatars/fr/fr8.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr9.png','fr/')}><img src='/avatars/fr/fr9.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr10.png','fr/')}><img src='/avatars/fr/fr10.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr11.png','fr/')}><img src='/avatars/fr/fr11.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr12.png','fr/')}><img src='/avatars/fr/fr12.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr13.png','fr/')}><img src='/avatars/fr/fr13.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr14.png','fr/')}><img src='/avatars/fr/fr14.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr15.png','fr/')}><img src='/avatars/fr/fr15.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr16.png','fr/')}><img src='/avatars/fr/fr16.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr17.png','fr/')}><img src='/avatars/fr/fr17.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('fr18.png','fr/')}><img src='/avatars/fr/fr18.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af1.png','af/')}><img src='/avatars/af/af1.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af2.png','af/')}><img src='/avatars/af/af2.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af3.png','af/')}><img src='/avatars/af/af3.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af4.png','af/')}><img src='/avatars/af/af4.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af5.png','af/')}><img src='/avatars/af/af5.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af6.png','af/')}><img src='/avatars/af/af6.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af7.png','af/')}><img src='/avatars/af/af7.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af8.png','af/')}><img src='/avatars/af/af8.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af9.png','af/')}><img src='/avatars/af/af9.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af10.png','af/')}><img src='/avatars/af/af10.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af11.png','af/')}><img src='/avatars/af/af11.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af12.png','af/')}><img src='/avatars/af/af12.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af13.png','af/')}><img src='/avatars/af/af13.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af14.png','af/')}><img src='/avatars/af/af14.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af15.png','af/')}><img src='/avatars/af/af15.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af16.png','af/')}><img src='/avatars/af/af16.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af17.png','af/')}><img src='/avatars/af/af17.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af18.png','af/')}><img src='/avatars/af/af18.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af19.png','af/')}><img src='/avatars/af/af19.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af20.png','af/')}><img src='/avatars/af/af20.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af21.png','af/')}><img src='/avatars/af/af21.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af22.png','af/')}><img src='/avatars/af/af22.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af23.png','af/')}><img src='/avatars/af/af23.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af24.png','af/')}><img src='/avatars/af/af24.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af25.png','af/')}><img src='/avatars/af/af25.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af26.png','af/')}><img src='/avatars/af/af26.png' alt='avatar11' /></li>
        <li onClick={() => selectAvatar('af27.png','af/')}><img src='/avatars/af/af27.png' alt='avatar11' /></li>
    </ul>
  )
}

export default SystemAvatars;