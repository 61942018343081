import TestPlay from "../../../components/testPlay";

const WsPlay = ({match}) => {
    const id = match.params.id;
    const themeId = match.params.themeId;
    const questionId = match.params.qId;

    return (
        <div>
            <TestPlay
                showModal={true} 
                contentRef={null}
                themeId={themeId}
                isTeacherPreview={false}
                worksheetId={id}
                questionId={questionId}
            />
        </div>
        
    )
}

export default WsPlay;