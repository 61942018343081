import { useState, useEffect, useRef } from "react";
import InputSearch from "../../../components/input/inputSearch";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import Input from "../../../components/input";
import creditIcon1 from '../../../images/medal.svg';

const ChallengeNew = ({connection, newChallenge, setNewChallenge, list, setList, selectData, setShowModal}) => {
    const [toUsers, setToUsers] = useState({users: []});
    const [search, setSearch] = useState('');
    
    const history = useHistory();
    const {setUser, user, moreInfo} = useStore();
    const subjectOnChange = (type) => setNewChallenge({...newChallenge, subjects: type});
    const topicOnChange = (type) => setNewChallenge({...newChallenge, topics: type});
    const expireOnChange = type => setNewChallenge({...newChallenge, expire: type});
    const searchOnChange = (e) => setSearch(e.target.value);

    useEffect(()=>{
        toUsers && toUsers.length && setNewChallenge({...newChallenge, toUsers: toUsers.join(',')})
    },[toUsers]);

    const onSave = () => {
        if (newChallenge.opponentType === "connections" && toUsers.users.length === 0) {
            toast.error('Connections are empty');
            return;
        }

        if (newChallenge.opponentType === "connections" && toUsers.users.length > 4) {
            toast.error('Max users are 4');
            return;
        }

        if (!newChallenge.subjects) {
            toast.error('Please choose a subject!');
            return;
        }

        if (!newChallenge.expire) {
            toast.error('Please choose expire date');
            return;
        }

        return axios.post(API_URL.USER_CHALLENGES_ACTION_URL, {type: 'new', data: newChallenge} ,{
            headers: authHeader(),
        })
        .then(response => 
            {   
                if (response.data) {
                    const newItem = {
                        id: response.data.id,
                        fullName: user.full_name,
                        userName: user.userName,
                        userImageUrl: user.userImageUrl,
                        time: new Date(),
                        expire: response.data.expire,
                        participants: response.data.participants,
                        readyToStart: response.data.readyToStart,
                        worksheetId: response.data.worksheetId,
                        bet: response.data.bet,
                        bonus: response.data.bonus
                    }
                    list.unshift(newItem);
                    setList([...list]);
                    setShowModal(false);
                }            
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={connection.challengeNew}>
            <h2>New Challenge</h2>
            <div className="privacy">
                <label>Opponent Type</label>
                <ul>
                    <li className={newChallenge && newChallenge.opponentType === "random" ? "active" : ""}
                        onClick={() => setNewChallenge({...newChallenge, opponentType: "random"})}>Random user</li>
                    <li className={newChallenge && newChallenge.opponentType === "connections" ? "active" : ""}
                        onClick={() => setNewChallenge({...newChallenge, opponentType: "connections"})}>My Connections</li>   
                </ul>
            </div>
            {
                newChallenge && newChallenge.opponentType === 'connections' &&
                <InputSearch
                    placeholder='Search...'
                    onChange={searchOnChange}
                    label="Select Connections (max 4 users)"
                    value={search}
                    setValue={setSearch}
                    apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                    formData={toUsers}
                    setFormData={setToUsers}
                    collection='users'
                    isMulti
                />
            }
            <Input
                className='select'
                type="select"
                options={selectData.subjects}
                placeholder=''
                onChange={subjectOnChange}
                value={newChallenge.subjects}
                label="Subject"
            />
            <Input
                className='select'
                type="select"
                options={selectData.topics}
                placeholder=''
                onChange={topicOnChange}
                value={newChallenge.topics}
                label="Topics"
                isOptional
                isMulti={true}
            />
            {
               moreInfo.credit > 0 && 
               <div>
                    <label>Betpoints <i>Optional</i></label>
                    <Input 
                        type="number" 
                        className='shortInput'
                        min={0}
                        max={moreInfo.credit}
                    /> <img src={creditIcon1} height="20" />
                </div>
            }
            <Input
                className='select'
                type="select"
                options={selectData.expireOptions}
                placeholder=''
                onChange={expireOnChange}
                value={newChallenge.expire}
                label="Expire Date"
            />
            <div className="buttons rightAlign">
                <button onClick={onSave} className="btn">Submit</button>
            </div>
        </div>
    )
}

export default ChallengeNew;