import React, {useState, useRef} from 'react';
import Modal from '../modal';
import TermsAndServices from './termsAndServices';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import {toast} from 'react-toastify';
import styles from './styles.module.scss';
import { useStore } from '../../store/useStore';

const Toc = () => {
    const contentRef = useRef();
    const {user, setUser} = useStore();
    const [showModal, setShowModal] = useState(true);

    const updateAgreeToc = () => {
        const url =  API_URL.UPDATE_AGREE_AND_TOC;
        axios.post(url, {agreed_toc_time: true}, {
            headers: authHeader()
        }).then((res)=>{
            setShowModal(false);
            setUser({...user, agreed_toc_time: true})
        }).catch(err => {
            toast.error(err || err.message);
        });
    }

    return (
        <Modal
            setShowModal={setShowModal}
            showModal={showModal}
            contentRef={contentRef}
            width="90%"
            height="95%"
            disabledClickOutside={true}
            hideCloseBtn={true}
            content={
                <div ref={contentRef}>
                    <div className={styles.termsAndServices}>
                        {/* <TermsAndServices /> */}
                        <div ref={contentRef} className={styles.viewPdf}>
                           <iframe width="100%"
                                height="98%"
                                src={API_URL.LINK_TOC_FILE}>
                            </iframe>
                        </div>
                        <div className={styles.buttons}>
                            <button className="btn" onClick={updateAgreeToc}>I Already Read And Agree</button>
                        </div>
                    </div>  
                </div>
            } />
    )
}

export default Toc;