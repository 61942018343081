import { useEffect, useState } from "react";
import { creditIcon } from "../../../common/utils";
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

const LeaderBoard = () => {
    const {setUser} = useStore();
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const [type, setType] = useState('daily');
    const [friendsOnly, setFriendsOnly] = useState(false);

    const checkFriendOnly = () => setFriendsOnly(!friendsOnly);

    useEffect(()=>{
        getList();
    },[type, friendsOnly]);

    const getList = () => {
        setLoading(true);
        axios.get(API_URL.GET_LEADER_BOARD_LIST_URL,{
            headers: authHeader(),
            params: {
                type : type,
                friendsOnly: friendsOnly,
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data && response.data.list) {
                setList(response.data.list); 
            }
        })
        .catch(error => {
            setLoading(false);
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }
    return (
        <div className="leaderBoard">
            <div className="topTabs">
                <button onClick={() => setType('daily')} className={type==="daily" ? "active" : undefined}>Daily</button>
                <button onClick={() => setType('monthly')} className={type==="monthly" ? "active" : undefined}>Monthly</button>
                <button onClick={() => setType('allTime')} className={type==="allTime" ? "active" : undefined}>All the time</button>
                <div className={`filter checkBox ${friendsOnly ? 'active' : ''}`} onClick={checkFriendOnly}><FaCheck /> <span>Friends Only</span></div>
            </div>
            <div className="content">
                {
                    loading &&
                    <Loader />
                }
                {
                    !loading && list && list.length > 0 &&
                    list.map((item,index) => 
                        <li key={`range${index+1}`} className={item.isUser ? "user" : undefined}>
                            <span className="range" style={{background: item.range === 1 ? "orange" : item.range === 2 ? "#e9e95b" : "#CCCCCC"}}>{item.range}</span>
                            {item.avatarUrl &&
                                <div>
                                    <img 
                                        src={item.avatarUrl} 
                                        alt="profile" 
                                        height="80"
                                        title="view profile picture"
                                    />
                                </div>
                            }
                            {
                                !item.avatarUrl &&
                                <MdAccountCircle size="80" color="#ccc"/>
                            }
                            <strong>{item.name}</strong>
                            <span className="line"></span>
                            {item.credit && creditIcon(item.credit)}
                        </li>    
                    )
                }
            </div>
        </div>
    )
}

export default LeaderBoard;