import React, {useMemo} from 'react';
import styles from './picker.module.scss';

export const YearMonthForm = ({date, localeUtils, onChange, disableFuture}) => {
  const months = localeUtils.getMonths();

  const years = useMemo(() => {
    const arrayOfYears = [];
    const cur = new Date().getFullYear();
    let next10Years = disableFuture ? cur : cur + 10; // request 1670
    const pass = cur - 10;
    while (next10Years >= pass) {
      arrayOfYears.push(next10Years--);
    }
    return arrayOfYears;
  }, []);

  const handleChange = (e) => {
    const {year, month} = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className={styles.monthPicker}>
      <select name='month' onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name='year' onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

export default YearMonthForm;
