import AlertsList from "../../../components/alerts/alertsList";
import styles from "../../../components/alerts/styles.module.scss";
import styles2 from "../styles.module.scss";

const Notifications = () => {
    return (
        <div className={styles2.whiteBox}>
            <div className={styles.notifications}>
                <AlertsList refresh={true} isShowAll={true}/>
            </div>
        </div>
    )
}

export default Notifications;