import {useState} from 'react';
import useCountryCheck from '../../hooks/useCountryCheck';
import {getPhoneCode, CountryWithFlag} from '../../common/utils';
import Input from './index';
import {toast} from 'react-toastify';
import axios from 'axios';
import {getError} from '../../common/utils';
import { useStore } from '../../store/useStore';


const ComplexField = ({styles, setPhoneNo, placeholder, buttonText, phoneNo, email, apiUrl, value, handleSuccess, disabled}) => {
    const [country, setCountry] = useState();
    const [countryCode] = useCountryCheck();
    const [phoneWithoutCode, setPhoneWithoutCode] = useState();
    const {setClickCount, clickCount} = useStore();

    const countryOnChange = (type) => {
        setCountry(type);
        setPhoneNo && setPhoneNo(type.value + phoneWithoutCode);
    }

    const phoneOnChange = (e) => {
        const _countryCode = country && country.value ? country.value : countryCode ? getPhoneCode(countryCode) : '+65';
        setPhoneNo && setPhoneNo(_countryCode + e.target.value);
        setPhoneWithoutCode(e.target.value);
    }

    const checkCountryCode = (countryCode) => {
        if(countryCode) return {
            value: getPhoneCode(countryCode), label: <CountryWithFlag code={countryCode} phoneCode={getPhoneCode(countryCode)} />
        }

        return {value: '+65', label: <CountryWithFlag code="sg" phoneCode="+65" />}
    }

    const buttonOnClick = () => {
        if(!email) {
            toast.error(`Email is empty!`);
            return;
        }

        if(!phoneNo) {
            toast.error(`Phone number is empty!`);
            return;
        }

        axios.post(apiUrl,{phoneNo,email})
        .then(response => 
            {
                toast.success('Verification code has sent to your email.');
                handleSuccess && handleSuccess(true);
                let number = clickCount ? clickCount : 0;
                setClickCount(number + 1);
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);
        })
    }
    
    return (
        <>
            {countryCode &&
                <Input 
                    type="text-button" 
                    placeholder={placeholder} 
                    onChange={phoneOnChange}
                    onSelectChange={countryOnChange}
                    selectDefault={checkCountryCode(countryCode)}
                    className={styles.verification}
                    buttonOnClick={buttonOnClick}
                    buttonText={buttonText}
                    value={value ? phoneWithoutCode : ""}
                    disabled={disabled}
                />
            }
            {!countryCode &&    
                <Input 
                    type="text-button" 
                    placeholder={placeholder}
                    onChange={phoneOnChange}
                    onSelectChange={countryOnChange}
                    selectDefault={checkCountryCode()}
                    className={styles.verification}
                    buttonOnClick={buttonOnClick}
                    buttonText={buttonText}
                    value={value ? phoneWithoutCode : ""}
                    disabled={disabled}
                />
            }
        </>    
    )
}

export default ComplexField;