import styles from './styles.module.scss';
import image from '../../images/ai-image.jpg';
import { useHistory } from 'react-router-dom';

const AiLearning = ({data, buyOnClick}) => {
    const history = useHistory();

    return (
        <div className={styles.aiLearning}>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            <div>
                <div>
                    <h3 className={styles.title}>{data && data.title}</h3>
                    <div className={styles.content}>{data && data.content}</div>
                    <div className='homeBtns buttons'>
                        <a className='btn homeBtn' href='#howStart' onClick={buyOnClick}>Buy Now</a>
                        <button onClick={() => history.push('/register')}>Start Free Trial</button>
                    </div>
                </div>
                <img src={data && data.imageUrl ? data.imageUrl : image} alt="ai-image" width={550} />
            </div>
            
        </div>
    )
}

export default AiLearning;