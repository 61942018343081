import React from 'react';

const Privacy = () => {

    return (
        <div>
            <h1>AiDAPAL&copy; PRIVACY POLICY</h1>
            <p>Thank you for your interest in AidaPal!</p>
            <h3>1. General</h3>
            <p>“Terms”, “Terms &amp; Conditions" - Terms &amp; Conditions <br/>“us”, “we”, “our”, “company”, “AidaPal” - AidaPal Pte Ltd and its affiliates, subsidiaries. “site", “website” - www.aidadojo.com, AidaPal’s website, AidaPal platform, which is owned and operated by AidaPal Pte Ltd “user”, “you”, “your” - users of www.aidadojo.com Any services in connection with the Website - Services
            </p>

            <p>Your privacy is extremely important to us and we are committed to protecting and respecting your privacy. This Privacy Policy explains how we treat your personal information and protect your privacy when you use our Services.
            </p>

            <p>By browsing, accessing, registering, or using our Services (with or without payment/with or without subscription) through any means shall signify your acceptance of this Privacy Policy. If you do not agree with this Privacy Policy or any other agreement that governs your use of the Services, do not browse, access, register, or otherwise use any of our Services.</p>

            <p>AidaPal has the right to update, change, modify, add or remove portions of this Privacy Policy from time to time at our sole discretion without prior notice to you. When these changes are made, we will make the updated copy accessible to you on our website. We will also update the “Last Updated” date at the top of this Privacy Policy, which reflects the effective date of such Policy. Please check the Policy periodically for changes that are made after you open your account. As permitted by applicable law, your continued use of AidaPal's website and services after revised Policy have become effective indicates that you have read, understood and agreed to the current version of the Policy.</p>           
            
            <h3>2. What Data We Collect</h3>
            <p>When you interact with this Website, we may collect and process the following data about you.
            </p>

            <h4>2.1. Information you provide</h4>
            <p>We receive and store any information you provide in relation to our Services. You can choose not to provide certain information, but then you might not be able to take advantage of many of our Services.
            </p>

            <p>(a) When you create an account, we collect any data you provide directly, including your‎ name,‎ email address,‎ phone number and sign-in information (username and password).</p>
            <p>(b) We collect additional information that you choose to provide us in your Profile & Settings. For example, if you are a teacher, we may collect and store information about your subjects, education background, licenses and certificates, accomplishments, experiences, your time availability, location, your pictures and videos, etc. If you are a parent, besides your personal data, we may collect your child's information such as name, birth date, gender, previous schooling information…</p>
            <p>(c) We may also collect information from you at other times, such as when you contact us for help, communicate with us by calling or sending us emails, or via third party services when you participate in our activities, programs, events or surveys.
            </p>
            <p>(d) We may collect certain data about your purchase on our Site as well as receive some limited information, like the fact that you have a new card or the last four digits of that card, from payment service providers to facilitate payments. For security, AidaPal does not collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.</p>
            <p>(e) When you access content, we collect certain data including which courses, lessons, assignments, worksheets and videos and other products you’ve started and completed; content purchases and credits; subscriptions; completion certificates; your exchanges with instructors, teaching assistants, and other students; answers to questions, and other items submitted to satisfy course and related content requirements. If you are an instructor, we store your educational content which may contain data about you.
            </p>

            <h4>2.2. Automatic Information</h4>
            <p>When you access the Services, we may automatically log your IP address, operating system and browser software to track and collect certain data, including:</p>

            <p>(a) information about your interaction with the Website such as the number of problems you have attempted, the number of videos you have viewed, and the amount of time spent to complete a problem, content you accessed, features you used, the pages you viewed, how long you spent on a page or a feature or the Service, hyperlinks you clicked, your search queries, date and time, etc.
            </p>

            <p>(b) information about your location or your approximate location such as country, city, and geographic coordinates, calculated based on your IP address. You may be able to change the settings on your computer or mobile device to prevent it from providing us with any location information.</p>

            <p>(c) certain technical information from your browser or device when you visit our website, read our emails, use our Service or otherwise engage with us such as advertiser ID, the model and brand of the laptop or mobile device and operating system version you use, default language, domain and other systems data, etc.</p>

            <h4>2.3. Your Public Content</h4>
            <p>You can make public content through many ways such as participating in forums, creating posts, comments, writing reviews, uploading videos and photos, filling out your public profile, voting, giving feedback, testimonials and any other representations you have made through our Site or our Service, along with your name if provided. These public content may include your personally identifiable information and we have the right to store them, display them to other users as well as post them on our Site and use them for promotional purposes. We are not responsible for the information you choose to include in your Public Content. </p>

            <h3>3. How We Collect Data</h3>
            
            <h4>3.1. Cookies</h4>
            <p>When you visit our Site, we may use tools like cookies, web beacons, and similar automatic data collecting and tracking technologies to gather certain information, including without limitation, details of your visits to our website like traffic data, location data, logs, and other communication data, your activities across websites, the resources that you access and use on the website, information about your computer and internet connection including your IP address, operating system, browser type, your device model and brand, your preferred language, etc. Cookies are small files placed on your computer by websites that you visit or certain emails you open. To learn more about cookies, visit https://cookiepedia.co.uk/all-about-cookies.</p>

            <p>You can set your web browser to alert you about attempts to place cookies on your computer, limit the types of cookies you allow, or reject all, or certain, cookies. However, if you do so, you may not be able to use some or all features of the Services, or some of our Services may not function properly. Please refer to your browser instructions to learn more about these functions.</p>


            <h4>3.2. Why do we use these tools to automatically collect information?</h4>
            <p><strong>Operation:</strong> These tools are required to enable you to access the Site, use a shopping cart or make use of e-billing services, provide basic functionality like logging in or accessing content, secure the site, protect against fraudulent logins, detect and prevent abuse or unauthorized use of your account.
            </p>

            <p><strong>Analytical data:</strong> These tools help measure and improve our Services by recognising and providing usage and performance data such as number of visitors, traffic sources, how visitors move around our Site, etc. From there, we could test and figure out which features or content users prefer as well as know how to speed up your searches...
            </p>

            <p><strong>Functionality:</strong> These tools remember data about your browser and your preferences which allows us to customize our Site according to your individual interests, recognize you when you return to our website, provide additional site functionality and remember settings affecting the appearance and behavior of the Services, like your choice of language or region.</p>

            <p><strong>Advertisement:</strong> These tools help record your visit to our website, your activities, the pages you have visited and the links you have followed which are used to deliver relevant ads to you. We may also share this information with third parties (including our service providers) for this purpose.</p>

            <p><strong>Social Media:</strong> These tools enable social media functionality, like sharing content with friends and networks. These cookies may track a user or device across other sites and build a profile of user interests for targeted advertising purposes.</p>

            <h3>4. Why We Collect Data</h3>
            <p>We use information collected through your use of the Services for the following purposes.</p>

            <strong>Administer the Services and verify identity</strong>
            <p>
            We may collect and use your Personal Information in order to provide, administer, maintain your account and the Services, including to facilitate participation in educational content, issue completion certificates, display customized content, and facilitate communication with other users. We also use your information to verify your identity such as your name, address, email address, etc.
            </p>

            <strong>Communicate with you</strong>
            <p>We use your information to communicate with you.</p>

            <p>(a) respond to your inquiries, questions, concerns, provide customer services, process your requests and orders for educational content, products, specific services, information, or features.</p>

            <p>(b) provide you with information about your progress in courses, updates, our new features, services, upcoming events, rewards programs, promotions, newsletters, other available instructor-created content and other offerings that may be of interest to you (which you can opt out of at any time)</p>

            <p>(c) send you information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages</p>

            <strong>Create personalized adaptive learning</strong>
            <p>When you use our Services, we collect results from questions you answered and use that information to analyze, then customize and personalize your educational experience. Such information will be aggregated and used by us to improve our educational algorithms.</p>

            <strong>Sharing with third parties</strong>
            <p>We may share your information with our trusted services providers who provide services on our behalf including but not limited to website hosting, bank payment, advertising, etc. We also share your information to enable your participation in programs or features we may offer in partnership with third parties, to the extent you decide to participate in such programs. We do not sell, trade or share your Information to any third party (except our subsidiaries/affiliates) unless we have been expressly authorized by you either in writing or electronically to do so. If you are a teacher, in order to promote your learning courses and help you find more students, we may share to our potential customers certain information about you such as your name, your location, your profile picture, your gender, your age, or other information that is needed based on what you share with us.</p>

            <strong>Promote, improve and develop our Services</strong>
            <p>(a) facilitate the Services’ technical functions, including troubleshooting and resolving issues, securing the Services, and preventing fraud and abuse</p>
            
            <p>(b) analyze usage, trends and traffic, statistical analysis, behavioural analysis, track purchases, and track usage data, conduct surveys and research, test and troubleshoot new products and features.</p>

            <p>(c) provide personalized advertisements via fax, email, direct mail, telephone and other means of communication (you can opt out anytime by contacting us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>)</p>

            <strong>As required by law</strong>
            <p>We may access, preserve, and disclose your Personal data to third parties if we (in our sole discretion) have a good faith belief that the disclosure is:</p>
            
            <p>(a) required or permitted by law and regulations applicable to us or any of our affiliates in or outside Singapore</p>

            <p>(b) required to comply with a judicial, governmental, legal requirement, proceedings, subpoena, court order</p>

            <p>(c) required to protect our rights and property, your and others’ safety</p>

            <p>(d) required to investigate and deter against fraudulent, unauthorized, illegal activity, potential violations of law (or rule or regulation), security or technical issues</p>

            <strong>Business transfer</strong>
            <p>If we undergo a business transaction like a merger, acquisition, other corporate restructuring transaction, or we are involved in a bankruptcy, dissolution or liquidation, we may share, disclose, or transfer all of your data to the successor organization.</p>

            <h3>5. Who We Share Data With</h3>

            <p>We may share your personal information with third parties without further notice to you, unless required by the law, as set forth below:</p>

            <p>(a) With our affiliates and subsidiaries that are related by common ownership or control to enable or support us in providing the Services.</p>

            <p>(b) With our business alliance partner and counterparts (if any)</p>

            <p>(c) With our professional advisers and consultants, including without limitation our lawyers, accountants, auditors, and financial institutions.</p>

            <p>(d) With other users on the Site if you use features that enable you to share your information with (or make it accessible to) others or with other users that are associated with your account such as instructors, teaching assistants, parents, other students...</p>

            <p>(e) With our trusted services providers, contractors and agents who provide services on our behalf including but not limited to website hosting, bank payment, email communications, data analysis services, marketing and advertising services, customer services and support…</p>

            <p>(f) With any person, organization or government authorities to whom we are required to make disclosure under applicable laws and regulations in or outside Singapore.</p>

            <p>(g) With a successor organization in case of merger, acquisition, other corporate restructuring transaction, bankruptcy, dissolution or liquidation.</p>

            <h3>6. How Secure is Your Data</h3>

            <p>Protecting your personal data is extremely important to us. We are committed to securing your personal information by some of the following ways:</p>

            <ul>
                <li>protect your personal information with encryption during transmission over the public Internet. For the sensitive data you enter into our Site such as payment information, we encrypt the transmission using secure socket layer technology (SSL) or similar technologies.</li>
                <li>use reasonable organizational and technical safeguards designed to help protect the privacy and security of your personal information</li>
                <li>only give restricted access to our employees, contractors and agents who need your information to operate and improve our Services. These individuals/entities are bound by confidentiality and may be subjected to discipline, including termination and criminal prosecution if they fail to meet these obligations.</li>
            </ul>
            
            <p>In order to protect your personal information, we recommend you to:</p>

            <ul>
                <li>create a username that does not reveal your identity</li>
                <li>create and maintain a strong password, for example a non-obvious one that mixes numbers and letters</li>
                <li>keep your password secure and confidential, not to share or divulge your password with anyone
                sign out of your account and close your browser window whenever you leave your computer, do not use the feature Remember me if you are using a public device</li>
                <li>sign out of your account and close your browser window whenever you leave your computer, do not use the feature Remember me if you are using a public device</li>
                <li>be careful about what you post, share, comment, feedback on the Site as we are not responsible for the unauthorized use by third parties of this information.</li>
                
            </ul>

            <p>We try our best and use reasonable methods to protect your personal information. Unfortunately, no security methods are perfect so we cannot guarantee the absolute security of any information. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and will notify you of the security incident in accordance with applicable laws and regulations.</p>

            <h3>7. What is Your Rights and Choices</h3>

            <h4>7.1. Your choices about the use of your data</h4>
            <p>You could choose to stop receiving promotional communications from us by using the unsubscribe mechanism in the promotional communication you receive, for example clicking the unsubscribe link in e-mail or updating your Settings page. However, you could not unsubscribe or opt-out of non-marketing emails regarding your account, such as account verification, changes or updates to features of the Service; technical or security notices; transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, and notices about our policies, etc.</p>

            <p>Many web browsers are set to accept cookies by default. However, you can control cookies by setting your web browser to alert you about attempts to place cookies on your computer, limiting the types of cookies you allow, or rejecting all, or certain, cookies. Please note that choosing to remove or reject certain cookies might limit your use of the Services. Please refer to your browser instructions to learn more about these functions. Learn more about managing cookies at: https://cookiepedia.co.uk/how-to-manage-cookies.</p>
            
            <h4>7.2. Update your data and delete your account</h4>
            <p>You could log into your account in our Site and update your information at any time. Please note that you are responsible for updating your submitted registration information so that it is always current, complete and accurate.</p>

            <p>To request removal of Personal Information you have voluntarily posted on our Site, please send us email at <a href="mailto:care@aidapal.com">care@aidapal.com</a>. We strive to accommodate your request, though we cannot guarantee that the information can be successfully removed in all cases.</p>
            
            <p>If you want to terminate your account or your child’s account, contact us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>. Please note that even after your account is terminated, some or all of your data may still be visible to others, including without limitation any data that has been (a) copied, stored, or disseminated by other users (including comments, feedback, testimonials); (b) shared or disseminated by you or others; or (c) posted to a third-party platform.</p>

            <h3>8. Miscellaneous terms</h3>

            <h4>8.1. Retaining your data</h4>

            <p>We will retain your data while your account is active and for at least three years afterward. We may alter this practice according to legal and business requirements. Unless otherwise prohibited, we may shorten the retention period for some types of data if needed to free up storage space. And some data, even after your account is terminated, we may retain and disclose such data as long as we have a legitimate purpose to do so (and in accordance with applicable law), including to assist with legal obligations, resolve disputes, and enforce our agreements, security, fraud and abuse prevention, or financial record-keeping.</p>
            
            <h4>8.2. Links to third party Website</h4>

            <p>When using services from AidaPal, you may have the ability to access links to websites that are owned, controlled, developed, sponsored and/or maintained by third parties. These third party websites may collect your personal data. AidaPal is not responsible for the content or privacy practices of these third party websites. Therefore, you should review the privacy policy of such websites before providing any personal data to them.</p>

            <h4>8.3. Children data</h4>

            <p>Individuals younger than 18 years of age, but of the required age for consent to use online services where they live, may not set up an account, but may have a parent or guardian open an account and help them access appropriate content. Individuals younger than the required age for consent to use online services may not use the Services. If we learn that we’ve collected personal data from a child under those ages, we will take reasonable steps to delete it. If you believe that we have collected information from an individual under the age of 13, please contact us at <a href="mailto:care@aidapal.com">care@aidapal.com</a> so that we may take appropriate steps to address the issue.</p>

            

            <h3>9. Contact us</h3>

            <p>If you have any questions, complaints, concerns, comments and feedback about our Privacy Policy as well as our Website and Services, you may email us at <a href="mailto:care@aidapal.com">care@aidapal.com</a>.</p>

            <p>Thanks for choosing us!</p>

        </div>
    )
}

export default Privacy;