import React, {useState, useEffect} from 'react';
import {useHistory, NavLink, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import axios from 'axios';
import styles from '../login/styles.module.scss';
import Input from '../../components/input';
import API_URL from '../../common/urls';
import {Logout} from '../../services/auth.service';
import {getError, handleKeyPress} from '../../common/utils';
import classNames from 'classnames';
import {isEqual} from 'lodash';
import ComplexField from '../../components/input/complexField';
import {useStore} from '../../store/useStore';
import InputVerify from '../../components/input/inputVerify';

const RegisterPage = ({fromHome}) => {
    const query = new URLSearchParams(useLocation().search);
    const from = query.get('from') ;
    const history = useHistory();
    const {setUser, clickCount} = useStore();

    const [phoneNo, setPhoneNo] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pasConfirm, setPassConfirm] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [seconds, setSeconds] = useState(0);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);
    const [level, setLevel] = useState(null);
    const [levelsList, setLevelsList] = useState([]);
    const [isShowTime, setIsShowTime] = useState(false);

    useEffect(() => {
        Logout();
        axios.get(API_URL.GET_SMART_JOURNEY_LEVELS_LIST_URL,{
        }).then(response => {
            const arr = [];
            if (response.data.grades && response.data.grades.length) {
                setLevelsList(response.data.grades);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);
        });
    },[]);

    useEffect(()=>{
        if (clickCount) {
            setSeconds(900);
            setFieldsDisabled(false);
        }

        !clickCount && setSeconds(0);
    },[clickCount]);

    const userNameOnChange = (e) => setUserName(e.target.value);
    const emailOnChange = (e) => setEmail(e.target.value);
    const verificationCodeOnChange = (e) => setVerificationCode(e.target.value);
    const passwordOnChange = (e) => setPassword(e.target.value);
    const passwordConfirmOnChange = (e) => setPassConfirm(e.target.value);
    const levelOnChange = (type) => {
        setLevel(type);
    }

    const register = () => {
        if(!phoneNo) {
            toast.error(`Phone number is empty!`);
            return;
        }

        if(!verificationCode) {
            toast.error(`Verification Code is empty!`);
            return;
        }

        if(!userName) {
            toast.error(`User name is empty!`);
            return;
        }

        if(!email) {
            toast.error(`Email is empty!`);
            return;
        }

        if(!password) {
            toast.error(`Password is empty!`);
            return;
        }

        if(password.length < 6) {
            toast.error(`Password at least 6 characters!`);
            return;
        }

        if(!isEqual(password, pasConfirm)) {
            toast.error(`Password confirmation is not equal password!`);
            return;
        }

        if(!level) {
            toast.error(`Please select level!`);
            return;
        }

        const data = {
            userName, email, phoneNo, verificationCode, password, level: level && level.value
        }
        axios.post(API_URL.REGISTER_URL,data)
        .then(response => {
            toast.success('Your account has been created.');
            setUser(response.data);
            localStorage.setItem("user", JSON.stringify(response.data));

            if(!from) {
                localStorage.removeItem('userRole');
                history.push('/user/profile');
            } else {
                history.push(`/${from}?showModal=1`);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);       
        })
    }

    const onSetPhoneNo = (value) => {
        setPhoneNo(value);
    }

    return (
        <div className={styles.formContainer}>
            <div className={classNames(styles.login, styles.register, {[styles.fromHome]: fromHome})}>
                <h1>Register</h1>
                <p>Already have an account? <NavLink to="/login">Log In Now</NavLink></p>
                {
                    fromHome &&
                    <div className={styles.info} dangerouslySetInnerHTML={{__html: fromHome}} />
                }
                <div>
                    <InputVerify
                        type="email" 
                        placeholder="Email"
                        onChange={emailOnChange} 
                        apiUrl={API_URL.CHECK_EXISTED_USER}
                        setIsDisabled={setIsDisabled}
                        value={email}
                        valueType="email"
                        verifyType="checkExisted"
                        disabled={isShowTime}
                    />

                    <ComplexField
                        styles={styles}
                        placeholder="Phone No."
                        buttonText="Get Code"
                        setPhoneNo={onSetPhoneNo}
                        phoneNo={phoneNo}
                        email={email}
                        apiUrl={API_URL.VERIFICATION_URL}
                        value={phoneNo}
                        disabled={isShowTime}
                    />

                    {
                        seconds !== 0 && 
                        <InputVerify
                            type="text" 
                            placeholder="Verification Code"  
                            onChange={verificationCodeOnChange} 
                            value={verificationCode}
                            verifyType="timeCount"
                            seconds={seconds}
                            messageTimeOut="Verification code is expired, please get code again!"
                            disabled={!seconds}
                            isShowTime={isShowTime}
                            setIsShowTime={setIsShowTime}
                            className={fromHome ? styles.fromHome : ''}
                        />
                    }
                    
                    
                    <InputVerify
                        type="text" 
                        placeholder="User Name"  
                        onChange={userNameOnChange} 
                        apiUrl={API_URL.CHECK_EXISTED_USER}
                        setIsDisabled={setIsDisabled}
                        value={userName}
                        valueType="userName"
                        verifyType="checkExisted"
                        disabled={fieldsDisabled}
                    />

    
                    
                    <Input 
                        type="pass-with-view" 
                        placeholder="Password (at least 6 characters)" 
                        onChange={passwordOnChange}
                        className={styles.registerInput}
                        disabled={fieldsDisabled}
                    />

                    {
                        password &&
                        <Input 
                            type="pass-with-view" 
                            placeholder="Password confirmation" 
                            onChange={passwordConfirmOnChange}
                            className={styles.registerInput}
                            disabled={fieldsDisabled}
                        />
                    }
                    <Input
                        className={styles.select}
                        type="select"
                        options={levelsList}
                        placeholder='Level'
                        onChange={levelOnChange}
                        value={level}
                        label=""
                        setIsDisabled={setIsDisabled}
                        disabled={!levelsList.length || fieldsDisabled}
                    />
                    <div className="buttons">
                        <button className="btn btnLarge" 
                            disabled={isDisabled || fieldsDisabled} 
                            onClick={register} 
                            onKeyPress={e => handleKeyPress(e, "Enter", register)}>Register</button>
                    </div>
                </div>     
            </div> 
        </div>
    )
}

export default RegisterPage;