import SharingLikeAction from "./sharingLikeAction";

import { MdFaceRetouchingNatural, MdMoreHoriz, MdPerson, MdTagFaces } from "react-icons/md";
import { FaRegCommentDots } from "react-icons/fa";
import moment from "moment";
import { useState } from "react";
import { reactionIcon } from "../../../common/utils";
import SharingItemComments from "./sharingItemComments";
import SharingOptions from "./sharingMoreOptions";

const SharingItem = ({item, connection, list, setList, setShowModal, setModalType, setCurId, setRType, rType, setCurItem}) => {
    const [showLikeList, setShowLikeList] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const reactionOnClick = (type) => {
        setShowModal(true);
        setModalType('reactionList');
        setCurId(item.id);
        setRType(type);
    }
    return (
        <li style={{borderColor: item.bgCode + '99', borderBottomWidth: 4}}>
            <div className={connection.user} style={{background: `linear-gradient(${item.bgCode + '90'},${item.bgCode + '70'})`, borderBottomColor: item.bgCode}}>
                {
                    item.userImageUrl &&
                    <img 
                        src={item.userImageUrl} 
                        alt="user image" 
                        width="100"
                    />
                }
                {
                    !item.userImageUrl &&
                    <MdPerson size="100" color="#ddd"/>
                }
                <div className={connection.name}>
                    {item.fullName}
                    <div className={connection.time}>
                        {moment.utc(item.time).startOf('seconds').fromNow()}
                    </div>
                </div>
                <div className={connection.moreAction} title='more actions' onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                    <MdMoreHoriz />
                    {
                        showOptions &&
                        <SharingOptions 
                            item={item} 
                            setShowModal={setShowModal} 
                            setModalType={setModalType}
                            setCurId={setCurId}
                            list={list}
                            setList={setList}
                            setShowOptions={setShowOptions}
                            optionType="sharing-item"
                            setCurItem={setCurItem}
                        />
                    }
                </div>  
            </div>
            <div className={connection.mainContent}>
                <div className={connection.content} dangerouslySetInnerHTML={{__html: item.content}} />
                <div className={connection.reactionBox}>
                    <div onMouseOver={() => setShowLikeList(true)} onMouseLeave={() => setShowLikeList(false)}>
                        <span title="Add Reaction" onClick={() => reactionOnClick('all')}>
                            <MdFaceRetouchingNatural size={25} />
                            {item.totalReaction}
                        </span>
                        {
                            showLikeList &&
                            <SharingLikeAction 
                                connection={connection} 
                                setShowLikeList={setShowLikeList} 
                                id={item.id}
                                yourReaction={item.yourReaction}
                                list={list}
                                setList={setList}
                                setShowModal={setShowModal}
                                setModalType={setModalType}
                            />
                        }
                    </div>
                    {
                        item.reactionDetails.like > 0 &&
                        reactionIcon('like', true, item.reactionDetails.like, reactionOnClick)
                    }
                    {
                        item.reactionDetails.love > 0 &&
                        reactionIcon('love', true, item.reactionDetails.love, reactionOnClick)
                    }
                    {
                        item.reactionDetails.haha > 0 &&
                        reactionIcon('haha', true, item.reactionDetails.haha, reactionOnClick)
                    }
                    {
                        item.reactionDetails.surprise > 0 &&
                        reactionIcon('surprise', true, item.reactionDetails.surprise, reactionOnClick)
                    }
                    {
                        item.reactionDetails.cry > 0 &&
                        reactionIcon('cry', true, item.reactionDetails.cry, reactionOnClick)
                    }
                    {
                        item.reactionDetails.angry > 0 &&
                        reactionIcon('angry', true, item.reactionDetails.angry, reactionOnClick)
                    }
                    <span onClick={() => setShowComments(!showComments)} className={showComments ? connection.active : undefined}>
                        <FaRegCommentDots size={22}/>   
                        {item.totalComment}
                    </span>
                </div>
                {
                    showComments &&
                    <SharingItemComments 
                        id={item.id}
                        setModalType={setModalType}
                        setShowModal={setShowModal}
                        setRType={setRType}
                        rType={rType}
                        setCurId={setCurId}
                        setCurItem={setCurItem}
                    />
                }
            </div>
        </li>
    )
}

export default SharingItem;