import {useEffect, useState, Fragment} from 'react';
import {useHistory, Link} from 'react-router-dom';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import { useStore } from '../../../store/useStore';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import terms from './styles/terms.module.scss';
import {Logout} from '../../../services/auth.service';
import {MdGroupWork} from 'react-icons/md';
import AssigmentForm from './assigmentForm';
import LessionFiles from './lessionFiles';
import Assignments from './assignments';
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import classNames from 'classnames';
import LiveTestForm from './liveTestForm';
import NoteBox from '../../../components/modal/noteBox';
import Percentage from '../../../components/percentage';
import AssignmentReview from './assignmentReview';
import LiveFeedbackForm from './liveFeedbackForm';
import moment from 'moment';

const ClassDocument = ({classId, setShowModal, setIsTestForm, showModal, setNoScroll, currentLessionId, setDisabledClickOutside, isFromMeeting, setIsAssigmentPreview}) => {
    const history = useHistory();
    const {user, setUser} = useStore();
    const [termsList, setTermsList] = useState();
    const [loading, setLoading] = useState();
    const [showAssignmentForm, setShowAssignmentForm] = useState(false);
    const [showTestForm, setShowTestForm] = useState(false);
    const [reloadList, setReloadList] = useState(false);
    const [lessionId, setLessionId] = useState();
    const [showViewFile, setShowViewFile] = useState(false);
    const [showViewAssignment, setShowViewAssignment] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [fileId, setFileId] = useState();
    const [fileType, setFileType] = useState('');
    const [isActive, setIsActive] = useState('0-0');
    const [files, setFiles] = useState([]);
    const [oldLessionOpen, setOldLessionOpen] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState('');
    const [studentUserName, setStudentUserName] = useState('');

    useEffect(() => {
        let isCancelled = false;
        setLoading(true);
        setShowAssignmentForm(false);
        showModal && getList(!isCancelled);
        return () => {
            isCancelled = true;
          };
    },[showModal]);

    useEffect(()=>{
        reloadList && getList(true);
    },[reloadList]);

    useEffect(()=>{
        showViewAssignment && selectedAssignmentId && setIsAssigmentPreview && setIsAssigmentPreview(true);
        !showViewAssignment && setIsAssigmentPreview && setIsAssigmentPreview(false);
    },[selectedAssignmentId, showViewAssignment]);

    useEffect(()=>{
        if(showAssignmentForm) {
            setDisabledClickOutside && setDisabledClickOutside(true);
        } else {
            setDisabledClickOutside && setDisabledClickOutside(false);
        }
    },[showAssignmentForm]);

    useEffect(()=>{
        if(termsList && termsList.length >0) {
            termsList.forEach((term,index) => {
                if(term.lessions && term.lessions.length > 0) {
                    term.lessions.forEach((lession,i) => {
                        if(lession.lessionId === currentLessionId){
                            setIsActive(`${index}-${i}`);
                        }
                    });
                }
            })
        }
    },[termsList]);

    useEffect(()=>{
        if(showTestForm) {
            setIsTestForm && setIsTestForm(true);
        } else {
            setIsTestForm && setIsTestForm(false);
        }
    },[showTestForm]);

    const getList = (condition) => {
        setLoading(true);
        return axios.get(`${API_URL.GET_CLASS_DOCUMENTS_LIST_URL}?isFromMeeting=${isFromMeeting}`,{
            headers: authHeader(),
            params: {
                classId
            }
        })
        .then(response => {
            if(response.data && condition) {
                response.data.termsList && setTermsList(response.data.termsList);
                response.data.files && setFiles(response.data.files);
                setLoading(false);
                setReloadList(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const updateList = (id, type) => {
        return axios.delete(API_URL.UPDATE_CLASS_DOCUMENTS_LIST_URL,{
            headers: authHeader(),
            params: {
                classId,
                itemId: id,
                type: type
            }
        })
    }

    const addFilesHandler = (lessionId, files) => {
        const data = new FormData();

        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                data.append('files', files[i])
            }
        }

        var config = {
            headers: authHeader(),
            params: {
                classId: classId,
                lessionId: lessionId
            },
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
          };

        axios.post(API_URL.POST_CLASS_ATTACHMENTS_URL,data,config)
        .then(response => {
            // toast.success("Files have been added to class!");
            getList(true);
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const removeFileHandler = (id, type, index, i, fi, isClassFile) => {  
        
        updateList(id, type).then(response => {
            // toast.success("File has been removed successfully!");
            if(isClassFile) {
                files.splice(fi, 1);
                setFiles([...files]);
            } else {
                termsList[index].lessions[i].files.splice(fi, 1);
                setTermsList([...termsList]);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const removeAssignmentHandler = (id, type, index, i, ai) => {

        updateList(id, type).then(response => {
            // toast.success("Assigment has been removed successfully!");
            termsList[index].lessions[i].assignments.splice(ai, 1);
            setTermsList([...termsList]);
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const closeView = () => {
        setShowViewFile(false);
        setShowViewAssignment(false);
        setShowTestForm(false);
        setShowAssignmentForm(false);
        // setNoScroll && setNoScroll(false);
    }

    const onNotesListRemove = (id, type, index, i, j) => {

        updateList(id, type).then(response => {
            // toast.success("Note has been removed successfully!");
            termsList[index].lessions[i].notes.splice(j, 1);
            setTermsList([...termsList]);
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const oldLessionsToggle = () => {
        setOldLessionOpen(!oldLessionOpen);
    }

    return (
        <div>
            {percentCompleted < 100 && percentCompleted > 0 && 
                <Percentage percentCompleted={percentCompleted} />
            }

            {showAssignmentForm && 
                <>
                <AssigmentForm 
                    lessionId={lessionId}
                    setShowAssignmentForm={setShowAssignmentForm}
                    setReloadList={setReloadList}
                    selectedAssignmentId={selectedAssignmentId}
                />
                <button onClick={closeView} className={terms.close}>&times;</button>
                </>
            }

            {showTestForm && 
                <>
                <LiveTestForm 
                    lessionId={lessionId}
                    setShowTestForm={setShowTestForm}
                    showTestForm={showTestForm}
                    setNoScroll={setNoScroll}
                    getList={getList}
                    setReloadList={setReloadList}
                    assignId={selectedAssignmentId}
                />
                <button onClick={closeView} className={classNames(terms.close, terms.whiteClose)}>&times;</button>
                </>
            }

            {
                !showAssignmentForm && !showViewFile && !showViewAssignment && !showTestForm &&
                    <>
                        { isFromMeeting ? <h2>Materials </h2> : <h2>Materials &amp; Assignments</h2>}
                        {
                            !loading && files &&
                            <div className={terms.files}>
                                <LessionFiles
                                    list={files} 
                                    user={user} 
                                    addFiles={addFilesHandler}
                                    removeFile={removeFileHandler}
                                    setShowView={setShowViewFile}
                                    setFileUrl={setFileUrl}
                                    setFileId={setFileId}
                                    setFileType={setFileType}
                                    isClassFile={true}
                                />
                            </div>
                        }
                        <div className={terms.termsList}>
                            {
                                loading && 
                                <Loader />
                            }
                            {
                                !loading && (!termsList || termsList.length === 0) &&
                                <span>No lession is ready on this class</span>
                            }
                            {
                                !loading && termsList && termsList.length > 0 &&
                                <ul className={oldLessionOpen ? terms.openOld : ''}>
                                    {
                                        termsList.map((item, index) => (
                                            <Fragment key={uniqueId()}>
                                            <li className={terms.term}>
                                                <h4>
                                                    <MdGroupWork /> {item.name} 
                                                </h4>
                                            </li>
                                            {
                                                item.hasOldLession &&
                                                <Link to="#" onClick={oldLessionsToggle}>
                                                    {!oldLessionOpen && <><span>View old lessons</span> <MdKeyboardArrowDown /></>}
                                                    {oldLessionOpen &&  <><span>Hide old lessons</span> <MdKeyboardArrowUp /></>}
                                                </Link>
                                            }
                                            {
                                                item.lessions && item.lessions.length > 0 && 
                                                    item.lessions.map((lession,i) => 
                                                        <li key={uniqueId()} className={classNames(terms.lession, {[terms.old]: lession.isCollapse, [terms.active] : isActive === `${index}-${i}`})}>
                                                            <div 
                                                                className={terms.lessionInfo}
                                                                onClick={() => setIsActive(isActive === `${index}-${i}` ? null : `${index}-${i}`)}
                                                            >
                                                                <h5>
                                                                    {lession.name} 
                                                                </h5>
                                                                {
                                                                    lession.startTime && lession.endTime && lession.date &&
                                                                    <span>{moment.utc(lession.startTime).local().format('HH:mm a')} - {moment.utc(lession.endTime).local().format('HH:mm a')} - {lession.date}</span>
                                                                }
                                                                {
                                                                    (!lession.startTime || !lession.endTime) && lession.time && lession.date &&
                                                                    <span>{lession.time} - {lession.date}</span>
                                                                }

                                                                {
                                                                    isActive === `${index}-${i}` && 
                                                                    <MdKeyboardArrowUp />
                                                                }
                                                                
                                                                {
                                                                    isActive !== `${index}-${i}` && 
                                                                    <MdKeyboardArrowDown />
                                                                }
                                                            </div>
                                                                {
                                                                    lession.notes && lession.notes.length > 0 &&
                                                                    <div className={terms.annotations}>
                                                                        <label>Class Annotations</label>
                                                                        <div className={terms.notesList}>
                                                                            {lession.notes.map((noteItem, j) => 
                                                                                <NoteBox 
                                                                                    key={uniqueId()} 
                                                                                    note={noteItem.note} 
                                                                                    color={noteItem.color} 
                                                                                    onRemove={() => onNotesListRemove(noteItem.noteId, "note", index, i, j)}
                                                                                />    
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                            <div className={terms.belongings}>
                                                                { (user.userRole === "Teachers" ||
                                                                  (user.userRole !== "Teachers" && lession.files.length > 0)) &&  
                                                                    <LessionFiles
                                                                        list={lession.files} 
                                                                        user={user} 
                                                                        lessionId={lession.lessionId}
                                                                        addFiles={addFilesHandler}
                                                                        removeFile={removeFileHandler}
                                                                        setShowView={setShowViewFile}
                                                                        setFileUrl={setFileUrl}
                                                                        setFileId={setFileId}
                                                                        setFileType={setFileType}
                                                                        index={index}
                                                                        i={i}
                                                                    />
                                                                }

                                                                { (user.userRole === "Teachers" ||
                                                                  (user.userRole !== "Teachers" && lession.assignments.length > 0)) && !isFromMeeting &&
                                                                    <Assignments
                                                                        list={lession.assignments} 
                                                                        user={user}
                                                                        setShowAssignmentForm={setShowAssignmentForm}
                                                                        setShowTestForm={setShowTestForm}
                                                                        setLessionId={setLessionId}
                                                                        lessionId={lession.lessionId}
                                                                        index={index}
                                                                        i={i}
                                                                        removeAssignment={removeAssignmentHandler}
                                                                        setNoScroll={setNoScroll}
                                                                        setSelectedAssignmentId={setSelectedAssignmentId}
                                                                        setShowView={setShowViewAssignment}
                                                                        setStudentUserName={setStudentUserName}
                                                                    />  
                                                                }
                                                            </div>                                  
                                                        </li>)
                                            }
                                            </Fragment>
                                        ))
                                    }
                                </ul>
                            }
                        </div>
                    </>
            }
            {
                showViewFile && fileUrl &&
                <div className={terms.viewFile}>
                    <div>
                        {   (fileType === "pdf") && 
                            <iframe width="100%"
                                height="98%"
                                src={`${API_URL.DOCUMENT_PDF_VIEW_URL}?docId=${fileId}`}>
                            </iframe>
                        }

                        {   (fileType !== "pdf") &&
                            <iframe width="1120"
                                height="100%"
                                src={`${fileUrl}&embedded=true`}>
                            </iframe>
                        }
                    </div>
                    <button onClick={closeView} className={terms.close}>&times;</button>
                </div>
            }
            {
                showViewAssignment && selectedAssignmentId &&
                <>
                    <AssignmentReview assignId={selectedAssignmentId} studentId={studentUserName}/>
                    <button onClick={closeView} className={terms.close}>&times;</button>
                </>
            }
        </div>
    )
}

export default ClassDocument;