import { useEffect, useState } from 'react';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError } from '../../../common/utils';
import { toast } from 'react-toastify';
import API_URL from '../../../common/urls';
import styles from './styles.module.scss';

const GenerateWorksheet = ({savedName, learnId, showModal, setShowModal, subject, level}) => {
    const history = useHistory();
    const {setUser, user} = useStore();
    const [status, setStatus] = useState('generating');
    const [wsLearn, setWsLearn] = useState({learnType: '', learnId: '', worksheetId: '', themeId: 'th1'});

    useEffect(()=>{
        !showModal && setStatus('generating');

        const url = API_URL.POST_SMART_JOURNEY_GENERATED_URL;
        const data = {
            learnId: learnId,
            worksheetName: savedName ? savedName : null,
            level: level ? level : user && user.grade,
            subject,
        }
        showModal && axios.post(url, data, {
            headers: authHeader()
        })
        .then(response => {
            if (response.data.learnType && response.data.learnId && response.data.worksheetId) {
                setStatus('generated');
                setWsLearn({learnType: response.data.learnType, learnId: response.data.learnId, 
                    worksheetId: response.data.worksheetId, themeId: response.data.themeId ? response.data.themeId : 'th1'});
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    },[showModal]);

    const practiceHandler = () => {
        const win = window.open(`testPlay/${wsLearn.worksheetId}/${wsLearn.themeId}?learnId=${wsLearn.learnId}&learnType=${wsLearn.learnType}`, "_self");
        win.focus();
        setShowModal && setShowModal(false);
    }

    return (
        <div>
            {
                status === "generating" &&
                <div className={styles.generating}>
                    <h2>Generating Worksheet</h2>
                </div>
            }

            {
                status === "generated" && wsLearn.worksheetId &&
                <div className={styles.generated}>
                    <h2>Worksheet generated succesfully!</h2>
                    <p>This worksheet will be under <strong>Daily Mission</strong> tab</p>
                    <button className='btn' onClick={practiceHandler}>Practice new worksheet</button>
                </div>
            }
        </div>
    )
}

export default GenerateWorksheet;