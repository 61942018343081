import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './styles.module.scss';
import styles2 from '../styles.module.scss';
import {Link} from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';

const FAQ = () => {
    const history = useHistory();
    const {user, setUser} = useStore();
    const [listFAQ, setListFAQ] = useState([]);
    const [isActive, setIsActive] = useState(0);
    const [loading, setLoading] = useState();

    useEffect(()=>{
        let isCancelled = false;
        setLoading(true);
        const url = API_URL.FAQ_LIST_URL;
        axios.get(url,{
            headers: authHeader(),
            params: {
                userRole: user.userRole
            }
        })
        .then(response => {
            if(response.data.faqList && !isCancelled) {
                 response.data.faqList && setListFAQ(response.data.faqList);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })

        return () => {
            isCancelled = true;
          };
    },[])

    return (
        <div className={styles2.whiteBox}>     
            <div className={styles.faq}>
                {loading && <Loader />}
                {
                    !loading && listFAQ.length === 0 && 
                    <div>No data here.</div>
                }
                {
                    !loading && listFAQ.length > 0 && 
                    <ul>
                        {listFAQ.map((item,index) => (
                            <li key={uniqueId()}>
                                <Link 
                                    to="#" 
                                    className={isActive === index ? styles.active : ''}
                                    onClick={() => setIsActive(index)}
                                >
                                    {item.question}
                                </Link>
                                <div dangerouslySetInnerHTML={{__html: item.answer}} />
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    )
}

export default FAQ;