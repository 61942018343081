import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {uniqueId} from 'lodash';
import styles from './styles.module.scss';
import API_URL from '../../common/urls';
import axios from 'axios';
import {toast} from 'react-toastify';
import {getError} from '../../common/utils';
import teacherUrl from '../../images/profile_teacher.svg';
import studentUrl from '../../images/profile_student.svg';
import parentUrl from '../../images/profile_parent.svg';
import {useStore} from '../../store/useStore';
import { authHeader } from '../../services/auth-header';
import {Logout} from '../../services/auth.service';

const roles = [
    {
        name: "Student",
        role: "Students"
    },
    {
        name: "Parent",
        role: "Parents"
    },
    {
        name: "Teacher",
        role: "Teachers"
    }
]
const Roles = ({showRoles, setShowRoles}) => {
    const history = useHistory();
    const {user, setUser} = useStore();

    const [role, setRole] = useState('Students');

    const setUserRole = (role) => {
    
        axios.post(API_URL.ADD_ROLE_URL,{role},{
            headers: authHeader()
        })
        .then(response => 
            {
                toast.success(`Your role as ${role} has been set.`);
                if(response && response.data) {
                    setUser(response.data);
                    localStorage.setItem("user", JSON.stringify(response.data));
                    setUser({...user, userRole: role});
                }
                history.push('/user/profile');
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage); 
            } 
        });

        setShowRoles && setShowRoles(false);
    }
    return (
        <div className={styles.chooseRole} style={{display: showRoles ? "flex" : "none"}}>
            <div>
                <h1>Please choose user's role</h1>
                <ul>
                    {roles.map(item => (
                        <li className={role === item.role ? styles.active : ''} key={uniqueId()} onClick={() => setRole(item.role)}>
                            <h3>{item.name}</h3>
                            <div>
                                <img 
                                    src={item.role === "Teachers" ? teacherUrl : item.role === "Parents" ? parentUrl : studentUrl}
                                    height="150" 
                                    alt="icon"
                                />
                            </div>
                        </li>
                        
                    ))}
                </ul>
                <button className="btn" onClick={() => setUserRole(role)}>Next</button>
            </div>    
        </div>
    )
}

export default Roles;