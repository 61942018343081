import styles from './styles.module.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
  };

const WhatNews = ({data}) => {
    return (
        <div className={styles.whatNews}>
            <p id='blog'></p>
            {
                data && data.name &&
                <h2>{data.name}</h2>
            }
            {
                data && data.list && data.list.length > 0 &&
                <Carousel responsive={responsive} itemClass={styles.listItem} containerClass={styles.container}>
                    {data.list.map((item, index) =>
                        <div key={`carousel2${index}`} className={styles.item}>
                            <h3 className={styles.title}>{item.title}</h3>
                            <div className={styles.content}>
                              <div dangerouslySetInnerHTML={{__html: item.content}}/>
                            </div>
                        </div>
                    )
                    }
                </Carousel> 
            }
        </div>
    )
}

export default WhatNews;