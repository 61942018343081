import { useState, useEffect } from 'react';
import { MdCancel, } from 'react-icons/md';
import RichEditor2 from '../../../components/editor/editor2';
import { FaCheck } from 'react-icons/fa';

const NewOption = ({lib, selectedType, item, index, formData, setFormData}) => {
    const [isChecked, setIsChecked] = useState(formData.correctOptions.includes(index) ? true : false);

    const checkOption = () => {
        setIsChecked(!isChecked);
        const arr = formData.correctOptions;
        if(isChecked === false) {
            !arr.includes(index) && arr.push(index);
        } else {
            if(arr.includes(index)){
                const pos = arr.indexOf(index);
                arr.splice(pos,1);
            }
        }
        setFormData({...formData, correctOptions: arr});
    }

    const onRemove = () => {
        const arr1 = formData.options;
        const arr2 = formData.options2;

        arr1.splice(index,1);
        arr2.splice(index,1);

        if (selectedType.value === "matching") {
            setFormData({...formData, options: arr1, options2: arr2});
        } else {
            setFormData({...formData, options: arr1, options2: []});
        }
    }

    const optionOnChange = (content) => {
        const arr1 = formData.options;
        let correctText = formData.correctText;

        arr1.splice(index,1, content);
        if(selectedType.value === 'ordering'){
            correctText = arr1;
        } 
            setFormData({...formData, options: arr1, correctText: correctText});
    }

    const option2OnChange = (content) => {
        const correctText = formData.correctText;
        correctText.splice(index,1, content);

        setFormData({...formData, options2: correctText, correctText: correctText});
    }

    return (
        <div className={lib.newOption}>
        <label>{selectedType.value === "matching" ? 'Pair' : 'Option'} {index + 1}</label>
        <div className={lib.newOptionBox}>
            <div className={lib.optionControls}>
                {
                    ["Choices"].includes(selectedType.label) &&
                    <FaCheck className={`${lib.checkbox} ${isChecked ? lib.checked : ''}`} onClick={checkOption}/>
                }
                <MdCancel onClick={onRemove}/>
            </div>
            <div className="flex">
                    <RichEditor2 
                        label=""
                        onChange={optionOnChange}
                        editorData={selectedType.value === 'ordering' ? formData.correctText[index] : item}
                        className={`${lib.editorContent} shortEditor`}
                        isFull={false}
                    />
                {
                    selectedType.value === "matching" &&
                    <RichEditor2 
                        label=""
                        onChange={option2OnChange}
                        editorData={formData.correctText[index]}
                        className={`${lib.editorContent} shortEditor`}
                        isFull={false}
                    />
                }
            </div>
        </div>
        </div>
    )
}

export default NewOption;