
import dashboard from './dashboard.module.scss';
import {MdPerson} from 'react-icons/md';
import {uniqueId} from 'lodash';

const Students = ({list}) => {

    return (
        <div className={dashboard.studentsList}>
            <h2>Students</h2>
            {
                list && list.length > 0 && list.map(student => 
                    <div className={dashboard.student} key={uniqueId()}>
                        <div className={dashboard.info}>
                            <div className={dashboard.image}>
                                {
                                    student.imageUrl &&
                                    <img src={student.imageUrl} alt="profile" />
                                }

                                {
                                    !student.imageUrl && <MdPerson size="40" color="#CCCCCC"/>
                                }
                            </div>
                            <h3>{student.fullName} <span>{student.grade}</span></h3>
                            <div>{student.totalQuestions} <span>questions</span></div>
                            <div>{student.totalTime} <span>mins</span></div>
                            <div className={dashboard.result}>{student.totalResult &&
                                    <div>
                                        <span className={dashboard.correct} style={{width: `${student.totalResult.correct*100/student.totalQuestions}%`}}>{student.totalResult.correct}</span>
                                        <span className={dashboard.incorrect} style={{width: `${student.totalResult.incorrect*100/student.totalQuestions}%`}}>{student.totalResult.incorrect}</span>
                                        <span className={dashboard.skip} style={{width: `${student.totalResult.skip*100/student.totalQuestions}%`}}>{student.totalResult.skip}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        {student.assigmentList && student.assigmentList.length > 0 &&
                            student.assigmentList.map(assign => 
                                <div key={uniqueId()} className={dashboard.row}>
                                    <div></div>
                                    <div>{assign.name}</div>
                                    <div>{assign.questions}</div>
                                    <div>{assign.time}</div>
                                    <div className={dashboard.result}>{assign.result &&
                                            <div>
                                                <span className={dashboard.correct} style={{width: `${assign.result.correct*100/assign.questions}%`}}>{assign.result.correct}</span>
                                                <span className={dashboard.incorrect} style={{width: `${assign.result.incorrect*100/assign.questions}%`}}>{assign.result.incorrect}</span>
                                                <span className={dashboard.skip} style={{width: `${assign.result.skip*100/assign.questions}%`}}>{assign.result.skip}</span>
                                            </div>
                                        }
                                    </div>
                                </div>    
                            )
                        }
                        
                    </div>
                )
            }
        </div>
    )
}

export default Students;