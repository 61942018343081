import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import meeting from './styles/meeting.module.scss';
import { Loader } from '../../../components/loading';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError, OPTIONS_TYPE } from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import {uniqueId} from 'lodash';
import {MdPerson, MdPeople, MdHelp, MdPauseCircleFilled, MdWarning, MdAccessTime, MdRefresh, MdFeedback} from 'react-icons/md';
import useInterval from '../../../hooks/useInterval';
import StudentReports from './studentReports';
import classNames from 'classnames';
import iconExcellent from '../../../images/icon-excellent.svg';
import iconLike from '../../../images/icon-like.svg';
import iconNeutral from '../../../images/icon-neutral.svg';
import iconSleepy from '../../../images/icon-sleepy.svg';
import Input from '../../../components/input';
import Pagination from '../../../components/table/pagination';
import { ItemPerPage } from '../../../components/table/pagination';

const Reports = ({classId, lessionId, showModal, modalType}) => {
    const history = useHistory();
    const {user, setUser} = useStore();
    const [reportsList, setReportsList] = useState(null);
    const [loading, setLoading] = useState();
    const [studentName, setStudentName] = useState();
    const [studentId, setStudentId] = useState();
    const [feedbackType, setFeedbackType] = useState();
    const [itemPerPage, setItemPerPage] = useState(20);
    const [page, setPage] = useState(0);

    useEffect(()=>{
        let isCancelled = false;
        getList(!isCancelled);
        return () => {
            isCancelled = true;
          };
    },[page,itemPerPage]);

    useInterval(
        ()=> {
            getList(true);
        }, user && showModal && modalType === "reports" && !studentId ? 300000 : null); //auto reload after 5 mins

    const getList = (condition) => {
        setLoading(true);
        return axios.get(API_URL.GET_USER_CLASS_REPORTS_LIST_URL,{
            headers: authHeader(),
            params: {
                classId, 
                lessionId,
                pNo: page,
                noOfItems: itemPerPage,
            }
        })
        .then(response => {
            if(response.data && condition) {
                 response.data && setReportsList(response.data);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                if (resMessage != 'Network Error'){
                    toast.error(resMessage);
                }
            }
        })
    }

    const onRefresh = () => {
        getList(true);
    }

    const closeView = () => {
        setStudentName(null);
        setStudentId(null);
    }

    const onItemPerPageChange = type => setItemPerPage(type.value);
    const onHandlePageChanged = page => setPage(page);

    return (
        <div className={meeting.reports}>
            {studentName && 
                <h1>{studentName} reports</h1>
            }
            {!studentName && 
                <h1>Reports <MdRefresh onClick={onRefresh} color="#009CC8" size="25"/></h1>
            }

            {loading && <Loader />}
            {!loading && studentName && 
                <>
                <StudentReports 
                    studentId={studentId}
                    classId={classId}
                    lessionId={lessionId}
                    studentName={studentName}
                />
                <button onClick={closeView} className={meeting.close}>&times;</button>
                </>
            }
            {!loading && !studentName && reportsList && 
                <>
                    {
                        reportsList.feedbackForClass &&
                        <>
                            <h2>Feedback</h2>
                            <div className={classNames(meeting.topInfos, meeting.feedback)}>
                                <div className={classNames(meeting.excellent, {[meeting.active]:feedbackType === "excellent"})} onClick={()=> setFeedbackType('excellent')}>
                                    <div>
                                        <img src={iconExcellent} alt="Excellent" style={{width: 20}}/>
                                        <label>Excellent</label>
                                    </div>
                                    <h3 className={meeting.excellent}>{reportsList.feedbackForClass.excellent && reportsList.feedbackForClass.excellent.value}</h3>
                                </div>
                                <div className={classNames(meeting.good, {[meeting.active]:feedbackType === "good"})} onClick={()=> setFeedbackType('good')}>
                                    <div>
                                        <img src={iconLike} alt="Good" style={{width: 20}}/>
                                        <label>Good</label>
                                    </div>
                                    <h3 className={meeting.good}>{reportsList.feedbackForClass.good && reportsList.feedbackForClass.good.value}</h3>
                                </div>
                                <div className={classNames(meeting.normal, {[meeting.active]:feedbackType === "normal"})} onClick={()=> setFeedbackType('normal')}>
                                    <div>
                                        <img src={iconNeutral} alt="Normal" style={{width: 20}}/>
                                        <label>Normal</label>
                                    </div>
                                    <h3 className={meeting.normal}>{reportsList.feedbackForClass.normal && reportsList.feedbackForClass.normal.value}</h3>
                                </div>
                                <div className={classNames(meeting.needImprovement, {[meeting.active]:feedbackType === "needImprovement"})} onClick={()=> setFeedbackType('needImprovement')}>
                                    <div>
                                        <img src={iconSleepy} alt="Bored" style={{width: 20}}/>
                                        <label>Need Improvement</label>
                                    </div>
                                    <h3 className={meeting.needImprovement}>{reportsList.feedbackForClass.needImprovement && reportsList.feedbackForClass.needImprovement.value}</h3>
                                </div>
                            </div>
                            <div className={meeting.showComments}>
                                {
                                    feedbackType && reportsList.feedbackForClass && reportsList.feedbackForClass[feedbackType] && 
                                    reportsList.feedbackForClass[feedbackType].comments &&
                                    reportsList.feedbackForClass[feedbackType].comments.map(comment =>
                                            <div key={uniqueId()}>{comment}</div>    
                                        )
                                }
                                {
                                        feedbackType && reportsList.feedbackForClass && reportsList.feedbackForClass[feedbackType] && 
                                        reportsList.feedbackForClass[feedbackType].comments.length === 0 &&
                                        <p>No comment found.</p>
                                }
                            </div>
                        </>
                    }
                    <h2>Students List</h2>
                    <div className={meeting.topInfos}>
                        <div className={meeting.blue}>
                            <MdPeople />
                            <label>Total Students</label>
                            <h3>{reportsList.totalStudents}</h3>
                        </div>
                        <div className={meeting.green}>
                            <MdHelp />
                            <label>Total Questions</label>
                            <h3>{reportsList.totalQuestions}</h3>
                        </div>
                        <div className={meeting.orange}>
                            <MdPauseCircleFilled />
                            <label>Students Idle</label>
                            <h3>{reportsList.totalIdleStudents}</h3>
                        </div>
                        <div className={meeting.red}>
                            <MdWarning />
                            <label>Students need help</label>
                            <h3>{reportsList.totalStruggledStudents}</h3>
                        </div>
                    </div>
                    
                    <div className={meeting.studentsList}>
                        {
                            reportsList.studentsList.length === 0 && 
                            <div className='noResult'>No student found.</div>
                        }
                        
                        {
                            reportsList.studentsList && reportsList.studentsList.length > 0 && 
                            <div>
                                {reportsList && reportsList.pagination && reportsList.pagination.totalItems > 10 &&
                                    <ItemPerPage itemPerPage={itemPerPage} onItemPerPageChange={onItemPerPageChange}/>
                                }
                                <ul>
                                    {reportsList.studentsList.map(item => 
                                        <li key={uniqueId()} className={item.isIdle ? meeting.idle : item.isInTrouble ? meeting.struggle : ""}>
                                            <div className={meeting.topInfo}>
                                                {item.isIdle && <MdPauseCircleFilled />}
                                                {item.isInTrouble && <MdWarning />}
                                                <div className={meeting.image}>
                                                    {item.imageUrl && 
                                                        <img src={item.imageUrl} alt="student" /> 
                                                    }
                                                    {!item.imageUrl && 
                                                        <MdPerson size="70" />
                                                    }
                                                </div>
                                                <div>
                                                    <h4 onClick={() => {setStudentName(item.fullName); setStudentId(item.userName)}}>{item.fullName}</h4>
                                                    <span>{item.userName}</span>
                                                    <div className={meeting.mainInfo}>
                                                        <label>Age</label>
                                                        <strong>{item.age}</strong>
                                                    </div>
                                                    <div className={meeting.mainInfo}>
                                                        <label>Grade</label>
                                                        <strong>{item.grade}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={meeting.info}>
                                                <div className={meeting.answer}>
                                                    {item.answeredQuestions} Questions answered
                                                    <div className={meeting.answerPercent}>
                                                        <span className={meeting.right} style={{width: `${item.correctAnswers*100/item.answeredQuestions}%`}}>{item.correctAnswers}</span>
                                                        <span className={meeting.wrong} style={{width: `${item.incorrectAnswers*100/item.answeredQuestions}%`}}>{item.incorrectAnswers}</span>
                                                        <span className={meeting.skip} style={{width: `${item.skipAnswers*100/item.answeredQuestions}%`}}>{item.skipAnswers}</span>
                                                        <span className={meeting.timeout} style={{width: `${item.timeoutAnswers*100/item.answeredQuestions}%`}}>{item.timeoutAnswers}</span>
                                                    </div>
                                                </div>
                                                <div className={meeting.time}>
                                                    <MdAccessTime />
                                                    <p><span>{item.totalAnswerTime ? `${Math.floor(item.totalAnswerTime/60) > 0 ? Math.floor(item.totalAnswerTime/60) + ' mins' : ""} ${item.totalAnswerTime%60 ? item.totalAnswerTime%60 + ' secs': ''}` : 0}</span></p>
                                                </div> 

                                            </div>        
                                        </li>   
                                    )}
                                    </ul>
                            </div>
                        }
                        {reportsList && reportsList.pagination && (
                            <Pagination
                                total={reportsList.pagination.totalItems}
                                currentPage={page}
                                itemPerPage={itemPerPage}
                                onChange={onHandlePageChanged}
                            />
                        )}
                    </div>
                </>
            }
        </div>
    )
}

export default Reports;