import { useState, useEffect, useRef } from "react";
import InputSearch from "../../../components/input/inputSearch";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError, toastCredit } from '../../../common/utils';
import { useHistory } from "react-router-dom";
import creditIcon1 from '../../../images/medal.svg';
import Input from "../../../components/input";
import { MdClose } from "react-icons/md";

const NewGift = ({connection, list, setList, setShowModal, setNewGift, newGift, curItem}) => {
    const {user, setUser, moreInfo, setMoreInfo, setToastContent} = useStore();
    const [toUsers, setToUsers] = useState({users: []});
    const [search, setSearch] = useState('');
    const history = useHistory();
    const inputFileRef = useRef();

    const searchOnChange = (e) => setSearch(e.target.value);
    const filesOnChange = (e) => {
        let fileSize = 0;
        if (e.target.files.length > 0) {
            fileSize = e.target.files[0].size;
            if(fileSize/1000000 > 2) {
                toast.error('Allow maximum 2 Mb file size, please try again!');
            } else {
                const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        const result = reader.result;
                        setNewGift({...newGift, imageUrl: result});
                    });
                    reader.readAsDataURL(e.target.files[0]);
            }
        }
    }

    const imageDelete = e => {
        setNewGift({...newGift, imageUrl: ''});
    }

    useEffect(()=>{
        toUsers && toUsers.length && setNewGift({...newGift, toUsers: toUsers.join(',')})
    },[toUsers]);

    useEffect(()=>{
        curItem && curItem.toUsers && setToUsers({users: curItem.toUsers.split(',')});
    },[curItem]);

    const onSave = () => {
        if (newGift.privacy === "connections" && toUsers.users.length === 0) {
            toast.error('Connections are empty');
            return;
        }

        if (newGift.type === "credit" && newGift.credit === 0) {
            toast.error('Credit should be larger than 0');
            return;
        }

        if (newGift.type === "credit" && newGift.credit > moreInfo.credit) {
            toast.error('Credit can\'t be larger than your current credit');
            return;
        }

        if (newGift.quantity === 0) {
            toast.error('Quantity should be greater than 0');
            return;
        }

        if (newGift.type === "others" && !newGift.name) {
            toast.error('Name is empty');
            return;
        }

        return axios.post(API_URL.POST_GIFT_ACTION_URL, {action: 'new', data: newGift},{
            headers: authHeader(),
            params: {
                id: curItem && curItem.id// just have value for edit case
            }
        })
        .then(response => 
            {               
                const idList = list && list.map(item => item.id);
                const index = idList.indexOf(curItem && curItem.id);

                if (newGift.type === "credit") {
                    setToastContent && setToastContent(toastCredit('-', newGift.credit));
                    moreInfo.credit = moreInfo.credit - newGift.credit;
                    setMoreInfo({...moreInfo});
                }

                if (curItem && curItem.id) {
                    const newItem = {...curItem, 
                        privacy : newGift.privacy,
                        toUsers : newGift.toUsers,
                        type : newGift.type,
                        credit : newGift.credit,
                        quantity : newGift.quantity,
                        imageUrl : newGift.imageUrl,
                        name : newGift.type === "credit" ? newGift.credit + ' credit' : newGift.name,
                        message : newGift.message,
                    }
                    list.splice(index, 1, newItem);
                    setList([...list]);
                } else {
                    const newItem = {
                        id: response.data && response.data.id ? response.data.id : "tempId",
                        fullName: user.full_name,
                        userName: user.userName,
                        userImageUrl: user.userImageUrl,
                        time: new Date(),
                        privacy : newGift.privacy,
                        toUsers : newGift.toUsers,
                        type : newGift.type,
                        credit : newGift.credit,
                        quantity : newGift.quantity,
                        imageUrl : newGift.imageUrl,
                        name : newGift.type === "credit" ? newGift.credit + ' credit' : newGift.name,
                        message : newGift.message,
                        route: "pending",
                        taken: response.data && response.data.taken ? response.data.taken : 0 
                    }
                    list.unshift(newItem);
                    setList([...list]);
                }

                setShowModal(false);
            }
        )
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <div className={connection.newGift}>
            <h2>New Gift</h2>
            <div className="privacy">
                <label>Who can take</label>
                <ul>
                    <li className={newGift && newGift.privacy === "public" ? "active" : ""}
                        onClick={() => setNewGift({...newGift, privacy: "public"})}>Public</li>
                    <li className={newGift && newGift.privacy === "connections" ? "active" : ""}
                        onClick={() => setNewGift({...newGift, privacy: "connections"})}>My Connections</li>   
                </ul>
            </div>
            {
                newGift.privacy === "connections" &&
                <InputSearch
                    placeholder='Search...'
                    onChange={searchOnChange}
                    label="Select Connections"
                    value={search}
                    setValue={setSearch}
                    apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                    formData={toUsers}
                    setFormData={setToUsers}
                    collection='users'
                    isMulti
                />
            }
            <div>
                <label>Gift Type</label>
                <ul className="radioList">
                    {
                        moreInfo.credit > 0 &&
                        <li className={newGift.type === 'credit' ? 'active' : undefined} onClick={() => setNewGift({...newGift, type: "credit"})}>Credit</li>
                    }
                    <li className={newGift.type === 'others' ? 'active' : undefined} onClick={() => setNewGift({...newGift, type: "others"})}>Others</li>
                </ul>
            </div>
            <div>
                {
                    newGift.type === 'credit' &&
                    <>
                    <div>
                        <label>Credit ( &#60;={moreInfo.credit})</label>
                        <Input 
                            type="number" 
                            className="shortInput" 
                            onChange={(e) => setNewGift({...newGift, credit: e.target.value})} 
                            value={newGift.credit}
                        /> <img src={creditIcon1} height={30} />
                    </div>
                    </> 
                }
            </div>
            <div>
                <label>Quantity</label>
                <Input
                    type="number" 
                    className="shortInput" 
                    onChange={(e) => setNewGift({...newGift, quantity: e.target.value})}
                    value={newGift.quantity}
                />
            </div>
            {
                newGift.type === 'others' &&     
                <>
                    <div className={connection.inputFile}>
                        <label>Gift Image</label>
                        {
                            !newGift.imageUrl &&
                            <div>
                                <input type="file" accept=".png,.jpg,.jpeg" ref={inputFileRef} onChange={filesOnChange}/>
                                <span>Drag or Click to select file</span>
                            </div>
                        }
                       {
                           newGift.imageUrl &&
                           <div>
                               <img src={newGift.imageUrl} alt="gift image" width={200} />
                               <MdClose onClick={imageDelete}/>
                           </div>
                       }
                    </div>
                    <div>
                        <label>Gift name</label>
                        <Input 
                            type="text" 
                            className="fullInput" 
                            onChange={(e) => setNewGift({...newGift, name: e.target.value})} 
                            value={newGift.name}
                        />
                    </div>
                </>               
                
            }
            <div>
                <label>Message <i>optional</i></label>
                <Input 
                    type="textarea" 
                    className="fullInput" 
                    onChange={(e) => setNewGift({...newGift, message: e.target.value})}
                    value={newGift.message}
                />
            </div>
            <div className="buttons rightAlign">
                <button onClick={onSave} className="btn">Save</button>
            </div>
        </div>
    )
}

export default NewGift;