import Select from './index';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import { getError } from '../../common/utils';
import { authHeader } from '../../services/auth-header';

const ASyncSelect = ({className, url, isCreatable, isAsync, value, autoSelect, onChange, compareValue,compareAction, ...rest}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(url,{
      headers: authHeader()
  })
    .then(response => {
      response.data && response.data.list && setOptions(response.data.list);
      response.data.list && response.data.list.length > 0 && response.data.list.forEach(item => 
        {
            if(item.value === compareValue) {compareAction && compareAction(item)}
        }
    )
      setIsLoading(false);
    }).catch(error => {
      const resMessage = getError(error);
      toast.error(resMessage);
    })
  }, [url]);

  return <Select
    className={className}
    maxMenuHeight={200}
    isCreatable={isCreatable}
    value={value}
    onChange={onChange}
    {...rest}
    isLoading={isLoading}
    options={options}
  />;
};

export default ASyncSelect;
