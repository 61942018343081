import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import profile from './profile.module.scss';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import 'react-day-picker/lib/style.css';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { creditIcon, getError } from '../../../common/utils';
import { MdEdit, MdPerson } from 'react-icons/md';
import MainInfoEdit from './mainInfoEdit';
import ProfilePicture from './profilePicture';
import creditIcon1 from '../../../images/medal.svg';
 
const MainInfo = () => {
    const history = useHistory();
    const {user, setUser, moreInfo} = useStore();
    const [openEdit, setOpenEdit] = useState(0);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        phoneNumber: '',
        email: '',
        goal: '',
        school: '',
        selfIntro: '',
        hobbies: '',
        address: '',
        gender: {label: '', value: ''},
        subject: null,
        education: {label: '', value: ''},
        grade: null,
        lisencesAndCertificates: '',
        accomplishments: '',
        experiences: '',
        dateOfBirth: ''
    });

    useEffect(() => {
        setOpenEdit(0);
        axios.get(API_URL.USER_PROFILE_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data.data) {
                setFormData(response.data.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });

    },[]);
    
    return (
        <div className={profile.mainInfo}>
            <div className='rightAlign'>
                <button onClick={() => !openEdit ? setOpenEdit(1) : setOpenEdit(0)}>
                    {
                        !openEdit &&
                        <>
                            <MdEdit /> Edit Information
                        </>
                    }
                    {
                        openEdit !== 0 &&
                        <>
                            Back to info page
                        </>
                    }
                </button>
            </div>
        
        {
            !openEdit &&
            <div className={profile.info}>
                <div className={profile.profileImage}>
                    <div>
                        {
                            user.userImageUrl && 
                            <img 
                                src={user.userImageUrl} 
                                alt="profile" 
                                width="250"
                            />
                        }
                        {
                            !user.userImageUrl && 
                            <MdPerson size="150" color="#ddd"/>
                        }
                    </div>
                    {/* <a href='#' onClick={(e) => {
                        e.preventDefault();
                        setOpenEdit(2)
                    }}>Edit Profile picture</a> */}
                    {
                        user.userRole === "Students" &&
                        <div className='creditExchange'>
                            {creditIcon(moreInfo.credit)}
                        </div>
                    }
                </div>
                <div>
                    <div>
                        <label>First Name</label>
                        {formData.firstName ? formData.firstName : 'N/A'}
                    </div>
                    <div>
                        <label>Last Name</label>
                        {formData.lastName ? formData.lastName : 'N/A'}
                    </div>
                    <div>
                        <label>User Name</label>
                        {formData.userName ? formData.userName : 'N/A'}
                    </div>
                    <div>
                        <label>Phone Number</label>
                        {formData.phoneNumber ? formData.phoneNumber : 'N/A'}
                    </div>
                    <div>
                        <label>Email</label>
                        {formData.email ? formData.email : 'N/A'}
                    </div>
                    <div>
                        <label>Date Of Birth</label>
                        {formData.dateOfBirth ? formData.dateOfBirth : 'N/A'}
                    </div>
                    <div>
                        <label>Gender</label>
                        {formData.gender && formData.gender.label ? formData.gender.label : 'N/A'}
                    </div>
                </div>
                <div>
                    { user.userRole !== "Parents" && 
                        <>
                            <div>
                                <label>Level/Grade</label>
                                {formData.grade && formData.grade.label ? formData.grade.label : 'N/A'}
                            </div>
                            <div>
                                <label>School</label>
                                {formData.school ? formData.school : 'N/A'}
                            </div>
                        </>
                    }
                    <div>
                        <label>Address</label>
                        {formData.address ? formData.address : 'N/A'}
                    </div>
                    <div>
                        <label>Hobbies</label>
                        {formData.hobbies ? formData.hobbies : 'N/A'}
                    </div>
                    { user.userRole !== "Teachers" &&
                        <div>
                            <label>Your Goal</label>
                            {formData.goal ? formData.goal : 'N/A'}
                        </div>
                    }
                </div>
                { user.userRole === "Teachers" &&
                    <div>
                        <div>
                            <label>Self Intro</label>
                            <div dangerouslySetInnerHTML={{__html: formData.selfIntro ? formData.selfIntro : 'N/A'}} />
                        </div>
                        <div>
                            <label>Subject</label>
                            {formData.subject && formData.subject.length > 0 ? formData.subject.map(item => item.label).join(', ') : 'N/A'}
                        </div>
                        <div>
                            <label>Education</label>
                            {formData.education && formData.education.label ? formData.education.label : 'N/A'}
                        </div>
                        <div>
                            <label>Lisences And Certificates</label>
                            {formData.lisencesAndCertificates ? formData.lisencesAndCertificates : 'N/A'}
                        </div>
                        <div>
                            <label>Accomplishments</label>
                            {formData.accomplishments ? formData.accomplishments : 'N/A'}
                        </div>
                        <div>
                            <label>Experiences</label>
                            {formData.experiences ? formData.experiences : 'N/A'}
                        </div>
                    </div>
                }
            </div> 
        }   
        {
            openEdit === 1 &&
            <MainInfoEdit formData={formData} setFormData={setFormData} />
        }
        {
            openEdit === 2 &&
            <ProfilePicture />
        }
        </div>
    )
}

export default MainInfo;