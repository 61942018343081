import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import NewQuestion from "./newQuestionForm";
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import API_URL from '../../../common/urls';
import { getError} from '../../../common/utils';
import {Logout} from '../../../services/auth.service';
import {toast} from 'react-toastify';
import { useStore } from '../../../store/useStore';
import Input from '../../../components/input';
import assign from './styles/assign.module.scss';
import {uniqueId} from 'lodash';
import {MdClose, MdEdit, MdAdd, MdContentCopy} from 'react-icons/md';
import {FaQuestionCircle} from 'react-icons/fa';
import { Loader } from '../../../components/loading';
import classNames from 'classnames';

const AssigmentForm = ({lessionId, setShowAssignmentForm, setReloadList, selectedAssignmentId, isFromMeeting, setShowModal}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState();
    const [topics, setTopics] = useState([]);
    const [subTopics, setSubTopics] = useState([]);
    const [assignment, setAssignment] = useState( 
        {
            name: 'Assignment 1',
            isHomeWork: false,
            questions: [],
            tempAssignId: null
        });
    
    useEffect(()=>{
        setAssignment({...assignment, tempAssignId: `${Date.now()}-${Math.random().toString(36).slice(2)}-${lessionId}`});
    },[]);

    useEffect(()=>{
        assignment.questions && setCurrentIndex(assignment.questions.length);
    },[assignment]);

    useEffect(()=>{
        axios.get(API_URL.GET_USER_ASSIGMENT_TOPICS_LIST_URL,{
            headers: authHeader()
        })
        .then(response => {
            response.data && response.data.topics && setTopics(response.data.topics);
            response.data && response.data.subTopics && setSubTopics(response.data.subTopics);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    },[]);

    useEffect(()=>{
        selectedAssignmentId && setLoading(true);
        selectedAssignmentId && axios.get(API_URL.GET_USER_ASSIGNMENT_URL,{
            params: {
                assignId: selectedAssignmentId
            },
            headers: authHeader()
        })
        .then(response => {
                response.data && setAssignment(response.data);
                setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });

    },[selectedAssignmentId]);

    const onSubmit = () => {
        if(assignment.questions.length ===0) {
            toast.error("Please add at least 1 question!");
            return;
        }

        saveAssignment();
    }

    const saveAssignment = (formData) => {
        setLoading(true);

        if(formData) {
            //update assigment
            const shortContent = {
                questionId : formData.questionId,
                tempQuestionId: formData.tempQuestionId,
                editorState: formData.editorState,
                result: formData.result
            }
            if(assignment.questions[currentIndex]) {
                assignment.questions.splice(currentIndex, 1, shortContent);
            } else {
                assignment.questions.push(shortContent);
            }
            setAssignment({...assignment});
        }

        axios.post(API_URL.USER_ADD_ASSIGNMENT_URL, assignment,{
            headers: authHeader(),
            params: {
                lessionId,
                isFromMeeting,
                tempAssignId: assignment.tempAssignId
            }
        })
        .then(response => {
            if(!formData) {
                !setShowAssignmentForm && toast.success("Assignment is sent successfully!");
                setShowAssignmentForm && setShowAssignmentForm(false);
                setShowModal && setShowModal(false);
            } else {
                updateQuestion(response.data && response.data.assignId, formData);
            }

            response.data && response.data.assignId && setAssignment({...assignment, assignId: response.data.assignId});
            setReloadList && setReloadList(true);
            setLoading(false);
        })
        .catch(error => {
            setShowAssignmentForm && setShowAssignmentForm(true);
            setLoading(false);
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const updateQuestion = (assignId, formData) => {
        //update question
        const url = API_URL.USER_ADD_ASSIGNMENT_QUESTION_URL;
        axios.post(url,{formData: formData},{
            headers: authHeader(),
            params: {
                assignId: assignId,
                questionId: formData.questionId,
            }
        })
        .then(response => {
            console.log('updated');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const checkboxOnChange = (e) => {
        if(e && e.target){
            setAssignment({...assignment, isHomeWork: e.target.checked});
        };
    }

    const nameOnChange = e => setAssignment({...assignment, name: e.target.value});
    
    const onRemoveItem = (index) => {
        assignment.questions.splice(index, 1);
        setAssignment({...assignment});
    }

    const onEdit = (index) => {
        setShowQuestionForm(true);
        setCurrentIndex(index);
    }

    const onCopy = (index) => {

        let _question;
        if(assignment.questions[index].questionId) {
            _question = {...assignment.questions[index], questionId: null, tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}-${lessionId}`};
        } else {
            _question = {...assignment.questions[index], tempQuestionId: `${Date.now()}-${Math.random().toString(36).slice(2)}-${lessionId}`};
        }
        assignment.questions.push(_question);
        setAssignment({...assignment});
    }

    const onAddQuestion = () => {
        setShowQuestionForm(true);
        setCurrentIndex(assignment.questions && assignment.questions.length);
    }

    return (
            <>
            {
                !showQuestionForm &&
                <h2>{selectedAssignmentId ? 'Edit' : 'New'} Assignment</h2>
            }
            
            {
                loading && <Loader />            
            }
            {
                !loading && !showQuestionForm &&
                <>
                    <div className={assign.assignment}>
                        <Input 
                            type="text" 
                            placeholder="" 
                            onChange={nameOnChange}
                            value={assignment.name}
                            label="Assigment Name"
                        />
                        <Input 
                            type="checkbox" 
                            checkboxLabel="Homework"
                            onChange={checkboxOnChange}
                            value={assignment.isHomeWork}
                        />
                    </div>
                    
                    <div className={assign.questionsList}>
                        <label>Questions List</label>
                        {
                            assignment.questions && assignment.questions.length > 0 &&
                            assignment.questions.map((question, index) =>
                                <div key={uniqueId()} className={assign.question}>
                                    <FaQuestionCircle size="20" color="#999"/>
                                    <span>Question {index+1}</span>
                                    <MdClose className={assign.close} title="Remove item" onClick={() => onRemoveItem(index)}/>
                                    <MdEdit className={assign.edit} title="Edit item" onClick={() => onEdit(index)}/>
                                    <MdContentCopy className={assign.edit} title="Copy item" onClick={() => onCopy(index)} />
                                    <div className={assign.questionContent} dangerouslySetInnerHTML={{__html: question.editorState}} />
                                </div>    
                            )
                        }
                        <div className={assign.newQuestion} title="New question" onClick={onAddQuestion}>
                            <MdAdd />
                        </div>
                    </div>
                </>
            }

            {
                showQuestionForm &&
                <>
                    <h2>Question {currentIndex + 1}</h2>
                    <NewQuestion 
                        lessionId={lessionId} 
                        currentIndex={currentIndex}
                        setShowAssignmentForm={setShowAssignmentForm}
                        assignment={assignment}
                        setAssignment={setAssignment}
                        selectedAssignmentId={selectedAssignmentId}
                        setShowQuestionForm={setShowQuestionForm}
                        questionId={assignment.questions.length > 0 && assignment.questions[currentIndex] && assignment.questions[currentIndex].questionId}
                        saveAssignment={saveAssignment}
                        topics={topics}
                        subTopics={subTopics}
                        updateQuestion={updateQuestion}
                    />
                    <button onClick={() => setShowQuestionForm(false)} className={assign.close}>&times;</button>
                </>
            }
            {
                !loading && !showQuestionForm &&
                <div>
                    <button className={classNames('btn',assign.buttons)} onClick={onSubmit}>Save Assignment</button>
                </div>
            }    
            </>
    )
}

export default AssigmentForm