import {Route, Switch, useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import LeftMenu from './leftMenus';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Profile from './profile';
import MyClasses from './myClasses';
import Dashboard from './dashboard';
import Feedback from './feedback';
import TransactionHistory from './transactionHistory';
import FAQ from './FAQ';
import Roles from './roles';
import {getCurrentUserRole} from '../../services/auth.service';
import Notifications from './notifications';
import MyCalendar from './calendar';
import Library from './library';
import MyAidaPal from './myAidaPal';
import WsPlay from './myAidaPal/playWs';
import { useStore } from '../../store/useStore';
import API_URL from '../../common/urls';
import axios from 'axios';
import {toast} from 'react-toastify';
import {getError, toastCredit} from '../../common/utils';
import { authHeader } from '../../services/auth-header';
import {Logout} from '../../services/auth.service';
import CustomTheme from './customTheme';
import creditIcon from '../../images/medal.svg';
import Connection from './connections';
import Exam from './exam';
// import Chat from './chat/';
import useQuery from '../../hooks/useQuery';
import Themes from './themes';

const User = () => {
    const {user, setUser, moreInfo, setMoreInfo, setShowBox, setActiveSubTab, activeTab, setActiveTab, setToastContent, customValue, setCustomValue} = useStore();
    const history = useHistory();
    const [showModal, setShowModal] = useState();
    const savedThemeBg = localStorage.getItem('user-theme-bg');
    const savedThemeBox = localStorage.getItem('user-theme-box');
    const savedCustomtheme = localStorage.getItem('user-custom-theme');
    const [theme, setTheme] = useState(savedCustomtheme ? {} : {
      bg: savedThemeBg ? savedThemeBg : user && user.userRole !== 'Students' ? 'theme-bg13' : 'theme-bg1',
      box: savedThemeBox ? savedThemeBox : 'theme-box1'
    });
    const [showRoles, setShowRoles] = useState(false);
    const curHours = new Date().getHours();
    const [dark, setDark] = useState(false);
    const [showClaim, setShowClaim] = useState(moreInfo && moreInfo.showDailyClaimButton);
    const queryParams = useQuery();
    const tab = decodeURIComponent(queryParams.get('tab'));
    const subTab = decodeURIComponent(queryParams.get('subTab'));


    useEffect(()=>{
      moreInfo && moreInfo.showDailyClaimButton && setShowClaim(true);
    },[moreInfo && moreInfo.showDailyClaimButton]);
  
    useEffect(() => {
      if(!(user && user.userRole)) setShowRoles(true);
      setShowBox(true);
      tab != 'null' && setActiveTab(tab);
      subTab != 'null' && setActiveSubTab(subTab);

      //get user additional info
      axios.get(API_URL.GET_USER_ADDITIONAL_INFO,{
        headers: authHeader()
      })
      .then(response => {
        if(response.data) {
          setMoreInfo(response.data);
          if(response.data.theme) {
            // response.data.theme.defaultTheme && setTheme(response.data.theme.defaultTheme); // comment while waiting BE update theme case
            response.data.theme.customThemeData && setCustomValue(response.data.theme.customThemeData);
          }
        }
      })
      .catch(error => {
          const resMessage = getError(error);
          if(error.response && error.response.status === 401) {
              Logout();
              setUser(null);
              history.push('/login');
          } else {
              toast.error(resMessage); 
          } 
      });
    },[]);

    useEffect(() => {
      if(!showModal) {
        if(!savedCustomtheme) {
          setCustomValue({
            color: null,
            images: [],
              box: {
                textColor: null,
                boxColor: null,
                boxColorOpacity: 1,
                effect: 0,
                shadowColor: null
              }
          })
        } else {
          setCustomValue(JSON.parse(savedCustomtheme));
        }
      }
    },[showModal]);

    useEffect(() => {
      if((curHours > 17 || curHours < 6) && theme.bg === 'theme-bg13') {
        setDark(true);
      } else {
        setDark(false);
      }
    },[curHours, theme]);

    useEffect(() => {
      (['theme-bg4', 'theme-bg11', 'theme-bg12', 'theme-bg16'].includes(theme.bg)) && setDark(true);
    },[theme]);

    const onClaim = () => {
      axios.post(API_URL.CREDIT_CLAIM_URL,{},{
        headers: authHeader(),
        params: {
          type: 'daily'
        }
      })
      .then(response => 
          {
            if(response.data && response.data.bonus) {
              setToastContent && setToastContent(toastCredit('+', response.data.bonus));
              moreInfo.credit = moreInfo.credit + response.data.bonus;
              setMoreInfo({...moreInfo});
              setShowClaim(false);
            }
          }
      )
      .catch(error => {
          const resMessage = getError(error);
          
          if(error.response && error.response.status === 401) {
              Logout();
              setUser(null);
              history.push('/login');
          } else {
              toast.error(resMessage); 
          } 
      });
    }

    const setBackground = (images, color) => {
      const arr = [];
      if (images &&  images.length) {
        images.forEach(image => 
          arr.push(`url(${image.url}) ${image.repeat} ${image.position}/${image.size}`)  
        )
      };

      if (color) {
        arr.push(color);
      };

      if (arr.length > 0) return {background: arr.join(',')};
      return null;
    }

    const updateTheme = () => {
      localStorage.setItem('user-custom-theme', JSON.stringify(customValue));
      saveTheme(true);
      setShowModal(false);
      setTheme({...theme, box: '', bg: ''});
    }

    const saveTheme = (isCustom) => {
      let data;
      if(isCustom) {
        data = customValue;
      } else {
        data = {theme};
      }

      // axios.post(API_URL.SAVE_USER_THEME_URL,data,{
      //   headers: authHeader()
      // })
      // .then(response => {
      // })
      // .catch(error => {
      //     const resMessage = getError(error);
      //     if(error.response && error.response.status === 401) {
      //         Logout();
      //         setUser(null);
      //         history.push('/login');
      //     } else {
      //         toast.error(resMessage); 
      //     } 
      // });
    }

    return (
        <div className={classNames(styles.user)}>
            <LeftMenu 
              setShowBox={setShowBox} 
              setActiveTab={setActiveTab}
              setActiveSubTab={setActiveSubTab}
            />
            <div className={classNames(styles.mainContent, styles[theme.bg], {[styles.dark]: dark}, styles[theme.box])}
              style={setBackground(customValue.images, customValue.color)}
            >
              {
                showClaim && user.userRole === "Students" &&
                <div className={styles.dailyClaim} onClick={onClaim}>CLAIM <img src={creditIcon} height="25" /></div>
              }

              <Themes 
                styles={styles} 
                customValue={customValue}
                setCustomValue={setCustomValue} 
                savedCustomtheme={savedCustomtheme} 
                setShowModal={setShowModal}
                theme={theme}
                setTheme={setTheme}
                saveTheme={saveTheme}
              />
              <Switch>
                <Route path='/user/dashboard' component={Dashboard} />
                <Route path='/user/classes' component={MyClasses} />
                <Route path='/user/library' component={Library} />
                <Route path='/user/notifications' component={Notifications} />
                <Route path='/user/profile' component={Profile} />
                <Route path='/user/connection' component={Connection} />
                <Route path='/user/feedback' component={Feedback} />
                <Route path='/user/transaction' component={TransactionHistory} />
                <Route path='/user/faq' component={FAQ} />
                <Route path='/user/calendar' component={MyCalendar} />
                <Route path='/user/myAidaPal' component={MyAidaPal} />
                <Route path='/user/exam' component={Exam} />
                <Route path='/user/testPlay/:id/:themeId/:qId' component={WsPlay} />
                <Route path='/user/testPlay/:id/:themeId' component={WsPlay} />
              </Switch>
            </div>
            <Roles showRoles={showRoles} setShowRoles={setShowRoles} />
            <CustomTheme 
              styles={styles} 
              showModal={showModal} 
              setShowModal={setShowModal} 
              customValue={customValue}
              setCustomValue={setCustomValue}
              updateTheme={updateTheme}
            />
            {/* <Chat /> */}
        </div>
    )
}

export default User;