import {useState} from 'react';
import Input from '../../../components/input';
import {useHistory} from 'react-router-dom';
import classes from './styles/classes.module.scss';
import {toast} from 'react-toastify';

const SetMeetingForm = ({setShowModal, setMeetingInfo}) => {
    const history = useHistory();
    const [meetingNo, setMeetingNo] =  useState();
    const [pass, setPass] =  useState();

    const meetingNumberOnChange = (e) => setMeetingNo(e.target.value);
    const passwordOnChange = (e) => setPass(e.target.value);

    const onSubmit = () => {
        if(!meetingNo) {
            toast.error(`Meeting number is empty!`);
            return;
        }

        if(!pass) {
            toast.error(`Password is empty!`);
            return;
        }

        setMeetingInfo && setMeetingInfo({meetingNo, pass});
        setShowModal(false);
        // history.push(`/meeting?classId=${classId}&meeting=${meetingNo.replace(/\s+/g,"")}&pass=${pass}`);
    }
    return (
        <div className={classes.form}>
            <div className={classes.formControl}>
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={meetingNumberOnChange}
                    value={meetingNo}
                    label="Meeting Number"
                />
                <Input 
                    type="text" 
                    placeholder="" 
                    onChange={passwordOnChange}
                    value={pass}
                    label="Meeting Password"
                />
                <div>
                    <button className="btn" onClick={onSubmit}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default SetMeetingForm;