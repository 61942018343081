import percentage from './percentage.module.scss';

const Percentage = ({percentCompleted}) => {

    return (
        <div className={percentage.progress}>        
            <div className={percentage.progressContent}>
                <h2>{percentCompleted}% completed</h2>
                <div className={percentage.progressBar}>
                    <div style={{width: percentCompleted + '%'}}></div>
                </div>
            </div>
        </div>
    )
        
    
}

export default Percentage;