import worksheetIcon from '../../../images/ws.svg';

import classNames from 'classnames';
import { creditIcon } from '../../../common/utils';
import { Loader } from '../../../components/loading';
import { useHistory } from 'react-router-dom';
import DmCombo from './dmCombo';

const DailyMissionList = ({styles, combos, worksheets, loading, openModal, getList}) => {
    const history = useHistory();

    return (
        <div>
            {
                loading && <Loader />
            }
            {
                !loading &&
                <>
                    <h2>Learning Combos</h2>
                    {
                        combos.length === 0 &&
                        <div className='noResult'>No combos found.</div>
                    }
                    {
                        combos.length > 0 &&
                        <ul className={styles.comboList}>
                            {
                                combos.map((item,index) => 
                                    <DmCombo
                                        item={item}
                                        styles={styles}
                                        key={`combo ${index}`}
                                        openModal={openModal}
                                        getList={getList} />
                                )
                            }
                        </ul>
                    }
                    <h2>Worksheets</h2>
                    {
                        worksheets.length === 0 &&
                        <div className='noResult'>No worksheets found.</div>
                    }
                    {
                        worksheets.length > 0 &&
                        <ul className={styles.comboList}>
                            {
                                worksheets.map((item,index) => 
                                <li className={styles.worksheet} key={`item ${index}`} onClick={() => history.push(`testPlay/${item.worksheetId}/${item.themeId}?learnId=${item.learnId}&learnType=${item.learnType}`)}>
                                    <img title='Play Worksheet' src={worksheetIcon} width={80} />
                                    <div>
                                        <p className={styles.date}>Due {item.dueDate}</p>
                                        <h4>{item.topic}</h4>
                                        <h3>{item.subTopic}</h3>
                                        <div className={styles.credit}>{creditIcon(item.credit)}</div>
                                    </div>
                                    <span className={classNames(styles.status,{[styles.notStart]: item.status === "0%"})}>{item.status}</span>
                                </li>
                                )
                            }
                        </ul>
                    }
                </>
            }
        </div>
    )
}

export default DailyMissionList;