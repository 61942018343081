import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import styles from '../styles.module.scss';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from '../../../components/loading';

const TeacherInfo = ({classId, showModal, teacherId}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const [teacherInfo, setTeacherInfo] = useState();
    const [loading, setLoading] = useState();

    useEffect(()=>{
        let isCancelled = false;
        setLoading(true);
        showModal && axios.get(API_URL.GET_CLASS_TEACHER_INFO_URL,{
            headers: authHeader(),
            params: {
                classId: classId,
                userId: teacherId
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data && !isCancelled) {
                 response.data && setTeacherInfo(response.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
        return () => {
            isCancelled = true;
          };
    },[showModal])

    return(
        <div>
            {loading && <Loader />}
                    
            {
                !loading && teacherInfo &&
                <>
                    <h2>{teacherInfo.name}</h2>
                        <div className={styles.teacherProfile}>
                        {
                            teacherInfo.imageUrl && 
                            <div className={styles.image}>
                            <img src={teacherInfo.imageUrl} alt="teacher" />
                            </div>
                        }
                        <div className={styles.content} dangerouslySetInnerHTML={{ __html:teacherInfo.info }} />
                    </div>
                </>        
            }
        </div>
    )
}

export default TeacherInfo;