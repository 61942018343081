import {useState, useRef, useEffect} from 'react';
import {toast} from 'react-toastify';
import axios from 'axios';
import {getError, handleKeyPress} from '../../common/utils';
import input from './input.module.scss';
import {MdSearch, MdPerson, MdCancel, MdVisibility, MdEdit, MdArrowDropDown} from 'react-icons/md';
import { authHeader } from '../../services/auth-header';
import {Logout} from '../../services/auth.service';
import {useHistory} from 'react-router-dom';
import {useStore} from '../../store/useStore';
import useClickOutside from '../../hooks/useClickOutside';

const InputSearch = ({
        inputClass, value, placeholder, onChange, label, getResult, useFullResult,
        defaultValue, apiUrl, isMulti, formData, setFormData, collection, setValue, allowSelectPermission
    }) => {

    const history = useHistory();
    const {setUser} = useStore();
    const [result, setResult] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [curPermission, setCurPermission] = useState('view');
    const [showPermissions, setShowPermission] = useState(false);
    const permissions = useRef();
    
    useClickOutside(permissions, () => setShowPermission(false));

    // const ref = useRef();
    // useClickOutside(ref, () => setShowResult(false));
    useEffect(()=>{
        !showResult && setCurPermission('view');
    }, [showResult]);

    const onSearch = () => {
        setShowResult(!showResult);
        setResult({});
        if(!value) {
            toast.error(`${placeholder} is empty!`);
            return;
        }

        if(apiUrl) {
            axios.post(apiUrl,{search: value},{
                headers: authHeader()
            })
            .then(response => 
                {
                    setIsLoading(false);
                    response.data.user && setResult(response.data.user);
                }
            )
            .catch(error => {
                const resMessage = getError(error);
                
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }
            })
        }
    }

    const verifyResult = () => {
        setShowResult(false);
        setShowPermission(false);
        !isMulti && getResult && !useFullResult && getResult(result.userName);
        !isMulti && getResult && useFullResult && getResult(result);
        if(isMulti && formData && formData[collection] && !formData[collection].includes(result.userName)) {
            if (!useFullResult) {
                formData[collection].push(result.userName);
            } else {
                if (allowSelectPermission) {
                    result.permission = curPermission;
                }
                formData[collection].push(result);
            }
            setFormData && setFormData({...formData, [collection]: [...formData[collection]]});
        }
        setValue && setValue('');
    }

    const removeResult = (index) => {
        formData[collection].splice(index,1);
        setFormData({...formData, [collection]: [...formData[collection]]});
    }

    const onHandleKeyPress = (e) => {
        if(e.key === "Enter") {            
            handleKeyPress(e, "Enter", onSearch)
        }

        if(e.key === "Tab" && showResult && result.userName && !isLoading) {
            handleKeyPress(e, "Tab", verifyResult)
        }
    }

    const onInputChange = (e) => {
        setShowResult(false);
        onChange && onChange(e);
    }

    return(
      <div className={`${input.inputSearch} ${isMulti && input.maxInputSearch}`}>
        {label && <label>{label}</label> }
        <div>
            {
                isMulti && formData && formData[collection] && formData[collection].length > 0 &&
                <ul className={input.results}>
                    {formData[collection].map((result,index) => 
                        <li key={`result${index}`}>
                            {typeof result === 'object' && result.userImageUrl ? 
                                <img src={result.userImageUrl} width='24' alt="image" /> : ''
                            }
                            {typeof result === 'object' && result.fullName ? result.fullName : result}
                            <MdCancel className={input.cancel} onClick={() => removeResult(index)}/>
                            {typeof result === 'object' && result.permission && result.permission === 'view' &&
                                <MdVisibility className={input.permission} />
                            }
                            {typeof result === 'object' && result.permission && result.permission === 'edit' &&
                                <MdEdit className={input.permission} />
                            }
                        </li>    
                    )}
                </ul>
            }
            <input
                className={inputClass}
                value={value}
                placeholder={placeholder}
                onChange={onInputChange}
                defaultValue={defaultValue}
                onKeyPress={e => onHandleKeyPress(e)}
            //   onKeypress={e => handleKeyPress(e, "Tab", onTabClick)}
            />
            <MdSearch 
                size="25" 
                className={input.searchBtn} 
                onClick={onSearch}
                // ref={ref}
            />
        </div>
        
        {showResult && 
            <div className={input.result}>
            {
                result.userName && !isLoading &&
                <>
                    <div onClick={verifyResult} className={input.user}>
                        {result.userImageUrl && 
                            <img src={result.userImageUrl} alt="avatar" />
                        }
                        {!result.userImageUrl && 
                            <MdPerson size="40" color="#ccc"/>
                        }
                        <strong>
                            {result.fullName} 
                            {result.relationship && 
                                <span>
                                    {result.relationship}
                                </span>
                        }
                        </strong>
                    </div>
                    {
                        allowSelectPermission && curPermission &&
                        <div className={input.selectPermission}>
                            <div onClick={() => setShowPermission(true)}>
                                {
                                    curPermission === 'view' && <MdVisibility />
                                }
                                {
                                    curPermission === 'edit' && <MdEdit />
                                }
                                <MdArrowDropDown />
                            </div>
                            
                            {
                                showPermissions &&
                                <ul ref={permissions}>
                                    <li className={curPermission === 'view' ? input.active : undefined}
                                        onClick={() => {
                                            setCurPermission('view');
                                            setShowPermission(false);
                                            }
                                        }
                                    >
                                        <MdVisibility /> Can View
                                    </li>
                                    <li className={curPermission === 'edit' ? input.active : undefined}
                                        onClick={() => {
                                            setCurPermission('edit');
                                            setShowPermission(false);
                                            }
                                        }
                                    >
                                        <MdEdit /> Can Edit
                                    </li>
                                </ul>
                            }
                        </div>
                    }
                </>
                
            }

            {!result.userName && !isLoading && 
                'No result found.'
            }

            {
                isLoading && <div className={input.spinner}><span></span></div>
            }   
            </div>
        }
        
     </div>   
    )
}

export default InputSearch;