import {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Link} from 'react-router-dom';
import connection from './connection.module.scss';
import {MdDelete, MdEdit, MdInfoOutline, MdMoreHoriz, MdMoreVert, MdPerson, MdRemove} from 'react-icons/md';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {MdAddCircleOutline} from 'react-icons/md';
import Modal from '../../../components/modal';
import { useStore } from '../../../store/useStore';
import {uniqueId, clone} from 'lodash';
import ConnectionItem from './connectionItem';
import ConnectionForm from './connectionForm';
import { Loader } from '../../../components/loading';
import { getError } from '../../../common/utils';
import { Logout } from '../../../services/auth.service';
import Input from '../../../components/input';

const ConnectionSuggest = ({item, list, setList, index}) => {
    const history = useHistory();
    const {setUser} = useStore();
    const addConnection = () => {
        reactRequest('request');
    }

    const ignoreConnetion = () => {
        reactRequest('ignore');
    }

    const reactRequest = (type) => {
        return axios.post(API_URL.USER_CONNECTIONS_REQUEST_URL,{search: item.userName, type: type},{
            headers: authHeader()
        })
        .then(response => {
            if (type === "request") {
                toast.success('Your request is sent. Please wait until they confirm!');
            };
            list.splice(index, 1);
            setList([...list]);
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        <li>
            <div>
                {item.imageUrl && 
                    <img src={item.imageUrl} height="80" alt="profile" />
                }

                {!item.imageUrl && 
                    <MdPerson size="80" color="#ccc"/>
                }
            </div>
            <span>{item.fullName}</span>
            {
                item.mutual && item.mutual.type &&
                <div className={connection.mutual}>
                    <span>Mutual {item.mutual.type}</span>
                    <div className={connection.mutualFriends}>

                    </div>
                </div>
            }
           
            <button onClick={addConnection} className='smallBtn2'>Connect</button>
            <button onClick={ignoreConnetion}>Ignore</button>
        </li>
    )
}

const ConnectionList = ({list, title, isPending, getList, isCustomGroup, onMoreHandle, index}) => {
    const [showOptions, setShowOptions] = useState(false);    
    return (
    <div className={connection.connectionGroup}>
        <h4 className={isCustomGroup ? connection.customGroup : ''}>
            <span>{title}</span>
            {
                isCustomGroup &&
                <div onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                    <MdMoreHoriz title='more actions' size={25}/>
                    {
                        showOptions &&
                        <div className="options">
                            <MdEdit title="Edit group" onClick={() => onMoreHandle && onMoreHandle('edit', index)} />
                            <MdDelete title="Delete group" onClick={() => onMoreHandle && onMoreHandle('delete', index)} />
                        </div>
                    }
                </div>
                
            }
        </h4>
        {
            list.length > 0 &&
            <ul className={connection.list}>
                {
                    list.map((item) => (
                        <ConnectionItem 
                            getList={getList} 
                            key={uniqueId()} 
                            item={item} 
                            isPending={isPending}
                        />
                    ))
                }
            </ul>
        }
        {/* {
            !list.length &&
            <p>No item found.</p>
        } */}
    </div> ) 
}

const ConnectUsers =  () => {
    const history = useHistory();
    const contentRef = useRef();
    const {user, setUser, isRead, setIsRead} = useStore();
    const [connections, setConnections] = useState({pending: [], teachersStudents: [], family: [], friends: [], customGroups: []});
    const [fullConnections, setFullConnections] = useState({pending: [], teachersStudents: [], family: [], friends: [], customGroups: []});
    const [suggestFriends, setSuggestFriends] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState();
    const [search, setSearch] = useState('');
    const [type, setType] = useState('connection');
    const [modalType, setModalType] = useState('new');
    const [editItem, setEditItem] = useState(null);

    useEffect(()=>{
        let isCancelled = false;
        setLoading(true);
        getList(!isCancelled);
        return () => {
            isCancelled = true;
          };
    },[]);


    useEffect(()=>{
        if (search.length > 2) {
            setConnections({...connections,
                pending: connections.pending.filter(item => item.userName.includes(search)),
                teachersStudents: connections.teachersStudents.filter(item => item.userName.includes(search)),
                family: connections.family.filter(item => item.userName.includes(search)),
                friends: connections.friends.filter(item => item.userName.includes(search))
            })
        }

        if (!search.length) {
            setConnections({...fullConnections});
        }
    },[search]);

    const getList = (condition) => {
        return axios.get(API_URL.USER_CONNECTIONS_LIST_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data && condition) {
                 response.data.connections && setConnections(response.data.connections);
                 response.data.connections && setFullConnections(response.data.connections);
                 response.data.suggestFriends && setSuggestFriends(response.data.suggestFriends);
                 setLoading(false);
            }
        })
        .catch(error => {
            setLoading(false);
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const onCloseClick = () => {
        setIsRead({...isRead, connectionInfo: true});
        localStorage.setItem('connection-info-read', 'true');
    }

    const searchOnChange = (e) => {
        setSearch(e.target.value);
    }

    const onMoreHandle = (action, index) => {
        if (action === "delete") {
            const confirm = window.confirm('Are you sure you want to remove this group?');
            if (confirm) {
                connections.customGroups.splice(index, 1);
                setConnections({...connections});
            }
        }

        if (action === "edit") {
            setModalType('new');
            setShowModal(true);
            setType('group');
            setEditItem(connections.customGroups[index]);
        }
    }

    return (
        <div className={connection.connections}>
            <p className='systemInfo' style={{display: isRead.connectionInfo ? "none" : "block"}}>
                <MdInfoOutline size="30"/> 
                Connect to other users , then you can follow their activities 
                <Link to="#" onClick={onCloseClick}>&times;</Link>
            </p>
            {
                !loading && suggestFriends && suggestFriends.length > 0 &&
                <div className={connection.suggestFriends}>
                    <h3>People you may know</h3>
                    <ul>
                        {
                            suggestFriends.map((item, index) => 
                                <ConnectionSuggest 
                                    item={item} 
                                    index={index} 
                                    key={`suggest${index}`}
                                    list={suggestFriends}
                                    setList={setSuggestFriends}
                                />
                            )
                        }
                    </ul>
                </div>
            }
                           
                <div className={connection.connectionsList}>
                    <h2>
                        My Connections 
                        <span>
                        <MdAddCircleOutline onClick={() => setShowModal(true)}/>
                        </span>
                        <Input
                            type="text" 
                            placeholder="Search" 
                            className={"searchBox"}
                            onChange={searchOnChange}
                            value={search}
                            label=""
                        />
                    </h2>
                    {loading && <Loader />}
                    {
                        !loading &&
                        connections.pending.length === 0 
                        && connections.family.length === 0 
                        && connections.friends.length === 0
                        && connections.teachersStudents.length === 0 && 
                        <div className={connection.noResult}>No connection found</div>  
                    }
                    {
                        !loading &&
                        connections.pending.length > 0 && 
                        <ConnectionList 
                            list={connections.pending} 
                            isPending={true} 
                            title="Pending Request" 
                            getList={getList}
                        /> 
                    }

                    {
                        !loading &&
                        connections.family.length > 0 && 
                        <ConnectionList 
                            list={connections.family} 
                            title="Family" 
                            getList={getList}
                        /> 
                    }

                    {
                        !loading &&
                        connections.friends.length > 0 && 
                        <ConnectionList 
                            list={connections.friends} 
                            title="Friends" 
                            getList={getList}
                        />
                    }

                    {
                        !loading &&
                        connections.teachersStudents.length > 0 && 
                        <ConnectionList 
                            list={connections.teachersStudents} 
                            title={user.userRole === "Teachers" ? "Students" : "Teachers"} 
                            getList={getList}
                        /> 
                    }

                    {
                        !loading && 
                        connections.customGroups && connections.customGroups.length > 0 &&
                        connections.customGroups.map((group,index) => 
                        <ConnectionList
                            key={`group${index}`} 
                            index={index}
                            list={group.list} 
                            title={group.name} 
                            getList={getList}
                            isCustomGroup={true}
                            onMoreHandle={onMoreHandle}
                        />
                        )
                    }
                </div>
                <Modal 
                    setShowModal={setShowModal} 
                    showModal={showModal} 
                    contentRef={contentRef}
                    width={type === "connection" ? '400px' : '600px'}
                    height="auto"
                    noScroll={true}
                    content={
                        <div ref={contentRef}>
                            {
                                modalType === 'new' &&
                                <ConnectionForm 
                                    setShowModal={setShowModal}
                                    list={connections}
                                    setList={setConnections}
                                    getList={getList}
                                    type={type}
                                    setType={setType}
                                    editItem={editItem}
                                    setEditItem={setEditItem}
                                />
                            }
                        </div>
                    } 
                />
        </div>
    )
}

export default ConnectUsers;