import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import profile from '../profile/profile.module.scss';
import styles from '../styles.module.scss';
import Input from '../../../components/input';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import classNames from 'classnames';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
 
const Feedback = () => {
    const history = useHistory();
    const {setUser} = useStore();
    const[formData, setFormData] = useState({
        subject: '',
        message: ''
    });
    const [files, setFiles] = useState();
    const [fileNames, setFileNames] = useState([]);

    const messageOnChange = (e) => setFormData({...formData, message: e.target.value});
    const subjectOnChange = (e) => setFormData({...formData, subject: e.target.value});
    const filesOnChange = (e) => {
            const files = e.target.files;
            if(files.length > 5) {
                toast.error('Allow maximum 5 files. Please try again!');
                setFiles([]);
            } else {
                setFiles(e.target.files);
                if (e.target.files.length > 0) {
                    for (let i = 0; i < e.target.files.length; i++) {
                        fileNames.push(e.target.files[i].name);
                    }
                    setFileNames([...fileNames]);
                }
            }
    }

    const onSend = () => {
        if(!formData.subject) {
            toast.error('Subject is empty');
            return;
        }

        if(!formData.message) {
            toast.error('Message is empty');
            return;
        }

        const data = new FormData();

        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                data.append(files[i].name, files[i])
            }
        }

        data.append('Subject', formData.subject);
        data.append('Message', formData.message);

        const url = API_URL.USER_FEEDBACK_UPDATE_URL;
        axios.post(url,data,{
            headers: authHeader()
        })
        .then(response => {
            toast.success('Your feedback is sent!');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }
    
    return (
        <div className={styles.whiteBox}>
            <div className={classNames(profile.form, profile.feedbackForm)}>
                <div className={profile.formCol}>
                    <Input
                        type="text"
                        placeholder=''
                        onChange={subjectOnChange}
                        value={formData.subject}
                        label="Subject"
                    />
                    <Input 
                        type="textarea" 
                        placeholder="" 
                        onChange={messageOnChange}
                        value={formData.message}
                        label="Feedback"
                    />
                    <Input 
                        type="file" 
                        placeholder=""
                        onChange={filesOnChange}
                        files={files}
                        label="Upload files (up to 5 files)"
                        isMulti={true}
                        fileNames={fileNames}
                        className={profile.inputFile}
                    />
                    
                </div>
            </div>   

            <div className={profile.buttons}>
                <button className="btn" onClick={onSend}>Send</button>
            </div>
        </div>
    )
}

export default Feedback;