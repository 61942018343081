import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import {Link, useHistory} from 'react-router-dom';
import Menu from './menu';
import { MobileMenu } from './menu';
import RightMenus from './rightMenu';
import { useStore } from '../../store/useStore';

const Header = ({logout, socialConnect, products, alertsCount}) => {
    const history = useHistory();
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [type, setType] = useState('classes');
    const {user, currentMenu, setShowBox, setActiveSubTab, setActiveTab, moreInfo, setCurrentMenu} = useStore();

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setDeviceWidth(window.innerWidth);
    }

    let isMobile = (deviceWidth <= 768);

    const getSubMenus = (type, products) => {
        
        const itemList = products && products.length > 0 && products.filter(item => item.type === type);
        
        if (itemList && itemList.length > 0) {
            return itemList[0].list
        }

        return [];
    }

    const currentMenuOnClick = (url) => {
        history.push(url);
        setActiveSubTab('');
        setActiveTab('');
        setShowBox(true);
    }

    const logoOnClick = () => {
        if (user && currentMenu && user.userRole === 'Students') {
            setCurrentMenu(['My AidaPal','/user/myAidapal']);
            history.push('/user/myAidapal');
        } else if (user && currentMenu && user.userRole === 'Teachers'){
            setCurrentMenu(['Library','/user/library']);
            history.push('/user/library');
        } else {
            history.push('/#top');
        }
    }

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <span className={styles.logo} onClick={logoOnClick} style={{marginLeft: user && window.location.pathname.includes("user") ? 40 : 10}}><img src={"/logo-white.svg"} alt="Logo" width={120} /></span>
                <div className={styles.menu}>
                    {!isMobile && !(currentMenu && currentMenu.length) && <Menu subMenus={getSubMenus(type, products)} type={type}/>}
                </div>
                {isMobile && <MobileMenu isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} subMenus={getSubMenus(type, products)}/>}
                <RightMenus 
                    setIsMenuOpen={setIsMenuOpen} 
                    logout={logout}
                    alertsCount={alertsCount}
                    credit={moreInfo.credit}
                />
            </div>
            {  user && currentMenu && window.location.pathname.includes("user") && 
                <div className={styles.currentMenu}>
                    <h1>
                        <Link to="#" onClick={() => currentMenuOnClick(currentMenu[1])}>{currentMenu[0]}</Link>
                    </h1>
                </div>
            }
        </header>
    )
}

export default Header;