import {Fragment, useEffect, useState} from 'react';
import {MdChevronLeft, MdChevronRight, MdMoreHoriz} from 'react-icons/md';
import table from './table.module.scss';
import { OPTIONS_TYPE } from '../../common/utils';
import Input from '../input';

export const ItemPerPage = ({itemPerPage, onItemPerPageChange}) => {
  return(
    <div className={table.itemPerPage}>
        <label>Item per page</label>
        <Input
            type="select"
            defaultValue={{value: itemPerPage, label: itemPerPage}}
            options={OPTIONS_TYPE.ITEM_PER_PAGE}
            onChange={onItemPerPageChange}
        />
    </div>
  )
}

const PageItem = ({className, number, onClick}) => {
  const moveToPage = () => onClick && onClick(number - 1);
  return (
    <li className={className} onClick={moveToPage}>
      {number}
    </li>
  );
};

const Pagination = ({
  currentPage = 0,
  itemPerPage = 20,
  total,
  onChange,
  blockPerPage = 10,
}) => {
  const totalPage = Math.ceil(total / itemPerPage);
  const blocks = Math.ceil(totalPage / blockPerPage);
  const [currentBlock, setCurrentBlock] = useState(0);
  const pages = [];
  useEffect(() => {
    if (currentPage >= totalPage) {
      onChange(0);
    }
    setCurrentBlock(Math.floor(currentPage / blockPerPage));
    // eslint-disable-next-line
  }, [currentPage]);

  const max =
    totalPage - blockPerPage * currentBlock < blockPerPage ?
      totalPage - blockPerPage * currentBlock :
      blockPerPage;
  for (let i = 0; i < max; i++) {
    const pageInNumber = i + currentBlock * blockPerPage;
    pages.push(
      <PageItem
        key={`pagination-${currentPage + i}-${totalPage}`}
        number={pageInNumber + 1}
        className={currentPage === pageInNumber ? table.current : ''}
        onClick={onChange}
      />,
    );
  }

  const onGoBack = () => onChange(currentPage - 1);
  const onGoNext = () => onChange(currentPage + 1);
  const onPreviousBlock = () => setCurrentBlock(currentBlock - 1);
  const onNextBlock = () => setCurrentBlock(currentBlock + 1);

  if (totalPage < 2) {
    return null;
  }
  return (
    <ul className={table.pagination}>
      {currentPage > 0 && (
        <li className={table.btn} onClick={onGoBack}>
          <MdChevronLeft size={30} />
        </li>
      )}
      {currentBlock > 0 && (
        <Fragment>
          <PageItem number={1} onClick={onChange} />
          <li className={table.btn} onClick={onPreviousBlock}>
            <MdMoreHoriz size={30} />
          </li>
        </Fragment>
      )}
      {pages}
      {currentBlock < blocks - 1 && (
        <Fragment>
          <li className={table.btn} onClick={onNextBlock}>
            <MdMoreHoriz size={30} />
          </li>
          <PageItem number={totalPage} onClick={onChange} />
        </Fragment>
      )}
      {currentPage < totalPage - 1 && (
        <li className={table.btn} onClick={onGoNext}>
          <MdChevronRight size={30} />
        </li>
      )}
    </ul>
  );
};

export default Pagination;
