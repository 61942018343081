import CreatableSelect from 'react-select/creatable';
import Input from '../../../components/input';
import { OPTIONS_TYPE } from '../../../common/utils';
import InputSearch from '../../../components/input/inputSearch';
import API_URL from '../../../common/urls';
import { useState } from 'react';

const QuestionStep1 = ({lib, formData, setFormData, filtersData, showTime}) => {
    const [search, setSearch] = useState('');
    const tagsOnChange = type => setFormData({...formData, tags: type});
    const topicsOnChange = type => setFormData({...formData, topics: type});
    const subTopicsOnChange = type => setFormData({...formData, subTopics: type});
    const questionTimeOnChange = e => setFormData({...formData, questionTime: e.target.value});
    const difficultyOnChange = type => setFormData({...formData, difficulty: type});
    const levelOnChange = (type) => setFormData({...formData, levels: type});
    const subjectOnChange = (type) => setFormData({...formData, subjects: type});
    const searchOnChange = (e) => setSearch(e.target.value);

    return (
        <div className={lib.autoScroll}>
            <Input
                className={lib.select}
                type="select"
                options={filtersData && filtersData.levels}
                placeholder=''
                onChange={levelOnChange}
                value={formData.levels}
                label="Level"
                isMulti={true}
            />
            <Input
                className={lib.select}
                type="select"
                options={filtersData && filtersData.subjects}
                placeholder=''
                onChange={subjectOnChange}
                value={formData.subjects}
                label="Subject"
                isMulti={true}
            />
            <div className={lib.select}>
                <label>Topics <i>optional</i></label>
                <CreatableSelect
                    isMulti
                    onChange={topicsOnChange}
                    options={filtersData.topics}
                    value={formData.topics}
                    placeholder="Select or Create new topics"
                />
            </div>
            <div className={lib.select}>
                <label>Sub Topics <i>optional</i></label>
                <CreatableSelect
                    isMulti
                    onChange={subTopicsOnChange}
                    options={filtersData.subTopics}
                    value={formData.subTopics}
                    placeholder="Select or Create new sub topics"
                />
            </div>
            <div className={lib.select}>
                <label>Tags <i>optional</i></label>
                <CreatableSelect
                    isMulti
                    onChange={tagsOnChange}
                    options={filtersData.tags}
                    value={formData.tags}
                    placeholder="Select or Create new tags"
                />
            </div>
            <div className={lib.privacy}>
                <label>Privacy</label>
                <ul>
                    <li className={formData.privacy === "onlyMe" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "onlyMe"})}>Only Me</li>
                    <li className={formData.privacy === "authorised" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "authorised"})}>Authorised</li>
                    <li className={formData.privacy === "public" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "public"})}>Public</li>
                </ul>
            </div>
            {
                formData.privacy === "authorised" && 
                <InputSearch
                    placeholder='Search...'
                    onChange={searchOnChange}
                    label="Grant permission for users (username, phone or email)"
                    value={search}
                    setValue={setSearch}
                    apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                    formData={formData}
                    setFormData={setFormData}
                    collection='permissionForUsers'
                    isMulti
                    allowSelectPermission
                    useFullResult
                />
            }
            <Input
                className={`${lib.select} ${lib.shortSelect}`}
                type="select"
                options={OPTIONS_TYPE.QUESTION_DIFFICULTY_LEVEL}
                placeholder=''
                onChange={difficultyOnChange}
                value={formData.difficulty}
                label="Difficulty Level"
            />
            <Input 
                type="number" 
                placeholder="" 
                min={0}
                onChange={questionTimeOnChange}
                value={formData.questionTime}
                label="Question time (minutes)"
                className={lib.shortSelect}
                disabled={showTime}
            />
        </div>
    )
}

export default QuestionStep1;