import { useEffect, useState } from 'react';
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { useHistory } from "react-router";
import { getError, toastCredit } from '../../../common/utils';
import { toast } from 'react-toastify';
import API_URL from '../../../common/urls';
import DailyMissionList from './dmList';
import DmHistory from './dmHistory';
import moment from 'moment';

const DmContent = ({styles, subject, openModal, spentTime, selectItem, modalType}) => {
    const [combos, setCombos] = useState([]);
    const [worksheets, setWorksheets] = useState([]);
    const [loading, setLoading] = useState();
    const history = useHistory();
    const { setUser, setToastContent, moreInfo, setMoreInfo} = useStore();
    const [showHistory, setShowHistory] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [filters, setFilters] = useState({search: '', type: null});

    useEffect(()=>{
        !showHistory && getList();
        showHistory && getHistoryData();
    },[showHistory, filters, subject]);

    useEffect(()=>{
        if (spentTime.startTime && spentTime.endTime) {
            const totalSpentTime = moment.duration(moment(spentTime.endTime).diff(moment(spentTime.startTime)));
            const minutes = totalSpentTime.asMinutes();

            if (modalType === "file" && selectItem.readingTime && minutes > selectItem.readingTime){
                postMaterialView(selectItem.id, selectItem.readingId, 'readingDone', selectItem.index, minutes);
            }

            if (modalType === "video" && selectItem.videoDuration && minutes > selectItem.videoDuration) {
                postMaterialView(selectItem.id, selectItem.videoId, 'videoDone', selectItem.index, minutes);
            }
        }
        
    },[spentTime]);

    const getList = () => {
        setLoading(true);
        const url = API_URL.GET_MYAIDAPAL_DAILY_MISSION_LIST_URL;
        return axios.get(url,{
            headers: authHeader(),
            params:{
                subject
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.combos) {
                setCombos(response.data.combos);
            }
            if(response.data.worksheets) {
                setWorksheets(response.data.worksheets);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const getHistoryData = () => {
        setLoading(true);
        const url = API_URL.GET_MYAIDAPAL_DAILY_MISSION_HISTORY_URL;
        return axios.get(url,{
            headers: authHeader(),
            params: {
                subject,
                fromDate: filters && filters.fromDate ? filters.fromDate : null,
                toDate: filters && filters.toDate ? filters.toDate : null,
                type: filters && filters.type && filters.type.length ? filters.type.map(item => item.value).join(',') : null,
                search: filters && filters.search ? filters.search : null,
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.historyList) {
                setHistoryData(response.data.historyList);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const postMaterialView = (learnId, id, doneType, index, spentTime) => {
        axios.post(API_URL.CREDIT_CLAIM_URL,{},{
            headers: authHeader(),
            params: {
              learnId,
              type: 'view-material',
              id,
              doneType,
              spentTime
            }
          })
          .then(response => 
              {
                  if(response.data && response.data.bonus) {
                    setToastContent && setToastContent(toastCredit('+', response.data.bonus));
                    moreInfo.credit = moreInfo.credit + response.data.bonus;
                    setMoreInfo({...moreInfo});

                    const index = combos.indexOf(selectItem);
                    if (doneType === "readingDone") {  
                        combos[index].readingDone = true;
                        setCombos([...combos]);
                    }

                    if (doneType === "videoDone") {
                        combos[index].videoDone = true;
                        setCombos([...combos]);
                    }
                  }
              }
          )
          .catch(error => {
              const resMessage = getError(error);
              
              if(error.response && error.response.status === 401) {
                  Logout();
                  setUser(null);
                  history.push('/login');
              } else {
                  toast.error(resMessage); 
              } 
          });
    }

    return (
        <div className={styles.dailyMission}>
            {
                !showHistory &&
                <DailyMissionList 
                    styles={styles} 
                    loading={loading} 
                    combos={combos} 
                    worksheets={worksheets}
                    openModal={openModal}
                    getList={getList}
                    // setMaterialView={setMaterialView}
                />
            }
            {
                showHistory &&
                <DmHistory styles={styles} loading={loading} data={historyData} filters={filters} setFilters={setFilters} openModal={openModal}/>
            }
            <div className="buttons">
                <button className="btn btnLarge" onClick={() => setShowHistory(!showHistory)}>{showHistory ? 'Back to learning list' : 'View History'}</button>
            </div>
        </div>
    )
}

export default DmContent;