import {useState, useEffect, useRef} from 'react';
import {useHistory, Link} from 'react-router-dom';
import { ZoomMtg } from '@zoomus/websdk';
import API_URL from "../../../common/urls";
import {uniqueId} from 'lodash';
import { toast } from 'react-toastify';
import {MdPlaylistAdd, MdRecentActors, MdNoteAdd, MdComment} from 'react-icons/md';
import {FaUserClock, FaBookOpen} from 'react-icons/fa';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import meeting from "./styles/meeting.module.scss";
import { useStore } from '../../../store/useStore';
import {Logout} from '../../../services/auth.service';
import { Loader } from '../../../components/loading';
import useQuery from '../../../hooks/useQuery';
import Modal from '../../../components/modal';
import QuestionForm from './questionForm';
import LiveTestForm from './liveTestForm';
import SetBreakTimeForm from './setBreakTimeForm';
import BreakTime from '../../../components/modal/breakTime';
import Reports from './realtimeReports';
import NoteBoxForm from '../../../components/modal/noteBoxForm';
import NoteBox from '../../../components/modal/noteBox';
import LiveFeedbackForm from './liveFeedbackForm';
import classNames from 'classnames';
import ClassDocument from './classDocuments';
import creditIcon from '../../../images/medal.svg';
import SetCreditForm from './setCreditForm';

console.log('checkSystemRequirements');
console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av'); // 2.6.0 // 1.9.6
// ZoomMtg.setZoomJSLib('https://us02st1.zoom.us/web_client/umlcpk/js','/av-sdk');
ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();
ZoomMtg.prepareWebSDK();

const Meeting = ({classId, lessionId, meetingInfo}) => {
    const contentRef = useRef();
    const history = useHistory();
    const query = useQuery();
    // const classId = decodeURIComponent(query.get('classId'));
    // const meetingNo = decodeURIComponent(query.get('meeting'));
    // const pass = decodeURIComponent(query.get('pass'));
    // const classId = match.params.classId;
    const [assignId, setAssignId] = useState();
    const meetingNo = meetingInfo && meetingInfo.meetingNo ? meetingInfo.meetingNo.replace(/\s+/g,"") : "";
    const pass = meetingInfo && meetingInfo.pass ? meetingInfo.pass : "";
    const {user, setUser, setToastContent} = useStore();
    const [loading, setLoading] = useState();
    const [isNoClass, setIsNoClass] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('form');
    const [showIdle, setShowIdle] = useState(false);
    const [minutes, setMinutes] = useState(1);
    const chatSocket = useRef(null);
    const [showNoteBox, setShowNoteBox] = useState(false);
    const [notesList, setNotesList] = useState([]);
    const [noScroll, setNoScroll] = useState(false);

    useEffect(() => {
      if(showModal && modalType==="break"){
        setMinutes(1);
      }
    },[showModal]);

    useEffect(() => {
        // connect to web socket
        var socketPath = API_URL.WEBSOCKET_URL + lessionId.replace(/-/g,'_') + '/';
        console.log(socketPath);
        
        chatSocket.current = new WebSocket(socketPath);

        chatSocket.current.onopen = function() {
            //     chatSocket.send(JSON.stringify({
            //             'message': 'HELLO'
            //         }));
            console.log('Connected to web socket');
        }
        
        chatSocket.current.onmessage = (e) => {
            var data = JSON.parse(e.data);
            console.log(data)
            var message = {text: data.message, date: data.utc_time, data: data.data, users: data.users ? data.users : []};
            // use moment to convert time from UTC
            // message.date = moment(message.date).local().format('YYYY-MM-DD HH:mm:ss');
            if (message.text === 'set_break_time' && user.userRole === "Students") {
                setMinutes(parseInt(message.data));
                setShowIdle && setShowIdle(true);
            }

            if (message.text === 'cancel_break_time' && user.userRole === "Students") {
              setMinutes(0);
              setShowIdle && setShowIdle(false);
            }

            if (message.text.includes('popup_question_on_class:') && user.userRole === "Students") {
                setAssignId(message.data);
                setModalType('form');
                setShowModal(true);
            }

            if (message.text === 'set_feedback' && user.userRole === "Students") {
              setModalType('feedback');
              setShowModal(true);
            }

            if (message.text === 'end_meeting' && user.userRole === "Students") {
              history.push('/meetingEnd');
            }

            if (message.text === 'send_credit' && message.users.includes(user.userName)) {
              setToastContent(message.data);
            }
            
        };

        chatSocket.current.onclose = (e) => {
            console.log('Web socket closed!');
        };

        return () => {
            chatSocket.current.close();
        };
    },[]);

    useEffect(() => {
        let isCancelled = false;
        !meetingInfo.type.includes("Interactive") && setLoading(true);
        meetingInfo.type.includes("Interactive") && setLoading(false);
        // const data = {lessionId};

        const data = {lessonId : lessionId}; 
        //console.log(data);
        axios.post(API_URL.GET_USER_MEETING_SIGNATURE_URL, data, {
          headers: authHeader()
        }).then(response => {
          setLoading(false);

          if(response.data && !isCancelled && !meetingInfo.type.includes("Interactive")) {
            console.log(response.data);
            var apiKey = response.data.apiKey
            var meetingNumber = response.data.meetingNumber;
            var passWord = response.data.password;
            var signature = response.data.signature;
            var zak_token = response.data.zak_token;
            var role = user.userRole === "Teachers" ? 1 : 0;
            var leaveUrl = '/meetingEnd';
            var userName = user.userName;
            var userEmail = response.data.email ? response.data.email : user.userEmail;

            if (meetingNumber && signature && passWord) {              
              document.getElementById('zmmtg-root').style.display = 'block';
              /*
                * Init Zoom Meeting
                * @memberof ZoomMtg
                * @method init
                * @instance
                * @param {Object} params options
                * @param {Object} params.loginWindow options
                * @param {boolean} [params.debug=false] - Turn on debug mode can print log in browser console log
                * @param {string} params.leaveUrl - required, Post message page url after leave meeting, like “http://www.zoom.us”
                * @param {string} [params.webEndpoint] - web domain option for PSO ENV, contract with zoom PSO supporter
                * @param {boolean} [params.showMeetingHeader=true] - Show or hide the meeting header, includes meeting number and topic
                * @param {boolean} [params.disableInvite=false] - Enable or disable invite function.
                * @param {boolean} [params.disableCallOut=false] - Enable or disable call out function.
                * @param {boolean} [params.disableRecord=false] - Enable or disable record function.
                * @param {boolean} [params.disableJoinAudio=false] - Enable or disable join audio function.
                * @param {boolean} [params.audioPanelAlwaysOpen=false] - Set the default state of the audio panel on join.
                * @param {boolean} [params.isSupportAV=true] - Enable or disable if you want use audio and video feature.
                * @param {boolean} [params.isSupportChat=true] - Enable or disable if you want use chat feature.
                * @param {boolean} [params.isSupportQA=true] - Enable or disable if you want use Webinar Q&A feature.
                * @param {boolean} [params.isSupportCC=true] - Enable or disable if you want use Meeting Closed Caption feature.
                * @param {boolean} [params.isSupportPolling=true] - Enable or disable if you want use Meeting Polling feature.
                * @param {boolean} [params.isSupportBreakout=true] - Enable or disable if you want use Meeting Breakout Room feature.
                * @param {boolean} [params.screenShare=true] - Enable or disable if you want use browser feature(only chrome).
                * @param {boolean} [params.rwcBackup=''] - rwc domain, if set enable multi-rwc feature
                * @param {boolean} [params.videoDrag=true] - Enable drag video tile
                * @param {boolean} [params.sharingMode='both'] - 'fit' - disable sharing "origin size"
                * @param {boolean} [params.videoHeader=true] - Enable show video tile header
                * @param {boolean} [params.isLockBottom=true] - alway show footer or auto hide footer
                * @param {boolean} [params.isSupportNonverbal=true] - Enable/Disable Noverbal feedback feature
                * @param {boolean} [params.isShowJoiningErrorDialog=true] - Enable/Disable Join error popup dialog when join meeting fail
                * @param {boolean} [params.disablePreview=false] - Enable/Disable Audio and Video Preview
                * @param {boolean} [params.disableCORP=true] - Enable/Disable web isolation mode
                * @param {string} [params.inviteUrlFormat=''] - hide: hide invite url, or customer invite formate "https://your.com/?j={0}pwd={1}"
                * @param {int} [params.loginWindow.width=400] - Login subWindow width
                * @param {int} [params.loginWindow.height=380] - Login subWindow height
                * @param {boolean} [params.disableVoIP=false] - Enable/Disable VoIP feature
                * @param {boolean} [params.disableReport=false] - Enable/Disable Report feature
                * @param {array} [params.meetingInfo=['topic','host','mn','pwd','telPwd','invite','participant','dc', 'enctype', 'report']] - choice which meeting info want to show
                * @param {ZoomMtg.successCallback} [params.success] - Call back function on success
                * @param {ZoomMtg.errorCallback} [params.error] - Call back function on error
              */
              ZoomMtg.init({
                leaveUrl: leaveUrl,
                isSupportAV: true,
                // disableJoinAudio: user.userRole === "Teachers" ? true : false, // not stop audio, just stop microphone
                disablePreview: true, // set true to fix camera permission issue on Firefox
                success: (success) => {
                  console.log(success)
              
                  ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    sdkKey: apiKey,
                    userEmail: userEmail,
                    passWord: passWord,
                    tk: '',
                    zak: zak_token,
                    success: (success) => {
                      console.log(success);
                      setIsNoClass(false);
                    },
                    error: (error) => {
                      console.log(error)
                    }
                  })
              
                },
                error: (error) => {
                  console.log(error)
                }
              })
            } else {
              setIsNoClass(true);
              toast.error('No meeting available');
            }
          }
        })
        .catch(error => {
          setLoading(false);
          const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
        return () => {
            isCancelled = true; 
        };
        
    },[]);

    const logoOnclick = () => {
      document.getElementById('zmmtg-root').style.display = 'none';
      history.push('/');
    }

    const addFormOnClick = () => {
      setModalType('form');
      setShowModal(true);
    }

    const addBreakOnClick = () => {
      setModalType('break');
      setShowModal(true);
    }

    const addCreditOnClick = () => {
      setModalType('credit');
      setShowModal(true);
    }

    const viewRecentActions = () => {
      setModalType('reports');
      setShowModal(true);
    }

    const addNoteHandler = () => {
      setShowNoteBox(!showNoteBox);
    }

    const openMaterialsHandler = () => {
      setModalType('classDocuments');
      setShowModal(true);
    }

    const requestAddFeedback = () => {
        const confirm = window.confirm("Please confirm to send Feedback box to all students!");
        if(confirm) {
          axios.post(API_URL.POST_SEND_FEEDBACK_URL,{sendFeedback: true, lessionId: lessionId},{
            headers: authHeader()
          })
          .then(response => {
            toast.success('Feedback form has been sent to all students!');
          })
          .catch(error => {
              const resMessage = getError(error);
              
              if(error.response && error.response.status === 401) {
                  Logout();
                  setUser(null);
                  history.push('/login');
              } else {
                  toast.error(resMessage);
              }
          });
        }
    }

  const onNoteSave = (note, color) => {
      const _note = {note, color};
      notesList.push(_note);
      setNotesList([...notesList]);

      // call Api to save Notes 
      axios.post(API_URL.USER_ADD_NOTES_URL,notesList,{
          headers: authHeader(),
          params: {
              lessionId
          }
      })
      .then(response => {
      })
      .catch(error => {
          const resMessage = getError(error);
          
          if(error.response && error.response.status === 401) {
              Logout();
              setUser(null);
              history.push('/login');
          } else {
              toast.error(resMessage);
          }
      });
  }

  const onNotesListClose = (index) => {
      notesList.splice(index,1);
      setNotesList([...notesList]);

      // call Api to save Notes 
      axios.post(API_URL.USER_ADD_NOTES_URL,notesList,{
          headers: authHeader(),
          params: {
              lessionId
          }
      })
      .then(response => {
      })
      .catch(error => {
          const resMessage = getError(error);
          
          if(error.response && error.response.status === 401) {
              Logout();
              setUser(null);
              history.push('/login');
          } else {
              toast.error(resMessage);
          }
      });
  }

  const getPosition = (type, index) => {
    let pos = 0;
    const innerHeight = window.innerHeight;
    const innerWidth = window.innerWidth;
    if(innerHeight > (index + 1)*60 + 40) {
        if(type === "top") {
            pos = index === 0 ? 40 : index*60 + 40
        } else {
            pos = 5;
        }
    } else {
        if((index+1)*60 + 40 > innerHeight*2 - 110) {
            toast.info('View note reaches limitation, the note will be saved in list!');
            return;
        } else {
            const newIndex = Math.floor(((index + 1)*60 + 40 - innerHeight)/60);
            if(type === "top") {
                pos = index === 0 ? 40 : newIndex*60 + 40
            } else {
                pos = innerWidth - 210;
            }
        }
    }
    return pos;
}

  const onBreakTimeCancel = () => {
    setShowIdle(false);

    const data = {lessionId : lessionId};
    axios.post(API_URL.CANCEL_CLASS_BREAK_TIME_URL,data, {
        headers: authHeader()
    })
    .then(response => {
    })
    .catch(error => {
        const resMessage = getError(error);
        
        setLoading(false);
        if(error.response && error.response.status === 401) {
            Logout();
            setUser(null);
            history.push('/login');
        } else {
            toast.error(resMessage);
        }
    })
  }

  const endMeetingHandle = () => {
    if (user.userRole === "Teachers") {
      const confirm = window.confirm("Please confirm to end meeting to all students!");
      if(confirm) {

        const data = {lessionId : lessionId};
        axios.post(API_URL.END_MEETING_URL,data, {
          headers: authHeader()
        })
        .then(response => {
          history.push('/meetingEnd');
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
      }
    } else {
      const confirm = window.confirm("Are you sure to end this class meeting?");
      if(confirm) {
          history.push('/meetingEnd');
      }
    }
  }

    return (
        <div className={classNames(meeting.meeting, {[meeting.interactView]: meetingInfo.type.includes("Interactive")})}>
          {loading && <Loader />}
          {!loading && isNoClass && 
            <div className={meeting.notAvailable}>
              No class available
            </div>
          }
          {!loading && (isNoClass === false || meetingInfo.type.includes("Interactive")) && 
            <div className={meeting.aidaMenu}>
              <Link to="#" onClick={logoOnclick}><img src="/logo-white.svg" alt="Logo" width="120" /></Link> 
              {user.userRole === "Teachers" && 
                <>
                <MdPlaylistAdd size="25" onClick={addFormOnClick} title="Add Assignment" />
                <FaUserClock size="20" onClick={addBreakOnClick} title="Set Break Time"/>
                <MdRecentActors size="20" onClick={viewRecentActions} title="View live report board"/>
                <MdComment size="20" onClick={requestAddFeedback} title="Request feedback from student"/>
                <div onClick={addCreditOnClick} title='Reward students with medals'>+<img src={creditIcon} height="25" /></div>
                </>
              }
              <MdNoteAdd size="20" onClick={addNoteHandler} title="Add note"/>
              <FaBookOpen size="20"  onClick={openMaterialsHandler} title="Materials"/> 
            </div>
          }

          {
            !loading && meetingInfo.type.includes("Interactive") &&
            <h1>
              Class Meeting On Progress <br />
              {
                <button onClick={endMeetingHandle} className={meeting.endMeeting}>End Meeting</button>
              }
            </h1>
          }

          {
              showNoteBox && 
              <NoteBoxForm
                  setShowNoteBox={setShowNoteBox}
                  onSave={onNoteSave}
              />
          }
          {
              notesList && notesList.length > 0 && 
              notesList.map((item,index) => 
                  <NoteBox 
                      key={uniqueId()} 
                      note={item.note} 
                      color={item.color} 
                      top={getPosition('top', index)}
                      left={getPosition('right', index)}
                      onClose={() => onNotesListClose(index)}
                      isInMeeting={true}
                      index={index}
                  />
              )
          }
          <Modal 
            setShowModal={setShowModal} 
            showModal={showModal} 
            contentRef={contentRef}
            width={modalType === "break" ? "300px" : modalType === "reports" ? "90%" : modalType === "credit" ? "600px" : "80%"}
            height="auto"
            disabledClickOutside={user.userRole === "Students" && modalType === "form"? true : false}
            isTestForm={user.userRole === "Students" && modalType === "form"}
            content={
                <div ref={contentRef}>
                    {
                        user.userRole === "Teachers" && modalType === "form" && 
                        <QuestionForm 
                          classId={classId}
                          setShowModal={setShowModal}
                          lessionId={lessionId}
                        />
                    }

                    {
                      user.userRole === "Teachers" && modalType === "break" && 
                        <SetBreakTimeForm 
                          classId={classId} 
                          lessionId={lessionId}
                          setShowIdle={setShowIdle}
                          minutes={minutes} 
                          setMinutes={setMinutes}
                          setShowModal={setShowModal}
                        />
                    }

                    {
                      user.userRole === "Teachers" && modalType === "credit" && 
                        <SetCreditForm 
                          classId={classId} 
                          lessionId={lessionId}
                          // setShowIdle={setShowIdle}
                          // minutes={minutes} 
                          // setMinutes={setMinutes}
                          setShowModal={setShowModal}
                        />
                    }
                    {
                      user.userRole === "Teachers" && modalType === "reports" && 
                        <Reports
                          classId={classId}
                          showModal={showModal}
                          lessionId={lessionId}
                          modalType={modalType}
                        />
                    }

                    {
                        user.userRole === "Students" && modalType === "form" && 
                        <LiveTestForm 
                          lessionId={lessionId}
                          assignId={assignId}
                          setShowModal={setShowModal}
                          showModal={showModal}
                          setNoScroll={setNoScroll}
                        />
                    }

                    {
                      user.userRole === "Students" && modalType === "feedback" &&
                      <LiveFeedbackForm 
                          classId={classId}
                          lessionId={lessionId}
                          setShowModal={setShowModal}
                        />
                    }

                    {
                        modalType === "classDocuments" && 
                        <ClassDocument
                            classId={classId}
                            setShowModal={setShowModal} 
                            showModal={showModal}
                            setNoScroll={setNoScroll}
                            currentLessionId={lessionId}
                            // setDisabledClickOutside={setDisabledClickOutside}
                            // setIsTestForm={setIsTestForm}
                            isFromMeeting={true}
                        />
                    }
                </div>
            } 
          />
          <BreakTime 
            minutes={minutes} 
            setMinutes={setMinutes}
            showIdle={showIdle} 
            setShowIdle={setShowIdle} 
            onCancel={onBreakTimeCancel}
          />
        </div>
    )
}

export default Meeting;