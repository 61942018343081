import { Link } from 'react-router-dom';
import styles from '../styles.module.scss';
import revisionIcon from '../../../images/revision.svg';
import missionIcon from '../../../images/mission.svg';
import classNames from 'classnames';
import { parseRgb } from '../../../common/utils';
import { useStore } from '../../../store/useStore';

const TabContent = ({activeTab, setActiveTab, customValue, activeParent, setActiveParent}) => {
    const {user} = useStore();

    const tabOnClick = (tab) => {
        setActiveTab(tab);
    }

    return(
        <div>
            <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
                <div style={{width: 400}}>
                    <div className={styles.color1} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link
                            to="#" 
                            onClick={() => tabOnClick('Daily Mission')} 
                            style={{height: 400, background: `url(${missionIcon}) no-repeat center top /contain`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>
                                Daily Mission
                            </strong>
                        </Link>
                    </div>
                </div>
                
                <div style={{width: 300}}>
                    <div className={styles.color3} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => tabOnClick('Smart Journey')} 
                            className={activeTab === "Smart Journey" ? styles.active : ""}
                            style={{height: 95, marginBottom: 10, background: `url(${revisionIcon}) no-repeat center top /cover`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>
                                Smart Journey
                            </strong>
                        </Link>
                    </div>
                    <div className={styles.color2} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => tabOnClick('Smart Search')} 
                            className={activeTab === "Smart Search" ? styles.active : ""}
                            style={{height: 85, marginBottom: 10, background: `url(${revisionIcon}) no-repeat left top /cover`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>
                                Smart Search
                            </strong>
                        </Link>
                    </div>
                    <div className={styles.color4} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => tabOnClick('Revision')} 
                            className={activeTab === "Revision" ? styles.active : ""}
                            style={{height: 200, background: `url(${revisionIcon}) no-repeat center top /contain`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor}}>
                                Revision
                            </strong>
                        </Link>
                    </div>
                </div>
                
            </div>
        </div>        
    )
}

export default TabContent;