import Input from '../../../components/input';
import RichEditor2 from '../../../components/editor/editor2';
import { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import API_URL from '../../../common/urls';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { FaCheck } from 'react-icons/fa';

const QuestionStep2 = ({lib, formData, setFormData, setPercentCompleted}) => {
    const [fileNames, setFileNames] = useState([]);
    const [files, setFiles] = useState([]);
    const [addMediaUrl, setAddMediaUrl] = useState(false);
    const [addNewMedia, setAddNewMedia] = useState(false);
    const {setUser} = useStore();
    const history = useHistory();
    const onContentChange = (data) => {
        const noOfBlanks = data.match(/____/g) && data.match(/____/g).length;
        let arr = [];
        if(noOfBlanks > 0) {
            const correctLength = formData.correctText.length;
            if (correctLength) {
                arr = formData.correctText;
                if (noOfBlanks > correctLength) {
                    for(let i=correctLength; i<noOfBlanks; i++) {
                        arr.push("");
                    }
                } else {
                    arr = arr.slice(0, noOfBlanks);
                }
            } else {
                Array.from(Array(noOfBlanks)).map(item => arr.push(""));
            }
            setFormData({...formData, content: data, noOfBlanks: noOfBlanks, correctText: arr})
        } else {
            setFormData({...formData, content: data})
        }
    };
    const titleOnChange = e => setFormData({...formData, title: e.target.value});
    const mediaUrlOnChange = e => setFormData({...formData, mediaUrl: e && e.target && e.target.value});
    const mediaTypeOnChange = e => setFormData({...formData, mediaType: e && e.target && e.target.value});
    
    const filesOnChange = (e) => {
        setFiles(e.target.files);
        if (e.target.files.length > 0) {
            fileNames.push(e.target.files[0].name);
            setFileNames([...fileNames]);
        }
    }

    const addFilesHandler = () => {
        const data = new FormData();

        if (files && files.length > 0) {
            data.append('file', files[0]);
        }

        var config = {
            headers: authHeader(),
            onUploadProgress: function(progressEvent) {
              setPercentCompleted(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
          };

        axios.post(API_URL.USER_UPLOAD_FILE_URL,data,config)
        .then(response => {
            toast.success("File has been added to question!");
            setFormData({...formData, mediaUrl : response.data.data.link, mediaType: response.data.data.extension})
        })
        .catch(error => {
            const resMessage = getError(error);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const addBlank = () => {
        const content = formData.content.concat(' ____ ');
        setFormData({...formData, content: content});
    }

    return (
        <div className={lib.autoScroll}>
            <Input
                type="text" 
                placeholder="" 
                onChange={titleOnChange}
                value={formData.title}
                label="Title"
                className={lib.inputName}
                isOptional
            />
            <RichEditor2 
                label="Content"
                subLabel="<u>Note:</u> If question type is Fill-in-the-blank, click to <strong>Add Blank</strong> button or type 4 underscores (____) to insert the Blanks"
                subButton="Add Blank"
                subButtonHandler={addBlank}
                onChange={onContentChange}
                editorData={formData.content}
                className={lib.editorContent}
                isFull={true}
            />
            <div className={lib.additionInfo}>
                <div className={`checkBox ${addMediaUrl ? "active" : ''}`}
                    onClick={() => 
                        {
                            setAddMediaUrl(!addMediaUrl);
                            setAddNewMedia(false);
                        }}>
                    <FaCheck /> <span>Add Media File Url</span>
                </div>
                <div className={`checkBox ${addNewMedia ? "active" : ''}`}
                    onClick={() => 
                        {
                            setAddNewMedia(!addNewMedia);
                            setAddMediaUrl(false);
                        }}>
                    <FaCheck /> <span>Upload New Media File</span>
                </div>
            </div>
            <div className={lib.mediaArea}>
                {
                    addMediaUrl && 
                    <div className={lib.mediaFileUrl}>
                        <input type="text" onChange={mediaUrlOnChange} value={formData.mediaUrl} placeholder="File Url"/>
                        <input type="text" onChange={mediaTypeOnChange} value={formData.mediaType} placeholder="File Type"/>
                    </div>
                }

                {
                    addNewMedia && 
                    <div className={lib.mediaFileUpload}>
                        <Input 
                            type="file" 
                            placeholder="Click to choose file"
                            onChange={filesOnChange}
                            files={files}
                            label=""
                            isMulti={false}
                            fileNames={fileNames}
                            className={lib.inputFile}
                        />
                        {
                            fileNames.length > 0 &&
                            <input type="button" className="btn" onClick={addFilesHandler} value="Upload" />

                        }
                    </div>
                }
                
            </div>
        </div>
    )
}

export default QuestionStep2;