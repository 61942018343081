import { useState, useEffect, useRef } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { getError } from '../../../common/utils';
import { Loader } from "../../../components/loading";
import { useHistory } from "react-router-dom";
import ChallengeItem from "./challengeItem";
import { MdInfoOutline } from "react-icons/md";
import Modal from "../../../components/modal";
import ChallengeNew from "./challengeNew";
import ChallengeResponse from "./challengeResponse";

const ChallengesList = ({connection}) => {
    const [loading, setLoading] = useState();
    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState();
    const [modalType, setModalType] = useState('');
    const contentRef = useRef();
    const [curItem, setCurItem] = useState({});
    const [selectData, setSelectData] = useState({subjects: [], topics: [], expireOptions: [
        {label: '1 day', value: '1 day'},
        {label: '2 days', value: '2 days'},
        {label: '3 days', value: '3 days'}
    ]})
    const {user, setUser} = useStore();
    const history = useHistory();
    const [newChallenge, setNewChallenge] = useState({
        opponentType: 'random',
        toUsers: '',
        subjects: null,
        topics: [],
        bet: 0,
        expire: '1 day'
    });

    useEffect(()=>{
        getList();
    },[]);

    const getList = () => {
        setLoading(true);
        return axios.get(API_URL.USER_CHALLENGES_LIST_URL,{
            headers: authHeader(),
            params: {
                // type: curTab
            }
        })
        .then(response => {
            if(response.data) {
                setLoading(false);
                response.data.list && setList(response.data.list);
                response.data.subjects && response.data.topics && setSelectData({...selectData, subjects: response.data.subjects, topics: response.data.topics});
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    const onNewChallenge = () => {
        setShowModal(true);
        setModalType('new');
    }
    return (
        <div className={connection.challenges}>
            {
                loading && <Loader />
            }
            {
                !loading && 
                <div>
                    <p className="systemInfo"><MdInfoOutline size="30"/> Maximum 3 challenges per day. If there are 3 already, to create new challenge you have to reject your friend request.</p>
                    <ul>
                    {
                        list && list.length > 0 &&
                        list.map((item, index) => 
                            <ChallengeItem
                                key={`challengeList-${index}`}
                                item={item}
                                connection={connection}
                                setCurItem={setCurItem}
                                setShowModal={setShowModal}
                                setModalType={setModalType}
                                user={user}
                                history={history}
                            />
                        )
                    }
                    {
                        list.length < 3 &&
                        Array.from(Array(3 - list.length)).map((item,index) => 
                            <li className={connection.new} onClick={onNewChallenge}>+</li>
                        )
                    }
                    </ul>
                </div>
            }
            <Modal 
                setShowModal={setShowModal} 
                showModal={showModal} 
                contentRef={contentRef}
                width={"500px"}
                height={"auto"}
                disabledClickOutside={modalType === "generating"}
                noScroll={modalType === "reactionList"}
                content={
                    <div ref={contentRef}>
                        {
                            (modalType === "new" || modalType === "edit") &&
                            <ChallengeNew 
                                connection={connection}
                                list={list}
                                setList={setList}
                                setShowModal={setShowModal}
                                newChallenge={newChallenge}
                                setNewChallenge={setNewChallenge}
                                curItem={curItem}
                                selectData={selectData}
                            />
                        }
                        {
                            modalType === "accept" && curItem && 
                            <ChallengeResponse
                                type="accept"
                                curItem={curItem}
                                connection={connection}
                                setShowModal={setShowModal}
                                list={list}
                                setList={setList}
                            />
                        }
                        {
                            modalType === "reject" && curItem && 
                            <ChallengeResponse
                                type="reject"
                                curItem={curItem}
                                connection={connection}
                                setShowModal={setShowModal}
                                list={list}
                                setList={setList}
                            />
                        }
                    </div>
                }
            />
        </div>
    )
}

export default ChallengesList;