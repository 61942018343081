import {useState} from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import editor from './editor.module.scss';

const RichEditor2 = ({editorData, onChange, className, label, subLabel, subButton, subButtonHandler, isFull, isOptional}) => {  
    const [words, setWords] = useState(0);
    const [characters, setCharacters] = useState(0);
    const onEditorChange = ( event, editor ) => {
        const data = editor.getData();
        onChange && onChange(data);
    }

    const basicItems = [
            'heading',
            '|',
            'bold',
            'underline',
            'italic',
            'strikethrough',
            'link',
            'bulletedList',
            'numberedList',
            'fontFamily',
            'fontSize',
            'fontColor',
            'alignment',
            '|',
            'superscript',
            'subscript',
            'specialCharacters',
            '|',
            'imageInsert',
            'mediaEmbed',
            'insertTable',
            'blockQuote',
    ];
    const fullItems = [
            'heading',
            '|',
            'bold',
            'underline',
            'italic',
            'strikethrough',
            'link',
            'bulletedList',
            'numberedList',
            'fontFamily',
            'fontSize',
            'fontBackgroundColor',
            'fontColor',
            'highlight',
            'alignment',
            'outdent',
            'indent',
            'removeFormat',
            '|',
            'htmlEmbed',
            'restrictedEditingException',
            'code',
            'codeBlock',
            // '-',
            'findAndReplace',
            '|',
            'undo',
            'redo',
            '|',
            'superscript',
            'subscript',
            'specialCharacters',
            'todoList',
            'horizontalLine',
            'pageBreak',
            '|',
            'imageInsert',
            'mediaEmbed',
            'insertTable',
            'blockQuote',
            '|',
            'textPartLanguage',
            '|',
            'sourceEditing',
];

    return (
            <div className={className}>
                {label && 
                    <label>
                        {label}
                        {isOptional && <i>Optional</i>}
                    </label>
                }
                {subLabel && <p dangerouslySetInnerHTML={{__html: subLabel}} />}
                {subButton && <div style={{textAlign: 'right'}}><button className="smallBtn" onClick={subButtonHandler}>{subButton}</button></div>}
                <CKEditor
                    editor={ ClassicEditor }
                    config={{
                        wordCount: {
                            onUpdate: stats => {
                                setWords(stats.words);
                                setCharacters(stats.characters);
                            }
                        },
                        toolbar: {
                            items: isFull ? fullItems : basicItems,
                            shouldNotGroupWhenFull: true
                        },
                        language: 'en',
                        image: {
                            toolbar: [
                                'imageTextAlternative',
                                'imageStyle:inline',
                                'imageStyle:block',
                                'imageStyle:side'
                            ]
                        },
                        table: {
                            contentToolbar: [
                                'tableColumn',
                                'tableRow',
                                'mergeTableCells',
                                'tableCellProperties',
                                'tableProperties'
                            ]
                        }
                    }}
                    data={editorData}
                    onChange={onEditorChange}
                />
                <div className={editor.wordCount}>
                    <i>Words: <b>{words}</b></i><i>Characters: <b>{characters}</b></i>
                </div>    
            </div>
    )
}

export default RichEditor2;