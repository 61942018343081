const frontend_domain = process.env.REACT_APP_FRONTEND_DOMAIN;
// const sandbox_string = process.env.REACT_APP_SANDBOX;
const sandbox_string = '.sandbox.';

export const FRONTEND_URL = {
    PAYMENT_REDIRECT_URL: `${frontend_domain}/payment-result`,
    CART_PAGE_URL: `${frontend_domain}/cart`
}

const domain = process.env.REACT_APP_API_DOMAIN;

const websocket_domain = process.env.REACT_APP_WEBSOCKET_DOMAIN;

const API_URL = {
    CREATE_PAYMENT_URL: `https://api${sandbox_string}hit-pay.com/v1/payment-requests`,
    CREATE_PAYMENT_CART_URL: `https://api${sandbox_string}checkout.com/hosted-payments`,
    CHECKOUT_PAYMENT_WEBHOOK_URL: `https://api${sandbox_string}checkout.com/webhooks`,
    // USER_GET_PAYMENT_REFERENCE_URL: 'https://demo3900486.mockable.io/user-payment-reference',
    USER_GET_PAYMENT_REFERENCE_URL: `${domain}/payments/api/v1/shoping_cart_with_auth`, 

    // PAYMENT_WEBHOOK_URL: 'https://demo0753129.mockable.io/aida-post',
    HITPAY_PAYMENT_WEBHOOK_CALLBACK_URL: `${domain}/payments/api/v1/hitpay_payment_callback_no_auth`, 

    CHECKOUT_PAYMENT_WEBHOOK_CALLBACK_URL: `${domain}/payments/api/v1/checkout_payment_callback_no_auth`, 

    COUNTRY_LOOKUP_URL: 'https://extreme-ip-lookup.com/json/',
    // GENERAL_URL: 'https://demo0753129.mockable.io/aida-general',
    GENERAL_URL: `${domain}/lms/api/v1/general_no_auth`,
    // HOMEPAGE_URL: 'https://demo6174520.mockable.io/homepage-data',
    HOMEPAGE_URL: `${domain}/lms/api/v1/new_homepage_no_auth`,
    // CLASSES_LIST_URL: 'https://demo0753129.mockable.io/aida-products',
    CLASSES_LIST_URL: `${domain}/lms/api/v1/classes_list_no_auth`,
    // CLASSES_LIST_TRIAL_URL: 'https://demo0753129.mockable.io/products-trial-list',
    CLASSES_LIST_TRIAL_URL: `${domain}/lms/api/v1/classes_list_no_auth?classType=Trial`,
    // CLASS_DETAIL_URL: 'https://demo0753129.mockable.io/aida-product-detail',
    CLASS_DETAIL_URL: `${domain}/lms/api/v1/class_detail_no_auth`,
    
    // BLOGS_URL: 'https://demo0753129.mockable.io/blogs',
    BLOGS_URL: `${domain}/lms/api/v1/blog_no_auth`,
    // ABOUT_US_URL: 'https://demo0753129.mockable.io/about-us',
    ABOUT_US_URL: `${domain}/lms/api/v1/aboutus_no_auth`,
    // USER_PROFILE_URL: 'https://demo0753129.mockable.io/user-profile',
    USER_PROFILE_URL: `${domain}/users/api/v1/get_user_profile_with_auth`,
    // USER_CONNECTIONS_LIST_URL: 'https://demo3900486.mockable.io/user-connections',
    // USER_CONNECTIONS_LIST_URL: `${domain}/users/api/v1/user_connections_with_auth`,
    USER_CONNECTIONS_LIST_URL: 'https://edtechee.mockable.io/profile-connections',
    // USER_INFO_URL: 'https://demo0753129.mockable.io/user-info', // [Thao]: not in use for now
    // ALERTS_COUNTING_URL: 'https://demo0753129.mockable.io/alerts-counting', // get and post
    ALERTS_COUNTING_URL: `${domain}/users/api/v1/alerts_count_with_auth`, // get: return number of new alerts and post: mark as viewed
    // ALERTS_LIST_URL: 'https://demo0753129.mockable.io/alerts-list', //get and post, post with alertId -> this alertId should be removed after acting 
    ALERTS_LIST_URL: `${domain}/users/api/v1/alerts_action_with_auth`, //get and post, post with alertId -> this alertId should be removed after acting 
    
    // USER_GET_REQUEST_PAYMENT_URL: 'https://demo0753129.mockable.io/get-request-payment', // get and post, post to remove the item in the list (with requestId)
    USER_GET_REQUEST_PAYMENT_URL: `${domain}/payments/api/v1/request_payment_with_auth`,
    
    // FAQ_LIST_URL: 'https://demo6174520.mockable.io/faq-list',//params: userRole
    FAQ_LIST_URL: `${domain}/lms/api/v1/get_faq_with_auth`, 
    // GET_TRANSATION_HISTORY_URL: 'https://demo6174520.mockable.io/transaction-history',
    // GET_TRANSATION_HISTORY_URL: 'https://edtechee.mockable.io/transaction-history',
    GET_TRANSATION_HISTORY_URL: `${domain}/payments/api/v1/transaction_history_with_auth`,
    
    // GET_SUBJECTS_LIST_URL: 'https://demo6174520.mockable.io/subjects-list',
    GET_SUBJECTS_LIST_URL: `${domain}/lms/api/v1/categories_list_no_auth?category=subjects`,
    // GET_USER_PROFILE_LIST_URL: 'https://demo6174520.mockable.io/profile-list',
    GET_USER_PROFILE_LIST_URL: `${domain}/lms/api/v1/categories_list_no_auth`,
    // GET_USER_CLASSES_LIST_URL: 'https://demo6174520.mockable.io/my-classes',
    GET_USER_CLASSES_LIST_URL: `${domain}/lms/api/v1/my_classes_list_with_auth`,
    // GET_USER_TRIAL_CLASSES_LIST_URL: 'https://demo6174520.mockable.io/my-trial-classes',
    GET_USER_TRIAL_CLASSES_LIST_URL: `${domain}/lms/api/v1/my_classes_list_with_auth?classType=Trial`,
    // GET_USER_COMPLETED_CLASSES_LIST_URL: 'https://demo6174520.mockable.io/my-completed-classes',
    GET_USER_COMPLETED_CLASSES_LIST_URL: `${domain}/lms/api/v1/my_classes_list_with_auth?isCompleted=true`,
    GET_USER_CLASS_INFORMATION_URL: 'https://demo3900486.mockable.io/user-class-info',
    // GET_USER_CLASS_LESSIONS_LIST_URL: 'https://demo0753129.mockable.io/user-class-lession', //params : classId
    GET_USER_CLASS_LESSIONS_LIST_URL: `${domain}/lms/api/v1/class_lession_with_auth`, //params : classId
    // GET_USER_LESSION_RESOURCE_LIST_URL: 'https://demo0753129.mockable.io/user-lession-resources', //params: lessionId
    GET_USER_LESSION_RESOURCE_LIST_URL: `${domain}/lms/api/v1/lession_assignment_list_with_auth`, //params: lessionId

    GET_USER_LIVE_TEST_URL: `${domain}/lms/api/v1/live_test_question_with_auth`, //params: lessionId, questionId
    // GET_USER_LIVE_TEST_URL:`https://edtechee.mockable.io/live-test`,
    // GET_USER_LIVE_TEST_URL2: `https://edtechee.mockable.io/live-test-question`, //params: lessionId, questionId
    GET_USER_LIVE_TEST_URL2: `${domain}/lms/api/v1/myaidapal_worksheet_question_with_auth`,
    // GET_USER_LIVE_TEST_LIST_URL: 'https://edtechee.mockable.io/live-test-questions-list', // params: assignId
    GET_PUBLIC_TEST_URL: `https://edtechee.mockable.io/shareQuestion`,
    GET_USER_LIVE_TEST_LIST_URL: `${domain}/lms/api/v1/myaidapal_worksheet_question_list_with_auth`,
    // GET_USER_LIVE_TEST_INFO_URL: 'https://edtechee.mockable.io/live-test-info', // params: assignId
    GET_USER_LIVE_TEST_INFO_URL: `${domain}/lms/api/v1/myaidapal_worksheet_info_with_auth`,
    // GET_USER_LIVE_TEST_NOTE_LIST_URL: 'https://edtechee.mockable.io/live-test-question-notes', // params: questionId
    GET_USER_LIVE_TEST_NOTE_LIST_URL: `${domain}/lms/api/v1/myaidapal_question_notes_with_auth`,
    // POST_USER_LIVE_TEST_ACTION_URL: 'https://edtechee.mockable.io/post-daily-mission',
    POST_USER_LIVE_TEST_ACTION_URL: `${domain}/lms/api/v1/myaidapal_post_daily_mission_with_auth`,
    
    // GET_USER_DASHBOARD_LIVE_CLASS_URL: 'https://edtechee.mockable.io/dashboard-tab1', //params: grade, subject, classId, fromDate, toDate
    GET_USER_DASHBOARD_LIVE_CLASS_URL: `${domain}/lms/api/v1/dashboard_live_class_with_auth`,
    // GET_USER_DASHBOARD_GRADES_FILTER_URL: 'https://edtechee.mockable.io/dashboard-filter',
    GET_USER_DASHBOARD_GRADES_FILTER_URL: `${domain}/lms/api/v1/dashboard_grades_filter_with_auth`,
    // GET_USER_DASHBOARD_CLASS_NAME_FILTER_URL: 'https://edtechee.mockable.io/dashboard-filter-className', //params : grade, subject
    GET_USER_DASHBOARD_CLASS_NAME_FILTER_URL: `${domain}/lms/api/v1/dashboard_class_filter_with_auth`,
    // GET_USER_DASHBOARD_PROFICIENCY_URL: 'https://edtechee.mockable.io/dashboard-proficiency-reports',//params: grade, subject, classId, fromDate, toDate
    GET_USER_DASHBOARD_PROFICIENCY_URL: `${domain}/lms/api/v1/dashboard_proficiency_with_auth`,
    // GET_USER_DASHBOARD_PROFICIENCY_DETAILS_URL: 'https://edtechee.mockable.io/dashboard-proficiency-details', // params: studentId
    GET_USER_DASHBOARD_PROFICIENCY_DETAILS_URL: `${domain}/lms/api/v1/dashboard_proficiency_details_with_auth`,
    // GET_USER_DASHBOARD_ASSIGNMENT_TRACKING_URL: 'https://edtechee.mockable.io/dashboard-assignment-track',
    GET_USER_DASHBOARD_ASSIGNMENT_TRACKING_URL: `${domain}/lms/api/v1/dashboard_assignment_with_auth`,
    // GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_URL: 'https://edtechee.mockable.io/dashboard-assignment-details',
    GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_URL: `${domain}/lms/api/v1/dashboard_assignment_details_with_auth`,
    // GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_QUESTION_URL: 'https://edtechee.mockable.io/dashboard-assignment-details-question',
    GET_USER_DASHBOARD_ASSIGNMENT_DETAILS_QUESTION_URL: `${domain}/lms/api/v1/dashboard_assignment_details_question_with_auth`,
    // GET_USER_DASHBOARD_QUICK_VIEW_URL: 'https://edtechee.mockable.io/dashboard-quick-view',
    GET_USER_DASHBOARD_QUICK_VIEW_URL: `${domain}/lms/api/v1/dashboard_quick_view_with_auth`,
    // GET_USER_DASHBOARD_QUICK_VIEW_SUBJECTS_URL: `https://edtechee.mockable.io/dashboard-quick-view-subjects`,
    GET_USER_DASHBOARD_QUICK_VIEW_SUBJECTS_URL: `${domain}/lms/api/v1/dashboard_quick_view_subjects_count_with_auth`,
    // GET_USER_DASHBOARD_REVISION_URL: 'https://edtechee.mockable.io/dashboard-revision',
    GET_USER_DASHBOARD_REVISION_URL: `${domain}/lms/api/v1/dashboard_revision_with_auth`,

    // GET_CALENDAR_EVENTS_URL:'https://edtechee.mockable.io/calendar-events',
    GET_CALENDAR_EVENTS_URL: `${domain}/lms/api/v1/calendar_events_with_auth`,
    
    // GET_CLASS_STUDENTS_LIST_URL: 'https://edtechee.mockable.io/class-students-list', // params: classId , when class has not finished "details" should be empty obj {}
    GET_CLASS_STUDENTS_LIST_URL: `${domain}/lms/api/v1/class_students_list_with_auth`,
    
// GET_CLASS_TEACHER_INFO_URL: 'https://demo3900486.mockable.io/class-teacher-info', // params: classId
    GET_CLASS_TEACHER_INFO_URL: `${domain}/users/api/v1/user_intro_with_auth`,
    
    // GET_CLASS_DOCUMENTS_LIST_URL: 'https://edtechee.mockable.io/class-documents', // params: classId
    GET_CLASS_DOCUMENTS_LIST_URL: `${domain}/lms/api/v1/class_materials_with_auth`, 
    // POST_CLASS_ATTACHMENTS_URL: 'https://demo0753129.mockable.io/aida-post', //param: classId, lessionId, if lessionId null , so files will be add to class (generic)
    POST_CLASS_ATTACHMENTS_URL: `${domain}/lms/api/v1/class_materials_with_auth`, 
    // UPDATE_CLASS_DOCUMENTS_LIST_URL: 'https://edtechee.mockable.io/delete-item', // delete with id and type
    UPDATE_CLASS_DOCUMENTS_LIST_URL: `${domain}/lms/api/v1/class_materials_with_auth`, 
    DOCUMENT_PDF_VIEW_URL: `${domain}/lms/api/v1/document_view_with_auth`, 
    COURSEWARE_PDF_VIEW_URL: `${domain}/lms/api/v1/courseware_view_with_auth`, 
    // GET_USER_CLASS_REPORTS_LIST_URL: 'https://edtechee.mockable.io/class-reports-list',
    GET_USER_CLASS_REPORTS_LIST_URL: `${domain}/lms/api/v1/class_report_with_auth`,  // params: classId, lessionId
    // GET_USER_CLASS_REPORTS_STUDENT_LIST_URL: 'https://edtechee.mockable.io/student-report',
    GET_USER_CLASS_REPORTS_STUDENT_LIST_URL: `${domain}/lms/api/v1/class_report_student_with_auth`,
    // GET_USER_ASSIGNMENT_URL: 'https://edtechee.mockable.io/assigment-review', //param: assignId , studentId is available if student clicks to review , in case studentId will have "studentAnswer" and "resuult"
    GET_USER_ASSIGNMENT_URL: `${domain}/lms/api/v1/assignment_details_with_auth`, 
    GET_USER_ASSIGNMENT_QUESTION_URL: `${domain}/lms/api/v1/question_details_with_auth`, 
    // GET_USER_ASSIGNMENT_QUESTION_URL: 'https://edtechee.mockable.io/assignment-question-details',
    // GET_USER_ASSIGMENT_TOPICS_LIST_URL: 'https://edtechee.mockable.io/topics-list',
    GET_USER_ASSIGMENT_TOPICS_LIST_URL: `${domain}/lms/api/v1/categories_list_no_auth?category=topics`,

    // GET_LIBRARY_FOLDERS_URL: 'https://edtechee.mockable.io/folders-list',
    GET_LIBRARY_FOLDERS_URL: `${domain}/lms/api/v1/library_folders_list_with_auth`, 
    // GET_LIBRARY_LIST_URL: 'https://edtechee.mockable.io/library-list',
    GET_LIBRARY_LIST_URL: `${domain}/lms/api/v1/library_list_with_auth`, 
    // GET_LIBRARY_FILTERS_LIST_URL: 'https://edtechee.mockable.io/library-filters',
    GET_LIBRARY_FILTERS_LIST_URL: `${domain}/lms/api/v1/library_filters_no_auth`,
    // GET_LIBRARY_ITEM_INFO_URL: 'https://edtechee.mockable.io/library-item-info',
    GET_LIBRARY_ITEM_INFO_URL: `${domain}/lms/api/v1/library_item_info_with_auth`,
    // GET_LIBRARY_QUESTIONS_LIST_URL: 'https://edtechee.mockable.io/library-questions-list',
    GET_LIBRARY_QUESTIONS_LIST_URL: `${domain}/lms/api/v1/library_questions_list_with_auth`,
    // GET_LIBRARY_ITEM_CONTENT_URL: 'https://edtechee.mockable.io/library-item-content', //params: type (question,folder,file,worksheet)
    GET_LIBRARY_ITEM_CONTENT_URL: `${domain}/lms/api/v1/library_item_content_with_auth`,
    
    // POST_LIBRARY_ITEMS_ACTION: 'https://demo0753129.mockable.io/aida-post',
    POST_LIBRARY_ITEMS_ACTION: `${domain}/lms/api/v1/library_items_action_with_auth`, 
    // POST_LIBRARY_CREATE_NEW_ITEM: 'https://edtechee.mockable.io/aida-post-new', //response importId if upload questions list file
    POST_LIBRARY_CREATE_NEW_ITEM: `${domain}/lms/api/v1/library_create_new_item_with_auth`, 
    // POST_LIBRARY_CREATE_NEW_ITEM: 'https://edtechee.mockable.io/aida-post-fail',
    // POST_LIBRARY_CREATE_NEW_ITEM: `${domain}/lms/api/v1/library_action_with_auth`,
    // POST_LIBRARY_IMPORT_ACTION: 'https://demo0753129.mockable.io/aida-post', //delete all questions in import, delete 1 question
    POST_LIBRARY_IMPORT_ACTION: `${domain}/lms/api/v1/library_import_action_with_auth`, 
    // POST_USER_CREATE_QUESTION_URL: 'https://edtechee.mockable.io/library-save-question',
    POST_USER_CREATE_QUESTION_URL: `${domain}/lms/api/v1/library_save_question_with_auth`, 
    POST_LIBRARY_PERMISSION_REQUEST_URL: `${domain}/lms/api/v1/library_permission_request_action_with_auth`,

    // GET_MYAIDAPAL_DAILY_MISSION_LIST_URL: 'https://edtechee.mockable.io/daily-mission-list',
    GET_MYAIDAPAL_DAILY_MISSION_LIST_URL: `${domain}/lms/api/v1/daily_missions_list_with_auth`, 

    // GET_MYAIDAPAL_DAILY_MISSION_HISTORY_URL: 'https://edtechee.mockable.io/daily-mission-history',
    GET_MYAIDAPAL_DAILY_MISSION_HISTORY_URL: `${domain}/lms/api/v1/myaidapal_learning_histories_list_with_auth`,
    // GET_TOPIC_VIDEOS_LIST_URL: 'https://edtechee.mockable.io/topic-videos-list',
    GET_TOPIC_VIDEOS_LIST_URL: `${domain}/lms/api/v1/topic_video_list_with_auth`, 

    // GET_TOPIC_NOTES_LIST_URL: 'https://edtechee.mockable.io/topic-notes-list',
    GET_TOPIC_NOTES_LIST_URL: `${domain}/lms/api/v1/topic_notes_list_with_auth`,
    // GET_SMART_JOURNEY_LIST_URL: 'https://edtechee.mockable.io/smart-journey-list',
    GET_SMART_JOURNEY_LIST_URL: `${domain}/lms/api/v1/learning_paths_with_auth`,
    // GET_SMART_JOURNEY_LEVELS_LIST_URL: 'https://edtechee.mockable.io/smart-journey-levels-list',
    GET_SMART_JOURNEY_LEVELS_LIST_URL: `${domain}/lms/api/v1/categories_list_no_auth?category=grades`,
    // POST_SMART_JOURNEY_GENERATED_URL: 'https://edtechee.mockable.io/smart-journey-generated',
    POST_SMART_JOURNEY_GENERATED_URL: `${domain}/lms/api/v1/myaidapal_generate_learning_worksheet_with_auth`,
    // GET_REVISION_TIMELINES_URL: 'https://edtechee.mockable.io/revision-timelines',
    GET_REVISION_TIMELINES_URL: `${domain}/lms/api/v1/get_revision_timelines_with_auth`,
    // GET_REVISION_QUESTION_CONTENT_URL: 'https://edtechee.mockable.io/revision-question-content',
    GET_REVISION_QUESTION_CONTENT_URL: `${domain}/lms/api/v1/get_revision_question_content_with_auth`,
    // GET_SMART_SEARCH_URL: 'https://edtechee.mockable.io/smart-search-results',
    GET_SMART_SEARCH_URL: `${domain}/lms/api/v1/myaidapal_smart_search_with_auth`,
    // POST_DAILY_MISSION_ITEM_URL: 'https://edtechee.mockable.io/post-daily-mission',
    POST_DAILY_MISSION_ITEM_URL: `${domain}/lms/api/v1/daily_mission_item_with_auth`,

    //credit
    // CREDIT_CLAIM_URL: 'https://edtechee.mockable.io/aida-post', //params: type, id
    CREDIT_CLAIM_URL: `${domain}/lms/api/v1/myaidapal_claim_credits_with_auth`,
    // GET_LEADER_BOARD_LIST_URL: 'https://edtechee.mockable.io/leader-board', //param : type (daily, monthly, allTime)
    GET_LEADER_BOARD_LIST_URL: `${domain}/lms/api/v1/dashboard_leader_board_with_auth`,
    // GET_USER_ADDITIONAL_INFO: 'https://edtechee.mockable.io/user-additional-infos',
    GET_USER_ADDITIONAL_INFO: `${domain}/users/api/v1/user_additional_infos_with_auth`,

    //sharing
    USER_SHARING_URL: 'https://edtechee.mockable.io/sharings', //params: type (mySharing, connectionSharing)
    USER_SHARING_COMMENTS_URL: 'https://edtechee.mockable.io/sharing-comments',
    USER_SHARING_REACTION_LIST_URL: 'https://edtechee.mockable.io/sharing-reaction-list',
    USER_SHARING_ACTION_URL: 'https://edtechee.mockable.io/aida-post', //response are just for new case
    USER_SHARING_COMMENTS_NEW_COMMENT_URL: 'https://edtechee.mockable.io/aida-post', // new comment or edit a comment

    //Gifts
    GET_GIFT_MY_POSSESSIONS_URL: 'https://edtechee.mockable.io/gifts-my-possessions',
    GET_GIFT_LIST_URL: 'https://edtechee.mockable.io/gifts-list',
    GET_GIFT_TRACKING_URL: 'https://edtechee.mockable.io/gifts-tracking',
    POST_GIFT_ACTION_URL: 'https://edtechee.mockable.io/aida-post',

    //Challenges
    USER_CHALLENGES_LIST_URL: 'https://edtechee.mockable.io/challenges-list',
    USER_CHALLENGES_HISTORY_URL: 'https://edtechee.mockable.io/challenges-history',
    USER_CHALLENGES_ACTION_URL: 'https://edtechee.mockable.io/challenges-action-new', //response are just for new case

    //Credit Exchange
    // USER_CREDIT_EXCHANGE_LIST_URL: 'https://edtechee.mockable.io/credit-shop',
    USER_CREDIT_EXCHANGE_LIST_URL: `${domain}/lms/api/v1/credit_exchange_list_with_auth`,
    // USER_CREDIT_EXCHANGE_ACTION_URL: 'https://edtechee.mockable.io/aida-post',
    USER_CREDIT_EXCHANGE_ACTION_URL: `${domain}/lms/api/v1/credit_exchange_action_with_auth`,
    // USER_CREDIT_EXCHANGE_TRACKING_URL: 'https://edtechee.mockable.io/credit-exchange-tracking',
    USER_CREDIT_EXCHANGE_TRACKING_URL: `${domain}/lms/api/v1/credit_exchange_tracking_with_auth`,

    //Exam
    USER_EXAM_LIST_URL: 'https://edtechee.mockable.io/exam-list',
    USER_EXAM_ACTION_URL: 'https://edtechee.mockable.io/aida-post',
    USER_EXAM_HISTORY_URL: 'https://edtechee.mockable.io/exam-history',

    //Chat
    USER_CHAT_LIST_URL: 'https://edtechee.mockable.io/chat-list',
    USER_CHAT_MESSAGES_URL: 'https://edtechee.mockable.io/chat-message-list',
    USER_CHAT_ACTION_URL: 'https://edtechee.mockable.io/aida-post',

    // LOGIN_URL: 'https://edtechee.mockable.io/login',
    LOGIN_URL: `${domain}/users/api/v1/token`,
    LINK_TOC_FILE: `https://aida-learning-lms-sit.s3.amazonaws.com/media/public/master_config_files/AIDAPAL_TERMS_AND_CONDITIONS.pdf`,
    // UPDATE_AGREE_AND_TOC: 'https://demo0753129.mockable.io/aida-post',
    UPDATE_AGREE_AND_TOC: `${domain}/users/api/v1/update_agreed_toc_time`,
    // REGISTER_URL: 'https://demo0753129.mockable.io/aida-post',
    REGISTER_URL: `${domain}/users/api/v1/signup_no_auth`,
    // UPDATE_PASSWORD_URL: 'https://demo0753129.mockable.io/aida-post',
    UPDATE_PASSWORD_URL: `${domain}/users/api/v1/forgot_pass_update_no_auth`,
    // VERIFICATION_URL: 'https://demo0753129.mockable.io/aida-post',
    VERIFICATION_URL: `${domain}/users/api/v1/veri_code_signup_no_auth`,
    // FORGOT_VERIFICATION_URL: 'https://demo0753129.mockable.io/aida-post',
    FORGOT_VERIFICATION_URL: `${domain}/users/api/v1/veri_code_forgot_pass_no_auth`,
    // CHECK_EXISTED_USER: 'https://demo0753129.mockable.io/aida-post',
    CHECK_EXISTED_USER: `${domain}/users/api/v1/check_existed_user_no_auth`,
    // CONTACT_US_POST_URL: 'https://demo0753129.mockable.io/aida-post',
    CONTACT_US_POST_URL: `${domain}/lms/api/v1/contactus_no_auth`,
    // ADD_ROLE_URL: 'https://demo0753129.mockable.io/update-role',
    ADD_ROLE_URL: `${domain}/users/api/v1/update_user_role_with_auth`,
    // CHANGE_PASS_URL: 'https://demo0753129.mockable.io/aida-post',
    CHANGE_PASS_URL: `${domain}/users/api/v1/change_password_with_auth`,
    // USER_PROFILE_UPDATE_URL: 'https://demo0753129.mockable.io/aida-post',
    USER_PROFILE_UPDATE_URL: `${domain}/users/api/v1/update_user_profile_with_auth`,
    SAVE_USER_THEME_URL: 'https://edtechee.mockable.io/aida-post',
    
    // USER_IMAGE_UPDATE_URL: 'https://demo0753129.mockable.io/aida-post', // response as "userImageUrl"
    USER_IMAGE_UPDATE_URL: `${domain}/users/api/v1/update_user_photo_with_auth`,
    SUBSCRIBE_URL: 'https://demo0753129.mockable.io/aida-post',
    // CHECK_PROMO_CODE_URL: 'https://demo6174520.mockable.io/check-code', // Frontend will send referal code, exist student code, promo code here, just different type
    CHECK_PROMO_CODE_URL: `${domain}/payments/api/v1/discount_code_with_auth`, 
    
    // CONNECTION_SEARCH_URL: 'https://demo6174520.mockable.io/user-searching',
    CONNECTION_SEARCH_URL: `${domain}/users/api/v1/search_user_with_auth`, 
    
    USER_CONNECTIONS_REQUEST_URL: 'https://demo0753129.mockable.io/aida-post',
    // USER_CONNECTIONS_REQUEST_URL: `${domain}/users/api/v1/connect_user_with_auth`, 
    
    // USER_CONNECTIONS_REQUEST_ACTION_URL: 'https://demo0753129.mockable.io/aida-post', //params will be userName and action: approve, cancel request, and disconnect
    USER_CONNECTIONS_REQUEST_ACTION_URL: `${domain}/users/api/v1/action_connect_user_with_auth`, //params will be userName and action: approve, cancel request, and disconnect
    
    USER_REQUEST_PAYMENT_URL: 'https://demo0753129.mockable.io/aida-post',
    // USER_FEEDBACK_UPDATE_URL: 'https://demo0753129.mockable.io/aida-post',
    USER_FEEDBACK_UPDATE_URL: `${domain}/lms/api/v1/feedback_with_auth`, 
    USER_CREAT_CLASS_URL: 'https://demo0753129.mockable.io/aida-post',
    // USER_ADD_TRIAL_CLASS_URL: 'https://demo0753129.mockable.io/aida-post', // params: classId
    USER_ADD_TRIAL_CLASS_URL: `${domain}/lms/api/v1/add_trial_class_with_auth`,

    USER_PAYMENT_REQUEST_URL: 'https://demo0753129.mockable.io/aida-post',
    // GET_USER_MEETING_SIGNATURE_URL: 'https://demo0753129.mockable.io/aida-post', // response: meetingNumber, password, signature, apiKey
    GET_USER_MEETING_SIGNATURE_URL: `${domain}/lms/api/v1/create_zoom_meeting_signature_with_auth`, 
    // USER_ADD_ASSIGNMENT_URL: 'https://demo0753129.mockable.io/aida-post',
    USER_ADD_ASSIGNMENT_URL: `${domain}/lms/api/v1/assignment_details_with_auth`, // assignment_form_with_auth`, 
    // USER_ADD_ASSIGNMENT_QUESTION_URL: 'https://demo0753129.mockable.io/aida-post', //can use same api with GET_USER_ASSIGNMENT_QUESTION_URL
    USER_ADD_ASSIGNMENT_QUESTION_URL: `${domain}/lms/api/v1/question_details_with_auth`, 
    // USER_SEND_QUESTION_LIST_URL: 'https://demo0753129.mockable.io/aida-post',
    USER_SEND_QUESTION_LIST_URL: `${domain}/lms/api/v1/popup_question_on_class_with_auth`, 
    

    // POST_USER_LIVE_TEST_RESULT_URL: 'https://edtechee.mockable.io/live_test_result',
    POST_USER_LIVE_TEST_RESULT_URL: 'https://edtechee.mockable.io/live_test_result-question',
    // POST_USER_LIVE_TEST_RESULT_URL: `${domain}/lms/api/v1/live_test_result_with_auth`,
    POST_USER_LIVE_TEST_RESULT_URL: `${domain}/lms/api/v1/myaidapal_worksheet_question_with_auth`,

    POST_USER_NEW_CLASS_URL: 'https://demo0753129.mockable.io/aida-post',

    // USER_UPLOAD_FILE_URL: 'https://demo3900486.mockable.io/editor-upload-image', // the right response should be as sample with "data" and "link" inside
    USER_UPLOAD_FILE_URL: `${domain}/lms/api/v1/file_upload`, 
    
    // USER_ADD_NOTES_URL: 'https://demo0753129.mockable.io/aida-post',
    USER_ADD_NOTES_URL: `${domain}/lms/api/v1/add_user_note_with_auth`, 
    // USER_MEETING_APP_LINK_URL: 'https://edtechee.mockable.io/get-meeting-link', //param: classId, lessionId
    USER_MEETING_APP_LINK_URL: `${domain}/lms/api/v1/create_zoom_meeting_link`, 
 
    WEBSOCKET_URL: `${websocket_domain}/ws/`, 

    SET_CLASS_BREAK_TIME_URL: `${domain}/lms/api/v1/broad_cast_message_to_meeting_with_auth?action=set_break_time`, 
    CANCEL_CLASS_BREAK_TIME_URL: `${domain}/lms/api/v1/broad_cast_message_to_meeting_with_auth?action=cancel_break_time`, 
    END_MEETING_URL: `${domain}/lms/api/v1/broad_cast_message_to_meeting_with_auth?action=end_meeting`, 
    SEND_CREDIT_URL: `${domain}/lms/api/v1/broad_cast_message_to_meeting_with_auth?action=send_credit`, 

    // POST_CLASS_LIVE_FEEDBACK_URL: 'https://demo0753129.mockable.io/aida-post', //params : classId, lessionId
    POST_CLASS_LIVE_FEEDBACK_URL: `${domain}/lms/api/v1/class_lesson_feedback_with_auth`, 
    
    // POST_SEND_FEEDBACK_URL: 'https://demo0753129.mockable.io/aida-post', //params : classId, lessionId
    POST_SEND_FEEDBACK_URL: `${domain}/lms/api/v1/broad_cast_message_to_meeting_with_auth?action=set_feedback`, 
    // POST_USER_MANUAL_MARK_URL: 'https://demo0753129.mockable.io/aida-post', //params : assignId, questionId, studentId
    POST_USER_MANUAL_MARK_URL: `${domain}/lms/api/v1/manual_mark_with_auth`,
    // POST_USER_DIRECTLY_MARKUP_URL: 'https://demo0753129.mockable.io/aida-post'
    POST_USER_DIRECTLY_MARKUP_URL: `${domain}/lms/api/v1/answer_teacher_markup_with_auth`,
}

export default API_URL;