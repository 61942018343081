import { forwardRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { OPTIONS_TYPE } from "../../../common/utils";
import DateRangePicker from "../../../components/day-range";
import Input from '../../../components/input';

const HistoryFilter = forwardRef(({styles, filters, setFilters, setShowFilter}, ref) => {
    const [filter, setFilter] = useState(filters);
    const onDateRangeChange = (from, to) => setFilter({...filter, fromDate:from, toDate: to});
    const typeOnChange = (type) => setFilter({...filter, type: type});

    const onApplyFilter = () => {
        setFilters && setFilters(filter);
        setShowFilter && setShowFilter(false);
    }

    return (
    <div className={styles.filtersOption} ref={ref}>
        <div>
            <div>
                <label>Date</label>
                <DateRangePicker
                    fromDate={filter.fromDate}
                    toDate={filter.toDate}
                    onChange={onDateRangeChange}
                    className={styles.datePicker}
                />
            </div>
            <Input
                className={styles.select}
                type="select"
                options={[{label: 'Combo', value: 'combo'},{label: 'Worksheet', value: 'worksheet'}]}
                placeholder=''
                onChange={typeOnChange}
                value={filter.type}
                label="Type"
                isMulti={true}
            />
        </div>
        <div className="rightAlign">
            <button className="smallBtn2" onClick={() => onApplyFilter()}>Apply Filters</button>
        </div>
    </div>
        
    )
});

export default HistoryFilter;