import classNames from 'classnames';
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import table from './table.module.scss';

const Table = ({className, head, children, id=''}) => {
  return (
    <table className={classNames(table.table, className)} id={id} cellSpacing="0">
      {head && <thead className={table.head}>{head}</thead>}
      <tbody className={table.body}>{children}</tbody>
    </table>
  );
};

export const Cell = ({className, children, align = 'left', width, style,  ...rest}) => {
  return (
    <td {...rest} className={classNames(className, table[align])} width={width} align={align} style={style}>
      {children}
    </td>
  );
};

export const SortHeader = ({
  className,
  index,
  align,
  valign,
  text,
  sortType,
  onSortChange,
  width,
  isSortDisabled,
}) => {

  const onHandleSort = (e) => {
    e.preventDefault();
    onSortChange && onSortChange(index);
  };

  return (
    !isSortDisabled ? (
      <Cell
        index={index}
        align={align}
        valign={valign}
        className={classNames(table.clickable, table.stick, {[table.isShowSortIcon]: sortType}, className)}
        width={width}
      >
        <div className={table.headText} onClick={onHandleSort}>
          {text}
          {sortType && (
            <span className={table.sort}>
              {sortType === 'asc' && <MdKeyboardArrowUp className={table.top} />}
              {sortType === 'desc' && (
                <MdKeyboardArrowDown className={table.down} />
              )}
            </span>
          )}
        </div>
      </Cell>
    ) : (
      <StickHeader
        className={className}
        index={index}
        align={align}
        valign={valign}
        width={width}
      >{text}</StickHeader>
    )
  );
};

export const StickHeader = ({
  className,
  index,
  align,
  children,
  icon,
  onIconClick,
  ...rest
}) => {
  return (
    <Cell
      {...rest}
      index={index}
      align={align}
      className={classNames(table.stick, className)}
    >
      <strong>{children}</strong>
    </Cell>
  );
};

export default Table;
