import {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import Banner from './banner';
import API_URL from '../../common/urls';
import { toast } from 'react-toastify';
import Loading from '../../components/loading';
import { getError } from '../../common/utils';
import Products from './products';
import WhatBring from './whatBring';
import AiLearning from './aiLearning';
import QuickView from './quickView';
import WhyUs from './whyAidaPal';
import Price from './price';
import HowToStart from './howToStart';
import WhatNews from './whatNews';
import ContactUs from './contactUs';
// import {authHeader} from '../services/auth-header';

const Home = () => {
    const [bannerInfo, setBannerInfo] = useState({});
    const [part2, setPart2] = useState(null);
    const [part3, setPart3] = useState(null);
    const [part4, setPart4] = useState(null);
    const [part5, setPart5] = useState(null);
    const [part6, setPart6] = useState(null);
    const [part7, setPart7] = useState(null);
    const [part8, setPart8] = useState(null);
    const [part9, setPart9] = useState(null);
    const [part10, setPart10] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const [curIndex, setCurIndex] = useState(0);

    useEffect(() => {
        let isCancelled = false;
        const url = API_URL.HOMEPAGE_URL;
        axios.get(url)
        .then(response => {
            if(response.data && !isCancelled) {
                response.data.part1 && setBannerInfo(response.data.part1); 
                response.data.part2 && setPart2(response.data.part2); 
                response.data.part3 && setPart3(response.data.part3); 
                response.data.part4 && setPart4(response.data.part4); 
                response.data.part5 && setPart5(response.data.part5); 
                response.data.part6 && setPart6(response.data.part6); 
                response.data.part7 && setPart7(response.data.part7); 
                response.data.part8 && setPart8(response.data.part8);
                response.data.part9 && setPart9(response.data.part9); 
                response.data.part10 && setPart10(response.data.part10); 
                setShowLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            toast.error(resMessage);
            setShowLoading(false);
        })

        return () => {
            isCancelled = true;
          };
    },[]);

    const buyOnClick = () => {
        setCurIndex(2);
    }
    return (
        <Fragment>
            <Loading showLoading={showLoading}/>
            <Banner data={bannerInfo} />
            <Products data={part2} />
            <WhatBring data={part3} />
            <AiLearning data={part4} buyOnClick={buyOnClick} />
            <QuickView data={part5} />
            <WhyUs data={part6} />
            <Price data={part7} buyOnClick={buyOnClick} />
            <HowToStart data={part8} curIndex={curIndex} setCurIndex={setCurIndex} />
            <WhatNews data={part9} />
            <ContactUs data={part10} />
            {/* <Mission data={mission}/>
            <Teachers data={teachers}/>
            <Testimonials data={testimonials}/> */}
        </Fragment>
    )
}

export default Home;