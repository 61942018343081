import {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import { getError } from '../../../common/utils';
import { useStore } from '../../../store/useStore';
import {uniqueId} from 'lodash';
import { Loader } from '../../../components/loading';
import Modal from '../../../components/modal';
import ClassItem from '../../../components/class-item';
import classes from './styles/classes.module.scss';
import styles from '../styles.module.scss';
import {Logout} from '../../../services/auth.service';
import SetMeetingForm from './setMeetingForm';
import Meeting from './meeting';
import StudentsList from './studentsList';
import TeacherInfo from './teacherInfo';
import ClassDocument from './classDocuments';
import Reports from './realtimeReports';
import LiveFeedbackForm from './liveFeedbackForm';

const ClassesList = ({list, trial, isCompleted, isMyClass, getList, setShowModal, setClassId, setLessionId, 
    setTeacherId, setModalType, setMeetingInfo, setClassContent, joinClassByApp}) => {  
    return (
    <div className={classes.connectionGroup}>
        <div className={classes.list}>
            {
                list && list.length > 0 && list.map((item) => (
                    <ClassItem
                        data={item} 
                        key={uniqueId()} 
                        trial={trial}
                        isMyClass={isMyClass}
                        isCompleted={isCompleted}
                        getList={getList}
                        setShowModal={setShowModal}
                        setClassId={setClassId}
                        setLessionId={setLessionId}
                        setTeacherId={setTeacherId}
                        setModalType={setModalType}
                        setMeetingInfo={setMeetingInfo}
                        setClassContent={setClassContent}
                        joinClassByApp={joinClassByApp}
                    />
                ))
            }
        </div>
    </div> ) 
}

const Classes = ({trial, isCompleted, isMyClass}) => {
    const contentRef = useRef();
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [noScroll, setNoScroll] = useState(false);
    const [isTestForm, setIsTestForm] = useState(false);
    const [modalType, setModalType] = useState('form');
    const [loading, setLoading] = useState();
    const {user, setUser} = useStore();
    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState('');
    const [lessionId, setLessionId] = useState('');
    const [teacherId, setTeacherId] = useState('');
    const [meetingInfo, setMeetingInfo] = useState({meetingNo: '', pass: '', type: ''});
    const [classContent, setClassContent] = useState({title: '', imageUrl: '', classIntro: ''});
    const [disabledClickOutside, setDisabledClickOutside] = useState(false);
    const [isAssigmentPreview ,setIsAssigmentPreview] = useState(false);

    useEffect(()=>{
        let isCancelled = false;
        setLoading(true);
        getList(!isCancelled);
        return () => {
            isCancelled = true;
          };
    },[]);

    const getList = (condition) => {
        return axios.get(trial ? API_URL.GET_USER_TRIAL_CLASSES_LIST_URL : isCompleted ? API_URL.GET_USER_COMPLETED_CLASSES_LIST_URL : API_URL.GET_USER_CLASSES_LIST_URL,{
            headers: authHeader()
        })
        .then(response => {
            if(response.data && condition) {
                 response.data.list && setClasses(response.data.list);
                 setLoading(false);
            }
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    const addClassHandler = () => {
        if (user.userRole === "Teachers") {
                setModalType("form");
                setShowModal(true);
        } else {
            history.push('/products/classes/list');
        }
    }

    const joinClassByApp = (classId, lessionId) => {
        const data = {classId, lessionId};
        return axios.post(API_URL.USER_MEETING_APP_LINK_URL,data,{
            headers: authHeader()
        })
        .then(response => {
            response && response.data && response.data.link &&
            // window.open(response.data.link, '_blank').focus();
            window.open(response.data.link);
        })
        .catch(error => {
            const resMessage = getError(error);
            
            setLoading(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        })
    }

    return (
        
        <div>
            {!meetingInfo.meetingNo && 
                <>
                <div>
                    {/* {!isCompleted && !trial &&
                        <h2>My Classes 
                            {
                                !(user.userRole === "Teachers" && !user.permitAddClass) &&
                                <MdAddCircleOutline onClick={addClassHandler}/>
                            }
                            </h2>
                    } */}
                    {loading && <Loader />}
                    
                    {
                        !loading &&
                        classes.length === 0 &&
                        
                        <div className={classes.noResult}>No class found</div>  
                    }

                    {
                        !loading &&
                        classes.length > 0 && 
                        <ClassesList 
                            list={classes} 
                            isMyClass={isMyClass}
                            trial={trial}
                            isCompleted={isCompleted}
                            getList={getList}
                            setShowModal={setShowModal}
                            setClassId={setClassId}
                            setLessionId={setLessionId}
                            setTeacherId={setTeacherId}
                            setModalType={setModalType}
                            setMeetingInfo={setMeetingInfo}
                            setClassContent={setClassContent}
                            joinClassByApp={joinClassByApp}
                        /> 
                    }

                </div>
                <Modal 
                    setShowModal={setShowModal} 
                    showModal={showModal} 
                    contentRef={contentRef}
                    width={modalType === "addMeeting" ? "500px" : isAssigmentPreview ? "calc(100% - 40px)" : "80%"}
                    height={isAssigmentPreview ? "calc(100% - 40px)" : "auto"}
                    noScroll={noScroll}
                    disabledClickOutside={disabledClickOutside}
                    isTestForm={isTestForm}
                    content={
                        <div ref={contentRef}>
                            {/* {
                               modalType === "form" && user.userRole === "Teachers" &&
                               <NewClassForm setShowModal={setShowModal}/>
                            }

                            {
                               modalType === "edit" &&
                               <NewClassForm isEdit={true} setShowModal={setShowModal} showModal={showModal}/>
                            } */}

                            {
                                modalType === "studentsList" &&
                                <StudentsList classId={classId} lessionId={lessionId} showModal={showModal}/>
                            }

                            {
                                modalType === "teacherInfo" &&
                                <TeacherInfo classId={classId} showModal={showModal} teacherId={teacherId}/>
                            }

                            {
                               modalType === "addMeeting" &&
                               <SetMeetingForm setShowModal={setShowModal} setMeetingInfo={setMeetingInfo} />
                            }

                            {
                                modalType === "classDocuments" && 
                                <ClassDocument 
                                    classId={classId}
                                    setShowModal={setShowModal} 
                                    showModal={showModal}
                                    setNoScroll={setNoScroll}
                                    currentLessionId={lessionId}
                                    setDisabledClickOutside={setDisabledClickOutside}
                                    setIsTestForm={setIsTestForm}
                                    setIsAssigmentPreview={setIsAssigmentPreview}
                                />
                            }

                            {
                               modalType === "classReport" &&
                               <Reports
                                    classId={classId}
                                    showModal={showModal}
                                    lessionId={lessionId}
                                    modalType={modalType}
                                />
                            }

                            {
                               modalType === "classFeedback" &&
                               <LiveFeedbackForm 
                                    classId={classId} 
                                    lessionId={lessionId}
                                    setShowModal={setShowModal}
                                />
                            }

                            { classContent && user.userRole !== "Teachers" &&
                                <>
                                    <h2>{classContent.title}</h2>
                                    <div className={styles.teacherProfile}>
                                        {
                                        classContent.imageUrl && 
                                        <div className={styles.image}>
                                            <img src={classContent.imageUrl} alt="teacher" />
                                        </div>
                                        }
                                        <div className={styles.content} dangerouslySetInnerHTML={{ __html: classContent.classIntro }} />
                                    </div>
                            </>
                            }
                        </div>
                    } 
                />
                </>
            }
            {meetingInfo.meetingNo && 
                <Meeting meetingInfo={meetingInfo} classId={classId} lessionId={lessionId} />
            }    
        </div>    
    )
}

export default Classes