import { MdClose, MdDelete, MdEdit, MdMoreVert, MdPeople, MdPerson, MdPublic, MdReportProblem } from "react-icons/md";
import moment from "moment";
import { creditIcon, toastCredit } from '../../../common/utils';
import { useState } from "react";
import API_URL from '../../../common/urls';
import { toast } from 'react-toastify';
import axios from 'axios';
import { authHeader } from '../../../services/auth-header';
import {Logout} from '../../../services/auth.service';
import { useStore } from '../../../store/useStore';
import { useHistory } from 'react-router-dom';
import { getError } from '../../../common/utils';

const GiftItem = ({item, connection, setShowModal, setModalType, setCurId, list, setList, setCurItem}) => {
    const history = useHistory();
    const {setUser, user, setToastContent,  setMoreInfo, moreInfo} = useStore();
    const [showOptions, setShowOptions] = useState(false);

    const reportOnClick = () => {
        setShowModal(true);
        setModalType('report');
        setCurId(item.id);
    }

    const editOnClick = () => {
        setShowModal(true);
        setModalType('edit');
        setCurItem(item);
    }

    const deleteOnClick = () => {
        callApi("delete");
    }

    const closeOnClick = () => {
        callApi("hide");
    }

    const onTakeIt = () => {
        callApi('take');
    }

    const callApi = (action) => {
        axios.post(API_URL.POST_GIFT_ACTION_URL,{action: action, productId: item.id},{
            headers: authHeader(),
        })
        .then(response => {
            if (action === "hide" || action === "delete") {
                const index = list.indexOf(item);
                list.splice(index, 1);
                setList([...list]);
            }

            if (action === "edit") {
                setShowModal(true);
                setModalType('edit');
                setCurItem(item);
            }

            if (action === "take") {
                const index = list.indexOf(item);
                if (item.type === "credit") {
                    setToastContent && setToastContent(toastCredit('+', item.credit/item.quantity));
                    moreInfo.credit = moreInfo.credit + item.credit/item.quantity;
                    setMoreInfo({...moreInfo});
                    list.splice(index, 1);
                } else {
                    list[index].route = 'preparing';
                    list[index].taken += 1;
                }
                
                setList([...list]);
            }
            
            setShowOptions(false);
        })
        .catch(error => {
            const resMessage = getError(error);
            setShowModal(false);
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }

    return (
        <li className={item.userName === user.userName ? connection.give : ''}>
            <div className={connection.user}>
                {
                    item.userImageUrl &&
                    <img 
                        src={item.userImageUrl} 
                        alt="user image" 
                        width="40"
                    />
                }
                {
                    !item.userImageUrl &&
                    <MdPerson size="40" color="#ddd"/>
                }
                <div className={connection.name}>
                    {item.fullName}
                    <div className={connection.time}>
                        {moment.utc(item.time).startOf('seconds').fromNow()}
                        {item.privacy === "public" && <MdPublic />}
                        {item.privacy !== "public" && item.toUsers && item.toUsers.split(',').length === 1 && <MdPerson />}
                        {item.privacy !== "public" && item.toUsers && item.toUsers.split(',').length > 1 && <MdPeople />}
                    </div>
                </div>
                <div className={connection.more} title='more actions' onMouseOver={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                    {
                        !(item.userName === user.userName && item.taken > 0) &&
                        <MdMoreVert size={20}/>
                    }
                    {
                        showOptions && item.userName !== user.userName &&
                        <div className="options">
                            <MdReportProblem title="Report" onClick={reportOnClick}/>
                            <MdClose title="Hide" onClick={closeOnClick} />
                        </div>
                        
                    }
                    {
                        showOptions && item.userName === user.userName && item.taken === 0 &&
                        <div className="options">
                            <MdEdit title="Edit" onClick={editOnClick} />
                            <MdDelete title="Delete" onClick={deleteOnClick} />
                        </div>
                    }
                </div>
            </div>
            <div className={connection.info}>
                <h4>{item.name}</h4>
                <div className={connection.message}>{item.message}</div>
                <div className={connection.image}>
                    {
                        item.type === "credit" &&
                        <div className={connection.credit}>
                            <div>
                            {creditIcon(item.credit)}
                            <p>{item.quantity > 1 && item.credit/item.quantity + ' For Each'}</p>
                            </div>
                        </div>
                    }
                    {
                        item.type === "others" && item.imageUrl &&
                        <img src={item.imageUrl} />
                    }
                </div>
            </div>
            <div className={connection.action}>
                <div>
                    <span>Quantity:</span><strong>{item.quantity}</strong> <br/>
                    <span>Taken:</span><strong>{item.taken}</strong>
                </div>
                {
                    item.route === "pending" && item.userName !== user.userName &&
                    <button className='btn' onClick={onTakeIt}>Take it now</button>
                }
                {
                    item.route === "pending" && item.userName === user.userName &&
                    <span className={connection.route}>{item.route}</span>
                }
                {
                    item.route !== "pending" &&
                    <span className={connection.route}>{item.route}</span>
                }
            </div>
        </li>
    )
}

export default GiftItem;