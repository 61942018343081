import React from 'react';
import loading from './loading.module.scss';
import imageUrl from '../../images/loading.gif';

const Loading = ({showLoading}) => {

    return (
        <div 
            className={loading.loading} 
            style={{display: showLoading ? '' : 'none'}} 
        >
            <img src={imageUrl} alt="Loading" /><br />
        </div>
    )
}

export const Loader = () => {
    return (
        <div className={loading.circleLoading}></div>
    )
}

export default Loading;