import InputSearch from '../input/inputSearch';
import API_URL from '../../common/urls';
import axios from 'axios';
import { authHeader } from '../../services/auth-header';
import { getError } from '../../common/utils';
import {Logout} from '../../services/auth.service';
import { useStore } from '../../store/useStore';
import { Loader } from '../../components/loading';
import {toast} from 'react-toastify';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FacebookShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, EmailIcon, WhatsappIcon } from "react-share";

const Share = ({tests, questionId, setShowModal2, themeId}) => {
    const {setUser} = useStore();
    const history = useHistory();
    const domain = process.env.REACT_APP_FRONTEND_DOMAIN;
    const [search, setSearch] = useState('');
    const [usersForSharing, setUsersForSharing] = useState({users: []}); // use the same name in library
    const inputRef = useRef();
    const searchOnChange = (e) => setSearch(e.target.value);

    const selectUrl = (url) => {
        navigator.clipboard.writeText(url);
        inputRef.current.select();
    }

    const shareThisQuestion = () => {
        axios.post(API_URL.POST_USER_LIVE_TEST_ACTION_URL,{
            action: 'shareQuestion',
            questionId: questionId,
            toUsers: usersForSharing.users.join(',')
        },
            {
                headers: authHeader()
            }).then(response => {
                toast.success('This question has been shared to your friends');
                setShowModal2(false);
            })
            .catch(error => {
                const resMessage = getError(error);
                if(error.response && error.response.status === 401) {
                    Logout();
                    setUser(null);
                    history.push('/login');
                } else {
                    toast.error(resMessage);
                }      
            });
    }
    return (
        <div className={tests.shareBox}>
            <InputSearch
                placeholder='Search...'
                onChange={searchOnChange}
                label="Share this question to your friends (username, phone or email)"
                value={search}
                setValue={setSearch}
                apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                formData={usersForSharing}
                setFormData={setUsersForSharing}
                collection='users'
                isMulti
            />
            <div className='buttons'>
                <button className='btn' 
                    disabled={usersForSharing.users.length < 1}
                    onClick={shareThisQuestion}
                >
                    Share
                </button>
            </div>
            <div className='buttons'>
                <FacebookShareButton
                    url={`${domain}/shareTestPlay/${themeId}/${questionId}`}
                    hashtag={"#AidaPal, #EduTech"}
                    quote={"Share the question to Facebook"}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={70} round />
                </FacebookShareButton>
                <WhatsappShareButton
                    url={`${domain}/shareTestPlay/${themeId}/${questionId}`}
                    hashtag={"#AidaPal, #EduTech"}
                    subject='Share AidaPal question'
                >
                    <WhatsappIcon size={70} round />
                </WhatsappShareButton>
                <EmailShareButton
                    url={`${domain}/shareTestPlay/${themeId}/${questionId}`}
                    subject='Share AidaPal question'
                    body={`I'd like to share this question to you:`}
                >
                    <EmailIcon size={70} round />
                </EmailShareButton>
            </div>
            <div className='flex'>
                <input ref={inputRef} type='text' value={`${domain}/shareTestPlay/${themeId}/${questionId}`} readOnly style={{width: 'calc(100% - 80px)'}}/>
                <input type='button' value='Copy' style={{width: 80, marginLeft: 10}} 
                    onClick={() => selectUrl(`${domain}/shareTestPlay/${themeId}/${questionId}`)}
                />
            </div>
            
        </div>
    )
}

export default Share;