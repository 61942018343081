import { useState } from "react";
import UploadFiles from "./uploadFile";
import Input from '../../../components/input';
import { FaCheck } from "react-icons/fa";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from 'react-day-picker/moment';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import InputSearch from "../../../components/input/inputSearch";
import API_URL from "../../../common/urls";
import classNames from "classnames";
import CreatableSelect from 'react-select/creatable';
import moment from "moment";
import { useEffect } from "react";
import axios from "axios";
import useQuery from "../../../hooks/useQuery";
import { authHeader } from '../../../services/auth-header';
import { getError, renderArray } from '../../../common/utils';
import { useStore } from "../../../store/useStore";
import { useHistory } from "react-router";
import { Logout } from "../../../services/auth.service";
import { toast } from 'react-toastify';

const Step1 = ({lib, formData, setFormData, type, filtersData, selectItem, files, setFiles, parentInfo, setParentInfo}) => {
    const DateFormat = 'DD-MM-YYYY';
    const [showDueDate, setShowDueDate] =  useState(false);
    const [search, setSearch] = useState('');
    const queryParams = useQuery();
    const folderId = decodeURIComponent(queryParams.get('folderId'));
    
    const history = useHistory();
    const {setUser} = useStore();

    useEffect(() => {
        type === 'file' && folderId && getParentInfo();
    }, []);

    useEffect(() => {
        let _sj = [], _lv = [];
        if (parentInfo && parentInfo.info && parentInfo.info.subjects) {
            _sj = renderArray(parentInfo.info.subjects);
        }
        if (parentInfo && parentInfo.info && parentInfo.info.levels) {
            _lv = renderArray(parentInfo.info.levels);
        }
        setFormData({...formData,
            levels: _lv, subjects: _sj});
    }, [parentInfo]);

    const getParentInfo = () => {
        return axios.get(API_URL.GET_LIBRARY_ITEM_INFO_URL,{
            headers: authHeader(),
            params: {
                id: folderId,
                type: 'folder',
            }
        })
        .then(response => {
            if(response.data) {
                setParentInfo(response.data);
            }
        })
        .catch(error => {
            const resMessage = getError(error);            
            if(error.response && error.response.status === 401) {
                Logout();
                setUser(null);
                history.push('/login');
            } else {
                toast.error(resMessage);
            }
        });
    }
    const showDueDateOnChange = () => {
        setShowDueDate(!showDueDate);
        showDueDate && setFormData && setFormData({...formData, dueDate: ''});
    }

    const showTimeOnChange =  () => {
        setFormData && setFormData({...formData, showTime: !formData.showTime, totalTime: 0});
    }

    const autoMarkOnChange = () => {
        setFormData && setFormData({...formData, isAutoMarkEachQuestion: !formData.isAutoMarkEachQuestion});
    }

    const showResultsOnChange = () => {
        setFormData && setFormData({...formData, showResult: !formData.showResult});
    }

    const nameOnChange = (e) => {
        setFormData && setFormData({...formData, name: e.target.value});
    }

    const viewTimeOnChange = (e) => setFormData && setFormData({...formData, viewTime: e.target.value});

    const levelOnChange = (type) => {
        setFormData && setFormData({...formData, levels: type});
    }

    const subjectOnChange = (type) => {
        setFormData && setFormData({...formData, subjects: type});
    }

    const showListOnChange =  () => {
        setFormData && setFormData({...formData, showList: !formData.showList});
    }

    const totalTimeOnChange = (e) => {
        setFormData && setFormData({...formData, totalTime: e.target.value});
    }

    const dueDateOnChange = (type) => {
        setFormData && setFormData({...formData, dueDate: moment(type).format('YYYY-MM-DD')});
    }

    const displayRandomOnChange = () => {
        setFormData && setFormData({...formData, displayRandom: !formData.displayRandom});
    }

    const downloadableOnChange = () => {
        setFormData && setFormData({...formData, downloadable: !formData.downloadable});
    }

    const searchOnChange = (e) => setSearch(e.target.value);
    const topicsOnChange = type => setFormData({...formData, topics: type});
    const subTopicsOnChange = type => setFormData({...formData, subTopics: type});

    return (
        <div className={lib.autoScroll}>
            {
                (["worksheet","folder"].includes(type)) && 
                <Input
                    type="text" 
                    placeholder="" 
                    onChange={nameOnChange}
                    value={formData.name}
                    label="Name"
                    className={lib.inputName}
                />
            }
            {
                type !== "questions list" &&
                <>
                    <Input
                        className={lib.select}
                        type="select"
                        options={filtersData && filtersData.levels}
                        placeholder='Select Levels'
                        onChange={levelOnChange}
                        value={formData.levels}
                        label="Level"
                        isMulti={true}
                        isOptional={type === "folder"}
                    />
                    <Input
                        className={lib.select}
                        type="select"
                        options={filtersData && filtersData.subjects}
                        placeholder='Select Subjects'
                        onChange={subjectOnChange}
                        value={formData.subjects}
                        label="Subject"
                        isMulti={true}
                        isOptional={type === "folder"}
                    />
                </>
            }
            {
                (type === 'file' || type === 'worksheet') &&
                <>
                    <div className={lib.select}>
                        <label>Topics</label>
                        <CreatableSelect
                            isMulti
                            onChange={topicsOnChange}
                            options={filtersData.topics}
                            value={formData.topics}
                            placeholder="Select or Create new topics"
                        />
                    </div>
                    <div className={lib.select}>
                        <label>Sub Topics</label>
                        <CreatableSelect
                            isMulti
                            onChange={subTopicsOnChange}
                            options={filtersData.subTopics}
                            value={formData.subTopics}
                            placeholder="Select or Create new sub topics"
                        />
                    </div>
                </>
            }
            <div className={lib.privacy}>
                <label>Privacy</label>
                <ul>
                    <li className={formData.privacy === "onlyMe" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "onlyMe"})}>Only Me</li>
                    <li className={formData.privacy === "authorised" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "authorised"})}>Authorised</li>
                    <li className={formData.privacy === "public" ? lib.active : ""}
                        onClick={() => setFormData({...formData, privacy: "public"})}>Public</li>
                </ul>
            </div>
            {
                formData.privacy === "authorised" && 
                <InputSearch
                    placeholder='Search...'
                    onChange={searchOnChange}
                    label="Grant permission for users (username, phone or email)"
                    value={search}
                    setValue={setSearch}
                    apiUrl={`${API_URL.CONNECTION_SEARCH_URL}?filter=friends`}
                    formData={formData}
                    setFormData={setFormData}
                    collection='permissionForUsers'
                    isMulti
                    allowSelectPermission
                    useFullResult
                />
            }
            {
                type === 'file' &&
                <Input
                    type="number" 
                    placeholder="" 
                    onChange={viewTimeOnChange}
                    value={formData.viewTime}
                    label="Required viewing time (s)"
                    className={lib.inputNumber}
                />
            }
            {
                type === "worksheet" && 
                <div className={classNames(lib.methods, lib.inlineMethods)}>
                    <label>Worksheet Type</label>
                    <ul>
                        <li className={formData.worksheetType === "practice" ? lib.active : ""} 
                        onClick={() => setFormData({...formData, worksheetType: "practice"})}>
                            Practice
                        </li>
                        <li className={formData.worksheetType === "exam" ? lib.active : ""} 
                        onClick={() => setFormData({...formData, worksheetType: "exam"})}>
                            Exam
                        </li>
                    </ul>
                </div>
            }
            
            {
                (type === "file" || type === "questions list") && !selectItem &&
                <UploadFiles lib={lib} type={type} files={files} setFiles={setFiles}/>
            }
            {
                type === "file" && ["authorised","public"].includes(formData.privacy) &&
                <div className={`checkBox ${formData.downloadable ? "active" : ''}`} onClick={downloadableOnChange}>
                    <FaCheck /> <span>Allow others to download</span>
                </div>
            }
            {
                type === "worksheet" && 
                <div className={lib.additionInfo}>
                    {
                        formData.worksheetType === "exam" &&
                        <div className={`checkBox ${formData.showCorrectAnswer ? "active" : ''}`} 
                        onClick={() => setFormData({...formData, showCorrectAnswer: !formData.showCorrectAnswer})}>
                            <FaCheck /> <span>Show Correct Answers</span>
                        </div>
                    }
                    
                    <div className={`checkBox ${(showDueDate || formData.dueDate) ? "active" : ''}`} onClick={showDueDateOnChange}>
                        <FaCheck /> <span>Set Due Date</span>
                    </div>
                    {
                        showDueDate && type === "worksheet" && 
                        <div className={lib.dueDate}>
                            <DayPickerInput 
                                placeholder="" 
                                format={DateFormat} 
                                value={formData.dueDate}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                onDayChange={dueDateOnChange}
                            />
                        </div>
                    }
                    <div className={`checkBox ${formData.showList ? "active" : ''}`} onClick={showListOnChange}>
                        <FaCheck /> <span>Show List of Questions</span>
                    </div>
                    <div className={`checkBox ${formData.displayRandom ? "active" : ''}`} onClick={displayRandomOnChange}>
                        <FaCheck /> <span>Questions display randomly in test</span>
                    </div>
                    {
                        formData.worksheetType === "exam" &&
                        <div className={`checkBox ${formData.showTime ? "active" : ''}`} onClick={showTimeOnChange}>
                            <FaCheck /> <span>Show Time Countdown</span>
                        </div>
                    }
                </div>
            }
            {
                formData.showTime && type === "worksheet" && 
                <div className={lib.totalTime}>
                    <div>
                        <label onClick={() => setFormData && setFormData({...formData, showTimeQuestion: false})}>
                            {
                                !formData.showTimeQuestion &&
                                <MdRadioButtonChecked />
                            }
                            {
                                formData.showTimeQuestion &&
                                <MdRadioButtonUnchecked />
                            }
                            Countdown For Whole Worksheet</label>
                        {
                            !formData.showTimeQuestion &&
                            <Input
                                type="number"
                                value={formData.totalTime}
                                onChange={totalTimeOnChange}
                                endText="minute(s)"
                            />
                        }
                        
                    </div>
                    <div>
                        <label onClick={() => setFormData && setFormData({...formData, showTimeQuestion: true})}>
                            {
                                formData.showTimeQuestion &&
                                <MdRadioButtonChecked />
                            }
                            {
                                !formData.showTimeQuestion &&
                                <MdRadioButtonUnchecked />
                            }
                            Countdown For Each Question</label>
                    </div>
                    
                </div>
            }
        </div>
    )
}

export default Step1;