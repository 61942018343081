import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styles from '../styles.module.scss';
import MainInfo from './mainInfo';
import ChangePassword from './changePassword';
import ProfilePicture from './profilePicture';
import classNames from 'classnames';
import profile from '../../../images/profile.svg';
import changePass from '../../../images/changePass.svg';
import { getCurrentUserImage } from '../../../services/auth.service';
import { creditIcon, parseRgb } from '../../../common/utils';
import { MdAccountCircle } from 'react-icons/md';
import { useStore } from '../../../store/useStore';
import CreditExchange from './creditExchange';

const Profile = () => {
    const profileImage = getCurrentUserImage();
    const {moreInfo, user, customValue, showBox, setShowBox, activeTab, setActiveTab} = useStore();
    const boxChange = (tab) => {
        setActiveTab(tab);
        setShowBox(false);
    }
    
    const tabChange = () => {
        setShowBox(true);
        setActiveTab('');
    }

    useEffect(()=>{
        setActiveTab(null);
    }, []);

    return (
        <div>
            {
                activeTab &&
                <div className={styles.tabLinks}>
                    <Link
                        to="#" 
                        onClick={tabChange} 
                        style={{width: activeTab === "Connections" ? 300 : activeTab === "Sharing" ? 220 : 400}}
                    >
                        <svg viewBox={activeTab === "Connections" ? "0 0 360 60" : activeTab === "Sharing" ? "0 0 230 60" : "0 0 500 60"}>
                            <text className={styles.svgStroke} x="50%" y="50%">{activeTab}</text>
                            <text className={styles.svgText} x="50%" y="50%">{activeTab}</text>
                        </svg>
                    </Link>
                </div>
            }
            {
                showBox &&
                <div className={classNames(styles.tabLinks2,{[styles[`effect${customValue.box && customValue.box.effect}`]]: customValue.box && customValue.box.effect})}>
                    <div style={{width: user.userRole === "Students" ? 350 : 400}}>
                        <div className={styles.color1} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                        <Link 
                            to="#" 
                            onClick={() => boxChange('User Information')} 
                            className={classNames({[styles.active] : activeTab === "User Information"})}
                            style={{height: user.userRole === "Students" ? 240 : 400, marginBottom: 10, background:`url(${profile}) no-repeat center 90%/190px`}}
                        >
                            <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>User Information</strong>
                        </Link>
                        </div>
                        {
                            user.userRole === "Students" &&
                            <div className={classNames(styles.color2, styles.disabled)} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                                <Link 
                                    to="#" 
                                    onClick={() => boxChange('Credit Exchange')} 
                                    className={classNames({[styles.active] : activeTab === "Credit Exchange"})}
                                    style={{height: 150}}
                                >
                                    <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Credit Exchange</strong>
                                    <div className='creditExchange outside'>
                                        {creditIcon(moreInfo.credit)}
                                    </div>
                                </Link>
                            </div>
                        }
                    </div>
                    <div style={{width: user.userRole === "Students" ? 350 : 300}}>
                        <div className={styles.color3} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                            <Link 
                                to="#" 
                                onClick={() => boxChange('Change Password')} 
                                className={classNames({[styles.active] : activeTab === "Change Password"})}
                                style={{height: 150, marginBottom: 10, background:`url(${changePass}) no-repeat center 90%/80px`}}
                            >
                                <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Change Password</strong>
                            </Link>
                        </div>
                        <div className={styles.color4} style={customValue.box && customValue.box.boxColor && {background: `rgba(${parseRgb(customValue.box.boxColor).r},${parseRgb(customValue.box.boxColor).g},${parseRgb(customValue.box.boxColor).b}, ${customValue.box.boxColorOpacity})`}}>
                            <Link 
                                to="#" 
                                onClick={() => boxChange('Change Avatar')} 
                                className={classNames({[styles.active] : activeTab === "Change Avatar"})}
                                style={{height: 240, marginBottom: 10}}
                            >
                                <strong style={customValue.box && customValue.box.textColor && {color: customValue.box.textColor, textShadow: `1px 1px 1px ${customValue.box.shadowColor ? customValue.box.shadowColor : 'transparent'}`}}>Change Avatar</strong>
                                {profileImage &&
                                    <div style={{paddingTop: 20}}>
                                        <img 
                                            src={profileImage} 
                                            alt="profile" 
                                            width="120"
                                            title="view profile picture"
                                        />
                                    </div>
                                }
                                {
                                    !profileImage &&
                                    <MdAccountCircle size="120" color="#00000030"/>
                                }

                            </Link>
                        </div>
                    </div>
                </div>
            }
            {
                !showBox &&
                <div className={styles.whiteBox2}>
                    {activeTab === "User Information" && <MainInfo />}
                    {activeTab === "Change Password" && <ChangePassword/>}
                    {activeTab === "Change Avatar" &&  <ProfilePicture />}
                    {activeTab === "Credit Exchange" &&  <CreditExchange />}
                </div>
            }           
        </div>
    )
}

export default Profile;