import exam from './exam.module.scss';
import styles from '../styles.module.scss';
import { useState} from "react";
import ExamList from './examList';
import ExamHistory from './examHistory';

const Exam = () => {
    const [curTab, setCurTab] = useState('list');

    return (
        <div className={styles.whiteBox} style={{maxWidth: 1250}}>
            <div className="leaderBoard">
                <div className="topTabs">
                    <button onClick={() => setCurTab('list')} className={curTab === "list" ? "active" : undefined}>All Exams</button>
                    <button onClick={() => setCurTab('history')} className={curTab === "history" ? "active" : undefined}>Exams History</button>
                </div>
            </div>
                {
                    curTab === "list" &&
                    <ExamList exam={exam} />
                }
                {
                    curTab === "history" &&
                    <ExamHistory exam={exam} />
                }
        </div>
    )
}

export default Exam;