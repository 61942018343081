import React, {useState, useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Login} from '../../services/auth.service';
import styles from '../login/styles.module.scss';
import Input from '../../components/input';
import {getError} from '../../common/utils';
import useQuery from '../../hooks/useQuery';
import {useStore} from '../../store/useStore';

const LoginPage = ({from, handle}) => {
    const queryParams = useQuery();
    let fromQuery = decodeURIComponent(queryParams.get('from'));
    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const {setUser, setCurrentMenu} = useStore();

    useEffect(() => {
        setUserName(localStorage.getItem('userName'));
        setPassword(localStorage.getItem('password'));
        document.getElementById('zmmtg-root').style.display = 'none';
        setCurrentMenu(null);
    },[]);

    const userNameOnchange = (e) => {
        const val = e && e.target && e.target.value;
        setUserName(val)
    }

    const passwordOnchange = (e) => {
        const val = e && e.target && e.target.value;
        setPassword(val);
    }

    const login = () => {
        if(!userName) {
            toast.error(`User name is empty!`);
            return;
        }

        if(!password) {
            toast.error(`Password is empty!`);
            return;
        }

        if(isRemember) {
            localStorage.setItem('userName', userName);
            localStorage.setItem('password', password);
        }

        Login(userName, password).then(
            (response)=> { 
                setUser(response);
                    if(!from && fromQuery === "null") {
                        !handle && response.userRole === 'Teachers' && history.push('/user/library');
                        !handle && response.userRole !== 'Teachers' && history.push('/user/myAidaPal');
                        handle && handle();
                    } else {
                        if(from) {
                            history.push(`/${from}`);
                            if(from === "cart") {
                                history.push(`/${from}?showModal=1`);
                            }
                        }
                        
                        if(fromQuery !== "null") {
                            history.push(`/${fromQuery}`);
                        }
                    }
            },
            error => {
                const resMessage = getError(error);
                toast.error(resMessage);    
            }
        );
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            login();
        }
    }

    const checkboxOnChange = (e) => {
        if(e && e.target && e.target.checked){
            setIsRemember(true);
        };
    }

    return (
        <div className={styles.formContainer}>
            <div className={styles.login}>
                <h1>Login</h1>
                <div>
                    <Input 
                        type="text" 
                        placeholder="Username / Phone or Email"  
                        onChange={userNameOnchange} 
                        onKeyPress={handleKeyPress} 
                        value={userName}
                    />
                    <Input 
                        type="pass-with-view" 
                        placeholder="Password" 
                        onChange={passwordOnchange} 
                        onKeyPress={handleKeyPress} 
                        value={password}
                    />
                    <div className={styles.subLinks}>
                        <Input 
                            type="checkbox" 
                            checkboxLabel="Remember me"
                            className={styles.checkbox}
                            onChange={checkboxOnChange}
                        />
                        <Link to="/forgot-pass">Forgot password?</Link>
                    </div>
                    <div className="buttons">
                        <button className="btn" onClick={login} onKeyPress={handleKeyPress}>Login</button>
                    </div>
                    <p>
                        Not a user? <Link to={`/register${from ? "?from=" + from : fromQuery !== "null" ? "?from=" + fromQuery :"" }`}>Create an account</Link>
                    </p>
                    
                </div>     
            </div>
            
        </div>
        
    )
}

export default LoginPage;